import React, { type ReactElement } from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import DocumentsHubComponent from './documents/DocumentsHubComponent'
import LoanOffersComponent from './LoanOffersComponent'
import { type GetLendingPageData } from '@/graphql/__generated__/GetLendingPageData'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export interface LoansComponentProps {
  data?: GetLendingPageData
}

export default function LoansComponent (
  { data }: LoansComponentProps
): ReactElement {
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)

  const preApprovalOffers = data?.currentUser?.selectedOrganization?.preApprovedLendingOffers ?? []
  const genericOffers = data?.currentUser?.selectedOrganization?.genericLendingOffers ?? []

  const franchiseGroupId = data?.currentUser?.franchiseGroup?.id

  return (
    <Flex
      justifyContent='space-between'
      gap={8}
    >
      <Flex flexDir='column' gap={3} flex={2}>
        <Heading size='sm'>Loan Offers</Heading>
        <LoanOffersComponent preApprovalOffers={preApprovalOffers} genericOffers={genericOffers}/>
      </Flex>
      <Flex flexDir='column' gap={3} flex={1}>
        <Heading size='sm'>Documents</Heading>
        <DocumentsHubComponent
          franchiseGroupId={Number(franchiseGroupId)}
          organizationId={selectedOrganizationId}
        />
      </Flex>
    </Flex>
  )
}
