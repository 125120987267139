import React, { useState, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { Color } from '@/theme/theme'
import { TransferRuleType } from '@/graphql/__generated__/globalTypes'

import SelectorComponent from '@/library/form/select/SelectorComponent'
import { formatDateOfMonthToString } from '@/utils/transferRuleUtils'
import FormDateInput from '@/library/form/number/FormDateInput'

export interface RecurringRuleFormState {
  transferDate: string
  transferRuleType: TransferRuleType
  dayOfWeek: string
  dayOfMonth: number
}
export interface RecurringRuleFormSectionProps {
  formState: RecurringRuleFormState
  setState: React.Dispatch<React.SetStateAction<RecurringRuleFormState>>
}

enum TransferRuleAsString {
  'Daily' = 'Daily',
  'Weekly' = 'Weekly',
  'Monthly' = 'Monthly',
  'Once' = 'Once'
}

/*
  Renders part of the "create recurring rule" form pertaining to the cadence
  (daily, weekly) and sub-cadence (monday, 6th)
*/
export default function RecurringRuleFormSection (
  { formState, setState }: RecurringRuleFormSectionProps
): ReactElement {
  // TODO consolidate
  const [transferRuleTypeString, setTransferRuleTypeString] = useState<TransferRuleAsString>(TransferRuleAsString.Daily)

  return <Flex direction='column' gap={5} width='100%' >
    <Flex gap={3}>
      <SelectorComponent
        label='Frequency'
        alignText='start'
        options={[
          TransferRuleAsString.Daily,
          TransferRuleAsString.Weekly,
          TransferRuleAsString.Monthly,
          TransferRuleAsString.Once]}
        value={transferRuleTypeString}
        handleSelection={transferType => {
          // TODO consolidate
          setState({
            ...formState,
            transferRuleType: (transferTypeToEnum(transferType))
          })
          setTransferRuleTypeString(transferType)
        }}
      />
      {getFormComponentForRuleType()}
    </Flex>
  </Flex>

  function getFormComponentForRuleType (): ReactElement {
    switch (formState.transferRuleType) {
      case TransferRuleType.ONE_TIME:
        return (
          <FormDateInput
            fieldName='transferDate'
            label='Transfer Date (MM/DD/YYYY)'
            value={formState.transferDate}
            onChange={date => {
              setState({
                ...formState,
                transferDate: date
              })
            }}
            placeholder='MM/DD/YYYY'
            backgroundColor={Color.WHITE}
          />
        )
      case TransferRuleType.WEEKLY:
        return (
          <SelectorComponent
            label='On'
            alignText='start'
            options={['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']}
            value={formState.dayOfWeek}
            handleSelection={(dayOfWeek) => {
              setState({
                ...formState,
                dayOfWeek
              })
            }}
          />
        )
      case TransferRuleType.MONTHLY:
        return (
          <SelectorComponent
            label={formState.transferRuleType === TransferRuleType.MONTHLY ? 'On the' : 'On'}
            alignText='start'
            options={new Array(31).fill(null).map((_, i) => i + 1)}
            formatOptions={(option) => option != null ? formatDateOfMonthToString(option) : ''}
            value={formState.dayOfMonth}
            handleSelection={(dayOfMonth) => {
              setState({
                ...formState,
                dayOfMonth
              })
            }}
          />
        )
      default:
        return <Flex></Flex>
    }
  }

  function transferTypeToEnum (type: TransferRuleAsString): TransferRuleType {
    switch (type) {
      case TransferRuleAsString.Daily:
        return TransferRuleType.DAILY
      case TransferRuleAsString.Weekly:
        return TransferRuleType.WEEKLY
      case TransferRuleAsString.Monthly:
        return TransferRuleType.MONTHLY
      case TransferRuleAsString.Once:
        return TransferRuleType.ONE_TIME
    }
  }
}
