import React, { useState, type ReactElement, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { RequestAnEditModalContent } from './RequestAnEditModalContent'
import { AdditionalInfoModalContent } from './AdditionalInfoModalContent'
import { ConfirmationMessageComponent } from './ConfirmationMessageComponent'
import { AccountInfoRowType } from '../AccountInfoRowComponent'
import { DefaultModalComponent } from '@/library/modal/DefaultModalComponent'
import CircleCheckIcon from '@/library/icons/CircleCheckIcon'
import { CREATE_ACCOUNT_INFO_REVISION_REQUEST }
  from '@/graphql/mutations/CreateAccountInfoRevisionRequest'
import { type CreateAccountInfoRevisionRequestVariables }
  from '@/graphql/__generated__/CreateAccountInfoRevisionRequest'
import { RevisionRequestType, type AccountInfoUpdateField }
  from '@/graphql/__generated__/globalTypes'
import { Color, IconSize } from '@/theme/theme'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export interface AccountInfoUpdateInfoModalProps {
  currentValue?: string
  isModalOpen: boolean
  onClose: () => void
  productVariant: AccountInfoRowType
  objectId: string
  fieldType: AccountInfoUpdateField
}

export interface FormData {
  newFieldValue: string
  tin: string
  email: string
}

enum ModalPage {
  REQUEST_EDIT_PAGE,
  ADDITIONAL_INFO_PAGE,
  CONFIRMATION_PAGE
}

export interface PageSettings {
  title: string
  content: ReactElement
  submitTitle: string
  submitAction: () => void
}

export default function AccountInfoUpdateInfoModal ({
  currentValue,
  isModalOpen,
  onClose,
  productVariant,
  objectId,
  fieldType
}: AccountInfoUpdateInfoModalProps): ReactElement {
  const selectedFranchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const [
    createAccountInfoRevisionRequestMutation
  ] = useMutation<CreateAccountInfoRevisionRequestVariables, CreateAccountInfoRevisionRequestVariables>(
    CREATE_ACCOUNT_INFO_REVISION_REQUEST
  )

  const [modalPage, setModalPage] = useState<ModalPage>(ModalPage.REQUEST_EDIT_PAGE)

  const [formData, setFormData] = useState<FormData>({
    newFieldValue: '',
    tin: '',
    email: ''
  })

  const [formError, setFormError] = useState<string | null>()

  useEffect(() => {
    if (!isModalOpen) {
      setModalPage(ModalPage.REQUEST_EDIT_PAGE)
      setFormData({
        newFieldValue: '',
        tin: '',
        email: ''
      })
      setFormError('')
    }
  }, [isModalOpen])

  function onChangeTextArea (event: React.ChangeEvent<HTMLTextAreaElement>): void {
    const { name, value } = event.target
    setFormError(null)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  function onChange (event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target
    setFormError(null)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  function handleEditPageComplete (): void {
    if (formData.newFieldValue === '') {
      setFormError('New field value is missing')
    } else {
      setModalPage(ModalPage.ADDITIONAL_INFO_PAGE)
    }
  }

  async function handleSubmit (
    variant: AccountInfoRowType,
    formData: FormData
  ): Promise<void> {
    if (formData.tin === '' &&
      (variant === AccountInfoRowType.BUSINESS_WITH_KYC || variant === AccountInfoRowType.PERSON_WITH_KYC)) {
      setFormError('Tin is missing')
      return
    }
    if (formData.email === '') {
      setFormError('Email is missing')
      return
    }

    const objectType =
    (variant === AccountInfoRowType.BUSINESS_WITH_KYC || variant === AccountInfoRowType.BUSINESS_WITH_NO_KYC)
      ? RevisionRequestType.BUSINESS
      : RevisionRequestType.PERSON

    await createAccountInfoRevisionRequestMutation(
      {
        variables: {
          franchiseGroupId: selectedFranchiseGroupId,
          contactEmail: formData.email,
          fieldToChange: fieldType,
          newFieldValue: formData.newFieldValue,
          objectId,
          objectType,
          tin: formData.tin
        }
      }
    )
      .then(response => {
        setModalPage(ModalPage.CONFIRMATION_PAGE)
      })
      .catch(() => {
        setFormError('Something went wrong.  Please try again.  If the issue persists, contact us.')
      })
  }

  function modalContentForPage (
    currentPage: ModalPage
  ): ReactElement {
    switch (currentPage) {
      case ModalPage.REQUEST_EDIT_PAGE:
        return (
          <RequestAnEditModalContent
            currentValue={currentValue}
            onChangeTextArea={onChangeTextArea}
            formData={formData}
            formError={formError}
          />
        )
      case ModalPage.ADDITIONAL_INFO_PAGE:
        return (
          <AdditionalInfoModalContent
            onChange={onChange}
            productVariant={productVariant}
            formData={formData}
            formError={formError}
          />
        )
      case ModalPage.CONFIRMATION_PAGE:
        return (
          <ConfirmationMessageComponent/>
        )
    }
  }

  function modalTitleForPage (
    currentPage: ModalPage
  ): string {
    switch (currentPage) {
      case ModalPage.REQUEST_EDIT_PAGE:
        return 'Request an Edit'
      case ModalPage.ADDITIONAL_INFO_PAGE:
        return 'Additional Information\nRequired'
      case ModalPage.CONFIRMATION_PAGE:
        return 'Your request has been submitted'
    }
  }

  function submitButtonForPage (
    currentPage: ModalPage
  ): string {
    switch (currentPage) {
      case ModalPage.REQUEST_EDIT_PAGE:
        return 'Continue'
      case ModalPage.ADDITIONAL_INFO_PAGE:
        return 'Confirm'
      case ModalPage.CONFIRMATION_PAGE:
        return 'Done'
    }
  }

  function submitActionForPage (
    currentPage: ModalPage
  ): () => void {
    switch (currentPage) {
      case ModalPage.REQUEST_EDIT_PAGE:
        return handleEditPageComplete
      case ModalPage.ADDITIONAL_INFO_PAGE: {
        return () => { void handleSubmit(productVariant, formData) }
      }
      case ModalPage.CONFIRMATION_PAGE:
        return onClose
    }
  }

  function headerIconForPage (
    currentPage: ModalPage
  ): ReactElement | undefined {
    if (currentPage === ModalPage.CONFIRMATION_PAGE) {
      return <CircleCheckIcon color={Color.BRIGHT_BLUE} size={IconSize.XX_LARGE}/>
    }
    return undefined
  }

  return (
    <DefaultModalComponent
      title={modalTitleForPage(modalPage)}
      isOpen={isModalOpen}
      onClose={onClose}
      callToActionText={submitButtonForPage(modalPage)}
      callToActionClick={submitActionForPage(modalPage)}
      isLoading={false}
      hideCancel={modalPage === ModalPage.CONFIRMATION_PAGE}
      icon={headerIconForPage(modalPage)}
    >
      {modalContentForPage(modalPage)}
    </DefaultModalComponent>
  )
}
