import React, { useState, type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { FileUploadInput } from './FileUploadInput'
import FormLabelWrapper from '../FormLabelWrapper'
import { type FileUploadType } from '@/graphql/__generated__/globalTypes'
import CirclePlusIcon from '@/library/icons/CirclePlusIcon'
import { BorderRadius, Color, IconSize } from '@/theme/theme'
import CircleCheckIcon from '@/library/icons/CircleCheckIcon'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import CloseIcon from '@/library/icons/CloseIcon'
import Loader from '@/library/loading/Loader'
import { useUploadFile } from '@/hooks/useUploadFile'
import ErrorInline from '@/library/errors/ErrorInline'

interface FileUploadFormFieldProps {
  label: string
  secondaryLabel?: string
  fileType: FileUploadType
  onUpdate: (fileUrl: string) => void
  isRequired?: boolean
  fieldError?: string
  franchiseGroupId: number
  organizationId: string | null
}

export default function FileUploadFormField ({
  label,
  franchiseGroupId,
  secondaryLabel,
  fileType,
  onUpdate,
  fieldError,
  isRequired = false,
  organizationId
}: FileUploadFormFieldProps): ReactElement {
  const [uploadFile, { loading, error: uploadFileError }] = useUploadFile()
  const [submissionError, setSubmissionError] = useState<Error>()
  const [file, setFile] = useState<File | undefined>()

  function handleFileChange (e: React.ChangeEvent<HTMLInputElement>): void {
    const selectedFile = (e.target.files ?? [])[0]
    if (selectedFile == null) {
      setSubmissionError(Error('Unable to upload file.'))
      return
    }

    setFile(selectedFile)
    handleFileUpload(selectedFile)
  }

  function handleFileUpload (selectedFile: File): void {
    if (selectedFile == null) {
      setSubmissionError(Error('Unable to upload file.'))
      return
    }

    const updatedFileName = selectedFile.name
    if (updatedFileName == null) {
      setSubmissionError(Error('Unable to upload file.'))
      return
    }
    void uploadFile({
      file: selectedFile,
      type: fileType,
      franchiseGroupId,
      organizationId,
      onSuccess: (fileId) => {
        if (fileId == null) {
          setSubmissionError(Error('Unable to upload file.'))
          return
        }
        onUpdate(fileId)
      }
    })
  }

  const fileButton = (
    <Flex
      w='100%'
      gap={4}
      p={6}
      bg={Color.LIGHT_GREY}
      borderRadius={BorderRadius.CARD}
      alignItems='center'
      _hover={{ bg: Color.GREY, cursor: 'pointer' }}
      as='button'
    >
      <CirclePlusIcon color={Color.DARK_GREY} size={IconSize.SMALL}/>
      <Text>Upload Document (PDF, JPG, or PNG)</Text>
    </Flex>
  )
  const fileSubmittedButton = (
    <Flex
      w='100%'
      p={6}
      bg={Color.WHITE}
      borderRadius={BorderRadius.CARD}
      justifyContent='space-between'
      alignItems='center'
      gap={2}
    >
      <Flex
        gap={4}
        alignItems='center'
      >
        {loading ? <Loader size='12px'/> : <CircleCheckIcon color={Color.DARK_BLUE} size={IconSize.SMALL}/>}
        <Text color={Color.DARK_BLUE} textOverflow='ellipsis' noOfLines={1}>{file?.name}</Text>
      </Flex>
      <Button
        text='Clear'
        variant={ButtonVariant.WHITE_OUTLINE}
        beforeIcon={<CloseIcon size={IconSize.SMALL}/>}
        width='auto'
        size={ButtonSize.X_SMALL}
        onClick={() => { setFile(undefined) }}
      />
    </Flex>
  )

  return (
    <FormLabelWrapper
      fieldName={label}
      label={label}
      labelSubtext={secondaryLabel}
      errorMessage={fieldError}
      isRequired={isRequired}
    >
      {file == null
        ? <FileUploadInput
            type={fileType}
            onChange={handleFileChange}
            isLoading={false}
            customButton={fileButton}
          />
        : fileSubmittedButton}
      <ErrorInline error={submissionError ?? uploadFileError}/>
    </FormLabelWrapper>
  )
}
