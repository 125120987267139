import { Link } from '@chakra-ui/react'
import React, { useEffect, useState, type ReactElement } from 'react'
import { Event } from 'metrics/metrics'
import { useMutation, useQuery } from '@apollo/client'
import SparkleIcon from '../icons/SparkleIcon'
import AltirSkeleton from '../loading/AltirSkeleton'
import Button, { ButtonVariant } from '@/library/button/Button'
import { ROOT, ROUTES } from '@/api/routes'
import {
  type CreateStripeCheckoutLink,
  type CreateStripeCheckoutLinkVariables
} from '@/graphql/__generated__/CreateStripeCheckoutLink'
import { CREATE_STRIPE_CHECKOUT_LINK } from '@/graphql/mutations/CreateStripeCheckoutLink'
import { getStripeEnvironment } from '@/utils/envUtils'
import { GET_STRIPE_AND_AMPLIFY_PROMO } from '@/graphql/queries/GetStripeCheckoutLink'
import {
  type GetStripeCheckoutLink,
  type GetStripeCheckoutLinkVariables
} from '@/graphql/__generated__/GetStripeCheckoutLink'

const { STRIPE_PRODUCT_ALTIR_PRO } = getStripeEnvironment()

export default function AltirProUpgradeButton ({ organizationId }: { organizationId: string }): ReactElement {
  const [newCheckoutLink, setNewCheckoutLink] = useState<string | undefined>(undefined)
  const [isLinkCreated, setIsLinkCreated] = useState(false)
  const [existingCheckoutLink, setExistingCheckoutLink] = useState<string | null>(null)
  const [
    createLink,
    { loading: createLinkLoading, error: createLinkError }
  ] = useMutation<CreateStripeCheckoutLink, CreateStripeCheckoutLinkVariables>(
    CREATE_STRIPE_CHECKOUT_LINK, {
      onCompleted: (data) => {
        const link = data?.createStripeCheckoutLink?.link

        if (link != null) {
          setIsLinkCreated(true)
          setNewCheckoutLink(link)
        }
      }
    })

  const {
    loading: getLinkLoading,
    error: getLinkError
  } = useQuery<GetStripeCheckoutLink, GetStripeCheckoutLinkVariables>(
    GET_STRIPE_AND_AMPLIFY_PROMO,
    {
      variables: {
        organizationId
      },
      onCompleted: (data) => {
        if (data?.StripeCheckoutLink?.link != null) {
          setExistingCheckoutLink(data.StripeCheckoutLink.link)
          setIsLinkCreated(true)
        }
      }
    }
  )

  useEffect(() => {
    if (
      !getLinkLoading &&
      !isLinkCreated) {
      const variables = {
        organizationId,
        stripeProductId: STRIPE_PRODUCT_ALTIR_PRO,
        checkoutRedirectUrl: `https://${ROOT}${ROUTES.V2_SIGN_UP_SUCCESS}`
      }
      void createLink({ variables })
    }
  }, [getLinkLoading])

  return (
    <AltirSkeleton isLoading={getLinkLoading ?? createLinkLoading} error={createLinkError ?? getLinkError}>
      <Link _hover={{ textDecoration: 'none' }}
        href={`${newCheckoutLink ?? existingCheckoutLink ?? ''}` +
        `?client_reference_id=${organizationId}`}
        width='100%'
      >
        <Button
          text='Upgrade Now'
          variant={ButtonVariant.PRIMARY}
          beforeIcon={<SparkleIcon/>}
          onClickEventType={Event.ALTIR_UPGRADE_MODAL_STRIPE_REDIRECT_CLICK}
        />
      </Link>
    </AltirSkeleton>
  )
}
