import React, { type ReactElement } from 'react'
import BaseAnimation from './BaseAnimation'
import altirRacingFlag from '../../../assets/animations/altir_racing_flag.json'

export interface RacingFlagAnimationProps {
  size?: string
}

export default function RacingFlagAnimation ({ size = '400px' }: RacingFlagAnimationProps): ReactElement {
  return (
    <BaseAnimation animationData={altirRacingFlag} size={size}/>
  )
}
