import { gql } from '@apollo/client'
import {
  AMPLIFY_ACCOUNT_APPLICATION_FRAGMENT
} from '../../fragments/amplify_account_application/AmplifyAccountApplicationFragment'

export const GET_AMPLIFY_ACCOUNT_APPLICATION = gql`
    ${AMPLIFY_ACCOUNT_APPLICATION_FRAGMENT}
    query GetAmplifyAccountApplication($franchiseGroupId: Int!) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                id
                name
                amplifyAccount {
                    accountId
                }   
                hasAcceptedAmplifyTermsAndConditions
                amplifyAccountApplication {
                    ...AmplifyAccountApplicationFragment
                }
            }
        }
    }
`
