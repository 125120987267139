/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function CarrotIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M7.5 21L16.5 12L7.5 3' stroke={color} strokeWidth='3' strokeLinecap='round' strokeLinejoin='round'/>
      </svg>
    </IconContainer>
  )
}
