import React, { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import AmplifyFormGroupComponent from '../AmplifyFormGroupComponent'
import { AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import { AmplifyAccountApplicationStatus } from '@/graphql/__generated__/globalTypes'
import { Color } from '@/theme/theme'
import DisclosureIcon from '@/library/icons/DisclosureIcon'
import { routeWithParams } from '@/utils/routerUtils'
import { RouteParam, ROUTES } from '@/api/routes'

export interface DisclosuresRowProps {
  applicationId?: string
  franchiseGroupId?: number
  isDisabled: boolean
  hasAcceptedDisclosures: boolean
  context: AmplifyApplicationContext
}

export default function DisclosuresRow (
  {
    applicationId,
    franchiseGroupId,
    isDisabled,
    hasAcceptedDisclosures,
    context
  }: DisclosuresRowProps
): ReactElement {
  const navigate = useNavigate()

  function handleFormGroupClick (): void {
    if (isDisabled) return
    const link = routeWithParams(
      getRouteForContext(context),
      [
        {
          param: RouteParam.BUSINESS_ID,
          value: String(franchiseGroupId)
        },
        {
          param: RouteParam.AMPLIFY_APPLICATION_ID,
          value: String(applicationId)
        }
      ]
    )
    navigate(link)
  }

  const status = hasAcceptedDisclosures
    ? AmplifyAccountApplicationStatus.SUBMITTED
    : AmplifyAccountApplicationStatus.CREATED

  return (
    <AmplifyFormGroupComponent
      title='Disclosures & Agreements'
      status={status}
      icon={<DisclosureIcon color={isDisabled ? Color.DARK_GREY : Color.DARK_BLUE}/>}
      handleClick={handleFormGroupClick}
      isDisabled={isDisabled}
    />
  )
}

function getRouteForContext (context: AmplifyApplicationContext): string {
  switch (context) {
    case AmplifyApplicationContext.DEFAULT:
      return ROUTES.AMPLIFY_APP_DISCLOSURES
    case AmplifyApplicationContext.ONBOARDING:
      return ROUTES.V2_SIGN_UP_AMPLIFY_APP_DISCLOSURES
  }
}
