import React, { useState, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { type ApolloError, useMutation } from '@apollo/client'
import { FormInput } from '@/library/form/text/FormInput'
import { replaceEmptyStringWithUndefined } from '@/utils/stringUtils'
import { Color } from '@/theme/theme'
import ErrorInline from '@/library/errors/ErrorInline'
import { type ErrorWithContent } from '@/types/types'
import { CREATE_STORE_LOCATION } from '@/graphql/mutations/CreateStoreLocation'
import {
  type CreateStoreLocation,
  type CreateStoreLocationVariables
} from '@/graphql/__generated__/CreateStoreLocation'
import { getErrorCode } from '@/utils/errorUtils'
import { GraphQLErrorCode } from '@/graphql/__generated__/globalTypes'
import Button, { ButtonVariant } from '@/library/button/Button'

// Define Zod Schema
const storeLocationFormSchema = z.object({
  storeLocationName: z.string()
    .min(1, { message: 'Please enter a Store Name' }),
  storeLocationStoreId: z.string()
    .min(1, { message: 'Please enter a Store ID' }),
  storeLocationCheckId: z.string()
    .transform(replaceEmptyStringWithUndefined)
    .optional(),
  storeLocationPosId: z.string()
    .transform(replaceEmptyStringWithUndefined)
    .optional()
})

// Create a type from the zod schema
type AddStoreLocationFormInput = z.infer<typeof storeLocationFormSchema>

interface AddStoreLocationFormProps {
  franchiseGroupId: number
  submitButtonText?: string
  onSuccess: () => void
  onError?: () => void
}

export default function AddStoreLocationForm ({
  franchiseGroupId,
  onSuccess,
  submitButtonText = 'Save Changes',
  onError = () => {}
}: AddStoreLocationFormProps): ReactElement {
  const [submissionError, setSubmissionError] = useState<ErrorWithContent | undefined>()
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors }
  } = useForm<AddStoreLocationFormInput>({
    resolver: zodResolver(storeLocationFormSchema)
  })

  const [createStoreLocation, { loading }] = useMutation<CreateStoreLocation, CreateStoreLocationVariables>(
    CREATE_STORE_LOCATION, {
      onCompleted: onSuccess,
      onError: handleError
    }
  )

  function handleError (error: ApolloError): void {
    const errorCode = getErrorCode(error)
    switch (errorCode) {
      case GraphQLErrorCode.CHECK_ID_FIELD_ALREADY_IN_USE:
        setSubmissionError({
          customContent: {
            title: 'Invalid Check ID',
            subtitle: 'This Check ID is already associated with another store location.'
          },
          error: Error()
        })
        return
      case GraphQLErrorCode.POS_ID_FIELD_ALREADY_IN_USE:
        setSubmissionError({
          customContent: {
            title: 'Invalid POS ID',
            subtitle: 'This POS ID is already associated with another store location.'
          },
          error: Error()
        })
    }
    onError()
  }

  async function onSubmit (formData: AddStoreLocationFormInput): Promise<void> {
    void createStoreLocation({
      variables: {
        franchiseGroupId,
        storeLocationInput: {
          name: formData.storeLocationName,
          storeId: formData.storeLocationStoreId,
          checkFieldIdentifier: formData.storeLocationCheckId,
          posFieldIdentifier: formData.storeLocationPosId
        }
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Flex w='100%' flexDir='column' gap={4}>
        {/* Name */}
        <FormInput<AddStoreLocationFormInput>
          label='Name'
          fieldName='storeLocationName'
          fieldError={errors?.storeLocationName?.message}
          placeholder='E.g. East Side Store'
          register={register}
          registerLabel='storeLocationName'
          allowAutoComplete={false}
          isRequired={true}
        />
        {/* Location ID */}
        <FormInput<AddStoreLocationFormInput>
          label='Location ID'
          fieldName='storeLocationStoreId'
          fieldError={errors?.storeLocationStoreId?.message}
          placeholder='E.g. 01234'
          register={register}
          registerLabel='storeLocationStoreId'
          allowAutoComplete={false}
          isRequired={true}
        />
        {/* Check ID */}
        <FormInput<AddStoreLocationFormInput>
          label='Check Number Identifier'
          fieldName='storeLocationCheckId'
          backgroundColor={Color.WHITE}
          fieldError={errors?.storeLocationCheckId?.message}
          placeholder='E.g. 01234'
          register={register}
          registerLabel='storeLocationCheckId'
          allowAutoComplete={false}
          isRequired={false}
        />
        {/* POS ID */}
        <FormInput<AddStoreLocationFormInput>
          label='Point of Sale Identifier'
          fieldName='storeLocationPosId'
          fieldError={errors?.storeLocationPosId?.message}
          placeholder='E.g. Toast POS ID: 01234'
          register={register}
          registerLabel='storeLocationPosId'
          allowAutoComplete={false}
          isRequired={false}
        />
        <ErrorInline error={submissionError}/>
        <Button
          text={submitButtonText}
          isLoading={loading}
          variant={ButtonVariant.PRIMARY}
        />
      </Flex>
    </form>
  )
}
