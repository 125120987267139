import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { type Recipient } from './RecipientDropDown'
import RecipientPaymentHistory from './RecipientPaymentHistory'
import UnverifiedRecipientWarning from './UnverifiedRecipientWarning'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { ROUTES } from '@/api/routes'
import TransferIcon from '@/library/icons/TransferIcon'
import { IconSize } from '@/theme/theme'
import {
  type GetRecentRecipientsPage_currentUser_franchiseGroup_amplifyAccount as FinancialAccount
} from '@/graphql/__generated__/GetRecentRecipientsPage'

interface RecipientDropDownContentProps {
  recipient: Recipient
  amplifyAccount: FinancialAccount | null
}

export default function RecipientDropDownContent ({
  recipient,
  amplifyAccount
}: RecipientDropDownContentProps): ReactElement {
  const navigate = useNavigate()

  return (
    <Flex flexDir='column' gap={6}>
      {!recipient.isVerified && <UnverifiedRecipientWarning/>}
      <RecipientPaymentHistory transfers={recipient.transfers} amplifyAccount={amplifyAccount}/>
      <Flex gap={4}>
        <Button
          text='Make Transfer'
          beforeIcon={<TransferIcon size={IconSize.SMALL}/>}
          onClick={() => { navigate(ROUTES.TRANSFER, { state: { recipientCounterpartyId: recipient.id } }) }}
          size={ButtonSize.SMALL}
        />
        <Button
          text='View All'
          variant={ButtonVariant.WHITE_OUTLINE}
          afterIcon={<RightArrowIcon/>}
          onClick={() => { navigate(ROUTES.TRANSACTIONS) }}
          size={ButtonSize.SMALL}
        />
      </Flex>
    </Flex>
  )
}
