import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { uploadFileToS3 } from '../utils/s3Utils'
import {
  COMPLETE_LENDING_DOCUMENT_UPLOAD,
  INITIATE_LENDING_DOCUMENT_UPLOAD
} from '../graphql/mutations/UploadLendingDocument'
import {
  type CompleteLendingDocumentUploadVariables,
  type CompleteLendingDocumentUpload
} from '../graphql/__generated__/CompleteLendingDocumentUpload'
import { FileUploadStatus, type FileUploadType } from '../graphql/__generated__/globalTypes'
import {
  type InitiateLendingDocumentUploadVariables,
  type InitiateLendingDocumentUpload
} from '../graphql/__generated__/InitiateLendingDocumentUpload'
import { type HookState } from '../types/types'

export interface UploadFileInput {
  franchiseGroupId: number
  organizationId: string | null
  type: FileUploadType
  file: File
  onSuccess: (documentId?: string) => void
}

export type UploadFileSignature = (input: UploadFileInput) => Promise<void>

/*
 * This hook uploads a user-provided file to S3 and manages the lifecycle of
 * the corresponding FileUpload database record
 *
 * 1. Create FileUpload record and signed S3 upload URL
 * 2. Upload file to S3
 * 3. Update FileUpload record with SUCCESS/ Failure status
 *
 * Further notes: we may also want to explore removing the "onComplete" mutation and
 * updating the backend to poll S3 for a document upload directly. We would then have to poll the backend
 * for updates to the file status.
 */
export function useUploadFile (): [UploadFileSignature, HookState] {
  const [initiateUpload] = useMutation<InitiateLendingDocumentUpload, InitiateLendingDocumentUploadVariables>(
    INITIATE_LENDING_DOCUMENT_UPLOAD
  )
  const [completeUpload] = useMutation<CompleteLendingDocumentUpload, CompleteLendingDocumentUploadVariables>(
    COMPLETE_LENDING_DOCUMENT_UPLOAD

  )
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)

  async function uploadFile (input: UploadFileInput): Promise<void> {
    setLoading(true)
    try {
      // Create initial model + signed URL
      const createResult = await initiateUpload(
        {
          variables: {
            fileName: input.file.name,
            franchiseGroupId: input.franchiseGroupId,
            organizationId: input.organizationId,
            type: input.type
          }
        }
      )
      const document = createResult.data?.initiateLendingDocumentUpload
      if (document?.signedUploadUrl == null) {
        setLoading(false)
        setError(Error('Unable to create signed url'))
        return
      }

      try {
        // Upload file content to s3
        await uploadFileToS3(document.signedUploadUrl, input.file)

        // Update model with SUCCESS flag
        const uploadResult = await completeUpload({ variables: { id: document.id, status: FileUploadStatus.SUCCESS } })
        input.onSuccess(uploadResult.data?.completeLendingDocumentUpload.id)
      } catch (e) {
        await completeUpload({ variables: { id: document.id, status: FileUploadStatus.FAILED } })
      }
    } catch (error) {
      setError(Error('Unable to upload file'))
    } finally {
      setLoading(false)
    }
  }

  return [uploadFile, { loading, error }]
}
