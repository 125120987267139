import {
  // eslint-disable-next-line max-len
  type GetTreasuryPageData_currentUser_selectedOrganization_selectedFranchiseGroup_financialAccounts_defaultStoreLocation as StoreLocation,
  // eslint-disable-next-line max-len
  type GetTreasuryPageData_currentUser_selectedOrganization_selectedFranchiseGroup_financialAccounts as FinancialAccount
} from '@/graphql/__generated__/GetTreasuryPageData'
import { type LinkedAccountType } from '@/graphql/__generated__/globalTypes'
import { AccountsSortByType, type AccountsSortByInput } from '@/types/types'
import { getSortInput, nonNull, unique } from '@/utils/arrayUtils'

export function sortAndFilterAccounts (
  accounts: FinancialAccount[],
  sortByInput: AccountsSortByInput,
  queryValue?: string,
  selectedInstitutions: string[] = [],
  selectedLocations: string[] = [],
  selectedAccountTypes: LinkedAccountType[] = [],
  selectedBusinesses: string[] = []
): FinancialAccount[] {
  const selectedBusinessesAsNumber = selectedBusinesses.map(id => Number(id))
  const filteredAccounts = filterAccounts(
    accounts, queryValue, selectedInstitutions, selectedLocations, selectedAccountTypes, selectedBusinessesAsNumber
  )
  return sortAccounts(filteredAccounts, sortByInput)
}

function filterAccounts (
  accounts: FinancialAccount[],
  queryValue?: string,
  selectedInstitutions: string[] = [],
  selectedLocations: string[] = [],
  selectedAccountTypes: LinkedAccountType[] = [],
  selectedBusinesses: number[] = []
): FinancialAccount[] {
  return accounts
    // Search query filter
    .filter(account => {
      if (queryValue == null) return true
      const lowerCaseQueryValue = queryValue.toLowerCase()
      return (account.name?.toLowerCase().includes(lowerCaseQueryValue) ?? false) ||
        (account.lastFour?.includes(lowerCaseQueryValue) ?? false) ||
        (account.institution?.name?.toLowerCase().includes(lowerCaseQueryValue) ?? false) ||
        (account.accountSubTypeFormatted?.toLowerCase().includes(lowerCaseQueryValue) ?? false)
    })
    // Institution filter
    .filter(account => {
      if (selectedInstitutions.length < 1) return true
      if (account.institution?.name == null) return false
      return selectedInstitutions.includes(account.institution?.name)
    })
    // Location filter
    .filter(account => {
      if (selectedLocations.length < 1) return true
      if (account.defaultStoreLocation?.id == null) return false
      return selectedLocations.includes(account.defaultStoreLocation?.id)
    })
    // Account Type filter
    .filter(account => {
      if (selectedAccountTypes.length < 1) return true
      if (account.accountType == null) return false
      return selectedAccountTypes.includes(account.accountType)
    })
    // Business Filter
    .filter(account => {
      if (selectedBusinesses.length < 1) return true
      if (account.franchiseGroupId == null) return false
      return selectedBusinesses.includes(account.franchiseGroupId)
    })
}

function sortAccounts (
  accounts: FinancialAccount[],
  sortByInput: AccountsSortByInput
): FinancialAccount[] {
  if (sortByInput.type === AccountsSortByType.BALANCE) {
    return accounts.sort((_a, _b) => {
      const [a, b] = getSortInput(_a, _b, sortByInput.ascending)
      return (a.liveBalance?.currentBalance?.amount ?? 0) - (b.liveBalance?.currentBalance?.amount ?? 0)
    })
  } else if (sortByInput.type === AccountsSortByType.NAME) {
    return accounts.sort((_a, _b) => {
      const [a, b] = getSortInput(_a, _b, sortByInput.ascending)
      return (a.name ?? '').toLowerCase().localeCompare((b.name ?? '').toLowerCase())
    })
  }

  return accounts
}

export function getInstitutionNames (
  accounts: FinancialAccount[]
): string[] {
  const institutionSet = unique(nonNull(accounts.map(account => account.institution?.name)))
  return institutionSet.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
}

export function getStoreLocations (
  accounts: FinancialAccount[]
): StoreLocation[] {
  const locationSet = unique(nonNull(accounts.map(account => account.defaultStoreLocation)))
  return locationSet.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
}
