import React, { useState, type ReactElement } from 'react'
import { Center, Flex, Text } from '@chakra-ui/react'
import { type ApolloQueryResult, useMutation, type ApolloError } from '@apollo/client'
import { Event } from 'metrics/metrics'
import { useNavigate } from 'react-router-dom'
import PreSubmitModal from './PreSubmitModal'
import BusinessApplicationRow from './business/BusinessApplicationRow'
import OwnershipAttestationRow from './ownership_attestation/OwnershipAttestationRow'
import AccountUsageRow from './account/AccountUsageRow'
import DisclosuresRow from './disclosures/DisclosuresRow'
import OwnershipRow from './ownership/OwnershipRow'
import { useFetchAlloyDeviceData } from '../../../../../../hooks/useFetchAlloyDeviceData'
import { AmplifyApplicationContext } from '../AmplifyApplicationContainerComponent'
import { isAmplifyApplicationSubmitted, isPersonSectionComplete } from '@/utils/amplifyApplicationUtils'
import LegalDisclosureBox from '@/library/legal/LegalDisclosureBox'
import SecureBadgeIcon from '@/library/icons/SecureBadgeIcon'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { Color, IconSize } from '@/theme/theme'
import {
  SUBMIT_AMPLIFY_ACCOUNT_APPLICATION
} from '@/graphql/mutations/AmplifyApplicationMutations'
import { AmplifyAccountApplicationRole, AmplifyAccountApplicationStatus } from '@/graphql/__generated__/globalTypes'
import {
  type SubmitAmplifyAccountApplication,
  type SubmitAmplifyAccountApplicationVariables
} from '@/graphql/__generated__/SubmitAmplifyAccountApplication'
import {
  type GetAmplifyAccountApplication_currentUser_franchiseGroup_amplifyAccountApplication as AmplifyApplication,
  type GetAmplifyAccountApplication,
  type GetAmplifyAccountApplication_currentUser_franchiseGroup as FranchiseGroup
} from '@/graphql/__generated__/GetAmplifyAccountApplication'
import { ROUTES } from '@/api/routes'
import ErrorInline from '@/library/errors/ErrorInline'
import { ErrorCopy } from '@/utils/errorUtils'

export interface AmplifyAccountApplicationComponentProps {
  franchiseGroupId: number
  franchiseGroup?: FranchiseGroup
  amplifyApplication?: AmplifyApplication | null
  context: AmplifyApplicationContext
  refetchApplicationData: () => Promise<ApolloQueryResult<GetAmplifyAccountApplication>>
}

// eslint-disable-next-line max-len
const PATRIOT_ACT_DISCLOSURE_TEXT = 'To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions and their third parties to obtain, verify, and record information that identifies each person who opens a new account. What this means for you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may ask to see your driver\'s license or other identifying documents.'

export default function AmplifyApplicationComponent (
  {
    franchiseGroupId,
    franchiseGroup,
    amplifyApplication,
    context,
    refetchApplicationData
  }: AmplifyAccountApplicationComponentProps
): ReactElement {
  const navigate = useNavigate()
  const { data: { blackboxToken } } = useFetchAlloyDeviceData()
  const [isPreSubmitModalOpen, setIsPreSubmitModalOpen] = useState(false)
  const [submissionError, setSubmissionError] = useState<string | null>()

  const [
    submitAmplifyAccountApplication,
    { loading: isSubmissionLoading }
  ] = useMutation<SubmitAmplifyAccountApplication, SubmitAmplifyAccountApplicationVariables>(
    SUBMIT_AMPLIFY_ACCOUNT_APPLICATION,
    {
      onCompleted: () => {
        void refetchApplicationData()
        const baseOverviewRoute = getOverviewBaseRouteForContext(context)
        navigate(baseOverviewRoute, { state: { submittedApplicationName: franchiseGroup?.name } })
      },
      onError (error: ApolloError) {
        setSubmissionError(error.message)
      }
    }
  )

  async function handleSubmit (applicantFullName: string): Promise<void> {
    setIsPreSubmitModalOpen(false)
    const applicationId = amplifyApplication?.id
    if (applicationId == null || blackboxToken == null) {
      setSubmissionError('Something went wrong')
      return
    }

    void submitAmplifyAccountApplication({
      variables: {
        input: {
          applicationId,
          alloyBlackboxToken: blackboxToken,
          // TODO (pjc): Remove deprecated fields
          businessApplicationSensitiveInput: { businessApplicationId: '', tin: '' },
          personalApplicationSensitiveInput: [],
          applicantFullName
        }
      }
    })
  }

  const isApplicationSubmitted = isAmplifyApplicationSubmitted(amplifyApplication?.status)

  const hasAcceptedDisclosures = franchiseGroup?.hasAcceptedAmplifyTermsAndConditions === true

  const atLeastOneControlPerson = (amplifyApplication?.personalApplications ?? [])
    .some(p => p.roles?.includes(AmplifyAccountApplicationRole.CONTROL_PERSON))
  const isApplicationReadyForSubmission = amplifyApplication?.isApplicationReadyForSubmission === true &&
    hasAcceptedDisclosures && atLeastOneControlPerson

  const hasCompletedPersonalApplication = isPersonSectionComplete(amplifyApplication?.personalApplications ?? [])
  const hasCompletedOwnershipAttestation = amplifyApplication?.isOwnershipPercentageConfirmed === true
  const hasCompletedBusinessApplication =
    amplifyApplication?.businessApplication?.status === AmplifyAccountApplicationStatus.SUBMITTED
  const hasCompletedSourcesOfFunds = (amplifyApplication?.sourcesOfFunds?.length ?? 0) > 0

  if (isApplicationSubmitted) {
    return <></>
  }

  return (
    <Center flexDirection='column' width='100%' gap={8}>
      <LegalDisclosureBox
        disclosureText={PATRIOT_ACT_DISCLOSURE_TEXT}
        icon={<SecureBadgeIcon color={Color.DARK_GREY} size={IconSize.MEDIUM}/>}
      />
      <Flex flexDirection='column' width='100%' gap={10}>
        <Flex flexDirection='column' gap={3}>
          <Flex flexDirection='column' gap={1}>
            <Flex alignItems='start' flexDir='row' gap={1}>
              <Text color={Color.DARK_BLUE}>Step 1:</Text>
              <Text>
                About each Beneficial Owner and the Controlling Manager
              </Text>
            </Flex>
          </Flex>
          <OwnershipRow
            franchiseGroupId={franchiseGroupId}
            personApplications={amplifyApplication?.personalApplications ?? []}
            amplifyApplicationId={amplifyApplication?.id}
            context={context}
          />
        </Flex>
        <Flex flexDirection='column' gap={3}>
          <Flex flexDir='row' gap={1}>
            <Text color={Color.DARK_BLUE} as='span'>Step 2:</Text>
            <Text>Ownership Attestation</Text>
          </Flex>
          <OwnershipAttestationRow
            applicationId={amplifyApplication?.id}
            franchiseGroupId={franchiseGroupId}
            isDisabled={!hasCompletedPersonalApplication}
            isComplete={hasCompletedOwnershipAttestation}
            context={context}
          />
        </Flex>
        <Flex flexDirection='column' gap={3}>
          <Flex flexDir='row' gap={1}>
            <Text color={Color.DARK_BLUE} as='span'>Step 3:</Text>
            <Text>About the Business</Text>
          </Flex>
          <BusinessApplicationRow
            franchiseGroupId={franchiseGroupId}
            businessApplication={amplifyApplication?.businessApplication}
            hasCompletedOwnershipAttestation={hasCompletedOwnershipAttestation}
            context={context}
          />
        </Flex>
        <Flex flexDirection='column' gap={3}>
          <Flex flexDir='row' gap={1}>
            <Text color={Color.DARK_BLUE} as='span'>Step 4:</Text>
            <Text>About your Amplify Account</Text>
          </Flex>
          <AccountUsageRow
            franchiseGroupId={franchiseGroupId}
            existingFundingSources={amplifyApplication?.sourcesOfFunds ?? []}
            isDisabled={!hasCompletedBusinessApplication}
            applicationId={amplifyApplication?.id}
            context={context}
          />
        </Flex>
        <Flex flexDirection='column' gap={3}>
          <Flex flexDir='row' gap={1}>
            <Text color={Color.DARK_BLUE} as='span'>Step 5:</Text>
            <Text>Customer Agreements</Text>
          </Flex>
          <DisclosuresRow
            applicationId={amplifyApplication?.id}
            franchiseGroupId={franchiseGroupId}
            hasAcceptedDisclosures={hasAcceptedDisclosures ?? false}
            isDisabled={!hasCompletedSourcesOfFunds}
            context={context}
          />
        </Flex>
      </Flex>
      <Flex flexDirection='column' w='100%'>
        <ErrorInline
          error={
            submissionError != null
              ? {
                  error: Error(),
                  customContent: { title: ErrorCopy.TRY_AGAIN_LATER, subtitle: submissionError }
                }
              : undefined}
        />
        <Button
          text='Submit'
          isDisabled={!isApplicationReadyForSubmission}
          onClick={() => { setIsPreSubmitModalOpen(true) }}
          isLoading={isSubmissionLoading}
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.LARGE}
          onClickEventType={Event.SUBMIT_AMPLIFY_APPLICATION_CLICK}
          toolTipText={!atLeastOneControlPerson
            ? 'One applicant must be selected as controlling manager.'
            : undefined}
        />
      </Flex>
      <PreSubmitModal
        isOpen={isPreSubmitModalOpen}
        onClose={() => { setIsPreSubmitModalOpen(false) }}
        onSubmit={handleSubmit}
      />
    </Center>
  )
}

function getOverviewBaseRouteForContext (context: AmplifyApplicationContext): string {
  switch (context) {
    case AmplifyApplicationContext.ONBOARDING:
      return ROUTES.V2_SIGN_UP_AMPLIFY_OVERVIEW
    case AmplifyApplicationContext.DEFAULT:
      return ROUTES.AMPLIFY_APP_OVERVIEW
  }
}
