/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function PaperPlaneIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M21.4679 3.6589C21.2645 3.45699 21.0095 3.31506 20.7307 3.24868C20.4519 3.18231 20.1603 3.19407 19.8877 3.28268L4.16185 8.52823C3.8702 8.62064 3.61247 8.79742 3.42123 9.03622C3.22998 9.27502 3.1138 9.56515 3.08734 9.86995C3.06089 10.1747 3.12535 10.4806 3.2726 10.7487C3.41984 11.0169 3.64325 11.2354 3.91462 11.3767L10.4393 14.6015L13.664 21.1476C13.7936 21.4039 13.992 21.619 14.2369 21.769C14.4817 21.9189 14.7635 21.9978 15.0507 21.9968H15.1582C15.4657 21.9742 15.7593 21.8596 16.0008 21.6679C16.2424 21.4762 16.4206 21.2163 16.5125 20.9219L21.8333 5.23901C21.9283 4.96806 21.9444 4.6757 21.8797 4.39596C21.815 4.11622 21.6722 3.86061 21.4679 3.6589ZM4.8283 10.0116L18.5549 5.43249L10.9338 13.0536L4.8283 10.0116ZM15.1259 20.2985L12.0732 14.193L19.6943 6.5719L15.1259 20.2985Z' fill={color}/>
      </svg>
    </IconContainer>
  )
}
