/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize, Color } from '../theme/theme'

export default function TransferIcon ({ color = Color.WHITE, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg height='100%' width='100%' viewBox='0 0 25 24'>
        <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M16.0408 3.05874C15.6769 2.64337 15.0451 2.6017 14.6297 2.96568C14.2143 3.32966 14.1726 3.96145 14.5366 4.37683L18.163 8.51528H4.63281C4.08053 8.51528 3.63281 8.963 3.63281 9.51528C3.63281 10.0676 4.08053 10.5153 4.63281 10.5153H20.3689C20.7614 10.5153 21.1176 10.2857 21.2797 9.92817C21.4417 9.57069 21.3797 9.15145 21.121 8.85624L16.0408 3.05874ZM8.96094 20.9411C9.32492 21.3565 9.95671 21.3981 10.3721 21.0342C10.7875 20.6702 10.8291 20.0384 10.4651 19.623L6.83877 15.4846L20.369 15.4846C20.9212 15.4846 21.369 15.0369 21.369 14.4846C21.369 13.9323 20.9212 13.4846 20.369 13.4846L4.6329 13.4846C4.24039 13.4846 3.88418 13.7142 3.72212 14.0717C3.56006 14.4292 3.62211 14.8484 3.88079 15.1436L8.96094 20.9411Z'/>
      </svg>
    </IconContainer>
  )
}
