import React, { useRef, type ReactElement } from 'react'
import { AltirContext, createAltirStore, type OrganizationState } from '@/hooks/store/useAltirStore'

interface AltirContextProviderProps {
  userId: number
  defaultFranchiseGroupId: number
  defaultSelectedOrganizationState: OrganizationState
  children: ReactElement
}

export default function AltirContextProvider ({
  userId,
  defaultFranchiseGroupId,
  defaultSelectedOrganizationState,
  children
}: AltirContextProviderProps): ReactElement {
  const altirStore = useRef(createAltirStore({
    userId,
    defaultSelectedOrganizationState,
    defaultFranchiseGroupId
  })).current
  return (
    <AltirContext.Provider value={altirStore}>
      {children}
    </AltirContext.Provider>
  )
}
