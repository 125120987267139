import { Box, Divider, Flex, Tooltip } from '@chakra-ui/react'
import React, { type ReactElement, useState } from 'react'
import { getBackgroundColor, getHeaderTextColor } from './utils'
import { type BaseDropDownProps, DropDownVariant } from './types'
import DropDownHeader from './components/header/DropDownHeader'
import {
  BorderRadius
} from '../../theme/theme'

type DropDownComponentProps = BaseDropDownProps & { isOpenOnStart?: boolean }

export default function DropDownComponent (
  {
    title,
    subtitle,
    icon,
    dropDownContent,
    variant = DropDownVariant.DEFAULT,
    isOpenOnStart = false,
    rightDescription,
    rightCallToActionComponent,
    isComplete = false,
    hasNotification = false,
    isDisabled = false,
    disabledTooltipText,
    customHeader,
    borderStyle,
    padding = 6,
    isOpenFromParent,
    setIsOpenFromParent
  }: DropDownComponentProps
): ReactElement {
  const backgroundColor = getBackgroundColor(variant, isDisabled)
  const headerColor = getHeaderTextColor(variant, isDisabled)

  const [isSelected, setIsSelected] = useState(isOpenOnStart)

  function handleDropDownToggle (): void {
    if (isDisabled) return
    setIsSelected(!isSelected)

    // If Parent is managing state, update
    if (setIsOpenFromParent != null && isOpenFromParent != null) setIsOpenFromParent(!isOpenFromParent)
  }

  const isDropdownOpen = isOpenFromParent != null && setIsOpenFromParent != null
    ? isOpenFromParent
    : isSelected
  return (
    <Tooltip label={disabledTooltipText}>
      <Box
        bg={backgroundColor}
        borderRadius={BorderRadius.CARD}
        p={padding}
        width='100%'
        border={borderStyle}
      >
        {/* DropDown Header */}
        <DropDownHeader
          title={title}
          subtitle={subtitle}
          icon={icon}
          rightDescription={rightDescription}
          rightCallToActionComponent={rightCallToActionComponent}
          customHeader={customHeader}
          isSelected={isDropdownOpen}
          isDisabled={isDisabled}
          isComplete={isComplete}
          hasNotification={hasNotification}
          handleDropDownToggle={handleDropDownToggle}
          headerColor={headerColor}
        />
        {/* DropDown Content */}
        {
          isDropdownOpen &&
            <Flex
              direction='column'
              _hover={{ cursor: 'auto' }}
            >
              <Divider my={padding} variant='thick'/>
              {dropDownContent}
            </Flex>
        }
      </Box>
    </Tooltip>
  )
}
