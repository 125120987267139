import React, { type ReactElement } from 'react'
import TermsAndConditionsComponent from '../treasury/components/amplify/TermsAndConditionsComponent'
import Page from '@/library/page/Page'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export default function TermsAndConditionsPage (): ReactElement {
  const selectedFranchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  return (
    <Page>
      <TermsAndConditionsComponent franchiseGroupId={selectedFranchiseGroupId}/>
    </Page>
  )
}
