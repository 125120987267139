import React, { type ReactNode, type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import DropDownIsSelectedIcon from '../DropDownIsSelectedIcon'
import NotificationDotIcon from '@/library/icons/NotificationDotIcon'
import { Color, IconSize } from '@/theme/theme'

interface DropDownDefaultHeaderProps {
  // Text
  title?: string
  subtitle?: string
  rightDescription?: string

  // State
  isSelected: boolean
  isDisabled: boolean
  isComplete: boolean
  hasNotification: boolean
  onClick: () => void

  // Components
  rightCallToActionComponent?: ReactNode
  icon?: ReactNode

  // Styles
  headerColor: string
}

export default function DropDownDefaultHeader ({
  title,
  subtitle,
  rightDescription,
  isComplete,
  isSelected,
  isDisabled,
  hasNotification,
  onClick,
  icon,
  rightCallToActionComponent,
  headerColor
}: DropDownDefaultHeaderProps): ReactElement {
  return (
    <Flex
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
      h='8'
    >
      <Flex alignItems='center' w='100%' gap={4} flex={9}>
        {icon != null && <Flex alignItems='center'> {icon} </Flex>}
        <Flex flexDirection='column' justifyContent='center' flex={8}>
          <Flex justifyContent='start'>
            <Text color={headerColor}>
              {title}
            </Text>
            {hasNotification && <NotificationDotIcon size={IconSize.MEDIUM}/>}
          </Flex>
          <Text fontSize='sm'>{subtitle}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection='row' justifyContent='end' alignItems='center' verticalAlign='center' flex={4}>
        {rightCallToActionComponent != null
          ? rightCallToActionComponent
          : <>
            <Text color={isComplete ? Color.SUCCESS_GREEN : Color.DARK_GREY} mx={3}>
              {rightDescription}
            </Text>
            <DropDownIsSelectedIcon isSelected={isSelected} onClick={onClick} isDisabled={isDisabled}/>
          </>
      }
      </Flex>
    </Flex>
  )
}
