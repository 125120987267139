import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { Color, IconSize } from '@/theme/theme'

interface PaginationIconProps {
  isEnabled: boolean
  onClick: () => void
  icon: ReactElement
}

export default function PaginationIcon ({ isEnabled, onClick, icon }: PaginationIconProps): ReactElement {
  const iconWithColor =
     React.cloneElement(icon, { color: isEnabled ? Color.DARK_BLUE : Color.DARK_GREY, size: IconSize.SMALL })
  return (
    <Flex _hover={{ cursor: isEnabled ? 'pointer' : 'arrow' }} onClick={() => { isEnabled && onClick() }}>
      {iconWithColor}
    </Flex>
  )
}
