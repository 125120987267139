/* eslint-disable max-len */
import { Icon } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function CheckBadgeIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <Icon boxSize={size} display='flex' alignItems='center' justifyContent='center'>
      <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M13.5604 3.24927C13.377 2.98831 13.0801 2.83047 12.7612 2.8244C12.4423 2.81832 12.1397 2.96473 11.9465 3.21852C11.0888 4.3453 9.93388 5.2278 8.60164 5.77034C7.26927 6.31294 5.81067 6.49463 4.38015 6.29536C4.09363 6.25545 3.80388 6.34157 3.58567 6.53149C3.36746 6.72141 3.24219 6.99652 3.24219 7.2858V11.1351L3.24219 11.1374C3.2478 13.5658 4.00083 15.9344 5.40044 17.9352C6.79983 19.9357 8.78003 21.475 11.081 22.3567L11.0828 22.3574L11.9186 22.6758L11.9186 22.6759L11.9306 22.6803C12.4542 22.8722 13.0302 22.8722 13.5538 22.6803L13.5538 22.6804L13.5657 22.6758L14.4016 22.3574L14.4033 22.3567C16.7043 21.475 18.6845 19.9357 20.0839 17.9352C21.4835 15.9344 22.2366 13.5658 22.2422 11.1374L22.2422 11.1351V7.2858C22.2422 7.00921 22.1276 6.74497 21.9257 6.5559C21.7239 6.36684 21.4527 6.26983 21.1767 6.28795C17.632 6.52064 15.0808 5.41293 13.5604 3.24927ZM9.35598 7.62263C10.6244 7.10607 11.7677 6.34056 12.717 5.37673C14.5538 7.34046 17.1536 8.31056 20.2422 8.31798L20.2422 11.1328L20.2422 11.1336C20.2374 13.15 19.6122 15.1204 18.4451 16.7888C17.2777 18.4577 15.6211 19.7482 13.688 20.489L13.6877 20.4891L12.8615 20.8039C12.7847 20.831 12.6997 20.831 12.6229 20.8039L11.7967 20.4891L11.7963 20.489C9.86325 19.7482 8.20668 18.4577 7.03926 16.7888C5.87219 15.1205 5.24708 13.1502 5.24219 11.1339V8.3703C6.64732 8.40761 8.04955 8.15467 9.35598 7.62263ZM17.7759 11.4788C18.1375 11.0613 18.0921 10.4298 17.6746 10.0682C17.257 9.70671 16.6255 9.75211 16.264 10.1696L12.134 14.9393L9.92559 12.872C9.5224 12.4945 8.88958 12.5154 8.51215 12.9186C8.13471 13.3218 8.15559 13.9546 8.55878 14.332L11.5261 17.1098C11.7246 17.2957 11.9903 17.3926 12.2619 17.3784C12.5334 17.3642 12.7875 17.2399 12.9655 17.0344L17.7759 11.4788Z'/>
      </svg>
    </Icon>
  )
}
