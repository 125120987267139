import React, { type ReactNode, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { BorderRadius, Color } from '@/theme/theme'

interface InfoBoxProps {
  children: ReactNode

}

export function InfoBox ({ children }: InfoBoxProps): ReactElement {
  return (
    <Flex bg={Color.WHITE} p={6} w='100%' flexDir='column' gap={6} borderRadius={BorderRadius.CARD}>
      {children}
    </Flex>
  )
}
