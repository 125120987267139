import React, { type ReactElement } from 'react'
import '../../../assets/css/App.css'
import TreasuryComponent from './components/treasury/TreasuryComponent'
import { LinkedAccountType } from '@/graphql/__generated__/globalTypes'
import { useAltirStore } from '@/hooks/store/useAltirStore'

function TreasuryPage (): ReactElement<string, string> {
  const franchiseGroupId = useAltirStore((state) => state.selectedFranchiseGroupId)
  return (
    <TreasuryComponent
      accountType={LinkedAccountType.BANK}
      requestedFranchiseGroupId={franchiseGroupId}
    />
  )
}

export default TreasuryPage
