import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import StackedInfoAttribute from '../../../library/utility/StackedInfoAttribute'
import { BorderRadius, Color } from '@/theme/theme'
import { TransferRuleType } from '@/graphql/__generated__/globalTypes'

export interface BalanceRuleConfirmationCardProps {
  from: string
  to: string
  balanceThreshold: string
  type: TransferRuleType.TARGET_BALANCE | TransferRuleType.MIN_TARGET_BALANCE
}

export default function BalanceRuleConfirmationCard (
  { from, to, balanceThreshold, type }: BalanceRuleConfirmationCardProps): ReactElement {
  const triggerAccount = type === TransferRuleType.TARGET_BALANCE ? from : to
  const effectedAccount = type === TransferRuleType.TARGET_BALANCE ? to : from
  const condition = type === TransferRuleType.TARGET_BALANCE ? 'Exceeds' : 'Falls below'
  const result = type === TransferRuleType.TARGET_BALANCE ? 'Excess funds will be transferred into' : 'Pull Funds from'

  return (
    <Flex borderRadius={BorderRadius.CARD} backgroundColor={Color.WHITE} flexDir='column' gap={3} px={5} py={5}>
      <StackedInfoAttribute
        header='When'
        subtext={triggerAccount}
      />
      <StackedInfoAttribute
        header={condition}
        subtext={balanceThreshold}
      />
      <StackedInfoAttribute
        header={result}
        subtext={effectedAccount}
      />
    </Flex>
  )
}
