import React, { useState, type ReactElement } from 'react'
import { Flex, MenuItem, Text } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import CarrotComponent from '@/library/utility/toggle/CarrotComponent'
import { IconFacing } from '@/types/types'
import { Color, IconSize } from '@/theme/theme'
import { CheckEnablementStatus } from '@/graphql/__generated__/globalTypes'
import { REQUEST_CHECK_ISSUANCE_ACCESS } from '@/graphql/mutations/checks/RequestCheckAccess'
import {
  type RequestCheckIssuanceAccess,
  type RequestCheckIssuanceAccessVariables
} from '@/graphql/__generated__/RequestCheckIssuanceAccess'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import Loader from '@/library/loading/Loader'
import CheckEnablementConfirmationModal
  from '@/components/pages/account_detail/components/checks/CheckEnablementConfirmationModal'

interface CheckIssuanceAccessRequestMenuItemProps {
  checkEnablementStatus?: CheckEnablementStatus
}

export default function CheckIssuanceAccessRequestMenuItem ({
  checkEnablementStatus
}: CheckIssuanceAccessRequestMenuItemProps): ReactElement {
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const [
    requestCheckIssuanceAccess,
    { loading }
  ] = useMutation<RequestCheckIssuanceAccess, RequestCheckIssuanceAccessVariables>(
    REQUEST_CHECK_ISSUANCE_ACCESS,
    {
      variables: { organizationId: selectedOrganizationId },
      onCompleted: () => {
        setIsConfirmationModalOpen(true)
      },
      onError: () => {}
    }
  )

  function handleRequestAccessButtonClick (): void {
    if (checkEnablementStatus === CheckEnablementStatus.REQUESTED) return
    void requestCheckIssuanceAccess()
  }

  const hasRequestedAccess = checkEnablementStatus === CheckEnablementStatus.REQUESTED
  return (
    <MenuItem
      closeOnSelect={false}
      onClick={handleRequestAccessButtonClick}
      isDisabled={checkEnablementStatus !== CheckEnablementStatus.DISABLED}
    >
      <CheckEnablementConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => { setIsConfirmationModalOpen(false) }}
      />
      <Flex flexDir='row' align='center' justify='space-between' w='100%'>
        <Flex alignItems='center' gap={4}>
          <Text color={Color.DARK_BLUE}>
            {hasRequestedAccess ? 'Your Check Send access request is under review' : 'Request access to Check Send'}
          </Text>
          {loading && <Loader size='20px'/>}
        </Flex>
        {
            !hasRequestedAccess &&
            <CarrotComponent
              facing={IconFacing.RIGHT}
              color={Color.DARK_BLUE}
              size={IconSize.SMALL}
            />
        }
      </Flex>
    </MenuItem>
  )
}
