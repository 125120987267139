import React, { type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import {
  type GetAmplifyApplicationCompletionPercentage,
  type GetAmplifyApplicationCompletionPercentageVariables
} from '@/graphql/__generated__/GetAmplifyApplicationCompletionPercentage'
import {
  GET_AMPLIFY_APPLICATION_COMPLETION_PERCENTAGE
} from '@/graphql/queries/amplify_application/GetAmplifyApplicationCompletionPercentage'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import ProgressBarComponent, { ProgressBarVariant } from '@/library/utility/ProgressBarComponent'
import { ContainerWidth } from '@/theme/theme'

export interface AmplifyApplicationContext {
  franchiseGroupId: number
}

interface AmplifyStatusBarProps {
  amplifyApplicationContext: AmplifyApplicationContext
}

export default function AmplifyStatusBar ({ amplifyApplicationContext }: AmplifyStatusBarProps): ReactElement {
  const {
    data,
    loading,
    error
  } = useQuery<GetAmplifyApplicationCompletionPercentage, GetAmplifyApplicationCompletionPercentageVariables>(
    GET_AMPLIFY_APPLICATION_COMPLETION_PERCENTAGE,
    { variables: { franchiseGroupId: amplifyApplicationContext.franchiseGroupId } }
  )

  const completionPercentage = data?.currentUser?.franchiseGroup?.amplifyAccountApplication?.completionPercentage ?? 0
  return (
    <AltirSkeleton isLoading={loading} error={error} isCompact={true}>
      <Center w='100%'>
        <Center w='100%' maxWidth={ContainerWidth.MEDIUM} px={8}>
          <ProgressBarComponent
            percentage={completionPercentage}
            variant={ProgressBarVariant.BRIGHT_BLUE}
          />
        </Center>
      </Center>
    </AltirSkeleton>
  )
}
