import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { Color } from '@/theme/theme'

interface BackgroundBlurComponentProps {
  isVisible: boolean
  children?: ReactElement
}

export default function BackgroundBlurComponent ({ isVisible, children }: BackgroundBlurComponentProps): ReactElement {
  return (
    <Flex
      position='fixed'
      visibility={isVisible ? 'visible' : 'hidden'}
      top='0'
      left='0'
      width='full'
      height='full'
      zIndex={9999}
      backdropFilter='blur(10px)'
    >
      {children}
      <Flex
        zIndex={-1}
        position='fixed'
        width='full'
        height='full'
        bg={Color.DARK_BLUE}
        opacity={0.5}
        display='flex'
      >
      </Flex>
    </Flex>
  )
}
