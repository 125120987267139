import React, { type ReactElement, type ChangeEvent } from 'react'
import { Stack } from '@chakra-ui/react'
import { AccountInfoRowType } from '../AccountInfoRowComponent'
import ErrorToast from '@/library/errors/ErrorToast'
import { FormInput } from '@/library/form/text/FormInput'

export interface FormData {
  newFieldValue: string
  tin: string
  email: string
}

export interface AdditionalInfoModalContentProps {
  formData: FormData
  formError?: string | null

  productVariant: AccountInfoRowType
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export function AdditionalInfoModalContent ({
  formData,
  formError,
  productVariant,
  onChange
}: AdditionalInfoModalContentProps): ReactElement {
  return (
    <Stack spacing={6} width='100%'>
      {
      needsTin(productVariant) &&
      <FormInput
        fieldName='tin'
        value={formData.tin}
        label={tinLabelForRowType(productVariant)}
        labelSize='large'
        labelSubtext='We need this in order to authorize this edit'
        onChange={onChange}
        placeholder=''
      />
      }
      <FormInput
        fieldName='email'
        value={formData.email}
        label='Contact Email'
        labelSize='large'
        labelSubtext='We will contact you at this email about this edit'
        onChange={onChange}
        placeholder=''
      />
      <ErrorToast errorMessage={formError}/>
    </Stack>
  )
}

function needsTin (rowType: AccountInfoRowType): boolean {
  return rowType === AccountInfoRowType.BUSINESS_WITH_KYC ||
  rowType === AccountInfoRowType.PERSON_WITH_KYC
}

function tinLabelForRowType (rowType: AccountInfoRowType): string {
  switch (rowType) {
    case AccountInfoRowType.BUSINESS_WITH_KYC:
      return 'Business TIN'
    case AccountInfoRowType.PERSON_WITH_KYC:
      return 'Person TIN'
    default:
      return ''
  }
}
