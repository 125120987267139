/* eslint-disable max-len */
import { Icon } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function CloseThinIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <Icon boxSize={size} display='flex' alignItems='center' justifyContent='center'>
      <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path stroke={color} fill={color} d='M8.0795 7.05543C7.88795 6.85652 7.5774 6.85652 7.38585 7.05543C7.1943 7.25434 7.1943 7.57683 7.38585 7.77574L11.8369 12.3979L7.38601 17.0198C7.19446 17.2187 7.19446 17.5412 7.38601 17.7401C7.57756 17.939 7.88812 17.939 8.07967 17.7401L12.5306 13.1182L16.9809 17.7395C17.1725 17.9384 17.483 17.9384 17.6746 17.7395C17.8661 17.5406 17.8661 17.2181 17.6746 17.0192L13.2242 12.3979L17.6747 7.77634C17.8663 7.57743 17.8663 7.25494 17.6747 7.05603C17.4832 6.85712 17.1726 6.85712 16.9811 7.05603L12.5306 11.6776L8.0795 7.05543Z'/>
      </svg>
    </Icon>
  )
}
