import { getCurrencyFormatted, isEmpty } from '@/utils/stringUtils'

/**
 * 34345.30 => $34,345.30
 */
export function formatInputAsCurrency (value: string): string {
  if (value === '-' || value === '-$') {
    return '$-'
  }
  if (isEmpty(value)) {
    return '$'
  }

  // Pre-pend a zero if the user types "." to avoid NaN
  if (value === '.') return '$0.'

  const currencyFormatted = getCurrencyFormatted(Number(value), { currency: 'USD', minimumFractionDigits: 0 })
  // Since getCurrencyFormatted strips . and .0, the following branches are needed
  // to allow the user to enter decimals
  if (value[value.length - 1] === '.') {
    return `${currencyFormatted}.`
  } else if (
    value.length > 1 &&
      value[value.length - 2] === '.' &&
      value[value.length - 1] === '0'
  ) {
    return `${currencyFormatted}.0`
  }

  // If value has two fractional digits, we want to force display both
  if (value.includes('.') && (value.split('.')[1]?.length ?? 0) > 1) {
    return getCurrencyFormatted(Number(value), { minimumFractionDigits: 2 })
  }

  return currencyFormatted
}

/**
 * $1,234.56 -> 1234.56
 */
export function parsePositiveCurrency (value: string): string {
  const parsedValue = stripDollarsAndDashesFromNumericString(value)

  // Strip prepended 0, ie 02 -> 2
  if (parsedValue.length > 1 && parsedValue[0] === '0' && parsedValue[1] !== '.') {
    return parsedValue.substring(1, parsedValue.length)
  }

  if (
    parsedValue.split('.').length > 2 || // Prevent more than 2 "."
    (parsedValue.split('.')[1]?.length ?? 0) > 2 // prevent more than 2 decimal digits
  ) {
    return parsedValue.substring(0, parsedValue.length - 1)
  }
  return parsedValue
}
/**
 * $1,234.56 -> 1234.56
 */
export function parseCurrency (value: string): string {
  const parsedValue = stripDollarsFromNumericString(value)

  // Strip prepended 0, ie 02 -> 2
  if (parsedValue.length > 1 && parsedValue[0] === '0' && parsedValue[1] !== '.') {
    return parsedValue.substring(1, parsedValue.length)
  }

  if (
    parsedValue.split('.').length > 2 || // Prevent more than 2 "."
    (parsedValue.split('.')[1]?.length ?? 0) > 2 // prevent more than 2 decimal digits
  ) {
    return parsedValue.substring(0, parsedValue.length - 1)
  }
  return parsedValue
}

/**
 * 123456789 => 123-45-6789
 */
export function formatInputAsSSN (rawValue: string): string {
  const value = stripDollarsAndDashesFromNumericString(rawValue)
  if (value.length > 5) {
    return `${value.substring(0, 3)}-${value.substring(3, 5)}-${value.substring(5, 9)}`
  } else if (value.length > 3) {
    return `${value.substring(0, 3)}-${value.substring(3, 5)}`
  }
  return value
}

/**
 * 123456789 => 12-3456789
 */
export function formatInputAsEIN (value: string): string {
  if (value.length > 2) {
    return `${value.substring(0, 2)}-${value.substring(2, 9)}`
  }
  return value
}

/**
 * Strips non-numeric characters and adds slashes
 */
export function formatInputAsDate (rawValue: string): string {
  const value = stripDateFormatting(rawValue)

  // Format the string as a date
  if (value.length > 4) {
    return `${value.substring(0, 2)}/${value.substring(2, 4)}/${value.substring(4, 8)}`
  } else if (value.length > 2) {
    return `${value.substring(0, 2)}/${value.substring(2, 4)}`
  }
  return value
}

export function stripDateFormatting (date: string): string {
  return date.replace(/\D/g, '')
}

export function stripDollarsAndDashesFromNumericString (value: string): string {
  return Number(value) < 0 ? '' : value.replace(/[$-]/g, '')
}

export function stripDollarsFromNumericString (value: string): string {
  return value.replace(/[$]/g, '')
}
