import React, { type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import ChecksFAQComponent from './components/ChecksFAQComponent'
import CheckTransactionsComponent from './components/CheckTransactionsComponent'
import Page from '@/library/page/Page'
import { ContainerWidth } from '@/theme/theme'
import Button from '@/library/button/Button'
import PaperPlaneIcon from '@/library/icons/PaperPlaneIcon'
import { ROUTES } from '@/api/routes'

export default function ChecksPage (): ReactElement {
  const { ACCOUNT_ID: amplifyAccountId } = useParams()
  const navigate = useNavigate()
  return (
    <Page maxWidth={ContainerWidth.FULL_PAGE}>
      <Center w='100%' gap={6} alignItems='start'>
        <Center flex={2}>
          <CheckTransactionsComponent amplifyAccountId={Number(amplifyAccountId)}/>
        </Center>
        <Center flex={1} flexDir='column' gap={6} pt={1}>
          <Button
            text='Send Check'
            beforeIcon={<PaperPlaneIcon/>}
            onClick={() => { navigate(ROUTES.TRANSFER, { state: { isCheckSendPreSelected: true } }) }}
          />
          <ChecksFAQComponent/>
        </Center>
      </Center>
    </Page>
  )
}
