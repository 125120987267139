import React, { useState, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import TransactionRow from './TransactionRowComponent'
import {
  type TransactionFragment as Transaction
} from '@/graphql/__generated__/TransactionFragment'
import { type BankAccountIdMap } from '@/components/pages/transactions/components/utils'

interface TransactionsRowListComponentProps {
  transactionsData?: Transaction[]
  bankAccountIdMap?: BankAccountIdMap

  isCheckManagementEnabled?: boolean
}

export default function TransactionsRowListComponent (
  {
    transactionsData,
    bankAccountIdMap,
    isCheckManagementEnabled = false
  }: TransactionsRowListComponentProps
): ReactElement {
  const [selectedTransactionIndex, setSelectedTransactionIndex] = useState<number | null>(null)
  return (
    <Flex gap={2} flexDir='column'>
      {transactionsData?.map((transaction, index) => {
        return (
          <TransactionRow
            key={transaction.id}
            index={index}
            transaction={transaction}
            isSelected={selectedTransactionIndex === index}
            setSelectedTransactionIndex={setSelectedTransactionIndex}
            bankAccountIdMap={bankAccountIdMap}
            isCheckManagementEnabled={isCheckManagementEnabled}
          />
        )
      })}
    </Flex>
  )
}
