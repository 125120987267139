import { Select } from '@chakra-ui/react'
import
React, {
  type ChangeEvent,
  type ReactElement
} from 'react'
import FormLabelWrapper from '../FormLabelWrapper'
import { type BaseFormInputProps } from '../types'
import {
  BorderRadius,
  FontWeight,
  FormInputHeight,
  Color
} from '../../theme/theme'

export type FormSelectAlign = 'start' | 'center' | 'end'

export type FormSelectProps = {
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  value?: number
  placeholder: string
  children: ReactElement
  alignText?: FormSelectAlign
  color?: string
  backgroundColor?: string
  hasBorder: boolean
  height?: FormInputHeight
} & BaseFormInputProps

export const FormSelect = ({
  fieldName,
  label,
  onChange,
  value,
  placeholder,
  alignText = 'center',
  color = Color.DARK_GREY,
  children,
  fieldError,
  backgroundColor = Color.WHITE,
  height = FormInputHeight.DEFAULT,
  hasBorder,
  isRequired
}: FormSelectProps): ReactElement => {
  const isInErrorState = fieldError != null
  const showBorder = isInErrorState || hasBorder
  return (
    <FormLabelWrapper
      fieldName={fieldName}
      errorMessage={fieldError}
      label={label}
      isRequired={isRequired}
    >
      <Select
        name={fieldName}
        variant='filled'
        _hover={{ cursor: 'pointer' }}
        _focus={{ bg: backgroundColor }}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        backgroundColor={backgroundColor}
        borderRadius={BorderRadius.CARD}
        w='100%'
        h={height}
        textAlign={alignText}
        color={color}
        fontSize='md'
        fontWeight={FontWeight.MEDIUM}
        focusBorderColor={Color.DARK_GREY}
        borderColor={isInErrorState ? Color.ERROR_RED : Color.DARK_GREY}
        borderStyle='solid'
        borderWidth={getBorderWidth(height, showBorder)}
      >
        {children}
      </Select>
    </FormLabelWrapper>
  )
}

function getBorderWidth (height: FormInputHeight, showBorder: boolean): number {
  if (!showBorder) return 0

  return height === FormInputHeight.SMALL ? 1 : 1.5
}
