import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { Color } from '@/theme/theme'

interface FAQTextProps {
  question: string
  answer: string | null
  customAnswer?: ReactElement
}

export default function FAQText ({ question, answer, customAnswer }: FAQTextProps): ReactElement {
  return (
    <Flex flexDir='column' w='100%' gap={1} textAlign='start' alignItems='start'>
      <Text color={Color.DARK_BLUE}>{question}</Text>
      {customAnswer != null ? customAnswer : <Text>{answer}</Text>}
    </Flex>
  )
}
