import React, { type ReactElement, type ChangeEvent } from 'react'
import { Stack } from '@chakra-ui/react'
import ErrorToast from '@/library/errors/ErrorToast'
import FormTextArea from '@/library/form/text/FormTextArea'
import { Color } from '@/theme/theme'

export interface RequestAnEditModalContentProps {
  currentValue?: string
  formData: FormData
  formError?: string | null
  onChangeTextArea: (event: ChangeEvent<HTMLTextAreaElement>) => void
}

export interface FormData {
  newFieldValue: string
  tin: string
  email: string
}

export function RequestAnEditModalContent ({
  currentValue,
  formData,
  formError,
  onChangeTextArea
}: RequestAnEditModalContentProps): ReactElement {
  return (
    <Stack spacing={6} width='100%'>
      <FormTextArea
        fieldName='currentField'
        value={currentValue}
        label='Current Field'
        labelSize='large'
        onChange={onChangeTextArea}
        isDisabled={true}
        placeholder='i.e. Company, Inc.'
      />
      <FormTextArea
        fieldName='newFieldValue'
        label='New Field'
        labelSize='large'
        value={formData.newFieldValue}
        onChange={onChangeTextArea}
        placeholder=''
        backgroundColor={Color.WHITE}
      />
      <ErrorToast errorMessage={formError}/>
    </Stack>
  )
}
