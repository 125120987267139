/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function StockMarketIcon ({ color, size = IconSize.LARGE }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path stroke={color} d='M2.90488 18.4137L1.62794 17.0925C1.27773 16.7301 1.27773 16.1553 1.62794 15.793L9.5089 7.63836C9.87644 7.25806 10.486 7.25806 10.8535 7.63836L14.6846 11.6025C15.0439 11.9743 15.6368 11.9838 16.0079 11.6238L19.7704 7.97398C20.1594 7.59663 20.1471 6.96864 19.7437 6.60682L18.9832 5.92489C18.664 5.63858 18.8665 5.10938 19.2953 5.10938H24.065C24.5814 5.10938 25 5.52797 25 6.04434V11.1196C25 11.54 24.4885 11.7468 24.1964 11.4445L23.4173 10.6384C23.0497 10.2581 22.4402 10.2581 22.0727 10.6384L16.0079 16.9137C15.6403 17.294 15.0308 17.294 14.6633 16.9137L10.8535 12.9717C10.486 12.5914 9.87644 12.5914 9.5089 12.9717L4.24949 18.4137C3.88195 18.794 3.27241 18.794 2.90488 18.4137Z' strokeWidth='2'/>
      </svg>
    </IconContainer>
  )
}
