import React, { type ReactNode, type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import { type ApolloError } from '@apollo/client'
import Page from './Page'
import HelpCenterPopoverComponent from '../nav/help_center/HelpCenterPopoverComponent'
import OnboardingStatusBar, { type OnboardingCallToAction } from '../nav/onboarding/OnboardingStatusBar'
import AltirSkeleton from '../loading/AltirSkeleton'
import DisclosureFooter from '../nav/DisclosureFooter'
import { type AmplifyApplicationContext } from '../nav/onboarding/AmplifyStatusBar'
import OnboardingErrorContent from '../errors/OnboardingErrorContent'
import { Color, type ContainerWidth } from '@/theme/theme'

interface OnboardingPageProps {
  maxWidth?: ContainerWidth
  callToAction?: OnboardingCallToAction
  children: ReactNode
  isLoading?: boolean
  error?: ApolloError
  amplifyApplicationContext?: AmplifyApplicationContext
  shouldHideProgressBar?: boolean
}

export default function OnboardingPage ({
  maxWidth,
  callToAction,
  children,
  isLoading = false,
  error,
  amplifyApplicationContext,
  shouldHideProgressBar = false
}: OnboardingPageProps): ReactElement {
  return (
    <>
      <Page marginY={8} marginX={10} backgroundColor={Color.LIGHT_GREY}>
        <Center flexDir='column' w='100%' gap={20}>
          <Center flexDir='column' w='100%' justifyContent='start' gap={20} minH='100vh'>
            <OnboardingStatusBar
              callToAction={callToAction}
              amplifyApplicationContext={amplifyApplicationContext}
              shouldHideProgressBar={shouldHideProgressBar}
            />
            <Center w='full' maxW={maxWidth} flexDir='column'>
              <AltirSkeleton
                isLoading={isLoading}
                error={error}
                customErrorContent={<OnboardingErrorContent/>}
              >
                <Center flexDir='column' w='100%' gap={8}>
                  {children}
                </Center>
              </AltirSkeleton>
            </Center>
          </Center>
          <DisclosureFooter backgroundColor={Color.LIGHT_GREY}/>
        </Center>
      </Page>
      <HelpCenterPopoverComponent/>
    </>
  )
}
