import React, { type ReactElement } from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import { useComponentSize } from 'react-use-size'
import { type ApolloError } from '@apollo/client'
import MonthlyCashChangeGraph from './MonthlyCashChangeGraph'
import { type DateSelectionWindow } from '../../../../../types/types'
import EmptyGraphSection from '../EmptyGraphSection'
import { BorderRadius, Color } from '@/theme/theme'
import {
  type GetMonthlyMetrics_currentUser_franchiseGroup_historicalMonthlyAccountBalanceChange as MonthlyAccountBalanceChange
} from '@/graphql/__generated__/GetMonthlyMetrics'
import AltirSkeleton from '@/library/loading/AltirSkeleton'

interface MonthlyCashChangeGraphContainerProps {
  data: MonthlyAccountBalanceChange[]
  selectedDateRange: DateSelectionWindow
  loading: boolean
  error?: ApolloError
}

export default function MonthlyCashChangeGraphContainer ({
  data,
  selectedDateRange,
  loading,
  error
}: MonthlyCashChangeGraphContainerProps): ReactElement {
  const { ref, width } = useComponentSize()

  const hasSufficientData = data.length > 2 // We need a minimum of 3 months to calculate a scale
  return (
    <AltirSkeleton isLoading={loading} error={error}>
      {hasSufficientData
        ? <Flex
            ref={ref}
            flexDir='column'
            bg={Color.WHITE}
            w='100%'
            h='100%'
            borderRadius={BorderRadius.CARD}
            p={6}
            gap={3}
          >
          <Heading size='sm'>6-Month Cash Change</Heading>
          <MonthlyCashChangeGraph
            parentWidth={width}
            data={data}
            selectedDateRange={selectedDateRange}
          />
        </Flex>
        : <EmptyGraphSection metricDescription='6-month cash change'/>}
    </AltirSkeleton>
  )
}
