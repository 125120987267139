import React, { useEffect, useState, type ReactElement } from 'react'
import { type GetTransactionsPageData_currentUser_selectedOrganization_franchiseGroups as FranchiseGroup }
  from '@/graphql/__generated__/GetTransactionsPageData'
import PopoverMenuMultiSelector from '@/library/popoverMenu/PopoverMenuMultiSelector'

interface TransactionsBusinessSelectorComponentProps {
  queryValue: number[]
  onSubmit: (businessIds?: number[]) => void
  businesses: FranchiseGroup[]
  areFinancialAccountsLoading?: boolean
}

export default function TransactionsBusinessSelectorComponent (
  { queryValue, onSubmit, businesses, areFinancialAccountsLoading }:
  TransactionsBusinessSelectorComponentProps
): ReactElement {
  const [selectedBusinessIds, setSelectedBusinessIds] = useState<string[]>(
    queryValue?.map(businessId => String(businessId))
  )
  function handleSubmit (): void {
    const businessIdsAsNumber = selectedBusinessIds.map(businessId => Number(businessId))
    onSubmit(businessIdsAsNumber.length > 0 ? businessIdsAsNumber : undefined)
  }

  useEffect(() => {
    setSelectedBusinessIds(queryValue?.map(businessId => String(businessId)))
  }, [queryValue])

  return (
    <PopoverMenuMultiSelector
      label='Business'
      selectedOptionIds={selectedBusinessIds}
      options={businesses.map(business => { return { id: String(business.id), label: business.name ?? '' } })}
      onChange={(ids) => { setSelectedBusinessIds(ids) }}
      onSubmit={handleSubmit}
      allowInteriorScroll={true}
      isLoading={areFinancialAccountsLoading}
      shouldMatchWidth
    />
  )
}
