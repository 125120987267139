import { gql } from '@apollo/client'
import {
  FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT
} from '../../fragments/financial_accounts/FinancialAccountExtendedFragment'

export const GET_FINANCIAL_ACCOUNT = gql`
    ${FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT}
    query GetFinancialAccount($accountId: Int!) {
        financialAccount(accountId: $accountId) {
            ...FinancialAccountExtendedFragment
        }
    }
`
