import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { AvatarComponent } from '@/library/utility/AvatarComponent'
import { safeGetFirstLetter } from '@/utils/stringUtils'
import {
  type GetOrganizationSettings_currentUser_selectedOrganization as Organization
} from '@/graphql/__generated__/GetOrganizationSettings'
import { Color } from '@/theme/theme'

interface BusinessSelectorComponentProps {
  organization?: Organization
}

export default function OrganizationInfoComponent ({
  organization
}: BusinessSelectorComponentProps): ReactElement {
  return (
    <Flex flexDir='column' gap={4} justifyContent='center' alignItems='center' w='100%'>
      <AvatarComponent text={safeGetFirstLetter(organization?.name)}/>
      <Text color={Color.DARK_BLUE}>{organization?.name}</Text>
    </Flex>
  )
}
