import React, { type ReactElement } from 'react'
import { FormInput } from './FormInput'
import { Color, IconSize } from '@/theme/theme'
import SearchIcon from '@/library/icons/SearchIcon'

interface SearchBarProps {
  queryValue?: string
  onChange?: (value: string) => void
  onSubmit?: (value?: string) => void

  // Styles
  height?: string
}

export default function SearchBar ({
  onChange,
  onSubmit,
  queryValue,
  height
}: SearchBarProps): ReactElement {
  function handleChange (event: React.ChangeEvent<HTMLInputElement>): void {
    if (onChange != null) {
      onChange(event.target.value)
    }
  }

  function handleKeyPress (event: React.KeyboardEvent<HTMLInputElement>): void {
    if (onSubmit == null) {
      return
    }
    if (event.key === 'Enter' && queryValue != null) {
      onSubmit(queryValue)
    }
  }
  return (
    <FormInput
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      value={queryValue}
      fieldName='searchText'
      fieldInteriorLabel={<SearchIcon color={Color.DARK_GREY} size={IconSize.SMALL}/>}
      placeholder='Search'
      height={height}
    />
  )
}
