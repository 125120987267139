import React, { useState, type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import DownloadStatementButton from '../../../account_statement/DownloadStatementButton'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import {
  type GetAvailableStatements,
  type GetAvailableStatementsVariables
} from '@/graphql/__generated__/GetAvailableStatements'
import {
  BorderRadius,
  Color
} from '@/theme/theme'
import { GET_AVAILABLE_STATEMENTS } from '@/graphql/queries/GetAvailableBankStatements'
import PopoverMenuSingleOptionSelect from '@/library/popoverMenu/PopoverMenuSingleOptionSelect'
import PopoverMenu from '@/library/popoverMenu/components/PopoverMenu'

export interface AccountActivityComponentProps {
  accountId: number
}

export default function AccountDocumentsComponent (
  {
    accountId
  }: AccountActivityComponentProps
): ReactElement<string, string> {
  const [
    selectedStatementStartDate,
    setSelectedStatementStartDate
  ] = useState<string | null>(null)

  const {
    data,
    loading,
    error
  } = useQuery<GetAvailableStatements, GetAvailableStatementsVariables>(
    GET_AVAILABLE_STATEMENTS,
    {
      variables: {
        accountId
      },
      onCompleted: (data) => { setSelectedStatementStartDate(data.availableStatements[0]?.startDate ?? null) }
    }
  )

  const availableStatements = data?.availableStatements ?? []

  const selectedStatement = availableStatements.find(s => s.startDate === selectedStatementStartDate) ?? null

  return (
    <Flex flexDirection='column'>
      <Text>Documents</Text>
      <AltirSkeleton isLoading={loading} error={error} marginY={3}>
        <Flex
          width='100%'
          flexDirection='column'
          p={6}
          borderRadius={BorderRadius.CARD}
          bg={Color.WHITE}
          gap={6}
        >
          <Flex
            justifyContent='space-between'
            gap={4}
          >
            <Flex flexDir='column'>
              <Text color={Color.DARK_BLUE}>Statements</Text>
              <Text>Download monthly account statements</Text>
            </Flex>
            <Flex>
              <PopoverMenu
                popoverMenuTitle={selectedStatement?.startDate ?? 'Select Business'}
                onSubmit={() => {}}
                border={`1px solid ${Color.DARK_BLUE}`}
                borderRadius={BorderRadius.BUTTON}
                shouldMatchWidth={true}
                allowInteriorScroll={true}
              >

                <PopoverMenuSingleOptionSelect
                  options={availableStatements}
                  setSelectedOptionOrUndefined={(statement) => {
                    setSelectedStatementStartDate(statement?.startDate ?? null)
                  }}
                  selectedOption={selectedStatement}
                  formattingFunction={(statement) => ({
                    title: statement?.startDate ?? 'Unnamed Statement'
                  })}
                />
              </PopoverMenu>
            </Flex>
          </Flex>
          {
            selectedStatement?.startDate != null && selectedStatement?.endDate != null &&
              <DownloadStatementButton
                accountId={accountId}
                isAmplifyAccount={true}
                startDate={selectedStatement?.startDate}
                endDate={selectedStatement?.endDate}
              />
          }
        </Flex>
      </AltirSkeleton>
    </Flex>
  )
}
