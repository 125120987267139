import React, { useState, type ReactElement } from 'react'
import { Center, Flex, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import SuccessNextStepBox from './components/SuccessNextStepBox'
import OnboardingPage from '@/library/page/OnboardingPage'
import { ROUTES } from '@/api/routes'
import SuccessConfettiAnimation from '@/library/animations/SuccessConfettiAnimation'
import Button from '@/library/button/Button'
import { getFormattedFullName } from '@/utils/stringUtils'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import {
  CARD_SPARKLE,
  DOLLAR_SPARKLE,
  HANDHOLDING_PEOPLE_HEART,
  RECURRING_TRANSFER_SPARKLE,
  SUNGLASSES_SPARKLE
} from '@/theme/graphics'
import { UPDATE_ORGANIZATION_ONBOARDING_STATE } from '@/graphql/mutations/onboarding/UpdateOrganizationOnboardingState'
import {
  type UpdateOrganizationOnboardingState,
  type UpdateOrganizationOnboardingStateVariables
} from '@/graphql/__generated__/UpdateOrganizationOnboardingState'
import ErrorInline from '@/library/errors/ErrorInline'
import { GET_ONBOARDING_ORGANIZATION } from '@/graphql/queries/onboarding/GetOnboardingOrganization'
import { type GetOnboardingOrganization } from '@/graphql/__generated__/GetOnboardingOrganization'
import { ContainerWidth } from '@/theme/theme'

export default function OnboardingSuccessPage (): ReactElement {
  const navigate = useNavigate()
  const [submissionError, setSubmissionError] = useState<Error | undefined>()

  const {
    data,
    loading,
    error
  } = useQuery<GetOnboardingOrganization>(GET_ONBOARDING_ORGANIZATION)
  const [
    acknowledgeOnboardingCompletion,
    { loading: acknowledgeOnboardingCompletionLoading }
  ] = useMutation<UpdateOrganizationOnboardingState, UpdateOrganizationOnboardingStateVariables>(
    UPDATE_ORGANIZATION_ONBOARDING_STATE,
    {
      onCompleted: () => { navigate(ROUTES.HOME) },
      onError: () => {}
    }
  )

  function handleSubmission (): void {
    const organizationId = data?.currentUser?.selectedOrganization?.id
    if (organizationId == null) {
      setSubmissionError(Error())
      return
    }

    void acknowledgeOnboardingCompletion({
      variables: {
        organizationId,
        isOnboardingCompletionAcknowledged: true
      }
    })
  }

  const userFullName = getFormattedFullName(data?.currentUser?.firstName, data?.currentUser?.lastName)

  return (
    <OnboardingPage maxWidth={ContainerWidth.EXTRA_LARGE} isLoading={loading} error={error}>
      <SuccessConfettiAnimation/>
      <Center flexDir='column' textAlign='center' gap={1}>
        <Heading>Welcome to Altir, {userFullName}</Heading>
        <Text>You are ready to start using Altir. Here are a few things you can do:</Text>
      </Center>
      <Flex w='100%' gap={2}>
        <SuccessNextStepBox
          imageSrc={HANDHOLDING_PEOPLE_HEART}
          text='Invite team members'
          imageHeight='54px'
        />
        <SuccessNextStepBox
          imageSrc={DOLLAR_SPARKLE}
          text='Fund your Amplify Account'
          imageHeight='52px'
        />
        <SuccessNextStepBox
          imageSrc={RECURRING_TRANSFER_SPARKLE}
          text='Automate a transfer'
          imageHeight='58px'
        />
        <SuccessNextStepBox
          imageSrc={SUNGLASSES_SPARKLE}
          text='See your recent transactions'
          imageHeight='41px'
        />
        <SuccessNextStepBox
          imageSrc={CARD_SPARKLE}
          text='Apply for an Altir spend card'
          imageHeight='61px'
        />
      </Flex>
      <Button
        text='Explore Altir'
        afterIcon={<RightArrowIcon/>}
        onClick={handleSubmission}
        isLoading={acknowledgeOnboardingCompletionLoading}
        width='360px'
      />
      <ErrorInline error={submissionError}/>
    </OnboardingPage>
  )
}
