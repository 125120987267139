import { gql } from '@apollo/client'

export const LOAN_OFFER_FRAGMENT = gql`
    fragment LoanOfferFragment on PreApprovalOffer {
        id
        offerUrl
        minOfferAmount {
            amountFormatted
        }
        maxOfferAmount {
            amountFormatted
        }
        minInterestRate {
            aprRate
            monthlyRate
        }
        maxInterestRate {
            aprRate
            monthlyRate
        }
        termLengthFormatted
        minLoanMonths
        maxLoanMonths
        offerTTLDays
        status
        type
        lender {
            name
            email
            logoAssetUrl
        }
    }
`
