import { Box } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { BorderRadius, Color } from '../theme/theme'

export interface ProgressBarComponentProps {
  percentage: number
  variant?: ProgressBarVariant
  isFocused?: boolean
}

export enum ProgressBarVariant {
  DEFAULT,
  DEFAULT_HOVER,
  BRIGHT_BLUE
}

export default function ProgressBarComponent (
  { percentage, variant = ProgressBarVariant.DEFAULT }: ProgressBarComponentProps
): ReactElement {
  const roundedPercentage = Math.min(Math.max(0, percentage), 100)

  return (
    <Box width='100%' bg={Color.DARK_GREY} borderRadius={BorderRadius.BUTTON} overflow='hidden'>
      <Box
        width= '100%'
        height={2}
        backgroundColor={getBarBackgroundColor(variant)}
      >
        <Box
          width={`${roundedPercentage}%`}
          borderRadius={BorderRadius.BAR}
          backgroundColor={getBarColor(variant)}
          height='100%'
        />
      </Box>
    </Box>
  )
}

function getBarColor (variant: ProgressBarVariant): string {
  if (variant === ProgressBarVariant.BRIGHT_BLUE) return Color.BRIGHT_BLUE

  return Color.WHITE
}

function getBarBackgroundColor (variant: ProgressBarVariant): string {
  switch (variant) {
    case ProgressBarVariant.DEFAULT:
      return Color.DARK_BLUE
    case ProgressBarVariant.DEFAULT_HOVER:
      return Color.GREY_BLUE
    case ProgressBarVariant.BRIGHT_BLUE:
      return Color.GREY
  }
}
