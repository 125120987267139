import { type ReactElement } from 'react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AmplifyFormGroupComponent from '../AmplifyFormGroupComponent'
import { AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import Loader from '@/library/loading/Loader'
import PersonIcon from '@/library/icons/PersonIcon'
import {
  getApplicantNameFormatted,
  isPrimaryOwner
} from '@/utils/amplifyApplicationUtils'
import {
  // eslint-disable-next-line max-len
  type GetAmplifyAccountApplication_currentUser_franchiseGroup_amplifyAccountApplication_personalApplications as PersonalApplicationData
} from '@/graphql/__generated__/GetAmplifyAccountApplication'
import { Color } from '@/theme/theme'
import { RouteParam, ROUTES } from '@/api/routes'
import { routeWithParams } from '@/utils/routerUtils'

const CONTROL_PERSON_ROW_INDEX = 0

export interface PersonApplicationComponentProps {
  franchiseGroupId: number
  personalApplication?: PersonalApplicationData
  index: number
  context: AmplifyApplicationContext
}
export default function PersonApplicationRow (
  {
    franchiseGroupId,
    personalApplication,
    index,
    context
  }: PersonApplicationComponentProps
): ReactElement {
  const navigate = useNavigate()

  if (personalApplication == null) {
    return <Loader/>
  }

  function getTitle (): string {
    if (isPrimaryOwner(personalApplication) || index === CONTROL_PERSON_ROW_INDEX) return 'Controlling Manager'

    return `Owner #${String(index + 1)}`
  }

  const link = routeWithParams(
    getRouteForContext(context),
    [
      {
        param: RouteParam.BUSINESS_ID,
        value: String(franchiseGroupId)
      },
      {
        param: RouteParam.PERSON_ID,
        value: personalApplication.id
      }
    ]
  )

  return (
    <AmplifyFormGroupComponent
      title={getTitle()}
      subtitle={getApplicantNameFormatted(personalApplication)}
      status={personalApplication.status}
      icon={<PersonIcon color={Color.DARK_BLUE}/>}
      handleClick={() => { navigate(link) }}
    />
  )
}

function getRouteForContext (context: AmplifyApplicationContext): string {
  switch (context) {
    case AmplifyApplicationContext.DEFAULT:
      return ROUTES.AMPLIFY_APP_PERSON
    case AmplifyApplicationContext.ONBOARDING:
      return ROUTES.V2_SIGN_UP_AMPLIFY_APP_PERSON
  }
}
