import React, { useState, type ReactElement } from 'react'
import { Center, Checkbox, Flex, Text } from '@chakra-ui/react'
import ErrorInline from '@/library/errors/ErrorInline'
import Button from '@/library/button/Button'
import { Color } from '@/theme/theme'

interface OwnershipAttestationFormComponentProps {
  onSubmit: () => void
  isLoading: boolean
  error?: Error
}

export enum ExpectedTransferVolume {
  ZERO_TO_TEN,
  TEN_TO_TWENTY_FIVE,
  MORE_THAN_TWENTY_FIVE
}

// eslint-disable-next-line max-len
const ATTESTATION_TEXT = 'I hereby certify, to the best of my knowledge, that all information provided about the Controlling Manger and Beneficial Owners is complete and correct.'

export default function OwnershipAttestationFormComponent ({
  onSubmit, isLoading, error
}: OwnershipAttestationFormComponentProps): ReactElement {
  const [isAttestationAccepted, setIsAttestationAccepted] = useState(false)

  return (
    <Flex flexDir='column' gap={10}>
      <Flex flexDir='column' gap={6}>
        <Flex flexDir='column' gap={2}>
          <Flex gap={4}>
            <Checkbox
              isChecked={isAttestationAccepted}
              onChange={() => { setIsAttestationAccepted(!isAttestationAccepted) }}
              size='lg'
              colorScheme='selectableInput'
            />
            <Text color={Color.DARK_BLUE}>{ATTESTATION_TEXT}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Center flexDir='column' gap={4}>
        <Button
          text='Save & Continue'
          onClick={onSubmit}
          isLoading={isLoading}
          isDisabled={!isAttestationAccepted}
        />
        <ErrorInline error={error}/>
      </Center>
    </Flex>
  )
}
