import { gql } from '@apollo/client'
import { TRANSFER_FRAGMENT } from './TransferFragment'

export const TRANSACTION_FRAGMENT = gql`
    ${TRANSFER_FRAGMENT}
    fragment TransactionFragment on Transaction {
        id
        description
        originalDescription
        amount
        merchantFormatted
        bankAccountId
        isPendingTransaction
        categoryLabel
        date
        originatingTransfer {
            ...TransferFragment
        }
    }
`
