/* eslint-disable max-len */
import { Icon } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function CircleExclamationIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <Icon boxSize={size} display='flex' alignItems='center' justifyContent='center'>
      <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M22 12.6468C22 18.1696 17.5228 22.6468 12 22.6468C6.47715 22.6468 2 18.1696 2 12.6468C2 7.12394 6.47715 2.64679 12 2.64679C17.5228 2.64679 22 7.12394 22 12.6468ZM10.4964 8.37146C10.4742 8.16737 10.4964 7.96103 10.5617 7.76582C10.627 7.57062 10.7338 7.39092 10.8752 7.23838C11.0167 7.08583 11.1896 6.96386 11.3827 6.88036C11.5759 6.79687 11.7849 6.75372 11.9964 6.75372C12.2078 6.75372 12.4169 6.79687 12.61 6.88036C12.8032 6.96386 12.9761 7.08583 13.1175 7.23838C13.2589 7.39092 13.3658 7.57062 13.431 7.76582C13.4963 7.96103 13.5186 8.16737 13.4964 8.37146L12.913 14.0449C12.8934 14.2677 12.7884 14.4754 12.6186 14.6267C12.4489 14.778 12.2268 14.8619 11.9964 14.8619C11.7659 14.8619 11.5439 14.778 11.3741 14.6267C11.2044 14.4754 11.0993 14.2677 11.0797 14.0449L10.4964 8.37146ZM13.25 17.2899C13.25 16.9583 13.1183 16.6404 12.8839 16.406C12.6495 16.1716 12.3315 16.0399 12 16.0399C11.6685 16.0399 11.3505 16.1716 11.1161 16.406C10.8817 16.6404 10.75 16.9583 10.75 17.2899C10.75 17.6214 10.8817 17.9393 11.1161 18.1737C11.3505 18.4082 11.6685 18.5399 12 18.5399C12.3315 18.5399 12.6495 18.4082 12.8839 18.1737C13.1183 17.9393 13.25 17.6214 13.25 17.2899Z' fill={color}/>
      </svg>
    </Icon>
  )
}
