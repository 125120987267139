import React, { type ReactElement, useState } from 'react'
import { Flex, Stack, Switch, Text } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import ErrorInline from '@/library/errors/ErrorInline'
import AddressForm, { type AddressInput } from '@/library/form/address/AddressForm'
import { FormInput } from '@/library/form/text/FormInput'
import SelectorComponent from '@/library/form/select/SelectorComponent'
import Button, { ButtonVariant } from '@/library/button/Button'
import {
  isInvalidAddress,
  isInvalidDate,
  isInvalidOwnershipPercent,
  isInvalidSSN
} from '@/utils/formUtils'
import { fieldRequiredMessage, fieldInvalidMessage, isEmpty } from '@/utils/stringUtils'
import { COUNTRIES } from '@/utils/addressUtils'
import {
  // eslint-disable-next-line max-len
  type GetAmplifyAccountApplication_currentUser_franchiseGroup_amplifyAccountApplication_personalApplications as PersonalApplicationData
} from '@/graphql/__generated__/GetAmplifyAccountApplication'
import { Color } from '@/theme/theme'
import FormSSNInput from '@/library/form/number/FormSSNInput'
import FormPercentageInput from '@/library/form/number/FormPercentageInput'
import { isPrimaryOwner } from '@/utils/amplifyApplicationUtils'
import { type ErrorWithContent } from '@/types/types'
import FormDateInput from '@/library/form/number/FormDateInput'
import { formatInputAsDate } from '@/library/form/number/utils'

export interface PersonalApplicationFormData {
  businessTitle?: string | null
  citizenship?: string | null
  dateOfBirth?: string | null
  firstName?: string | null
  lastName?: string | null
  middleName?: string | null
  emailAddress?: string | null
  mailingAddress?: AddressInput | null
  ownershipPercentage?: number | null
  occupation?: string | null
  phoneNumber?: string | null
  physicalAddress?: AddressInput | null
  isPrimaryOwner?: boolean | null
  socialSecurityNumber?: string | null
}

export interface PersonalApplicationErrorState {
  businessTitle?: string | null
  citizenship?: string | null
  dateOfBirth?: string | null
  firstName?: string | null
  lastName?: string | null
  middleName?: string | null
  emailAddress?: string | null
  mailingAddress?: string | null
  ownershipPercentage?: string | null
  occupation?: string | null
  phoneNumber?: string | null
  physicalAddress?: string | null
  isPrimaryOwner?: string | null
  socialSecurityNumber?: string | null
}

export interface PersonApplicationFormComponentProps {
  personalApplication?: PersonalApplicationData
  isControllingManagerForm: boolean
  handleSubmit: (formData: PersonalApplicationFormData) => void
  handleDelete: () => void
  handleCancel: () => void
  isSubmissionLoading: boolean
  submissionError?: Error
}

export default function PersonApplicationFormComponent (
  {
    personalApplication,
    isControllingManagerForm,
    handleSubmit,
    handleDelete,
    handleCancel,
    isSubmissionLoading,
    submissionError
  }: PersonApplicationFormComponentProps
): ReactElement {
  const [formData, setFormData] = useState<PersonalApplicationFormData>({
    businessTitle: personalApplication?.businessTitle,
    citizenship: personalApplication?.citizenship,
    dateOfBirth: personalApplication?.dateOfBirth,
    firstName: personalApplication?.firstName,
    lastName: personalApplication?.lastName,
    middleName: personalApplication?.middleName,
    emailAddress: personalApplication?.emailAddress,
    mailingAddress: personalApplication?.mailingAddress,
    ownershipPercentage: personalApplication?.ownershipPercentage,
    occupation: personalApplication?.occupation,
    phoneNumber: personalApplication?.phoneNumber,
    physicalAddress: personalApplication?.physicalAddress,
    isPrimaryOwner: isPrimaryOwner(personalApplication) || isControllingManagerForm
  })
  const [inlineErrorState, setInlineErrorState] = useState<PersonalApplicationErrorState>({})
  const [inlineError, setInlineError] = useState<ErrorWithContent>()
  const [isMailingAddressSameAsPhysicalAddress, setIsMailingAddressSameAsPhysicalAddress] = useState(true)

  function onChange (event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target
    updateForm(name, value)
  }

  function updateForm (name: string, value: string | boolean): void {
    updateInlineErrorState(name, undefined)
    setInlineError(undefined)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  function updateInlineErrorState (name: string, value?: string): void {
    setInlineErrorState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  function handleFormSubmit (): void {
    if (!isFormValidForSubmit()) return

    handleSubmit({
      ...formData,
      // The raw form value is just the date's numbers, but we need to format as a date
      // before submitting to the backend
      dateOfBirth: formatInputAsDate(formData.dateOfBirth ?? '')
    })
  }

  function onIsMailingAddressSameAsPhysicalAddressChange (): void {
    // If setting addresses as different, set mailing address null
    // If setting addresses as same, do so
    if (isMailingAddressSameAsPhysicalAddress) {
      onMailingAddressChange(null)
    } else {
      onMailingAddressChange(formData.physicalAddress ?? null)
    }
    setIsMailingAddressSameAsPhysicalAddress(!isMailingAddressSameAsPhysicalAddress)
  }

  function onPhysicalAddressChange (address: AddressInput): void {
    updateAddress('physicalAddress', address)
    if (isMailingAddressSameAsPhysicalAddress) {
      onMailingAddressChange(address)
    }
  }

  function onMailingAddressChange (address: AddressInput | null): void {
    updateAddress('mailingAddress', address)
  }

  function updateAddress (name: string, address: AddressInput | null): void {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: address
    }))
  }

  // TODO (PJ): add field-specific error copy, additional field validations
  const isFormValidForSubmit = (): boolean => {
    let hasError = false

    if (isEmpty(formData.firstName)) {
      updateInlineErrorState('firstName', fieldRequiredMessage('First Name'))
      hasError = true
    }
    if (isEmpty(formData.lastName)) {
      updateInlineErrorState('lastName', fieldRequiredMessage('Last Name'))
      hasError = true
    }
    if (isEmpty(formData.businessTitle)) {
      updateInlineErrorState('businessTitle', fieldRequiredMessage('Business Title'))
      hasError = true
    }
    if (isEmpty(formData.citizenship)) {
      updateInlineErrorState('citizenship', fieldRequiredMessage('Citizenship'))
      hasError = true
    }
    if (isEmpty(formData.emailAddress)) {
      updateInlineErrorState('emailAddress', fieldRequiredMessage('Email Address'))
      hasError = true
    }
    if (isInvalidOwnershipPercent(formData.ownershipPercentage)) {
      updateInlineErrorState('ownershipPercentage',
        fieldInvalidMessage('Ownership Percentage', 'It must be in a number the format of 00'))
      hasError = true
    }
    if (isEmpty(formData.occupation)) {
      updateInlineErrorState('occupation', fieldRequiredMessage('Occupation'))
      hasError = true
    }
    if (isEmpty(formData.phoneNumber)) {
      updateInlineErrorState('phoneNumber', fieldRequiredMessage('Phone Number'))
      hasError = true
    }
    if (isInvalidSSN(formData.socialSecurityNumber)) {
      updateInlineErrorState('socialSecurityNumber',
        fieldInvalidMessage('Social Security Number', 'It must be a 9 digit number'))
      hasError = true
    }
    // TODO (PJ): Add more extensive address validation
    const {
      isInvalid: isAddressInvalid,
      reason: addressInvalidReason
    } = isInvalidAddress(formData.physicalAddress ?? {})
    if (isAddressInvalid) {
      updateInlineErrorState('physicalAddress', addressInvalidReason ?? fieldInvalidMessage('Physical Address', ''))
      hasError = true
    }
    if (isInvalidDate(formatInputAsDate(formData.dateOfBirth ?? ''))) {
      updateInlineErrorState('dateOfBirth',
        fieldInvalidMessage('Date of Birth', 'It must be in the format of MM/DD/YYYY'))
      hasError = true
    }

    if (hasError) {
      setInlineError({
        customContent: {
          title: 'Unable to submit form',
          subtitle: 'Please check your form fields'
        },
        error: Error('Please check your form fields.')
      })
    }

    return !hasError
  }

  const isControllingManager = isPrimaryOwner(personalApplication)

  return (
    <Flex flexDir='column' gap={8} w='100%'>
      <Stack spacing={4}>
        <FormInput
          fieldName='firstName'
          label='First Name'
          value={formData.firstName ?? ''}
          fieldError={inlineErrorState.firstName}
          onChange={onChange}
          placeholder='i.e. John'
          backgroundColor={Color.WHITE}
          isRequired
        />
        <FormInput
          fieldName='middleName'
          label='Middle Name'
          value={formData.middleName ?? ''}
          fieldError={inlineErrorState.middleName}
          onChange={onChange}
          placeholder='i.e. James'
          backgroundColor={Color.WHITE}
        />
        <FormInput
          fieldName='lastName'
          label='Last Name'
          value={formData.lastName ?? ''}
          fieldError={inlineErrorState.lastName}
          onChange={onChange}
          placeholder='i.e. Doe'
          backgroundColor={Color.WHITE}
          isRequired
        />
        <FormPercentageInput
          fieldName='ownershipPercentage'
          label='Ownership Percentage'
          value={formData.ownershipPercentage != null ? String(formData.ownershipPercentage) : ''}
          onChange={value => { updateForm('ownershipPercentage', value ?? '') }}
          placeholder='i.e. 33 - enter 0 for managers without an ownership stake'
          backgroundColor={Color.WHITE}
          fieldError={inlineErrorState.ownershipPercentage}
          isRequired
        />
        <FormInput
          fieldName='businessTitle'
          label='Title'
          value={formData.businessTitle ?? ''}
          fieldError={inlineErrorState.businessTitle}
          onChange={onChange}
          placeholder='i.e. CEO'
          backgroundColor={Color.WHITE}
          isRequired
        />
        <FormInput
          fieldName='occupation'
          label='Occupation'
          value={formData.occupation ?? ''}
          fieldError={inlineErrorState.occupation}
          onChange={onChange}
          placeholder='i.e. Entrepreneur'
          backgroundColor={Color.WHITE}
          isRequired
        />
        <FormInput
          fieldName='emailAddress'
          label='Email Address'
          value={formData.emailAddress ?? ''}
          fieldError={inlineErrorState.emailAddress}
          onChange={onChange}
          placeholder='i.e. name@email.com'
          backgroundColor={Color.WHITE}
          isRequired
        />
        <FormInput
          fieldName='phoneNumber'
          label='Phone Number'
          value={formData.phoneNumber ?? ''}
          fieldError={inlineErrorState.phoneNumber}
          onChange={onChange}
          placeholder='i.e. 999-999-9999'
          backgroundColor={Color.WHITE}
          isRequired
        />
        <AddressForm
          formName='Home Address'
          prepulatedAddress={personalApplication?.physicalAddress}
          onUpdate={onPhysicalAddressChange}
          fieldError={inlineErrorState.physicalAddress}
          backgroundColor={Color.WHITE}
          isRequired
        />
        <Flex justifyContent='space-between' py={4}>
          <Text>Mailing Address is the same as Business Address</Text>
          <Switch
            id='mailingAddressEnabled'
            isChecked={isMailingAddressSameAsPhysicalAddress}
            onChange={onIsMailingAddressSameAsPhysicalAddressChange}
          />
        </Flex>
        {!isMailingAddressSameAsPhysicalAddress &&
        <AddressForm formName='Mailing Address'
          prepulatedAddress={personalApplication?.mailingAddress}
          onUpdate={onMailingAddressChange}
          fieldError={inlineErrorState.mailingAddress}
        />
        }
        <SelectorComponent
          options={COUNTRIES}
          fieldName='country'
          label='Country of Citizenship'
          placeHolder='Select Country'
          value={formData.citizenship}
          fieldError={inlineErrorState.citizenship}
          alignText='start'
          backgroundColor={Color.WHITE}
          handleSelection={country => { updateForm('citizenship', country) }}
          isRequired
        />
        <FormDateInput
          fieldName='dateOfBirth'
          label='Date of Birth (MM/DD/YYYY)'
          value={formData.dateOfBirth ?? ''}
          fieldError={inlineErrorState.dateOfBirth}
          onChange={(value) => { updateForm('dateOfBirth', value ?? '') }}
          placeholder='MM/DD/YYYY'
          backgroundColor={Color.WHITE}
          isRequired
        />
        <FormSSNInput
          fieldName='socialSecurityNumber'
          label='SSN'
          placeholder='***-**-****'
          value={formData.socialSecurityNumber ?? ''}
          fieldError={inlineErrorState.socialSecurityNumber}
          backgroundColor={Color.WHITE}
          onChange={(value) => { updateForm('socialSecurityNumber', value ?? '') }}
          isRequired
        />
      </Stack>
      <Flex flexDir='column' gap={4}>
        <Button
          text='Save & Continue'
          onClick={handleFormSubmit}
          isLoading={isSubmissionLoading}
          onClickEventType={Event.PERSON_APPLICATION_SUBMISSION_CLICK}
        />
        <Flex width='100%' justifyContent='center' gap={3}>
          <Button
            text='Delete Owner'
            onClick={handleDelete}
            variant={ButtonVariant.DESTRUCTIVE_TRANSPARENT}
            onClickEventType={Event.PERSON_APPLICATION_DELETE_CLICK}
            isDisabled={isControllingManager}
            toolTipText={isControllingManager ? 'The Controlling Manager cannot be deleted' : undefined}
          />
          <Button
            text='Cancel'
            onClick={handleCancel}
            variant={ButtonVariant.WHITE_OUTLINE}
            onClickEventType={Event.PERSON_APPLICATION_CANCEL_CLICK}
          />
        </Flex>
        <ErrorInline error={inlineError ?? submissionError}/>
      </Flex>
    </Flex>
  )
}
