import { Flex, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { Color } from '@/theme/theme'

export interface LoanDataPointComponentProps {
  description: string
  stat: string
}

export default function LoanDataPointComponent (
  { description, stat }: LoanDataPointComponentProps
): ReactElement {
  return (
    <Flex flexDirection='row' justifyContent='space-between' alignItems='center'>
      <Text color={Color.WHITE} noOfLines={1}>{description}</Text>
      <Text color={Color.WHITE} noOfLines={1}>{stat}</Text>
    </Flex>
  )
}
