import React, { type ReactElement } from 'react'
import Banner, { BannerVariant } from '@/library/banner/Banner'
import TreasuryIcon from '@/library/icons/TreasuryIcon'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { sendSupportEmail } from '@/library/nav/help_center/utils'
import RightArrowIcon from '@/library/icons/RightArrowIcon'

export default function AccountClosedBanner (): ReactElement {
  return (
    <Banner
      title='This account is closed.'
      subtitle='If you have any questions, contact us.'
      beforeIcon={<TreasuryIcon/>}
      variant={BannerVariant.INFO}
      callToActionComponent={
        <Button
          text='Contact Us'
          onClick={sendSupportEmail}
          size={ButtonSize.SMALL}
          variant={ButtonVariant.GREY}
          afterIcon={<RightArrowIcon/>}
        />
    }
    />
  )
}
