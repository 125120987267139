import React, { type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import SuccessRowAction from './SuccessRowAction'
import Button from '@/library/button/Button'
import { ROUTES } from '@/api/routes'
import { Graphic } from '@/library/utility/Graphic'
import { SUCCESS_COMPASS_PLUS_GRAPHIC } from '@/theme/graphics'
import TreasuryIcon from '@/library/icons/TreasuryIcon'
import DollarIcon from '@/library/icons/DollarIcon'
import TransferIcon from '@/library/icons/TransferIcon'
import CloseThinIcon from '@/library/icons/CloseThinIcon'

export default function AccountUpgradeSuccessComponent (): ReactElement {
  const navigate = useNavigate()
  const steps = [
    {
      title: 'Open your new Altir account',
      icon: <TreasuryIcon/>
    },
    {
      title: 'Pay out any interest you have accrued in your old Altir account ',
      icon: <DollarIcon/>
    },
    {
      title: 'Wire funds from your old Altir account to your new Altir account',
      icon: <TransferIcon/>
    },
    {
      title: 'Close your old account',
      icon: <CloseThinIcon/>
    }
  ]
  return (
    <Center flexDir='column' gap={8} w='100%'>
      <Graphic src={SUCCESS_COMPASS_PLUS_GRAPHIC} h='120px'/>
      <Center flexDir='column' textAlign='center' w='100%' gap={1}>
        <Heading>
          You have successfully initiated the account upgrade.
        </Heading>
        <Text>
          We will email you within 1-2 days, once all of your accounts have been successfully upgraded.
          In the meantime, we will...
        </Text>
      </Center>
      <Center flexDir='column' gap={2}>
        {
          steps.map((step, index) => <SuccessRowAction key={index} icon={step.icon} title={step.title}/>)
        }
      </Center>
      <Button text='Done' onClick={() => { navigate(ROUTES.HOME) }}/>
    </Center>
  )
}
