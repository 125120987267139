
import { gql } from '@apollo/client'

export const GET_ONBOARDING_USER = gql`
    query GetOnboardingUser {
        currentUser {
            id
            firstName
            lastName
            email
        }
    }
`
