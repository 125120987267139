import React, { type ReactElement } from 'react'
import { Center, Flex, Text } from '@chakra-ui/react'
import CloudErrorIcon from '../icons/CloudErrorIcon'
import { Color, IconSize } from '@/theme/theme'

interface ErrorBoxDefaultContentProps {
  customMessage?: string[]
  isCompact: boolean
}

const DEFAULT_ERROR_MESSAGE_LINE_1 = 'We are having trouble loading this content.'
const DEFAULT_ERROR_MESSAGE_LINE_2 = 'Please try refreshing the page.'

export default function ErrorBoxDefaultContent ({
  customMessage,
  isCompact
}: ErrorBoxDefaultContentProps): ReactElement {
  function getText (): ReactElement {
    if (customMessage != null) {
      return (
        <Flex flexDir='column' textAlign='center'>
          {customMessage.map((message, index) => {
            return <Text key={index}>{message}</Text>
          })}
        </Flex>
      )
    }
    const defaultText = (
      <Flex flexDir='column' textAlign='center'>
        <Text>{DEFAULT_ERROR_MESSAGE_LINE_1}</Text>
        <Text>{DEFAULT_ERROR_MESSAGE_LINE_2}</Text>
      </Flex>
    )
    const defaultTextCompact = (
      <Flex flexDir='column' textAlign='center'>
        <Text>
          {`${DEFAULT_ERROR_MESSAGE_LINE_1} ${DEFAULT_ERROR_MESSAGE_LINE_2}`}
        </Text>
      </Flex>
    )
    return isCompact ? defaultTextCompact : defaultText
  }
  return (
    <Center flexDir='column'>
      {!isCompact && <CloudErrorIcon color={Color.DARK_GREY} size={IconSize.X_LARGE}/>}
      {getText()}
    </Center>
  )
}
