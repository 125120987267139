import React, { type ReactElement } from 'react'
import EmptyStateCard from '@/library/card/EmptyStateCard'
import Table, { type TableHeader } from '@/library/table/Table'
import {
  type GetRecentRecipientsPage_currentUser_franchiseGroup_amplifyAccount as FinancialAccount,
  type GetRecentRecipientsPage_currentUser_franchiseGroup_counterparties_transfers as Transfer
} from '@/graphql/__generated__/GetRecentRecipientsPage'
import { getFormattedTransferType } from '@/utils/transferUtils'
import { DateTimeTemplate, getFormattedDateString } from '@/utils/dateUtils'
import TransferStatusBadge from '@/library/badge/TransferStatusBadge'
import { Color } from '@/theme/theme'
import { getBankAccountShortName } from '@/utils/financialAccountUtils'
import { getCurrencyFormatted } from '@/utils/stringUtils'

interface RecipientTransaction {
  amount: string
  date: string
  transferStatus: ReactElement
  amplifyAccountName?: string
  method?: string
}

interface RecipientPaymentHistoryProps {
  transfers: Transfer[]
  amplifyAccount: FinancialAccount | null
}

export default function RecipientPaymentHistory ({
  transfers,
  amplifyAccount
}: RecipientPaymentHistoryProps): ReactElement {
  const tableMetadata: Array<TableHeader<RecipientTransaction>> = [
    { header: 'Date', key: 'date' },
    { header: 'Method', key: 'method' },
    { header: 'From', key: 'amplifyAccountName' },
    { header: 'Status', key: 'transferStatus', isReactElement: true },
    { header: 'Amount', key: 'amount', alignment: 'end' }
  ]
  const formattedTransfers: RecipientTransaction[] = transfers.map(transfer => {
    return {
      amount: getCurrencyFormatted(transfer.amount.amount),
      date: getFormattedDateString(transfer.createdAt, DateTimeTemplate.MONTH_YEAR_SHORT) ?? '',
      transferStatus: <TransferStatusBadge status={transfer.status ?? undefined}/>,
      amplifyAccountName: getBankAccountShortName(amplifyAccount?.lastFour ?? null, 'Altir'),
      method: transfer.type != null ? getFormattedTransferType(transfer.type) : undefined
    }
  })

  if (transfers.length < 1) {
    return (
      // TODO (PJ): Align with Allison on more robust empty state
      <EmptyStateCard
        title='No Transfers Found'
        subTitle='Any payments you make to this recipient will appear here.'
      />
    )
  }

  return (
    <Table metadata={tableMetadata} data={formattedTransfers} backgroundColor={Color.LIGHT_GREY}/>
  )
}
