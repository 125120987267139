import React, { type ReactElement } from 'react'
import TreasuryComponent from './components/treasury/TreasuryComponent'
import { LinkedAccountType } from '@/graphql/__generated__/globalTypes'
import { useNavigationState } from '@/hooks/useNavigationState'
import useSafeGetBusinessFromURL from '@/hooks/useSafeGetBusinessFromURL'

interface TreasuryPageNavigationState {
  isLinkAccountModalOpen?: boolean
}

export default function TreasuryBusinessPage (): ReactElement<string, string> {
  const stateOnNavigate = useNavigationState<TreasuryPageNavigationState>()
  // Allows franchiseGroupId to be set safely i.e. not outside of their allowed range
  const selectedBusinessId = useSafeGetBusinessFromURL()
  return (
    <TreasuryComponent
      requestedFranchiseGroupId={selectedBusinessId}
      accountType={LinkedAccountType.BANK}
      isLinkAccountModalOpen={stateOnNavigate?.isLinkAccountModalOpen}
    />
  )
}
