import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import AmplifyBusinessDropDownCTA from './AmplifyBusinessDropDownCTA'
import { BorderRadius, Color } from '@/theme/theme'
import {
  type GetOnboardingBusinessAmplifyApplicationData_currentUser_selectedOrganization_franchiseGroups as Business
} from '@/graphql/__generated__/GetOnboardingBusinessAmplifyApplicationData'
import { isAmplifyApplicationInProgress } from '@/utils/amplifyApplicationUtils'

interface AmplifyBusinessDropDownComponentProps {
  business: Business
  callToActionClick: () => void
}

export default function AmplifyBusinessDropDownComponent ({
  business,
  callToActionClick
}: AmplifyBusinessDropDownComponentProps): ReactElement {
  const isBoxClickable = isAmplifyApplicationInProgress(business.amplifyAccountApplication?.status)

  return (
    <Flex
      w='full'
      bg='white'
      p={6}
      borderRadius={BorderRadius.CARD}
      alignItems='center'
      justifyContent='space-between'
      _hover={isBoxClickable ? { cursor: 'pointer', bg: Color.GREY } : {}}
      onClick={() => { isBoxClickable && callToActionClick() }}

    >
      <Text color={Color.DARK_BLUE}>{business.name}</Text>
      <AmplifyBusinessDropDownCTA business={business} onClick={callToActionClick}/>
    </Flex>
  )
}
