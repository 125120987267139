export function nonNull<T> (array: Array<T | null | undefined>): T[] {
  return array.filter(x => x != null).map(x => x as T)
}

/**
 *
 * @param array<T>
 * @returns Given an array [5, 5, 6, 7], return [5, 6, 7]
 *
 */
export function unique<T> (array: T[]): T[] {
  return Array.from(new Set(array))
}

export function getSortInput<T> (a: T, b: T, isAscending: boolean): [T, T] {
  return isAscending ? [a, b] : [b, a]
}

export function areArraysEqual<T> (arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) return false

  // Compare each element in order
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }

  return true
}
