import React, { type ReactElement } from 'react'
import { Navigate, Outlet, useLocation, useOutletContext } from 'react-router-dom'
import SafeNavigateToErrorPage from '../server_error/SafeNavigateToErrorPage'
import { ROUTES } from '@/api/routes'
import {
  clearAltirCache,
  hasAccessToPage
} from '@/utils/authUtils'
import useAuthentication from '@/hooks/auth/useAuthentication'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { type OnboardingStep } from '@/graphql/__generated__/globalTypes'

interface DefaultGroupContext {
  userId: number | undefined
  defaultOrganizationId: string | undefined
  defaultFranchiseGroupId: number | undefined
  defaultAvailableFranchiseGroupIds: number[] | undefined
  onboardingStep: OnboardingStep | undefined
}

export default function RequireAuthenticationLayout (): ReactElement {
  const location = useLocation()
  const {
    isAuthenticated,
    isAuthQueryError,
    isAuthLoading,
    userId,
    userAuthorization,
    defaultOrganizationId,
    defaultFranchiseGroupId,
    defaultAvailableFranchiseGroupIds,
    onboardingStep
  } = useAuthentication()

  if (isAuthLoading) {
    // Display a white page while loading
    return (
      <AltirSkeleton isLoading={isAuthLoading} loadingHeight='100vh'>
        <h1>loading</h1>
      </AltirSkeleton>
    )
  }
  if (isAuthQueryError) {
    // Both Queries have resolved and at least one failing
    return <SafeNavigateToErrorPage />
  }

  if (!isAuthenticated) {
    // User is not authenticated and the page requires authentication
    clearAltirCache()
    return <Navigate to={ROUTES.LOG_IN} />
  }

  if (!hasAccessToPage(location, userAuthorization ?? [])) {
    return <Navigate to={ROUTES.NOT_AUTHORIZED} />
  }

  return (
    <Outlet
      context={{
        userId,
        defaultOrganizationId,
        defaultFranchiseGroupId,
        defaultAvailableFranchiseGroupIds,
        onboardingStep
      }}
    />
  )
}

export function useDefaultGroupContext (): DefaultGroupContext {
  return useOutletContext<DefaultGroupContext>()
}
