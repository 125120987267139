import React, { type ReactElement } from 'react'
import { Flex, Heading, useToast } from '@chakra-ui/react'
import AddStoreLocationForm from './AddStoreLocationForm'
import Button, { ButtonVariant } from '@/library/button/Button'
import { getErrorToast, getSuccessToast } from '@/utils/toastUtils'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { Color } from '@/theme/theme'

interface AddStoreLocationModalProps {
  isOpen: boolean
  onClose: () => void
  franchiseGroupId: number
}

export default function AddStoreLocationModal ({
  isOpen,
  onClose,
  franchiseGroupId
}: AddStoreLocationModalProps): ReactElement {
  const toast = useToast()

  function handleError (): void {
    toast(getErrorToast('Failed to Create Store Location'))
  }

  function onSuccess (): void {
    toast(getSuccessToast('Store Location Created'))
    onClose()
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} >
      <Flex flexDir='column' w='100%' alignItems='center' textAlign='center' gap={3}>
        <Heading color={Color.DARK_BLUE} size='lg'>Add Store Location</Heading>
        <AddStoreLocationForm
          onSuccess={onSuccess}
          onError={handleError}
          franchiseGroupId={franchiseGroupId}
        />
        <Button
          text='Cancel'
          variant={ButtonVariant.WHITE}
          onClick={onClose}
        />
      </Flex>
    </ModalComponent>
  )
}
