import React, { useState, type ReactElement } from 'react'
import { Flex, Text, useToast } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import { DELETE_USER_INVITE } from '@/graphql/mutations/DeleteUserInvite'
import { type DeleteUserInvite, type DeleteUserInviteVariables } from '@/graphql/__generated__/DeleteUserInvite'
import { DefaultModalComponent } from '@/library/modal/DefaultModalComponent'
import { Color } from '@/theme/theme'
import { type NullableUser } from '@/library/layout/user_row/UserInfoRow'
import { getSuccessToast } from '@/utils/toastUtils'
import ErrorInline from '@/library/errors/ErrorInline'
import { type ErrorWithContent } from '@/types/types'
import { ErrorCopy } from '@/utils/errorUtils'

interface DeleteUserInviteModalProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
  userToDelete: NullableUser | null
}

const DELETE_USER_TEXT = `
  You can add them again later if you change your mind. If you need to remove a current member, email us at help@altir.app
`

const DELETE_USER_HEADER = 'Unable to delete invite'

export default function DeleteUserInviteModal ({
  isOpen,
  onClose,
  onSuccess,
  userToDelete
}: DeleteUserInviteModalProps): ReactElement {
  const toast = useToast()
  const [deleteInviteSubmissionError, setDeleteInviteSubmissionError] = useState<ErrorWithContent>()

  const [
    deleteInvite,
    { loading: isDeleteInviteLoading }
  ] = useMutation<DeleteUserInvite, DeleteUserInviteVariables>(
    DELETE_USER_INVITE,
    {
      onCompleted: () => {
        onSuccess()
        onClose()
        toast(getSuccessToast('User Deleted'))
      },
      onError: (error) => {
        setDeleteInviteSubmissionError({
          customContent: { title: DELETE_USER_HEADER, subtitle: ErrorCopy.TRY_AGAIN_LATER },
          error
        })
      }
    }
  )

  function handleDeleteUserSubmission (): void {
    if (userToDelete == null || userToDelete.email == null) {
      setDeleteInviteSubmissionError({
        customContent: { title: DELETE_USER_HEADER, subtitle: ErrorCopy.TRY_AGAIN_LATER },
        error: Error()
      })
      return
    }
    void deleteInvite({ variables: { email: userToDelete.email } })
  }

  return (
    <DefaultModalComponent
      title='Are you sure you want to delete this invited user?'
      callToActionText='Delete User'
      callToActionClick={handleDeleteUserSubmission}
      callToActionColor={Color.ERROR_RED}
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isDeleteInviteLoading}
    >
      <Flex flexDir='column' gap={6}>
        <Text color={Color.DARK_BLUE}>{userToDelete?.email}</Text>
        <Text>{DELETE_USER_TEXT}</Text>
        <ErrorInline error={deleteInviteSubmissionError}/>
      </Flex>
    </DefaultModalComponent>
  )
}
