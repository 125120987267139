import { gql } from '@apollo/client'

export const DISMISS_BANK_MIGRATION = gql`
    mutation DismissBankMigration($organizationId: String!) {
        dismissBankMigration(organizationId: $organizationId) {
            id
            hasDismissedBankMigration
        }
    }
`
