import React, { type ReactElement } from 'react'
import Badge, { BadgeVariant } from './Badge'
import { TransferStatus } from '@/graphql/__generated__/globalTypes'

interface TransferStatusBadgeProps {
  status?: TransferStatus
}

export default function TransferStatusBadge ({ status }: TransferStatusBadgeProps): ReactElement {
  if (status == null) return <></>

  const badgeProps = getBadgeProps(status)
  if (badgeProps == null) return <></>

  return (
    <Badge badgeVariant={badgeProps.variant} title={badgeProps.title}/>
  )
}

function getBadgeProps (status: TransferStatus): { title: string, variant: BadgeVariant } | null {
  switch (status) {
    case TransferStatus.PENDING:
    case TransferStatus.CREATED:
    case TransferStatus.PROCESSING:
      return { title: 'Pending', variant: BadgeVariant.GREY }
    case TransferStatus.ERROR:
      return { title: 'Error', variant: BadgeVariant.RED }
    case TransferStatus.CANCELED:
      return { title: 'Canceled', variant: BadgeVariant.RED }
    case TransferStatus.CHECK_STOP_PAYMENT_PENDING:
      return { title: 'Stop Payment Pending', variant: BadgeVariant.GREY }
    case TransferStatus.CHECK_STOP_PAYMENT:
      return { title: 'Stop Payment Approved', variant: BadgeVariant.RED }
    case TransferStatus.CHECK_EXPIRED:
      return { title: 'Expired', variant: BadgeVariant.GREY }
  }

  // TODO (PJ): Add flag that will allow us to display complete statuses
  return null
}
