import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { BorderRadius, Color } from '@/theme/theme'

interface SuccessRowActionProps {
  icon: ReactElement
  title: string
}

export default function SuccessRowAction ({ icon, title }: SuccessRowActionProps): ReactElement {
  const iconWithProps = React.cloneElement(icon, { color: Color.DARK_BLUE })
  return (
    <Flex
      w='100%'
      borderRadius={BorderRadius.CARD}
      alignItems='center'
      p={6}
      gap={6}
      bg={Color.GREY}
    >
      {iconWithProps}
      <Text color={Color.DARK_BLUE}>{title}</Text>
    </Flex>
  )
}
