import {
  Divider,
  Flex
} from '@chakra-ui/react'
import React, {
  type ReactElement
} from 'react'
import { motion } from 'framer-motion'

import CarrotComponent from '../utility/toggle/CarrotComponent'
import {
  BorderRadius,
  Color,
  IconSize
} from '@/theme/theme'
import { type Child, type ComponentChild } from '@/types/react/types'
import { IconFacing } from '@/types/types'

const MotionFlex = motion(Flex)

export enum AnimatedDropDownRowVariant {
  LIGHT,
  DARK
}

interface AnimatedDropDownRowProps {
  isSelected: boolean
  onClick: () => void
  isFixed?: boolean
  children: [ReactElement, ReactElement]

  // Styles
  isRowClickable?: boolean // Determines whether entire row is clickable, or just carrot
  variant?: AnimatedDropDownRowVariant
}

/**
 * Animated Row component with carrot toggle that allows for additional content
 * to be displayed.
 *
 * Usage:
 *
 * <AnimatedDropDownRow>
 *    <AnimatedDropDownRowRowContent>
 *      // Your row content
 *    </AnimatedDropDownRowRowContent>
 *    <AnimatedDropDownRowExpandedContent>
 *      // Your expanded content
 *    </AnimatedDropDownRowExpandedContent>
 * </AnimatedDropDownRow>
 */
export default function AnimatedDropDownRow (
  {
    isSelected,
    onClick,
    children,
    isFixed = false,
    isRowClickable = false,
    variant = AnimatedDropDownRowVariant.LIGHT
  }: AnimatedDropDownRowProps
): ReactElement {
  const { rowContent, expandedContent } = getComponents(children)

  const {
    backgroundColor,
    hoverBackgroundColor,
    borderRadius,
    py: rowPy
  } = getStyles(variant, isRowClickable)

  return (
    <MotionFlex
      as={motion.div}
      flexDirection='column'
      bg={backgroundColor}
      animate={isSelected ? 'selected' : 'notSelected'}
      transition={{
        ease: 'easeInOut',
        borderRadius: {
          ease: 'linear'
        },
        backgroundColor: {
          duration: 0.2
        }
      }}
      variants={{
        selected: { borderRadius: BorderRadius.CARD, transition: { duration: 0.3 } },
        notSelected: { borderRadius, transition: { delay: 0.1, duration: 0.3 } }
      }}
    >
      <Flex
        w='100%'
        alignItems='center'
        justifyContent='space-between'
        px={6}
        py={rowPy}
        gap={6}
        onClick={() => { isRowClickable && onClick() }}
        _hover={isRowClickable ? { cursor: 'pointer' } : {}}
      >
        <Flex w='100%'>
          {rowContent}
        </Flex>
        {
          !isFixed &&
          <Flex
            onClick={onClick}
            _hover={{ bg: hoverBackgroundColor, cursor: 'pointer' }}
            borderRadius='100%'
            p={2}
          >
            <CarrotComponent
              facing={isSelected ? IconFacing.DOWN : IconFacing.RIGHT}
              size={IconSize.SMALL}
              color={Color.DARK_GREY}
            />
          </Flex>
        }
      </Flex>
      {isSelected && <Flex px={6}><Divider/></Flex>}
      {
        isSelected &&
        <Flex
          w='100%'
          justifyContent='center'
          p={6}
        >
          {expandedContent}
        </Flex>
      }
    </MotionFlex>
  )
}

function getStyles (
  variant: AnimatedDropDownRowVariant,
  isRowClickable: boolean
): { backgroundColor: Color, hoverBackgroundColor: Color, py: number, borderRadius: BorderRadius } {
  return {
    ...getRowStyles(isRowClickable),
    ...getColorStyles(variant)
  }
}

function getColorStyles (variant: AnimatedDropDownRowVariant): { backgroundColor: Color, hoverBackgroundColor: Color } {
  switch (variant) {
    case AnimatedDropDownRowVariant.DARK:
      return { backgroundColor: Color.LIGHT_GREY, hoverBackgroundColor: Color.GREY }
    case AnimatedDropDownRowVariant.LIGHT:
      return { backgroundColor: Color.WHITE, hoverBackgroundColor: Color.GREY }
  }
}

function getRowStyles (isRowClickable: boolean): { py: number, borderRadius: BorderRadius } {
  return isRowClickable ? { py: 2, borderRadius: BorderRadius.BUTTON } : { py: 4, borderRadius: BorderRadius.CARD }
}

function getComponents (children: [ReactElement, ReactElement]): { rowContent: Child, expandedContent: Child } {
  const childArray = React.Children.toArray(children)
  const rowContent = childArray.find(
    (child) => React.isValidElement(child) && child.type === AnimatedDropDownRowRowContent
  )

  const expandedContent = childArray.find(
    (child) => React.isValidElement(child) && child.type === AnimatedDropDownRowExpandedContent
  )
  return { rowContent, expandedContent }
}

export const AnimatedDropDownRowRowContent: React.FC<ComponentChild> = ({ children }: ComponentChild) => {
  return (<>{children}</>)
}

export const AnimatedDropDownRowExpandedContent: React.FC<ComponentChild> = ({ children }: ComponentChild) => {
  return (<>{children}</>)
}
