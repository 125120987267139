import { type UseToastOptions } from '@chakra-ui/react'

export function getSuccessToast (title: string): UseToastOptions {
  return {
    title,
    duration: 4000,
    status: 'success',
    colorScheme: 'successToast'
  }
}

export function getActionToast (title: string): UseToastOptions {
  return {
    title,
    duration: 2000,
    status: 'success',
    colorScheme: 'actionToast'
  }
}

export function getErrorToast (title: string): UseToastOptions {
  return {
    title,
    duration: 4000,
    status: 'error',
    colorScheme: 'errorToast'
  }
}
