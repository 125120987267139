import { gql } from '@apollo/client'

export const CREATE_ACCOUNT_INFO_REVISION_REQUEST = gql`
    mutation CreateAccountInfoRevisionRequest(
        $franchiseGroupId: Int!,
        $contactEmail: String!,
        $fieldToChange: AccountInfoUpdateField!,
        $newFieldValue: String!,
        $objectId: String!,
        $objectType: RevisionRequestType!,
        $tin: String!,
    ) {
        createAccountInfoRevisionRequest(
            franchiseGroupId: $franchiseGroupId,
            contactEmail: $contactEmail,
            fieldToChange: $fieldToChange,
            newFieldValue: $newFieldValue,
            objectId: $objectId,
            objectType: $objectType,
            tin: $tin
        ) 
    }
`
