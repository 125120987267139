import { Flex, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { BorderRadius, Color } from '../theme/theme'

interface TextBadgeComponentProps {
  text: string
  backgroundColor?: string
}

export default function TextBadgeComponent (
  { text, backgroundColor = Color.DARK_GREY }: TextBadgeComponentProps
): ReactElement {
  return (
    <Flex
      justifyContent='center'
      alignItems='center'
      h={6}
      px={2}
      bg={backgroundColor}
      borderRadius={BorderRadius.BUTTON}
    >
      <Text noOfLines={1} color={Color.WHITE} fontSize='xs'>{text}</Text>
    </Flex>
  )
}
