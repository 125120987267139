import { gql } from '@apollo/client'

export const CREATE_EXPORT_JOB = gql`
    mutation CreateExportJob($transactionExportJobInput: TransactionExportJobInput!) {
        export(transactionExportJobInput: $transactionExportJobInput) {
            fileUrl
            id
            status
        }
    }
`
