import React, { useState, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import WireDetailsSwitchComponent from './WireDetailsSwitchComponent'
import { FormInput } from '@/library/form/text/FormInput'
import AddressForm, { type AddressInput } from '@/library/form/address/AddressForm'
import { Color } from '@/theme/theme'
import Button from '@/library/button/Button'
import { fieldRequiredMessage } from '@/utils/stringUtils'
import FormNumberInput from '@/library/form/number/FormNumberInput'

export interface AddWireInfoFormData {
  wireAccountNumber?: string
  wireRoutingNumber?: string
  bankAddress?: AddressInput
  address?: AddressInput
  bankName?: string
}

interface AddWireInfoFormProps {
  isSubmissionLoading: boolean
  onSubmit: (formData: AddWireInfoFormData) => void
  inlineErrorState: AddWireInfoFormData
  achAccountNumber: string
  achRoutingNumber: string
}

export default function AddWireInfoForm ({
  isSubmissionLoading,
  onSubmit,
  inlineErrorState,
  achAccountNumber,
  achRoutingNumber
}: AddWireInfoFormProps): ReactElement {
  const [formData, setFormData] = useState<AddWireInfoFormData>({})

  function onChange (event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target
    updateForm(name, value)
  }

  function updateForm (name: string, value: string): void {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  function updateAddress (address: AddressInput | null): void {
    setFormData((prevFormData) => ({
      ...prevFormData,
      address: address ?? undefined
    }))
  }

  function updateBankAddress (address: AddressInput | null): void {
    setFormData((prevFormData) => ({
      ...prevFormData,
      bankAddress: address ?? undefined
    }))
  }

  return (
    <Flex flexDir='column' gap={6} w='100%'>
      <WireDetailsSwitchComponent
        onSwitch={(accountValue, routingValue) => {
          updateForm('wireAccountNumber', accountValue)
          updateForm('wireRoutingNumber', routingValue)
        }}
        achAccountNumber={achAccountNumber}
        achRoutingNumber={achRoutingNumber}
      />
      <FormNumberInput
        fieldName='wireAccountNumber'
        label='Wire Account Number'
        value={formData.wireAccountNumber}
        fieldError={inlineErrorState.wireAccountNumber}
        onChange={value => { updateForm('wireAccountNumber', value) }}
        placeholder='i.e. 000000000'
        backgroundColor={Color.WHITE}
        isRequired
      />
      <FormNumberInput
        fieldName='wireRoutingNumber'
        label='Wire Routing Number'
        value={formData.wireRoutingNumber}
        fieldError={inlineErrorState.wireRoutingNumber}
        onChange={value => { updateForm('wireRoutingNumber', value) }}
        placeholder='i.e. 000000000'
        backgroundColor={Color.WHITE}
        isRequired
      />
      <AddressForm
        formName='Bank Address'
        subtext='Physical Address of the recipient’s banking institution'
        onUpdate={updateBankAddress}
        backgroundColor={Color.WHITE}
        fieldError={inlineErrorState.bankAddress != null ? fieldRequiredMessage('Bank address') : undefined}
        isRequired
      />
      <FormInput
        fieldName='bankName'
        label='Institution'
        value={formData.bankName ?? ''}
        fieldError={inlineErrorState.bankName}
        onChange={onChange}
        placeholder='i.e. Bank of America'
        isRequired
      />
      <AddressForm
        formName='Recipient Address'
        subtext='Physical address of the account holder'
        onUpdate={updateAddress}
        backgroundColor={Color.WHITE}
        fieldError={inlineErrorState.address != null ? fieldRequiredMessage('Address') : undefined}
        isRequired
      />
      <Button
        text='Continue'
        onClick={() => { onSubmit(formData) }}
        isLoading={isSubmissionLoading}
      />
    </Flex>
  )
}
