import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import { ROUTES } from '@/api/routes'
import { type OnboardingCallToAction } from '@/library/nav/onboarding/OnboardingStatusBar'
import {
  type UpdateOrganizationOnboardingState,
  type UpdateOrganizationOnboardingStateVariables
} from '@/graphql/__generated__/UpdateOrganizationOnboardingState'
import { UPDATE_ORGANIZATION_ONBOARDING_STATE } from '@/graphql/mutations/onboarding/UpdateOrganizationOnboardingState'
import { getErrorToast } from '@/utils/toastUtils'
import { type GetOnboardingOrganization } from '@/graphql/__generated__/GetOnboardingOrganization'
import { GET_ONBOARDING_ORGANIZATION } from '@/graphql/queries/onboarding/GetOnboardingOrganization'

export function useSkipAmplifyApplicationCallToAction (): OnboardingCallToAction {
  const navigate = useNavigate()
  const toast = useToast()
  const toastContent = getErrorToast(
    'Unable to proceed. Your applications have been saved. Please try again or contact support.'
  )
  const {
    data,
    loading
  } = useQuery<GetOnboardingOrganization>(GET_ONBOARDING_ORGANIZATION)
  const [
    completeAmplifyOnboarding,
    { loading: completeAmplifyOnboardingLoading }
  ] = useMutation<UpdateOrganizationOnboardingState, UpdateOrganizationOnboardingStateVariables>(
    UPDATE_ORGANIZATION_ONBOARDING_STATE,
    {
      // TODO (PJ): Pass organizationId once we receive from auth context
      onCompleted: () => { navigate(ROUTES.V2_SIGN_UP_PRO) },
      onError: () => {
        toast(toastContent)
      }
    }
  )

  return {
    callToActionText: 'Finish this later',
    callToActionOnClick: () => {
      const organizationId = data?.currentUser?.selectedOrganization?.id
      if (organizationId == null) {
        toast(toastContent)
        return
      }
      void completeAmplifyOnboarding({ variables: { organizationId, isAmplifyOnboardingComplete: true } })
    },
    isLoading: completeAmplifyOnboardingLoading || loading
  }
}
