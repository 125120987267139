import React, { type ReactElement } from 'react'
import { Center, Heading } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import OnboardingPage from '@/library/page/OnboardingPage'
import Button, { ButtonVariant } from '@/library/button/Button'
import LeftArrowIcon from '@/library/icons/LeftArrowIcon'
import { ContainerWidth, IconSize } from '@/theme/theme'
import { ROUTES } from '@/api/routes'
import {
  type GetOnboardingStoreLocationData,
  type GetOnboardingStoreLocationDataVariables
} from '@/graphql/__generated__/GetOnboardingStoreLocationData'
import { GET_ONBOARDING_STORE_LOCATION_DATA } from '@/graphql/queries/onboarding/GetOnboardingStoreLocationData'
import AddStoreLocationForm from '@/components/pages/settings/components/store_locations/AddStoreLocationForm'

export default function OnboardingStoreLocationCreatePage (): ReactElement {
  const { BUSINESS_ID } = useParams()
  const navigate = useNavigate()

  const {
    data,
    loading,
    error
  } = useQuery<GetOnboardingStoreLocationData, GetOnboardingStoreLocationDataVariables>(
    GET_ONBOARDING_STORE_LOCATION_DATA,
    { variables: { franchiseGroupId: Number(BUSINESS_ID) } }
  )
  return (
    <OnboardingPage maxWidth={ContainerWidth.SMALL} isLoading={loading} error={error}>
      <Button
        text='Back'
        beforeIcon={<LeftArrowIcon size={IconSize.SMALL}/>}
        variant={ButtonVariant.GREY}
        onClick={() => { navigate(ROUTES.V2_SIGN_UP_BUSINESS) }}
        width='auto'
      />
      <Center flexDir='column' gap={1} w='100%'>
        <Heading size='sm'>{data?.currentUser?.franchiseGroup?.name}</Heading>
        <Heading>Add a Store Location</Heading>
      </Center>
      <AddStoreLocationForm
        franchiseGroupId={Number(data?.currentUser?.franchiseGroup?.id)}
        submitButtonText='Continue'
        onSuccess={() => { navigate(ROUTES.V2_SIGN_UP_BUSINESS) }}
      />
    </OnboardingPage>
  )
}
