import React, { type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import MoreInfoTooltip from '../utility/MoreInfoTooltip'
import { Color } from '@/theme/theme'

interface HeaderStatisticProps {
  description: string
  descriptionInfoText?: string
  statistic: string

  isDisabled?: boolean
}

export default function HeaderStatistic ({
  description,
  descriptionInfoText,
  statistic,
  isDisabled = false
}: HeaderStatisticProps): ReactElement {
  return (
    <Flex flexDir='column' gap={3}>
      <Flex gap={1} alignItems='center'>
        <Text>{description}</Text>
        {descriptionInfoText != null && <MoreInfoTooltip tooltipText={descriptionInfoText}/>}
      </Flex>
      <Heading
        size='2xl'
        color={isDisabled ? Color.DARK_GREY : Color.DARK_BLUE}
      >
        {statistic}
      </Heading>
    </Flex>
  )
}
