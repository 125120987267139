import { Flex, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { type MenuSelectionItem, type MenuSelections } from '../PopoverMenuHierarchicalMultiSelector'
import CircleCheckSolidIcon from '@/library/icons/CircleCheckSolidIcon'
import CircleEmptyIcon from '@/library/icons/CircleEmptyIcon'
import { BorderRadius, Color } from '@/theme/theme'

interface MenuHeadingMultiSelectProps {
  title: string
  selectedItems: MenuSelections
  menuOptions: MenuSelectionItem[]
  updateSelection: (value: string | string[], menuOptions: MenuSelectionItem[]) => void
}

export default function MenuHeadingMultiSelect (
  { title, selectedItems, menuOptions, updateSelection }:
  MenuHeadingMultiSelectProps
): ReactElement {
  const allIds = menuOptions.map(item => item.id)
  const allSelected = menuOptions.every(item => selectedItems.includes(item.id))
  const selection = allSelected ? [] : allIds
  return (
    <Flex
      my={2}
      gap={4}
      m={0}
      py={3}
      pl={4}
      as='button'
      bg={Color.LIGHT_GREY}
      _hover={{ bg: Color.GREY }}
      w='100%'
      borderRadius={BorderRadius.BAR}
      alignItems='center'
      onClick={() => { updateSelection(selection, menuOptions) }}
    >
      {allSelected
        ? <CircleCheckSolidIcon color={Color.BRIGHT_BLUE} />
        : <CircleEmptyIcon color={Color.DARK_GREY} />}
      <Text color={Color.DARK_BLUE}>{title}</Text>
    </Flex>
  )
}
