import React, { type ReactElement } from 'react'
import { GridItem, Text } from '@chakra-ui/react'
import { Color } from '@/theme/theme'
import { getCurrencyFormatted } from '@/utils/stringUtils'

export default function TransferFeeLineItem ({ transferFee }: { transferFee: number }): ReactElement {
  const isFree = transferFee === 0
  return (
    <GridItem justifySelf='end'>
      <Text color={isFree ? Color.SUCCESS_GREEN : Color.DARK_GREY}>
        {isFree ? 'Free' : getCurrencyFormatted(transferFee)}
      </Text>
    </GridItem>
  )
}
