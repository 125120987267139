import React, { type ReactElement } from 'react'
import {
  Center,
  Flex,
  Heading,
  Text
} from '@chakra-ui/react'
import TransferTableComponent from './TransferTableComponent'
import TransferLinkButton from '@/library/button/TransferLinkButton'
import {
  Color
} from '@/theme/theme'
import {
  type TransferFragment as Transfer
} from '@/graphql/__generated__/TransferFragment'
import BusinessSelectorComponent from '@/library/state/BusinessSelectorComponent'

export interface TransferHistoryComponentProps {
  transfers: Transfer[]
}

export default function TransferHistoryComponent (
  { transfers }: TransferHistoryComponentProps
): ReactElement {
  const hasExistingTransfers = transfers.length > 0

  return (
    <Center flexDirection='column' width='100%' gap={8} w='100%'>
      <Flex alignItems='center' justifyContent='space-between' w='100%'>
        <Flex flexDir='column' gap={2}>
          <Heading size='md' color={Color.DARK_BLUE}>Transfer History</Heading>
          <Text>In or out of your amplify account</Text>
        </Flex>
        <Flex gap={4}>
          {
            hasExistingTransfers && <TransferLinkButton/>
          }
          <BusinessSelectorComponent businessFilterParams={{ hasAmplify: true }} requireMultipleBusinesses={true}/>
        </Flex>
      </Flex>
      <TransferTableComponent transfers={transfers}/>
    </Center>
  )
}
