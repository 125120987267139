import React, { type ReactNode, type ReactElement } from 'react'
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import ModalCloseHeader from './ModalCloseHeader'
import {
  BorderRadius,
  Color,
  type ModalHeight
} from '../theme/theme'

export interface ModalComponentProps {
  isOpen: boolean
  onClose: () => void
  children?: ReactNode
  headerBanner?: ReactElement

  // Styles
  height?: ModalHeight
  size?: string
  trapFocus?: boolean
}

export function ModalComponent (
  {
    isOpen,
    onClose,
    children,
    height,
    trapFocus = true,
    size = 'md',
    headerBanner
  }: ModalComponentProps
): ReactElement {
  const paddingX = 8
  const hasHeaderBanner = headerBanner != null
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      preserveScrollBarGap
      trapFocus={trapFocus}
      size={size}
      scrollBehavior='inside'
    >
      <ModalOverlay />
      <ModalContent
        flexDirection='column'
        borderRadius={BorderRadius.CARD}
        bg={Color.LIGHT_GREY}
        alignItems='center'
        justifyContent='center'
        width='100%'
        gap={hasHeaderBanner ? 10 : 2}
      >
        <Flex
          w='100%'
          borderTopRadius={BorderRadius.CARD}
          px={paddingX}
          pt={4}
          pb={hasHeaderBanner ? 4 : 0}
          backgroundColor={hasHeaderBanner ? Color.DARK_BLUE : undefined}
        >
          <ModalCloseHeader onClose={onClose} headerContent={headerBanner}/>
        </Flex>
        <ModalBody height={height} w='100%' px={paddingX} pb={8}>
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
