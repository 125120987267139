import React, { useState, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import InviteUserModal from '../permissions/InviteUserModal'
import DeleteUserInviteModal from '../permissions/DeleteUserInviteModal'
import UserInfoRow, { UserRoleContext, type NullableUser } from '@/library/layout/user_row/UserInfoRow'
import Button, { ButtonVariant } from '@/library/button/Button'
import PlusIcon from '@/library/icons/PlusIcon'
import { IconSize } from '@/theme/theme'
import { INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY } from '@/utils/authUtils'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import {
  type GetOrganizationSettings_currentUser_selectedOrganization_franchiseGroups as Business
} from '@/graphql/__generated__/GetOrganizationSettings'

interface OrganizationTeamSettingsProps {
  users: NullableUser[]
  invitedUsers: NullableUser[]
  organizationBusinesses: Business[]
  refetchSettings: () => void
  canManageUsers: boolean
  canManageInvites: boolean
}

export default function OrganizationTeamSettings ({
  users,
  invitedUsers,
  canManageUsers,
  canManageInvites,
  organizationBusinesses,
  refetchSettings
}: OrganizationTeamSettingsProps): ReactElement {
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)

  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false)

  const [isDeleteInvitedUserModalOpen, setIsDeleteInvitedUserModalOpen] = useState(false)
  const [selectedInvitedUserForDeletion, setSelectedInvitedUserForDeletion] = useState<NullableUser | null>(null)

  function handleDeleteUserClick (invitedUser: NullableUser): void {
    setSelectedInvitedUserForDeletion(invitedUser)
    setIsDeleteInvitedUserModalOpen(true)
  }

  const allUsers = [...users, ...invitedUsers]

  return (
    <Flex flexDir='column'>
      <Flex flexDir='column' gap={2}>
        {allUsers.map((user) => {
          return (
            <UserInfoRow
              key={user.id}
              user={user}
              context={UserRoleContext.ORGANIZATION}
              canEditUsers={canManageUsers}
              canEditInvites={canManageInvites}
              onUserRoleUpdate={refetchSettings}
              onUserInviteDelete={() => { handleDeleteUserClick(user) }}
            />
          )
        })}
      </Flex>
      <Flex w='100%' justifyContent='end' pt={2}>
        <Button
          text='Invite a New User'
          beforeIcon={<PlusIcon size={IconSize.SMALL}/>}
          variant={ButtonVariant.GREY}
          width='auto'
          onClick={() => { setIsInviteUserModalOpen(true) }}
          isDisabled={!canManageInvites}
          toolTipText={!canManageInvites ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
        />
      </Flex>
      <DeleteUserInviteModal
        isOpen={isDeleteInvitedUserModalOpen}
        onClose={() => { setIsDeleteInvitedUserModalOpen(false) }}
        onSuccess={refetchSettings}
        userToDelete={selectedInvitedUserForDeletion}
      />
      <InviteUserModal
        isOpen={isInviteUserModalOpen}
        onClose={() => { setIsInviteUserModalOpen(false) }}
        onSuccess={refetchSettings}
        organizationId={selectedOrganizationId}
        franchiseGroupId={null}
        organizationBusinesses={organizationBusinesses}
        isOrganizationsEnabled={true}
      />
    </Flex>
  )
}
