import React, { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { GRASSHOPPER_MIGRATION_CUTOFF_DATE_FORMATTED } from './utils'
import { BankMigrationStatus } from '@/graphql/__generated__/globalTypes'
import { isMigrationInProgress } from '@/utils/bankMigrationUtils'
import Banner, { BannerVariant } from '@/library/banner/Banner'
import TreasuryIcon from '@/library/icons/TreasuryIcon'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { sendSupportEmail } from '@/library/nav/help_center/utils'
import { ROUTES } from '@/api/routes'

const ACCOUNT_UPGRADE_IN_PROGRESS_SUBTITLE = `
    We are in the process of upgrading your account. We will email you in 1-2 days once all of your accounts have been successfully upgraded. Functionality may be limited in the meantime. View our FAQs to learn more.
`

const ACCOUNT_UPGRADE_CTA_SUBTITLE = `
    We are moving to a new banking partner, Grasshopper Bank. Upgrade your account by ${GRASSHOPPER_MIGRATION_CUTOFF_DATE_FORMATTED} to avoid account closure.
`

interface GrasshopperMigrationBannerContentProps {
  status: BankMigrationStatus | null
}

export default function GrasshopperMigrationBannerContent ({
  status
}: GrasshopperMigrationBannerContentProps): ReactElement {
  const navigate = useNavigate()

  if (status === BankMigrationStatus.COMPLETE) {
    return <></>
  }

  if (isMigrationInProgress(status)) {
    return (
      <Banner
        title='Account Upgrade in Progress'
        subtitle={ACCOUNT_UPGRADE_IN_PROGRESS_SUBTITLE}
        beforeIcon={<TreasuryIcon/>}
        variant={BannerVariant.PRIMARY}
        callToActionComponent={
          <Button
            text='Contact Us'
            size={ButtonSize.SMALL}
            afterIcon={<RightArrowIcon/>}
            onClick={sendSupportEmail}
          />
          }
      />
    )
  }

  return (
    <Banner
      title='Upgrade Your Account'
      subtitle={ACCOUNT_UPGRADE_CTA_SUBTITLE}
      beforeIcon={<TreasuryIcon/>}
      variant={BannerVariant.DARK_BLUE}
      callToActionComponent={
        <Button
          text='Get Started'
          variant={ButtonVariant.WHITE}
          size={ButtonSize.SMALL}
          afterIcon={<RightArrowIcon/>}
          onClick={() => { navigate(ROUTES.ACCOUNT_UPGRADE) }}
        />
          }
    />
  )
}
