import { gql } from '@apollo/client'
import { FINANCIAL_ACCOUNT_FRAGMENT } from '../fragments/financial_accounts/FinancialAccountFragment'
import { TRANSFER_FRAGMENT } from '../fragments/TransferFragment'

export const GET_RECENT_RECIPIENTS_PAGE = gql`
    ${TRANSFER_FRAGMENT}
    ${FINANCIAL_ACCOUNT_FRAGMENT}
    query GetRecentRecipientsPage(
        $counterpartyTypes: [CounterpartyType!], 
        $franchiseGroupId: Int!,
        $transferLimit: Int 
    ) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                id
                amplifyAccount {
                    ...FinancialAccountFragment
                }
                counterparties(counterpartyTypes: $counterpartyTypes) {
                    id
                    counterpartyType
                    isOwnershipVerified
                    createdAt
                    name
                    achAccountNumber
                    transfers(limit: $transferLimit) {
                        ...TransferFragment
                    }
                }
            }
        }
    }
`

export const GET_RECENT_RECIPIENTS = gql`
    query GetRecentRecipients(
        $counterpartyTypes: [CounterpartyType!], 
        $franchiseGroupId: Int!,
    ) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                id
                amplifyAccount {
                    amplifyAccount {
                        id
                    }
                }
                counterparties(counterpartyTypes: $counterpartyTypes) {
                    id
                    isOwnershipVerified
                    nameOnAccount
                    name
                }
            }
        }
    }
`

export const GET_COUNTERPARTIES_PENDING_VERIFICATION = gql`
    query GetCounterpartiesPendingVerification(
        $counterpartyTypes: [CounterpartyType!], 
        $isOwnershipVerified: Boolean,
        $franchiseGroupId: Int!
    ) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                id
                amplifyAccount {
                    amplifyAccount {
                        id
                    }
                }
                counterparties(counterpartyTypes: $counterpartyTypes, isOwnershipVerified: $isOwnershipVerified) {
                    id
                    isOwnershipVerified
                    nameOnAccount
                    name
                }
            }
        }
    }
`
