import { DropDownVariant } from './types'
import { Color } from '@/theme/theme'

export function getHeaderTextColor (variant: DropDownVariant, isDisabled: boolean): string {
  if (isDisabled) return Color.DARK_GREY
  switch (variant) {
    case DropDownVariant.DARK:
      return Color.GREY_BLUE
    case DropDownVariant.DEFAULT:
      return Color.DARK_BLUE
    case DropDownVariant.RED:
      return Color.ERROR_RED
    case DropDownVariant.GRAY:
      return Color.DARK_GREY
  }
}

export function getBackgroundColor (variant: DropDownVariant, isDisabled: boolean): string {
  if (isDisabled) return Color.GREY
  return variant === DropDownVariant.DARK ? Color.GREY : Color.WHITE
}

export function getBackgroundHoverColor (variant: DropDownVariant, isDisabled: boolean): string {
  if (isDisabled) return Color.GREY
  return variant === DropDownVariant.DARK ? '#D2D6DF' : Color.GREY
}
