import { gql } from '@apollo/client'

export const CREATE_FRANCHISE_GROUP = gql`
    mutation CreateFranchiseGroup(
        $organizationId: String!,
        $franchiseBrand: String!,
        $franchiseGroupName: String!,
        $numberOfLocations: Int!,
        $memberIds: [Int!]!
    ) {
        createFranchiseGroup(
            organizationId: $organizationId,
            franchiseBrand: $franchiseBrand, 
            name: $franchiseGroupName
            numberOfLocations: $numberOfLocations
            memberIds: $memberIds
        ) {
            id
            name
        }
    }
`
