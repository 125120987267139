import React, { useState, type ReactElement } from 'react'
import { Center, Heading, Text, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import OnboardingPage from '@/library/page/OnboardingPage'
import Button, { ButtonVariant } from '@/library/button/Button'
import { BorderRadius, Color, ContainerWidth } from '@/theme/theme'
import ContactUsModal from '@/library/nav/help_center/ContactUsModal'
import { FormInput } from '@/library/form/text/FormInput'
import { ROUTES } from '@/api/routes'
import { type GetOnboardingUser } from '@/graphql/__generated__/GetOnboardingUser'
import { GET_ONBOARDING_USER } from '@/graphql/queries/onboarding/GetOnboardingUser'
import { type CreateOrganization, type CreateOrganizationVariables } from '@/graphql/__generated__/CreateOrganization'
import { CREATE_ORGANIZATION } from '@/graphql/mutations/onboarding/CreateOrganization'
import { isEmpty } from '@/utils/stringUtils'
import ErrorInline from '@/library/errors/ErrorInline'
import { isUserAlreadyHasOrganizationError } from '@/utils/errorUtils'
import { type ErrorWithContent } from '@/types/types'
import { getErrorToast } from '@/utils/toastUtils'

export default function OnboardingOrganizationCreatePage (): ReactElement {
  const navigate = useNavigate()
  const toast = useToast()
  const [formError, setFormError] = useState<ErrorWithContent | undefined>()
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false)
  const [organizationName, setOrganizationName] = useState('')

  const { data, loading, error } = useQuery<GetOnboardingUser>(GET_ONBOARDING_USER, { fetchPolicy: 'no-cache' })
  const [
    createOrganization,
    {
      loading: isCreateOrganizationLoading
    }
  ] = useMutation<CreateOrganization, CreateOrganizationVariables>(
    CREATE_ORGANIZATION,
    {
      onCompleted: () => {
        navigate(ROUTES.V2_SIGN_UP_BUSINESS)
      },
      onError: (error) => {
        if (isUserAlreadyHasOrganizationError(error)) {
          setFormError({
            customContent: {
              title: 'Unable to create new organization',
              subtitle: 'You are already associated with an existing organization.'
            },
            error
          })
          toast(getErrorToast('Unable to create organization. You are already associated with an existing one.'))
          navigate(ROUTES.V2_SIGN_UP)
        } else {
          setFormError({
            error
          })
        }
      }
    }
  )

  function onSubmit (): void {
    const userId = data?.currentUser?.id
    if (userId == null) {
      setFormError({
        error: Error('User id is null.')
      })
      return
    }
    if (isEmpty(organizationName)) {
      setFormError({
        error: Error('Organization name is required')
      })
      return
    }
    void createOrganization({ variables: { ownerId: userId, name: organizationName } })
  }

  return (
    <OnboardingPage maxWidth={ContainerWidth.SMALL} isLoading={loading} error={error}>
      <Center flexDir='column' gap={8}>
        <Center flexDir='column' textAlign='center' gap={1}>
          <Heading>Looks like you are new here!</Heading>
          <Text>Create an organization below.</Text>
        </Center>
        <Center flexDir='column' width='100%' gap={4}>
          <FormInput
            label='Name of Organization'
            fieldName='organizationName'
            placeholder='i.e. Organization Inc.'
            value={organizationName}
            onChange={(event) => { setOrganizationName(event.target.value) }}
            isRequired
          />
          <Button
            text='Continue'
            isLoading={isCreateOrganizationLoading}
            isDisabled={isEmpty(organizationName)}
            onClick={onSubmit}
          />
          <ErrorInline error={formError}/>
        </Center>
        <Center
          flexDir='column'
          w='100%'
          bg={Color.GREY}
          borderRadius={BorderRadius.CARD}
          p={6}
          gap={4}
        >
          <Center flexDir='column' gap={1} textAlign='center'>
            <Heading size='md'>Is this a mistake?</Heading>
            <Text>
              Is this a mistake?
              If you are already associated with an organization on Altir, contact us and we will sort it out.
            </Text>
          </Center>
          <Button
            text='Contact Us'
            width='auto'
            variant={ButtonVariant.WHITE_GREY_BACKGROUND}
            onClick={() => { setIsContactUsModalOpen(true) }}
          />
          <ContactUsModal isOpen={isContactUsModalOpen} setIsOpen={(isOpen) => { setIsContactUsModalOpen(isOpen) }}/>
        </Center>
      </Center>
    </OnboardingPage>
  )
}
