import * as d3 from 'd3'

export function createSvgCanvas (
  graphRef: React.MutableRefObject<null>,
  height: number,
  width: number,
  paddingHorizontal: number,
  paddingVertical: number,
  paddingTop?: number,
  horizontalShift = 0
): d3.Selection<SVGGElement, unknown, null, undefined> {
  const extendedWidth = width + paddingHorizontal
  const extendedHeight = height + paddingVertical * 2 + (paddingTop ?? 0)

  // Clear previous rendering
  d3.select(graphRef.current).selectAll('*').remove()

  // Create a new SVG element
  const svg = d3
    .select(graphRef.current)
    .append('svg')
    .attr('width', extendedWidth)
    .attr('height', extendedHeight)
    .append('g')
    .attr('transform', `translate(${paddingHorizontal / 2 + horizontalShift}, ${(paddingVertical + (paddingTop ?? 0))})`)
  return svg
}

export function addGradientShading (
  svgCanvas: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: (value: d3.NumberValue) => number,
  yScale: (value: d3.NumberValue) => number,
  data: Array<[number, number]>,
  chartHeight: number,
  color: string,
  className: string,
  offset = 0
): void {
  // Add gradient shading under line
  const gradientArea = d3
    .area()
    .x((d, i) => xScale(i + offset))
    .y0(chartHeight)
    .y1((d, i) => yScale(d[1]))
  const gradient = svgCanvas
    .append('defs')
    .append('linearGradient')
    .attr('id', className)
    .attr('gradientTransform', 'rotate(90)')
  gradient
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', color)
    .attr('stop-opacity', 0.1) // Adjust the opacity as needed
  gradient
    .append('stop')
    .attr('offset', '70%')
    .attr('stop-color', 'white')
    .attr('stop-opacity', 0)
  svgCanvas
    .append('path')
    .datum(data)
    .attr('fill', `url(#${className})`) // Use the gradient as the fill
    .attr('d', gradientArea)
}

interface CreatePointArgs {
  svgCanvas: d3.Selection<SVGGElement, unknown, null, undefined>
  xScale: (value: d3.NumberValue) => number
  yScale: (value: d3.NumberValue) => number
  xVal: number
  yVal: number
  chartHeight: number
  color: string

}
export function createPoint ({
  svgCanvas,
  xScale,
  yScale,
  xVal,
  yVal,
  color
}: CreatePointArgs): void {
  svgCanvas.append('circle')
    .attr('cx', xScale(xVal))
    .attr('cy', yScale(yVal))
    .attr('r', 7) // Adjust the radius of the dot
    .attr('fill', color)
}

export function selectEvenlySpacedIndexes <T> (
  array: T[],
  numberTicks: number
): number[] {
  const length = array.length
  const step = Math.floor(length / numberTicks)
  const indexes = []

  for (let i = 0; i < 6; i++) {
    const index = i * step
    indexes.push(index)
  }

  return indexes
}

export function selectEvenlySpacedValues<T> (
  array: T[],
  numberTicks: number
): T[] {
  const length = array.length
  const step = Math.floor(length / numberTicks)
  const values = []

  for (let i = 0; i < 6; i++) {
    const index = i * step
    const value = array[index]
    if (value != null) {
      values.push(value)
    }
  }
  return values
}

export function getFormattedCurrentDate (): string {
  const date = new Date()
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)}`
}

export function skipTickLabels <T> (
  skipDistance: number,
  formatFunction: (domainValue: T, index: number) => string,
  domainValue: T,
  index: number
): string {
  if (index % skipDistance === 0) {
    return formatFunction(domainValue, index)
  } else {
    return ''
  }
}

export function formatLargeCurrency (domainValue: d3.NumberValue): string {
  return (domainValue > 1000 || domainValue < 1000)
    ? d3.format('$.0s')(domainValue)
    : d3.format('$,.0f')(domainValue)
}

/**
 * @param svg SVG Canvas to Draw box on
 * @param x Top Left Corner of the box X Coordinate
 * @param y Top Left Corner of the box Y Coordinate
 * @param boxWidth Width of the box
 * @param boxHeight Height of the box
 * @param fillColor Color
 * @param radiusTL Top Left Radius size
 * @param radiusTR Top Right Radius size
 * @param radiusBL Bottom Left Radius size
 * @param radiusBR Bottom Right Radius size
 */
export function drawBox (
  svg: d3.Selection<SVGGElement, unknown, null, undefined>,
  x: number,
  y: number,
  boxWidth: number,
  boxHeight: number,
  fillColor: string,
  radiusTL = 0,
  radiusTR = 0,
  radiusBL = 0,
  radiusBR = 0
): void {
  // These letters represent 8 coordinate pairs used to draw this box
  //   A *---* B
  // H *       * C
  //   |       |
  //   |       |
  // G *       * D
  //   F *---* E

  const A = { x: x + radiusTL, y }
  const B = { x: x + boxWidth - radiusTR, y }
  const C = { x: x + boxWidth, y: y + radiusTR }
  const D = { x: x + boxWidth, radiusTR, y: y + boxHeight - radiusBR }
  const E = { x: x + boxWidth - radiusBR, y: y + boxHeight }
  const F = { x: x + radiusBL, y: y + boxHeight }
  const G = { x, y: y + boxHeight - radiusBL }
  const H = { x, y: y + radiusTL }

  // Construct a raw SVG path
  // M = starting position
  // V = vertical line
  // H = Horizontal line
  // A = Arc

  const pathString = `
  M ${A.x} ${A.y} 
  H ${B.x}
  A ${radiusTR} ${radiusTR} 0 0 1 ${C.x} ${C.y}
  V ${D.y}
  A ${radiusBR} ${radiusBR} 0 0 1 ${E.x} ${E.y}
  H ${F.x}
  A  ${radiusBL} ${radiusBL} 0 0 1 ${G.x} ${G.y}
  V ${H.y}
  A  ${radiusTL} ${radiusTL} 0 0 1 ${A.x} ${A.y}
  `

  svg.append('path')
    .attr('d', pathString)
    .attr('fill', fillColor)
}

/**
 *
 * @param data Array of input data
 * @param desiredLength Desired number of items for the output data
 * @returns Array sampled at a frequency producing an array similar to the desired length
 */
export function reSampleData<T> (
  data: T[],
  desiredLength: number
): T[] {
  // If the data is less than the desired length, set a the frequency to 1
  const frequency = Math.max(Math.floor(data.length / desiredLength), 1)
  return data.filter((d, i) => i % frequency === 0)
}
