import React, { type ReactElement } from 'react'
import { Center, Flex, Text } from '@chakra-ui/react'
import { Color } from '@/theme/theme'

export default function AltirSubscriptionComponent (): ReactElement {
  return (
    <Center flexDir='column' gap={6} w='full'>
      <Center flexDir='column' w='full' gap={1}>
        <Flex flexDir='row' w='full' gap={1}>
          <Text>Current Plan: </Text>
          <Text color={Color.BRIGHT_BLUE}>Altir Pro</Text>
        </Flex>
        <Flex flexDir='row' w='full'>
          <Text>Your subscription to Altir Pro is provided free of charge.</Text>
        </Flex>
      </Center>
    </Center>
  )
}
