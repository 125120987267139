
import React, { type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import TableHeaderRow from './components/TableHeaderRow'
import TableBody from './components/TableBody'
import { Color } from '@/theme/theme'

export interface TableHeader<T> {
  key: keyof T
  header: string
  width?: string

  alignment?: 'start' | 'end'
  isReactElement?: boolean
}

export interface TableProps<T extends object> {
  metadata: Array<TableHeader<T>>
  data: T[]

  // Styles
  backgroundColor?: Color
}

export default function Table<T extends object> ({
  metadata,
  data,
  backgroundColor = Color.WHITE
}: TableProps<T>
): ReactElement {
  const numberOfColumns = metadata.length
  const templateColumns = `repeat(${numberOfColumns}, minmax(0, 1fr)) 40px`
  const gridHorizontalPadding = 6
  return (
    <Center w='100%' flexDir='column' gap={2}>
      {/* HEADER */}
      <TableHeaderRow
        tableMetadata={metadata}
        templateColumns={templateColumns}
        gridHorizontalPadding={gridHorizontalPadding}
      />
      {/* DATA */}
      <TableBody
        data={data}
        tableMetadata={metadata}
        templateColumns={templateColumns}
        gridHorizontalPadding={gridHorizontalPadding}
        backgroundColor={backgroundColor}
      />
    </Center>
  )
}
