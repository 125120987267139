/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { Color, IconSize } from '../theme/theme'

export default function RecurringRuleTimeIcon ({ color = Color.DARK_BLUE, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M4.06482 13.8501C3.70799 12.0446 3.97766 10.1713 4.82929 8.53973C5.68092 6.90813 7.06357 5.61581 8.74888 4.87617C10.4342 4.13653 12.3214 3.99383 14.0988 4.47163C15.8761 4.94943 17.4374 6.01916 18.5247 7.50414' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M19.9332 10.7143C20.2901 12.5199 20.0204 14.3931 19.1688 16.0247C18.3171 17.6563 16.9345 18.9486 15.2492 19.6883C13.5638 20.4279 11.6767 20.5706 9.89929 20.0928C8.12191 19.615 6.56065 18.5453 5.47336 17.0603' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M18.7422 7.60704V4.19531' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M5.25781 16.9574L5.25781 20.3691' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M12.4993 10.4488C12.4993 10.1727 12.2755 9.94884 11.9993 9.94884C11.7232 9.94884 11.4993 10.1727 11.4993 10.4488H12.4993ZM11.9993 12.5753H11.4993C11.4993 12.8514 11.7232 13.0753 11.9993 13.0753V12.5753ZM13.7441 13.0753C14.0203 13.0753 14.2441 12.8514 14.2441 12.5753C14.2441 12.2992 14.0203 12.0753 13.7441 12.0753V13.0753ZM11.9993 8.29492C9.79666 8.29492 8.00977 10.0818 8.00977 12.2845H9.00977C9.00977 10.6341 10.3489 9.29492 11.9993 9.29492V8.29492ZM8.00977 12.2845C8.00977 14.4872 9.79666 16.2741 11.9993 16.2741V15.2741C10.3489 15.2741 9.00977 13.9349 9.00977 12.2845H8.00977ZM11.9993 16.2741C14.202 16.2741 15.9889 14.4872 15.9889 12.2845H14.9889C14.9889 13.9349 13.6497 15.2741 11.9993 15.2741V16.2741ZM15.9889 12.2845C15.9889 10.0818 14.202 8.29492 11.9993 8.29492V9.29492C13.6497 9.29492 14.9889 10.6341 14.9889 12.2845H15.9889ZM11.4993 10.4488V12.5753H12.4993V10.4488H11.4993ZM11.9993 13.0753H13.7441V12.0753H11.9993V13.0753Z' fill={color}/>
      </svg>
    </IconContainer>
  )
}
