import React, { useState, type ReactElement } from 'react'
import { Flex, Text, useToast } from '@chakra-ui/react'
import { Color } from '../theme/theme'
import EyeClosedIcon from '../icons/EyeClosedIcon'
import EyeIcon from '../icons/EyeIcon'
import { getActionToast } from '@/utils/toastUtils'

export interface SensitiveTextComponentProps {
  text: string
  description?: string
}

const HIDDEN_TEXT_PLACEHOLDER = '•••••••••'

export default function SensitiveTextComponent (
  {
    text,
    description
  }: SensitiveTextComponentProps
): ReactElement {
  const toast = useToast()
  const [isHidden, setIsHidden] = useState(true)

  function toggleIsHidden (): void {
    if (isHidden) {
      navigator.clipboard.writeText(text)
        .then(() => { description != null && toast(getActionToast(`${description} copied to clipboard`)) })
        .catch(() => {})
    }
    setIsHidden(!isHidden)
  }
  return (
    <Flex>
      <Text mr={2} color={Color.DARK_BLUE}>
        {isHidden ? HIDDEN_TEXT_PLACEHOLDER : text}
      </Text>
      <Flex onClick={toggleIsHidden} _hover={{ cursor: 'pointer' }}>
        {isHidden ? <EyeClosedIcon color={Color.DARK_GREY}/> : <EyeIcon color={Color.DARK_GREY}/>}
      </Flex>
    </Flex>
  )
}
