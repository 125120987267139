import React, { type ReactElement } from 'react'
import { Flex, Slider, SliderThumb, SliderTrack, Text } from '@chakra-ui/react'
import { Color } from '@/theme/theme'

interface SliderComponentProps {
  label: string
  value: number
  minValue: number
  maxValue: number
  onChange: (value: number) => void

  // Styles
  colorScheme?: string
  formatValue?: (value: number) => string
}

export default function SliderComponent ({
  label,
  value,
  minValue,
  maxValue,
  onChange,
  colorScheme = Color.DARK_BLUE,
  formatValue = (value) => String(value)
}: SliderComponentProps): ReactElement {
  return (
    <Flex flexDir='column' w='100%' gap={2}>
      <Slider
        value={value}
        onChange={onChange}
        min={minValue}
        max={maxValue}
      >
        <SliderTrack bg={Color.GREY}>
        </SliderTrack>
        <SliderThumb boxSize={3} bg={colorScheme} />
      </Slider>
      <Flex justifyContent='space-between'>
        <Text>{label}</Text>
        <Text color={colorScheme}>{formatValue(value)}</Text>
      </Flex>
    </Flex>
  )
}
