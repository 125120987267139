import React, { type ReactElement } from 'react'
import { Grid, GridItem, Flex, Divider, Text } from '@chakra-ui/react'
import { type OrganizationBillingComponentProps } from './OrganizationBillingComponent'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import GreenDotIcon from '@/library/icons/GreenDotIcon'
import { Color, BorderRadius, IconSize } from '@/theme/theme'
import { formatEpochTime } from '@/utils/dateUtils'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { getStripeEnvironment } from '@/utils/envUtils'
import RightArrowIcon from '@/library/icons/RightArrowIcon'

export default function SubscriptionHistoryComponent (
  subscriptionDetails?: OrganizationBillingComponentProps
): ReactElement {
  const { STRIPE_ACCOUNT_LINK } = getStripeEnvironment()
  const subscription = subscriptionDetails?.subscriptionDetails?.AltirSubscription
  const invoices = subscription?.invoices

  if (invoices == null) return <></>
  return (
    <Flex flexDir='column' w='full' gap={3}>
      <Text>Subscription History</Text>
      <Grid
        rowGap={4}
        padding={6}
        templateColumns='repeat(4, minmax(0, 1fr))'
        borderRadius={BorderRadius.CARD}
        backgroundColor={Color.LIGHT_GREY}
      >
        {invoices?.map((invoice, index) => {
          const invoiceTotal = getCurrencyFormatted(invoice.total / 100, { minimumFractionDigits: 2 })
          return (
            <>
              <GridItem justifySelf='start'>
                <Text color={Color.DARK_BLUE}>{formatEpochTime(invoice?.period_end ?? 0)}</Text>
              </GridItem>
              <GridItem>
                <Text color={Color.DARK_BLUE}>Altir Pro</Text>
              </GridItem>
              <GridItem>
                <Flex flexDir='row' justify='center' align='center' gap={2}>
                  <GreenDotIcon color={Color.SUCCESS_GREEN} size={IconSize.SMALL}/>
                  <Text textColor={Color.SUCCESS_GREEN}>Paid</Text>
                </Flex>
              </GridItem>
              <GridItem justifySelf='end'>
                <Text color={Color.DARK_BLUE}>
                  {invoiceTotal}
                </Text>
              </GridItem>
              {index !== invoices.length - 1 &&
              <GridItem colStart={1} colEnd={5}>
                <Divider color={Color.DARK_GREY}/>
              </GridItem>
              }
            </>
          )
        })}
        <GridItem colStart={1} colEnd={5} justifySelf='end' pt={2}>
          <Button
            text='View All'
            variant={ButtonVariant.WHITE}
            onClick={() => { window?.open(STRIPE_ACCOUNT_LINK, '_blank')?.focus() }}
            width='auto'
            size={ButtonSize.SMALL}
            afterIcon={<RightArrowIcon/>}
          />
        </GridItem>
      </Grid>
    </Flex>
  )
}
