import React from 'react'
import { Flex } from '@chakra-ui/react'
import { type ReactElement } from 'react'
import { BorderRadius, Color } from '@/theme/theme'

export interface IconButtonProps {
  icon: ReactElement
  onClick: () => void
}

export default function IconButton ({
  icon,
  onClick
}: IconButtonProps): ReactElement {
  return (
    <Flex
      alignItems='center'
      _hover={{ cursor: 'pointer', bg: Color.GREY }}
      onClick={onClick}
      p={1}
      borderRadius={BorderRadius.BUTTON_HOVER}
    >
      {icon}
    </Flex>
  )
}
