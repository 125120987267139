import { CounterpartyTypeSearchCriteria } from './AccountSelectorModal'
import { AccountSelectorAccountContext } from './AccountSelectorComponent'
import { AccountLinkStatus, TransferDirection, TransferType } from '@/graphql/__generated__/globalTypes'
import {
  isAccountValidForDebit
} from '@/utils/financialAccountUtils'
import { getFormattedTransferType, isAmplifyAccount, isCounterparty } from '@/utils/transferUtils'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'

export const COUNTERPARTY_NEEDS_VERIFICATION_TEXT_NEW = `
  This account is being verified. Any transfers initiated into or out of this account may be delayed until the verification process is complete.
`

export const DISABLED_REASON_EXTERNAL_COUNTERPARTY_CREDIT = `
  This account cannot send transfers. It can only receive them.
`

export const DISABLED_REASON_PLAID_COUNTERPARTY_DISCONNECTED = `
  This account is de-linked. Transfers cannot be initiated from this account until it has been re-linked.
`

export interface AccountWithEnabledState {
  isDisabled: boolean
  disabledReason?: string
}

export type AccountWithDisabledState = FinancialAccountExtendedFragment & { enabledState: AccountWithEnabledState }

export function getCounterpartyTypeOptions (transferDirection?: TransferDirection): CounterpartyTypeSearchCriteria[] {
  return transferDirection === TransferDirection.CREDIT
    ? [
        CounterpartyTypeSearchCriteria.ALL,
        CounterpartyTypeSearchCriteria.ACCOUNT,
        CounterpartyTypeSearchCriteria.VENDOR
      ]
    : [CounterpartyTypeSearchCriteria.ACCOUNT]
}

export function augmentAccountsWithEnabledStates (
  accounts: FinancialAccountExtendedFragment[],
  oppositeSideOfTransfer: FinancialAccountExtendedFragment | null,
  transferType: TransferType | null,
  context?: AccountSelectorAccountContext
): AccountWithDisabledState[] {
  return accounts.map(account => {
    return {
      ...account,
      enabledState: getAccountSelectorStateForAccount(account, oppositeSideOfTransfer, transferType, context)
    }
  })
}

/**
 * Returns whether a given account is valid for selection given the broader transfer context.
 * In the event that an account is disabled, also provides a specific reason as to why the
 * account cannot be selected
 */
function getAccountSelectorStateForAccount (
  account: FinancialAccountExtendedFragment,
  accountToTransferWith: FinancialAccountExtendedFragment | null,
  transferType: TransferType | null,
  context?: AccountSelectorAccountContext
): AccountWithEnabledState {
  if (areAccountsEqual(account, accountToTransferWith)) {
    return {
      isDisabled: true,
      disabledReason:
        `This account has already been selected as the ${context === AccountSelectorAccountContext.FROM ? 'destination' : 'source'} account.`
    }
  }
  if (
    (
      transferType === TransferType.CHECK ||
      transferType === TransferType.WIRE
    ) &&
    context === AccountSelectorAccountContext.FROM &&
    isCounterparty(account)
  ) {
    return {
      isDisabled: true,
      disabledReason: `${getFormattedTransferType(transferType)} transfers cannot be initiated from this account.`
    }
  }

  if (!isAmplifyAccount(account) && accountToTransferWith != null && !isAmplifyAccount(accountToTransferWith)) {
    return {
      isDisabled: true,
      disabledReason: 'At least one of the selected accounts must be an Altir account.'
    }
  }

  // Vendors + external personal are invalid for DEBIT
  if (
    context === AccountSelectorAccountContext.FROM &&
      !isAccountValidForDebit(account)
  ) {
    return {
      isDisabled: true,
      disabledReason: DISABLED_REASON_EXTERNAL_COUNTERPARTY_CREDIT
    }
  }

  // Broken Plaid accounts are invalid for DEBIT as we can't access their balance
  if (
    context === AccountSelectorAccountContext.FROM &&
      account.status === AccountLinkStatus.LOGIN_REQUIRED
  ) {
    return {
      isDisabled: true,
      disabledReason: DISABLED_REASON_PLAID_COUNTERPARTY_DISCONNECTED
    }
  }

  return { isDisabled: false }
}

/**
 * If the counterparty isn't valid for the selected counterparty critera, explain why.
 *
 *
 */
export function getAccountRowTooltipText (account: AccountWithDisabledState): string | undefined {
  if (account.enabledState.disabledReason != null) return account.enabledState.disabledReason

  return account.counterparty?.isOwnershipVerified === false
    ? COUNTERPARTY_NEEDS_VERIFICATION_TEXT_NEW
    : undefined
}

/**
 * Filters counterparties based off of user search input
 * DOES NOT hide counterparties that are invalid for a given transfer type
 */
export function filterCounterparties (
  counterparties: AccountWithDisabledState[],
  searchQuery: string
): AccountWithDisabledState[] {
  return counterparties
    // Filter based on search query text
    .filter(counterparty => {
      const formattedQuery = searchQuery.toLowerCase()
      return counterparty.name?.toLowerCase().includes(formattedQuery) === true ||
        counterparty.institution?.name?.toLowerCase().includes(formattedQuery) === true ||
        counterparty.lastFour?.includes(formattedQuery) === true ||
        counterparty.counterparty?.nickname?.toLowerCase().includes(formattedQuery) === true ||
        counterparty.counterparty?.achAccountNumber?.toLowerCase().includes(formattedQuery) === true ||
        counterparty.counterparty?.nameOnAccount?.toLocaleLowerCase().includes(formattedQuery) === true
    })
}

/**
 * Sorts counterparties for the AccountSelectorModal based on the following criteria:
 * 1. Valid counterparties before disabled ones
 * 2. Alphabetically by name
 */
export function sortCounterparties (
  counterparties: AccountWithDisabledState[]
): AccountWithDisabledState[] {
  return counterparties.sort((a, b) => {
    // 1. Sort by validity
    if (a.enabledState.isDisabled !== b.enabledState.isDisabled) {
      return Number(a.enabledState.isDisabled) - Number(b.enabledState.isDisabled)
    }

    // 2. Fall back to name
    return a.name?.localeCompare(b.name ?? '') ?? 0
  })
}

export function areAccountsEqual (
  a?: FinancialAccountExtendedFragment | null,
  b?: FinancialAccountExtendedFragment | null
): boolean {
  return (a?.amplifyAccount?.id != null && b?.amplifyAccount?.id === a?.amplifyAccount?.id) ||
    (a?.counterpartyId != null && a.counterpartyId === b?.counterpartyId)
}
