import React, { useEffect, type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Graphic } from '@/library/utility/Graphic'
import { SCREEN_500 } from '@/theme/graphics'
import SupportEmailInlineLink from '@/library/text/SupportEmailInlineLink'
import { Color, IconSize } from '@/theme/theme'
import Button from '@/library/button/Button'
import CenteredInfoPage from '@/library/page/CenteredInfoPage'
import RecurringIcon from '@/library/icons/RecurringIcon'
import { ROUTES } from '@/api/routes'
import { clearAltirStoreFromOutsideOfStoreContext } from '@/hooks/store/useAltirStore'

export default function ServerErrorPage (): ReactElement {
  const navigate = useNavigate()

  useEffect(() => {
    clearAltirStoreFromOutsideOfStoreContext()
  })

  function handleRefresh (): void {
    navigate(ROUTES.INDEX)
  }

  return (
    <CenteredInfoPage>
      <Flex
        flexDir='column'
        alignItems='center'
        gap={6}
      >
        <Graphic src={SCREEN_500} h='102px'/>
        <Flex flexDir='column' gap={2} alignItems='center'>
          <Heading color={Color.DARK_BLUE} size='lg'>Something went wrong.</Heading>
          <Text maxW={96}>
            This page failed to load because of a server error. If the problem persists, contact us at
            <SupportEmailInlineLink/>
          </Text>
        </Flex>
        <Button
          text='Refresh Page'
          beforeIcon={<RecurringIcon size={IconSize.SMALL}/>}
          onClick={handleRefresh}
        />
      </Flex>
    </CenteredInfoPage>
  )
}
