import {
  type ApolloError,
  useLazyQuery,
  type DocumentNode,
  type LazyQueryHookOptions,
  type OperationVariables,
  type TypedDocumentNode
} from '@apollo/client'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface UseQueryResponse<T> {
  data?: T
  loading: boolean
  error: ApolloError | undefined
  refetch: () => void
}

/**
 * This hook exposes the generic useQuery Apollo interface, but re-fetches data when
 * the navigation state changes
 *
 * Particularly useful in Layout components
 */
export function useQueryWithLocationSensitivity<T, TVariables extends OperationVariables> (
  QUERY_STRING: DocumentNode | TypedDocumentNode<T, TVariables>,
  options?: LazyQueryHookOptions<T, TVariables>
): UseQueryResponse<T> {
  const location = useLocation()

  const [fetch, { data, loading, error, refetch }] = useLazyQuery<T, TVariables>(QUERY_STRING, options)

  // Setting "location" as a dependency of this useEffect call allows us to refetch data
  //    whenever the url changes
  useEffect(() => {
    void fetch()
  }, [fetch, location])

  return {
    data,
    loading,
    error,
    refetch: () => { void refetch() }
  }
}
