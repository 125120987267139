import { Flex, FormLabel, VisuallyHiddenInput } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { Event } from 'metrics/metrics'
import FileUploadIcon from '../../icons/FileUploadIcon'
import Button, { ButtonSize, ButtonVariant } from '../../button/Button'
import { type FileUploadType } from '@/graphql/__generated__/globalTypes'

export interface FileUploadInputProps {
  type: FileUploadType
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isLoading: boolean
  customButton?: ReactElement
}

export function FileUploadInput ({ onChange, type, isLoading, customButton }: FileUploadInputProps): ReactElement {
  function handleButtonClick (): void {
    document.getElementById(type)?.click()
  }
  const customButtonWithProps = customButton != null
    ? React.cloneElement(customButton, { onClick: handleButtonClick })
    : null
  return (
    // If custom button exists, allow it to dictate its own width, otherwise set component width
    <Flex width={customButton != null ? '100%' : 28} flexDir='column' justifyContent='center'>
      <FormLabel htmlFor={type} _hover={{ cursor: 'pointer' }} w='100%' mb={-0.25}>
        {customButtonWithProps != null
          ? customButtonWithProps
          : <Button
              text='Upload'
              variant={ButtonVariant.WHITE_OUTLINE}
              onClick={handleButtonClick}
              beforeIcon={<FileUploadIcon/>}
              size={ButtonSize.SMALL}
              isLoading={isLoading}
              onClickEventType={Event.FILE_UPLOAD_CLICK}
            />}
      </FormLabel>
      <VisuallyHiddenInput
        id={type}
        type='file'
        onChange={onChange}
      />
    </Flex>
  )
}
