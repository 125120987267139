import React, { useState, type ReactElement } from 'react'
import { Center, Heading, Link, Radio, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import OnboardingPage from '@/library/page/OnboardingPage'
import OnboardingDisclosureFooter from '@/library/legal/OnboardingDisclosureFooter'
import { Graphic } from '@/library/utility/Graphic'
import { SUCCESS_COMPASS_PLUS_GRAPHIC } from '@/theme/graphics'
import Button, { ButtonVariant } from '@/library/button/Button'
import { ROUTES } from '@/api/routes'
import { TERMS_OF_SERVICE_URL } from '@/theme/urls'
import { Color, ContainerWidth, FontWeight } from '@/theme/theme'
import AltirProFeatureBox from '@/library/pro/AltirProFeatureBox'
import AltirProFeatureRow from '@/library/pro/AltirProFeatureRow'
import AltirProUpgradeButton from '@/library/pro/AltirProUpgradeButton'
import {
  type UpdateOrganizationOnboardingState,
  type UpdateOrganizationOnboardingStateVariables
} from '@/graphql/__generated__/UpdateOrganizationOnboardingState'
import { UPDATE_ORGANIZATION_ONBOARDING_STATE } from '@/graphql/mutations/onboarding/UpdateOrganizationOnboardingState'
import ErrorInline from '@/library/errors/ErrorInline'
import { type GetOnboardingOrganization } from '@/graphql/__generated__/GetOnboardingOrganization'
import { GET_ONBOARDING_ORGANIZATION } from '@/graphql/queries/onboarding/GetOnboardingOrganization'
import { AmplifyAccountApplicationStatus } from '@/graphql/__generated__/globalTypes'
import { isAmplifyApplicationSubmitted } from '@/utils/amplifyApplicationUtils'
import { type ErrorWithContent } from '@/types/types'
import { ErrorCopy } from '@/utils/errorUtils'

export default function OnboardingProPage (): ReactElement {
  const navigate = useNavigate()
  const [organizationError, setOrganizationError] = useState<Error | undefined>()
  const [skipStripeOnboardingError, setSkipStripeOnboardingError] = useState<ErrorWithContent | undefined>()
  const [hasAgreedToTermsOfService, setHasAgreedToTermsOfService] = useState(true)
  const { data, loading, error } = useQuery<GetOnboardingOrganization>(GET_ONBOARDING_ORGANIZATION)

  const organization = data?.currentUser?.selectedOrganization
  const organizationId = organization?.id ?? ''
  const businesses = organization?.franchiseGroups
  const amplifyAccountApplications = businesses
    ?.map(business => business.amplifyAccountApplicationStatus)
    ?.filter(status => status !== AmplifyAccountApplicationStatus.DOES_NOT_EXIST)
  const totalSubmittedApplications = (amplifyAccountApplications ?? []).filter(isAmplifyApplicationSubmitted).length
  const totalBusiness = businesses?.length ?? 1

  const [
    skipStripeOnboarding,
    { loading: skipStripeOnboardingLoading }
  ] = useMutation<UpdateOrganizationOnboardingState, UpdateOrganizationOnboardingStateVariables>(
    UPDATE_ORGANIZATION_ONBOARDING_STATE,
    {
      onCompleted: () => { navigate(ROUTES.V2_SIGN_UP_SUCCESS) },
      onError: (error) => {
        setSkipStripeOnboardingError({
          customContent: {
            title: ErrorCopy.SOMETHING_WENT_WRONG,
            subtitle: 'Unable to complete onboarding. Please try again or contact support.'
          },
          error
        })
      }
    }
  )

  function onSkip (): void {
    const organizationId = data?.currentUser?.selectedOrganization?.id
    if (organizationId == null) {
      setOrganizationError(Error('Organization id is null.'))
      return
    }
    void skipStripeOnboarding({ variables: { organizationId, isStripeOnboardingSkipped: true } })
  }

  return (
    <OnboardingPage
      maxWidth={ContainerWidth.MEDIUM}
      callToAction={{ callToActionText: 'Continue to Site', callToActionOnClick: onSkip }}
      isLoading={loading}
      error={error}
    >
      <Graphic src={SUCCESS_COMPASS_PLUS_GRAPHIC} h='120px'/>
      <Center flexDir='column' gap={1} textAlign='center' w='100%'>
        <Heading>Nice Work! Now, start earning interest.</Heading>
        {totalSubmittedApplications > 0 &&
          <Text>
            You&apos;ve applied for {totalSubmittedApplications}
            Amplify Account{totalSubmittedApplications > 1 ? 's' : ''}.
            You are off to a good start.
            Start earning interest {totalSubmittedApplications > 1 ? 'on those accounts' : ''}
            by upgrading to Altir Pro.
          </Text>
      }
      </Center>
      <Center flexDir='column' w='100%' gap={1}>
        <Text color={Color.DARK_BLUE} fontSize='lg'>BASIC FEATURES</Text>
        <AltirProFeatureBox color={Color.DARK_BLUE}>
          <AltirProFeatureRow
            text='Access to Amplify Checking Account'
            color={Color.DARK_BLUE}
          />
          <AltirProFeatureRow
            text='Dashboard with cash-based financial insights'
            color={Color.DARK_BLUE}
          />
          <AltirProFeatureRow
            text='Ability to provision Altir spending cards'
            color={Color.DARK_BLUE}
          />
        </AltirProFeatureBox>
      </Center>
      <Center flexDir='column' w='100%' gap={1}>
        <Text color={Color.BRIGHT_BLUE} fontSize='lg'>PRO FEATURES</Text>
        <AltirProFeatureBox color={Color.BRIGHT_BLUE}>
          <AltirProFeatureRow
            text='Earn 4.00% APY on cash in your Amplify Account'
            color={Color.BRIGHT_BLUE}
          />
          <AltirProFeatureRow
            text='Access to lines of credit'
            color={Color.BRIGHT_BLUE}
          />
        </AltirProFeatureBox>
      </Center>
      <Heading>
        ${totalBusiness * 25} <Text as='span' color={Color.DARK_BLUE}>/month</Text>
      </Heading>
      <Center gap={4}>
        <Radio
          isChecked={hasAgreedToTermsOfService}
          onClick={() => { setHasAgreedToTermsOfService(!hasAgreedToTermsOfService) }}
          size='lg'
          colorScheme='selectableInput'
        />
        <Text color={Color.DARK_BLUE}>
          I have read and understand the
          <Link
            href={TERMS_OF_SERVICE_URL}
            isExternal
            color={Color.GREY_BLUE}
            fontWeight={FontWeight.NORMAL}
            textDecoration='underline'
            _hover={{ color: Color.DARK_BLUE }}
            pl={1}
          >
            Terms of Service
          </Link>.
        </Text>
      </Center>
      <Center flexDir='column' gap={4} w='100%'>
        <AltirProUpgradeButton organizationId={organizationId} />
        <Button
          text='Skip This'
          variant={ButtonVariant.WHITE}
          onClick={onSkip}
          isLoading={skipStripeOnboardingLoading}
        />
        <ErrorInline error={skipStripeOnboardingError ?? organizationError}/>
      </Center>
      <OnboardingDisclosureFooter/>
    </OnboardingPage>
  )
}
