import { Flex, Text, useRadio } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { SLIDE_SELECT_TRANSITION_TIME } from './constant'
import { BorderRadius, Color } from '@/theme/theme'

export function SlideSelectCard (props: any): ReactElement {
  // We need to handle the state here to apply the styles for the moving focus box.
  const { getInputProps, getCheckboxProps, state } = useRadio(props)
  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Flex
      as='label'
      position='relative'
      zIndex='1'
      width='100%'
      justifyContent='center'
      alignItems='center'
    >
      <input {...input} />
      <Flex
        {...checkbox}
        cursor='pointer'
        color={Color.WHITE}
        borderRadius={BorderRadius.INPUT}
        justifyContent='center'
        alignItems='center'
        width='100%'
      >
        <Text
          fontSize='sm'
          color={state.isChecked ? Color.DARK_BLUE : Color.DARK_GREY}
          transition={`color ${SLIDE_SELECT_TRANSITION_TIME}`}
        >
          {props.children}
        </Text>
      </Flex>
    </Flex>
  )
}
