import React, { type ReactElement } from 'react'
import FormNumberInput from './FormNumberInput'
import { type BaseFormInputProps } from '../types'

type FormPercentageInputProps = {
  value: string
  onChange: (value: string) => void
} & BaseFormInputProps

export default function FormPercentageInput (props: FormPercentageInputProps): ReactElement {
  return (
    <FormNumberInput
      unitSuffix='%'
      maxValue={100}
      maxNumberOfDigits={3}
      {...props}
    />
  )
}
