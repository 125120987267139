import React, { type ReactElement } from 'react'
import { Box } from '@chakra-ui/react'
import CarrotComponent from '@/library/utility/toggle/CarrotComponent'
import { BorderRadius, Color, IconSize } from '@/theme/theme'
import { IconFacing } from '@/types/types'

interface DropDownIsSelectedIconProps {
  isSelected: boolean
  isDisabled: boolean
  onClick: () => void
}

export default function DropDownIsSelectedIcon ({
  isSelected,
  isDisabled,
  onClick
}: DropDownIsSelectedIconProps): ReactElement {
  return (
    <Box
      w='auto'
      p={2}
      borderRadius={BorderRadius.BAR}
      onClick={onClick}
      _hover={!isDisabled
        ? {
            backgroundColor: Color.GREY,
            cursor: 'pointer'
          }
        : {}}
    >
      <CarrotComponent
        facing={isSelected ? IconFacing.DOWN : IconFacing.RIGHT}
        size={IconSize.SMALL}
        color={Color.DARK_GREY}
      />
    </Box>
  )
}
