import React, { type ReactElement } from 'react'
import { Td, Tr, Text, Flex } from '@chakra-ui/react'
import DeprecatedTransferStatusTag from './DeprecatedTransferStatusTag'
import TransferActionTag from './TransferActionTag'
import { getFormattedDateString, getFormattedTimeString } from '@/utils/dateUtils'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { Color, FontWeight, IconSize } from '@/theme/theme'
import {
  type TransferFragment as Transfer
} from '@/graphql/__generated__/TransferFragment'
import { TransferDirection, TransferStatus } from '@/graphql/__generated__/globalTypes'

enum TransferPartyType {
  SENDER,
  RECIPIENT
}

export interface TransferTableRowProps {
  transfer: Transfer
  isCompact?: boolean
}

export default function TransferTableRow (
  {
    transfer,
    isCompact = false
  }: TransferTableRowProps
): ReactElement {
  const didTransferFail = transfer.status === TransferStatus.ERROR
  const tablePadding = 6

  return (
    <Tr
      borderBottom={`1.5px solid ${String(Color.GREY)}`}
      color={didTransferFail ? Color.ERROR_RED : Color.DARK_GREY}
      fontWeight={FontWeight.BOLD}
      pl={4}
      py={4}
      bg={Color.WHITE}
    >
      {/* For compact view, render a small amount of horizontal padding */}
      <Td pl={tablePadding}>
        <Flex flexDir='column'>
          <Text color={Color.DARK_GREY}>
            {getFormattedDateString(transfer.createdAt)}
          </Text>
          <Text color={Color.DARK_GREY} fontSize='xs'>
            {getFormattedTimeString(transfer.createdAt)}
          </Text>
        </Flex>
      </Td>
      <Td pl={tablePadding}>{transfer.amount.amountFormatted}</Td>
      <Td pl={tablePadding}>
        <DeprecatedTransferStatusTag
          status={transfer.status}
          counterpartyType={transfer.counterparty?.counterparty?.counterpartyType}
        />
      </Td >
      <Td pl={tablePadding}>
        <Flex alignItems='center'>
          <Text color={didTransferFail ? Color.ERROR_RED : Color.DARK_BLUE}>
            {getTransferAccountDescription(transfer, TransferPartyType.SENDER)}
          </Text>
          <Flex px={1}>
            <RightArrowIcon size={IconSize.MEDIUM} color={Color.DARK_GREY}/>
          </Flex>
          <Text color={didTransferFail ? Color.ERROR_RED : Color.DARK_BLUE}>
            {getTransferAccountDescription(transfer, TransferPartyType.RECIPIENT)}
          </Text>
        </Flex>
      </Td>
      {
        !isCompact &&
          <Td pl={tablePadding}>
            <TransferActionTag status={transfer.status}/>
          </Td>
      }
    </Tr>
  )
}

function getTransferAccountDescription (
  transfer: Transfer,
  transferPartyType: TransferPartyType
): string {
  // Determine whether account is amplify or counterparty based on direction.
  // The amplify account is the sender of a CREDIT and the recipient of a DEBIT,
  // otherwise we infer it is the counterparty
  if (
    (transfer.direction === TransferDirection.CREDIT && transferPartyType === TransferPartyType.SENDER) ||
    (transfer.direction === TransferDirection.DEBIT && transferPartyType === TransferPartyType.RECIPIENT)
  ) {
    return transfer.amplifyAccount?.name ?? ''
  } else {
    return `***${transfer.counterparty?.lastFour ?? ''}`
  }
}

// TODO (PJ): Move check from effective date
export function getTransferDateColor (transfer: Transfer): string {
  if (transfer.status === TransferStatus.ERROR) return Color.ERROR_RED
  return transfer.effectiveDate != null ? Color.DARK_BLUE : Color.DARK_GREY
}
