import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigationState } from '../useNavigationState'
import { getSuccessToast } from '@/utils/toastUtils'

interface AmplifySubmissionNavigationState {
  submittedApplicationName?: string
}

const SUBMITTED_APPLICATION_NAME = 'SUBMITTED_APPLICATION_NAME'

/**
 * Displays success toast when page is redirected to with a submittedApplicationName
 */
export function useAmplifyAppSuccessToast (): void {
  const toast = useToast()
  const navigationState = useNavigationState<AmplifySubmissionNavigationState>()
  localStorage.getItem('')

  useEffect(() => {
    const storedApplicationName = localStorage.getItem(SUBMITTED_APPLICATION_NAME)
    if (
      navigationState?.submittedApplicationName != null &&
      navigationState.submittedApplicationName !== storedApplicationName
    ) {
      localStorage.setItem(SUBMITTED_APPLICATION_NAME, navigationState.submittedApplicationName)
      toast(getSuccessToast(`You've submitted an application for ${navigationState.submittedApplicationName}`))
    }
  }, [navigationState, toast])
}
