import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GET_EXPORT_JOB_STATUS } from '@/graphql/queries/GetExportJobStatus'
import {
  type ExportJobStatus,
  type ExportJobStatusVariables,
  type ExportJobStatus_exportJob
} from '@/graphql/__generated__/ExportJobStatus'
import { JobExecutionStatus } from '@/graphql/__generated__/globalTypes'

const POLL_INTERVAL = 200

interface UseTrackJobHookProps {
  onSuccess?: (trackedJob: ExportJobStatus_exportJob) => void
  onError?: (error: Error) => void
}

interface UseTrackJobHookStates {
  trackJobStatus: (trackedJobId: string) => void
  isTracking: boolean
  jobStatus?: JobExecutionStatus
  error?: Error
}

/**
   * Polls a job status and notifies with callbacks and/or state when the job status changes
   *
   * @param onSuccess
   *   callback to trigger when job has completed in a successful state
   * @param onError
   *   callback to trigger when job is in a failed state or the query fails
   * @returns {UseTrackJobHookStates}
   *   startTrackingJobId: function to trigger polling for a job status
   *   isTracking: whether or not the hook is currently polling for job status
   *   jobStatus: the current status of the job
   *   error: the error that occurred during polling, if any
   */
export default function useTrackJobStatus (
  { onSuccess, onError }: UseTrackJobHookProps
): UseTrackJobHookStates {
  const [trackedJobId, setTrackedJobId] = useState<string>()
  const [isTracking, setIsTracking] = useState<boolean>(false)
  const [jobStatus, setJobStatus] = useState<JobExecutionStatus>(JobExecutionStatus.PENDING)
  const [error, setError] = useState<Error>()
  // Create a lazy query to poll for job status
  const [
    getJobStatus,
    { stopPolling: exportJobStatusStopPolling }
  ] = useLazyQuery<ExportJobStatus, ExportJobStatusVariables>(
    GET_EXPORT_JOB_STATUS,
    {
      pollInterval: POLL_INTERVAL,
      onError: (error) => {
        exportJobStatusStopPolling()
        setError(error)
      },
      onCompleted: (data) => {
        handleSuccessOrErrorOnJobStatus(
          data.exportJob,
          handleSuccess,
          handleError
        )
      }
    }
  )

  useEffect(() => {
    if (trackedJobId != null) {
      setIsTracking(true)
      void getJobStatus({ variables: { jobId: trackedJobId } })
    }
  }, [trackedJobId, getJobStatus])

  function handleSuccess (exportJob: ExportJobStatus_exportJob): void {
    exportJobStatusStopPolling()
    setIsTracking(false)
    if (onSuccess != null) onSuccess(exportJob)
  }

  function handleError (error: Error): void {
    setError(error)
    exportJobStatusStopPolling()
    setIsTracking(false)
    if (onError != null) onError(error)
  }

  function handleSuccessOrErrorOnJobStatus (
    exportJob: ExportJobStatus_exportJob | null,
    onSuccess: (exportJob: ExportJobStatus_exportJob) => void,
    onError: (error: Error) => void
  ): void {
    if (exportJob == null) {
      onError(Error('Export Job is Null'))
      return
    }

    const jobStatus = exportJob.status
    setJobStatus(jobStatus)

    switch (exportJob.status) {
      case JobExecutionStatus.FAILED:
        onError(Error('Export Job Failed'))
        break
      case JobExecutionStatus.SUCCESS:
        onSuccess(exportJob)
        break
      case JobExecutionStatus.PENDING:
        break
      default:
        break
    }
  }

  return {
    trackJobStatus: setTrackedJobId,
    isTracking,
    jobStatus,
    error
  }
}
