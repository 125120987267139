/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function ClipboardCopyIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size} >
      <svg width='100%' height='100%' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path stroke={color} d='M7.58594 10.5654C7.58594 9.8581 7.86692 9.17974 8.36708 8.67958C8.86724 8.17942 9.5456 7.89844 10.2529 7.89844H18.9189C19.2692 7.89844 19.616 7.96742 19.9396 8.10145C20.2631 8.23548 20.5571 8.43193 20.8048 8.67958C21.0524 8.92724 21.2489 9.22125 21.3829 9.54482C21.517 9.8684 21.5859 10.2152 21.5859 10.5654V19.2314C21.5859 19.5817 21.517 19.9285 21.3829 20.2521C21.2489 20.5756 21.0524 20.8696 20.8048 21.1173C20.5571 21.3649 20.2631 21.5614 19.9396 21.6954C19.616 21.8295 19.2692 21.8984 18.9189 21.8984H10.2529C9.9027 21.8984 9.5559 21.8295 9.23232 21.6954C8.90875 21.5614 8.61474 21.3649 8.36708 21.1173C8.11943 20.8696 7.92298 20.5756 7.78895 20.2521C7.65492 19.9285 7.58594 19.5817 7.58594 19.2314V10.5654Z' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path stroke={color} d='M4.59794 17.6354C4.29128 17.4606 4.03621 17.2079 3.85851 16.9029C3.68082 16.5979 3.58679 16.2514 3.58594 15.8984V5.89844C3.58594 4.79844 4.48594 3.89844 5.58594 3.89844H15.5859C16.3359 3.89844 16.7439 4.28344 17.0859 4.89844' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      </svg>
    </IconContainer>
  )
}
