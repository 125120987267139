export enum Color {
  DARK_BLUE = '#12285E',
  BRIGHT_BLUE = '#4460EB',
  BRIGHT_BLUE_HOVER = '#2B44A4',
  LIGHT_BLUE = '#EDF3FD',
  GREY_BLUE = '#6881AB',
  DARK_GREY = '#919BAA',
  GREY = '#E6E8EC',
  LIGHT_GREY = '#F6F6F6',
  ERROR_RED = '#EA5145',
  ERROR_RED_HOVER = '#7D2F2F',
  MEDIUM_GREY = '#C9CED4',
  ROSE_GREY = '#A5A5A5',
  SUCCESS_GREEN = '#479F55',
  YELLOW = '#F4BC42',
  WHITE = '#ffffff',
}

export enum Height {
  BUTTON = '60px',
  DOUBLE_BUTTON = '120px',
  BANNER = '40px',
  NAVBAR = '60px',
  ROW = '64px'
}

export enum FormInputHeight {
  SMALL = '32px',
  DEFAULT = '48px',
  HERO = '84px',
}

export enum ButtonHeight {
  X_SMALL = '28px',
  SMALL = '32px',
  MEDIUM = '40px',
  LARGE = '48px'
}

export enum Width {
  VERTICAL_NAVBAR_EXPANDED_WIDTH_PIXELS = 240,
  VERTICAL_NAVBAR_COLLAPSED_WIDTH_PIXELS = 64,
}

export enum ContainerWidth {
  SMALL = '360px',
  MEDIUM = '440px',
  LARGE = '512px',
  EXTRA_LARGE = '800px',
  FULL_PAGE = '1200px'
}

export enum ZIndex {
  NAV_SECONDARY = '997',
  NAV_PRIMARY = '998',
  MAX = '999'
}

export enum BorderRadius {
  INPUT = '200px',
  BADGE = '92px',
  BUTTON = '50px',
  CARD = '16px',
  BAR = '8px',
  BUTTON_HOVER = '4px',
  NAV_ITEM = '10'
}

export enum FontWeight {
  THIN = 300,
  MEDIUM = 500,
  NORMAL = 'normal',
  BOLD = 'bold'
}

export enum IconSize {
  EXTRA_SMALL = 2,
  SMEXTRA_SMALL = 3,
  SMALL = 4,
  SCHMEDIUM = 5,
  MEDIUM = 6,
  LARGE = 8,
  X_LARGE = 10,
  XX_LARGE = 20
}

export enum TransitionTime {
  NAV_TRANSITION = 0.1
}

export enum Opacity {
  INACTIVE = 0.3,
  ACTIVE = 1
}

export enum BoxShadow {
  LIGHT = '0px 0px 70px 0px rgba(0, 0, 0, 0.10)',
  MEDIUM = 'md' // 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

// We explicitly set heights on modals when it's critical that their height doesn't change
// in response to changes in the height of their children.
//
// We use rem units because Chakra's default spacing only extends to ~380px
// https://chakra-ui.com/docs/styled-system/theme
export enum ModalHeight {
  X_SMALL = '24rem',
  SMALL = '28rem',
  MEDIUM = '32rem',
  LARGE = '36rem',
  X_LARGE = '40rem',
}

export enum Gradient {
  // eslint-disable-next-line max-len
  BLUE_ORB = 'linear-gradient(to bottom right, #D8E5FF 0%, #FFF 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #D8E5FF 0%, #FFF 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #D8E5FF 0%, #FFF 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #D8E5FF 0%, #FFF 50%) top right / 50% 50% no-repeat;'
}
