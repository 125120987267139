import { gql } from '@apollo/client'

export const GET_BUSINESS_NAME = gql`
    query GetBusinessName($franchiseGroupId: Int!) {
        franchiseGroup(franchiseGroupId: $franchiseGroupId) {
            id
            name
        }
    }
`
