import React, { useEffect, type ReactElement } from 'react'
import {
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import { type ApolloQueryResult } from '@apollo/client'
import SuccessContent from './SuccessContent'
import PendingContent from './PendingContent'
import LoadingContent from './LoadingContent'
import { isAmplifyApplicationSubmitted } from '@/utils/amplifyApplicationUtils'
import { AmplifyAccountApplicationStatus } from '@/graphql/__generated__/globalTypes'
import { type GetAmplifyAccountApplication } from '@/graphql/__generated__/GetAmplifyAccountApplication'
import { BorderRadius, Color } from '@/theme/theme'

export interface AmplifyApplicationPostSubmitModalProps {
  applicationStatus?: AmplifyAccountApplicationStatus
  refetchApplication: () => Promise<ApolloQueryResult<GetAmplifyAccountApplication>>
}

export function AmplifyApplicationPostSubmitModal (
  { applicationStatus, refetchApplication }: AmplifyApplicationPostSubmitModalProps
): ReactElement {
  function getModalContent (): ReactElement | null {
    switch (applicationStatus) {
      case AmplifyAccountApplicationStatus.APPROVED:
        return <SuccessContent/>
      case AmplifyAccountApplicationStatus.MANUAL_REVIEW:
      case AmplifyAccountApplicationStatus.REJECTED:
        // Show PendingContent when status is rejected due to erroneous Alloy denies
        return <PendingContent/>
      case AmplifyAccountApplicationStatus.PROCESSING:
      case AmplifyAccountApplicationStatus.SUBMITTED:
        return <LoadingContent/>
      default:
        return null
    }
  }

  const shouldShowModal = isAmplifyApplicationSubmitted(applicationStatus)

  const isLoading = applicationStatus != null && [
    AmplifyAccountApplicationStatus.SUBMITTED,
    AmplifyAccountApplicationStatus.PROCESSING
  ].includes(applicationStatus)

  useEffect(() => {
    if (isLoading) {
      const id = setInterval(() => {
        void refetchApplication()
      }, 1000)
      return () => { clearInterval(id) }
    }
  }, [isLoading])

  return (
    <Modal
      isOpen={shouldShowModal}
      onClose={() => {}}
      isCentered={true}
      size='md'
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent
        flexDirection='row'
        borderRadius={BorderRadius.CARD}
        alignItems='center'
        justifyContent='center'
        width='100%'
        px={4}
        bg={Color.LIGHT_GREY}
      >
        {getModalContent()}
      </ModalContent>
    </Modal>
  )
}
