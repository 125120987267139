import { gql } from '@apollo/client'

export const LINK_INSTITUTION = gql`
    mutation LinkInstitution(
        $franchiseGroupId:Int!,
        $connectionProvider: InstitutionConnectionProvider!,
        $plaidLinkInput: PlaidLinkInput,
        $tellerLinkInput: TellerLinkInput
    ) {
        linkInstitution(
            franchiseGroupId: $franchiseGroupId,
            connectionProvider: $connectionProvider,
            plaidLinkInput: $plaidLinkInput,
            tellerLinkInput: $tellerLinkInput,
        )
    }
`
