import React, { type ReactElement } from 'react'
import {
  Flex,
  Heading
} from '@chakra-ui/react'
import { ModalComponent } from './ModalComponent'
import {
  Color,
  FontWeight
} from '../theme/theme'
import Button, { ButtonVariant } from '../button/Button'
import { SPACING, SpacerComponent } from '../layout/SpacerComponent'
import ErrorInline from '../errors/ErrorInline'

export interface DefaultModalComponentProps {
  title: string
  titleColor?: string
  isOpen: boolean
  onClose: () => void
  children?: ReactElement
  callToActionText?: string
  callToActionClick?: () => void
  customCallToActionButton?: ReactElement
  callToActionColor?: string
  isLoading: boolean
  hideCancel?: boolean
  icon?: ReactElement
  callToActionButtonVariant?: ButtonVariant
  error?: Error
}

export function DefaultModalComponent (
  {
    title,
    titleColor = Color.DARK_BLUE,
    isOpen,
    onClose,
    callToActionText,
    callToActionClick,
    callToActionButtonVariant,
    customCallToActionButton,
    isLoading,
    children,
    hideCancel = false,
    icon,
    error
  }: DefaultModalComponentProps
): ReactElement {
  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
    >
      <Flex flexDir='column' alignItems='center' textAlign='center' w='100%'>
        {icon != null &&
        <Flex>
          {icon}
          <SpacerComponent spacing={SPACING.S5}/>
        </Flex>
          }
        <Heading size='lg' fontWeight={FontWeight.MEDIUM} color={titleColor}>{title}</Heading>
        <Flex py={6} w='100%'>
          {children}
        </Flex>
        <Flex flexDir='column' w='100%' gap={3}>
          {
              customCallToActionButton != null
                ? customCallToActionButton
                : <Button
                    text={callToActionText}
                    onClick={callToActionClick}
                    isLoading={isLoading}
                    variant={callToActionButtonVariant}
                  />
            }
          {!hideCancel &&
          <Button
            text='Cancel'
            onClick={onClose}
            variant={ButtonVariant.WHITE}
          />}
          <ErrorInline error={error}/>
        </Flex>
      </Flex>
    </ModalComponent>
  )
}
