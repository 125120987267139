/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function CirclePlusIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V11.0005H7C6.44772 11.0005 6 11.4482 6 12.0005C6 12.5528 6.44772 13.0005 7 13.0005H11V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V13.0005H17C17.5523 13.0005 18 12.5528 18 12.0005C18 11.4482 17.5523 11.0005 17 11.0005H13V7Z' />
      </svg>
    </IconContainer>
  )
}
