import React, { type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { Graphic } from '@/library/utility/Graphic'
import { HANDHOLDING_PEOPLE } from '@/theme/graphics'
import { Color } from '@/theme/theme'

export default function UnverifiedRecipientWarning (): ReactElement {
  return (
    <Flex w='100%' justifyContent='center'>
      <Flex maxW='276px'>
        <Flex flexDir='column' gap={4}>
          <Graphic src={HANDHOLDING_PEOPLE} h='53px'/>
          <Flex flexDir='column' gap={2} alignItems='center' textAlign='center'>
            <Heading color={Color.DARK_BLUE} size='md'>This is a new recipient.</Heading>
            <Text fontSize='sm'>
              For your security, we are in the process of verifying this recipient.
              This should not interfere with your ability to transfer.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
