import { type DateTime } from 'luxon'
import React, { type ReactElement, useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../assets/css/index.css'
import DateRangeSelectorButton from './components/DateRangeSelectorButton'
import { dateTimeFromJsDate, jsDateFromDateTime } from '@/utils/dateUtils'

export interface DateRangeSelectorInput {
  startDate?: DateTime
  endDate?: DateTime
}

interface DateRangeSelectorProps {
  dateRange: DateRangeSelectorInput
  setDateRange: (dateRange: DateRangeSelectorInput) => void
}

export default function DateRangeSelector ({
  dateRange,
  setDateRange
}: DateRangeSelectorProps): ReactElement {
  const [internalStartDate, setInternalStartDate] = useState<DateTime | undefined>(
    dateRange.startDate
  )
  const [internalEndDate, setInternalEndDate] = useState<DateTime | undefined>(
    dateRange.endDate
  )
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setInternalStartDate(dateRange.startDate)
    setInternalEndDate(dateRange.endDate)
  }, [dateRange])

  const handleSelect = (dates: [Date | null, Date | null]): void => {
    const [startDate, endDate] = dates
    const newStartDate = dateTimeFromJsDate(startDate) ?? undefined
    const newEndDate = dateTimeFromJsDate(endDate) ?? undefined

    setInternalStartDate(newStartDate)
    setInternalEndDate(newEndDate)

    if (newStartDate != null && newEndDate != null) {
      setDateRange({ startDate: newStartDate, endDate: newEndDate })
      setIsOpen(false) // Close the calendar when both dates are selected
    }
  }

  return (
    <DatePicker
      open={isOpen}
      monthsShown={2}
      onInputClick={() => { setIsOpen(!isOpen) }}
      onChange={handleSelect}
      startDate={jsDateFromDateTime(internalStartDate) ?? undefined}
      endDate={jsDateFromDateTime(internalEndDate) ?? undefined}
      selectsRange
      showPopperArrow={false}
      customInput={
        <DateRangeSelectorButton
          isOpen={isOpen}
          onClick={() => { setIsOpen(!isOpen) }}
          startDate={internalStartDate}
          endDate={internalEndDate}
        />
      }
    />
  )
}
