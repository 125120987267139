import { gql } from '@apollo/client'

export const GET_ALTIR_SUBSCRIPTION_DETAILS = gql`
  query GetAltirSubscriptionDetails($organizationId: String!) {
    AltirSubscription(organizationId: $organizationId) {
      status
      stripeId
      customerId
      quantity
      product {
        name
      }
      subscription {
        periodStart
        periodEnd
        id
        days_until_due
        canceled_at
        cancel_at_period_end
        cancel_at
      }
      paymentDetails {
        id
        card {
          last4
          expYear
          expMonth
          country
          brand
        }
        billing_details {
          phone
          name
          email
          address {
            postalCode
            line2
            line1
            country
            city
            state
          }
        }
      }
      invoices {
        total
        paid
        period_start
        period_end
      } 
    }
  }
`
