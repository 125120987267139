import React, { type ReactElement } from 'react'
import {
  Flex,
  Stack,
  Text,
  Radio,
  Link,
  FormControl
} from '@chakra-ui/react'
import { useState } from 'react'
import { type ApolloError, useMutation } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { Event } from 'metrics/metrics'
import { ONBOARD_USER } from '@/graphql/mutations/OnboardUser'
import {
  type OnboardUser,
  type OnboardUserVariables
} from '@/graphql/__generated__/OnboardUser'
import { Color, FontWeight } from '@/library/theme/theme'
import { TERMS_OF_SERVICE_URL } from '@/library/theme/urls'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { FormInput } from '@/library/form/text/FormInput'
import { type ErrorWithContent } from '@/types/types'
import ErrorInline from '@/library/errors/ErrorInline'
import { useNoContextStore } from '@/hooks/store/useNoContextStore'
import { getUpdateUserError } from '@/utils/errorUtils'

export interface UserSignUpFormData {
  firstName: string
  lastName: string
  email: string
}

export interface UserFormProps {
  onSuccess: () => void
}

const UNABLE_TO_SUBMIT_FORM_TEXT = 'Unable to submit form'

export default function UserFormComponent (
  {
    onSuccess
  }: UserFormProps
): ReactElement {
  const { user } = useAuth0()
  const inviteCode = useNoContextStore(store => store.inviteCode)

  const [hasAgreedToTermsOfService, setHasAgreedToTermsOfService] = useState(false)
  const [submissionError, setSubmissionError] = useState<ErrorWithContent | undefined>()
  const [formData, setFormData] = useState<UserSignUpFormData>({
    firstName: '',
    lastName: '',
    email: ''
  })
  const [submitUserSignUp, { loading: isSubmitUserSignUpMutationLoading }] =
    useMutation<OnboardUser, OnboardUserVariables>(
      ONBOARD_USER,
      {
        onCompleted: onSuccess,
        onError: (error: ApolloError) => {
          setSubmissionError(getUpdateUserError(error))
        }
      }
    )

  function onChange (event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target
    setSubmissionError(undefined)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const isFormValidForSubmit = ({ displayErrors = false }): boolean => {
    if (formData.firstName === '' || formData.lastName === '') {
      if (displayErrors) {
        setSubmissionError(
          {
            customContent: {
              title: UNABLE_TO_SUBMIT_FORM_TEXT,
              subtitle: 'Please enter your name.'
            },
            error: Error()
          }
        )
      }
      return false
    }
    if (formData.email === '') {
      if (displayErrors) {
        setSubmissionError(
          {
            customContent: {
              title: UNABLE_TO_SUBMIT_FORM_TEXT,
              subtitle: 'Please enter your email.'
            },
            error: Error()
          }
        )
      }
      return false
    }
    if (!hasAgreedToTermsOfService) {
      if (displayErrors) {
        setSubmissionError(
          {
            customContent: {
              title: UNABLE_TO_SUBMIT_FORM_TEXT,
              subtitle: 'Please agree to the Terms of Service.'
            },
            error: Error()
          }
        )
      }
      return false
    }
    return true
  }

  async function handleUserFormSubmit (
  ): Promise<void> {
    if (!isFormValidForSubmit({ displayErrors: true })) { return }

    void submitUserSignUp({
      variables: {
        input: {
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          // Due to the phone-based auth configuration, Auth0 stores the phone # under the name field
          phoneNumber: user?.name ?? '',
          inviteCode
        }
      }
    })
  }

  return (
    <FormControl w='100%'>
      <Flex gap={8} flexDir='column'>
        <Stack spacing={5}>
          <FormInput
            fieldName='firstName'
            value={formData.firstName}
            label='First Name'
            onChange={onChange}
            placeholder='i.e. John'
            backgroundColor={Color.WHITE}
          />
          <FormInput
            fieldName='lastName'
            value={formData.lastName}
            label='Last Name'
            onChange={onChange}
            placeholder='i.e. Doe'
            backgroundColor={Color.WHITE}
          />
          <FormInput
            fieldName='email'
            value={formData.email}
            label='Email'
            onChange={onChange}
            placeholder='i.e. address@sample.com'
            backgroundColor={Color.WHITE}
          />
          <ErrorInline error={submissionError}/>
        </Stack>
        <Flex gap={4}>
          <Radio
            isChecked={hasAgreedToTermsOfService}
            onClick={() => { setHasAgreedToTermsOfService(!hasAgreedToTermsOfService) }}
            size='lg'
            colorScheme='selectableInput'
          />
          <Text>
            I have read and understand the
            <Link
              href={TERMS_OF_SERVICE_URL}
              isExternal
              color={Color.GREY_BLUE}
              fontWeight={FontWeight.NORMAL}
              textDecoration='underline'
              _hover={{ color: Color.DARK_BLUE }}
              pl={1}
            >
              Terms of Service
            </Link>.
          </Text>
        </Flex>
        <Button
          text='Continue'
          isDisabled={!isFormValidForSubmit({})}
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          isLoading={isSubmitUserSignUpMutationLoading}
          onClickEventType={Event.USER_SIGN_UP_FORM_SUBMIT_CLICK}
          onClick={handleUserFormSubmit}
        />
      </Flex>
    </FormControl>
  )
}
