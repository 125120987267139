import { gql } from '@apollo/client'

export const GET_EXPORT_JOB_STATUS = gql`
    query ExportJobStatus($jobId: String!) {
        exportJob(id: $jobId) {
            id
            status
            fileUrl
        }
    }
`
