import React, { type ReactElement } from 'react'
import { Navigate, Outlet, useLocation, type Location } from 'react-router-dom'
import { OnboardingStep } from '@/graphql/__generated__/globalTypes'
import { ROUTES } from '@/api/routes'
import { GET_AUTHENTICATED_USER } from '@/graphql/queries/GetAuthenticatedUser'
import { type GetAuthenticatedUser } from '@/graphql/__generated__/GetAuthenticatedUser'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { useQueryWithLocationSensitivity } from '@/hooks/data/useQueryWithLocationSensitivity'

export default function OnboardingLayout (): ReactElement {
  const location = useLocation()
  const { data, loading, error } = useQueryWithLocationSensitivity<GetAuthenticatedUser, GetAuthenticatedUser>(
    GET_AUTHENTICATED_USER,
    {
      fetchPolicy: 'network-only'
    }
  )

  const onboardingStep = data?.currentUser?.onboardingStep

  if (onboardingStep != null && !isInAllowedRouteForOnboardingStep(onboardingStep, location)) {
    const routeForStep = getRouteForOnboardingStep(onboardingStep)
    return <Navigate to={routeForStep}/>
  }

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Outlet/>
    </AltirSkeleton>
  )
}

export function getRouteForOnboardingStep (onboardingStep?: OnboardingStep): string {
  if (onboardingStep == null) {
    return ROUTES.ERROR
  }

  switch (onboardingStep) {
    case OnboardingStep.AMPLIFY_APPLICATION:
      return ROUTES.V2_SIGN_UP_AMPLIFY_OVERVIEW
    case OnboardingStep.BUSINESS_CREATION:
      return ROUTES.V2_SIGN_UP_BUSINESS
    case OnboardingStep.COMPLETE:
      return ROUTES.HOME
    case OnboardingStep.ACKNOWLEDGE_COMPLETION:
      return ROUTES.V2_SIGN_UP_SUCCESS
    case OnboardingStep.EMAIL_VERIFICATION:
      return ROUTES.V2_SIGN_UP_EMAIL_VERIFICATION
    case OnboardingStep.ORGANIZATION_INVITE_ACCEPTANCE:
      return ROUTES.V2_SIGN_UP_ORGANIZATION_ACCEPT
    case OnboardingStep.ORGANIZTION_CREATION:
      return ROUTES.V2_SIGN_UP_ORGANIZATION_CREATE
    case OnboardingStep.STRIPE_SUBSCRIPTION:
      return ROUTES.V2_SIGN_UP_PRO
    case OnboardingStep.USER_INFO:
      return ROUTES.V2_SIGN_UP_USER
  }
}

// TODO (PJ): Break this up into sub-layouts
/**
 * For a given onboarding step, there a set of pages that the user should be allowed to visit.
 * This function returns whether the current page is allowed given the onboarding state
 */
function isInAllowedRouteForOnboardingStep (onboardingStep: OnboardingStep, location: Location): boolean {
  const pathname = location.pathname
  switch (onboardingStep) {
    case OnboardingStep.AMPLIFY_APPLICATION:
      return pathname.includes('amplify')
    case OnboardingStep.BUSINESS_CREATION:
      return pathname.includes('business')
    case OnboardingStep.COMPLETE:
    case OnboardingStep.ACKNOWLEDGE_COMPLETION:
      return pathname.includes('success')
    case OnboardingStep.EMAIL_VERIFICATION:
      return pathname.includes('email_verification')
    case OnboardingStep.ORGANIZATION_INVITE_ACCEPTANCE:
      return pathname.includes('organization/accept')
    case OnboardingStep.ORGANIZTION_CREATION:
      return pathname.includes('organization/create')
    // Stripe subscription happens asynchronously, so we allow user to view success page
    // while this is processing
    case OnboardingStep.STRIPE_SUBSCRIPTION:
      return pathname.includes('pro') || pathname.includes('success')
    case OnboardingStep.USER_INFO:
      return pathname.includes('user')
  }
}
