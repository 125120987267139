import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import SliderComponent from '@/library/slider/SliderComponent'
import { getCurrencyFormatted } from '@/utils/stringUtils'

interface InterestCalculatorSliderProps {
  depositValue: number
  depositTermYears: number
  minDepositValue: number
  maxDepositValue: number
  setDepositValue: (value: number) => void
  setDepositTermYears: (value: number) => void
}

export default function InterestCalculatorSlider ({
  depositValue,
  depositTermYears,
  minDepositValue,
  maxDepositValue,
  setDepositValue,
  setDepositTermYears
}: InterestCalculatorSliderProps): ReactElement {
  const minDepositYears = 1
  const maxDepositYears = 5
  return (
    <Flex flexDir='column' gap={6} w='100%'>
      <SliderComponent
        label='Amplify Balance'
        value={depositValue}
        onChange={setDepositValue}
        minValue={minDepositValue}
        maxValue={maxDepositValue}
        formatValue={getCurrencyFormatted}
      />
      <SliderComponent
        label='Timeline'
        value={depositTermYears}
        onChange={setDepositTermYears}
        minValue={minDepositYears}
        maxValue={maxDepositYears}
        formatValue={formatYears}
      />
    </Flex>
  )
}

function formatYears (numYears: number): string {
  if (numYears === 1) return '1 Year'
  return `${(String(numYears))} Years`
}
