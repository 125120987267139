import { create } from 'zustand'

interface NoContextStoreState {
  inviteCode: string | undefined
  setInviteCode: (inviteCode: string) => void
}

export const useNoContextStore = create<NoContextStoreState>((set) => ({
  inviteCode: undefined,
  setInviteCode: (inviteCode: string) => { set({ inviteCode }) }
}))
