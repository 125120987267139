import { gql } from '@apollo/client'
import {
  AMPLIFY_ACCOUNT_PERSON_APPLICATION_FORMATTED_FRAGMENT
} from '../../fragments/amplify_account_application/AmplifyAccountPersonApplicationFragment'

export const GET_AMPLIFY_PERSON_APPLICATION = gql`
    ${AMPLIFY_ACCOUNT_PERSON_APPLICATION_FORMATTED_FRAGMENT}
    query GetAmplifyPersonApplication($id: String!, $franchiseGroupId: Int!) {
        amplifyPersonApplication(id: $id) {
            ...AmplifyAccountPersonApplicationFormattedFragment
        }
        franchiseGroup(franchiseGroupId: $franchiseGroupId) {
            name
            amplifyAccountApplication {
                id
            }
        }
    }
`
