import React, { type ReactElement } from 'react'
import { Circle } from '@chakra-ui/react'
import { Color } from '@/theme/theme'

interface ProgressItemProps {
  index: number
  currentIndex: number
}

export default function ProgressCircle ({ index, currentIndex }: ProgressItemProps): ReactElement {
  const isCompleted = currentIndex > index
  const isActive = currentIndex === index
  const borderColor = isActive || isCompleted ? Color.BRIGHT_BLUE : Color.GREY
  return (
    <Circle
      size='18px'
      border={`2px solid ${borderColor}`}
      bg={isCompleted ? Color.BRIGHT_BLUE : isActive ? Color.LIGHT_GREY : Color.GREY}
      color={Color.BRIGHT_BLUE}
    />
  )
}
