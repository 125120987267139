import React, { type ReactElement } from 'react'
import { Divider, Flex } from '@chakra-ui/react'
import DisclosureLine from './DisclosureLine'
import { Color } from '@/theme/theme'
import {
  GRASSHOPPER_CUSTOMER_AGREEMENT_URL,
  GRASSHOPPER_ICS_DEPOSIT_PLACEMENT_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL
} from '@/theme/urls'

export default function AccountDisclosuresComponent (): ReactElement {
  return (
    <Flex flexDir='column' gap={1} w='100%'>
      <DisclosureLine name='Grasshopper Customer Agreement' link={GRASSHOPPER_CUSTOMER_AGREEMENT_URL}/>
      <Divider borderColor={Color.MEDIUM_GREY}/>
      <DisclosureLine
        name='Grasshopper ICS Deposit Placement Agreement'
        link={GRASSHOPPER_ICS_DEPOSIT_PLACEMENT_URL}
      />
      <Divider borderColor={Color.MEDIUM_GREY}/>
      <DisclosureLine name='Terms of Service (Altir Industries, Inc.)' link={TERMS_OF_SERVICE_URL}/>
      <Divider borderColor={Color.MEDIUM_GREY}/>
      <DisclosureLine name='Privacy Policy (Altir Industries, Inc.)' link={PRIVACY_POLICY_URL}/>
    </Flex>
  )
}
