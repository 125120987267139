import { gql } from '@apollo/client'
import { AUTHORIZATION_FRAGMENT } from '../fragments/AuthorizationFragment'

export const GET_AUTHENTICATED_USER = gql`
    ${AUTHORIZATION_FRAGMENT}
    query GetAuthenticatedUser{
        currentUser {
            id
            authorization {
                ...AuthorizationFragment
            }
            selectedOrganization {
                id
                name
                franchiseGroups {
                    id
                    name
                }
            }
            isUserSignedUp
            onboardingStep
        }
    }
`
