import { gql } from '@apollo/client'

export const AMPLIFY_ACCOUNT_PERSON_APPLICATION_FORMATTED_FRAGMENT = gql`
    fragment AmplifyAccountPersonApplicationFormattedFragment on AmplifyAccountPersonalApplicationFormatted {
        businessTitle
        citizenship
        dateOfBirth
        firstName
        id
        lastName
        emailAddress
        middleName
        mailingAddress {
            city
            country
            postalCode
            state
            streetAddressLine1
            streetAddressLine2
        }
        ownershipPercentage
        occupation
        phoneNumber
        physicalAddress {
            city
            country
            postalCode
            state
            streetAddressLine1
            streetAddressLine2
        }
        roles
        status
    }
`
