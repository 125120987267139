import { gql } from '@apollo/client'

export const GET_INTEREST_RATE = gql`
    query GetInterestRate($accountId: Int!) {
        financialAccount(accountId: $accountId) {
            amplifyAccount {
                interestRate {
                    rate
                }
            }
        }
        amplifyAccountPromotionalOffer {
            subscribedAnnualPercentageYield
            unsubscribedAnnualPercentageYield
        }
    }
`
