import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import ProgressText from './ProgressText'
import ProgressItem from './ProgressItem'

interface ProgressStep {
  title: string
}

interface ProgressBarProps {
  steps: ProgressStep[]
  currentIndex: number
}

export default function ProgressBar ({ steps, currentIndex }: ProgressBarProps): ReactElement {
  return (
    <Flex flexDir='column' w='100%' gap={2}>
      <Flex justify='space-between' align='center' width='100%'>
        {
            steps.map((_step, index) => (
              <ProgressItem key={index} index={index} currentIndex={currentIndex} stepCount={steps.length}/>
            ))
        }
      </Flex>
      <Flex justify='space-between' align='center' width='100%'>
        {
            steps.map((step, index) => (
              <ProgressText key={index} index={index} title={step.title} currentIndex={currentIndex}/>
            ))
        }
      </Flex>
    </Flex>
  )
}
