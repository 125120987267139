/* eslint-disable max-len */
import { Icon } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function DownloadIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <Icon boxSize={size} display='flex' alignItems='center' justifyContent='center'>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill={color} d='M5.90625 13.584C6.01399 13.584 6.11733 13.6268 6.19351 13.703C6.2697 13.7792 6.3125 13.8825 6.3125 13.9902V16.0215C6.3125 16.237 6.3981 16.4436 6.55048 16.596C6.70285 16.7484 6.90951 16.834 7.125 16.834H16.875C17.0905 16.834 17.2972 16.7484 17.4495 16.596C17.6019 16.4436 17.6875 16.237 17.6875 16.0215V13.9902C17.6875 13.8825 17.7303 13.7792 17.8065 13.703C17.8827 13.6268 17.986 13.584 18.0938 13.584C18.2015 13.584 18.3048 13.6268 18.381 13.703C18.4572 13.7792 18.5 13.8825 18.5 13.9902V16.0215C18.5 16.4525 18.3288 16.8658 18.024 17.1705C17.7193 17.4753 17.306 17.6465 16.875 17.6465H7.125C6.69402 17.6465 6.2807 17.4753 5.97595 17.1705C5.6712 16.8658 5.5 16.4525 5.5 16.0215V13.9902C5.5 13.8825 5.5428 13.7792 5.61899 13.703C5.69517 13.6268 5.79851 13.584 5.90625 13.584Z' strokeWidth='0.5'/>
        <path fill={color} d='M11.7129 15.1719C11.7506 15.2097 11.7955 15.2398 11.8448 15.2602C11.8942 15.2807 11.9471 15.2913 12.0005 15.2913C12.0539 15.2913 12.1069 15.2807 12.1562 15.2602C12.2056 15.2398 12.2504 15.2097 12.2881 15.1719L14.7256 12.7344C14.8019 12.6581 14.8448 12.5547 14.8448 12.4468C14.8448 12.3389 14.8019 12.2354 14.7256 12.1592C14.6494 12.0829 14.5459 12.04 14.438 12.04C14.3301 12.04 14.2267 12.0829 14.1504 12.1592L12.4068 13.9036V6.75928C12.4068 6.65153 12.364 6.5482 12.2878 6.47202C12.2116 6.39583 12.1083 6.35303 12.0005 6.35303C11.8928 6.35303 11.7894 6.39583 11.7133 6.47202C11.6371 6.5482 11.5943 6.65153 11.5943 6.75928V13.9036L9.85064 12.1592C9.77436 12.0829 9.67089 12.04 9.56301 12.04C9.45513 12.04 9.35167 12.0829 9.27539 12.1592C9.19911 12.2354 9.15625 12.3389 9.15625 12.4468C9.15625 12.5547 9.19911 12.6581 9.27539 12.7344L11.7129 15.1719Z' strokeWidth='0.5'/>
      </svg>
    </Icon>
  )
}
