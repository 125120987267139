import React, { useEffect, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import FinancialAccountRow from './FinancialAccountRow'
import DropDownComponent from '../layout/drop_down/DropDownComponent'
import MockDropDownComponent from '../layout/drop_down/MockDropDownComponent'
import { DropDownVariant } from '../layout/drop_down/types'
import {
  type FinancialAccountExtendedFragment as FinancialAccount
} from '@/graphql/__generated__/FinancialAccountExtendedFragment'
import TreasuryAccountDropdownContent
  from '@/components/pages/treasury/components/treasury/treasury_row_dropdown/TreasuryAccountDropdownContent'
import { AccountLinkStatus, AmplifyAccountStatus, LinkedAccountType } from '@/graphql/__generated__/globalTypes'
import { ERROR_BORDER } from '@/utils/errorUtils'
import { ROUTES } from '@/api/routes'
import useTrackJobStatus from '@/hooks/useTrackJobStatus'
import {
  type GetFinancialAccount,
  type GetFinancialAccountVariables
} from '@/graphql/__generated__/GetFinancialAccount'
import { GET_FINANCIAL_ACCOUNT } from '@/graphql/queries/accounts/GetFinancialAccount'
import { getErrorToast } from '@/utils/toastUtils'

interface FinancialAccountDropDownComponentProps {
  account: FinancialAccount
  isTransactionRefreshEnabled: boolean
  refetch: () => void
}

export default function FinancialAccountDropDownComponent ({
  account,
  isTransactionRefreshEnabled,
  refetch
}: FinancialAccountDropDownComponentProps): ReactElement {
  const navigate = useNavigate()
  const toast = useToast()
  const [
    fetchFinancialAccountToUpdateCache
  ] = useLazyQuery<GetFinancialAccount, GetFinancialAccountVariables>(
    GET_FINANCIAL_ACCOUNT
  )
  const { trackJobStatus, isTracking } = useTrackJobStatus({
    onSuccess: () => {
      if (account.accountId != null) {
        void fetchFinancialAccountToUpdateCache({ variables: { accountId: account.accountId } })
      } else {
        toast(getErrorToast('Unable to update data after account refresh. Try refreshing the page.'))
      }
    },
    onError: () => {} // TODO (PJ): align on how to display error state
  })

  function onCardClick (): void {
    navigate(`${ROUTES.CARDS}/${String(account.accountId)}`)
  }

  const isBank = account.accountType === LinkedAccountType.BANK
  const borderStyle = account.status === AccountLinkStatus.LOGIN_REQUIRED ? ERROR_BORDER : undefined
  const isAccountClosed = account.amplifyAccount?.status === AmplifyAccountStatus.CLOSED
  const dropdownVariant = isAccountClosed ? DropDownVariant.DARK : DropDownVariant.DEFAULT

  const trackedJobId = account.trackedJob?.id

  useEffect(() => {
    if (!isTracking && trackedJobId != null) {
      trackJobStatus(trackedJobId)
    }
  }, [trackJobStatus, isTracking, trackedJobId])

  const header = (
    <FinancialAccountRow
      account={account}
      hasPendingRefreshJob={isTracking}
      isTransactionRefreshEnabled={isTransactionRefreshEnabled}
    />
  )

  // For now, only enable DropDowns for bank accounts, not cards
  return isBank
    ? <DropDownComponent
        borderStyle={borderStyle}
        variant={dropdownVariant}
        customHeader={header}
        dropDownContent={
          <TreasuryAccountDropdownContent
            account={account}
            isTransactionRefreshEnabled={isTransactionRefreshEnabled}
            hasPendingRefreshJob={isTracking}
            refetch={refetch}
          />
        }
      />
    : <MockDropDownComponent
        borderStyle={borderStyle}
        customHeader={header}
        variant={dropdownVariant}
        onClick={onCardClick}
      />
}
