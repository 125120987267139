import { Center, Flex, Heading, Radio, Text } from '@chakra-ui/react'
import React, { useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import AccountDisclosuresComponent from
  '../../amplify_account_application/routes/apply/components/disclosures/AccountDisclosuresComponent'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import LeftArrowIcon from '@/library/icons/LeftArrowIcon'
import { IconSize } from '@/theme/theme'
import { ROUTES } from '@/api/routes'
import {
  type RequestBankMigration,
  type RequestBankMigrationVariables
} from '@/graphql/__generated__/RequestBankMigration'
import { REQUEST_BANK_MIGRATION } from '@/graphql/mutations/RequestBankMigration'
import ErrorInline from '@/library/errors/ErrorInline'
import { useAltirStore } from '@/hooks/store/useAltirStore'

interface AccountUpgradeAttestationProps {
  onBack: () => void
  onSuccess: () => void
}

export default function AccountUpgradeAttestation ({
  onBack,
  onSuccess
}: AccountUpgradeAttestationProps): ReactElement {
  const navigate = useNavigate()
  const organizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)

  const [hasVerifiedAgreement, setHasVerifiedAgreement] = useState(false)

  const [
    requestBankMigration,
    { loading, error }
  ] = useMutation<RequestBankMigration, RequestBankMigrationVariables>(
    REQUEST_BANK_MIGRATION,
    {
      variables: { organizationId },
      onCompleted: onSuccess
    }
  )

  return (
    <Center flexDir='column' gap={8} w='100%'>
      <Button
        text='Back'
        beforeIcon={<LeftArrowIcon size={IconSize.SMALL}/>}
        variant={ButtonVariant.GREY}
        onClick={onBack}
        width='auto'
        size={ButtonSize.SMALL}
      />
      <Center flexDir='column' textAlign='center' w='100%' gap={1}>
        <Heading>Documents & Agreements</Heading>
        <Text>
          Please read and understand the following documents before upgrading.
        </Text>
      </Center>
      <AccountDisclosuresComponent/>
      <Flex gap={4} w='100%'>
        <Radio
          isChecked={hasVerifiedAgreement}
          onClick={() => { setHasVerifiedAgreement(!hasVerifiedAgreement) }}
          size='lg'
          colorScheme='selectableInput'
        />
        <Text>
          I have read and agree to the above documents.
        </Text>
      </Flex>
      <Center flexDir='column' textAlign='center' w='100%' gap={4}>
        <ErrorInline error={error}/>
        <Button
          text='Agree & Upgrade'
          isLoading={loading}
          onClick={requestBankMigration}
          isDisabled={!hasVerifiedAgreement}
        />
        <Button text='Cancel' variant={ButtonVariant.WHITE} onClick={() => { navigate(ROUTES.HOME) }}/>
      </Center>
    </Center>
  )
}
