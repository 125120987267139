import React, { useState, type ReactElement } from 'react'
import { Divider, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import StoreLocationRow from './StoreLocationRow'
import AddStoreLocationModal from './AddStoreLocationModal'
import Button, { ButtonVariant } from '../../../../library/button/Button'
import PlusIcon from '../../../../library/icons/PlusIcon'
import { type GetAllStoreLocations_currentUser_franchiseGroup_storeLocations as StoreLocation }
  from '@/graphql/__generated__/GetAllStoreLocations'
import { BorderRadius, Color, IconSize } from '@/theme/theme'
import { INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY } from '@/utils/authUtils'
import EmptyStateCard from '@/library/card/EmptyStateCard'

interface StoreLocationSettingsComponentProps {
  storeLocations: StoreLocation[]
  canManageStoreLocationSettings: boolean
  refetchSettings: () => void
  onAddStoreLocationClick?: () => void
  franchiseGroupId: number
}

const STORE_LOCATION_EMPTY_STATE_TITLE = 'No Store Locations'
const STORE_LOCATION_EMPTY_STATE_SUBTITLE =
  'Create a store location to allow all transactions and accounts to be automatically tagged with a location names.'

export default function StoreLocationSettingsComponent ({
  storeLocations,
  canManageStoreLocationSettings,
  refetchSettings,
  franchiseGroupId,
  onAddStoreLocationClick
}: StoreLocationSettingsComponentProps
): ReactElement {
  const [addStoreLocationModalOpen, setAddStoreLocationModalOpen] = useState(false)

  function handleStoreLocationUpdate (): void {
    refetchSettings()
    setAddStoreLocationModalOpen(false)
  }

  // Allow for creation via modal or full page (settings vs. onboarding)
  function handleAddStoreLocation (): void {
    if (onAddStoreLocationClick != null) {
      onAddStoreLocationClick()
    } else {
      setAddStoreLocationModalOpen(true)
    }
  }

  return (
    <Flex flexDir='column' gap={4} w='100%'>
      {storeLocations.length === 0
        ? <EmptyStateCard title={STORE_LOCATION_EMPTY_STATE_TITLE} subTitle={STORE_LOCATION_EMPTY_STATE_SUBTITLE}>
          <Button
            text='Add a Store Location'
            beforeIcon={<PlusIcon size={IconSize.SMALL}/>}
            variant={ButtonVariant.WHITE}
            width='auto'
            onClick={handleAddStoreLocation}
            isDisabled={!canManageStoreLocationSettings}
            toolTipText={!canManageStoreLocationSettings ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
          />
        </EmptyStateCard>
        : <Flex w='100%' flexDir='column' alignItems='end' gap={4}>
          <Grid
            templateColumns='repeat(4, minmax(0, 1fr)) 40px'
            w='100%'
            columnGap={4}
            rowGap={2}
            alignItems='center'
            overflow='hidden'
            bg={Color.LIGHT_GREY}
            p={6}
            borderRadius={BorderRadius.CARD}
          >
            <GridItem colStart={1} justifySelf='left'><Text>Name</Text></GridItem>
            <GridItem colStart={2} justifySelf='left'><Text>Location ID</Text></GridItem>
            <GridItem colStart={3} justifySelf='left'><Text>Check ID</Text></GridItem>
            <GridItem colStart={4} justifySelf='left'><Text>POS ID</Text></GridItem>
            {
              storeLocations.map((storeLocation, index) => {
                return (
                  <React.Fragment key={index}>
                    <StoreLocationRow
                      franchiseGroupId={franchiseGroupId}
                      storeLocation={storeLocation}
                      canManageStoreLocationSettings={canManageStoreLocationSettings}
                      refetchSettings={refetchSettings}
                    />
                    {index !== storeLocations.length - 1 &&
                    <GridItem colStart={1} colEnd={6}><Divider borderColor={Color.MEDIUM_GREY}/></GridItem>}
                  </React.Fragment>
                )
              }
              )
            }
          </Grid>
          <Button
            text='Add a Store Location'
            beforeIcon={<PlusIcon size={IconSize.SMALL}/>}
            variant={ButtonVariant.GREY}
            width='auto'
            onClick={handleAddStoreLocation}
            isDisabled={!canManageStoreLocationSettings}
            toolTipText={!canManageStoreLocationSettings ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
          />
        </Flex>}
      <AddStoreLocationModal
        isOpen={addStoreLocationModalOpen}
        onClose={handleStoreLocationUpdate}
        franchiseGroupId={franchiseGroupId}
      />
    </Flex>
  )
}
