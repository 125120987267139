import React, { type ReactElement } from 'react'
import { Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import MenuIcon from '../icons/MenuIcon'
import { Color, IconSize } from '@/theme/theme'

interface MenuOption {
  text: string
  onClick: () => void
  color?: Color
  beforeIcon?: ReactElement
}

interface PopoverIconMenuProps {
  isDisabled?: boolean
  tooltipText?: false | string
  icon?: ReactElement
  backgroundColor?: Color
  menuOptions: MenuOption[]
}

export default function PopoverIconMenu ({
  icon,
  tooltipText,
  menuOptions,
  isDisabled = false,
  backgroundColor = Color.WHITE
}: PopoverIconMenuProps
): ReactElement {
  return (
    <Menu placement='bottom-end' >
      <MenuButton
        as={IconButton}
        bg={backgroundColor}
        aria-label='Options'
        icon={icon ?? <MenuIcon color={Color.DARK_GREY} size={IconSize.SMALL}/>}
        borderRadius= '100%'
        isDisabled={isDisabled}
        tooltiptext={tooltipText}
      />
      <MenuList p={2} >
        {
            menuOptions.map(option => {
              return (
                <MenuItem onClick={option.onClick} key={option.text}>
                  <Flex alignItems='center' gap={2}>
                    {option.beforeIcon}
                    <Text color={option.color ?? Color.DARK_GREY}>{option.text}</Text>
                  </Flex>
                </MenuItem>
              )
            })
        }
      </MenuList>
    </Menu>
  )
}
