import React, { type ReactElement } from 'react'
import { Flex, HStack, PinInput, PinInputField } from '@chakra-ui/react'
import ErrorInline from '@/library/errors/ErrorInline'
import { type ErrorWithContent } from '@/types/types'

interface FormPinInputProps {
  value: string
  onComplete: (value: string) => void
  onChange: (value: string) => void
  error?: ErrorWithContent
}

export default function FormPinInput ({
  value,
  onComplete,
  onChange,
  error
}: FormPinInputProps): ReactElement {
  return (
    <Flex flexDir='column' alignItems='center' textAlign='center' gap={8} w='100%'>
      <HStack>
        <PinInput
          onComplete={(newValue) => { onComplete(newValue) }}
          value={value}
          onChange={(value) => { onChange(value) }}
          mask
          size='lg'
          variant='unstyled'
          autoFocus={true}
        >
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>
      <ErrorInline error={error}/>
    </Flex>
  )
}
