import React, { type ReactElement } from 'react'
import { Box, Flex, Text, Tooltip, useBoolean } from '@chakra-ui/react'
import { Color } from '../theme/theme'

export interface IconCircleButtonProps {
  icon: ReactElement
  color?: string
  hoverColor?: string
  backgroundColor?: string
  hoverBackgroundColor?: string
  text?: string
  disabledReasonText?: string
  onClick?: () => void
}

export default function IconCircleButton ({
  icon,
  text,
  color,
  hoverColor = color,
  backgroundColor,
  hoverBackgroundColor = backgroundColor,
  disabledReasonText,
  onClick = () => {}
}: IconCircleButtonProps): ReactElement {
  const [isHovered, setIsHovered] = useBoolean()
  const isDisabled = disabledReasonText != null
  const iconColor = isDisabled ? Color.WHITE : isHovered ? hoverColor : color
  const iconWithProps = React.cloneElement(icon, { color: iconColor })
  return (
    <Tooltip label={disabledReasonText}>
      <Flex flexDir='column' justifyContent='center' alignItems='center'>
        <Box
          borderRadius='100%'
          p={4}
          mb={2}
          mx={12}
          bg={isDisabled ? Color.DARK_GREY : backgroundColor}
          onMouseEnter={setIsHovered.on}
          onMouseLeave={setIsHovered.off}
          _hover={{
            cursor: isDisabled ? undefined : 'pointer',
            backgroundColor: isDisabled ? Color.DARK_GREY : hoverBackgroundColor
          }}
          onClick={() => { !isDisabled && onClick() }}
        >
          {iconWithProps}
        </Box>
        <Text textAlign='center' fontSize='sm' color={Color.DARK_BLUE}>{text}</Text>
      </Flex>
    </Tooltip>
  )
}
