import React, { type ReactElement } from 'react'
import ChecksFAQContent from './ChecksFAQContent'
import DropDownComponent from '@/library/layout/drop_down/DropDownComponent'
import InfoIcon from '@/library/icons/InfoIcon'
import { Color, IconSize } from '@/theme/theme'

export default function ChecksFAQComponent (): ReactElement {
  return (
    <DropDownComponent
      icon={<InfoIcon color={Color.DARK_BLUE} size={IconSize.SCHMEDIUM}/>}
      title='Check FAQs'
      dropDownContent={<ChecksFAQContent/>}
    />
  )
}
