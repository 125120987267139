import React, { type ReactElement } from 'react'
import { useMutation } from '@apollo/client'
import {
  Flex, Text,
  useToast
} from '@chakra-ui/react'
import ManageAccountsButtonComponent from './ManageAccountsButtonComponent'
import { isManageExistingAccountLinkEnabled } from './utils'
import { DELETE_PLAID_ITEM_ID } from '@/graphql/mutations/DeletePlaidItemId'
import {
  type DeletePlaidItemId,
  type DeletePlaidItemIdVariables
} from '@/graphql/__generated__/DeletePlaidItemId'
import PopoverEditDeleteMenu from '@/library/popoverMenu/components/PopoverEditDeleteMenu'
import { type GroupedFinancialAccounts } from '@/types/types'
import { getAccountString } from '@/utils/financialAccountUtils'
import { getErrorToast, getSuccessToast } from '@/utils/toastUtils'

interface AccountManagementOptionsComponentProps {
  franchiseGroupId: number
  institutionGroup: GroupedFinancialAccounts
  refetch: () => void
}

export default function AccountManagementOptionsComponent ({
  institutionGroup,
  franchiseGroupId,
  refetch
}: AccountManagementOptionsComponentProps): ReactElement {
  const [
    deletePlaidItemId,
    {
      loading: isDeleteAccountMutationLoading
    }
  ] = useMutation<DeletePlaidItemId, DeletePlaidItemIdVariables>(DELETE_PLAID_ITEM_ID, {
    variables: { plaidItemId: institutionGroup.plaidItemId },
    onCompleted: () => {
      refetch()
      toast(getSuccessToast('Accounts removed'))
    },
    onError: () => {
      toast(getErrorToast('Error removing accounts'))
    }
  })
  const toast = useToast()

  return (
    <Flex>
      <PopoverEditDeleteMenu
        editComponent={
          isManageExistingAccountLinkEnabled(institutionGroup.connectionProvider)
            ? <ManageAccountsButtonComponent
                franchiseGroupId={franchiseGroupId}
                handleBankAccountLinkUpdate={refetch}
                plaidAccessToken={institutionGroup.plaidAccessToken}
                plaidItemId={institutionGroup.plaidItemId}
                connectionProvider={institutionGroup.connectionProvider}
              />
            : undefined
        }
        isDisabled={isDeleteAccountMutationLoading}
        deleteTitle='Remove Accounts'
        deleteOnClick={() => { void deletePlaidItemId() }}
        deleteModalTitle='Are you sure you want to remove these accounts?'
        deleteModalContent={
          <Flex flexDir='column' gap={2}>
            {institutionGroup.accounts.map((account, index) => {
              return <Text key={index}>{getAccountString(account)}</Text>
            })}
          </Flex>
        }
      />
    </Flex>
  )
}
