import { Box, Menu, MenuList } from '@chakra-ui/react'
import React, { type ReactNode, type ReactElement } from 'react'
import PopoverMenuButton from './button/PopoverMenuButton'
import { type PopOverMenuButtonVariant } from './button/PopoverMenuButtonContent'
import AltirSkeleton from '../../loading/AltirSkeleton'
import { Color } from '@/theme/theme'

interface PopoverMenuProps {
  popoverMenuTitle: string
  onSubmit: () => void
  subtitle?: string
  isLoading?: boolean
  menuLoadingError?: Error
  children: ReactNode
  beforeIcon?: ReactElement
  hasSelection?: boolean

  // Styles
  buttonVariant?: PopOverMenuButtonVariant
  borderRadius?: string
  border?: string
  menuButtonHeight?: string
  shouldRotateMenuIcon?: boolean
  shouldMatchWidth?: boolean
  maxMenuHeight?: string | number
  allowInteriorScroll?: boolean
  paddingX?: number
  paddingY?: number

}

export default function PopoverMenu (
  {
    popoverMenuTitle,
    onSubmit,
    subtitle,
    isLoading,
    menuLoadingError,
    borderRadius,
    border,
    menuButtonHeight,
    allowInteriorScroll = false,
    maxMenuHeight = '75vh',
    shouldRotateMenuIcon = true,
    shouldMatchWidth,
    beforeIcon,
    hasSelection = false,
    paddingX,
    paddingY,
    children,
    buttonVariant
  }:
  PopoverMenuProps
): ReactElement {
  const derivedBorder = border != null ? border : hasSelection ? `1px solid ${Color.DARK_BLUE}` : 'none'
  return (
    <Menu variant='CheckBox' closeOnSelect={false} onClose={onSubmit} matchWidth={shouldMatchWidth} >
      {({ isOpen }) => (
        <>
          <PopoverMenuButton
            isOpen={isOpen}
            title={popoverMenuTitle}
            subtitle={subtitle}
            borderRadius={borderRadius}
            border={derivedBorder}
            height={menuButtonHeight}
            shouldRotateIcon={shouldRotateMenuIcon}
            beforeIcon={beforeIcon}
            paddingX={paddingX}
            paddingY={paddingY}
            variant={buttonVariant}
          />
          {allowInteriorScroll
            ? <MenuList maxHeight={maxMenuHeight} p={0} overflow='hidden'>
              <AltirSkeleton isLoading={isLoading ?? false} error={menuLoadingError} >
                {/* Scrolling within the box prevents the scrollbars from removing rounded corners */}
                <Box maxHeight={maxMenuHeight} overflowY='auto' p={2}>
                  {children}
                </Box>
              </AltirSkeleton>
            </MenuList>
            : <MenuList maxHeight={maxMenuHeight} p={4} overflow='hidden'>
              <AltirSkeleton isLoading={isLoading ?? false} error={menuLoadingError} >
                {/* Scrolling within the box prevents the scrollbars from removing rounded corners */}
                {children}
              </AltirSkeleton>
            </MenuList>
        }
        </>)}
    </Menu>
  )
}
