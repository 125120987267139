/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function MenuIconHorizontal ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 21 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M4.58594 2.39844C4.58594 3.50301 3.69051 4.39844 2.58594 4.39844C1.48137 4.39844 0.585938 3.50301 0.585938 2.39844C0.585938 1.29387 1.48137 0.398438 2.58594 0.398438C3.69051 0.398438 4.58594 1.29387 4.58594 2.39844Z' fill={color}/>
        <path d='M12.5859 2.39844C12.5859 3.50301 11.6905 4.39844 10.5859 4.39844C9.48137 4.39844 8.58594 3.50301 8.58594 2.39844C8.58594 1.29387 9.48137 0.398438 10.5859 0.398438C11.6905 0.398438 12.5859 1.29387 12.5859 2.39844Z' fill={color}/>
        <path d='M20.5859 2.39844C20.5859 3.50301 19.6905 4.39844 18.5859 4.39844C17.4814 4.39844 16.5859 3.50301 16.5859 2.39844C16.5859 1.29387 17.4814 0.398438 18.5859 0.398438C19.6905 0.398438 20.5859 1.29387 20.5859 2.39844Z' fill={color}/>
      </svg>
    </IconContainer>
  )
}
