/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function EditIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill={color} d='M5 16.247V18.6112C5 18.8289 5.17109 19 5.38884 19H7.75296C7.85406 19 7.95515 18.9611 8.02514 18.8833L16.5173 10.3989L13.6011 7.48268L5.11665 15.9671C5.03888 16.0448 5 16.1382 5 16.247ZM18.7726 7.04718L16.9528 5.22743C16.8809 5.15533 16.7954 5.09814 16.7013 5.05911C16.6073 5.02009 16.5064 5 16.4046 5C16.3027 5 16.2019 5.02009 16.1078 5.05911C16.0137 5.09814 15.9282 5.15533 15.8563 5.22743L14.4332 6.65057L17.3494 9.56684L18.7726 8.1437C18.8447 8.07175 18.9019 7.98629 18.9409 7.89222C18.9799 7.79814 19 7.69729 19 7.59544C19 7.49359 18.9799 7.39274 18.9409 7.29866C18.9019 7.20458 18.8447 7.11912 18.7726 7.04718Z'/>
      </svg>
    </IconContainer>
  )
}
