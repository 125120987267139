import React, { useEffect, useRef, type ReactElement } from 'react'
import * as d3 from 'd3'
import { Flex } from '@chakra-ui/react'
import { createSvgCanvas, formatLargeCurrency, reSampleData, selectEvenlySpacedValues } from '@/utils/chartUtils'
import { DateTimeTemplate, getFormattedDateString } from '@/utils/dateUtils'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { BorderRadius, Color } from '@/theme/theme'
import { type FinancialDataPoint } from '@/types/types'

const SLIDER_CIRCLE_RADIUS = 5

type SVG = d3.Selection<SVGGElement, unknown, null, undefined>

export interface LineGraphProps {
  data: FinancialDataPoint[]

  // SVG area
  parentWidth?: number
  parentHeight?: number

  // Styling
  isCompact?: boolean
  shouldHideSliderDisplayText?: boolean

  // Functions
  onValueChange?: (value?: FinancialDataPoint) => void

  // Data transforms
  allowReSampleData?: boolean
}

const TOOLTIP_AMOUNT_FONT_SIZE = 20
const TOOLTIP_DATE_FONT_SIZE = 12
const FONT_RATIO = 2 / 3 // Roughly the font height to width conversion
const COMPACT_FONT_RATIO = 1 / 2 // Roughly the font height to width conversion

const CURVE = d3.curveBumpX

export default function FinancialLineGraph ({
  data: rawData,
  parentWidth = 0,
  parentHeight = 0,
  isCompact = false,
  shouldHideSliderDisplayText = false,
  allowReSampleData = false,
  onValueChange = () => {}
}: LineGraphProps): ReactElement {
  const chartRef = useRef(null)

  useEffect(() => {
    if (parentWidth < 200 || rawData.length < 1) {
      return
    }
    // Set up SVG container
    const height = parentHeight
    const width = isCompact ? parentWidth : parentWidth * 0.9
    const paddingHorizontal = isCompact ? 10 : 90
    const paddingVertical = isCompact ? 0 : 20
    const horizontalShift = isCompact ? 0 : 10 // Shift right for axis if present
    const svg = createSvgCanvas(
      chartRef,
      height,
      width,
      paddingHorizontal,
      paddingVertical,
      SLIDER_CIRCLE_RADIUS,
      horizontalShift
    )

    // Data should have a tick distance at least equal to the slider circle diameter
    const desiredTicks = Math.floor(width / (SLIDER_CIRCLE_RADIUS * 2))
    const data = allowReSampleData ? reSampleData(rawData, desiredTicks) : rawData
    // Extract x and y values\
    const xValues = data.map((d) => d.date)
    const yValues = data.map((d) => d.amount)

    // Set up scales
    const xScale = d3.scalePoint().domain(xValues).range([0, width])
    const yScale = d3.scaleLinear().domain([0, d3.max(yValues) ?? 1]).range([height, 0])

    // Plot components
    if (!isCompact) {
      createYAxis(svg, yValues, yScale)
      createXAxis(svg, xValues, xScale, height)
    }

    createGradient(svg, data, xScale, yScale, height, CURVE)
    plotLine(svg, data, xScale, yScale, CURVE)
    createSlider(
      svg,
      xValues,
      yValues,
      xScale,
      yScale,
      height,
      width,
      onValueChange,
      isCompact || shouldHideSliderDisplayText
    )
  }, [rawData, parentWidth])

  return (
    <Flex
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
      w='100%'
    >
      <div ref={chartRef}></div>
    </Flex>
  )
}

function createXAxis (
  svg: SVG,
  xValues: string[],
  xScale: d3.ScalePoint<string>,
  height: number
): void {
  const xTickValues = selectEvenlySpacedValues(xValues, 4)
  svg
    .append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0, ${height})`)
    .call(d3.axisBottom(xScale)
      .tickValues(xTickValues)
      .tickFormat(d => { return getFormattedDateString(d, DateTimeTemplate.MONTH_YEAR_SHORT) ?? '' }))
    .attr('color', Color.GREY)

  styleAxisLabels(svg, '.x-axis')
}

function createYAxis (
  svg: SVG,
  yValues: number[],
  yScale: d3.ScaleLinear<number, number, never>
): void {
  const yTicksCount = 3
  const yTickValues = d3.ticks(0, d3.max(yValues) ?? 1, yTicksCount)
  svg
    .append('g')
    .attr('class', 'y-axis')
    .call(d3.axisLeft(yScale).tickValues(yTickValues).tickFormat(formatLargeCurrency))
    .attr('color', Color.GREY)

  styleAxisLabels(svg, '.y-axis')
}

function plotLine (
  svg: SVG,
  data: FinancialDataPoint[],
  xScale: d3.ScalePoint<string>,
  yScale: d3.ScaleLinear<number, number, never>,
  curve: d3.CurveFactory
): void {
  const line = d3
    .line<FinancialDataPoint>()
    .x((d) => (xScale(d.date) ?? 0))
    .y((d) => yScale(d.amount))
    .curve(curve)

  svg
    .append('g')
    .append('path')
    .datum(data)
    .attr('fill', 'none')
    .attr('stroke', Color.BRIGHT_BLUE)
    .attr('stroke-width', 1.5)
    .attr('d', line)
}

function styleAxisLabels (svg: SVG, className: string): void {
  svg.selectAll(`${className} text`)
    .style('text-anchor', 'center')
    .style('font-weight', 'bold')
    .style('font-size', '12px')
    .style('font-family', 'AltirCommons')
    .style('fill', Color.DARK_BLUE)
}

function createGradient (
  svg: SVG,
  data: FinancialDataPoint[],
  xScale: d3.ScalePoint<string>,
  yScale: d3.ScaleLinear<number, number, never>,
  height: number,
  curve: d3.CurveFactory
): void {
  // Set up area generator
  const area = d3
    .area<FinancialDataPoint>()
    .x((d) => xScale(d.date) ?? 0)
    .y0(height)
    .y1((d) => yScale(d.amount)!)
    .curve(curve)

  // Create linear gradient
  const gradient = svg
    .append('defs')
    .append('linearGradient')
    .attr('id', 'gradient')
    .attr('x1', '0%')
    .attr('y1', '0%')
    .attr('x2', '0%')
    .attr('y2', '100%')

  gradient
    .append('stop')
    .attr('offset', '0%')
    .style('stop-color', Color.BRIGHT_BLUE)
    .style('stop-opacity', 0.2)

  gradient
    .append('stop')
    .attr('offset', '100%')
    .style('stop-color', 'white')
    .style('stop-opacity', 0.2)

  // Draw the area under the curve with gradient fill
  svg
    .append('g')
    .append('path')
    .datum(data)
    .attr('d', area)
    .style('fill', 'url(#gradient)')
}

function createSlider (
  svg: SVG,
  xValues: string[],
  yValues: number[],
  xScale: d3.ScalePoint<string>,
  yScale: d3.ScaleLinear<number, number, never>,
  height: number,
  width: number,
  onValueChange: (value?: FinancialDataPoint) => void,
  isCompact: boolean
): void {
  const verticalLine = svg
    .append('line')
    .attr('class', 'vertical-line')
    .attr('stroke', Color.DARK_BLUE)
    .attr('stroke-width', 0) // init width to 0
    .attr('y1', 0)
    .attr('y2', height)

  const circle = svg
    .append('circle')
    .attr('cx', 300)
    .attr('cy', yScale(890))
    .attr('r', 0)
    .attr('fill', Color.DARK_BLUE)

  svg
    .append('rect')
    .style('fill', 'none')
    .style('pointer-events', 'all')
    .attr('width', width)
    .attr('height', height)
    .on('mousemove', updateVerticalLine)
    .on('mouseover', showSlider)
    .on('mouseout', destroySlider)

  svg
    .on('mousemove', updateVerticalLine)
    .on('mouseover', showSlider)
    .on('mouseout', destroySlider)

  const rectangle = svg.append('rect')
    .attr('fill', Color.WHITE)
    .attr('rx', BorderRadius.BAR)
    .attr('ry', BorderRadius.BAR)
    .style('filter', 'url(#drop-shadow)') // Apply drop shadow

  // Add drop shadow filter to rectangle
  svg.append('defs').append('filter')
    .attr('id', 'drop-shadow')
    .attr('height', '150%')
    .attr('width', '150%')
    .append('feDropShadow')
    .attr('dx', 0)
    .attr('dy', 4)
    .attr('stdDeviation', 4)
    .attr('flood-color', Color.DARK_GREY)
    .attr('flood-opacity', '0.2')

  const amountText = svg
    .append('text')
    .style('font-size', TOOLTIP_AMOUNT_FONT_SIZE)
    .style('font-weight', 'bold')
    .attr('text-anchor', 'middle')
  const dateText = svg
    .append('text')
    .style('font-size', TOOLTIP_DATE_FONT_SIZE)
    .style('font-weight', 'bold')
    .attr('text-anchor', 'middle')

  /**
   * Given a mouse coordinate, return the nearest x coordinate on our graph
   * + the corresponding y value
   */
  function getNearestPoint (mouseX: number): { x: number, y: number, index: number } {
    let minDistance = Number.MAX_SAFE_INTEGER
    let nearestIndex = -1
    let xPositionWithMinDistance = 0

    xValues.forEach((dataPoint, index) => {
      const xPosition = xScale(dataPoint)
      if (xPosition != null) {
        const distance = Math.abs(xPosition - mouseX)

        if (distance < minDistance) {
          minDistance = distance
          nearestIndex = index
          xPositionWithMinDistance = xPosition
        }
      }
    })
    return { x: xPositionWithMinDistance, y: yScale(yValues[nearestIndex] ?? 0), index: nearestIndex }
  }

  /**
   * On a mouse event, update the slider's height, position, and text
   */
  function updateVerticalLine (event: any): void {
    const mouseX = d3.pointer(event)[0]
    const { x, y, index } = getNearestPoint(mouseX)

    // Format displayed values
    const amount = yValues[index]
    const date = xValues[index]
    const formattedAmountText = amount != null ? getCurrencyFormatted(amount) : ''
    const formattedDateText = getFormattedDateString(date, DateTimeTemplate.FULL) ?? ''

    // Get bounding box dimensions
    const rectangleHeight = isCompact ? 25 : 55
    const rectangleWidth = getRectangleWidth(formattedDateText, formattedAmountText, isCompact)

    // When the graph nears the top of chart area, render the info box below the dot
    // When the info widget would exit the chart area, shift it horizontally back inside
    const isBelowLineOffset = getVerticalOffset(y, rectangleHeight)
    const horizontalSelectorOffset = getHorizontalOffset(x, rectangleWidth, width)

    // Update parent component with selected value
    if (amount != null && date != null) {
      onValueChange({ amount, date })
    }

    verticalLine
      .attr('x1', x)
      .attr('x2', x)
      .attr('y1', y)
      .attr('stroke-width', 2)

    circle
      .attr('cx', x)
      .attr('cy', y)
      .attr('r', SLIDER_CIRCLE_RADIUS)

    if (!isCompact) {
      amountText
        .attr('x', x + horizontalSelectorOffset)
        .attr('y', y - 25 + isBelowLineOffset)
        .text(formattedAmountText)

      dateText
        .attr('x', x + horizontalSelectorOffset)
        .attr('y', y - 50 + isBelowLineOffset)
        .text(`${formattedDateText}`)

      rectangle
        .attr('width', rectangleWidth)
        .attr('height', rectangleHeight)
        .attr('x', x - rectangleWidth / 2 + horizontalSelectorOffset)
        .attr('y', y - rectangleHeight - 15 + isBelowLineOffset)
    }
  }

  function showSlider (): void {
    verticalLine
      .style('opacity', 1)

    circle
      .style('opacity', 1)

    amountText
      .style('opacity', 1)

    dateText
      .style('opacity', 1)

    rectangle
      .style('opacity', 1)
  }

  function destroySlider (): void {
    onValueChange(undefined) // indicate to parent that no balance is selected
    verticalLine
      .style('opacity', 0)

    circle
      .style('opacity', 0)

    amountText
      .style('opacity', 0)

    dateText
      .style('opacity', 0)

    rectangle
      .style('opacity', 0)
  }
}

function getRectangleWidth (dateText: string, amountText: string, isCompact: boolean): number {
  const widestSliderText = isCompact ? dateText : amountText
  const fontRatio = isCompact ? COMPACT_FONT_RATIO : FONT_RATIO
  return widestSliderText.length * TOOLTIP_AMOUNT_FONT_SIZE * fontRatio
}

function getVerticalOffset (y: number, rectangleHeight: number): number {
  return y < rectangleHeight ? 1.45 * rectangleHeight : 0
}

function getHorizontalOffset (x: number, rectangleWidth: number, chartWidth: number): number {
  const isRightOfChartOffset = (x + (rectangleWidth / 2)) > chartWidth ? -50 : 0
  const isLeftOfChartOffset = (x - (rectangleWidth / 2)) < 0 ? 50 : 0
  return isRightOfChartOffset + isLeftOfChartOffset
}
