import React, { type ReactNode, type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import ErrorBoxDefaultContent from './ErrorBoxDefaultContent'
import { BorderRadius, Color } from '../theme/theme'

export interface ErrorBoxProps {
  isCompact?: boolean
  width?: string
  customMessage?: string[]
  customErrorContent?: ReactNode
  marginY?: number
}

export default function ErrorBox (
  {
    isCompact = false,
    width = '100%',
    marginY = 0,
    customMessage,
    customErrorContent
  }: ErrorBoxProps
): ReactElement {
  return (
    <Center
      borderRadius={BorderRadius.CARD}
      py={isCompact ? 6 : 32}
      flexDirection='column'
      width={width}
      backgroundColor={Color.GREY}
      flexDir='column'
      gap={2}
      my={marginY}
      px={4}
    >
      {
        customErrorContent != null
          ? customErrorContent
          : <ErrorBoxDefaultContent customMessage={customMessage} isCompact={isCompact}/>
      }
    </Center>
  )
}
