import React, { type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import AmplifyApplicationContainerComponent, { AmplifyApplicationContext } from './AmplifyApplicationContainerComponent'
import AmplifyApplicationPageHeaderContent from './components/text/AmplifyApplicationPageHeaderContent'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import OnboardingPage from '@/library/page/OnboardingPage'
import { useAmplifyAppNavigation } from '@/hooks/onboarding/useAmplifyAppNavigation'
import { ContainerWidth } from '@/theme/theme'

export default function AmplifyApplicationPage (): ReactElement {
  const franchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const { skipCallToAction } = useAmplifyAppNavigation(String(franchiseGroupId))

  return (
    <OnboardingPage
      callToAction={skipCallToAction}
      maxWidth={ContainerWidth.EXTRA_LARGE}
      amplifyApplicationContext={{ franchiseGroupId }}
    >
      <Center flexDir='column' gap={8} maxW={ContainerWidth.EXTRA_LARGE}>
        <AmplifyApplicationPageHeaderContent/>
        <AmplifyApplicationContainerComponent
          franchiseGroupId={franchiseGroupId}
          context={AmplifyApplicationContext.DEFAULT}
        />
      </Center>
    </OnboardingPage>
  )
}
