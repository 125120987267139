
import { gql } from '@apollo/client'

export const GET_ONBOARDING_USER_EMAIL_VERIFICATION_STATUS = gql`
    query GetOnboardingUserEmailVerificationStatus {
        currentUser {
            id
            emailVerificationStatus
        }
    }
`
