import React, { useState, type ReactElement } from 'react'
import { useMutation } from '@apollo/client'
import { Center, Checkbox, Flex, Text } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import { useNavigate } from 'react-router-dom'
import PersonApplicationRow from './PersonApplicationRow'
import { type AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import Button, { ButtonVariant } from '@/library/button/Button'
import PlusIcon from '@/library/icons/PlusIcon'
import { CREATE_AMPLIFY_ACCOUNT_PERSONAL_APPLICATION } from '@/graphql/mutations/AmplifyApplicationMutations'
import {
  type CreateAmplifyAccountPersonalApplicationVariables,
  type CreateAmplifyAccountPersonalApplication
} from '@/graphql/__generated__/CreateAmplifyAccountPersonalApplication'
import {
  // eslint-disable-next-line max-len
  type GetAmplifyAccountApplication_currentUser_franchiseGroup_amplifyAccountApplication_personalApplications as PersonalApplicationData
} from '@/graphql/__generated__/GetAmplifyAccountApplication'
import { Color, IconSize } from '@/theme/theme'
import {
  AmplifyApplicationStep,
  getAmplifyApplicationRouteForContext,
  hasBeneficialOwner,
  isPrimaryOwner
} from '@/utils/amplifyApplicationUtils'
import ErrorInline from '@/library/errors/ErrorInline'
import { routeWithParams } from '@/utils/routerUtils'
import { RouteParam } from '@/api/routes'
import { type ErrorWithContent } from '@/types/types'
import { ErrorCopy } from '@/utils/errorUtils'
import { AmplifyAccountApplicationStatus } from '@/graphql/__generated__/globalTypes'

export interface PersonApplicationGroupComponentProps {
  franchiseGroupId: number
  amplifyApplicationId?: string
  personalApplications: PersonalApplicationData[]
  context: AmplifyApplicationContext
  onStateUpdate: () => void
}

const OWNER_CREATION_ERROR_SUBTITLE = 'We were unable to add an owner. Please try again later or contact support.'

export default function PersonApplicationRowGroup (
  {
    franchiseGroupId,
    amplifyApplicationId,
    personalApplications,
    context,
    onStateUpdate
  }: PersonApplicationGroupComponentProps
): ReactElement {
  const navigate = useNavigate()
  const [error, setError] = useState<ErrorWithContent | undefined>()
  const [
    createPersonalApplication,
    { loading }
  ] = useMutation<CreateAmplifyAccountPersonalApplication, CreateAmplifyAccountPersonalApplicationVariables>(
    CREATE_AMPLIFY_ACCOUNT_PERSONAL_APPLICATION,
    {
      onCompleted: onStateUpdate,
      onError: (mutationError) => {
        setError({
          customContent: {
            title: ErrorCopy.SOMETHING_WENT_WRONG,
            subtitle: OWNER_CREATION_ERROR_SUBTITLE
          },
          error: mutationError
        })
      }
    }
  )
  const [
    isBeneficialOwnershipNotApplicable,
    setIsBeneficialOwnershipNotApplicable
  ] = useState(false)

  async function handleAddAnOwnerClick (): Promise<void> {
    if (amplifyApplicationId == null) {
      setError({
        customContent: {
          title: ErrorCopy.SOMETHING_WENT_WRONG,
          subtitle: OWNER_CREATION_ERROR_SUBTITLE
        },
        error: Error('amplifyApplicationId must not be null')
      })
      return
    }
    const variables: CreateAmplifyAccountPersonalApplicationVariables = {
      amplifyApplicationId
    }
    void createPersonalApplication({ variables })
  }

  function onContinueClick (): void {
    const baseRoute = getAmplifyApplicationRouteForContext(AmplifyApplicationStep.OWNERSHIP_ATTESTATION, context)

    navigate(routeWithParams(
      baseRoute,
      [
        { param: RouteParam.BUSINESS_ID, value: String(franchiseGroupId) },
        { param: RouteParam.AMPLIFY_APPLICATION_ID, value: String(amplifyApplicationId) }
      ]
    ))
  }

  // Ensure that the control person is at start of the list
  const controlPerson = personalApplications.filter(application => isPrimaryOwner(application))
  const managers = personalApplications.filter(application => !isPrimaryOwner(application))
  personalApplications = controlPerson.concat(managers)

  const doesNotHaveBeneficialOwner = !hasBeneficialOwner(personalApplications)

  const areAllApplicationsSubmitted = personalApplications
    .every(app => app.status === AmplifyAccountApplicationStatus.SUBMITTED)

  const isFormValid = (personalApplications.length > 0 && areAllApplicationsSubmitted) ||
    isBeneficialOwnershipNotApplicable

  return (
    <Center flexDir='column' gap={8} w='100%'>
      <Center flexDir='column' gap={4} w='100%'>
        <Center flexDir='column' gap={2} w='100%'>
          {
          personalApplications.map((personalApp, i) =>
            <PersonApplicationRow
              franchiseGroupId={franchiseGroupId}
              key={personalApp.id}
              personalApplication={personalApp}
              index={i}
              context={context}
            />
          )
        }
        </Center>
        <Button
          text='Add an Owner or Manager'
          variant={ButtonVariant.WHITE_OUTLINE}
          beforeIcon={<PlusIcon color={Color.DARK_BLUE} size={IconSize.SMALL}/>}
          onClickEventType={Event.ADD_PERSON_APPLICATION_CLICK}
          isDisabled={isBeneficialOwnershipNotApplicable}
          isLoading={loading}
          onClick={handleAddAnOwnerClick}
        />
        <ErrorInline
          error={error}
        />
      </Center>
      {
        doesNotHaveBeneficialOwner && <Flex gap={4}>
          <Checkbox
            isChecked={isBeneficialOwnershipNotApplicable}
            onChange={() => { setIsBeneficialOwnershipNotApplicable(!isBeneficialOwnershipNotApplicable) }}
            size='lg'
            colorScheme='selectableInput'
          />
          <Text color={Color.DARK_BLUE}>
            If checked, the Beneficial Owner listing requirement is not applicable to this business.
          </Text>
        </Flex>
      }
      <Button
        text='Save & Continue'
        isDisabled={!isFormValid}
        onClick={onContinueClick}
      />
    </Center>
  )
}
