import React, { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { BALANCE_TAB, TIME_TAB } from '../TransferRuleCreationPage'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { Color, IconSize } from '@/theme/theme'
import PlusIcon from '@/library/icons/PlusIcon'
import { ROUTES } from '@/api/routes'
import EmptyStateCard from '@/library/card/EmptyStateCard'
import { GenericTransferRuleType } from '@/utils/transferRuleUtils'

export interface TransferRuleEmptyStateComponentProps {
  ruleType: GenericTransferRuleType
}
export default function TransferRuleEmptyStateComponent (
  { ruleType }:
  TransferRuleEmptyStateComponentProps
): ReactElement {
  const ruleTypeHeader = ruleType === GenericTransferRuleType.BALANCE ? 'Balance-Based' : 'Time-Based'
  const descriptionSuffix = ruleType === GenericTransferRuleType.BALANCE
    ? 'when an account balance exceeds a certain threshold.'
    : 'at a daily, weekly, or monthly cadence.'

  const tabIndex = ruleType === GenericTransferRuleType.BALANCE ? BALANCE_TAB : TIME_TAB

  const navigate = useNavigate()

  return (
    <EmptyStateCard
      title={`No ${ruleTypeHeader} Rules`}
      subTitle={`Automate a transfer ${descriptionSuffix}`}
    >
      <Button
        text={`Add a ${ruleTypeHeader} Rule`}
        size={ButtonSize.MEDIUM}
        beforeIcon={<PlusIcon color={Color.DARK_BLUE} size={IconSize.SMALL}/>}
        variant={ButtonVariant.WHITE_GREY_BACKGROUND}
        onClick={() => { navigate(ROUTES.CREATE_AUTOMATED_TRANSFER_RULE, { state: { startingTab: tabIndex } }) }}
      />
    </EmptyStateCard>
  )
}
