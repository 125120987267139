import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import FixedInsightCard from './FixedInsightCard'
import FixedInsightsSectionUpsellComponent from './FixedInsightsSectionUpsellComponent'
import DashboardSection from './layout/DashboardSection'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { GET_FIXED_METRICS } from '@/graphql/queries/GetInsightsData'
import {
  type GetFixedMetrics_currentUser_franchiseGroup_fixedInsightMetrics_idleCashPercentage as IdleCashPercentage,
  type GetFixedMetrics,
  // eslint-disable-next-line max-len
  type GetFixedMetrics_currentUser_franchiseGroup_fixedInsightMetrics_compoundGrowthRateMonthEarningProjection as Projection,
  type GetFixedMetricsVariables
} from '@/graphql/__generated__/GetFixedMetrics'
import { Color } from '@/theme/theme'

// eslint-disable-next-line max-len
const AVERAGE_IDLE_CASH_TOOLTIP = 'Your “Average Idle Cash” is the amount that remains unused by your day to day operations across the entire month. We take a 6-month average to determine the amount in excess of the operating needs.'
const IDLE_CASH_THRESHOLD = 0.05
// eslint-disable-next-line max-len
const NET_CASH_TOOLTIP = 'Your “Net Cash” is an indicator of your business’s liquidity. It is calculated by subtracting your total current credit card liabilities (outstanding balance) from your total cash.'

// eslint-disable-next-line max-len
const COMPOUND_MONTHLY_GROWTH_RATE_TOOLTIP = '“Compounded Monthly Growth Rate” (CMGR) is a metric, shown as a percentage, that expresses the average growth rate of your business over a specific period. We calculate your CMGR over the past 6-months.'

interface FixedInsightsSectionProps {
  franchiseGroupId: number
}

export default function FixedInsightsSection ({ franchiseGroupId }: FixedInsightsSectionProps): ReactElement {
  const { data, loading, error } = useQuery<GetFixedMetrics, GetFixedMetricsVariables>(
    GET_FIXED_METRICS,
    { variables: { franchiseGroupId } }
  )
  const metrics = data?.currentUser?.franchiseGroup?.fixedInsightMetrics
  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <DashboardSection title='My Insights'>
        <Flex w='100%' gap={3}>
          <FixedInsightCard
            label='Average Idle Cash'
            metric={metrics?.idleCash?.amountFormatted ?? null}
            description={getIdleCashDescription(metrics?.idleCashPercentage)}
            moreInfoText={AVERAGE_IDLE_CASH_TOOLTIP}
          />
          <FixedInsightCard
            label='Net Cash'
            metric={metrics?.netCash?.amountFormatted ?? null}
            description={getNetCashDescription(metrics?.netCash?.amount)}
            moreInfoText={NET_CASH_TOOLTIP}
          />
          <FixedInsightCard
            label='Compound Monthly Growth Rate'
            metric={metrics?.compoundGrowthRate?.rateFormatted ?? null}
            description={
              getCmgrDescription(
                metrics?.compoundGrowthRate?.rate ?? null,
                metrics?.compoundGrowthRateMonthEarningProjection
              )
            }
            moreInfoText={COMPOUND_MONTHLY_GROWTH_RATE_TOOLTIP}
          />
          <FixedInsightsSectionUpsellComponent
            idleCash={metrics?.idleCash ?? null}
            compoundGrowthRate={metrics?.compoundGrowthRate ?? null}
            idleCashPotentialEarnings={metrics?.idleCashPotentialEarnings ?? null}
          />
        </Flex>
      </DashboardSection>
    </AltirSkeleton>
  )
}

function getIdleCashDescription (idleCashPercentage?: IdleCashPercentage | null): ReactElement | null {
  if (idleCashPercentage == null) return null
  else if (idleCashPercentage.rate != null && idleCashPercentage.rate < IDLE_CASH_THRESHOLD) {
    return (
      <Text color={Color.DARK_BLUE}>
        Less than {' '}
        <Text as='span' color={Color.DARK_GREY}>
          {idleCashPercentage.rateFormatted}
        </Text>
        {' '}of cash in your accounts is idle.
      </Text>
    )
  }
  return (
    <Text color={Color.DARK_BLUE}>
      <Text as='span' color={Color.ERROR_RED}>
        {idleCashPercentage.rateFormatted}
      </Text>
      {' '}of cash in your accounts is idle cash. We suggest earning interest on it.
    </Text>
  )
}

function getNetCashDescription (netCash?: number | null): ReactElement | null {
  if (netCash == null || netCash === 0) return null
  const description = netCash > 0
    ? 'Your cash balance exceeds your credit card liabilities, looking good.'
    : 'Your credit card liabilities exceed your cash balance, keep an eye on this.'
  return <Text color={Color.DARK_BLUE}>{description}</Text>
}

function getCmgrDescription (cmgr: number | null, cmgrProjection?: Projection | null): ReactElement | null {
  if (cmgrProjection == null || cmgr == null) return null
  const isPositive = cmgr > 0
  return <Text color={Color.DARK_BLUE}>
    You are projected to {isPositive ? 'gain' : 'lose'}{' '}
    <Text as='span' color={isPositive ? Color.SUCCESS_GREEN : Color.ERROR_RED}>{cmgrProjection.amountFormatted}</Text>
    {' '}next month, based on your last 6 months of performance.
  </Text>
}
