import React, { type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { CHECK_SEND } from '@/theme/graphics'
import { Graphic } from '@/library/utility/Graphic'
import Button from '@/library/button/Button'

interface CheckEnablementConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
}

const MODAL_SUBTITLE = `
    We will review your request and email next steps to you within 1-2 business days.
`

export default function CheckEnablementConfirmationModal ({
  isOpen,
  onClose
}: CheckEnablementConfirmationModalProps): ReactElement {
  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
    >
      <Center w='100%' flexDir='column' gap={8}>
        <Graphic src={CHECK_SEND} h='50px'/>
        <Center w='100%' flexDir='column' gap={1} textAlign='center'>
          <Heading>You have requested access to Check Send.</Heading>
          <Text>{MODAL_SUBTITLE}</Text>
        </Center>
        <Button text='Done' onClick={onClose}/>
      </Center>
    </ModalComponent>
  )
}
