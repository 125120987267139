import { gql } from '@apollo/client'

export const GET_ORGANIZATION_OPTIONS = gql`
query GetOrganizationOptions {
    currentUser {
        organizations {
            id
            name
            franchiseGroups {
                id
            }
        }
    }
  }
`
