import React, { useState, type ReactElement } from 'react'
import { Flex, Heading, Link, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import {
  type GetHomePageData_currentUser_selectedOrganization_franchiseGroups as Business

} from '@/graphql/__generated__/GetHomePageData'
import { BorderRadius, Color, FontWeight, IconSize } from '@/theme/theme'
import { AvatarComponent } from '@/library/utility/AvatarComponent'
import { getCurrencyFormatted, safeGetFirstLetter } from '@/utils/stringUtils'
import PopoverIconMenu from '@/library/popoverMenu/PopoverIconMenu'
import { EMPTY_BALANCE_VALUE } from '@/utils/constants'
import { ROUTES } from '@/api/routes'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { nonNull } from '@/utils/arrayUtils'
import { getBusinessPageRoute } from '@/utils/routerUtils'
import { getAmplifyApplicationCTA } from '@/utils/amplifyApplicationUtils'
import AmplifyWaitlistModal from '@/library/modal/amplify/AmplifyWaitlistModal'

interface BusinessInfoRowProps {
  business: Business
  isOnAmplifyWaitlist: boolean
}

export default function BusinessInfoRow ({ business, isOnAmplifyWaitlist }: BusinessInfoRowProps): ReactElement {
  const navigate = useNavigate()
  const setSelectedFranchiseGroupId = useAltirStore(state => state.setSelectedFranchiseGroupId)

  const [isClickableChildElementFocused, setIsClickableChildElementFocused] = useState(false)
  const [isWaitlistModalOpen, setIsWaitlistModalOpen] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  function onRowClick (): void {
    if (!isClickableChildElementFocused) {
      setSelectedFranchiseGroupId(Number(business.id))
      navigate(getBusinessPageRoute(business.id))
    }
  }

  const applicationStatus = business.amplifyAccountApplicationStatus
  const aggregateBalance = business.aggregateAccountBalance?.availableBalance?.amount
  const accounts = business.financialAccounts ?? []
  const amplifyAccounts = accounts.filter(account => account.amplifyAccount?.id != null)
  const popoverMenuOptions = nonNull([
    amplifyAccounts.length < 1
      ? {
          text: 'Go To Amplify Application',
          onClick: () => {
            setSelectedFranchiseGroupId(Number(business.id))
            navigate(ROUTES.AMPLIFY_APP_APPLY)
          }
        }
      : undefined,
    {
      text: 'Manage Business Settings',
      onClick: () => {
        setSelectedFranchiseGroupId(Number(business.id))
        navigate(ROUTES.SETTINGS, { state: { isBusinessSettingPreSelected: true } })
      }
    }
  ])

  return (
    <Flex
      bg={Color.WHITE}
      borderRadius={BorderRadius.CARD}
      px={6}
      py={4}
      alignItems='center'
      justifyContent='space-between'
      _hover={!isClickableChildElementFocused ? { cursor: 'pointer', bg: Color.GREY } : {}}
      onClick={onRowClick}
      onMouseEnter={() => { setIsHovered(true) }}
      onMouseLeave={() => { setIsHovered(false) }}
    >
      <Flex alignItems='center' gap={6}>
        <AvatarComponent
          text={safeGetFirstLetter(business.name)}
          size={IconSize.SMALL}
          backgroundColor={isHovered ? Color.WHITE : Color.LIGHT_GREY}
        />
        <Flex flexDir='column'>
          <Text color={Color.DARK_BLUE}>{business.name}</Text>
          <Text fontSize='sm'>{accounts.length} Total Accounts</Text>
          {amplifyAccounts.length < 1
            ? <Flex
                onMouseEnter={() => { setIsClickableChildElementFocused(true) }}
                onMouseLeave={() => { setIsClickableChildElementFocused(false) }}
              >
              <Link
                onClick={() => { !isOnAmplifyWaitlist && setIsWaitlistModalOpen(true) }}
              >
                <Text
                  fontSize='sm'
                  color={Color.GREY_BLUE}
                  fontWeight={FontWeight.MEDIUM}
                >
                  {getAmplifyApplicationCTA(isOnAmplifyWaitlist, applicationStatus)}
                </Text>
              </Link>
            </Flex>
            : <Text fontSize='sm'>{getNumberOfAccountsText(amplifyAccounts.length)}</Text>
          }
        </Flex>
      </Flex>
      <Flex alignItems='center' textAlign='end' justifyContent='end' gap={6}>
        <Heading size='md'>
          {aggregateBalance != null ? getCurrencyFormatted(aggregateBalance) : EMPTY_BALANCE_VALUE}
        </Heading>
        <Flex
          borderRadius='100%'
          onMouseEnter={() => { setIsClickableChildElementFocused(true) }}
          onMouseLeave={() => { setIsClickableChildElementFocused(false) }}
        >
          <PopoverIconMenu
            menuOptions={popoverMenuOptions}
          />
        </Flex>
      </Flex>
      <AmplifyWaitlistModal isOpen={isWaitlistModalOpen} onClose={() => { setIsWaitlistModalOpen(false) }}/>
    </Flex>
  )
}

function getNumberOfAccountsText (numberOfAccounts: number): string {
  return numberOfAccounts === 1
    ? '1 Amplify Account'
    : `${numberOfAccounts} Amplify Accounts`
}
