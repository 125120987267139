import { gql } from '@apollo/client'

export const GET_ACTIVE_TRANSFER_RULES = gql`
  query GetActiveTransferRules($franchiseGroupId: Int!) {
    currentUser {
      franchiseGroup(id: $franchiseGroupId) {
        transferRules(isActive: true) {
            id
        }
      }
    }
  }
`
