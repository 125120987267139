/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function PlusIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M7.5 1V13' stroke={color} strokeWidth='1.66405' strokeLinecap='round'/>
        <path d='M13.5 7L1.5 7' stroke={color} strokeWidth='1.66405' strokeLinecap='round'/>
      </svg>
    </IconContainer>
  )
}
