import React, { type ReactElement } from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import { Color, IconSize } from '@/theme/theme'
import CarrotComponent from '@/library/utility/toggle/CarrotComponent'
import { IconFacing } from '@/types/types'
import CirclePlusOutlineIcon from '@/library/icons/CirclePlusOutlineIcon'

export default function ChooseAnAccountContent (): ReactElement {
  return (
    <Flex alignItems='center' justifyContent='start' gap={3} width='100%'>
      <Flex px={1}>
        <CirclePlusOutlineIcon color={Color.DARK_GREY} size={IconSize.MEDIUM}/>
      </Flex>
      <Heading size='sm' color={Color.DARK_GREY} flex={8}>Choose an Account</Heading>
      <Flex
        justifyContent='end'
        alignItems='center'
      >
        <CarrotComponent facing={IconFacing.RIGHT} color={Color.DARK_GREY} size={IconSize.SMALL}/>
      </Flex>
    </Flex>
  )
}
