import { DateTime } from 'luxon'
import { create, useStore } from 'zustand'
import { persist } from 'zustand/middleware'
import { createContext, useContext } from 'react'
import { beginningOfTime } from '@/utils/dateUtils'

const PASSCODE_COOLDOWN_MINUTES = 10

const ALTIR_STORE_NAME = 'AltirStore'

interface InitState {
  userId: number
  defaultFranchiseGroupId: number
  defaultSelectedOrganizationState: OrganizationState
}

export interface OrganizationState {
  selectedOrganizationId: string
  availableFranchiseGroupIds: number[]
}

export interface PersistedAltirState {
  // Passcode State
  passcodeCooldownTimeISO: string
  startPasscodeCooldownWindow: () => void

  // User State
  userId: number

  // Business & Organization State
  selectedFranchiseGroupId: number
  setSelectedFranchiseGroupId: (id: number) => void
  selectedOrganizationState: OrganizationState
  setSelectedOrganizationState: (organizationState: OrganizationState) => void
  resetFranchiseGroupAndOrgToDefault: () => void
}
export type AltirStore = ReturnType<typeof createAltirStore>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createAltirStore = ({
  userId,
  defaultSelectedOrganizationState,
  defaultFranchiseGroupId
}: InitState) => {
  return create<PersistedAltirState>()(
    persist(
      (set) => ({
        userId,
        passcodeCooldownTimeISO: beginningOfTime().toISO() as string,
        selectedFranchiseGroupId: defaultFranchiseGroupId,
        selectedOrganizationState: defaultSelectedOrganizationState,
        startPasscodeCooldownWindow: () => {
          set(() => ({
            passcodeCooldownTimeISO: DateTime.now().plus({ minutes: PASSCODE_COOLDOWN_MINUTES }).toISO()!
          }))
        },
        setSelectedOrganizationState: (organizationState: OrganizationState) => {
          set({ selectedOrganizationState: organizationState })
        },
        setSelectedFranchiseGroupId: (id: number) => {
          set((prev) => {
            if (prev.selectedOrganizationState.availableFranchiseGroupIds.includes(id)) {
              return { selectedFranchiseGroupId: id }
            }
            return { selectedFranchiseGroupId: prev.selectedFranchiseGroupId }
          })
        },
        resetFranchiseGroupAndOrgToDefault: () => {
          set({
            selectedFranchiseGroupId: defaultFranchiseGroupId,
            selectedOrganizationState: defaultSelectedOrganizationState
          })
        }
      }),
      { name: ALTIR_STORE_NAME }
    )
  )
}

export const AltirContext = createContext<AltirStore | null>(null)

export function useAltirStore<T> (selector: (state: PersistedAltirState) => T): T {
  const store = useContext(AltirContext)
  if (store == null) throw new Error('Missing AltirContext.Provider in the tree')
  return useStore(store, selector)
}

/**
 * Fully clears the zustand store.
 *
 * Note: only use this method when outside of the AltirContextLayout.
 *  When possible, rely on resetFranchiseGroupAndOrgToDefault
 */
export function clearAltirStoreFromOutsideOfStoreContext (): void {
  localStorage.removeItem(ALTIR_STORE_NAME)
}
