import React from 'react'
import { type ReactElement } from 'react'
import { FormSelect, type FormSelectAlign } from './FormSelect'
import { Color, FormInputHeight } from '../../theme/theme'

export interface SelectorComponentProps<T> {
  // Value Props
  value?: T | null
  options: T[]
  formatOptions?: (option?: T | null) => string
  handleSelection: (state: T) => void

  // Label props
  fieldName?: string
  label?: string
  placeHolder?: string
  fieldError?: string | null
  isRequired?: boolean

  // Style Props
  alignText?: FormSelectAlign
  backgroundColor?: string
  hasBorder?: boolean
  height?: FormInputHeight
}

/**
 * The selector component accepts options of type T and a formatting function of type (T) => string
 * that allows it to display options in a formatted manner while maintaining state in type T
 */
export default function SelectorComponent<T> (
  {
    fieldName = '',
    value,
    label,
    placeHolder = '',
    handleSelection,
    options,
    alignText,
    fieldError,
    backgroundColor,
    hasBorder = false,
    height = FormInputHeight.DEFAULT,
    formatOptions = (option) => String(option),
    isRequired = false
  }: SelectorComponentProps<T>
): ReactElement {
  function onOptionSelect (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void {
    const { value } = event.target
    const state = options[(Number(value))]
    if (state != null) {
      handleSelection(state)
    }
  }
  function getOptions (): ReactElement {
    return (
      <>
        {
          options
            .map(formatOptions)
            .map((option, i) => (<option value={i} key={option}>{option}</option>))
        }
      </>
    )
  }

  function getSelectedOptionIndex (value?: T | null): number {
    return options
      .map(formatOptions)
      .findIndex(option => option === formatOptions(value))
  }

  const color = value != null ? Color.DARK_BLUE : Color.DARK_GREY

  return (
    <FormSelect
      fieldName={fieldName}
      label={label}
      onChange={onOptionSelect}
      value={getSelectedOptionIndex(value)}
      placeholder={placeHolder}
      alignText={alignText}
      color={color}
      fieldError={fieldError}
      backgroundColor={backgroundColor}
      hasBorder={hasBorder}
      height={height}
      isRequired={isRequired}
    >
      {getOptions()}
    </FormSelect>
  )
}
