import React, { type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import CardInfoComponent from './CardInfoComponent'
import BillingInfoComponent from './BillingInfoComponent'
import SubscriptionHistoryComponent from './SubscriptionHistoryComponent'
import SubscriptionDetailsComponent from './subscription_details/SubscriptionDetailsComponent'
import EmptySubscriptionComponent from './subscription_details/EmptySubscriptionComponent'
import AltirSubscriptionComponent from './subscription_details/AltirSubscriptionComponent'
import {
  type GetAltirSubscriptionDetails
} from '@/graphql/__generated__/GetAltirSubscriptionDetails'

export interface OrganizationBillingComponentProps {
  subscriptionDetails?: GetAltirSubscriptionDetails
}

export default function OrganizationBillingComponent (
  subscriptionDetails?: OrganizationBillingComponentProps
): ReactElement {
  if (subscriptionDetails == null) {
    return <EmptySubscriptionComponent/>
  }
  const subscription = subscriptionDetails?.subscriptionDetails
  const stripeSubscription = subscription?.AltirSubscription?.subscription

  if (stripeSubscription == null) return <AltirSubscriptionComponent/>

  return (
    <Center flexDir='column' gap={6} w='full'>
      <SubscriptionDetailsComponent subscriptionDetails={subscription}/>
      <CardInfoComponent subscriptionDetails={subscription}/>
      <BillingInfoComponent subscriptionDetails={subscription}/>
      <SubscriptionHistoryComponent subscriptionDetails={subscription}/>
    </Center>
  )
}
