import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'
import { CheckEnablementStatus, TransferType } from '@/graphql/__generated__/globalTypes'
import { type PopoverMenuItemContent } from '@/library/popoverMenu/PopoverMenuSingleOptionSelect'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import {
  ALTIR_CHECK_PROCESSING_FEE,
  ALTIR_WIRE_PROCESSING_FEE,
  isCounterparty
} from '@/utils/transferUtils'

const ACH_DROPDOWN_TITLE = 'ACH (Free, Next Business Day)'
const ACH_DROPDOWN_SUBTITLE = `
  ACH transfers initiated before 3:00pm EST will be accessible the following business day.
  ACH transfers initiated after 3:00pm EST will be accessible in two business days.
`

const WIRE_DROPDOWN_TITLE = `Wire ($${ALTIR_WIRE_PROCESSING_FEE} Fee, Same Business Day)`
const WIRE_DROPDOWN_SUBTITLE = `
  Wire transfers initiated before 5:30pm EST will be accessible the same business day. 
  Wire transfers initiated after 5:30pm EST will be accessible the next business day.
`

const CHECK_DROPDOWN_TITLE = `Check (${getCurrencyFormatted(ALTIR_CHECK_PROCESSING_FEE)} Fee, Mailed within 2 Business Days)`
const CHECK_DROPDOWN_SUBTITLE = `
  A paper check is mailed within 2 business days via USPS First Class Mail. 
  The check is then delivered within 4-6 business days. 
`

export function getAvailableTransferTypes (
  selectedSourceAccount: FinancialAccountExtendedFragment | null,
  isCheckIssuanceEnabled: boolean,
  checkEnablementStatus?: CheckEnablementStatus
): TransferType[] {
  if (selectedSourceAccount != null && isCounterparty(selectedSourceAccount)) {
    return [TransferType.SAMEDAY_ACH]
  }

  return isCheckIssuanceEnabled && checkEnablementStatus === CheckEnablementStatus.ENABLED
    ? [TransferType.SAMEDAY_ACH, TransferType.WIRE, TransferType.CHECK]
    : [TransferType.SAMEDAY_ACH, TransferType.WIRE]
}

export function getFormattedDropdownForType (type: TransferType): PopoverMenuItemContent {
  switch (type) {
    case TransferType.CHECK:
      return {
        title: CHECK_DROPDOWN_TITLE,
        subtitle: CHECK_DROPDOWN_SUBTITLE
      }
    case TransferType.SAMEDAY_ACH:
      return {
        title: ACH_DROPDOWN_TITLE,
        subtitle: ACH_DROPDOWN_SUBTITLE
      }
    case TransferType.WIRE:
      return {
        title: WIRE_DROPDOWN_TITLE,
        subtitle: WIRE_DROPDOWN_SUBTITLE
      }
    default:
      console.error('Unsupported transfer type for transfer page selector')
      return { title: '', subtitle: '' }
  }
}
