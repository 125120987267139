import { type CacheLocation } from '@auth0/auth0-react'

const AUTH_CONNECTION_SMS = 'sms'
const AUTH_CACHE_LOCATION = 'localstorage'

interface AuthConfig {
  audience: string
  clientId: string
  domain: string
  connection?: string
  cacheLocation: CacheLocation
}

export const authConfig: AuthConfig = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE !== null &&
  process.env.REACT_APP_AUTH0_AUDIENCE !== undefined
    ? `https://${process.env.REACT_APP_AUTH0_AUDIENCE}`
    : '',
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID !== null &&
  process.env.REACT_APP_AUTH0_CLIENT_ID !== undefined
    ? process.env.REACT_APP_AUTH0_CLIENT_ID
    : '',
  domain: process.env.REACT_APP_AUTH0_DOMAIN !== null &&
  process.env.REACT_APP_AUTH0_DOMAIN !== undefined
    ? `https://${process.env.REACT_APP_AUTH0_DOMAIN}`
    : '',
  connection: AUTH_CONNECTION_SMS,
  cacheLocation: AUTH_CACHE_LOCATION
}
