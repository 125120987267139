import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import InfoBoxComponent from '../InfoBoxComponent'
import AccountInfoRowComponent, { AccountInfoRowType } from '../AccountInfoRowComponent'
import {
  type GetAmplifyOwnerData_currentUser_franchiseGroup_amplifyAccount_amplifyAccount_business as Business
} from '@/graphql/__generated__/GetAmplifyOwnerData'
import { AccountInfoUpdateField } from '@/graphql/__generated__/globalTypes'

export interface AmplifyAccountBusinessComponentProps {
  business: Business
}

export default function AmplifyAccountBusinessComponent (
  { business }: AmplifyAccountBusinessComponentProps
): ReactElement {
  return (
    <InfoBoxComponent label='About the Business'>
      <Flex flexDir='column' gap={3}>
        <Flex justifyContent='space-between' textAlign='start'>
          <AccountInfoRowComponent
            label='Legal Name'
            valueLine1={business?.name}
            accountInfoRowType={AccountInfoRowType.BUSINESS_WITH_KYC}
            objectId={business.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.BUSINESS_NAME}
          />
          <AccountInfoRowComponent
            label='Doing Business As'
            valueLine1={business.doingBusinessAs}
            accountInfoRowType={AccountInfoRowType.BUSINESS_WITH_NO_KYC}
            objectId={business.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.BUSINESS_AS_NAME}
          />
          <AccountInfoRowComponent
            label='Description'
            valueLine1={business.description}
            accountInfoRowType={AccountInfoRowType.BUSINESS_WITH_NO_KYC}
            objectId={business.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.BUSINESS_DESCRIPTION}
          />
        </Flex>
        <Flex justifyContent='space-between' textAlign='start'>
          <AccountInfoRowComponent
            label='Establishment Date'
            valueLine1={business.establishmentDate}
            accountInfoRowType={AccountInfoRowType.BUSINESS_WITH_NO_KYC}
            objectId={business.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.BUSINESS_ESTABLISHMENT_DATE}
          />
          <AccountInfoRowComponent
            label='Incorporation State'
            valueLine1={business.incorporationState}
            accountInfoRowType={AccountInfoRowType.BUSINESS_WITH_NO_KYC}
            objectId={business.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.BUSINESS_INCORPORATION_STATE}
          />
          <AccountInfoRowComponent
            label='Legal Structure'
            valueLine1={business.legalStructure}
            accountInfoRowType={AccountInfoRowType.BUSINESS_WITH_NO_KYC}
            objectId={business.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.BUSINESS_LEGAL_STRUCTURE}
          />
        </Flex>
        <Flex justifyContent='space-between' textAlign='start'>
          <AccountInfoRowComponent
            label='Phone Number'
            valueLine1={business?.phoneNumber}
            accountInfoRowType={AccountInfoRowType.BUSINESS_WITH_NO_KYC}
            objectId={business.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.BUSINESS_PHONE_NUMBER}
          />
          <AccountInfoRowComponent
            label='Address'
            valueLine1={business?.mailingAddress?.streetAddressLine1 ?? undefined}
            valueLine2={business?.mailingAddress?.streetAddressLine2 ?? undefined}
            valueLine3={`${business?.mailingAddress?.city ?? ''}, ${business?.mailingAddress?.state ?? ''} ${business?.mailingAddress?.postalCode ?? ''}`}
            accountInfoRowType={AccountInfoRowType.BUSINESS_WITH_KYC}
            objectId={business.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.BUSINESS_MAILING_ADDRESS}
          />
          <AccountInfoRowComponent
            label='URL'
            valueLine1={business.url}
            accountInfoRowType={AccountInfoRowType.BUSINESS_WITH_NO_KYC}
            objectId={business.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.BUSINESS_URLS}
          />
        </Flex>
      </Flex>
    </InfoBoxComponent>
  )
}
