import { gql } from '@apollo/client'

export const UPDATE_USER_ORGANIZATION_ROLES = gql`
    mutation UpdateUserOrganizationRoles(
        $organizationIds: [String!]!, 
        $role: Role!, 
        $userIds: [Int!]!
    ) {
        updateUserOrganizationRoles(
            organizationIds: $organizationIds, 
            role: $role, 
            userIds: $userIds
        )
    }
`
