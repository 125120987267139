import React, { useState, type ReactElement } from 'react'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import EditRoleInput from './components/EditRoleInput'
import AnimatedDropDownRow, {
  AnimatedDropDownRowExpandedContent,
  AnimatedDropDownRowRowContent,
  AnimatedDropDownRowVariant
} from '../AnimatedDropDownRow'
import PopoverEditDeleteMenu from '../../popoverMenu/components/PopoverEditDeleteMenu'
import {
  BorderRadius,
  Color,
  IconSize
} from '../../theme/theme'
import { AvatarComponent } from '../../utility/AvatarComponent'
import { DateTimeTemplate, getFormattedDateString } from '@/utils/dateUtils'
import { getFormattedFullName, safeGetFirstLetter } from '@/utils/stringUtils'
import EditUserRoleModal, {
  type EntityRole
} from '@/components/pages/settings/components/permissions/EditUserRoleModal'
import { type Role } from '@/graphql/__generated__/globalTypes'

export enum UserRoleContext {
  BUSINESS,
  ORGANIZATION
}

export interface NullableUserBusinessRole {
  businessId: number | null
  name: string | null
  role: Role | null
}

export interface NullableUserOrganizationRole {
  organizationId: string | null
  name: string | null
  role: Role | null
}

export interface NullableUser {
  id?: number | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  lastActivity?: string | null

  organizationRole?: NullableUserOrganizationRole | null
  businessRole?: NullableUserBusinessRole | null
  subRoles?: NullableUserBusinessRole[]
}

export interface UserInfoRowProps {
  user: NullableUser
  context: UserRoleContext
  canEditUsers: boolean
  canEditInvites: boolean
  onUserRoleUpdate: () => void
  onUserInviteDelete: () => void
}

/**
 * Displays user name, activity, and role information + enables org admins to update
 *   various user data
 *
 * The top level role can be either a business role or an organization role
 * Sub-roles refer to the multiple business roles that a user may have within their org
 */
export default function UserInfoRow (
  {
    user,
    context,
    canEditUsers,
    canEditInvites,
    onUserInviteDelete,
    onUserRoleUpdate
  }: UserInfoRowProps
): ReactElement {
  const [isEditUserRoleModalOpen, setIsEditUserModalOpen] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [selectedEntityRole, setSelectedEntityRole] = useState<EntityRole | null>(null)

  function onUserEdit (subRoleId?: number): void {
    if (subRoleId != null) {
      const selectedSubRole = (user.subRoles ?? []).find(role => role.businessId === subRoleId)
      setSelectedEntityRole({
        entityName: selectedSubRole?.name ?? null,
        organizationId: null,
        businessId: selectedSubRole?.businessId ?? null,
        role: selectedSubRole?.role ?? null,
        context: UserRoleContext.BUSINESS
      })
    } else if (context === UserRoleContext.BUSINESS) {
      setSelectedEntityRole({
        entityName: user.businessRole?.name ?? null,
        organizationId: null,
        businessId: user.businessRole?.businessId ?? null,
        role: user.businessRole?.role ?? null,
        context: UserRoleContext.BUSINESS
      })
    } else if (context === UserRoleContext.ORGANIZATION) {
      setSelectedEntityRole({
        entityName: user.organizationRole?.name ?? null,
        businessId: null,
        organizationId: user.organizationRole?.organizationId ?? null,
        role: user.organizationRole?.role ?? null,
        context: UserRoleContext.ORGANIZATION
      })
    }
    setIsEditUserModalOpen(true)
  }

  const isInvitedUser = user.firstName == null
  const hasSubRoles = (user.subRoles?.length ?? 0) > 0
  const topLevelRole = getTopLevelRole(user, context)

  const textColor = isInvitedUser ? Color.DARK_GREY : Color.DARK_BLUE

  const canEditTopLevelRow = isInvitedUser ? canEditInvites : canEditUsers && !hasSubRoles

  return (
    <>
      <AnimatedDropDownRow
        isSelected={isExpanded}
        isFixed={isInvitedUser || (user.subRoles?.length ?? 0) < 1}
        onClick={() => { setIsExpanded(!isExpanded) }}
        variant={AnimatedDropDownRowVariant.DARK}
      >
        <AnimatedDropDownRowRowContent>
          <Flex
            gap={4}
            alignItems='center'
            borderRadius={BorderRadius.CARD}
            w='100%'
          >
            <Flex alignItems='center'>
              <AvatarComponent
                size={IconSize.SMALL}
                text={`${safeGetFirstLetter(user.firstName ?? user.email)}${safeGetFirstLetter(user.lastName)}`}
                backgroundColor={Color.GREY}
                color={textColor}
              />
            </Flex>
            <Grid
              color={textColor}
              templateRows='1'
              flexDir='row'
              templateColumns='repeat(12, 1fr)'
              gap={2}
              w='100%'
              alignItems='center'
            >
              <GridItem colSpan={3}>
                <Flex flexDir='column'>
                  <Text noOfLines={1} color={textColor}>
                    {user.firstName != null ? getFormattedFullName(user.firstName, user.lastName) : user.email}
                  </Text>
                  {isExpanded && user.organizationRole != null &&
                    <Text noOfLines={1} color={Color.DARK_GREY}>
                      {user.organizationRole?.name}
                    </Text>
                  }
                </Flex>
              </GridItem>
              <GridItem colSpan={3}>
                {
                  topLevelRole != null &&
                  <EditRoleInput role={topLevelRole} onClick={() => { onUserEdit() }} canEditUsers={canEditUsers}/>
                }
              </GridItem>
              <GridItem colSpan={4}>
                <Text noOfLines={1} color={textColor}>
                  {!isInvitedUser && user.email}
                </Text>
              </GridItem>
              <GridItem colSpan={2}>
                <Text noOfLines={1} color={textColor} textAlign='end'>
                  {getLastActivityString(isInvitedUser, user.lastActivity)}
                </Text>
              </GridItem>
            </Grid>
            {canEditTopLevelRow && <Flex grow={1} justifyContent='end' >
              <PopoverEditDeleteMenu
                // Hide edit button for invited users
                editOnClick={isInvitedUser ? undefined : () => { onUserEdit() }}
                deleteOnClick={isInvitedUser ? onUserInviteDelete : undefined}
                useCustomDeleteModal={true}
              />
            </Flex>}
          </Flex>
        </AnimatedDropDownRowRowContent>
        <AnimatedDropDownRowExpandedContent>
          <Flex flexDir='column' w='100%' gap={2}>
            {
          (user.subRoles ?? []).map((role, index) => {
            // TODO (PJ): Pull this out into more robust component
            return (
              <Flex key={index} w='100%' alignItems='center' gap={4}>
                <AvatarComponent
                  backgroundColor={Color.WHITE}
                  size={IconSize.SMALL}
                  text={safeGetFirstLetter(role.name)}
                />
                <Grid
                  w='100%'
                  templateColumns='repeat(4, minmax(0, 1fr)) 40px'
                >
                  <GridItem>
                    <Flex alignItems='center'>
                      <Text color={Color.DARK_BLUE}>
                        {role.name}
                      </Text>
                    </Flex>
                  </GridItem>
                  <GridItem>
                    <EditRoleInput
                      onClick={() => { onUserEdit(role.businessId ?? undefined) }}
                      role={role.role}
                      canEditUsers={canEditUsers}
                    />
                  </GridItem>
                </Grid>
              </Flex>
            )
          })
        }
          </Flex>
        </AnimatedDropDownRowExpandedContent>
      </AnimatedDropDownRow>
      <EditUserRoleModal
        user={user}
        existingRole={selectedEntityRole}
        onClose={() => { setIsEditUserModalOpen(false) }}
        onSuccessfulUpdate={onUserRoleUpdate}
        isOpen={isEditUserRoleModalOpen}
      />
    </>
  )
}

function getTopLevelRole (user: NullableUser, context: UserRoleContext): Role | null {
  switch (context) {
    case UserRoleContext.BUSINESS:
      return user.businessRole?.role ?? null
    case UserRoleContext.ORGANIZATION:
      return user.organizationRole?.role ?? null
  }
}

function getLastActivityString (isInvitedUser: boolean, lastActivity?: string | null): string {
  if (isInvitedUser) return 'Invite Sent'

  if (lastActivity == null) return 'Inactive'

  const lastActivityFormatted = getFormattedDateString(lastActivity, DateTimeTemplate.MM_DD_YY_SLASH)

  return lastActivityFormatted != null ? `Active ${lastActivityFormatted}` : 'Inactive'
}
