import React, { type ReactElement } from 'react'
import { Flex, Text, Heading, Box } from '@chakra-ui/react'
import { BorderRadius, Color } from '@/theme/theme'

interface MonthlyCashChangeInfoCardProps {
  title?: string
  description?: string
  icon: ReactElement
}

export default function MonthlyCashChangeInfoCard (
  { title, description, icon }: MonthlyCashChangeInfoCardProps
): ReactElement {
  const hasSufficientData = title != null
  function getContent (): ReactElement {
    if (hasSufficientData) {
      return (
        <Flex
          flexDir='column'
          justifyContent='center'
          alignItems='center'
          gap={4}
          flex={1}
        >
          <Box bg={Color.WHITE} borderRadius='100%' p={2}>{icon}</Box>
          <Flex
            flexDir='column'
            justifyContent='center'
            alignItems='center'
            gap={1}
          >
            <Text color={Color.DARK_BLUE} textAlign='center'>{description}</Text>
            <Heading size='lg' color={Color.DARK_BLUE} textAlign='center'>{title}</Heading>
          </Flex>
        </Flex>
      )
    }

    return (
      <Flex
        justifyContent='center'
        textAlign='center'
        p={4}
      >
        <Text>Not enough data.</Text>
      </Flex>
    )
  }
  return (
    <Flex
      flexDir='column'
      justifyContent='center'
      alignItems='center'
      bg={Color.GREY}
      borderRadius={BorderRadius.CARD}
      p={6}
      flex={1}
    >
      {getContent()}
    </Flex>
  )
}
