import React, { type ReactElement } from 'react'
import { Center, Text } from '@chakra-ui/react'
import { Color } from '@/theme/theme'

interface ProgressTextProps {
  index: number
  title: string
  currentIndex: number
}

export default function ProgressText ({ index, title, currentIndex }: ProgressTextProps): ReactElement {
  return (
    <Center key={index} justifyContent='center' flex='1'>
      <Text
        fontSize='sm'
        textAlign='center'
        color={currentIndex >= index ? Color.DARK_BLUE : Color.DARK_GREY}
      >
        {title}
      </Text>
    </Center>
  )
}
