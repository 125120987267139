import { type DateTime } from 'luxon'
import {
  type GetSettingsPageData_currentUser_selectedOrganization_franchiseGroups_financialAccounts
  as FinancialAccount
}
  from '../graphql/__generated__/GetSettingsPageData'
import {
  type LinkedAccountType,
  type AccountLinkStatus,
  type InstitutionConnectionProvider
} from '../graphql/__generated__/globalTypes'

// Type Utils
export type FormErrorState<T> = Partial<Record<keyof T, string | undefined>>

export interface DateSelectionWindow {
  startDate: DateTime | null
  endDate: DateTime | null
}
export enum DateWindowSelectionOptions {
  WEEK = '1W',
  MONTH = '1M',
  THREE_MONTH = '3M',
  YEAR_TO_DATE = 'YTD',
  YEAR = '1Y',
  ALL = 'All'
}

export interface HookState {
  loading: boolean
  error?: Error
}

export interface Institution {
  logoAssetUrl: string | null
  name: string | null
}

export interface FinancialDataPoint {
  amount: number
  date: string
}

export interface CurrencyAmountFormatted {
  amount: number | null
  amountFormatted: string | null
  currency: string | null
}

export interface Balance {
  balance: CurrencyAmountFormatted | null
}

export interface LiveBalance {
  currentBalance: CurrencyAmountFormatted | null
  availableBalance: CurrencyAmountFormatted | null
  updatedAt: DateTime | null
}

export interface AccountsSortByInput {
  type: AccountsSortByType
  ascending: boolean
}

export enum AccountsSortByType {
  BALANCE,
  NAME
}

export interface MoneyMovementLimit {
  dailyAchCreditOriginationLimit: { amount: number | null } | null
  dailyAchDebitOriginationLimit: { amount: number | null } | null
  dailyWireOriginationLimit: { amount: number | null } | null
}

export interface GroupedFinancialAccounts {
  plaidAccessToken: string
  plaidItemId: string
  institution: Institution
  accounts: FinancialAccount[]
  status: AccountLinkStatus
  accountType: LinkedAccountType
  connectionProvider: InstitutionConnectionProvider
}

export interface ErrorContent {
  title: string
  subtitle: string
}

export interface ErrorWithContent {
  customContent?: ErrorContent
  error: Error
}

export enum IconFacing {
  RIGHT = 0,
  DOWN = 90,
  LEFT = 180,
  UP = 270,
}
