import { gql } from '@apollo/client'

export const UPDATE_STORE_LOCATION = gql`
    mutation UpdateStoreLocation($franchiseGroupId: Int!, $storeLocationId: String!, $storeLocationUpdate: StoreLocationUpdateInput!) {
      updateStoreLocation(franchiseGroupId: $franchiseGroupId, storeLocationId: $storeLocationId, storeLocationUpdate: $storeLocationUpdate) {
        checkFieldIdentifier
        id
        posFieldIdentifier
        name
        storeId
      }
    }
`
