import React, { useState, type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import BusinessInfoRow from './BusinessInfoRow'
import AddNewBusinessModal from '../../settings/components/business_settings/AddNewBusinessModal'
import { type GetHomePageData } from '@/graphql/__generated__/GetHomePageData'
import Button, { ButtonVariant } from '@/library/button/Button'

interface BusinessSectionProps {
  data?: GetHomePageData | null
}

export default function BusinessSection ({ data }: BusinessSectionProps): ReactElement {
  const [isAddNewBusinessModalOpen, setIsAddNewBusinessModalOpen] = useState(false)

  const franchiseGroups = data?.currentUser?.selectedOrganization?.franchiseGroups ?? []

  const isOnAmplifyWaitlist = data?.currentUser?.selectedOrganization?.isOnAmplifyWaitlist === true

  return (
    <Flex flexDir='column' gap={3} w='100%'>
      <Text>My Businesses</Text>
      <Flex flexDir='column' gap={4} w='100%'>
        <Flex flexDir='column' gap={2}>
          {
            franchiseGroups.map(franchiseGroup => {
              return (
                <BusinessInfoRow
                  key={franchiseGroup.name}
                  business={franchiseGroup}
                  isOnAmplifyWaitlist={isOnAmplifyWaitlist}
                />
              )
            })
        }
        </Flex>
        <Flex w='100%' justifyContent='end'>
          <Button
            text='Add Business'
            onClick={() => { setIsAddNewBusinessModalOpen(true) }}
            width='auto'
            variant={ButtonVariant.GREY}
          />
        </Flex>
      </Flex>
      <AddNewBusinessModal
        isOpen={isAddNewBusinessModalOpen}
        onClose={() => { setIsAddNewBusinessModalOpen(false) }}
      />
    </Flex>
  )
}
