import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import UserInfoRow, { UserRoleContext, type NullableUser } from '@/library/layout/user_row/UserInfoRow'

export interface TeamSettingsComponentProps {
  members: NullableUser[]
  canManageTeamSettings: boolean
  refetchSettings: () => void
}

export default function TeamSettingsComponent (
  {
    members,
    canManageTeamSettings,
    refetchSettings
  }: TeamSettingsComponentProps
): ReactElement {
  return (
    <Flex flexDir='column' gap={6}>
      <Flex flexDir='column' gap={2}>
        {
            members.map(user => {
              return (
                <UserInfoRow
                  key={user.id}
                  user={user}
                  context={UserRoleContext.BUSINESS}
                  onUserInviteDelete={() => {}}
                  canEditUsers={canManageTeamSettings}
                  canEditInvites={false} // Invites aren't supported for business settings
                  onUserRoleUpdate={refetchSettings}
                />
              )
            })
        }
      </Flex>
    </Flex>
  )
}
