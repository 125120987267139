/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function CircleSolidErrorIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size} >
      <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM10.1522 16.4091C10.0517 16.6364 10 16.8801 10 17.1262C10 17.6231 10.2107 18.0998 10.5858 18.4512C10.9609 18.8026 11.4696 19 12 19C12.5304 19 13.0391 18.8026 13.4142 18.4512C13.7893 18.0998 14 17.6231 14 17.1262C14 16.8801 13.9483 16.6364 13.8478 16.4091C13.7473 16.1817 13.5999 15.9752 13.4142 15.8012C13.2285 15.6272 13.008 15.4891 12.7654 15.395C12.5227 15.3008 12.2626 15.2523 12 15.2523C11.7374 15.2523 11.4773 15.3008 11.2346 15.395C10.992 15.4891 10.7715 15.6272 10.5858 15.8012C10.4001 15.9752 10.2527 16.1817 10.1522 16.4091ZM10.2744 5.17232C10.1961 5.39842 10.1694 5.63743 10.196 5.87383L10.896 12.4453C10.9195 12.7035 11.0456 12.944 11.2493 13.1192C11.453 13.2945 11.7195 13.3917 11.996 13.3917C12.2725 13.3917 12.539 13.2945 12.7427 13.1192C12.9464 12.944 13.0725 12.7035 13.096 12.4453L13.796 5.87383C13.8226 5.63743 13.7959 5.39842 13.7176 5.17232C13.6393 4.94622 13.5111 4.73807 13.3414 4.56138C13.1716 4.38468 12.9642 4.2434 12.7324 4.14669C12.5006 4.04998 12.2497 4 11.996 4C11.7423 4 11.4914 4.04998 11.2596 4.14669C11.0279 4.2434 10.8204 4.38468 10.6506 4.56138C10.4809 4.73807 10.3527 4.94622 10.2744 5.17232Z' fill='white'/>
      </svg>
    </IconContainer>
  )
}
