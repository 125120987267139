import { motion } from 'framer-motion'
import React, { type ReactElement } from 'react'
import CarrotIcon from '../../icons/CarrotIcon'
import { Color, IconSize } from '@/theme/theme'
import { type IconFacing } from '@/types/types'

interface CarrotComponentProps {
  facing: IconFacing
  size?: IconSize
  color?: Color
}

export default function CarrotComponent ({
  facing,
  color = Color.DARK_GREY,
  size = IconSize.MEDIUM
}: CarrotComponentProps
): ReactElement {
  return (
    <motion.div
      animate={{ rotate: facing }}
      transition={{ duration: 0.1 }}
    >
      <CarrotIcon size={size} color={color}/>
    </motion.div>
  )
}
