/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function InfoIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M0.75 10C0.75 4.89303 4.89303 0.75 10 0.75C15.107 0.75 19.25 4.89303 19.25 10C19.25 15.107 15.107 19.25 10 19.25C4.89303 19.25 0.75 15.107 0.75 10ZM10 2.75C5.9976 2.75 2.75 5.9976 2.75 10C2.75 14.0024 5.9976 17.25 10 17.25C14.0024 17.25 17.25 14.0024 17.25 10C17.25 5.9976 14.0024 2.75 10 2.75ZM10.6926 5.76214C10.8614 5.93092 10.9562 6.15984 10.9562 6.39853C10.9562 6.63723 10.8614 6.86615 10.6926 7.03493C10.5239 7.20371 10.2949 7.29853 10.0562 7.29853C9.81755 7.29853 9.58864 7.20371 9.41985 7.03493C9.25107 6.86615 9.15625 6.63723 9.15625 6.39853C9.15625 6.15984 9.25107 5.93092 9.41985 5.76214C9.58864 5.59336 9.81755 5.49853 10.0562 5.49853C10.2949 5.49853 10.5239 5.59336 10.6926 5.76214ZM11 9.24853C11 8.69625 10.5523 8.24853 10 8.24853C9.44771 8.24853 9 8.69625 9 9.24853V14.4985C9 15.0508 9.44771 15.4985 10 15.4985C10.5523 15.4985 11 15.0508 11 14.4985V9.24853Z'/>
      </svg>
    </IconContainer>
  )
}
