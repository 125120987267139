import React, { type ReactElement } from 'react'
import '../../../assets/css/App.css'
import { useQuery } from '@apollo/client'
import LoanIntroComponent from './components/LoanIntroComponent'
import LoansComponent from './components/LoansComponent'
import { GET_LENDING_PAGE_DATA } from '@/graphql/queries/GetLendingPageData'
import {
  type GetLendingPageData,
  type GetLendingPageDataVariables
} from '@/graphql/__generated__/GetLendingPageData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import Page from '@/library/page/Page'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export default function LoansPage (): ReactElement {
  const selectedFranchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const {
    data: pageData,
    loading: isPageDataLoading,
    error: pageDataError,
    refetch: refetchPageData
  } = useQuery<GetLendingPageData, GetLendingPageDataVariables>(
    GET_LENDING_PAGE_DATA,
    { variables: { franchiseGroupId: selectedFranchiseGroupId, organizationId: selectedOrganizationId } }
  )

  const organization = pageData?.currentUser?.selectedOrganization
  const franchiseGroup = pageData?.currentUser?.franchiseGroup

  const hasRequestedLoanOffers = organization?.hasRequestedLoanOffers === true

  return (
    <Page>
      <AltirSkeleton isLoading={isPageDataLoading} error={pageDataError}>
        {
          hasRequestedLoanOffers
            ? <LoansComponent data={pageData}/>
            : <LoanIntroComponent
                onNextStep={refetchPageData}
                franchiseGroupId={Number(franchiseGroup?.id)}
                organizationId={organization?.id ?? null}
              />
        }
      </AltirSkeleton>
    </Page>
  )
}
