import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import CheckboxSelect from './CheckboxSelect'

export interface SelectItem {
  description: string
  isSelected: boolean
}

interface CheckBoxSelectGroupProps {
  options: Record<string, SelectItem>
  setOptions: (options: Record<string, SelectItem>) => void
}

export default function CheckBoxSelectGroup ({ options, setOptions }: CheckBoxSelectGroupProps): ReactElement {
  function onClick (name: string, isChecked: boolean): void {
    const currentValue = options[name]
    if (
      currentValue?.description != null
    ) {
      setOptions({
        ...options,
        [name]: {
          ...currentValue,
          isSelected: isChecked
        }
      })
    }
  }
  return (
    <Flex flexDir='column' gap={4}>
      {Object.entries(options).map(([name, item]) => {
        return (
          <Flex key={name}>
            <CheckboxSelect
              description={item.description}
              isChecked={item.isSelected}
              setIsChecked={(isChecked) => { onClick(name, isChecked) }}
            />
          </Flex>
        )
      })}
    </Flex>
  )
}
