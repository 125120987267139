import { Flex, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { getFileNameFromUrl } from '@/utils/stringUtils'
import {
  type GetDocumentsHubData_currentUser_franchiseGroup_lendingDocuments as Document
} from '@/graphql/__generated__/GetDocumentsHubData'

export function DocumentDescription ({ document }: { document: Document }): ReactElement {
  return (
    <Flex w='100%' maxWidth={96}>
      <Text fontSize='sm' noOfLines={1}>{getFileNameFromUrl(document.fileUrl)}</Text>
    </Flex>
  )
}
