/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize, Color } from '../theme/theme'

export default function RecurringRuleIcon ({ color = Color.DARK_GREY, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M0.710364 7.82767C0.472429 6.62371 0.652246 5.3746 1.22012 4.28664C1.78799 3.19868 2.70995 2.33695 3.83374 1.84375C4.95752 1.35055 6.21591 1.2554 7.40108 1.574C8.58625 1.8926 9.62731 2.6059 10.3523 3.5961' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M11.2916 5.73678C11.5295 6.94075 11.3497 8.18985 10.7818 9.27781C10.214 10.3658 9.292 11.2275 8.16821 11.7207C7.04443 12.2139 5.78604 12.3091 4.60087 11.9905C3.4157 11.6718 2.37464 10.9585 1.64963 9.96835' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M8.2207 3.66559H10.4957V1.39062' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M3.7793 9.90081L1.50433 9.90081L1.50433 12.1758' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
      </svg>

    </IconContainer>
  )
}
