import React, { type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import AltirProUpgradeComponent from '@/library/pro/AltirProUpgradeComponent'
import { AltirProUpgradeModalVariant } from '@/library/pro/AltirProUpgradeModal'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { GET_AMPLIFY_UPSELL_DATA } from '@/graphql/queries/GetAmplifyUpsellData'
import {
  type GetAmplifyUpsellDataVariables,
  type GetAmplifyUpsellData
} from '@/graphql/__generated__/GetAmplifyUpsellData'
import { BorderRadius, Color, FontWeight } from '@/theme/theme'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { isSubscribed } from '@/utils/subscriptionUtils'
import { type AltirSubscriptionLevel } from '@/graphql/__generated__/globalTypes'

export default function AmplifyUpgradeCardComponent ({
  subscriptionLevel
}: { subscriptionLevel?: AltirSubscriptionLevel }): ReactElement {
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const { loading, error, data } = useQuery<GetAmplifyUpsellData, GetAmplifyUpsellDataVariables>(
    GET_AMPLIFY_UPSELL_DATA, {
      variables: {
        organizationId: selectedOrganizationId
      }
    })
  if (error != null) {
    return <></>
  }

  if (isSubscribed(subscriptionLevel)) {
    return <></>
  }

  return (
    <AltirSkeleton isLoading={loading}>
      <Flex
        border={`1px solid ${String(Color.BRIGHT_BLUE)}`}
        backgroundColor={Color.LIGHT_BLUE}
        justifyContent='space-between'
        alignItems='center'
        borderRadius={BorderRadius.CARD}
        px={6}
        py={6}
      >
        <Flex flexDirection='column'>
          <Text color={Color.BRIGHT_BLUE} fontWeight={FontWeight.BOLD} lineHeight={1.25}>
            Increase your <br/> APY to
          </Text>
          <Heading color={Color.BRIGHT_BLUE} fontWeight={FontWeight.MEDIUM} size='lg'>
            {data?.amplifyAccountPromotionalOffer?.subscribedAnnualPercentageYieldFormatted}
          </Heading>
        </Flex>
        <Flex width='100%' pl={4} maxWidth={60}>
          <AltirProUpgradeComponent
            ctaText='Upgrade'
            marginRight='0px'
            width='100%'
            productVariant={AltirProUpgradeModalVariant.AMPLIFY}
          />
        </Flex>
      </Flex>
    </AltirSkeleton>
  )
}
