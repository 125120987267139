import React, { useState, type ReactElement } from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Event } from 'metrics/metrics'
import FundAccountModal from './fund_account_modal/FundAccountModal'
import AmplifyUpgradeCardComponent from '../AmplifyUpgradeCardComponent'
import { ROUTES } from '@/api/routes'
import { BorderRadius, Color, FontWeight } from '@/theme/theme'
import { Graphic } from '@/library/utility/Graphic'
import { ALTIR_LOGO_ASSET_URL } from '@/library/theme/graphics'
import { type GetAmplifyAccountCardData } from '@/graphql/__generated__/GetAmplifyAccountCardData'
import { CardDivider, CardRow } from '@/library/layout/CardComponets'
import Button, { ButtonVariant } from '@/library/button/Button'
import { EMPTY_BALANCE_VALUE } from '@/utils/constants'
import DollarCircleIcon from '@/library/icons/DollarCircleIcon'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { isSubscribed } from '@/utils/subscriptionUtils'
import { AuthAction, hasAccess, INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY } from '@/utils/authUtils'
import { AuthResource } from '@/graphql/__generated__/globalTypes'

interface ActiveAmplifyAccountCardProps {
  franchiseGroupId: number
  data?: GetAmplifyAccountCardData
}

export default function ActiveAmplifyAccountCard ({
  franchiseGroupId,
  data
}: ActiveAmplifyAccountCardProps): ReactElement {
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false)
  const navigate = useNavigate()
  const amplifyAccountBankId = Number(data?.amplifyAccount?.accountId)
  const availableBalance = data?.amplifyAccount?.liveBalance?.availableBalance?.amountFormatted
  const earningsToDate = data?.amplifyAccount?.amplifyAccount?.earningsToDate?.amount
  const isProSubscriber = isSubscribed(data?.currentUser?.selectedOrganization?.subscriptionLevel)

  // Authorization Checks
  const canTransfer = hasAccess(AuthResource.AMPLIFY_ACCOUNT, AuthAction.WRITE, data?.currentUser?.authorization ?? [])

  return (
    <Box
      bg={Color.WHITE}
      borderRadius={BorderRadius.CARD}
      paddingX={6}
      paddingY={6}
    >
      <FundAccountModal
        amplifyAccount={data?.amplifyAccount ?? undefined}
        isOpen={isTransferModalOpen}
        onClose={() => { setIsTransferModalOpen(false) }}
      />
      <Flex flexDirection='column' color={Color.DARK_GREY} gap={6}>
        <Flex justifyContent='space-between' width='100%' paddingBottom={16}>
          <Flex flexDirection='column'>
            <Heading color={Color.DARK_BLUE} size='sm' paddingBottom={1}>Altir Amplify</Heading>
            <Heading color={Color.DARK_GREY} size='sm'>Checking</Heading>
          </Flex>
          <Graphic
            w='auto'
            h='60px'
            src={ALTIR_LOGO_ASSET_URL}
          />
        </Flex>
        <Box>
          <CardRow>
            <Text>Balance</Text>
            <Heading
              fontWeight={FontWeight.MEDIUM}
              color={availableBalance != null ? Color.DARK_BLUE : Color.DARK_GREY}
            >
              {availableBalance ?? EMPTY_BALANCE_VALUE}
            </Heading>
          </CardRow>
          <CardDivider/>
          {isProSubscriber &&
            <Flex flexDir='column'>
              <CardRow>
                <Text>Annual Percentage Yield (APY)</Text>
                <Text color={Color.DARK_BLUE}>
                  {data?.amplifyAccount?.amplifyAccount?.interestRateFormatted}
                </Text>
              </CardRow>
              <CardDivider/>
            </Flex>
          }
          <CardRow>
            <Text>Earnings to Date</Text>
            <Text color={Color.DARK_BLUE}>
              {earningsToDate != null
                ? getCurrencyFormatted(earningsToDate, { minimumFractionDigits: 2 })
                : EMPTY_BALANCE_VALUE}
            </Text>
          </CardRow>
        </Box>
        <Flex
          w='100%'
        >
          <AmplifyUpgradeCardComponent subscriptionLevel={data?.currentUser?.selectedOrganization?.subscriptionLevel} />
        </Flex>
        <Flex
          w='100%'
          flexDir='column'
          gap={4}
        >
          <Button
            text='Fund your Account'
            onClick={() => { setIsTransferModalOpen(true) }}
            beforeIcon={<DollarCircleIcon color={Color.WHITE} size={5}/>}
            onClickEventType={Event.OPEN_FUND_YOUR_ACCOUNT_MODAL_CLICK}
            isDisabled={!canTransfer}
            toolTipText={!canTransfer ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
          />
          <Button
            text='View Account Details'
            variant={ButtonVariant.WHITE_OUTLINE}
            onClick={() => { navigate(`${ROUTES.ACCOUNT}/${amplifyAccountBankId}`) }}
            onClickEventType={Event.VIEW_AMPLIFY_ACCOUNT_DETAILS_CLICK}
          />
        </Flex>
      </Flex>
    </Box>
  )
}
