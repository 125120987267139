import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'

import { type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule }
  from '@/graphql/__generated__/GetTransferRules'
import { TransferRuleType } from '@/graphql/__generated__/globalTypes'
import { Color, IconSize } from '@/theme/theme'
import { getFormattedBalanceRule, getFormattedTimeRule, isBalanceRule }
  from '@/utils/transferRuleUtils'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import RecurringRuleIcon from '@/library/icons/RecurringRuleIcon'

export interface TransferRuleHeaderProps {
  transferRule: TransferRule
}

export default function TransferRuleHeader (
  { transferRule }: TransferRuleHeaderProps
): ReactElement {
  const formattedBalanceRule = isBalanceRule(transferRule.transferRuleType)
    ? getFormattedBalanceRule(transferRule)
    : undefined
  const formattedTimeRule = !isBalanceRule(transferRule.transferRuleType)
    ? getFormattedTimeRule(transferRule)
    : undefined

  const mainColor = transferRule.isActive ? Color.DARK_BLUE : Color.DARK_GREY

  function getMainTextSection (): ReactElement {
    const to = isBalanceRule(transferRule.transferRuleType) ? formattedBalanceRule?.to : formattedTimeRule?.to
    const from = isBalanceRule(transferRule.transferRuleType) ? formattedBalanceRule?.from : formattedTimeRule?.from

    return <>
      <Text noOfLines={1} fontSize='md' textColor={mainColor}>{from} </Text>
      <RightArrowIcon color={Color.DARK_GREY}/>
      <Text noOfLines={1} fontSize='md' textColor={mainColor}>{to} </Text>
    </>
  }

  function getDetailSection (): ReactElement {
    if (transferRule.transferRuleType === TransferRuleType.TARGET_BALANCE) {
      return (
        <>
          <Text noOfLines={1} fontSize='md' textColor={Color.DARK_GREY}>{formattedBalanceRule?.from} </Text>
          <Text noOfLines={1} fontSize='xl' textColor={Color.DARK_GREY}>{'>'} </Text>
          <Text noOfLines={1} fontSize='md' textColor={mainColor}>{formattedBalanceRule?.balance} </Text>
        </>
      )
    } else if (transferRule.transferRuleType === TransferRuleType.MIN_TARGET_BALANCE) {
      return (
        <>
          <Text noOfLines={1} fontSize='md' textColor={Color.DARK_GREY}>{formattedBalanceRule?.to} </Text>
          <Text noOfLines={1} fontSize='xl' textColor={Color.DARK_GREY}>{'<'} </Text>
          <Text noOfLines={1} fontSize='md' textColor={mainColor}>{formattedBalanceRule?.balance} </Text>
        </>
      )
    } else if (transferRule.transferRuleType === TransferRuleType.ONE_TIME) {
      return <>
        <Text noOfLines={1}
          fontSize='md'
          textColor={Color.DARK_GREY}
        >{formattedTimeRule?.frequency}, {formattedTimeRule?.ruleStartDate}
        </Text>
        <Text noOfLines={1} fontSize='md' textColor={mainColor}>{formattedTimeRule?.amount} </Text>
      </>
    } else {
      return (
        <>
          <RecurringRuleIcon color={Color.DARK_GREY} size={IconSize.SMALL}/>
          <Text noOfLines={1} fontSize='md' textColor={Color.DARK_GREY}>{formattedTimeRule?.frequency} </Text>
          <Text noOfLines={1} fontSize='md' textColor={mainColor}>{formattedTimeRule?.amount} </Text>
        </>
      )
    }
  }

  return (
    <Flex width='100%' grow={1} gap={2} alignItems='center'>
      <Flex flex={5} gap={2} alignItems='center'>
        {getMainTextSection()}
      </Flex>
      <Flex flex={5}
        gap={3}
        alignItems='center'
        justifyContent='flex-end'
      >
        {getDetailSection()}
      </Flex>
    </Flex>
  )
}
