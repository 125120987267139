import React, { type ReactElement } from 'react'
import {
  Flex,
  useRadioGroup
} from '@chakra-ui/react'
import { SlideSelectCard } from './SlideSelectCard'
import { SLIDE_SELECT_TRANSITION_TIME } from './constant'
import { BorderRadius, Color } from '@/theme/theme'

interface SlideSelectProps<T> {
  selectedValue: T
  options: T[]
  handleSelection: (value: string) => void
  formatValue: (value: T) => string

  // Styles
  height?: string
  width?: string
}

export default function SlideSelect<T> ({
  selectedValue,
  options,
  handleSelection,
  formatValue,
  height = '40px',
  width = '300px'
}: SlideSelectProps<T>): ReactElement {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'slide-select',
    defaultValue: String(selectedValue),
    onChange: (value) => {
      handleSelection(value)
    },
    value: String(selectedValue)
  })

  const group = getRootProps()

  function getHighlightPosition (value: T): string {
    const index = options.indexOf(value)
    return `calc(${(index) * 100}%)`
  }

  return (
    <Flex
      bg={Color.GREY}
      borderRadius={BorderRadius.INPUT}
      position='relative'
      height={height}
      w={width}
      _before={{
        content: '""',
        position: 'absolute',
        height,
        width: `${100 / options.length}%`,
        boxSizing: 'border-box',
        bg: Color.WHITE,
        border: `1.25px solid ${String(Color.DARK_BLUE)}`,
        borderRadius: BorderRadius.INPUT,
        transition: `transform ${SLIDE_SELECT_TRANSITION_TIME} ease-in-out`,
        transform: `translateX(${getHighlightPosition(selectedValue)})`,
        zIndex: 0
      }}
    >
      <Flex {...group} position='relative' zIndex='1' w='100%' justifyContent='space-between'>
        {options.map((value) => {
          const radio = getRadioProps({ value })
          return (
            <SlideSelectCard key={value} {...radio}>
              {formatValue(value)}
            </SlideSelectCard>
          )
        })}
      </Flex>
    </Flex>
  )
}
