import { ASSET_HOSTING_URL } from './urls'

export const ALTIR_LOGO_ASSET_URL = `${ASSET_HOSTING_URL}/assets/altir_logo.svg`
export const ALTIR_LOGO_WITH_TEXT_GREY = `${ASSET_HOSTING_URL}/assets/altir_logo_grey_horizontal.svg`
export const ALTIR_PRO_LOGO_BLUE = `${ASSET_HOSTING_URL}/assets/altir_pro_logo_blue.svg`
export const ALTIR_FLAG_LOGO = `${ASSET_HOSTING_URL}/assets/altir_flag.svg`
export const EMAIL_ICON = `${ASSET_HOSTING_URL}/assets/mail_icon.svg`

// TODO (PJ): Remove PNG usage
export const CHECKMARK_ICON = `${ASSET_HOSTING_URL}/assets/check_circle.png`
export const ALTIR_LOGO_BLUE = `${ASSET_HOSTING_URL}/assets/altir_logo_blue.png`
export const X_ICON = `${ASSET_HOSTING_URL}/assets/x_icon.png`

// Graphics
// TODO (PJ): Refactor to enum so we can easily create page that displays all objects
export const ALTIR_LOGO_WHITE_HORIZONTAL = `${ASSET_HOSTING_URL}/assets/altir_logo_white_horizontal.svg`
export const ALTIR_LOGO_BLUE_HORIZONTAL = `${ASSET_HOSTING_URL}/assets/altir_logo_blue_horizontal.svg`
export const ALTIR_PRO_LOGO_BLUE_HORIZONTAL = `${ASSET_HOSTING_URL}/assets/altir_pro_logo_blue_horizontal.svg`
export const ALTIR_PRO_BLUE_GRAPHIC = `${ASSET_HOSTING_URL}/assets/altir_pro_blue.svg`
export const ALTIR_PRO_WHITE_GRAPHIC = `${ASSET_HOSTING_URL}/assets/altir_pro_white.svg`
export const ALTIR_LOGO_ARROW = `${ASSET_HOSTING_URL}/assets/altir_logo_arrow.svg`
export const GRASSHOPPER_LOGO = `${ASSET_HOSTING_URL}/assets/grasshopper_logo.svg`
export const GRAPH_ALTIR_GRAPHIC = `${ASSET_HOSTING_URL}/assets/graph_altir.svg`
export const GRAPH_GRAPHIC = `${ASSET_HOSTING_URL}/assets/graph.svg` // No Altir branding on this one
export const HOUR_GLASS_GRAPHIC = `${ASSET_HOSTING_URL}/assets/hour_glass.svg`
export const SUCCESS_RIBBON_GRAPHIC = `${ASSET_HOSTING_URL}/assets/success_ribbon.svg`
export const BANK_PLUS_GRAPHIC = `${ASSET_HOSTING_URL}/assets/bank_plus.svg`
export const FDIC_PILLAR_GRAPHIC = `${ASSET_HOSTING_URL}/assets/fdic_pillar.svg`
export const MAGNIFYING_GLASS_GRAPHIC = `${ASSET_HOSTING_URL}/assets/magnifying_glass.svg`
export const ERROR_OCTAGON_GRAPHIC = `${ASSET_HOSTING_URL}/assets/error_octagon.svg`
export const NOT_AUTHORIZED_OCTAGON_GRAPHIC = `${ASSET_HOSTING_URL}/assets/not_authorized_octagon.svg`
export const SUCCESS_COMPASS_GRAPHIC = `${ASSET_HOSTING_URL}/assets/success_compass.svg`
export const SUCCESS_COMPASS_PLUS_GRAPHIC = `${ASSET_HOSTING_URL}/assets/success_compass_plus.svg`
export const DOLLAR = `${ASSET_HOSTING_URL}/assets/altir_dollar.svg`
export const DOLLAR_SPARKLE = `${ASSET_HOSTING_URL}/assets/dollar_sparkle.svg`
export const TELESCOPE = `${ASSET_HOSTING_URL}/assets/telescope.svg`
export const SUNGLASSES = `${ASSET_HOSTING_URL}/assets/sunglasses.svg`
export const SUNGLASSES_SPARKLE = `${ASSET_HOSTING_URL}/assets/sunglasses_sparkle.svg`
export const BAR_CHART = `${ASSET_HOSTING_URL}/assets/bar_chart.svg`
export const HANDHOLDING_PEOPLE = `${ASSET_HOSTING_URL}/assets/handholding_people.svg`
export const HANDHOLDING_PEOPLE_HEART = `${ASSET_HOSTING_URL}/assets/handholding_people_heart.svg`
export const CIRCLE_CHECK_GRAPHIC = `${ASSET_HOSTING_URL}/assets/circle_check_graphic.svg`
export const LOCK = `${ASSET_HOSTING_URL}/assets/lock.svg`
export const PLAID_LOGO = `${ASSET_HOSTING_URL}/assets/plaid_logo.svg`
export const SCREEN_404 = `${ASSET_HOSTING_URL}/assets/screen_404.svg`
export const SCREEN_500 = `${ASSET_HOSTING_URL}/assets/500_screen.svg`
export const ALTIR_CARD = `${ASSET_HOSTING_URL}/assets/altir_card.svg`
export const CARD_SPARKLE = `${ASSET_HOSTING_URL}/assets/card_sparkle.svg`
export const ALTIR_DOUBLE_CARD = `${ASSET_HOSTING_URL}/assets/altir_double_card.svg`
export const CHAIN_LINK = `${ASSET_HOSTING_URL}/assets/chain_link.svg`
export const ALTIR_EMAIL_GRAPHIC = `${ASSET_HOSTING_URL}/assets/altir_email_graphic.svg`
export const ALTIR_PEOPLE_REACHING_FOR_LOGO = `${ASSET_HOSTING_URL}/assets/altir_people_reaching_for_logo.svg`
export const FOUR_PERCENT_BANNER = `${ASSET_HOSTING_URL}/assets/four_percent_banner.svg`
export const MULTIPLE_BUSINESS_GRAPHIC = `${ASSET_HOSTING_URL}/assets/multiple_business_graphic.svg`
export const RECURRING_TRANSFER_SPARKLE = `${ASSET_HOSTING_URL}/assets/recurring_transfer_sparkle.svg`
export const EMAIL_VERIFICATION_FAILED = `${ASSET_HOSTING_URL}/assets/email_verification_failed_graphic.svg`
export const STOPWATCH = `${ASSET_HOSTING_URL}/assets/stopwatch.svg`
export const CHECK_SEND = `${ASSET_HOSTING_URL}/assets/check_send.svg`

// Partner logos
export const DOYLE_LOGO = `${ASSET_HOSTING_URL}/logos/doyle_logo.svg`
export const HILINE_LOGO = `${ASSET_HOSTING_URL}/logos/hiline_logo.svg`
export const SULLY_FI_LOGO = `${ASSET_HOSTING_URL}/logos/sully_logo.svg`
export const SIB_LOGO = `${ASSET_HOSTING_URL}/logos/sib_logo.svg`
