import React, { type ReactElement } from 'react'
import { Divider, Flex, Text } from '@chakra-ui/react'
import { BorderRadius, Color, FontWeight } from '@/theme/theme'

export interface InfoBoxComponentProps {
  label: string
  children: ReactElement
}

export default function InfoBoxComponent ({ label, children }: InfoBoxComponentProps): ReactElement {
  return (
    <Flex flexDir='column'
      gap={2}
      bg={Color.LIGHT_GREY}
      borderRadius={BorderRadius.CARD}
      py={6}
      px={6}
    >
      <Text fontSize='sm' fontWeight={FontWeight.BOLD} color={Color.DARK_GREY}>{label}</Text>
      <Divider borderColor={Color.GREY}/>
      <Flex
        flexDir='column'
        w='100%'
        borderRadius={BorderRadius.CARD}
      >
        {children}
      </Flex>
    </Flex>
  )
}
