import { useState, type ReactElement } from 'react'
import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Center } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import PersonApplicationFormComponent, { type PersonalApplicationFormData } from './PersonApplicationFormComponent'
import { type AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import {
  DELETE_AMPLIFY_ACCOUNT_PERSONAL_APPLICATION,
  UPDATE_AMPLIFY_ACCOUNT_PERSONAL_APPLICATION
} from '@/graphql/mutations/AmplifyApplicationMutations'
import {
  AmplifyApplicationStep,
  createUpdatePersonalApplicationVariables, getAmplifyApplicationRouteForContext, isPrimaryOwner
} from '@/utils/amplifyApplicationUtils'
import {
  type DeleteAmplifyAccountPersonalApplication,
  type DeleteAmplifyAccountPersonalApplicationVariables
} from '@/graphql/__generated__/DeleteAmplifyAccountPersonalApplication'
import {
  type UpdateAmplifyAccountPersonalApplication,
  type UpdateAmplifyAccountPersonalApplicationVariables
} from '@/graphql/__generated__/UpdateAmplifyAccountPersonalApplication'
import { GET_AMPLIFY_PERSON_APPLICATION } from '@/graphql/queries/amplify_application/GetAmplifyPersonApplication'
import {
  type GetAmplifyPersonApplication,
  type GetAmplifyPersonApplicationVariables
} from '@/graphql/__generated__/GetAmplifyPersonApplication'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import
AmplifyApplicationHeader
  from '@/components/pages/v2/sign_up/routes/amplify/routes/components/AmplifyApplicationHeader'
import { routeWithParams } from '@/utils/routerUtils'
import { RouteParam } from '@/api/routes'

export interface PersonApplicationComponentProps {
  franchiseGroupId: number
  personApplicationId: string
  context: AmplifyApplicationContext
}

const BENEFICIAL_OWNER_DESCRIPTION = `
  This is any individual who owns, directly or indirectly, 25 percent or more of the equity interests of the legal entity.
`

export default function PersonApplicationComponent (
  {
    franchiseGroupId,
    personApplicationId,
    context
  }: PersonApplicationComponentProps
): ReactElement {
  const navigate = useNavigate()
  const {
    data,
    loading,
    error
  } = useQuery<GetAmplifyPersonApplication, GetAmplifyPersonApplicationVariables>(
    GET_AMPLIFY_PERSON_APPLICATION,
    { variables: { id: personApplicationId, franchiseGroupId } }
  )

  const [
    updatePersonalApplication,
    { loading: updatePersonApplicationLoading }
  ] = useMutation<UpdateAmplifyAccountPersonalApplication, UpdateAmplifyAccountPersonalApplicationVariables>(
    UPDATE_AMPLIFY_ACCOUNT_PERSONAL_APPLICATION,
    {
      onCompleted: () => {
        onAction()
      },
      onError: (submissionError) => {
        setSubmissionError(submissionError)
      }
    }
  )

  const [
    deletePersonalApplication
  ] = useMutation<DeleteAmplifyAccountPersonalApplication, DeleteAmplifyAccountPersonalApplicationVariables>(
    DELETE_AMPLIFY_ACCOUNT_PERSONAL_APPLICATION,
    {
      onCompleted: () => {
        onAction()
      },
      onError: (submissionError) => {
        setSubmissionError(submissionError)
      }
    }
  )

  const [submissionError, setSubmissionError] = useState<Error | undefined>()

  async function handleSubmission (formData: PersonalApplicationFormData): Promise<void> {
    void updatePersonalApplication({
      variables: createUpdatePersonalApplicationVariables(formData, personApplicationId)
    })
  }

  async function handleDelete (): Promise<void> {
    void deletePersonalApplication({ variables: { personalApplicationId: personApplicationId } })
  }

  function onAction (): void {
    navigate(backLink)
  }

  const isControllingManager = isPrimaryOwner(data?.amplifyPersonApplication)
  const { header, description } = getHeaderText(isControllingManager)

  const backLink = routeWithParams(
    getAmplifyApplicationRouteForContext(AmplifyApplicationStep.OWNERSHIP, context),
    [
      { param: RouteParam.BUSINESS_ID, value: String(franchiseGroupId) },
      { param: RouteParam.AMPLIFY_APPLICATION_ID, value: String(data?.franchiseGroup?.amplifyAccountApplication?.id) }
    ]
  )

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center flexDir='column' gap={8} w='100%'>
        <AmplifyApplicationHeader
          backLink={backLink}
          businessName={data?.franchiseGroup?.name ?? null}
          header={header}
          description={description}
        />
        <PersonApplicationFormComponent
          isSubmissionLoading={updatePersonApplicationLoading}
          personalApplication={data?.amplifyPersonApplication}
          isControllingManagerForm={isControllingManager}
          handleSubmit={handleSubmission}
          handleDelete={handleDelete}
          handleCancel={onAction}
          submissionError={submissionError}
        />
      </Center>
    </AltirSkeleton>
  )
}

function getHeaderText (isControllingManager: boolean): { header: string, description: string } {
  if (isControllingManager) {
    return {
      header: 'Add a Controlling Manager',
      description: 'This is the business\'s lead decision maker.'
    }
  }

  return {
    header: 'Add an Owner',
    description: BENEFICIAL_OWNER_DESCRIPTION
  }
}
