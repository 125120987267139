import React, { type ReactElement } from 'react'
import { type DateTime } from 'luxon'
import { Flex } from '@chakra-ui/react'
import { type DateRangeInput } from '@/graphql/__generated__/globalTypes'
import DateRangeSelector, { type DateRangeSelectorInput } from '@/library/date_picker/DateRangeSelector'
import { getISODateOrUndefined, getUserTimeZone, parseDate } from '@/utils/dateUtils'

interface TransactionsDateFilterComponentProps {
  queryValue?: DateRangeInput
  onSubmit: (dateRange: DateRangeInput) => void
}

export default function TransactionsDateFilterComponent (
  { queryValue, onSubmit }: TransactionsDateFilterComponentProps
): ReactElement {
  function handleSubmit (dateRange: DateRangeSelectorInput): void {
    onSubmit({
      startDate: nullableDateTimeToISO(dateRange.startDate),
      endDate: nullableDateTimeToISO(dateRange.endDate)
    })
  }

  return (
    <Flex w='100%' flexDir='column'>
      <DateRangeSelector
        dateRange={{
          startDate: nullableISOToDateTime(queryValue?.startDate),
          endDate: nullableISOToDateTime(queryValue?.endDate)
        }}
        setDateRange={handleSubmit}
      />
    </Flex>
  )
}

/** This converts the ISO string used by the query input to a front end template */
function nullableISOToDateTime (isoDate?: string | null): DateTime | undefined {
  if (isoDate == null) {
    return undefined
  }

  return parseDate(isoDate, getUserTimeZone())
}

/** This converts the formatted template string into an ISO for the backend */
function nullableDateTimeToISO (dateTime?: DateTime): string | undefined {
  if (dateTime == null) return undefined
  return getISODateOrUndefined(dateTime)
}
