import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { isManageExistingAccountLinkEnabled } from './utils'
import { type InstitutionConnectionProvider } from '@/graphql/__generated__/globalTypes'
import ReLinkAccountButton from '@/components/clients/plaid/ReLinkAccountButton'
import MoreInfoTooltip from '@/library/utility/MoreInfoTooltip'

interface ManageAccountsButtonComponentProps {
  handleBankAccountLinkUpdate: () => void
  plaidAccessToken: string
  connectionProvider: InstitutionConnectionProvider
  plaidItemId: string
  franchiseGroupId: number
}
// eslint-disable-next-line max-len
const PLAID_MANAGEMENT_TOOLTIP_TEXT = 'We will take you to Plaid, where you can link the accounts you need, and de-select the accounts you don’t.'

export default function ManageAccountsButtonComponent (
  {
    connectionProvider,
    handleBankAccountLinkUpdate,
    plaidAccessToken,
    plaidItemId,
    franchiseGroupId
  }:
  ManageAccountsButtonComponentProps
): ReactElement | null {
  if (!isManageExistingAccountLinkEnabled(connectionProvider)) {
    return null
  }

  return (
    <Flex gap={2} alignItems='center' _hover={{ cursor: 'pointer' }}>
      <ReLinkAccountButton
        franchiseGroupId={franchiseGroupId}
        handleBankAccountLinkUpdate={handleBankAccountLinkUpdate}
        plaidAccessToken={plaidAccessToken}
        plaidItemId={plaidItemId}
        connectionProvider={connectionProvider}
        customButton={<Text>Manage Accounts with Plaid</Text>}
      />
      <MoreInfoTooltip
        tooltipText={PLAID_MANAGEMENT_TOOLTIP_TEXT}
      />
    </Flex>
  )
}
