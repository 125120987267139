import React, { type ReactElement } from 'react'
import EmptyStateCard from '@/library/card/EmptyStateCard'
import Button from '@/library/button/Button'
import PlusIcon from '@/library/icons/PlusIcon'
import { IconSize } from '@/theme/theme'

interface CheckRecipientDetailsEmptyStateProps {
  onAddDetailsClick: () => void

}

export default function CheckRecipientDetailsEmptyState ({
  onAddDetailsClick
}: CheckRecipientDetailsEmptyStateProps): ReactElement {
  return (
    <EmptyStateCard
      title='We need additional information to send a check to this recipient.'
    >
      <Button
        text='Add Recipient Details'
        beforeIcon={<PlusIcon size={IconSize.SCHMEDIUM}/>}
        onClick={onAddDetailsClick}
      />
    </EmptyStateCard>
  )
}
