import React, { type ReactElement } from 'react'
import FormNumberInput from './FormNumberInput'
import { formatInputAsSSN } from './utils'
import { type BaseFormInputProps } from '../types'

type FormSSNInputProps = {
  value: string
  onChange: (value: string) => void
} & BaseFormInputProps

export default function FormSSNInput (props: FormSSNInputProps): ReactElement {
  return (
    <FormNumberInput
      autoFormatRule={formatInputAsSSN}
      maxNumberOfDigits={9}
      {...props}
    />
  )
}
