import React, { useState, type ReactElement } from 'react'
import { GridItem, Text, useToast } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import EditStoreLocationModal from './EditStoreLocationModal'
import { type GetAllStoreLocations_currentUser_franchiseGroup_storeLocations as StoreLocation }
  from '@/graphql/__generated__/GetAllStoreLocations'
import PopoverEditDeleteMenu from '@/library/popoverMenu/components/PopoverEditDeleteMenu'
import { Color } from '@/theme/theme'
import { INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY } from '@/utils/authUtils'
import { REMOVE_STORE_LOCATION } from '@/graphql/mutations/RemoveStoreLocation'
import { getSuccessToast, getErrorToast } from '@/utils/toastUtils'
import {
  type RemoveStoreLocation,
  type RemoveStoreLocationVariables
} from '@/graphql/__generated__/RemoveStoreLocation'

interface StoreLocationRowProps {
  franchiseGroupId: number
  storeLocation: StoreLocation
  canManageStoreLocationSettings: boolean
  refetchSettings: () => void
}

export default function StoreLocationRow ({
  franchiseGroupId,
  storeLocation,
  canManageStoreLocationSettings,
  refetchSettings
}: StoreLocationRowProps
): ReactElement {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const toast = useToast()

  const [removeStoreLocation] = useMutation<RemoveStoreLocation, RemoveStoreLocationVariables>(REMOVE_STORE_LOCATION, {
    variables: { storeLocationId: storeLocation.id },
    onCompleted: () => {
      refetchSettings()
      toast(getSuccessToast('Store location removed'))
    },
    onError: () => {
      toast(getErrorToast('Error removing store location'))
    }
  })

  function handleClose (): void {
    setIsEditModalOpen(false)
    refetchSettings()
  }
  return (
    <>
      {/* Name */}
      <GridItem colStart={1} justifySelf='left' width='100%'>
        <Text noOfLines={1} maxWidth='95%' color={Color.DARK_BLUE}>
          {storeLocation.name}
        </Text>
      </GridItem>
      {/* Location ID */}
      <GridItem colStart={2} justifySelf='left' width='100%'>
        <Text noOfLines={1} maxWidth='95%' color={Color.DARK_BLUE}>
          {storeLocation.storeId}
        </Text>
      </GridItem>
      {/* Check ID */}
      <GridItem colStart={3} justifySelf='left' width='100%'>
        <Text noOfLines={1} maxWidth='95%' color={Color.DARK_BLUE}>
          {storeLocation.checkFieldIdentifier}
        </Text>
      </GridItem>
      {/* POS ID */}
      <GridItem colStart={4} justifySelf='left' width='100%'>
        <Text noOfLines={1} maxWidth='95%' color={Color.DARK_BLUE}>
          {storeLocation.posFieldIdentifier}
        </Text>
      </GridItem>
      {/* Edit Menu */}
      <GridItem colStart={5} justifySelf='right' width='100%'>
        <PopoverEditDeleteMenu
          editOnClick={() => { setIsEditModalOpen(true) }}
          deleteOnClick={() => { void removeStoreLocation() }}
          isDisabled={!canManageStoreLocationSettings}
          tooltipText={!canManageStoreLocationSettings ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
          backgroundColor={Color.LIGHT_GREY}
        />
      </GridItem>
      <EditStoreLocationModal
        isOpen={isEditModalOpen}
        onClose={handleClose}
        franchiseGroupId={franchiseGroupId}
        storeLocation={storeLocation}
      />
    </>
  )
}
