import React, { type ReactElement, type ReactNode } from 'react'
import { Skeleton } from '@chakra-ui/react'
import ErrorBoundary from './ErrorBoundary'
import ErrorBox from '../errors/ErrorBox'
import { BorderRadius, Color } from '../theme/theme'

export interface AltirSkeletonProps {
  isLoading: boolean
  error?: Error
  isCompact?: boolean
  width?: string
  loadingHeight?: number | string
  marginY?: number
  children: ReactNode
  variant?: SkeletonVariant
  customErrorMessage?: string
  customErrorContent?: ReactNode
}

export enum SkeletonVariant {
  DEFAULT,
  LIGHT
}
const ERROR_MESSAGE = [
  'Something went wrong, please try again later.',
  'If the issues persists, please contact support'
]

export default function AltirSkeleton (
  {
    children,
    isLoading,
    error,
    isCompact = false,
    width = '100%',
    loadingHeight = undefined,
    marginY = 0,
    variant = SkeletonVariant.DEFAULT,
    customErrorMessage,
    customErrorContent
  }: AltirSkeletonProps
): ReactElement {
  if (!isLoading && error != null) {
    return (
      <ErrorBox
        isCompact={isCompact}
        width={width}
        marginY={marginY}
        customMessage={customErrorMessage != null ? [customErrorMessage] : undefined}
        customErrorContent={customErrorContent}
      />
    )
  }

  const { startColor, endColor } = getColors(variant)

  return (
    <Skeleton
      isLoaded={!isLoading}
      width={width}
      height={isLoading ? loadingHeight : undefined}
      borderRadius={BorderRadius.CARD}
      my={marginY}
      startColor={startColor}
      endColor={endColor}
      alignItems='center'
      justifyContent='center'
    >
      <ErrorBoundary fallback={
        <ErrorBox
          isCompact={isCompact}
          width={width}
          marginY={marginY}
          customMessage={ERROR_MESSAGE}
        />}
      >
        {children}
      </ErrorBoundary>
    </Skeleton>
  )
}

function getColors (variant: SkeletonVariant): { startColor: string, endColor: string } {
  switch (variant) {
    case SkeletonVariant.DEFAULT:
      return { startColor: Color.GREY, endColor: Color.DARK_GREY }
    case SkeletonVariant.LIGHT:
      return { startColor: Color.LIGHT_GREY, endColor: Color.WHITE }
  }
}
