import React, { useState, type ReactElement } from 'react'
import { Center, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import CheckRecipientDetailsEmptyState from './CheckRecipientDetailsEmptyState'
import CheckRecipientDetailsCard from './CheckRecipientDetailsCard'
import UpdateCheckRecipientDetailsModal from '../../check_details/UpdateCheckRecipientDetailsModal'
import { type CheckRecipientDetailsFormData } from '../../check_details/CheckRecipientDetailsForm'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { type GetCounterparty, type GetCounterpartyVariables } from '@/graphql/__generated__/GetCounterparty'
import { GET_COUNTERPARTY } from '@/graphql/queries/accounts/GetCounterparty'

interface CheckRecipientDetailsFormSectionProps {
  selectedCounterpartyId: string
  onSuccessfulCounterpartyUpdate: () => void
}

export default function CheckRecipientDetailsFormSection ({
  selectedCounterpartyId,
  onSuccessfulCounterpartyUpdate
}: CheckRecipientDetailsFormSectionProps): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [existingRecipientDetails, setExistingRecipientDetails] = useState<CheckRecipientDetailsFormData>()

  const {
    loading,
    error,
    refetch
  } = useQuery<GetCounterparty, GetCounterpartyVariables>(GET_COUNTERPARTY,
    {
      variables: {
        counterpartyId: selectedCounterpartyId
      },
      onCompleted: (data) => {
        const counterparty = data.counterparty
        if (counterparty.checkRecipientName == null) {
          return
        }

        setExistingRecipientDetails({
          name: counterparty.checkRecipientName,
          address: counterparty.checkRecipientAddress
        })
      }
    }
  )

  function handleSuccessfulCounterpartyUpdate (): void {
    onSuccessfulCounterpartyUpdate()
    void refetch()
  }

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center w='100%' flexDir='column' alignItems='start' gap={2}>
        <Text>Check Recipient Details</Text>
        {existingRecipientDetails != null
          ? <CheckRecipientDetailsCard
              onEdit={() => { setIsModalOpen(true) }}
              existingRecipientDetails={existingRecipientDetails}
            />
          : <CheckRecipientDetailsEmptyState onAddDetailsClick={() => { setIsModalOpen(true) }}/>
        }
        <UpdateCheckRecipientDetailsModal
          counterpartyId={selectedCounterpartyId}
          existingRecipientDetails={existingRecipientDetails ?? null}
          isOpen={isModalOpen}
          onClose={() => { setIsModalOpen(false) }}
          onSuccess={handleSuccessfulCounterpartyUpdate}
        />
      </Center>
    </AltirSkeleton>
  )
}
