import { gql } from '@apollo/client'

export const CREATE_AMPLIFY_ACCOUNT_APPLICATION = gql`
    mutation CreateAmplifyAccountApplication($franchiseGroupId: Int!) {
        createAmplifyAccountApplication(franchiseGroupId: $franchiseGroupId)
    }
`

export const UPDATE_AMPLIFY_ACCOUNT_BUSINESS_APPLICATION = gql`
    mutation UpdateAmplifyAccountBusinessApplication($input: UpdateBusinessApplicationInput!) {
        updateAmplifyAccountBusinessApplication(input: $input)
    }
`

export const CREATE_AMPLIFY_ACCOUNT_PERSONAL_APPLICATION = gql`
    mutation CreateAmplifyAccountPersonalApplication($amplifyApplicationId: String!) {
        createAmplifyAccountPersonalApplication(amplifyApplicationId: $amplifyApplicationId)
    }
`

export const UPDATE_AMPLIFY_ACCOUNT_PERSONAL_APPLICATION = gql`
    mutation UpdateAmplifyAccountPersonalApplication($input: UpdatePersonalApplicationInput!) {
        updateAmplifyAccountPersonalApplication(input: $input)
    }
`

export const DELETE_AMPLIFY_ACCOUNT_APPLICATION = gql`
    mutation DeleteAmplifyAccountApplication($applicationId: String!) {
        deleteAmplifyAccountApplication(applicationId: $applicationId)
    }
`

export const DELETE_AMPLIFY_ACCOUNT_PERSONAL_APPLICATION = gql`
    mutation DeleteAmplifyAccountPersonalApplication($personalApplicationId: String!) {
        deleteAmplifyAccountPersonalApplication(personalApplicationId: $personalApplicationId)
    }
`

export const SUBMIT_AMPLIFY_ACCOUNT_APPLICATION = gql`
mutation SubmitAmplifyAccountApplication($input: SubmitAmplifyAccountApplicationInput!) {
    submitAmplifyAccountApplication(input: $input)
  }
`

export const UPDATE_AMPLIFY_ACCOUNT_EXPECTED_USAGE = gql`
    mutation UpdateAmplifyAccountExpectedUsage(
        $applicationId: String!, 
        $sourcesOfFunds: [String!]!,
        $expectedContinuousSourcesOfFunds: [String!]!,
        $expectedTransferVolume: String!,
        $intendedFunction: AmplifyAccountIntendedFunction!,
        $doesExpectToTransactInternationally: Boolean!
    ) {
        updateAmplifyAccountUsage(
            id: $applicationId, 
            sourcesOfFunds: $sourcesOfFunds, 
            expectedTransferVolume: $expectedTransferVolume,
            expectedContinuousSourcesOfFunds: $expectedContinuousSourcesOfFunds,
            intendedFunction: $intendedFunction,
            doesExpectToTransactInternationally: $doesExpectToTransactInternationally
        )
    }
`
