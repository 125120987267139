import React, { type ReactElement } from 'react'
import { Stack, Text } from '@chakra-ui/react'

export function ConfirmationMessageComponent (): ReactElement {
  return (
    <Stack direction='column' alignItems='center'>
      <Text> We will review your request and contact you with updates
      </Text>
    </Stack>
  )
}
