import { gql } from '@apollo/client'

export const UPDATE_USER = gql`
    mutation UpdateUser($input: UpdateUserInfoInput!, $userId: Int!) {
        updateUser(input: $input, userId: $userId) {
            id
            firstName
            lastName
            email
        }
    }
`
