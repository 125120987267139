import { gql } from '@apollo/client'
import { FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT } from '../fragments/financial_accounts/FinancialAccountExtendedFragment'

export const GET_ACCOUNTS_PAGE_DATA = gql`
    ${FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT}
    query GetAccountsPageData($organizationId: String) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                id
                name
                financialAccounts {
                    ...FinancialAccountExtendedFragment
                }
                franchiseGroups {
                    id
                    name
                }
            }
            rolloutConfiguration {
                enableTransactionRefresh
            }
        }
    }
`
