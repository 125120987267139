import { gql } from '@apollo/client'

export const COMPLETE_INSTITUTION_RELINK = gql`
    mutation CompleteInstitutionReLink(
        $connectionProvider:InstitutionConnectionProvider!,
        $tellerReLinkArgs: TellerReLinkInput,
        $plaidReLinkArgs: PlaidReLinkInput
    ) {
        completeInstitutionReLink(
            connectionProvider: $connectionProvider,
            tellerReLinkArgs: $tellerReLinkArgs,
            plaidReLinkArgs: $plaidReLinkArgs,
        )
    }
`
