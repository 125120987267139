import { gql } from '@apollo/client'
import { TRANSFER_FRAGMENT } from '@/graphql/fragments/TransferFragment'

export const GET_TRANSFER_HISTORY = gql`
    ${TRANSFER_FRAGMENT}
    query GetTransferHistory($franchiseGroupId: Int!) {
        transfers(franchiseGroupId: $franchiseGroupId) {
            ...TransferFragment
        }
    }

`
