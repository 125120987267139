import { BankMigrationStatus } from '@/graphql/__generated__/globalTypes'

/**
 * Return true if migration has been started
 */
export function hasRequestedMigration (status: BankMigrationStatus | null): boolean {
  if (status == null) return false

  return status !== BankMigrationStatus.NOT_STARTED
}

export function isMigrationInProgress (status: BankMigrationStatus | null): boolean {
  if (status == null) return false
  return [
    BankMigrationStatus.REQUESTED,
    BankMigrationStatus.READY,
    BankMigrationStatus.IN_PROGRESS,
    BankMigrationStatus.MANUAL_INTERVENTION_REQUIRED
  ].includes(status)
}
