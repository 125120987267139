import React, { type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import DefaultCard from '@/library/card/DefaultCard'
import { Color, IconSize } from '@/theme/theme'
import InstitutionConnectionComponent from '@/components/clients/financial_institution/InstitutionConnectionComponent'
import Button, { ButtonSize } from '@/library/button/Button'
import AccountsIcon from '@/library/icons/AccountsIcon'
import { useAltirStore } from '@/hooks/store/useAltirStore'

interface ExternalPersonalCreationPlaidWarningProps {
  onSuccessfulLink: () => void
}

export default function ExternalPersonalCreationPlaidWarning ({
  onSuccessfulLink
}: ExternalPersonalCreationPlaidWarningProps): ReactElement {
  const selectedFranchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  return (
    <Flex w='auto'>
      <DefaultCard>
        <Flex flexDir='column' alignItems='center' textAlign='center' gap={6}>
          <Flex flexDir='column' gap={2}>
            <Text color={Color.BRIGHT_BLUE} fontSize='sm'>SUGGESTED</Text>
            <Heading size='md' color={Color.DARK_BLUE}>Have you tried linking <br/>with Plaid first?</Heading>
            <Text fontSize='sm'>
              Link your accounts with our partner, Plaid,<br/>
              to get the most out of Altir. Fill out the <br/>
              form below only if you are unable to link through<br/>
              our trusted method.
            </Text>
          </Flex>
          <InstitutionConnectionComponent
            franchiseGroupId={selectedFranchiseGroupId}
            button={
              <Button
                text='Link an Account'
                width='auto'
                size={ButtonSize.SMALL}
                beforeIcon={<AccountsIcon color={Color.WHITE} size={IconSize.SMALL}/>}
              />
            }
            handleBankAccountLink={onSuccessfulLink}
          />
        </Flex>
      </DefaultCard>
    </Flex>
  )
}
