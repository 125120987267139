import React, { type ReactElement } from 'react'
import IconButton from './IconButton'
import CloseIcon from '../icons/CloseIcon'
import { Color, IconSize } from '@/theme/theme'

interface CloseButtonProps {
  onClick: () => void
  size?: IconSize
}

export default function CloseButton ({ onClick, size = IconSize.SMALL }: CloseButtonProps): ReactElement {
  return (
    <IconButton
      icon={<CloseIcon color={Color.DARK_GREY} size={size}/>}
      onClick={onClick}
    />
  )
}
