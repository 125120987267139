import React, { type ReactElement } from 'react'
import { Grid, GridItem, Divider, Text, Flex } from '@chakra-ui/react'
import { type OrganizationBillingComponentProps } from './OrganizationBillingComponent'
import { Color, BorderRadius } from '@/theme/theme'

export default function BillingInfoComponent (subscriptionDetails?: OrganizationBillingComponentProps): ReactElement {
  const subscription = subscriptionDetails?.subscriptionDetails?.AltirSubscription
  const paymentDetails = subscription?.paymentDetails
  const billingDetails = paymentDetails?.billing_details
  const billingAddress = billingDetails?.address

  if (billingDetails == null) return <></>
  return (
    <Flex w='full' flexDir='column' gap={3}>
      <Text size='sm'color={Color.DARK_GREY}>Billing Information</Text>
      <Grid
        p={6}
        gap={4}
        templateColumns='repeat(2, minmax(0, 1fr))'
        borderRadius={BorderRadius.CARD}
        backgroundColor={Color.LIGHT_GREY}
      >
        <GridItem justifySelf='start'>
          <Text color={Color.DARK_GREY}>Name</Text>
          <Text color={Color.DARK_BLUE}>{billingDetails?.name}</Text>
        </GridItem>
        <GridItem colStart={1} colEnd={3}>
          <Divider color={Color.DARK_GREY}/>
        </GridItem>
        <GridItem justifySelf='start'>
          <Text color={Color.DARK_GREY}>Billing Address</Text>
          <Text color={Color.DARK_BLUE}>{billingAddress?.line1}</Text>
          <Text color={Color.DARK_BLUE}>{billingAddress?.line2}</Text>
          <Text color={Color.DARK_BLUE}>
            {billingAddress?.city}, {billingAddress?.state} {billingAddress?.postalCode}
          </Text>
        </GridItem>
        {billingDetails?.phone != null &&
          <>
            <GridItem>
              <Divider color={Color.DARK_GREY}/>
            </GridItem>
            <GridItem>
              <Text color={Color.DARK_GREY}>Phone Number</Text>
              <Text color={Color.DARK_BLUE}>{billingDetails?.phone}</Text>
            </GridItem>
          </>
        }
      </Grid>
    </Flex>
  )
}
