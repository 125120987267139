import React, { Fragment, type ReactElement } from 'react'
import TableCell from './TableCell'
import { type TableHeader } from '../Table'

interface TableBodyRowProps<T> {
  tableMetadata: Array<TableHeader<T>>
  row: T
}

/**
 * Given a row of table data + the table's metadata,
 *  Formats each cell as prescribed by the tablemetadata
 */
export default function TableBodyRow<T> ({
  tableMetadata,
  row
}: TableBodyRowProps<T>): ReactElement {
  return (
    <Fragment>
      {tableMetadata.map((columnMetadata, colIndex) => {
        return <TableCell key={colIndex} row={row} columnMetadata={columnMetadata} columnIndex={colIndex}/>
      })}
    </Fragment>
  )
}
