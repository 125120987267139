import { ApolloError } from '@apollo/client'
import { getCurrencyFormatted } from './stringUtils'
import { areArraysEqual, nonNull } from './arrayUtils'
import { GraphQLErrorCode } from '../graphql/__generated__/globalTypes'
import { Color } from '@/theme/theme'
import { type ErrorContent, type ErrorWithContent } from '@/types/types'

export const ERROR_BORDER = `1.5px solid ${String(Color.ERROR_RED)}`

export enum ErrorCopy {
  UNAUTHORIZED_TITLE = 'Action not allowed',
  UNAUTHORIZED_SUBTITLE = `
    You do not have permission to perform this action. Contact your administrator to change your role.
  `,
  SOMETHING_WENT_WRONG = 'Something Went Wrong',
  TRY_AGAIN_LATER = 'Please try again later. If the issue persists, contact us.',
  FORM_UNABLE_TO_SUBMIT = 'Unable to submit form',
  FORM_CHECK_FIELDS = 'Please check your form fields and try again.',
}

/**
 * Returns true if the provided ApolloError has an error of a specific type on the provided query path
 */
export function isErrorOfType (error: ApolloError, errorCode: GraphQLErrorCode, queryPath: string[]): boolean {
  // Find error matching specific query path
  const pathError = error.graphQLErrors.find(e => {
    return Object.keys(GraphQLErrorCode).includes(e.extensions?.code) &&
      areArraysEqual(nonNull((e.path ?? []).map(el => String(el))), queryPath)
  })

  // Check whether error type matches the provided
  return pathError != null && pathError.extensions.code === errorCode
}

export function isDuplicateAccountError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.DUPLICATE_ACCOUNT
}

export function isAuthenticationError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.AUTHENTICATION_FAILED
}

export function isAuthorizationError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.AUTHORIZATION_ERROR
}

export function isMaximumTransferRuleExceededError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.MAX_TRANSFER_RULES_REACHED
}

export function isTransferRuleMissingParamsError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.TRANSFER_RULE_MISSING_PARAMS
}

export function isTransferRuleCircularDependencyError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.TRANSFER_RULE_CIRCULAR_DEPENDENCY
}

export function isEmailAlreadyExistsError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.EMAIL_ALREADY_EXISTS
}

export function isEmailInvalidError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.INVALID_EMAIL
}

export function isVerificationEmailExpiredError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.EMAIL_VERIFICATION_EXPIRED
}

export function isUserAlreadyHasOrganizationError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.USER_ALREADY_HAS_ORGANIZATION
}

export function getErrorMetadata (error: ApolloError): any {
  return error.graphQLErrors[0]?.extensions.metadata
}

export function getErrorCode (error?: ApolloError): GraphQLErrorCode | null {
  if (error == null) {
    return null
  }
  const graphQLErrors = error.graphQLErrors
  if (graphQLErrors.length < 1) return null

  const altirError = graphQLErrors.find(e => Object.keys(GraphQLErrorCode).includes(e.extensions?.code))
  if (altirError == null) return null

  return altirError.extensions.code
}

export function getUpdateUserError (error: ApolloError): ErrorWithContent {
  if (isEmailAlreadyExistsError(error)) {
    return {
      customContent: {
        title: ErrorCopy.FORM_UNABLE_TO_SUBMIT,
        subtitle: 'That email already exists in our system. Please try a different one.'
      },
      error
    }
  } else if (isEmailInvalidError(error)) {
    return {
      customContent: {
        title: ErrorCopy.FORM_UNABLE_TO_SUBMIT,
        subtitle: 'Please enter a valid email.'
      },
      error
    }
  }

  return {
    error
  }
}

export function getErrorContent (
  error?: Error | ErrorWithContent
): ErrorContent {
  const rootError = getRootError(error)
  if (isApolloError(rootError)) {
    if (isAuthorizationError(rootError)) {
      return {
        title: ErrorCopy.UNAUTHORIZED_TITLE,
        subtitle: ErrorCopy.UNAUTHORIZED_SUBTITLE
      }
    }
  }

  if (
    error != null &&
    isErrorWithContent(error) &&
    error.customContent != null
  ) {
    return { title: error.customContent.title, subtitle: error.customContent.subtitle }
  }

  // TODO (PJ): parse raw error and display content
  return {
    title: ErrorCopy.SOMETHING_WENT_WRONG,
    subtitle: ErrorCopy.TRY_AGAIN_LATER
  }
}

function getRootError (error?: Error | ErrorWithContent): Error | undefined {
  return isErrorWithContent(error) ? error.error : error
}

function isErrorWithContent (error?: Error | ErrorWithContent): error is ErrorWithContent {
  return (error as ErrorWithContent)?.error instanceof Error
}

function isApolloError (error?: Error): error is ApolloError {
  return (error as ApolloError) instanceof ApolloError
}

// Transfer Error Helpers
export function createInsufficientFundsErrorMessage (amount: number | null): string {
  const amountFormatted = getCurrencyFormatted(amount)
  return `Your account does not have sufficient funds to complete this transfer. Please ensure that there is at least ${amountFormatted} in your account.`
}

export function getAsyncTransferErrorMessage (error: ApolloError, transferAmountString: string | null): string {
  const errorCode = getErrorCode(error)
  switch (errorCode) {
    case GraphQLErrorCode.AUTHORIZATION_ERROR:
      return "You don't have permission to make this transfer. " +
          'Reach out to the account administrator or contact us for help.'
    case GraphQLErrorCode.DUPLICATE_TRANSFER_ERROR:
      return 'This transfer is identical to one you initiated a few minutes ago. ' +
          'If this was intentional, you can try again in 5 minutes or contact support.'
    case GraphQLErrorCode.INSUFFICIENT_FUNDS: {
      const transferAmount = transferAmountString != null ? Number(transferAmountString) : null
      return createInsufficientFundsErrorMessage(transferAmount)
    }
    case GraphQLErrorCode.UNVERIFIED_COUNTERPARTY:
      return 'We need to verify that you own this account. Contact us with questions.'
    case GraphQLErrorCode.TRANSFER_LIMIT_EXCEEDED:
      return 'This transfer exceeds your ACH limits. Try again later or contact us for help.'
    default:
      return 'Try again later or contact us for help.'
  }
}
