/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function CalendarIcon ({ color, size = IconSize.LARGE }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path stroke={color} d='M4.5 9.27604H19.5M15.4091 6.44271V3.60938M8.59091 6.44271V3.60938M5.86364 20.6094H18.1364C18.498 20.6094 18.8449 20.4601 19.1006 20.1944C19.3563 19.9288 19.5 19.5684 19.5 19.1927V6.44271C19.5 6.06698 19.3563 5.70665 19.1006 5.44097C18.8449 5.1753 18.498 5.02604 18.1364 5.02604H5.86364C5.50198 5.02604 5.15513 5.1753 4.8994 5.44097C4.64367 5.70665 4.5 6.06698 4.5 6.44271V19.1927C4.5 19.5684 4.64367 19.9288 4.8994 20.1944C5.15513 20.4601 5.50198 20.6094 5.86364 20.6094Z' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      </svg>
    </IconContainer>
  )
}
