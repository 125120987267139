import {
  inputAnatomy,
  selectAnatomy,
  menuAnatomy,
  accordionAnatomy,
  tabsAnatomy,
  switchAnatomy
} from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle, defineStyleConfig } from '@chakra-ui/react'
import {
  BorderRadius,
  BoxShadow,
  Color,
  FontWeight,
  FormInputHeight
} from './theme'

// Input styles
const {
  definePartsStyle: defineInputPartsStyle,
  defineMultiStyleConfig: defineInputMultiStyle
} = createMultiStyleConfigHelpers(inputAnatomy.keys)
const inputStyleConfig = defineInputPartsStyle({
  field: {
    borderRadius: BorderRadius.BUTTON,
    fontWeight: FontWeight.MEDIUM,
    height: FormInputHeight.DEFAULT,
    fontSize: 'md'
  },
  element: {
    h: '100%',
    px: 10
  }
})
export const inputTheme = defineInputMultiStyle({ baseStyle: inputStyleConfig })

// Number Input styles
const {
  definePartsStyle: defineNumberInputPartsStyle,
  defineMultiStyleConfig: defineNumberInputMultiStyle
} = createMultiStyleConfigHelpers(inputAnatomy.keys)
const numberInputStyleConfig = defineNumberInputPartsStyle({
  field: {
    borderRadius: BorderRadius.BUTTON,
    fontWeight: FontWeight.MEDIUM,
    height: FormInputHeight.DEFAULT,
    fontSize: 'md'
  },
  element: {
    h: '100%',
    px: 10
  }
})
export const numberInputTheme = defineNumberInputMultiStyle({ baseStyle: numberInputStyleConfig })

// Radio styles
export const radioStyleConfig = defineStyleConfig({
  baseStyle: {
    control: {
      borderColor: 'selectableInput.300',
      _hover: {
        borderColor: 'selectableInput.600'
      }
    }
  }
})

// Checkbox styles
export const checkBoxStyleConfig = defineStyleConfig({
  baseStyle: {
    control: {
      borderColor: Color.DARK_GREY,
      borderRadius: BorderRadius.BUTTON_HOVER,
      _hover: {
        borderColor: Color.DARK_BLUE
      }
    }
  }
})

// Divider
const dividerProps = {
  borderRadius: BorderRadius.CARD,
  borderStyle: 'solid',
  borderColor: Color.GREY
}

const defaultDivider = defineStyle({
  ...dividerProps,
  borderWidth: 1
})

const thickDivider = defineStyle({
  ...dividerProps,
  borderWidth: 2
})

export const dividerStyleConfig = defineStyleConfig({
  defaultProps: {
    variant: 'default'
  },
  variants: {
    default: defaultDivider,
    thick: thickDivider
  }
})

// Text styles
export const textStyleConfig = defineStyleConfig({
  baseStyle: {
    fontWeight: FontWeight.MEDIUM,
    color: Color.DARK_GREY,
    fontSize: 'md'
  }
})

// Heading styles
export const headingStyleConfig = defineStyleConfig({
  baseStyle: {
    fontWeight: FontWeight.MEDIUM,
    color: Color.DARK_BLUE
  },
  sizes: {
    '2xl': {
      fontSize: '2rem'
    },
    xl: {
      fontSize: '1.75rem'
    },
    lg: {
      fontSize: '1.5rem'
    },
    md: {
      fontSize: '1.25rem'
    },
    sm: {
      fontSize: '1rem'
    }
  }
})

// Select styles
const {
  defineMultiStyleConfig: defineSelectMultiStyleConfig
} = createMultiStyleConfigHelpers(selectAnatomy.keys)
export const selectorTheme = defineSelectMultiStyleConfig({
  baseStyle: {
    field: {
      w: '100%',
      _hover: { cursor: 'pointer' },
      h: FormInputHeight.DEFAULT
    }

  },
  variants: {
    button: {
      field: {
        textAlign: 'center',
        focusBorderColor: Color.DARK_GREY,
        borderStyle: 'solid',
        border: '1px',
        borderColor: Color.DARK_BLUE,
        fontWeight: FontWeight.MEDIUM,
        fontSize: 'md',
        borderRadius: BorderRadius.BUTTON
      },
      icon: {
        fontSize: 'lg'
      }
    }
  }
})

// Badge styles
export const badgeTheme = defineStyleConfig({
  baseStyle: {
    borderRadius: BorderRadius.BUTTON,
    alignItems: 'center',
    display: 'flex',
    px: 3
  }
})

// Tooltip styles
export const tooltipTheme = defineStyleConfig({
  baseStyle: {
    bg: Color.DARK_BLUE,
    color: Color.WHITE,
    borderRadius: BorderRadius.CARD,
    py: 2,
    px: 4
  }
})

// Menu styles
const {
  definePartsStyle: defineMenuPartsStyle,
  defineMultiStyleConfig: defineMenuMultiStyleConfig
} = createMultiStyleConfigHelpers(menuAnatomy.keys)
const menuBaseStyle = defineMenuPartsStyle({
  button: {
    bg: Color.WHITE,
    _hover: {
      bg: Color.WHITE
    }
  },
  list: {
    borderRadius: BorderRadius.CARD,
    border: 'none',
    bg: Color.WHITE,
    py: 3,
    boxShadow: BoxShadow.LIGHT
  },
  item: {
    borderRadius: BorderRadius.BAR,
    color: Color.DARK_GREY,
    _hover: {
      bg: Color.LIGHT_GREY
    },
    _selected: {
      bg: Color.WHITE
    },
    _focus: { bg: Color.WHITE },
    px: 4,
    py: 3
  }
})
export const menuStyleConfig = defineMenuMultiStyleConfig({
  baseStyle: menuBaseStyle,
  variants: {
    CheckBox: {
      list: { px: 4, py: 6, bg: Color.WHITE, boxShadow: BoxShadow.LIGHT },
      trigger: { },
      content: { },
      command: { },
      icon: { },
      groupTitle: { color: Color.DARK_GREY, fontWeight: FontWeight.BOLD },
      divider: { borderColor: Color.DARK_GREY },
      item: {
        py: 3,
        pl: 4,
        bg: Color.WHITE,
        _hover: { bg: Color.LIGHT_GREY },
        _focus: { bg: Color.WHITE },
        _active: { bg: Color.WHITE },
        color: Color.DARK_BLUE,
        fontWeight: FontWeight.MEDIUM,
        borderRadius: BorderRadius.BAR
      }
    }
  }
})

// Accordion styles
const {
  definePartsStyle: defineAccordionPartStyle,
  defineMultiStyleConfig: defineAccordionMultiStyleConfig
} = createMultiStyleConfigHelpers(accordionAnatomy.keys)
const accordionBaseStyle = defineAccordionPartStyle({
  container: {
    border: 'none',
    px: 0
  },
  panel: {
    p: 1
  },
  root: {
    px: 0
  },
  button: {
    borderRadius: BorderRadius.BAR,
    px: 2
  }
})
export const accordionTheme = defineAccordionMultiStyleConfig({ baseStyle: accordionBaseStyle })

// Tabs styles
const {
  definePartsStyle: defineTabsPartsStyle,
  defineMultiStyleConfig: defineTabsMultiStyleConfig
} = createMultiStyleConfigHelpers(tabsAnatomy.keys)
const tabsStyle = defineTabsPartsStyle({
  tab: {
    fontWeight: 'medium',
    _selected: {
      bg: 'transparent',
      fontWeight: 'medium',
      color: Color.DARK_BLUE,
      borderColor: Color.BRIGHT_BLUE,
      borderBottom: `2px solid ${String(Color.BRIGHT_BLUE)}`
    },
    _hover: {
      color: Color.BRIGHT_BLUE_HOVER
    }
  },
  tabpanel: {
    py: 8
  }
})
export const tabsTheme = defineTabsMultiStyleConfig({
  baseStyle: tabsStyle,
  defaultProps: {
    size: 'md'
  }
})

// Switch styles
const {
  definePartsStyle: defineSwitchPartsStyle,
  defineMultiStyleConfig: defineSwitchStyleConfig
} = createMultiStyleConfigHelpers(switchAnatomy.keys)
const switchStyle = defineSwitchPartsStyle({
  track: {
    bg: Color.GREY,
    _checked: {
      bg: Color.BRIGHT_BLUE
    }
  }
})
export const switchTheme = defineSwitchStyleConfig({ baseStyle: switchStyle })
