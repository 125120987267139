/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function CloudErrorIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 44 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M1.16602 21.2087C1.16602 22.4399 1.40851 23.659 1.87965 24.7964C2.35078 25.9338 3.04134 26.9673 3.91189 27.8379C5.67004 29.596 8.05461 30.5837 10.541 30.5837H35.541C37.3868 30.5871 39.1651 29.8903 40.5173 28.6339C41.8695 27.3776 42.6949 25.6552 42.8271 23.8142C42.9593 21.9732 42.3883 20.1505 41.2294 18.7139C40.0706 17.2773 38.41 16.3337 36.5827 16.0733C36.603 12.5291 35.3319 9.09874 33.007 6.42352C30.6821 3.7483 27.4625 2.01133 23.95 1.5373C20.4376 1.06328 16.8727 1.88464 13.9218 3.84785C10.9709 5.81106 8.83592 8.78174 7.91601 12.2046C5.96835 12.7733 4.25765 13.9584 3.0406 15.5819C1.82355 17.2054 1.16579 19.1797 1.16602 21.2087Z' stroke={color} strokeWidth='2' strokeLinejoin='round'/>
        <path d='M22 23.2969H22.0208V23.3177H22V23.2969Z' stroke={color} strokeWidth='3' strokeLinejoin='round'/>
        <path d='M22.002 18L22.002 10' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      </svg>
    </IconContainer>
  )
}
