import React, { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import OnboardingPage from '@/library/page/OnboardingPage'
import
PersonApplicationComponent from
  '@/components/pages/amplify_account_application/routes/apply/components/person/PersonApplicationComponent'
import { useAmplifyAppNavigation } from '@/hooks/onboarding/useAmplifyAppNavigation'
import { ContainerWidth } from '@/theme/theme'

export default function AmplifyApplyPersonPage (): ReactElement {
  const { BUSINESS_ID, PERSON_ID } = useParams()
  const { skipCallToAction } = useAmplifyAppNavigation(String(BUSINESS_ID))

  return (
    <OnboardingPage
      maxWidth={ContainerWidth.LARGE}
      callToAction={skipCallToAction}
      amplifyApplicationContext={{ franchiseGroupId: Number(BUSINESS_ID) }}
    >
      <PersonApplicationComponent
        franchiseGroupId={Number(BUSINESS_ID)}
        personApplicationId={String(PERSON_ID)}
        context={AmplifyApplicationContext.DEFAULT}
      />
    </OnboardingPage>
  )
}
