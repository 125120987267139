import { gql } from '@apollo/client'
import {
  FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT
} from '@/graphql/fragments/financial_accounts/FinancialAccountExtendedFragment'

export const GET_ACCOUNT_UPGRADE_PAGE_DATA = gql`
    ${FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT}
    query GetAccountUpgradePageData($organizationId: String!, $financialAccountFilter: FinancialAccountFilterInput) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                bankMigrationStatus
                financialAccounts(financialAccountFilter: $financialAccountFilter) {
                    ...FinancialAccountExtendedFragment
                }
            }
        }
    }
`
