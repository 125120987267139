import React, { type ReactElement } from 'react'
import { Text } from '@chakra-ui/react'
import { DisclosureText } from './disclosureText'

interface NotaBankDisclosureProps {
  width?: string

}

export default function NotABankDisclosure ({ width = '360px' }: NotaBankDisclosureProps): ReactElement {
  return (
    <Text fontSize='xs' maxW={width} textAlign='center'>
      {DisclosureText.ALTIR_IS_NOT_A_BANK}
    </Text>
  )
}
