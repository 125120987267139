import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { getFormattedUserRole } from '@/utils/authUtils'
import { Color, IconSize } from '@/theme/theme'
import { type Role } from '@/graphql/__generated__/globalTypes'
import EditIcon from '@/library/icons/EditIcon'

interface EditRoleInputProps {
  role: Role | null
  canEditUsers: boolean
  onClick: () => void
}

export default function EditRoleInput ({
  role,
  canEditUsers,
  onClick
}: EditRoleInputProps): ReactElement {
  return (
    <Flex
      role='group'
      gap={2}
      alignItems='center'
      _hover={canEditUsers ? { cursor: 'pointer' } : {}}
      onClick={() => { canEditUsers && onClick() }}
    >
      <Text color={Color.DARK_BLUE}>
        {getFormattedUserRole(role)}
      </Text>
      {canEditUsers &&
        <Flex
          visibility='hidden'
          _groupHover={{ visibility: 'visible' }}
          alignSelf='center'
          cursor='pointer'
        >
          <EditIcon size={IconSize.SMALL} color={Color.DARK_BLUE}/>
        </Flex>
      }
    </Flex>
  )
}
