import { Flex, Table, TableContainer, Tbody, Thead, Tr } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import EmptyTransferComponent from './EmptyTransfersComponent'
import TransferTableRow from './TransferTableRow'
import TableHeader from '@/library/table/deprecated/TableHeader'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { BorderRadius, Color, IconSize } from '@/theme/theme'
import {
  type TransferFragment as Transfer
} from '@/graphql/__generated__/TransferFragment'

export interface TransferTableComponentProps {
  transfers: Transfer[]
  isCompact?: boolean
}

export default function TransferTableComponent (
  { transfers, isCompact = false }: TransferTableComponentProps
): ReactElement {
  if (transfers.length === 0) {
    return <EmptyTransferComponent isInline={isCompact}/>
  }

  return (
    <TableContainer borderRadius={BorderRadius.CARD} w='100%'>
      <Table variant='simple'>
        <Thead>
          <Tr bg={Color.GREY} px={4}>
            <TableHeader text='Date of Transfer'/>
            <TableHeader text='Amount'/>
            <TableHeader text='Status'/>
            <TableHeader>
              <Flex alignItems='center'>
                From
                <Flex px={1}>
                  <RightArrowIcon size={IconSize.MEDIUM} color={Color.DARK_BLUE}/>
                </Flex>
                To
              </Flex>
            </TableHeader>
            {!isCompact && <TableHeader text='Action'/>}
          </Tr>
        </Thead>
        <Tbody>
          {transfers.map((transfer, i) => (<TransferTableRow key={i} transfer={transfer} isCompact={isCompact}/>))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
