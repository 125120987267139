import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import StackedInfoAttribute from '../../../library/utility/StackedInfoAttribute'
import { ROUTES } from '@/api/routes'
import { getFormattedTimeRule } from '@/utils/transferRuleUtils'
import { type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule }
  from '@/graphql/__generated__/GetTransferRules'
import { DerivedTransactionCategory, TransferRuleType } from '@/graphql/__generated__/globalTypes'

export interface TimeRuleDropdownComponentProps {
  transferRule: TransferRule
}

export default function TimeRuleDropdownComponent (
  { transferRule }: TimeRuleDropdownComponentProps
): ReactElement {
  const navigate = useNavigate()

  const formattedRule = getFormattedTimeRule(transferRule)
  const transferRuleType = transferRule.transferRuleType

  function getNextTransfer (): ReactElement {
    return (
      <StackedInfoAttribute
        header={transferRuleType === TransferRuleType.ONE_TIME ? 'Date of Transfer' : 'Next Transfer'}
        subtext={transferRuleType === TransferRuleType.ONE_TIME
          ? formattedRule.ruleStartDate ?? ''
          : formattedRule.nextTransfer}
      />
    )
  }

  function getMostRecentTransfer (): ReactElement | undefined {
    if (transferRule.mostRecentTransferEST == null) {
      return undefined
    }

    return (
      <StackedInfoAttribute
        header={transferRuleType === TransferRuleType.ONE_TIME ? 'Date of Transfer' : 'Last Transfer'}
        subtext={transferRuleType === TransferRuleType.ONE_TIME
          ? formattedRule.ruleStartDate ?? ''
          : formattedRule.mostRecentTransfer}
        linkOptions={{
          linkText: 'View All',
          onLinkClick: () => {
            /* TODO Add additional filter on bank account id */
            navigate(ROUTES.TRANSACTIONS,
              { state: { categoryLabels: [DerivedTransactionCategory.AUTOMATED_INTERNAL_TRANSFER] } })
          }
        }}
      />
    )
  }

  function getSubFrequency (): string {
    if (transferRuleType === TransferRuleType.WEEKLY) {
      return formattedRule.dayOfWeek != null ? `on ${formattedRule.dayOfWeek}` : ''
    } else if (transferRuleType === TransferRuleType.MONTHLY) {
      return formattedRule.dateOfMonth != null ? `on the ${formattedRule.dateOfMonth}` : ''
    }
    return ''
  }

  return (
    <Flex direction='row' justifyItems='space-between' width='100%'>
      <Flex direction='column' gap={5} flex={1}>
        <StackedInfoAttribute header='Amount' subtext={formattedRule.amount}/>
        <StackedInfoAttribute header='Frequency' subtext={`${formattedRule.frequency} ${getSubFrequency()}`}/>
      </Flex>
      <Flex direction='column' gap={5} flex={1}>
        <StackedInfoAttribute header='From' subtext={formattedRule.from}/>
        {transferRule.nextScheduledTransferEST !== null ? getNextTransfer() : getMostRecentTransfer()}
      </Flex>
      <Flex direction='column' gap={5} flex={1}>
        <StackedInfoAttribute header='To' subtext={formattedRule.to}/>
        {transferRule.nextScheduledTransferEST !== null ? getMostRecentTransfer() : null}
      </Flex>
    </Flex>
  )
}
