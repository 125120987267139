import React, { type ReactNode, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import DropDownIsSelectedIcon from '../DropDownIsSelectedIcon'
import { BorderRadius } from '@/theme/theme'

interface DropDownCustomHeaderProps {
  customHeader: ReactNode
  isSelected: boolean
  isDisabled: boolean
  onClick: () => void
}

/**
 * Wrapper that extends custom headers to add clickability + DropDown functionality
 */
export default function DropDownCustomHeader ({
  customHeader,
  isSelected,
  isDisabled,
  onClick
}: DropDownCustomHeaderProps): ReactElement {
  return (
    <Flex
      width='100%'
      alignItems='center'
      align='center'
      borderRadius={BorderRadius.CARD}
      gap={6}
    >
      {customHeader}
      <DropDownIsSelectedIcon isSelected={isSelected} onClick={onClick} isDisabled={isDisabled}/>
    </Flex>
  )
}
