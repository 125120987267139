import { gql } from '@apollo/client'

export const UPDATE_ORGANIZATION_ONBOARDING_STATE = gql`
    mutation UpdateOrganizationOnboardingState(
        $organizationId: String!, 
        $isAmplifyOnboardingComplete: Boolean, 
        $isBusinessOnboardingComplete: Boolean, 
        $isStripeOnboardingSkipped: Boolean
        $isOnboardingCompletionAcknowledged: Boolean
    ) {
        updateOrganizationOnboardingState(
            organizationId: $organizationId, 
            isAmplifyOnboardingComplete: $isAmplifyOnboardingComplete, 
            isBusinessOnboardingComplete: $isBusinessOnboardingComplete, 
            isStripeOnboardingSkipped: $isStripeOnboardingSkipped,
            isOnboardingCompletionAcknowledged: $isOnboardingCompletionAcknowledged
        )
    }
`
