import React, { type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import TransactionsComponent from '@/components/pages/transactions/components/TransactionsComponent'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import {
  type GetTransactionsPageData,
  type GetTransactionsPageDataVariables
} from '@/graphql/__generated__/GetTransactionsPageData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { GET_TRANSACTIONS_PAGE_DATA } from '@/graphql/queries/GetTransactionsPageData'

interface AccountTransactionsComponentProps {
  accountId: number
}

export default function AccountTransactionsComponent ({
  accountId
}: AccountTransactionsComponentProps): ReactElement {
  const organizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const {
    data: transactionsPageData,
    loading: isPageDataLoading,
    error: pageDataError
  } = useQuery<GetTransactionsPageData, GetTransactionsPageDataVariables>(
    GET_TRANSACTIONS_PAGE_DATA,
    {
      variables: {
        organizationId
      }
    }
  )

  return (
    <AltirSkeleton isLoading={isPageDataLoading} error={pageDataError}>
      <TransactionsComponent
        transactionsPageData={transactionsPageData}
        isPageDataLoading={isPageDataLoading}
        organizationId={organizationId}
        singleAccountViewAccountId={accountId}
      />
    </AltirSkeleton>
  )
}
