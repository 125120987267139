import { gql } from '@apollo/client'
import { BUSINESS_FRAGMENT } from '../../fragments/BusinessFragment'

export const GET_BUSINESS = gql`
    ${BUSINESS_FRAGMENT}
    query GetBusiness($franchiseGroupId: Int!) {
        franchiseGroup(franchiseGroupId: $franchiseGroupId) {
            ...BusinessFragment
        }
        currentUser {
            rolloutConfiguration {
                enableCheckIssuance
            }
        }
    }
`
