/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { Icon } from '@chakra-ui/react'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function UserIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <Icon boxSize={size} display='flex' alignItems='center' justifyContent='center'>
      <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path stroke={color} d='M12 14.375C9.49687 14.375 4.5 15.6219 4.5 18.125V19C4.5 19.5523 4.94772 20 5.5 20H18.5C19.0523 20 19.5 19.5523 19.5 19V18.125C19.5 15.6219 14.5031 14.375 12 14.375ZM12 11.5C12.9946 11.5 13.9484 11.1049 14.6516 10.4017C15.3549 9.69839 15.75 8.74456 15.75 7.75C15.75 6.75544 15.3549 5.80161 14.6516 5.09835C13.9484 4.39509 12.9946 4 12 4C11.0054 4 10.0516 4.39509 9.34835 5.09835C8.64509 5.80161 8.25 6.75544 8.25 7.75C8.25 8.74456 8.64509 9.69839 9.34835 10.4017C10.0516 11.1049 11.0054 11.5 12 11.5Z' strokeWidth='2'/>
      </svg>
    </Icon>
  )
}
