/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { Icon } from '@chakra-ui/react'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function HelpRaftIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <Icon boxSize={size} display='flex' alignItems='center' justifyContent='center'>
      <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill={color} stroke={color} fillRule='evenodd' clipRule='evenodd' d='M12 3C7.02949 3 3 7.02949 3 12C3 16.9705 7.02949 21 12 21C16.9705 21 21 16.9705 21 12C21 7.02949 16.9705 3 12 3ZM4.25581 12C4.25581 10.0878 4.94902 8.33721 6.09767 6.98595L8.77926 9.6667C8.28662 10.3448 8.02191 11.1618 8.02326 12C8.02197 12.8382 8.28667 13.6551 8.77926 14.3333L6.09767 17.014C4.90611 15.6154 4.25297 13.8374 4.25581 12ZM9.6667 8.77926L6.98595 6.09767C8.38458 4.90611 10.1626 4.25297 12 4.25581C13.9122 4.25581 15.6628 4.94902 17.014 6.09767L14.3325 8.77926C13.6546 8.28674 12.8379 8.02203 12 8.02326C11.1618 8.02191 10.3448 8.28662 9.6667 8.77926ZM6.98595 17.9023C8.38458 19.0939 10.1626 19.747 12 19.7442C13.8374 19.747 15.6154 19.0939 17.014 17.9023L14.3325 15.2207C13.6546 15.7133 12.8379 15.978 12 15.9767C11.1618 15.9781 10.3448 15.7134 9.6667 15.2207L6.98595 17.9023ZM15.2207 14.3325L17.9023 17.014C19.0939 15.6154 19.747 13.8374 19.7442 12C19.747 10.1626 19.0939 8.38458 17.9023 6.98595L15.2207 9.6667C15.7133 10.3449 15.978 11.1618 15.9767 12C15.9781 12.8382 15.7134 13.6544 15.2207 14.3325ZM9.27907 12C9.27907 11.2784 9.56574 10.5863 10.076 10.076C10.5863 9.56574 11.2784 9.27907 12 9.27907C12.7216 9.27907 13.4137 9.56574 13.924 10.076C14.4343 10.5863 14.7209 11.2784 14.7209 12C14.7209 12.7216 14.4343 13.4137 13.924 13.924C13.4137 14.4343 12.7216 14.7209 12 14.7209C11.2784 14.7209 10.5863 14.4343 10.076 13.924C9.56574 13.4137 9.27907 12.7216 9.27907 12Z' strokeWidth='0.8'/>
      </svg>
    </Icon>
  )
}
