import React, { type ReactElement } from 'react'
import { Flex, Switch, Text } from '@chakra-ui/react'

interface WireDetailsSwitchComponentProps {
  onSwitch: (wireAccountValue: string, wireRoutingValue: string) => void
  achAccountNumber: string
  achRoutingNumber: string
}

export default function WireDetailsSwitchComponent ({
  onSwitch,
  achAccountNumber,
  achRoutingNumber
}: WireDetailsSwitchComponentProps): ReactElement {
  function onChange (event: React.ChangeEvent<HTMLInputElement>): void {
    if (event.target.checked) {
      onSwitch(achAccountNumber, achRoutingNumber)
    } else {
      onSwitch('', '')
    }
  }

  return (
    <Flex width='100%' gap={14}>
      <Text fontSize='xs'> Wire account and routing number are the same as for ACH transfers </Text>
      <Switch onChange={(event) => { onChange(event) }}/>
    </Flex>
  )
}
