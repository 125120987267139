import { gql } from '@apollo/client'
import { ADDRESS_FRAGMENT } from './AddressFragment'

export const AMPLIFY_ACCOUNT_OWNERSHIP_INFO_FRAGMENT = gql`
    ${ADDRESS_FRAGMENT}
    fragment AmplifyAccountOwnershipInfoFragment on AmplifyAccountFormatted {
        people {
            treasuryPrimeId
            firstName
            middleName
            lastName
            mailingAddress {
                ...AddressFragment
            }
            email
            phoneNumber
            physicalAddress {
                ...AddressFragment
            }
            occupation
            title
            ownershipPercentage
            dateOfBirth
            citizenship
        }
        business {
            treasuryPrimeId
            emails
            mailingAddress {
                ...AddressFragment
            }
            name
            phoneNumber
            legalName
            doingBusinessAs
            description
            establishmentDate
            incorporationState
            legalStructure
            url
        }
    }
`
