import React, { useState, type ReactElement } from 'react'
import DropDownComponent from '@/library/layout/drop_down/DropDownComponent'
import { Color } from '@/theme/theme'
import MailIcon from '@/library/icons/MailIcon'
import CheckRecipientDetailsForm, {
  type CheckRecipientDetailsFormData
} from '@/components/pages/transfer/components/check_details/CheckRecipientDetailsForm'

interface RecipientCheckDetailsDropdownProps {
  globalFormData: CheckRecipientDetailsFormData | null
  onSubmit: (formData: CheckRecipientDetailsFormData) => void
}

export default function RecipientCheckDetailsDropdown ({
  globalFormData,
  onSubmit
}: RecipientCheckDetailsDropdownProps): ReactElement {
  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false)

  function handleFormSubmission (newFormData: CheckRecipientDetailsFormData): void {
    onSubmit(newFormData)
    setIsDropdownExpanded(false)
  }

  return (
    <DropDownComponent
      title='Check Recipient Details'
      icon={<MailIcon color={Color.DARK_BLUE}/>}
      isOpenFromParent={isDropdownExpanded}
      setIsOpenFromParent={(isOpen) => { setIsDropdownExpanded(isOpen) }}
      rightDescription={globalFormData != null ? 'Complete' : undefined}
      isComplete={globalFormData != null}
      dropDownContent={
        <CheckRecipientDetailsForm
          existingRecipientDetails={globalFormData}
          isSubmissionLoading={false}
          onSubmit={handleFormSubmission}
          onCancel={() => { setIsDropdownExpanded(false) }}
          backgroundColor={Color.LIGHT_GREY}
        />
        }
    />
  )
}
