import React, { type ReactElement } from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import DashboardSection from './layout/DashboardSection'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { GET_TOTAL_CASH_SECTION_DATA } from '@/graphql/queries/GetInsightsData'
import {
  type GetTotalCashSectionDataVariables,
  type GetTotalCashSectionData
} from '@/graphql/__generated__/GetTotalCashSectionData'
import { Color } from '@/theme/theme'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import BusinessSelectorComponent from '@/library/state/BusinessSelectorComponent'

interface TotalAccountBalanceSectionProps {
  franchiseGroupId: number
}

export default function TotalAccountBalanceSection (
  {
    franchiseGroupId
  }: TotalAccountBalanceSectionProps
): ReactElement {
  const { data, loading, error } = useQuery<GetTotalCashSectionData, GetTotalCashSectionDataVariables>(
    GET_TOTAL_CASH_SECTION_DATA,
    { variables: { franchiseGroupId } }
  )
  const franchiseGroup = data?.currentUser?.franchiseGroup
  const availableBalance = franchiseGroup?.aggregateAccountBalance?.availableBalance?.amount

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <DashboardSection title='Total Cash'>
        <Flex w='100%' justifyContent='space-between' alignItems='center'>
          <Heading
            color={availableBalance != null ? Color.DARK_BLUE : Color.DARK_GREY}
            size='2xl'
          >
            {getCurrencyFormatted(availableBalance)}
          </Heading>
          <Flex alignItems='center'>
            <BusinessSelectorComponent requireMultipleBusinesses={true}/>
          </Flex>
        </Flex>
      </DashboardSection>
    </AltirSkeleton>
  )
}
