import { Flex, Heading, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import React, { useState, type ReactElement } from 'react'
import { Event } from 'metrics/metrics'
import { useUploadFile } from '../../../../../hooks/useUploadFile'
import ErrorInline from '@/library/errors/ErrorInline'
import { FileUploadInput } from '@/library/form/file/FileUploadInput'
import Button from '@/library/button/Button'
import { FormInput } from '@/library/form/text/FormInput'
import SelectorComponent from '@/library/form/select/SelectorComponent'
import FileUploadIcon from '@/library/icons/FileUploadIcon'
import {
  type FileUploadDescription,
  getFileUploadDescription,
  getFileUploadType,
  formatBytes
} from '@/utils/stringUtils'
import { FileUploadType } from '@/graphql/__generated__/globalTypes'
import { BorderRadius, Color } from '@/theme/theme'
import ModalCloseHeader from '@/library/modal/ModalCloseHeader'

export interface DocumentUploadModalProps {
  franchiseGroupId: number
  organizationId: string | null
  lendingDocumentTypes: FileUploadType[]
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
}

export default function DocumentUploadModal (
  {
    franchiseGroupId,
    organizationId,
    isOpen,
    onClose,
    onSuccess,
    lendingDocumentTypes
  }: DocumentUploadModalProps
): ReactElement {
  const [uploadFile, { loading, error: uploadFileError }] = useUploadFile()
  const [file, setFile] = useState<File | undefined>()
  const [type, setType] = useState<FileUploadType | undefined>()
  const [name, setName] = useState<string | undefined>()
  const [submissionError, setSubmissionError] = useState<Error>()
  const [fileSelectionError, setFileSelectionError] = useState<Error>()

  function handleFileChange (e: React.ChangeEvent<HTMLInputElement>): void {
    const selectedFile = (e.target.files ?? [])[0]
    if (selectedFile == null) {
      setFileSelectionError(Error('No file selected'))
      return
    }

    setFile(selectedFile)
  }

  function handleSuccess (): void {
    onSuccess()
    handleClose()
  }

  function handleFileUpload (): void {
    if (file == null || type == null) {
      setSubmissionError(Error('Invalid form data'))
      return
    }

    const updatedFileName = getUpdatedFileName(file, name)
    if (updatedFileName == null) {
      setSubmissionError(Error('Invalid form data'))
      return
    }

    const renamedFile = {
      ...file,
      name: updatedFileName
    }
    void uploadFile({
      file: renamedFile,
      type,
      franchiseGroupId,
      organizationId,
      onSuccess: handleSuccess
    })
  }

  function handleTypeChange (typeDescription: string): void {
    // It would be cleaner to allow FormSelect to take in FileUploadDescription as an option
    // type, but given that we're passing FileUploadDescription[] as options, this is a safe cast
    setType(getFileUploadType(typeDescription as FileUploadDescription))
  }
  function handleNameChange (event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target
    setName(value)
  }

  function handleClose (): void {
    setFile(undefined)
    setName(undefined)
    setType(undefined)
    onClose()
  }

  function getContent (): ReactElement {
    if (file == null) {
      return (
        <Flex flexDir='column' w='100%' gap={6}>
          <Text>Select a file from your computer</Text>
          <FileUploadInput
            type={FileUploadType.OTHER}
            onChange={handleFileChange}
            isLoading={false}
            customButton={
              <Button
                text='Select a File'
              />
          }
          />
          <ErrorInline error={fileSelectionError}/>
        </Flex>
      )
    }

    return (
      <Flex flexDir='column' gap={6} w='100%'>
        <Text color={Color.DARK_BLUE}>{file.name}{' '}({formatBytes(file.size)})</Text>
        <Flex flexDir='column' gap={4} w='100%'>
          <FormInput
            fieldName='FileName'
            label='Name'
            onChange={handleNameChange}
            value={name}
            placeholder='i.e. Tax Return 2023'
          />
          <SelectorComponent
            options={lendingDocumentTypes.map(t => getFileUploadDescription(t))}
            label='Category'
            placeHolder='Choose document category'
            value={type != null ? getFileUploadDescription(type) : null}
            handleSelection={handleTypeChange}
            backgroundColor={Color.WHITE}
            alignText='start'
          />
        </Flex>
        <Button
          text='Upload Document'
          beforeIcon={<FileUploadIcon/>}
          isLoading={loading}
          onClick={handleFileUpload}
          isDisabled={type == null}
          onClickEventType={Event.FILE_UPLOAD_CLICK}
        />
        <ErrorInline error={uploadFileError ?? submissionError}/>
      </Flex>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered={true}
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent
        flexDirection='column'
        borderRadius={BorderRadius.CARD}
        bg={Color.LIGHT_GREY}
        alignItems='center'
        justifyContent='center'
        width='100%'
        pt={6}
        pb={8}
        px={4}
        gap={2}
      >
        <ModalCloseHeader onClose={onClose}/>
        <Flex flexDir='column' alignItems='center' textAlign='center' px={8} w='100%' gap={6}>
          <Heading color={Color.DARK_BLUE} size='lg'>Upload Additional Document</Heading>
          {getContent()}
        </Flex>
      </ModalContent>
    </Modal>
  )
}

// If a new file name is specified, return new name with existing extension,
// otherwise return existing name
function getUpdatedFileName (file: File, newFileName?: string): string | null {
  if (newFileName == null) return file.name

  const originalExtension = file.name.split('.')[1]
  const rename = newFileName.split('.')[0]
  if (originalExtension == null || rename == null) {
    return null
  }

  return `${rename}.${originalExtension}`
}
