import React, { type ReactNode, type ReactElement, useState } from 'react'
import { Flex, Heading, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import MenuIcon from '../../icons/MenuIcon'
import { ModalComponent } from '../../modal/ModalComponent'
import Button, { ButtonVariant } from '../../button/Button'
import { Color, IconSize } from '@/theme/theme'

interface PopoverEditDeleteMenuProps {
  editOnClick?: () => void
  editTitle?: string
  editComponent?: ReactElement

  deleteOnClick?: () => void
  deleteTitle?: string
  deleteModalTitle?: string
  deleteModalContent?: ReactNode
  deleteComponent?: ReactElement

  isDisabled?: boolean
  tooltipText?: false | string
  icon?: ReactElement
  useCustomDeleteModal?: boolean
  backgroundColor?: Color
}

/**
 * DEPRECATED!!!
 *
 * Use PopoverIconMenu instead
 *
 * DEPRECATED!!!
 * @deprecated
 */
export default function PopoverEditDeleteMenu ({
  editOnClick,
  editTitle,
  editComponent,
  deleteOnClick,
  deleteTitle,
  deleteModalTitle,
  deleteComponent,
  deleteModalContent,
  icon,
  tooltipText,
  isDisabled = false,
  useCustomDeleteModal = false,
  backgroundColor = Color.GREY
}: PopoverEditDeleteMenuProps
): ReactElement {
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false)

  function handleDelete (): void {
    setIsDeleteAccountModalOpen(false)
    if (deleteOnClick != null) {
      deleteOnClick()
    }
  }

  return (
    <>
      <Menu placement='bottom-end' >
        <MenuButton
          as={IconButton}
          bg={backgroundColor}
          aria-label='Options'
          icon={icon ?? <MenuIcon color={Color.DARK_GREY} size={IconSize.SMALL}/>}
          borderRadius= '100%'
          isDisabled={isDisabled}
          tooltiptext={tooltipText}
        />
        <MenuList p={2} >
          {/* Edit */}
          {(editOnClick != null || editComponent != null) &&
            <MenuItem onClick={editOnClick} >
              {editComponent ?? <Text>{editTitle ?? 'Edit'}</Text>}
            </MenuItem>}

          {/* Delete */}
          {(deleteOnClick != null || deleteComponent != null) &&
            <MenuItem onClick={useCustomDeleteModal ? deleteOnClick : () => { setIsDeleteAccountModalOpen(true) }} >
              {deleteComponent ??
              <Text color={Color.ERROR_RED}>{deleteTitle ?? 'Delete'}</Text>}
            </MenuItem>}
        </MenuList>
      </Menu>
      <ModalComponent
        isOpen={isDeleteAccountModalOpen}
        onClose={() => { setIsDeleteAccountModalOpen(false) }}
      >
        <Flex flexDir='column' w='100%' alignItems='center' textAlign='center' gap={4} my={2}>
          <Heading color={Color.DARK_BLUE} size='lg'>
            {deleteModalTitle ?? 'Are you sure you want to delete?'}
          </Heading>
          {deleteModalContent}
          <Button text='Delete' variant={ButtonVariant.DESTRUCTIVE} onClick={handleDelete}/>
          <Button
            text='Cancel'
            variant={ButtonVariant.WHITE}
            onClick={() => { setIsDeleteAccountModalOpen(false) }}
          />
        </Flex>
      </ModalComponent>
    </>
  )
}
