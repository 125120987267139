import React, { type ReactElement } from 'react'
import {
  Box
} from '@chakra-ui/react'

export enum SPACING {
  S1 = '10px',
  S2 = '20px',
  S3 = '30px',
  S4 = '40px',
  S5 = '50px',
  S6 = '60px',
  S7 = '70px',
  S8 = '80px',
}

interface SpacerComponentProps {
  spacing: SPACING
}

export const SpacerComponent = (
  { spacing }: SpacerComponentProps): ReactElement => {
  return <Box py={spacing}></Box>
}
