import React, { type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import { Graphic } from './Graphic'
import MenuIconHorizontal from '../icons/MenuIconHorizontal'
import { ALTIR_LOGO_ARROW } from '@/theme/graphics'
import { Color } from '@/theme/theme'

interface AltirPartnerGraphicProps {
  partnerLogoSrc: string
}

export default function AltirPartnerLinkGraphic ({ partnerLogoSrc }: AltirPartnerGraphicProps): ReactElement {
  return (
    <Center gap={2}>
      <Center borderRadius='100%' bg={Color.WHITE} p={4}>
        <Graphic src={ALTIR_LOGO_ARROW} h='40px'/>
      </Center>
      <MenuIconHorizontal color={Color.DARK_GREY}/>
      <Center borderRadius='100%' bg={Color.WHITE} p={4}>
        <Graphic src={partnerLogoSrc} h='40px'/>
      </Center>
    </Center>
  )
}
