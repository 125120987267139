export enum Event {
  BUTTON_CLICK = 'BUTTON_CLICK',
  // Plaid Linking
  PLAID_LINK_OPEN_CLICK = 'PLAID_LINK_OPEN_CLICK',
  PLAID_RELINK_OPEN_CLICK = 'PLAID_RELINK_OPEN_CLICK',
  PLAID_ACCOUNT_MANAGEMENT_CLICK = 'PLAID_ACCOUNT_MANAGEMENT_CLICK',

  // Teller Linking
  TELLER_LINK_OPEN_CLICK = 'TELLER_LINK_OPEN_CLICK',
  TELLER_RELINK_OPEN_CLICK = 'TELLER_RELINK_OPEN_CLICK',
  TELLER_ACCOUNT_MANAGEMENT_CLICK = 'TELLER_ACCOUNT_MANAGEMENT_CLICK',

  // Altir Pro
  ALTIR_UPGRADE_MODAL_OPEN_CLICK = 'ALTIR_UPGRADE_MODAL_OPEN_CLICK',
  ALTIR_UPGRADE_MODAL_STRIPE_REDIRECT_CLICK = 'ALTIR_UPGRADE_STRIPE_REDIRECT__CLICK',
  MANAGE_SUBSCRIPTION_CLICK = 'MANAGE_SUBSCRIPTION_CLICK',

  // Customer Support
  CONTACT_US_CLICK = 'CONTACT_US_CLICK',
  VISIT_HELP_CENTER_CLICK = 'VISIT_HELP_CENTER_CLICK',

  // File management
  FILE_UPLOAD_CLICK = 'FILE_UPLOAD_CLICK',
  FILE_UPLOAD_ADDITONAL_DOCUMENTS_CLICK = 'FILE_UPLOAD_ADDITONAL_DOCUMENTS_CLICK',
  FILE_CENTER_DOWNLOAD_CLICK = 'FILE_CENTER_DOWNLOAD_CLICK',
  DOWNLOAD_STATEMENT_CLICK = 'DOWNLOAD_STATEMENT_CLICK',

  // Statements
  VIEW_STATEMENT_CLICK = 'VIEW_STATEMENT_CLICK',

  // Treasure
  TREASURE_LINK_CLICK = 'TREASURE_LINK_CLICK',

  // Amplify Application
  START_AMPLIFY_APPLICATION_CLICK = 'START_AMPLIFY_APPLICATION_CLICK',
  SUBMIT_AMPLIFY_APPLICATION_CLICK = 'SUBMIT_AMPLIFY_APPLICATION_CLICK',
  CONFIRM_SUBMIT_AMPLIFY_APPLICATION_CLICK = 'CONFIRM_SUBMIT_AMPLIFY_APPLICATION_CLICK',
  CLOSE_AMPLIFY_APPLICATION_SUBMISSION_MODAL_CLICK = 'CLOSE_AMPLIFY_APPLICATION_SUBMISSION_MODAL_CLICK',
  ACCOUNT_USAGE_SUBMISSION_CLICK = 'ACCOUNT_USAGE_SUBMISSION_CLICK',
  ACCOUNT_USAGE_SUBMISSION_CANCEL_CLICK = 'ACCOUNT_USAGE_SUBMISSION_CANCEL_CLICK',
  BUSINESS_APPLICATION_SUBMISSION_CLICK = 'BUSINESS_APPLICATION_SUBMISSION_CLICK',
  BUSINESS_APPLICATION_CANCEL_CLICK = 'BUSINESS_APPLICATION_CANCEL_CLICK',
  ADD_PERSON_APPLICATION_CLICK = 'ADD_PERSON_APPLICATION_CLICK',
  PERSON_APPLICATION_SUBMISSION_CLICK = 'PERSON_APPLICATION_SUBMISSION_CLICK',
  PERSON_APPLICATION_DELETE_CLICK = 'PERSON_APPLICATION_DELETE_CLICK',
  PERSON_APPLICATION_CANCEL_CLICK = 'PERSON_APPLICATION_CANCEL_CLICK',
  APPLICATION_DISCLOSURES_ACCEPTANCE_CLICK = 'APPLICATION_DISCLOSURES_ACCEPTANCE_CLICK',

  // Amplify Account
  VIEW_AMPLIFY_ACCOUNT_DETAILS_CLICK = 'VIEW_AMPLIFY_ACCOUNT_DETAILS_CLICK ',
  OPEN_UPDATE_ACCOUNT_INFO_MODAL_CLICK = 'OPEN_UPDATE_ACCOUNT_INFO_MODAL',
  OPEN_FUND_YOUR_ACCOUNT_MODAL_CLICK = 'OPEN_FUND_YOUR_ACCOUNT_MODAL_CLICK',
  CLOSE_AMPLIFY_ACCOUNT_CLICK = 'CLOSE_AMPLIFY_ACCOUNT_CLICK',
  VIEW_ALL_AMPLIFY_TRANSACTION_CLICK = 'VIEW_ALL_AMPLIFY_TRANSACTIONS_CLICK',

  // Onboarding
  FRANCHISE_CREATION_FORM_SUBMISSION_CLICK = 'FRANCHISE_CREATION_FORM_SUBMISSION_CLICK',
  SIGN_UP_NEXT_STEPS_CLICK = 'SIGN_UP_NEXT_STEPS_CLICK',

  // Insights Page
  INSIGHTS_UPSELL_CLICK = 'INSIGHTS_UPSELL_CLICK',

  // Loans
  LINK_ACCOUNTING_SOFTWARE_CLICK = 'LINK_ACCOUNTING_SOFTWARE_CLICK',
  LOAN_PORTAL_ENTRY_CLICK = 'LOAN_PORTAL_ENTRY_CLICK',
  LOAN_OFFER_CARD_CLICK = 'LOAN_OFFER_CARD_CLICK',

  // Login / Sign Up
  LOGIN_CLICK = 'LOGIN_CLICK',
  SIGN_UP_CLICK = 'SIGN_UP_CLICK',
  USER_SIGN_UP_FORM_SUBMIT_CLICK = 'USER_SIGN_UP_FORM_SUBMIT_CLICK',

  // Transfer
  OPEN_TRANSFER_PAGE_CLICK = 'OPEN_TRANSFER_PAGE_CLICK',
  COUNTERPARTY_SELECTION_CLICK = 'COUNTERPARTY_SELECTION_CLICK',
  CONFIRM_TRANSFER_CLICK = 'CONFIRM_TRANSFER_CLICK',
  VIEW_ALL_TRANSFERS_CLICK = 'VIEW_ALL_TRANSFERS_CLICK',
  INITIATE_TRANSFER_CLICK = 'INITIATE_TRANSFER_CLICK',

  // Transfer Rules
  TRANSFER_PAGE_CREATE_RULE = 'TRANSFER_PAGE_CREATE_RULE',
  TRANSFER_RULE_PAGE_CREATE_RULE = 'TRANSFER_RULE_PAGE_CREATE_RULE',
  TREASURY_PAGE_CREATE_RULE = 'TREASURY_PAGE_CREATE_RULE',
  TRANFER_PAGE_TO_RULES_PAGE = 'TRANFER_PAGE_TO_RULES_PAGE'
}
