import React, { type ReactElement } from 'react'

import { useNavigate } from 'react-router-dom'
import { Event } from 'metrics/metrics'
import { useQuery } from '@apollo/client'
import { Color, IconSize } from '@/theme/theme'
import { ROUTES } from '@/api/routes'
import RecurringRuleIcon from '@/library/icons/RecurringRuleIcon'
import { logEvent } from '@/utils/metricUtils'
import MockDropDownComponent from '@/library/layout/drop_down/MockDropDownComponent'
import {
  type GetActiveTransferRules,
  type GetActiveTransferRulesVariables
} from '@/graphql/__generated__/GetActiveTransferRules'
import { GET_ACTIVE_TRANSFER_RULES } from '@/graphql/queries/transfer_rules/GetActiveTransferRules'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import AltirSkeleton from '@/library/loading/AltirSkeleton'

// TODO(ALT 1878): add number of transfers
export default function ViewAutomatedTransferRulesCard (): ReactElement {
  const navigate = useNavigate()
  const selectedFranchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const { data, loading, error } = useQuery<GetActiveTransferRules, GetActiveTransferRulesVariables>(
    GET_ACTIVE_TRANSFER_RULES,
    {
      variables: { franchiseGroupId: selectedFranchiseGroupId }
    }
  )

  const transferRuleCount = (data?.currentUser?.franchiseGroup?.transferRules ?? []).length

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <MockDropDownComponent
        title='Automated Transfer Rules'
        icon={<RecurringRuleIcon color={Color.DARK_BLUE} size={IconSize.SMALL}/>}
        onClick={() => { navigate(ROUTES.AUTOMATED_TRANSFERS); logEvent(Event.TRANFER_PAGE_TO_RULES_PAGE) }}
        padding={6}
        rightDescription={transferRuleCount > 0 ? `(${transferRuleCount})` : undefined}
      />
    </AltirSkeleton>
  )
}
