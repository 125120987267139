import { type ReactElement } from 'react'
import React from 'react'
import { useQuery } from '@apollo/client'
import { Center } from '@chakra-ui/react'
import PersonApplicationRowGroup from '../person/PersonApplicationRowGroup'
import { type AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import
AmplifyApplicationHeader
  from '@/components/pages/v2/sign_up/routes/amplify/routes/components/AmplifyApplicationHeader'
import { GET_AMPLIFY_ACCOUNT_APPLICATION } from '@/graphql/queries/amplify_application/GetAmplifyAccountApplication'
import {
  type GetAmplifyAccountApplication,
  type GetAmplifyAccountApplicationVariables
} from '@/graphql/__generated__/GetAmplifyAccountApplication'

export interface PersonApplicationComponentProps {
  amplifyApplicationId?: string
  franchiseGroupId: number
  backLink: string
  context: AmplifyApplicationContext
}

const OWNER_DESCRIPTION = `
  Each individual, if any, who owns, directly or indirectly, 25 percent or more of the equity interests of the legal entity; Or an individual with significant responsibility for managing the legal entity.
`

export default function OwnershipComponent (
  {
    amplifyApplicationId,
    franchiseGroupId,
    backLink,
    context
  }: PersonApplicationComponentProps
): ReactElement {
  const {
    data,
    loading,
    error,
    refetch
  } = useQuery<GetAmplifyAccountApplication, GetAmplifyAccountApplicationVariables>(
    GET_AMPLIFY_ACCOUNT_APPLICATION,
    {
      variables: { franchiseGroupId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  )

  const personApplications = data?.currentUser?.franchiseGroup?.amplifyAccountApplication?.personalApplications ?? []
  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center flexDir='column' gap={8} w='100%'>
        <AmplifyApplicationHeader
          backLink={backLink}
          businessName={data?.currentUser?.franchiseGroup?.name ?? null}
          header='About each Beneficial Owner and the Controlling Manager'
          description={OWNER_DESCRIPTION}
        />
        <PersonApplicationRowGroup
          amplifyApplicationId={amplifyApplicationId}
          franchiseGroupId={franchiseGroupId}
          personalApplications={personApplications}
          context={context}
          onStateUpdate={refetch}
        />
      </Center>
    </AltirSkeleton>
  )
}
