import { useLazyQuery, useQuery } from '@apollo/client'
import { Flex } from '@chakra-ui/react'
import React, { useState, type ReactElement } from 'react'
import { Event } from 'metrics/metrics'
import useInitiateBrowserDownload, { BlobDownloadFileType } from '../../../hooks/useInitiateBrowserDownload'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import Button, { ButtonVariant } from '@/library/button/Button'
import ErrorInline from '@/library/errors/ErrorInline'
import DownloadIcon from '@/library/icons/DownloadIcon'
import {
  GET_STATEMENT_DOWNLOAD_AVAILABLE,
  GET_STATEMENT_DOWNLOAD_LINK
} from '@/graphql/queries/statements/GetStatementDownloadLink'
import { type GetStatementDownloadLink } from '@/graphql/__generated__/GetStatementDownloadLink'
import {
  type GetStatementDownloadIsAvailableVariables,
  type GetStatementDownloadIsAvailable
} from '@/graphql/__generated__/GetStatementDownloadIsAvailable'
import { type ErrorWithContent } from '@/types/types'
import { ErrorCopy } from '@/utils/errorUtils'

interface DownloadStatementButtonProps {
  accountId: number
  isAmplifyAccount: boolean
  startDate: string
  endDate: string
}

export default function DownloadStatementButton ({
  accountId,
  isAmplifyAccount,
  startDate,
  endDate
}: DownloadStatementButtonProps
): ReactElement {
  const [error, setError] = useState<ErrorWithContent | undefined>()
  const {
    loading: getStatementLoading,
    data: getStatementData,
    error: preFlightError
  } = useQuery<GetStatementDownloadIsAvailable, GetStatementDownloadIsAvailableVariables>(
    GET_STATEMENT_DOWNLOAD_AVAILABLE,
    {
      variables: {
        accountId,
        startDate,
        endDate
      }
    }
  )
  const isStatementAvailable = (
    preFlightError == null && getStatementData?.statementDownload.isStatementAvailable === true
  )
  // As this is potentially a slow query, we want to load the query without triggering, therefore useLazyQuery
  const [getStatementDownloadLink] =
    useLazyQuery<GetStatementDownloadLink>(GET_STATEMENT_DOWNLOAD_LINK,
      {
        variables: {
          accountId,
          startDate,
          endDate
        },
        onError: (error) => {
          setError({
            customContent: {
              title: 'Unable to download statement',
              subtitle: ErrorCopy.TRY_AGAIN_LATER
            },
            error
          })
        }
      }
    )
  const statementMonth: string = getStatementData?.statementDownload.statementMonth ?? ''
  const { setDownloadUrl: triggerDownload, loading: downloadLoading } =
    useInitiateBrowserDownload({
      fileType: BlobDownloadFileType.PDF,
      filename: `Statement ${statementMonth}`,
      onError: (error) => {
        setError({
          customContent: {
            title: 'Unable to download statement',
            subtitle: ErrorCopy.TRY_AGAIN_LATER
          },
          error
        })
      }
    })

  async function handleClick (): Promise<void> {
    // Get the download link
    const { data } = await getStatementDownloadLink()
    const downloadLink = data?.statementDownload?.statementDownloadLink ?? undefined
    triggerDownload(downloadLink)
  }

  if (!isAmplifyAccount) {
    // TODO, add support for plaid accounts
    return <></>
  }
  return (
    <AltirSkeleton isLoading={getStatementLoading}>
      <Flex gap={4} flexDir='column'>
        <Button
          variant={ButtonVariant.WHITE_OUTLINE}
          isDisabled={!isStatementAvailable}
          text='Download Statement'
          beforeIcon={<DownloadIcon/>}
          onClick={async () => { await handleClick() }}
          toolTipText={!isStatementAvailable ? 'No statement available for the selected dates' : undefined}
          isLoading={downloadLoading}
          onClickEventType={Event.DOWNLOAD_STATEMENT_CLICK}
        />
        <ErrorInline error={error}/>
      </Flex>
    </AltirSkeleton>
  )
}
