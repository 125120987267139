import React, { useState, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { AmplifyAccountApplicationStatus } from '@/graphql/__generated__/globalTypes'
import { getAmplifyApplicationCTA } from '@/utils/amplifyApplicationUtils'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import AmplifyWaitlistModal from '@/library/modal/amplify/AmplifyWaitlistModal'

interface AmplifyAccountApplicationCTAProps {
  status?: AmplifyAccountApplicationStatus | null
  isOnAmplifyWaitlist: boolean
}

export default function AmplifyAccountApplicationCTA ({
  status,
  isOnAmplifyWaitlist
}: AmplifyAccountApplicationCTAProps): ReactElement {
  const [isWaitlistModalOpen, setIsWaitlistModalOpen] = useState(false)
  const ctaText = getAmplifyApplicationCTA(isOnAmplifyWaitlist, status ?? null)
  const buttonVariant = getButtonVariantForStatus(status ?? null)
  return (
    <Flex>
      <Button
        text={ctaText}
        variant={buttonVariant}
        size={ButtonSize.SMALL}
        onClick={() => { setIsWaitlistModalOpen(true) }}
        width='auto'
        isDisabled={isOnAmplifyWaitlist}
      />
      <AmplifyWaitlistModal isOpen={isWaitlistModalOpen} onClose={() => { setIsWaitlistModalOpen(false) }}/>
    </Flex>
  )
}

export function getButtonVariantForStatus (status: AmplifyAccountApplicationStatus | null): ButtonVariant {
  if (status == null) return ButtonVariant.LINK_TRANSPARENT
  switch (status) {
    case AmplifyAccountApplicationStatus.DOES_NOT_EXIST:
    case AmplifyAccountApplicationStatus.CREATED:
      return ButtonVariant.PRIMARY
    case AmplifyAccountApplicationStatus.SUBMITTED:
    case AmplifyAccountApplicationStatus.MANUAL_REVIEW:
    case AmplifyAccountApplicationStatus.PROCESSING:
    case AmplifyAccountApplicationStatus.ERROR:
    case AmplifyAccountApplicationStatus.DELETED:
    case AmplifyAccountApplicationStatus.REJECTED:
    case AmplifyAccountApplicationStatus.APPROVED:
      return ButtonVariant.LINK_TRANSPARENT
  }
}
