import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAltirStore } from './store/useAltirStore'
import { ROUTES } from '@/api/routes'

export default function useSafeGetBusinessFromURL (): number {
  const { id } = useParams()
  const navigate = useNavigate()
  const { selectedBusinessId, setSelectedBusinessId, availableBusinessIds } = useAltirStore(
    (state) => ({
      selectedBusinessId: state.selectedFranchiseGroupId,
      setSelectedBusinessId: state.setSelectedFranchiseGroupId,
      availableBusinessIds: state.selectedOrganizationState.availableFranchiseGroupIds
    }))
  useEffect(() => {
    const businessId = Number(id)
    if (!isNaN(businessId) && availableBusinessIds.includes(businessId)) {
      setSelectedBusinessId(businessId)
    } else {
      navigate(ROUTES.HOME)
    }
  }, [setSelectedBusinessId, id, selectedBusinessId, navigate, availableBusinessIds])

  return selectedBusinessId
}
