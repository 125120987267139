import React from 'react'
import { type ReactElement } from 'react'
import SettingsNotificationIcon from '@/library/icons/SettingsNotificationIcon'
import SettingsIcon from '@/library/icons/SettingsIcon'
import { type IconSize } from '@/theme/theme'

interface NavItemProps {
  hasNotification: boolean
  size?: IconSize
  color: string
}

export default function SettingsCogComponent (
  {
    hasNotification,
    size,
    color
  }: NavItemProps): ReactElement {
  return (
    hasNotification ? <SettingsNotificationIcon size={size} color={color}/> : <SettingsIcon size={size} color={color}/>
  )
}
