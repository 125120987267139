import React, { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import '../../../assets/css/App.css'
import
AccountStatementComponent
  from '../treasury/components/treasury/AccountStatementComponent'
import Page from '@/library/page/Page'

export default function AccountStatementPage (): ReactElement<string, string> {
  const { id } = useParams()

  return (
    <Page>
      <AccountStatementComponent
        accountId={Number(id)}
      />
    </Page>
  )
}
