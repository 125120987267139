import React, { type ReactElement } from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import { useAuth0 } from '@auth0/auth0-react'
import { useApolloClient } from '@apollo/client'
import { ModalComponent } from '../ModalComponent'
import { Color } from '@/theme/theme'
import Button, { ButtonVariant } from '@/library/button/Button'

interface LogOutModalProps {
  isOpen: boolean
  onClose: () => void
}

export default function LogOutModal ({ isOpen, onClose }: LogOutModalProps): ReactElement {
  const { logout } = useAuth0()
  const client = useApolloClient()

  function onLogout (): void {
    logout({ logoutParams: { returnTo: window.location.origin } })
    void client.cache.reset()
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} size='sm'>
      <Flex flexDir='column' alignItems='center' textAlign='center' w='100%' gap={8}>
        <Heading color={Color.DARK_BLUE} size='lg'>Are you sure you <br/>want to log out?</Heading>
        <Flex flexDir='column' w='100%' gap={4}>
          <Button
            text='Log Out'
            variant={ButtonVariant.DESTRUCTIVE}
            onClick={onLogout}
          />
          <Button text='Cancel' variant={ButtonVariant.WHITE} onClick={onClose}/>
        </Flex>
      </Flex>
    </ModalComponent>
  )
}
