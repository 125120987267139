import React, { type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import AmplifyApplicationHeader from '../components/AmplifyApplicationHeader'
import OnboardingPage from '@/library/page/OnboardingPage'
import { ROUTES } from '@/api/routes'
import { useOnboardingAmplifyAppNavigation } from '@/hooks/onboarding/useOnboardingAmplifyAppNavigateion'
import AmplifyApplicationContainerComponent, { AmplifyApplicationContext } from
  '@/components/pages/amplify_account_application/routes/apply/AmplifyApplicationContainerComponent'
import { GET_ONBOARDING_BUSINESS_BY_ID } from '@/graphql/queries/onboarding/GetOnboardingBusinessById'
import {
  type GetOnboardingBusinessById,
  type GetOnboardingBusinessByIdVariables
} from '@/graphql/__generated__/GetOnboardingBusinessById'
import { ContainerWidth } from '@/theme/theme'

const AMPLIFY_APP_DESCRIPTION = `
    Your information will be securely encrypted and sent to our banking partner. We will save your progress if you skip this step.
`

export default function OnboardingAmplifyApplyPage (): ReactElement {
  const { BUSINESS_ID } = useParams()
  const {
    data,
    loading,
    error
  } = useQuery<GetOnboardingBusinessById, GetOnboardingBusinessByIdVariables>(
    GET_ONBOARDING_BUSINESS_BY_ID,
    {
      variables: { franchiseGroupId: Number(BUSINESS_ID) },
      fetchPolicy: 'no-cache'
    }
  )
  const { skipCallToAction } = useOnboardingAmplifyAppNavigation(String(BUSINESS_ID))

  return (
    <OnboardingPage
      maxWidth={ContainerWidth.EXTRA_LARGE}
      callToAction={skipCallToAction}
      isLoading={loading}
      error={error}
      amplifyApplicationContext={{ franchiseGroupId: Number(BUSINESS_ID) }}
    >
      <AmplifyApplicationHeader
        backLink={ROUTES.V2_SIGN_UP_AMPLIFY_OVERVIEW}
        businessName={data?.franchiseGroup?.name ?? null}
        header='Amplify Account Application'
        description={AMPLIFY_APP_DESCRIPTION}
      />
      <Center flexDir='column' w='auto' gap={2}>
        <AmplifyApplicationContainerComponent
          franchiseGroupId={Number(BUSINESS_ID)}
          context={AmplifyApplicationContext.ONBOARDING}
        />
      </Center>
    </OnboardingPage>
  )
}
