import { motion } from 'framer-motion'
import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { BorderRadius, Color, IconSize, TransitionTime } from '@/theme/theme'
import NavToggleIcon from '@/library/icons/NavToggleIcon'
import { IconFacing } from '@/types/types'

interface NavToggleComponentProps {
  isExpanded: boolean
  onToggle: () => void
  size?: IconSize
  color?: Color
}

const ROTATION_VARIANT = {
  EXPANDED: { rotate: IconFacing.LEFT },
  COLLAPSED: { rotate: IconFacing.RIGHT }
}

export default function NavToggleComponent ({
  isExpanded,
  onToggle,
  color = Color.DARK_GREY,
  size = IconSize.SMALL
}: NavToggleComponentProps
): ReactElement {
  return (
    <Flex
      borderRadius={BorderRadius.BUTTON_HOVER}
      p={1}
      _hover={{ bg: Color.GREY, cursor: 'pointer' }}
      onClick={onToggle}
    >
      <motion.div
        animate={isExpanded ? ROTATION_VARIANT.EXPANDED : ROTATION_VARIANT.COLLAPSED}
        transition={{ duration: TransitionTime.NAV_TRANSITION }}
        initial={ROTATION_VARIANT.EXPANDED}
      >
        <NavToggleIcon size={size} color={color}/>
      </motion.div>
    </Flex>
  )
}
