import React, { type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import Button from '@/library/button/Button'
import { Graphic } from '@/library/utility/Graphic'
import { Color } from '@/theme/theme'
import { CHAIN_LINK } from '@/theme/graphics'

interface AltirConnectInfoProps {
  onClose: () => void
}

export default function AltirConnectInfo ({ onClose }: AltirConnectInfoProps): ReactElement {
  return (
    <Flex flexDir='column' alignItems='center' justifyContent='center' w='100%' gap={8} textAlign='center'>
      <Flex borderRadius='100%' bg={Color.WHITE} px={4} py={3}>
        <Graphic src={CHAIN_LINK} h='40px'/>
      </Flex>
      <Flex flexDir='column' gap={2}>
        <Heading>Stay Tuned!</Heading>
        <Text>
          An Altir representative will email you a link with more info. Follow the instructions in the email.
        </Text>
      </Flex>
      <Button text='Ok, Thanks' onClick={onClose}/>
    </Flex>
  )
}
