import React, { type ReactElement } from 'react'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Text } from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import { useLocation, useNavigate } from 'react-router-dom'
import { type MenuLinkObject } from './VerticalNavMenuItem'
import { BorderRadius, Color } from '@/theme/theme'

interface DropDownMenuItemProps {
  color: string
  isFocused: boolean
  isCompact: boolean
  children: ReactElement
  options: MenuLinkObject[]
}

export default function DropDownMenuItem ({
  color,
  isFocused,
  isCompact,
  children,
  options
}: DropDownMenuItemProps): ReactElement {
  const navigate = useNavigate()
  const location = useLocation()
  const parentPageIndex = isFocused ? 0 : undefined
  const focusedSubPageIndex = getFocusedSubPageIndex(options, location.pathname)

  return (
    <Accordion allowMultiple index={parentPageIndex ?? focusedSubPageIndex} w='100%'>
      <AccordionItem>
        <Flex
          alignItems='center'
          justifyContent='start'
          pl={4}
          pr={1}
          py={1}
          color={color}
          _hover={{ cursor: 'pointer', backgroundColor: Color.LIGHT_GREY }}
          backgroundColor={isFocused ? Color.LIGHT_GREY : Color.WHITE}
          borderRadius={BorderRadius.NAV_ITEM}
        >
          <Flex justifyContent='space-between' width='100%'>
            {children}
            <AccordionButton _hover={{ bg: Color.GREY }} width='auto'>
              <AccordionIcon />
            </AccordionButton>
          </Flex>
        </Flex>
        <AccordionPanel>
          {options.map(option => {
            const isSubPageFocused = location.pathname === option.link
            return (
              <Flex
                key={nanoid()}
                onClick={() => { navigate(option.link) }}
                borderRadius={BorderRadius.NAV_ITEM}
                _hover={{ bg: Color.LIGHT_GREY, cursor: 'pointer' }}
                bg= {isSubPageFocused ? Color.LIGHT_GREY : 'none'}
                py={2}
                pl={12}
                pr={5}
              >
                <Text
                  color={isSubPageFocused ? Color.DARK_BLUE : Color.DARK_GREY}
                  fontSize='sm'
                >
                  {option.text}
                </Text>
              </Flex>
            )
          })}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

function getFocusedSubPageIndex (options: MenuLinkObject[], pathName: string): number | undefined {
  const index = options.findIndex(option => option.link === pathName)

  return (index !== -1) ? 0 : undefined
}
