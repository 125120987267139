import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { scrollToTop } from '@/utils/scrollUtils'

interface ScrollToTopProps {
  behavior?: 'smooth'
}

export function useScrollToTop ({ behavior }: ScrollToTopProps): void {
  const location = useLocation()
  useEffect(() => {
    scrollToTop({ behavior })
  }, [location, behavior])
}
