import { gql } from '@apollo/client'

export const TRACKED_JOB_FRAGMENT = gql`
    fragment TrackedJobFragment on TrackedJob {
        id 
        metadata
        status
        fileUrl
        trackedJobType
    }
`
