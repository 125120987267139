import React, { type ReactElement } from 'react'
import { DerivedTransactionCategory } from '@/graphql/__generated__/globalTypes'
import PopoverMenuHierarchicalMultiSelector from '@/library/popoverMenu/PopoverMenuHierarchicalMultiSelector'
import { getCategoryLabelFormatted } from '@/utils/stringUtils'

export const CATEGORY_SELECTOR_GROUPS = new Map([
  ['Amplify Account Transfers',
    [
      DerivedTransactionCategory.AUTOMATED_INTERNAL_TRANSFER,
      DerivedTransactionCategory.EXTERNALLY_INITIATED_TRANSFER,
      DerivedTransactionCategory.INTERNAL_TRANSFER
    ]]
])

interface TransactionsCategorySelectorComponentProps {
  queryValue?: DerivedTransactionCategory[]
  onSubmit: (categoryLabels?: DerivedTransactionCategory[]) => void
}

export default function TransactionsCategorySelectorComponent (
  { queryValue, onSubmit }: TransactionsCategorySelectorComponentProps
): ReactElement {
  const derivedCategoryGroups = Array.from(CATEGORY_SELECTOR_GROUPS.entries())
  const menuSelectionGroups = derivedCategoryGroups.map(
    ([categoryGroup, categories]: [string, DerivedTransactionCategory[]]) => ({
      menuSelectionGroupHeading: categoryGroup,
      menuSelectionItems: categories.map(category => ({
        id: category,
        textArray: [getCategoryLabelFormatted(category)]
      }))
    }))

  function handleSubmit (selectedItems: string[]): void {
    if (selectedItems.length === 0) {
      onSubmit(undefined)
      return
    }
    onSubmit(selectedItems as DerivedTransactionCategory[])
  }
  return (
    <PopoverMenuHierarchicalMultiSelector
      popoverMenuTitle='Category'
      onSubmit={handleSubmit}
      menuSelectionGroups={menuSelectionGroups}
      isCheckBox={true}
      initialState={queryValue?.map(accountId => String(accountId)) ?? []}
      allowInteriorScroll={true}
      shouldMatchWidth
    />
  )
}
