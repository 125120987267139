import React, { type ReactElement } from 'react'
import FormNumberInput, { type NumberInputSizeVariant } from './FormNumberInput'
import { formatInputAsCurrency, parseCurrency, parsePositiveCurrency } from './utils'
import { type BaseFormInputProps } from '../types'

type FormDollarInputProps = {
  value: string
  onChange: (value: string) => void
  allowNegative?: boolean
  variant?: NumberInputSizeVariant
} & BaseFormInputProps

export default function FormDollarInput ({
  allowNegative = false,
  ...props
}: FormDollarInputProps): ReactElement {
  return (
    <FormNumberInput
      autoFormatRule={formatInputAsCurrency}
      parseFormattedValue={allowNegative ? parseCurrency : parsePositiveCurrency}
      {...props}
    />
  )
}
