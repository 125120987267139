import React, { type ReactElement } from 'react'
import { Divider, Grid, GridItem, Text } from '@chakra-ui/react'
import { Color, BorderRadius } from '@/theme/theme'
import {
  type GetOnboardingBusinessData_currentUser_selectedOrganization_franchiseGroups as Business
} from '@/graphql/__generated__/GetOnboardingBusinessData'

export default function BusinessOverviewComponent (
  business: Business
): ReactElement {
  return (
    <Grid
      templateColumns='repeat(2, minmax(0, 1fr))'
      w='100%'
      columnGap={4}
      rowGap={2}
      alignItems='center'
      overflow='hidden'
      bg={Color.LIGHT_GREY}

      p={6}
      borderRadius={BorderRadius.CARD}
    >
      <GridItem justifySelf='start'>
        <Text>Name of Business</Text>
      </GridItem>
      <GridItem justifySelf='end'>
        <Text color={Color.DARK_BLUE}>{business.name}</Text>
      </GridItem>
      <GridItem colStart={1} colEnd={3}>
        <Divider my={1} color={Color.DARK_GREY}/>
      </GridItem>
      <GridItem justifySelf='start'>
        <Text>Number of Locations</Text>
      </GridItem>
      <GridItem justifySelf='end'>
        <Text color={Color.DARK_BLUE}>{business.numberOfFranchiseLocations}</Text>
      </GridItem>
      <GridItem colStart={1} colEnd={3}>
        <Divider my={1} color={Color.DARK_GREY}/>
      </GridItem>
      <GridItem justifySelf='start'>
        <Text>Franchise Brand</Text>
      </GridItem>
      <GridItem justifySelf='end'>
        <Text color={Color.DARK_BLUE}>{business.brand.name}</Text>
      </GridItem>
    </Grid>
  )
}
