import { useSkipAmplifyApplicationCallToAction } from './useSkipOnboardingAmplifyAppCallToAction'
import { RouteParam, ROUTES } from '@/api/routes'
import { type OnboardingCallToAction } from '@/library/nav/onboarding/OnboardingStatusBar'
import { routeWithParams } from '@/utils/routerUtils'

interface AmplifyNavigation {
  skipCallToAction: OnboardingCallToAction
  backLink: string
}

export function useOnboardingAmplifyAppNavigation (businessId: string): AmplifyNavigation {
  const skipCallToAction = useSkipAmplifyApplicationCallToAction()

  const backLink = routeWithParams(
    ROUTES.V2_SIGN_UP_AMPLIFY_APP,
    [{ param: RouteParam.BUSINESS_ID, value: businessId }]
  )

  return {
    skipCallToAction,
    backLink
  }
}
