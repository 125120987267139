import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import StackedInfoAttribute from '../../../library/utility/StackedInfoAttribute'
import { ROUTES } from '@/api/routes'
import { type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule }
  from '@/graphql/__generated__/GetTransferRules'
import { getFormattedBalanceRule } from '@/utils/transferRuleUtils'
import { DerivedTransactionCategory, TransferRuleType }
  from '@/graphql/__generated__/globalTypes'

export interface BalanceRuleDropdownComponentProps {
  transferRule: TransferRule
}

export default function BalanceRuleDropdownComponent (
  { transferRule }: BalanceRuleDropdownComponentProps
): ReactElement {
  const navigate = useNavigate()

  const formattedRule = getFormattedBalanceRule(transferRule)
  return (
    <Flex direction='column' gap={5}>
      {transferRule.transferRuleType === TransferRuleType.TARGET_BALANCE &&
      <>
        <StackedInfoAttribute header='When' subtext={formattedRule.from}/>
        <StackedInfoAttribute header='Exceeds' subtext={formattedRule.balance}/>
        <StackedInfoAttribute header='Excess funds will be transferred into' subtext={formattedRule.to}/>
      </>
      }
      {transferRule.transferRuleType === TransferRuleType.MIN_TARGET_BALANCE &&
      <>
        <StackedInfoAttribute header='When' subtext={formattedRule.to}/>
        <StackedInfoAttribute header='Falls below' subtext={formattedRule.balance}/>
        <StackedInfoAttribute header='Pull funds from' subtext={formattedRule.from}/>
      </>
      }
      {transferRule.mostRecentTransferEST != null &&
      <StackedInfoAttribute
        header='Most Recent Transfer'
        subtext={formattedRule.mostRecentTransfer}
        linkOptions={{
          linkText: 'View All',
          onLinkClick: () => {
            /* TODO Add additional filter on bank account id */
            navigate(ROUTES.TRANSACTIONS,
              { state: { categoryLabels: [DerivedTransactionCategory.AUTOMATED_INTERNAL_TRANSFER] } })
          }
        }}
      />}
    </Flex>
  )
}
