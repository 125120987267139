import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { useComponentSize } from 'react-use-size'
import { Color } from '@/theme/theme'

interface ResponseD3GraphProps {
  children: ReactElement
  minHeight?: number
}

export default function ResponsiveD3Graph ({ children, minHeight }: ResponseD3GraphProps): ReactElement {
  const { ref, width } = useComponentSize()
  return (
    <Flex ref={ref} bg={Color.WHITE} minHeight={minHeight} >
      <Flex w={width} >
        {React.cloneElement(children, { parentWidth: width, parentHeight: width * 0.3 })}
      </Flex>
    </Flex>
  )
}
