import React, { useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import AccountUpgradeIntro from './components/intro/AccountUpgradeIntro'
import AccountUpgradeAccountsOverview from './components/AccountUpgradeAccountsOverview'
import AccountUpgradeAttestation from './components/AccountUpgradeAttestation'
import AccountUpgradeSuccessComponent from './components/success/AccountUpgradeSuccessComponent'
import OnboardingPage from '@/library/page/OnboardingPage'
import { ROUTES } from '@/api/routes'
import {
  type GetAccountUpgradePageData,
  type GetAccountUpgradePageDataVariables
} from '@/graphql/__generated__/GetAccountUpgradePageData'
import { GET_ACCOUNT_UPGRADE_PAGE_DATA } from '@/graphql/queries/account_upgrade/GetAccountUpgradePageData'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { ContainerWidth } from '@/theme/theme'
import { InstitutionConnectionProvider } from '@/graphql/__generated__/globalTypes'
import { hasRequestedMigration } from '@/utils/bankMigrationUtils'

export default function AccountUpgradePage (): ReactElement {
  const navigate = useNavigate()
  const organizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)

  const {
    data,
    loading,
    error,
    refetch
  } = useQuery<GetAccountUpgradePageData, GetAccountUpgradePageDataVariables>(
    GET_ACCOUNT_UPGRADE_PAGE_DATA,
    {
      variables: {
        organizationId,
        financialAccountFilter: { connectionProvider: InstitutionConnectionProvider.TREASURY_PRIME }
      }
    }
  )

  const skipCallToAction = {
    callToActionText: 'Finish this later',
    callToActionOnClick: () => { navigate(ROUTES.HOME) }
  }

  function onPageForward (): void {
    setCurrentPageIndex(currentPageIndex + 1)
  }

  function onPageBack (): void {
    setCurrentPageIndex(currentPageIndex - 1)
  }

  const amplifyAccounts = data?.currentUser?.selectedOrganization?.financialAccounts ?? []

  const hasRequestedUpgrade = hasRequestedMigration(
    data?.currentUser?.selectedOrganization?.bankMigrationStatus ?? null
  )

  return (
    <OnboardingPage
      maxWidth={currentPageIndex === 1 ? ContainerWidth.EXTRA_LARGE : ContainerWidth.LARGE}
      shouldHideProgressBar={true}
      callToAction={skipCallToAction}
      isLoading={loading}
      error={error}
    >
      {
        hasRequestedUpgrade
          ? <AccountUpgradeSuccessComponent/>
          : currentPageIndex === 2
            ? <AccountUpgradeAttestation onBack={onPageBack} onSuccess={refetch}/>
            : currentPageIndex === 1
              ? <AccountUpgradeAccountsOverview
                  onContinue={onPageForward}
                  onBack={onPageBack}
                  amplifyAccounts={amplifyAccounts}
                />
              : <AccountUpgradeIntro onContinue={onPageForward}/>
    }
    </OnboardingPage>
  )
}
