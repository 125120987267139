import { Flex, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { Color } from '@/theme/theme'
import AltirProUpgradeComponent from '@/library/pro/AltirProUpgradeComponent'

export default function EmptySubscriptionComponent (): ReactElement {
  return (
    <Flex w='full' justifyContent='space-between' alignItems='center'>
      <Flex flexDir='column'>
        <Text color={Color.MEDIUM_GREY}>Your Plan: </Text>
        <Text color={Color.DARK_BLUE}>Altir Basic</Text>
      </Flex>
      <AltirProUpgradeComponent ctaText='Upgrade to Altir Pro!'/>
    </Flex>
  )
}
