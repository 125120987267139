import React, { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import AmplifyFormGroupComponent from '../AmplifyFormGroupComponent'
import { AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import TreasuryIcon from '@/library/icons/TreasuryIcon'
import { AmplifyAccountApplicationStatus } from '@/graphql/__generated__/globalTypes'
import { Color } from '@/theme/theme'
import { RouteParam, ROUTES } from '@/api/routes'
import { routeWithParams } from '@/utils/routerUtils'

export interface OwnershipAttestationRowProps {
  franchiseGroupId: number
  applicationId?: string
  isDisabled: boolean
  isComplete: boolean
  context: AmplifyApplicationContext
}

export default function OwnershipAttestationRow (
  {
    franchiseGroupId,
    applicationId,
    isDisabled,
    isComplete,
    context
  }: OwnershipAttestationRowProps
): ReactElement {
  const navigate = useNavigate()
  const status = isComplete ? AmplifyAccountApplicationStatus.SUBMITTED : AmplifyAccountApplicationStatus.CREATED

  const link = routeWithParams(
    getRouteForContext(context),
    [
      {
        param: RouteParam.BUSINESS_ID,
        value: String(franchiseGroupId)
      },
      {
        param: RouteParam.AMPLIFY_APPLICATION_ID,
        value: String(applicationId)
      }
    ]
  )

  return (
    <AmplifyFormGroupComponent
      title='Ownership Attestation'
      status={status}
      icon={<TreasuryIcon color={isDisabled ? Color.DARK_GREY : Color.DARK_BLUE}/>}
      handleClick={() => { navigate(link) }}
      isDisabled={isDisabled}
    />
  )
}

function getRouteForContext (context: AmplifyApplicationContext): string {
  switch (context) {
    case AmplifyApplicationContext.DEFAULT:
      return ROUTES.AMPLIFY_APP_PERSON_CONFIRMATION
    case AmplifyApplicationContext.ONBOARDING:
      return ROUTES.V2_SIGN_UP_AMPLIFY_APP_PERSON_CONFIRMATION
  }
}
