import React, { type ReactElement, type ReactNode } from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'
import { apolloConfig } from '../../config/config'
import { getApolloEnvironment } from '@/utils/envUtils'

const { devTools } = getApolloEnvironment()
export default function Provider ({
  children
}: {
  children: ReactNode
}): ReactElement {
  const { getAccessTokenSilently } = useAuth0()
  const httpLink = new HttpLink({
    uri: apolloConfig.serverUri
  })
  const authLink = setContext(async (_, { headers }) => {
    return await getAccessTokenSilently()
      .then((accessToken) => {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${accessToken}`
          }
        }
      })
      .catch((error) => {
        console.error(error)
        return {
          headers
        }
      })
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    connectToDevTools: devTools,
    cache: new InMemoryCache()
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
