import { gql } from '@apollo/client'

export const INITIATE_LENDING_DOCUMENT_UPLOAD = gql`
    mutation InitiateLendingDocumentUpload(
        $fileName: String!, 
        $franchiseGroupId: Int!, 
        $organizationId: String, 
        $type: FileUploadType!
    ) {
        initiateLendingDocumentUpload(
            fileName: $fileName, 
            franchiseGroupId: $franchiseGroupId, 
            organizationId: $organizationId, 
            type: $type
        ) {
            id
            signedUploadUrl
        }
    }
`

export const COMPLETE_LENDING_DOCUMENT_UPLOAD = gql`
    mutation CompleteLendingDocumentUpload($id: String!, $status: FileUploadStatus!) {
        completeLendingDocumentUpload(id: $id, status: $status) {
            id
            signedUploadUrl
        }
    }
`
