import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import PaginationIcon from './PaginationIcon'
import CarrotComponent from '../utility/toggle/CarrotComponent'
import { Color, FontWeight } from '@/theme/theme'
import { IconFacing } from '@/types/types'

interface PaginationSelectorProps {
  totalResults?: number
  offset: number
  limit: number
  onUpdatePaginationOffset: (increase: boolean) => void
}

export default function PaginationSelector ({
  totalResults = 0,
  offset,
  limit,
  onUpdatePaginationOffset
}: PaginationSelectorProps): ReactElement {
  const canIncreaseIncreasePageSelection = offset + limit < totalResults
  const canDecreasePageSelection = offset > 0
  return (
    <Flex gap={6} alignItems='center'>
      <Flex gap={2}>
        <Text color={Color.DARK_BLUE} fontWeight={FontWeight.MEDIUM}>
          {/* Offset + 1 since when offset is 0 we want to display 1-100 etc */}
          {offset + 1}-{Math.min(offset + limit, totalResults)}
        </Text>
        <Text color={Color.DARK_GREY} fontWeight={FontWeight.MEDIUM}>of</Text>
        <Text color={Color.DARK_BLUE} fontWeight={FontWeight.MEDIUM}>{totalResults}</Text>
        <Text color={Color.DARK_GREY} fontWeight={FontWeight.MEDIUM}>Results</Text>
      </Flex>
      <Flex gap={4}>
        <PaginationIcon
          isEnabled={canDecreasePageSelection}
          onClick={() => { onUpdatePaginationOffset(false) }}
          icon={<CarrotComponent facing={IconFacing.LEFT}/>}
        />
        <PaginationIcon
          isEnabled={canIncreaseIncreasePageSelection}
          onClick={() => { onUpdatePaginationOffset(true) }}
          icon={<CarrotComponent facing={IconFacing.RIGHT}/>}
        />
      </Flex>
    </Flex>
  )
}
