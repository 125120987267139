import { Center, Heading, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { useMutation } from '@apollo/client'
import { ModalComponent } from '../ModalComponent'
import { Graphic } from '@/library/utility/Graphic'
import { SUCCESS_COMPASS_GRAPHIC } from '@/theme/graphics'
import Button, { ButtonVariant } from '@/library/button/Button'
import {
  type RequestAccessToAmplifyWaitlist,
  type RequestAccessToAmplifyWaitlistVariables
} from '@/graphql/__generated__/RequestAccessToAmplifyWaitlist'
import { REQUEST_ACCESS_TO_AMPLIFY_WAITLIST } from '@/graphql/mutations/RequestAccessToAmplifyWaitlist'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import ErrorInline from '@/library/errors/ErrorInline'
import { FormInput } from '@/library/form/text/FormInput'

interface AmplifyWaitlistModalProps {
  isOpen: boolean
  onClose: () => void

}

export default function AmplifyWaitlistModal ({ isOpen, onClose }: AmplifyWaitlistModalProps): ReactElement {
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const [
    requestAccessToAmplifyWaitlist,
    { loading, error, client }
  ] = useMutation <RequestAccessToAmplifyWaitlist, RequestAccessToAmplifyWaitlistVariables>(
    REQUEST_ACCESS_TO_AMPLIFY_WAITLIST,
    {
      variables: { organizationId: selectedOrganizationId },
      onCompleted: () => {
        void client.cache.reset()
        onClose()
      }
    }
  )

  function handleWaitlistClick (): void {
    void requestAccessToAmplifyWaitlist()
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
    >
      <Center w='100%' flexDir='column' gap={8}>
        <Graphic src={SUCCESS_COMPASS_GRAPHIC} h='100px'/>
        <Center w='100%' flexDir='column' gap={1}>
          <Heading>
            Join the Waitlist
          </Heading>
          <Text textAlign='center'>
            We have reached our current capacity for Amplify Accounts.
            We will be releasing new accounts soon.
            Join our waitlist, and we will email you an update as soon as you can apply.
          </Text>
        </Center>
        <FormInput
          label='Email'
          fieldName='email'
          placeholder='john@doe.com'
        />
        <ErrorInline error={error}/>
        <Center w='100%' flexDir='column' gap={4}>
          <Button text='Join Waitlist' onClick={handleWaitlistClick} isLoading={loading}/>
          <Button text='Cancel' onClick={onClose} variant={ButtonVariant.WHITE}/>
        </Center>
      </Center>
    </ModalComponent>
  )
}
