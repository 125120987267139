import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import MailIcon from '@/library/icons/MailIcon'
import { BorderRadius, Color, IconSize } from '@/theme/theme'
import { TransferStatus } from '@/graphql/__generated__/globalTypes'

export default function TransferActionTag ({ status }: { status: TransferStatus | null }): ReactElement {
  if (status !== TransferStatus.ERROR) return <></>
  return (
    <Flex
      color={Color.DARK_BLUE}
      alignItems='center'
      justifyContent='center'
      borderRadius={BorderRadius.INPUT}
      display='inline-flex'
      backgroundColor={Color.WHITE}
      px={5}
      py={2}
      _hover={{ cursor: 'pointer', backgroundColor: Color.GREY }}
    >
      <Flex marginRight={2}>
        <MailIcon size={IconSize.SMALL} color={Color.DARK_BLUE}/>
      </Flex>
      <Text color={Color.DARK_BLUE}>
        Contact Us
      </Text>
    </Flex>
  )
}
