import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { Color, IconSize } from '@/theme/theme'
import Button, { ButtonSize } from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import {
  type GetOnboardingBusinessAmplifyApplicationData_currentUser_selectedOrganization_franchiseGroups as Business
} from '@/graphql/__generated__/GetOnboardingBusinessAmplifyApplicationData'
import { isAmplifyApplicationInProgress, isAmplifyApplicationSubmitted } from '@/utils/amplifyApplicationUtils'
import CircleCheckIcon from '@/library/icons/CircleCheckIcon'
import CarrotIcon from '@/library/icons/CarrotIcon'

interface AmplifyBusinessDropDownCTAProps {
  business: Business
  onClick: () => void
}

export default function AmplifyBusinessDropDownCTA ({
  business,
  onClick
}: AmplifyBusinessDropDownCTAProps): ReactElement {
  const status = business.amplifyAccountApplication?.status
  if (isAmplifyApplicationSubmitted(status)) {
    return (
      <Flex alignItems='center' gap={2}>
        <CircleCheckIcon color={Color.SUCCESS_GREEN} size={IconSize.SMALL}/>
        <Text color={Color.SUCCESS_GREEN}>Submitted</Text>
      </Flex>
    )
  }

  if (isAmplifyApplicationInProgress(status)) {
    return (
      <Flex alignItems='center' gap={4}>
        <Text>In Progress</Text>
        <CarrotIcon color={Color.DARK_GREY} size={IconSize.SMALL}/>
      </Flex>
    )
  }

  return (
    <Button
      text='Start Application'
      width='auto'
      size={ButtonSize.SMALL}
      afterIcon={<RightArrowIcon size={IconSize.SMALL}/>}
      onClick={onClick}
    />
  )
}
