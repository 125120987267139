import React, { useState, type ReactElement } from 'react'
import { Center, Flex } from '@chakra-ui/react'
import NoTransactionsFoundComponent from '../../transactions/components/NoTransactionsFoundComponent'
import TransactionsSortByComponent from '../../transactions/components/TransactionsSortByComponent'
import { useFetchAccountTransactions } from '@/hooks/data/useFetchAccountTransactions'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { type TransactionSortInput, TransferType, TransactionSortType } from '@/graphql/__generated__/globalTypes'
import TransactionsRowListComponent from '@/library/transactions/TransactionsRowListComponent'
import SearchBar from '@/library/form/text/SearchBar'

interface CheckTransactionsComponentProps {
  amplifyAccountId: number
}

export default function CheckTransactionsComponent ({
  amplifyAccountId
}: CheckTransactionsComponentProps): ReactElement {
  const [sortBy, setSortBy] = useState <TransactionSortInput>({ ascending: false, type: TransactionSortType.Date })
  const [rawSearchQuery, setRawSearchQuery] = useState<string>()
  const [searchQuery, setSearchQuery] = useState<string>()
  const {
    data: transactions,
    loading,
    error
  } = useFetchAccountTransactions(
    amplifyAccountId,
    { type: TransferType.CHECK, sortBy, keyword: searchQuery, shouldIncludePending: true }
  )

  return (
    <Center flexDir='column' w='100%' gap={6}>
      <Center w='100%' gap={4}>
        <Flex flex={3}>
          <SearchBar
            queryValue={rawSearchQuery}
            onChange={value => { setRawSearchQuery(value) }}
            onSubmit={value => { setSearchQuery(value) }}
          />
        </Flex>
        <Flex flex={1}>
          <TransactionsSortByComponent queryValue={sortBy} onChange={(value) => { setSortBy(value) }}/>
        </Flex>
      </Center>
      <AltirSkeleton isLoading={loading} error={error}>
        {transactions.length < 1
          ? <NoTransactionsFoundComponent title='No checks found.'/>
          : <TransactionsRowListComponent transactionsData={transactions} isCheckManagementEnabled={true}/>
        }
      </AltirSkeleton>
    </Center>
  )
}
