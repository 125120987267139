import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import TransferLinkButton from '@/library/button/TransferLinkButton'
import { Graphic } from '@/library/utility/Graphic'
import { BorderRadius, Color } from '@/theme/theme'
import { MAGNIFYING_GLASS_GRAPHIC } from '@/theme/graphics'

export interface EmptyTransferComponentProps {
  isInline?: boolean
}

export default function EmptyTransferComponent ({ isInline = false }: EmptyTransferComponentProps): ReactElement {
  const logoHeight = isInline ? '106px' : '141px'
  const verticalPadding = isInline ? 10 : 48
  return (
    <Flex
      flexDirection='column'
      width='100%'
      bg={Color.GREY}
      borderRadius={BorderRadius.CARD}
      justifyContent='center'
      alignItems='center'
      py={verticalPadding}
    >
      <Graphic src={MAGNIFYING_GLASS_GRAPHIC} h={logoHeight}/>
      <Flex maxW='280px' mt={8} mb={isInline ? 0 : 8}>
        <Text textAlign='center'>
          No recent Amplify Account Activity here. Make a deposit to get started
        </Text>
      </Flex>
      {!isInline ? <TransferLinkButton/> : null}
    </Flex>
  )
}
