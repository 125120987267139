import { gql } from '@apollo/client'

export const GET_STRIPE_AND_AMPLIFY_PROMO = gql`
    query GetStripeCheckoutLink($organizationId: String!) {
        StripeCheckoutLink(organizationId: $organizationId) {
            link
        }
        PromoCode(organizationId: $organizationId) {
            code
        }
        amplifyAccountPromotionalOffer {
            subscribedAnnualPercentageYieldFormatted
            unsubscribedAnnualPercentageYieldFormatted
        }
    }
`
