import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import CircleCheckIcon from '@/library/icons/CircleCheckIcon'
import { Color } from '@/theme/theme'

interface CapitalOSFeatureRowProps {
  text: string
}

export default function CapitalOSFeatureRow ({ text }: CapitalOSFeatureRowProps): ReactElement {
  return (
    <Flex gap={2} textAlign='start'>
      <CircleCheckIcon color={Color.BRIGHT_BLUE}/>
      <Text pl={2} color={Color.DARK_BLUE}>{text}</Text>
    </Flex>
  )
}
