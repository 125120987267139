import React, { type ReactElement } from 'react'
import { Color } from '@/theme/theme'
import ClockIcon from '@/library/icons/ClockIcon'
import InstitutionLogo from '@/library/logo/InstitutionLogo'
import CircleCheckIcon from '@/library/icons/CircleCheckIcon'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'

interface AccountRowIconProps {
  account: FinancialAccountExtendedFragment
}

export default function AccountRowIcon ({ account }: AccountRowIconProps): ReactElement {
  const isAwaitingVerification = account?.counterparty?.isOwnershipVerified === false
  if (isAwaitingVerification) {
    return (
      <ClockIcon color={Color.DARK_GREY}/>
    )
  } else if (account?.institution?.logoAssetUrl != null) {
    return (
      <InstitutionLogo
        src={account.institution.logoAssetUrl}
        height='25px'
      />
    )
  }
  return (
    <CircleCheckIcon color={Color.DARK_BLUE}/>
  )
}
