import { Flex, Text } from '@chakra-ui/react'
import
React, {
  useState,
  type ReactElement
} from 'react'
import { FormInput } from '../text/FormInput'
import SelectorComponent from '../select/SelectorComponent'
import FormInlineError from '../error/FormInlineError'
import { Color } from '../../theme/theme'
import FormNumberInput from '../number/FormNumberInput'
import { COUNTRIES, STATES } from '@/utils/addressUtils'

export interface AddressInput {
  streetAddressLine1?: string | null
  streetAddressLine2?: string | null
  city?: string | null
  state?: string | null
  country?: string | null
  postalCode?: string | null
}

export interface AddressFormProps {
  formName: string
  subtext?: string
  prepulatedAddress?: AddressInput | null
  onUpdate: (address: AddressInput) => void
  fieldError?: string | null
  isRequired?: boolean

  // Styles
  backgroundColor?: string
}

export default function AddressForm (
  {
    formName,
    subtext,
    prepulatedAddress,
    onUpdate,
    fieldError,
    isRequired = false,
    backgroundColor = Color.LIGHT_GREY
  }: AddressFormProps
): ReactElement {
  const [formData, setFormData] = useState<AddressInput>({
    streetAddressLine1: prepulatedAddress?.streetAddressLine1,
    streetAddressLine2: prepulatedAddress?.streetAddressLine2,
    city: prepulatedAddress?.city,
    state: prepulatedAddress?.state,
    country: prepulatedAddress?.country,
    postalCode: prepulatedAddress?.postalCode
  })

  function onChange (event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target
    updateFormData(name, value)
  }

  function updateFormData (name: string, value: string): void {
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value
      }
      onUpdate(updatedFormData)
      return updatedFormData
    })
  }

  const isInErrorState = fieldError != null

  return (
    <Flex flexDirection='column' gap={2} w='100%'>
      {/* TODO (PJ): refactor into generic text component */}
      <Flex flexDirection='column'>
        <Text color={isInErrorState ? Color.ERROR_RED : Color.DARK_GREY}>
          {formName}{isRequired ? '*' : ''}
        </Text>
        {subtext != null && <Text color={Color.DARK_GREY} fontSize='sm'>{subtext}</Text>}
      </Flex>
      <FormInlineError errorMessage={fieldError}/>
      <FormInput
        fieldName='streetAddressLine1'
        value={formData.streetAddressLine1 ?? ''}
        onChange={onChange}
        placeholder='Address Line 1'
        backgroundColor={backgroundColor}
      />
      <FormInput
        fieldName='streetAddressLine2'
        value={formData.streetAddressLine2 ?? ''}
        onChange={onChange}
        placeholder='Address Line 2'
        backgroundColor={backgroundColor}
      />
      <Flex width='100%' gap={2}>
        <Flex flexDirection='column' flex={1} gap={2}>
          <FormInput
            fieldName='city'
            value={formData.city ?? ''}
            onChange={onChange}
            placeholder='City'
            backgroundColor={backgroundColor}
          />
          <SelectorComponent
            options={COUNTRIES}
            fieldName='country'
            placeHolder='Select Country'
            value={formData.country}
            backgroundColor={backgroundColor}
            handleSelection={country => { updateFormData('country', country) }}
          />
        </Flex>
        <Flex flexDirection='column' flex={1} gap={2}>
          <SelectorComponent
            options={STATES}
            fieldName='state'
            placeHolder='Select State'
            value={formData.state}
            backgroundColor={backgroundColor}
            handleSelection={(state) => { updateFormData('state', state) }}
          />
          <FormNumberInput
            fieldName='postalCode'
            value={formData.postalCode ?? ''}
            onChange={(value) => { updateFormData('postalCode', value) }}
            placeholder='Zip Code'
            backgroundColor={backgroundColor}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
