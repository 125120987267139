import React, { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import OnboardingPage from '@/library/page/OnboardingPage'
import DisclosuresComponent
  from '@/components/pages/amplify_account_application/routes/apply/components/disclosures/DisclosuresComponent'
import { useAmplifyAppNavigation } from '@/hooks/onboarding/useAmplifyAppNavigation'
import { ContainerWidth } from '@/theme/theme'

export default function AmplifyApplyDisclosuresPage (): ReactElement {
  const { BUSINESS_ID } = useParams()
  const { skipCallToAction, backLink } = useAmplifyAppNavigation(String(BUSINESS_ID))
  return (
    <OnboardingPage
      maxWidth={ContainerWidth.LARGE}
      callToAction={skipCallToAction}
      amplifyApplicationContext={{ franchiseGroupId: Number(BUSINESS_ID) }}
    >
      <DisclosuresComponent franchiseGroupId={Number(BUSINESS_ID)} backLink={backLink}/>
    </OnboardingPage>
  )
}
