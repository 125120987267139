import React, { type ReactElement } from 'react'
import { Flex, Radio, Text } from '@chakra-ui/react'
import { Color } from '@/theme/theme'

interface RadioSelectProps<T> {
  description: string
  selectedValue: T | null // The selected value in the group
  valueOnSelection: T // the value that will be set when this radio is clicked
  handleSelection: (value: T | null) => void
}

export default function RadioSelect<T> ({
  description,
  handleSelection,
  selectedValue,
  valueOnSelection
}: RadioSelectProps<T>): ReactElement {
  const isChecked = selectedValue === valueOnSelection

  function onClick (): void {
    isChecked ? handleSelection(null) : handleSelection(valueOnSelection)
  }

  return (
    <Flex gap={4}>
      <Radio
        isChecked={selectedValue === valueOnSelection}
        onClick={onClick}
        size='lg'
        colorScheme='selectableInput'
      />
      <Text color={Color.DARK_BLUE}>{description}</Text>
    </Flex>
  )
}
