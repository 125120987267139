import React, { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import OwnershipComponent from '../../components/ownership/OwnershipComponent'
import { AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import OnboardingPage from '@/library/page/OnboardingPage'
import { useAmplifyAppNavigation } from '@/hooks/onboarding/useAmplifyAppNavigation'
import { ContainerWidth } from '@/theme/theme'

export default function AmplifyApplyOwnershipPage (): ReactElement {
  const { BUSINESS_ID, AMPLIFY_APPLICATION_ID } = useParams()
  const { skipCallToAction, backLink } = useAmplifyAppNavigation(String(BUSINESS_ID))

  return (
    <OnboardingPage
      maxWidth={ContainerWidth.LARGE}
      callToAction={skipCallToAction}
      amplifyApplicationContext={{ franchiseGroupId: Number(BUSINESS_ID) }}
    >
      <OwnershipComponent
        amplifyApplicationId={AMPLIFY_APPLICATION_ID}
        franchiseGroupId={Number(BUSINESS_ID)}
        backLink={backLink}
        context={AmplifyApplicationContext.DEFAULT}
      />
    </OnboardingPage>
  )
}
