import React, { type ReactElement } from 'react'
import { ListItem, Text, UnorderedList } from '@chakra-ui/react'
import { Color } from '@/theme/theme'

interface BulletedListProps {
  items: string[]

  // Styles
  color?: Color
}

export default function BulletedList ({
  items,
  color = Color.DARK_GREY
}: BulletedListProps): ReactElement {
  return (
    <UnorderedList color={color}>
      {
            items.map((item, index) => {
              return (
                <ListItem key={index}>
                  <Text>
                    {item}
                  </Text>
                </ListItem>
              )
            })
        }
    </UnorderedList>
  )
}
