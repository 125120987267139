import React, { type ReactElement, type ReactNode } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import MoreInfoTooltip from '../utility/MoreInfoTooltip'
import AltirSkeleton from '../loading/AltirSkeleton'
import { BorderRadius, Color } from '@/theme/theme'
import { EMPTY_BALANCE_VALUE } from '@/utils/constants'

export enum MetricCardVariant {
  DEFAULT,
  EDGE_TO_EDGE,
  CTA
}

interface MetricCardStyle {
  labelColor: string
  metricColor: string
  backgroundColor: string
  border: string
  contentPadding: number
}

interface MetricCardProps {
  label?: string
  metric: string | null
  secondaryMetric?: string
  children: ReactNode
  tooltipInfo?: string
  loadingHeight?: number | string
  isLoading?: boolean
  error?: Error
  // Styles
  variant?: MetricCardVariant
}

export default function MetricCard ({
  label,
  metric,
  secondaryMetric,
  children,
  tooltipInfo,
  error,
  loadingHeight,
  isLoading = false,
  variant = MetricCardVariant.DEFAULT
}: MetricCardProps): ReactElement {
  const isMetricAvailable = metric != null
  const { labelColor, metricColor, backgroundColor, border, contentPadding } = getCardStyle(variant)
  return (
    <AltirSkeleton isLoading={isLoading} error={error} loadingHeight={loadingHeight}>
      <Flex
        flexDir='column'
        justifyContent='space-between'
        flex={1}
        bg={isMetricAvailable ? backgroundColor : Color.GREY}
        borderRadius={BorderRadius.CARD}
        border={border}
        overflow='hidden'
        minH='100%'
      >
        <Flex flexDir='column' gap={2} px={6} pt={4}>
          <Flex justifyContent='space-between' alignItems='start'>
            {label != null && <Text color={labelColor}>{label}</Text>}
            {tooltipInfo != null && <Flex pt={0.5}><MoreInfoTooltip tooltipText={tooltipInfo}/></Flex>}
          </Flex>
          {/* Heading */}
          <Flex flexDir='column' alignItems='start' gap={1}>
            <Heading color={isMetricAvailable ? metricColor : Color.DARK_GREY} size='lg'>
              {isMetricAvailable ? metric : EMPTY_BALANCE_VALUE}
            </Heading>
            <Text pb={1} fontSize='xs' minHeight={6}>{secondaryMetric}</Text>
          </Flex>
        </Flex>
        <Flex flexDirection='column' gap={1} px={contentPadding} pb={contentPadding}>
          {children}
        </Flex>
      </Flex>
    </AltirSkeleton>
  )
}

function getCardStyle (variant: MetricCardVariant): MetricCardStyle {
  switch (variant) {
    case MetricCardVariant.DEFAULT:
      return {
        labelColor: Color.DARK_GREY,
        metricColor: Color.DARK_BLUE,
        backgroundColor: Color.WHITE,
        border: 'none',
        contentPadding: 6
      }
    case MetricCardVariant.CTA:
      return {
        labelColor: Color.BRIGHT_BLUE,
        metricColor: Color.BRIGHT_BLUE,
        backgroundColor: Color.LIGHT_BLUE,
        border: `1px solid ${String(Color.BRIGHT_BLUE)}`,
        contentPadding: 6
      }
    case MetricCardVariant.EDGE_TO_EDGE:
      return {
        labelColor: Color.DARK_GREY,
        metricColor: Color.DARK_BLUE,
        backgroundColor: Color.WHITE,
        border: 'none',
        contentPadding: 0
      }
  }
}
