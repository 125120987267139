import { gql } from '@apollo/client'
import { AUTHORIZATION_FRAGMENT } from '../../fragments/AuthorizationFragment'

export const GET_TRANSFER_RULES = gql`
  ${AUTHORIZATION_FRAGMENT}
  query GetTransferRules($organizationId: String!, $franchiseGroupId: Int!) {
    currentUser {
      franchiseGroup(id: $franchiseGroupId) {
        id
        hasAcknowledgedFraudulentReturnMessage
        transferRules {
            id
            updatedAt
            amplifyAccountId
            counterparty {
              name
              nickname
              achAccountNumber
            }
            counterpartyId
            direction
            isActive
            minTransferAmount
            mostRecentTransferEST
            nextScheduledTransferEST
            ruleStartDate
            targetBalance
            targetDate
            targetDay
            transferAmount
            transferRuleType
            lastEvaluationStatus
            lastEvaluationErrorType
        }
      }
      authorization(organizationId: $organizationId, franchiseGroupId: $franchiseGroupId) {
        ...AuthorizationFragment
      }
    }
  }
`
