import { nonNull } from './arrayUtils'
import { type updateAddressUnderscore as AddressUnderscoreInput } from '@/graphql/__generated__/globalTypes'
import { type AddressInput } from '@/library/form/address/AddressForm'

export const STATES = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL',
  'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT',
  'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI',
  'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
]

// TODO (pjc): Determine which countries we want to support in selector
export const COUNTRIES = ['US']

export function getAddressLines (address: AddressInput): string[] {
  return nonNull([
    address.streetAddressLine1,
    address.streetAddressLine2,
    `${address.city ?? ''}, ${address.state ?? ''} ${address.postalCode ?? ''}`
  ])
}

export function addressInputToAddressUnderscoreInput (
  addressInput?: AddressInput | null
): AddressUnderscoreInput | null {
  if (
    addressInput?.streetAddressLine1 == null ||
    addressInput.postalCode == null ||
    addressInput.city == null ||
    addressInput.state == null ||
    addressInput.country == null
  ) {
    return null
  }
  return {
    street_line_1: addressInput.streetAddressLine1,
    street_line_2: addressInput.streetAddressLine2,
    postal_code: addressInput.postalCode,
    city: addressInput.city,
    state: addressInput.state,
    country: addressInput.country
  }
}
