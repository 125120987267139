/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function GraphIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect stroke={color} x='3' y='13.5' width='4' height='7' rx='1' strokeWidth='2'/>
        <rect stroke={color} x='10' y='8.5' width='4' height='12' rx='1' strokeWidth='2'/>
        <rect stroke={color} x='17' y='3.5' width='4' height='17' rx='1' strokeWidth='2'/>
      </svg>
    </IconContainer>
  )
}
