import React, { type ReactElement } from 'react'
import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import StackedInfoAttribute from '../utility/StackedInfoAttribute'
import { TransferType } from '@/graphql/__generated__/globalTypes'
import {
  type TransferFragment as Transfer
} from '@/graphql/__generated__/TransferFragment'
import CheckManagementComponent from '@/components/pages/checks/components/CheckManagementComponent'

interface TransactionRowDropInfoDropdownProps {
  isSelected: boolean
  categoryLabelFormatted: string
  longDescription: string
  fromAccount?: string
  toAccount?: string
  dateFormatted?: string
  timeFormatted?: string
  institutionName?: string
  originatingTransfer?: Transfer

  isCheckManagementEnabled: boolean
}

const MotionFlex = motion(Flex)

export default function TransactionRowDropInfoDropdown ({
  isSelected,
  categoryLabelFormatted,
  longDescription,
  fromAccount,
  toAccount,
  dateFormatted,
  timeFormatted,
  originatingTransfer,
  isCheckManagementEnabled
}: TransactionRowDropInfoDropdownProps
): ReactElement {
  return (
    <MotionFlex
      w='100%'
      overflow='hidden'
      initial='closed'
      animate={isSelected
        ? 'open'
        : 'closed'}
      transition={{ duration: 0.2 }}
      variants={{
        open: {
          opacity: 1,
          height: 'auto',
          display: 'block'
        },
        closed: {
          opacity: 0.8,
          height: 0,
          transitionEnd: {
            display: 'none'
          }
        }
      }}
    >
      <Grid templateColumns='repeat(6, 1fr)' templateRows='1' rowGap={4}>
        {
          fromAccount != null &&
            <GridItem colSpan={1}>
              <StackedInfoAttribute header='From' subtext={fromAccount}/>
            </GridItem>
        }
        {
          toAccount != null &&
            <GridItem colSpan={1}>
              <StackedInfoAttribute header='To' subtext={toAccount}/>
            </GridItem>
        }
        {
          dateFormatted != null &&
            <GridItem colSpan={1}>
              <StackedInfoAttribute header='Date' subtext={dateFormatted}/>
            </GridItem>
        }
        {
          timeFormatted != null &&
            <GridItem colSpan={1}>
              <StackedInfoAttribute header='Time' subtext={timeFormatted}/>
            </GridItem>
        }
        <GridItem colSpan={6}>
          <StackedInfoAttribute header='Category' subtext={categoryLabelFormatted}/>
        </GridItem>
        <GridItem colSpan={6}>
          <StackedInfoAttribute header='Description' subtext={longDescription}/>
        </GridItem>
        {
          originatingTransfer?.checkMemo != null &&
            <GridItem colSpan={6}>
              <StackedInfoAttribute header='Check Memo' subtext={originatingTransfer.checkMemo}/>
            </GridItem>
        }
        {
          originatingTransfer?.checkMessage != null &&
            <GridItem colSpan={6}>
              <StackedInfoAttribute header='Check Message' subtext={originatingTransfer.checkMessage}/>
            </GridItem>
        }
        {
          isCheckManagementEnabled && originatingTransfer?.type === TransferType.CHECK &&
            <GridItem colSpan={6}>
              <CheckManagementComponent check={originatingTransfer}/>
            </GridItem>
        }
      </Grid>
    </MotionFlex>
  )
}
