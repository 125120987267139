import React, { useState, type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { type GetHomePageData } from '@/graphql/__generated__/GetHomePageData'
import { nonNull } from '@/utils/arrayUtils'
import { BorderRadius, Color } from '@/theme/theme'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { EMPTY_BALANCE_VALUE } from '@/utils/constants'
import { RouteParam, ROUTES } from '@/api/routes'
import EmptyStateCard from '@/library/card/EmptyStateCard'
import Button, { ButtonVariant } from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { getMask } from '@/utils/financialAccountUtils'
import { routeWithParams } from '@/utils/routerUtils'
import AmplifyWaitlistModal from '@/library/modal/amplify/AmplifyWaitlistModal'

interface AmplifyAccountSectionProps {
  data?: GetHomePageData | null
}

export default function AmplifyAccountSection ({
  data
}: AmplifyAccountSectionProps): ReactElement {
  const navigate = useNavigate()
  const [isWaitlistModalOpen, setIsWaitlistModalOpen] = useState(false)

  // TODO (PJ): Fetch amplify accounts for org directly here once endpoint is ready
  const amplifyAccounts = nonNull(data
    ?.currentUser
    ?.selectedOrganization
    ?.franchiseGroups
    ?.map(franchiseGroup => franchiseGroup.financialAccounts)
    ?.flat()
    .filter(account => account?.amplifyAccount != null) ?? [])

  const hasAmplifyAccount = amplifyAccounts.length > 0
  const isOnAmplifyWaitlist = data?.currentUser?.selectedOrganization?.isOnAmplifyWaitlist === true
  return (
    <Flex flexDir='column' gap={3}>
      <Text>My Amplify Accounts</Text>
      {hasAmplifyAccount
        ? <Flex flexDir='column' gap={2}>
          {
            amplifyAccounts.map(account => {
              const balance = account.liveBalance?.availableBalance?.amount
              const earnings = account.amplifyAccount?.earningsToDate?.amount
              return (
                <Flex
                  key={account.accountId}
                  onClick={() => {
                    navigate(
                      routeWithParams(
                        ROUTES.ACCOUNT_DETAIL, [{ param: RouteParam.ACCOUNT_ID, value: String(account.accountId) }]
                      )
                    )
                  }}
                  bg={Color.WHITE}
                  borderRadius={BorderRadius.CARD}
                  px={6}
                  py={4}
                  _hover={{ cursor: 'pointer', bg: Color.GREY }}
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Flex flexDir='column'>
                    <Text color={Color.DARK_BLUE}>
                      {account.name}
                    </Text>
                    <Text color={Color.DARK_BLUE}>
                      {getMask(account.lastFour)}
                    </Text>
                    <Text fontSize='sm'>{account.businessName}</Text>
                  </Flex>
                  <Flex
                    flexDir='column'
                    justifyContent='start'
                    alignItems='end'
                    textAlign='end'
                    h='100%'
                  >
                    <Text color={Color.DARK_BLUE}>
                      {balance != null
                        ? getCurrencyFormatted(balance, { minimumFractionDigits: 2 })
                        : EMPTY_BALANCE_VALUE}
                    </Text>
                    <Text fontSize='sm' color={Color.BRIGHT_BLUE}>
                      {earnings != null ? `+${getCurrencyFormatted(earnings, { minimumFractionDigits: 2 })}` : EMPTY_BALANCE_VALUE}
                    </Text>
                  </Flex>
                </Flex>
              )
            })
        }
        </Flex>
        : <EmptyStateCard
            title='No Amplify Accounts yet.'
            subTitle='Apply for an Amplify Account to get started.'
          >
          <Button
            text={isOnAmplifyWaitlist ? 'On Amplify Waitlist' : 'Apply Now'}
            onClick={() => { setIsWaitlistModalOpen(true) }}
            variant={ButtonVariant.WHITE_GREY_BACKGROUND}
            isDisabled={isOnAmplifyWaitlist}
            afterIcon={<RightArrowIcon color={Color.DARK_BLUE}/>}
          />
        </EmptyStateCard>}
      <AmplifyWaitlistModal isOpen={isWaitlistModalOpen} onClose={() => { setIsWaitlistModalOpen(false) }}/>
    </Flex>
  )
}
