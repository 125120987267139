/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { Icon } from '@chakra-ui/react'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function LogOutIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <Icon boxSize={size} display='flex' alignItems='center' justifyContent='center'>
      <svg width='100%' height='100%' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path stroke={color} d='M8 9H15.5M15.5 9L12.2857 12.375M15.5 9L12.2857 5.625M15.5 4.375V3.25C15.5 2.65326 15.2742 2.08097 14.8724 1.65901C14.4705 1.23705 13.9255 1 13.3571 1H2.64286C2.07454 1 1.52949 1.23705 1.12763 1.65901C0.725765 2.08097 0.5 2.65326 0.5 3.25V14.75C0.5 15.3467 0.725765 15.919 1.12763 16.341C1.52949 16.7629 2.07454 17 2.64286 17H13.3571C13.9255 17 14.4705 16.7629 14.8724 16.341C15.2742 15.919 15.5 15.3467 15.5 14.75V13.625' strokeLinecap='round' strokeLinejoin='round'/>
      </svg>
    </Icon>
  )
}
