import { gql } from '@apollo/client'
import { AUTHORIZATION_FRAGMENT } from '../fragments/AuthorizationFragment'

export const GET_CURRENT_USER = gql`
    ${AUTHORIZATION_FRAGMENT}
    query GetCurrentUser($organizationId: String) {
        currentUser {
            id
            firstName
            lastName
            email
            areEmailNotificationsEnabled
            authorization(organizationId: $organizationId) {
                ...AuthorizationFragment
            }
            selectedOrganization(organizationId: $organizationId) {
                id
                name
                subscriptionLevel
                franchiseGroups {
                    id
                    name
                    brand {
                        name
                        defaultPointOfSalePlatform
                    }
                    isOnboardingComplete
                    amplifyAccountApplicationStatus
                    hasAcceptedAmplifyTermsAndConditions
                    amplifyAccount {
                        accountId
                    }
                    subscription {
                        status
                    }
                    hasBrokenAccountLink
                    capitalOSConfiguration {
                        account {
                            status
                        }
                    }
            }
            }
            organizations {
                id
                name
            }
            isUserSignedUp
            pendingInvite {
                email
                inviteCreator
                franchiseGroupId
                organizationId
            }
            rolloutConfiguration {
                enableStripeCheckout
                enableLabelFreeInsightsPage
                enableCapitalOS
                enableInActivityBlur
            }
        }
        amplifyAccounts(organizationId: $organizationId) {
            accountId
            name
            lastFour
        } 
    }
`
