import { gql } from '@apollo/client'
import {
  AMPLIFY_ACCOUNT_BUSINESS_APPLICATION_FORMATTED_FRAGMENT
} from './AmplifyAccountBusinessApplicationFragment'
import {
  AMPLIFY_ACCOUNT_PERSON_APPLICATION_FORMATTED_FRAGMENT
} from './AmplifyAccountPersonApplicationFragment'

export const AMPLIFY_ACCOUNT_APPLICATION_FRAGMENT = gql`
    ${AMPLIFY_ACCOUNT_BUSINESS_APPLICATION_FORMATTED_FRAGMENT}
    ${AMPLIFY_ACCOUNT_PERSON_APPLICATION_FORMATTED_FRAGMENT}
    fragment AmplifyAccountApplicationFragment on AmplifyAccountApplication {
        id
        numberOfStakeholders
        sourcesOfFunds
        completionPercentage
        isApplicationReadyForSubmission
        status
        isOwnershipPercentageConfirmed
        businessApplication {
            ...AmplifyAccountBusinessApplicationFormattedFragment
        }
        personalApplications {
            ...AmplifyAccountPersonApplicationFormattedFragment
        }
    }
`
