import { gql } from '@apollo/client'
import { AUTHORIZATION_FRAGMENT } from '../../fragments/AuthorizationFragment'
import { TEAM_MEMBER_FRAGMENT } from '@/graphql/fragments/TeamMemberFragment'

export const GET_ORGANIZATION_SETTINGS = gql`
    ${AUTHORIZATION_FRAGMENT}
    ${TEAM_MEMBER_FRAGMENT}
    query GetOrganizationSettings($organizationId: String) {
        currentUser {
            roles {
                franchiseGroup {
                    id
                    name
                }
            }
            selectedOrganization(organizationId: $organizationId) {
                id
                name
                users {
                    ...TeamMemberFragment
                }
                franchiseGroups {
                    id
                    name
                }
                invitedUsers {
                    email
                }
            }
            authorization(organizationId: $organizationId) {
                ...AuthorizationFragment
            } 
        }
    }
`
