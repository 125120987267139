import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { BorderRadius, Color } from '../theme/theme'

interface AltirProFeatureBoxProps {
  children: ReactElement[]
  color?: Color
}

export default function AltirProFeatureBox ({
  children,
  color = Color.BRIGHT_BLUE
}: AltirProFeatureBoxProps): ReactElement {
  return (
    <Flex
      flexDirection='column'
      border={`1.5px solid ${color}`}
      borderRadius={BorderRadius.CARD}
      py={4}
      px={4}
      width='100%'
    >
      {children}
    </Flex>
  )
}
