import { Flex, Heading } from '@chakra-ui/react'
import React, { type ReactElement, type ReactNode } from 'react'
import { BorderRadius, Color } from '@/theme/theme'

interface DashboardSectionProps {
  title: string
  secondaryTitleElement?: ReactNode
  children: ReactNode
}

// TODO (PJ): Replace DashboardSection after migrating
export default function DashboardSection (
  { title, secondaryTitleElement, children }: DashboardSectionProps
): ReactElement {
  return (
    <Flex
      w='100%'
      borderRadius={BorderRadius.CARD}
      justifyContent='center'
      alignItems='center'
      bg={Color.LIGHT_GREY}
      flexDirection='column'
      p={6}
      gap={4}
      boxShadow='0px 0px 40px rgba(0, 0, 0, 0.1)' // TODO (PJ): Pull real drop shadow from Figma
    >
      <Flex justifyContent='space-between' alignItems='center' w='100%'>
        <Heading size='md' color={Color.DARK_BLUE}>{title}</Heading>
        {secondaryTitleElement != null && secondaryTitleElement}
      </Flex>
      {children}
    </Flex>
  )
}
