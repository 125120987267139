import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { AvatarComponent } from '@/library/utility/AvatarComponent'
import { BorderRadius, Color, IconSize } from '@/theme/theme'
import { safeGetFirstLetter } from '@/utils/stringUtils'

interface UserDescriptorRowProps {
  entityName?: string | null
}

export default function UserBusinessDescriptorRow ({ entityName }: UserDescriptorRowProps): ReactElement {
  if (entityName == null) return <></>

  return (
    <Flex
      borderRadius={BorderRadius.BUTTON}
      w='100%'
      alignItems='center'
      bg={Color.GREY}
      gap={4}
      p={2}
    >
      <AvatarComponent
        text={safeGetFirstLetter(entityName)}
        size={IconSize.SMALL}
        backgroundColor={Color.WHITE}
      />
      <Text>{entityName}</Text>
    </Flex>
  )
}
