import React, { type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import Button, { ButtonVariant } from '@/library/button/Button'
import { Graphic } from '@/library/utility/Graphic'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import AccountsIcon from '@/library/icons/AccountsIcon'
import { BANK_PLUS_GRAPHIC } from '@/library/theme/graphics'
import {
  BorderRadius,
  Color,
  FontWeight
} from '@/theme/theme'
import InstitutionConnectionComponent from '@/components/clients/financial_institution/InstitutionConnectionComponent'

export interface EmptyAccountsSectionComponentProps {
  refetch: () => void
  franchiseGroupId: number
  isLinkAccountModalOpen?: boolean
  ctaText?: string
  hideLinkAccountButton?: boolean
}

export default function EmptyAccountsSectionComponent (
  {
    refetch,
    franchiseGroupId,
    isLinkAccountModalOpen = false,
    hideLinkAccountButton = false,
    ctaText = 'Connect your account to get the most out of Altir.'
  }: EmptyAccountsSectionComponentProps
): ReactElement {
  const button = (
    <Button
      text='Link an Account'
      beforeIcon={<AccountsIcon/>}
      variant={ButtonVariant.WHITE_GREY_BACKGROUND}
      onClickEventType={Event.PLAID_LINK_OPEN_CLICK}
    />
  )
  return (
    <AltirSkeleton isLoading={false}>
      <Flex
        width='100%'
        bg={Color.GREY}
        borderRadius={BorderRadius.CARD}
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        height='100%'
        py={24}
      >
        <Flex flexDirection='column' alignItems='center' width='50%'>
          <Flex mb={2}>
            <Graphic w='auto' h='122px' src={BANK_PLUS_GRAPHIC}/>
          </Flex>
          <Heading
            size='lg'
            color={Color.DARK_BLUE}
            fontWeight={FontWeight.MEDIUM}
            my={2}
          >
            No accounts here.
          </Heading>
          <Text color={Color.DARK_GREY} fontWeight={FontWeight.MEDIUM} mb={4} textAlign='center'>
            {ctaText}
          </Text>
          {
            !hideLinkAccountButton &&
            <InstitutionConnectionComponent
              franchiseGroupId={franchiseGroupId}
              defaultIsOpen={isLinkAccountModalOpen}
              handleBankAccountLink={refetch}
              button={button}
            />
          }
        </Flex>
      </Flex>
    </AltirSkeleton>
  )
}
