import React, { type ReactElement } from 'react'
import { Text, Flex, useToast } from '@chakra-ui/react'
import ClipboardCopyIcon from '../icons/ClipboardCopyIcon'
import { getActionToast } from '@/utils/toastUtils'
import { BorderRadius, Color, IconSize } from '@/theme/theme'

export enum CopyTextPillVariant {
  DEFAULT,
  EXPANDED
}

interface CopyTextPillProps {
  name: string
  number: string

  // Style
  backgroundColor?: string
  variant?: CopyTextPillVariant
}

export function CopyTextPill ({
  name,
  number,
  backgroundColor = Color.LIGHT_GREY,
  variant = CopyTextPillVariant.DEFAULT
}: CopyTextPillProps): ReactElement {
  const toast = useToast()

  const isExpandedVersion = variant === CopyTextPillVariant.EXPANDED

  function copyToClipboard (): void {
    navigator.clipboard.writeText(number)
      .then(() => { toast(getActionToast(`${name} copied to clipboard`)) })
      .catch(() => {})
  }

  return (
    <Flex flexDir='column' gap={1}>
      {isExpandedVersion && <Text>{name}</Text>}
      <Flex
        bg={backgroundColor}
        py={4}
        pl={6}
        pr={5} // -1 to offset copy button padding
        gap={6}
        w='100%'
        borderRadius={BorderRadius.CARD}
        justifyContent='space-between'
        alignItems='center'
      >
        {!isExpandedVersion && <Text>{name}</Text>}
        <Flex
          alignItems='center'
          gap={1}
          justifyContent={isExpandedVersion ? 'space-between' : 'center'}
          w={isExpandedVersion ? '100%' : 'auto'}
        >
          <Text color={Color.DARK_BLUE}>{number}</Text>
          <Flex
            _hover={{ cursor: 'pointer', bg: Color.GREY }}
            p={1}
            borderRadius={BorderRadius.BUTTON_HOVER}
            onClick={copyToClipboard}
          >
            <ClipboardCopyIcon color={Color.DARK_BLUE} size={IconSize.MEDIUM}/>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
