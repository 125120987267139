import React, { type ReactElement, useEffect, useRef } from 'react'
import lottie from 'lottie-web'
import { Box, Center } from '@chakra-ui/react'

const BASE_LOGO_DIMENSIONS = '100px'

interface LoaderProps {
  animationData: any
  size?: string
  loop?: boolean
}

export enum AnimationSize {
  Medium
}

function BaseAnimation (
  {
    animationData,
    size = BASE_LOGO_DIMENSIONS,
    loop = true
  }: LoaderProps
): ReactElement {
  const containerRef = useRef(document.createElement('div'))

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      loop,
      autoplay: true,
      animationData
    })
    return () => { anim.destroy() } // cleanup on unmount
  }, [animationData])

  return (
    <Center>
      <Box
        w={size}
        h='auto'
      >
        <div ref={containerRef}></div>
      </Box>
    </Center>
  )
}

export default BaseAnimation
