import { InstitutionConnectionProvider } from '@/graphql/__generated__/globalTypes'

export function isManageExistingAccountLinkEnabled (connectionProvider: InstitutionConnectionProvider): boolean {
  switch (connectionProvider) {
    case InstitutionConnectionProvider.PLAID:
      return true
    case InstitutionConnectionProvider.TELLER:
    case InstitutionConnectionProvider.ALTIR:
    case InstitutionConnectionProvider.TREASURY_PRIME:
      return false
  }
}
