import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import InstitutionGroupComponent from './InstitutionGroupComponent'
import { groupFinancialAccountsByInstitution } from '@/utils/financialAccountUtils'
import {
  type GetSettingsPageData_currentUser_selectedOrganization_franchiseGroups_financialAccounts as FinancialAccount
} from '@/graphql/__generated__/GetSettingsPageData'
import InstitutionConnectionComponent from '@/components/clients/financial_institution/InstitutionConnectionComponent'
import Button, { ButtonVariant } from '@/library/button/Button'
import { Color, IconSize } from '@/theme/theme'
import PlusIcon from '@/library/icons/PlusIcon'
import EmptyStateCard from '@/library/card/EmptyStateCard'

export interface AccountsSettingsComponentProps {
  franchiseGroupId: number
  accounts: FinancialAccount[]
  refetchSettings: () => void
}

const ACCOUNTS_EMPTY_STATE_TITLE = 'No Accounts'
const ACCOUNTS_EMPTY_STATE_SUBTITLE =
  'Link an account to view transactions and make transfers.'

export default function AccountsSettingsComponent (
  {
    franchiseGroupId,
    accounts,
    refetchSettings
  }: AccountsSettingsComponentProps
): ReactElement {
  const groupedInstitutions = groupFinancialAccountsByInstitution(accounts)
  const hasLinkedAccount = groupedInstitutions.length > 0
  const institutionConnectionComponent = (
    <InstitutionConnectionComponent
      franchiseGroupId={franchiseGroupId}
      handleBankAccountLink={refetchSettings}
      button={
        <Button text='Add an Account'
          variant={hasLinkedAccount ? ButtonVariant.GREY : ButtonVariant.WHITE_OUTLINE}
          beforeIcon={<PlusIcon color={Color.DARK_BLUE} size={IconSize.SMALL}/>}
          width='auto'
        />
      }
    />
  )
  return (
    <Flex flexDir='column' gap={6} w='100%'>
      <Flex flexDir='column' gap={2} w='100%'>
        <Text>Linked Accounts</Text>
        {
          groupedInstitutions.map(institutionGroup => {
            return (
              <InstitutionGroupComponent
                key={institutionGroup.plaidAccessToken}
                franchiseGroupId={franchiseGroupId}
                institutionGroup={institutionGroup}
                refetchData={refetchSettings}
              />
            )
          })
        }
        {
          !hasLinkedAccount
            ? <EmptyStateCard
                title={ACCOUNTS_EMPTY_STATE_TITLE}
                subTitle={ACCOUNTS_EMPTY_STATE_SUBTITLE}
              >
              {institutionConnectionComponent}
            </EmptyStateCard>
            : <Flex w='100%' justifyContent='end'>{institutionConnectionComponent}</Flex>
        }
      </Flex>
    </Flex>
  )
}
