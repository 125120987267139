
import { gql } from '@apollo/client'
import {
  FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT
} from '../../fragments/financial_accounts/FinancialAccountExtendedFragment'

export const GET_ONBOARDING_BUSINESS_DATA = gql`
    ${FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT}
    query GetOnboardingBusinessData {
        currentUser {
            selectedOrganization {
                id
                name
                franchiseGroups {
                    id
                    name
                    numberOfFranchiseLocations
                    brand {
                        name
                    }
                    financialAccounts {
                        ...FinancialAccountExtendedFragment
                    }
                    storeLocations {
                        id
                        name
                        checkFieldIdentifier
                        posFieldIdentifier
                        storeId
                    }
                }
            }
        }
    }
`
