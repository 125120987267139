import React, { type ReactElement } from 'react'
import { Center, Text } from '@chakra-ui/react'
import FAQText from '@/library/text/FAQText'
import LinkText from '@/library/text/LinkText'

const CANCELLATION_ANSWER = `
    Within an hour of initiating a Check Send, you can cancel the check. Cancelling prevents the check from being sent. After an hour, you are able to request a stop payment, which does not guarantee its cancellation.
`

const USPS_FIRST_CLASS_REFERENCE_LINK = 'https://www.usps.com/ship/first-class-mail.htm'

export default function ChecksFAQContent (): ReactElement {
  return (
    <Center w='100%' flexDir='column' gap={6}>
      <FAQText
        question='How much does it cost to send a check?'
        answer='$1.50 per check.'
      />
      <FAQText
        question='How long does it take for my check to arrive?'
        answer={null}
        customAnswer={
          <Text>
            6-8 business days. A paper check is mailed within 2 business days via{' '}
            <LinkText text='USPS First Class Mail' href={USPS_FIRST_CLASS_REFERENCE_LINK}/>
            .{' '}
            The check is then delivered within 4-6 business days.
          </Text>
        }
      />
      <FAQText
        question='Can I cancel my check?'
        answer={CANCELLATION_ANSWER}
      />
    </Center>
  )
}
