import React, { type ReactNode, type ReactElement } from 'react'
import { Divider, Flex } from '@chakra-ui/react'
import { Color } from '../theme/theme'

export function CardRow ({ children }: { children: ReactNode }): ReactElement {
  return (
    <Flex
      alignItems='baseline'
      justifyContent='space-between'
      flexDirection='row'
      width='100%'
    >
      {children}
    </Flex>
  )
}

export function CardDivider ({ color = Color.GREY }: { color?: string }): ReactElement {
  return (
    <Divider my={3} borderColor={color}/>
  )
}
