import { Flex, Text } from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import React, { useState, type ReactElement } from 'react'
import { DocumentDescription } from './DocumentDescription'
import { useUploadFile } from '../../../../../hooks/useUploadFile'
import ErrorInline from '@/library/errors/ErrorInline'
import { FileUploadInput } from '@/library/form/file/FileUploadInput'
import CircleCheckIcon from '@/library/icons/CircleCheckIcon'
import { getFileUploadDescription } from '@/utils/stringUtils'
import { type FileUploadType } from '@/graphql/__generated__/globalTypes'
import {
  type GetDocumentsHubData_currentUser_franchiseGroup_lendingDocuments as LendingDocument
} from '@/graphql/__generated__/GetDocumentsHubData'
import { Color, IconSize } from '@/theme/theme'

export interface DocumentUploadComponentProps {
  franchiseGroupId: number
  organizationId: string | null
  type: FileUploadType
  existingDocuments: LendingDocument[]
  onUploadSuccess: () => void
}

export default function DocumentUploadComponent (
  {
    franchiseGroupId,
    organizationId,
    type,
    existingDocuments,
    onUploadSuccess
  }: DocumentUploadComponentProps
): ReactElement {
  const [uploadFile, { loading, error: uploadFileError }] = useUploadFile()
  const [error, setError] = useState<Error | undefined>()

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = (e.target.files ?? [])[0]
    if (file == null) {
      setError(Error('No file selected'))
      return
    }

    await uploadFile({
      franchiseGroupId,
      organizationId,
      type,
      file,
      onSuccess: onUploadSuccess
    })
  }

  const relevantExistingDocuments = existingDocuments.filter(d => d.type === type)
  const hasExistingDocument = relevantExistingDocuments.length > 0
  return (
    <Flex flexDir='column' justifyContent='center' gap={2}>
      <Flex w='100%' justifyContent='space-between' alignItems='center'>
        <Flex flexDir='column'>
          <Text color={Color.DARK_BLUE}>{getFileUploadDescription(type)}</Text>
          {relevantExistingDocuments.map(d => <DocumentDescription key={nanoid()} document={d}/>)}
        </Flex>
        {!hasExistingDocument
          ? <FileUploadInput onChange={handleFileChange} type={type} isLoading={loading}/>
          : <CircleCheckIcon color={Color.DARK_GREY} size={IconSize.SMALL}/>
        }
      </Flex>
      <ErrorInline error={uploadFileError ?? error}/>
    </Flex>
  )
}
