
import { gql } from '@apollo/client'

export const REQUEST_BANK_MIGRATION = gql`
    mutation RequestBankMigration($organizationId: String!) {
        requestBankMigration(organizationId: $organizationId) {
            id
            bankMigrationStatus
        }
    }
`
