/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function NotificationBellIcon ({ color, size = IconSize.LARGE }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M12.0007 3.75537C11.6694 3.75537 11.3516 3.88698 11.1173 4.12126C10.8831 4.35553 10.7515 4.67327 10.7515 5.00458V5.1295C9.34086 5.41791 8.07315 6.18461 7.16267 7.29995C6.25219 8.41529 5.75481 9.81083 5.75463 11.2506V16.2474L4.24934 17.9064C3.52105 18.7096 4.09069 19.9951 5.17375 19.9951H9.83704C10.0563 20.3748 10.3717 20.6902 10.7515 20.9095C11.1313 21.1287 11.5621 21.2441 12.0007 21.2441C12.4392 21.2441 12.87 21.1287 13.2498 20.9095C13.6296 20.6902 13.945 20.3748 14.1643 19.9951H18.8276C19.9106 19.9951 20.4803 18.7084 19.752 17.9064L18.2467 16.2474V11.2506C18.2465 9.81083 17.7491 8.41529 16.8387 7.29995C15.9282 6.18461 14.6605 5.41791 13.2499 5.1295V5.00458C13.2499 4.67327 13.1183 4.35553 12.884 4.12126C12.6497 3.88698 12.332 3.75537 12.0007 3.75537ZM7.62844 16.972L7.14125 17.5066L6.5841 18.1212H17.4172L16.8588 17.5066L16.3729 16.9707V11.2506C16.3729 10.6764 16.2598 10.1079 16.0401 9.57743C15.8203 9.04697 15.4983 8.56498 15.0923 8.15898C14.6863 7.75298 14.2043 7.43093 13.6738 7.2112C13.1434 6.99148 12.5748 6.87839 12.0007 6.87839C11.4265 6.87839 10.8579 6.99148 10.3275 7.2112C9.79702 7.43093 9.31503 7.75298 8.90904 8.15898C8.50304 8.56498 8.18098 9.04697 7.96126 9.57743C7.74153 10.1079 7.62844 10.6764 7.62844 11.2506V16.972Z' fill={color}/>
      </svg>
    </IconContainer>
  )
}
