import React, { type ReactElement } from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { type TableHeader } from '../Table'

interface TableHeaderRowProps<T> {
  tableMetadata: Array<TableHeader<T>>
  templateColumns: string
  gridHorizontalPadding: number
}

export default function TableHeaderRow<T> ({
  tableMetadata,
  templateColumns,
  gridHorizontalPadding
}: TableHeaderRowProps<T>): ReactElement {
  return (
    <Grid
      templateColumns={templateColumns}
      w='100%'
      alignItems='center'
      px={gridHorizontalPadding}
    >
      {
        tableMetadata.map((column, index) => {
          return (
            <GridItem
              key={index}
              colStart={index + 1}
              justifySelf='left'
              w='100%'
              textAlign={column.alignment ?? 'left'}
            >
              <Text>{column.header}</Text>
            </GridItem>
          )
        })
    }
    </Grid>
  )
}
