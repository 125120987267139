/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function ClockIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M4.75 12.1196C4.75 7.70336 8.33374 4.11963 12.75 4.11963C17.1663 4.11963 20.75 7.70336 20.75 12.1196C20.75 16.5359 17.1663 20.1196 12.75 20.1196C8.33374 20.1196 4.75 16.5359 4.75 12.1196ZM12.75 2.11963C7.22917 2.11963 2.75 6.59879 2.75 12.1196C2.75 17.6405 7.22917 22.1196 12.75 22.1196C18.2708 22.1196 22.75 17.6405 22.75 12.1196C22.75 6.59879 18.2708 2.11963 12.75 2.11963ZM13.75 8.17822C13.75 7.62594 13.3023 7.17822 12.75 7.17822C12.1977 7.17822 11.75 7.62594 11.75 8.17822V12.1782C11.75 12.5906 12.0032 12.9608 12.3876 13.1102L16.3877 14.6658C16.9024 14.866 17.4819 14.611 17.6821 14.0962C17.8823 13.5815 17.6273 13.0019 17.1125 12.8018L13.75 11.4941V8.17822Z' fill={color} />
      </svg>
    </IconContainer>
  )
}
