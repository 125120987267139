
/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function FileUploadIcon ({ color, size = IconSize.SMALL }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 14 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill={color} stroke={color} d='M2.125 7.66637V15.6666C2.125 15.7718 2.209 15.8571 2.3125 15.8571H11.6875C11.7372 15.8571 11.7849 15.837 11.8201 15.8013C11.8552 15.7656 11.875 15.7171 11.875 15.6666V7.66637C11.875 7.61585 11.8552 7.5674 11.8201 7.53168C11.7849 7.49596 11.7372 7.47589 11.6875 7.47589H9.8125C9.66332 7.47589 9.52024 7.41568 9.41475 7.30852C9.30926 7.20135 9.25 7.056 9.25 6.90444C9.25 6.75289 9.30926 6.60754 9.41475 6.50037C9.52024 6.3932 9.66332 6.333 9.8125 6.333H11.6875C12.412 6.333 13 6.93035 13 7.66637V15.6666C13 16.0203 12.8617 16.3594 12.6156 16.6095C12.3694 16.8595 12.0356 17 11.6875 17H2.3125C1.9644 17 1.63056 16.8595 1.38442 16.6095C1.13828 16.3594 1 16.0203 1 15.6666V7.66637C1 6.93035 1.588 6.333 2.3125 6.333H4.1875C4.33668 6.333 4.47976 6.3932 4.58525 6.50037C4.69074 6.60754 4.75 6.75289 4.75 6.90444C4.75 7.056 4.69074 7.20135 4.58525 7.30852C4.47976 7.41568 4.33668 7.47589 4.1875 7.47589H2.3125C2.26277 7.47589 2.21508 7.49596 2.17992 7.53168C2.14475 7.5674 2.125 7.61585 2.125 7.66637ZM7.3975 1.16712L9.835 3.64339C9.90904 3.71324 9.96317 3.80208 9.99168 3.90056C10.0202 3.99904 10.022 4.1035 9.997 4.20295C9.97197 4.3024 9.921 4.39315 9.84946 4.46564C9.77792 4.53813 9.68846 4.58968 9.5905 4.61485C9.49272 4.64031 9.38999 4.63853 9.29312 4.60971C9.19624 4.58088 9.10881 4.52607 9.04 4.45103L7.5625 2.95003V11.0951C7.5625 11.2466 7.50324 11.392 7.39775 11.4991C7.29226 11.6063 7.14918 11.6665 7 11.6665C6.85082 11.6665 6.70774 11.6063 6.60225 11.4991C6.49676 11.392 6.4375 11.2466 6.4375 11.0951V2.95003L4.96 4.45103C4.8533 4.55192 4.71227 4.60686 4.56653 4.6043C4.42079 4.60174 4.2817 4.54189 4.1785 4.43732C4.07557 4.33248 4.01665 4.19118 4.01413 4.04312C4.01162 3.89506 4.06569 3.75178 4.165 3.64339L6.6025 1.16712C6.70797 1.06011 6.85094 1 7 1C7.14906 1 7.29203 1.06011 7.3975 1.16712Z' strokeWidth='0.5'/>
      </svg>
    </IconContainer>
  )
}
