import React, { useState, type ReactElement } from 'react'
import { Center, Flex, Radio, Text } from '@chakra-ui/react'
import WireDetailsSwitchComponent from '../WireDetailsSwitchComponent'
import { FormInput } from '@/library/form/text/FormInput'
import AddressForm, { type AddressInput } from '@/library/form/address/AddressForm'
import { Color } from '@/theme/theme'
import ErrorInline from '@/library/errors/ErrorInline'
import Button, { ButtonVariant } from '@/library/button/Button'
import { fieldRequiredMessage, isEmpty } from '@/utils/stringUtils'
import SelectorComponent from '@/library/form/select/SelectorComponent'
import { AccountType, CounterpartyType, FileUploadType } from '@/graphql/__generated__/globalTypes'
import FileUploadFormField from '@/library/form/file/FileUploadFormField'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { type ErrorWithContent } from '@/types/types'
import FormNumberInput from '@/library/form/number/FormNumberInput'
import { isInvalidAccountNumber, isInvalidRoutingNumber } from '@/utils/formUtils'

export interface AddBankingDetailsFormData {
  accountHolderName?: string
  accountType?: AccountType
  accountNumber?: string
  routingNumber?: string
  wireAccountNumber?: string
  wireRoutingNumber?: string
  bankAddress?: AddressInput
  address?: AddressInput
  bankName?: string
  counterpartyType?: CounterpartyType
  fileUploadId?: string
}

interface AddBankingDetailsFormProps {
  globalFormData: AddBankingDetailsFormData | null
  onSubmit: (formData: AddBankingDetailsFormData) => void
  onCancel: () => void
}

const FILE_UPLOAD_SECONDARY_LABEL_PERSONAL = `
  Please provide evidence that you own the account. This is typically in the form of a bank statement.
`
const FILE_UPLOAD_SECONDARY_LABEL_VENDOR = `
  Please provide evidence that this is a vendor you transact with. This is typically in the form of an invoice.
`

export default function AddBankingDetailsForm ({
  globalFormData,
  onSubmit,
  onCancel
}: AddBankingDetailsFormProps): ReactElement {
  const { selectedOrganizationId, selectedFranchiseGroupId } = useAltirStore(state => {
    return {
      selectedOrganizationId: state.selectedOrganizationState.selectedOrganizationId,
      selectedFranchiseGroupId: state.selectedFranchiseGroupId
    }
  })
  const [formData, setFormData] = useState<AddBankingDetailsFormData>({
    counterpartyType: CounterpartyType.EXTERNAL_VENDOR,
    ...globalFormData
  })
  const [inlineErrorState, setInlineErrorState] = useState<AddBankingDetailsFormData>({})
  const [inlineError, setInlineError] = useState<ErrorWithContent>()
  const [isWireFieldsVisible, setIsWireFieldsVisible] = useState<boolean>(false)

  function onChange (event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target
    updateForm(name, value)
  }

  function updateForm (name: string, value: string): void {
    flushErrors(name)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  function flushErrors (name: string): void {
    updateInlineErrorState(name, undefined)
    setInlineError(undefined)
  }

  function updateAddress (address: AddressInput | null): void {
    flushErrors('address')
    setFormData((prevFormData) => ({
      ...prevFormData,
      address: address ?? undefined
    }))
  }

  function updateBankAddress (address: AddressInput | null): void {
    flushErrors('bankAddress')
    setFormData((prevFormData) => ({
      ...prevFormData,
      bankAddress: address ?? undefined
    }))
  }

  function updateInlineErrorState (name: string, value?: string): void {
    setInlineErrorState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  function isFormValidForSubmit ({ displayErrors = false } = {}): boolean {
    let hasError = false
    if (isEmpty(formData.accountHolderName)) {
      displayErrors && updateInlineErrorState('accountHolderName', fieldRequiredMessage('Account Holder Name'))
      hasError = true
    }
    if (isEmpty(formData.accountType)) {
      displayErrors && updateInlineErrorState('accountType', fieldRequiredMessage('Account Type'))
      hasError = true
    }
    const {
      isInvalid: isAccountNumberInvalid,
      reason: invalidAccountNumberReason
    } = isInvalidAccountNumber(formData.accountNumber)
    if (isAccountNumberInvalid) {
      displayErrors && updateInlineErrorState('accountNumber', invalidAccountNumberReason)
      hasError = true
    }
    const {
      isInvalid: isRoutingNumberInvalid,
      reason: invalidRoutingNumberReason
    } = isInvalidRoutingNumber(formData.routingNumber)
    if (isRoutingNumberInvalid) {
      displayErrors && updateInlineErrorState('routingNumber', invalidRoutingNumberReason)
      hasError = true
    }
    if (isEmpty(formData.fileUploadId)) {
      displayErrors && updateInlineErrorState('fileUploadId', fieldRequiredMessage('Document Upload'))
      hasError = true
    }

    return !hasError
  }

  function handleSubmit (): void {
    if (isFormValidForSubmit({ displayErrors: true })) {
      onSubmit(formData)
    } else {
      setInlineError({
        customContent: {
          title: 'Unable to submit form',
          subtitle: 'Check your form fields'
        },
        error: Error()
      })
    }
  }

  return (
    <Flex flexDir='column' alignItems='center' gap={8} w='100%'>
      <Flex flexDir='column' gap={6} w='100%'>
        <FormInput
          fieldName='accountHolderName'
          label='Name of Account Holder'
          value={formData.accountHolderName ?? ''}
          fieldError={inlineErrorState.accountHolderName}
          onChange={onChange}
          placeholder='i.e. John Doe'
          backgroundColor={Color.LIGHT_GREY}
          isRequired
        />
        <SelectorComponent
          label='Account Type'
          alignText='start'
          placeHolder='Select Account Type'
          fieldError={inlineErrorState.accountType}
          options={[AccountType.CHECKING, AccountType.SAVINGS]}
          value={formData.accountType}
          handleSelection={accountType => { updateForm('accountType', accountType) }}
          backgroundColor={Color.LIGHT_GREY}
          isRequired
        />
        <FormNumberInput
          fieldName='accountNumber'
          label='Account Number'
          value={formData.accountNumber ?? ''}
          fieldError={inlineErrorState.accountNumber}
          onChange={(value) => { updateForm('accountNumber', value) }}
          placeholder='i.e. 123456789'
          backgroundColor={Color.LIGHT_GREY}
          isRequired
        />
        <FormNumberInput
          fieldName='routingNumber'
          label='Routing Number'
          value={formData.routingNumber ?? ''}
          fieldError={inlineErrorState.routingNumber}
          onChange={(value) => { updateForm('routingNumber', value) }}
          placeholder='i.e. 123456789'
          backgroundColor={Color.LIGHT_GREY}
          isRequired
        />
        <FileUploadFormField
          franchiseGroupId={selectedFranchiseGroupId}
          label='Upload Document'
          secondaryLabel={
            formData.counterpartyType === CounterpartyType.EXTERNAL_VENDOR
              ? FILE_UPLOAD_SECONDARY_LABEL_VENDOR
              : FILE_UPLOAD_SECONDARY_LABEL_PERSONAL
          }
          fileType={FileUploadType.COUNTERPARTY_VERIFICATION_EVIDENCE}
          onUpdate={(fileUploadId) => { updateForm('fileUploadId', fileUploadId) }}
          fieldError={inlineErrorState.fileUploadId}
          isRequired
          organizationId={selectedOrganizationId}
        />
        <Flex gap={4}>
          <Radio
            isChecked={isWireFieldsVisible}
            onClick={() => { setIsWireFieldsVisible(!isWireFieldsVisible) }}
            size='lg'
            colorScheme='selectableInput'
          />
          <Flex gap={1}>
            <Text color={Color.DARK_BLUE}> Add Wire Details </Text>
            <Text color={Color.DARK_GREY}> (Optional) </Text>
          </Flex>
        </Flex>
        {isWireFieldsVisible &&
          <>
            <WireDetailsSwitchComponent
              onSwitch={(accountValue, routingValue) => {
                updateForm('wireAccountNumber', accountValue)
                updateForm('wireRoutingNumber', routingValue)
              }}
              achAccountNumber={formData.accountNumber ?? ''}
              achRoutingNumber={formData.routingNumber ?? ''}
            />
            <FormNumberInput
              fieldName='wireAccountNumber'
              label='Wire Account Number'
              value={formData.wireAccountNumber}
              fieldError={inlineErrorState.wireAccountNumber}
              onChange={value => { updateForm('wireAccountNumber', value) }}
              placeholder='i.e. 000000000'
              backgroundColor={Color.LIGHT_GREY}
            />
            <FormNumberInput
              fieldName='wireRoutingNumber'
              label='Wire Routing Number'
              value={formData.wireRoutingNumber}
              fieldError={inlineErrorState.wireRoutingNumber}
              onChange={value => { updateForm('wireRoutingNumber', value) }}
              placeholder='i.e. 000000000'
              backgroundColor={Color.LIGHT_GREY}
            />
            <AddressForm
              formName='Bank Address'
              subtext='Physical Address of the recipient’s banking institution'
              onUpdate={updateBankAddress}
              backgroundColor={Color.LIGHT_GREY}
            />
            <FormInput
              fieldName='bankName'
              label='Institution'
              value={formData.bankName ?? ''}
              fieldError={inlineErrorState.bankName}
              onChange={onChange}
              placeholder='i.e. Bank of America'
              backgroundColor={Color.LIGHT_GREY}
            />
            <AddressForm
              formName='Recipient Address'
              subtext='Physical address of the account holder'
              onUpdate={updateAddress}
              backgroundColor={Color.LIGHT_GREY}
            />
          </>
        }
      </Flex>
      <ErrorInline error={inlineError}/>
      <Center w='100%' flexDir='column' gap={4}>
        <Button
          text='Save & Continue'
          isDisabled={!isFormValidForSubmit()}
          onClick={handleSubmit}
        />
        <Button
          text='Cancel'
          onClick={onCancel}
          variant={ButtonVariant.WHITE_OUTLINE}
        />
      </Center>
    </Flex>
  )
}
