import { gql } from '@apollo/client'

export const GET_CAPITAL_OS_PAGE_DATA = gql`
    query GetCapitalOSPageData($organizationId: String!) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                id
                franchiseGroups {
                    id
                    name
                    capitalOSConfiguration {
                        account {
                            status
                            currentLimit {
                                amount
                            }
                            currentBalance {
                                amount
                            }
                        }
                    }
                }
            }
        }
    }
`
