import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { hasAmplifyAccount } from './utils'
import QuickAction from '@/library/nav/quick_action/QuickAction'
import RecurringRuleIcon from '@/library/icons/RecurringRuleIcon'
import TransferIconFilled from '@/library/icons/TransferIconFilled'
import CardsIcon from '@/library/icons/CardsIcon'
import ClockIcon from '@/library/icons/ClockIcon'
import UsersIcon from '@/library/icons/UsersIcon'
import LinkIcon from '@/library/icons/LinkIcon'
import { Color, IconSize } from '@/theme/theme'
import { ROUTES } from '@/api/routes'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { type GetHomePageData } from '@/graphql/__generated__/GetHomePageData'

interface QuickActionsSectionProps {
  data?: GetHomePageData | null
}

// @todo/tucker:
// recent transactions -- should load selected business
// manage users -- should deep link to user settings on business tab
// manage cards -- just link, no org state needed atm
export default function QuickActionsSection ({ data }: QuickActionsSectionProps): ReactElement {
  const hasAtLeastOneAmplifyAccount = hasAmplifyAccount(data)
  return (
    <AltirSkeleton isLoading={false} error={undefined}>
      <Text mb={4} color={Color.DARK_GREY}>Quick Actions</Text>
      <Flex flexDir='row' gap={3}>
        <Flex flexDir='column' gap={3} w='50%'>
          {
            hasAtLeastOneAmplifyAccount && <QuickAction
              isAmplifyRequired={true}
              text='Make a Transfer'
              link={ROUTES.TRANSFER}
              icon={
                <TransferIconFilled
                  size={IconSize.LARGE}
                  color={Color.BRIGHT_BLUE}
                />
              }
                                           />
          }
          <QuickAction
            icon={<LinkIcon size={IconSize.LARGE}/>}
            text='Link an Account'
            link={`${ROUTES.TREASURY}/business`}
            appendBusinessIdToRoute={true}
            urlState={{ isLinkAccountModalOpen: true }}
          />
          <QuickAction
            icon={<UsersIcon color={Color.BRIGHT_BLUE} size={IconSize.LARGE}/>}
            text='Manage Users'
            link={ROUTES.SETTINGS}
            isPopoverDisabled={true}
            urlState={{ isTeamSettingsSelected: true }}
          />
        </Flex>
        <Flex flexDir='column' gap={3} w='50%'>
          {
            hasAtLeastOneAmplifyAccount && <QuickAction
              isAmplifyRequired={true}
              text='Automate a Transfer'
              link={ROUTES.AUTOMATED_TRANSFERS}
              icon={
                <RecurringRuleIcon
                  size={IconSize.LARGE}
                  color={Color.BRIGHT_BLUE}
                />
              }
                                           />
          }
          <QuickAction
            icon={<ClockIcon color={Color.BRIGHT_BLUE} size={IconSize.LARGE}/>}
            text='Recent Transactions'
            link={ROUTES.TRANSACTIONS}
            urlState={{ shouldPreFilterOnSelectedBusiness: true }}
          />
          <QuickAction
            isPopoverDisabled={true}
            icon={<CardsIcon color={Color.BRIGHT_BLUE} size={IconSize.LARGE}/>}
            text='Manage Cards'
            link={ROUTES.CARDS}
          />
        </Flex>
      </Flex>
    </AltirSkeleton>
  )
}
