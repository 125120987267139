import { nonNull } from './arrayUtils'
import {
  type GetSettingsPageData_currentUser_selectedOrganization_franchiseGroups_financialAccounts
  as FinancialAccount
}
  from '../graphql/__generated__/GetSettingsPageData'

import { type FinancialDataPoint, type GroupedFinancialAccounts } from '../types/types'
import { CounterpartyType, InstitutionConnectionProvider } from '@/graphql/__generated__/globalTypes'
import {
  type GetAggregateEODBalancesForOrganization_currentUser_selectedOrganization_aggregateEODBalances as EODAccountBalance
} from '@/graphql/__generated__/GetAggregateEODBalancesForOrganization'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'

export function groupFinancialAccountsByInstitution (accounts: FinancialAccount[]): GroupedFinancialAccounts[] {
  // Create plaidAccessToken -> account[] map
  const map = new Map<string, FinancialAccount[]>()
  accounts.forEach(account => {
    const plaidAccessToken = account?.plaidAccessToken
    if (plaidAccessToken != null) {
      if (map.has(plaidAccessToken)) {
        map.get(plaidAccessToken)?.push(account)
      } else {
        map.set(plaidAccessToken, [account])
      }
    }
  })

  // Transform map into list of institutions and their respective accounts
  const result: GroupedFinancialAccounts[] = []
  map.forEach((groupedAccounts, plaidAccessToken) => {
    const firstAccount = groupedAccounts[0]
    if (
      firstAccount?.institution != null &&
      firstAccount?.status != null &&
      firstAccount?.plaidItemId != null &&
      firstAccount?.accountType != null &&
      firstAccount?.connectionProvider != null
    ) {
      result.push({
        accounts: groupedAccounts,
        institution: firstAccount.institution,
        plaidAccessToken,
        plaidItemId: firstAccount.plaidItemId,
        status: firstAccount.status,
        accountType: firstAccount.accountType,
        connectionProvider: firstAccount.connectionProvider
      })
    }
  })

  return result
}

export function EODAccountBalancesToFinancialDataPoint (
  EODAccountBalances: EODAccountBalance[]
): FinancialDataPoint[] {
  return nonNull(EODAccountBalances.map(b =>
    (b.balance?.amount != null && b.date != null)
      ? { amount: b.balance.amount, date: b.date }
      : undefined)
  )
}

export function getAccountString (account: FinancialAccount): string {
  // Cast to string for linter
  return `${String(getMask(account.lastFour))} | ${String(account.accountSubTypeFormatted ?? '')} | ${String(account.name ?? '')}`
}

/**
 * We frequently need to determine whether a given account is a legitimate bank account,
 * or an externally linked counterparty
 */
export function isLinkedBankAccountOrAmplifyAccount (account: FinancialAccountExtendedFragment): boolean {
  return isCounterpartyTypeLinkedBankAccount(account.counterparty?.counterpartyType) ||
    account.amplifyAccount?.id != null
}

/**
 * External accounts: Counterparty Nickname
 * Bank accounts: Account name
 */
export function getCounterpartyLongName (account?: FinancialAccountExtendedFragment): string | undefined {
  if (account == null) return undefined
  const isExternalAccount = !isLinkedBankAccountOrAmplifyAccount(account)
  return isExternalAccount
    ? getCounterpartyNickname(account)
    : account.name ?? undefined
}

/**
 * External accounts: Counterparty Nickname
 * Bank accounts: ***8765
 */
export function getCounterpartyShortName (account?: FinancialAccountExtendedFragment): string | undefined {
  if (account == null) return undefined
  const isExternalAccount = !isLinkedBankAccountOrAmplifyAccount(account)
  return isExternalAccount
    ? getCounterpartyNickname(account)
    : getBankAccountShortName(account.lastFour, account.institution?.name ?? null)
}

function getCounterpartyNickname (account: FinancialAccountExtendedFragment): string | undefined {
  return account.counterparty?.nickname ?? account.name ?? undefined
}

/**
 * Bank Account -> ***8765
 */
export function getBankAccountShortName (
  lastFour: string | null,
  institutionName: string | null
): string | undefined {
  return lastFour != null ? `${institutionName ?? ''} ${String(getMask(lastFour))}` : undefined
}

/**
 * 8765 -> ***8765
 */
export function getMask (lastFour: string | null): string | undefined {
  return lastFour != null ? lastFour.slice(-4).padStart(7, '*') : undefined
}

export function getConnectionProviderDescription (provider: InstitutionConnectionProvider): string {
  switch (provider) {
    case InstitutionConnectionProvider.PLAID:
      return 'Plaid'
    case InstitutionConnectionProvider.TELLER:
      return 'Teller'
    case InstitutionConnectionProvider.ALTIR:
      return 'Altir Connect'
    case InstitutionConnectionProvider.TREASURY_PRIME:
      return 'FirstBank'
  }
}

// TODO (PJ): In the long term we should more carefully discuss this logic.
// For now, it's no worse than it was before
export function isAccountValidForDebit (account: FinancialAccountExtendedFragment): boolean {
  if (account.plaidAccessToken != null) return true

  const counterpartyType = account.counterparty?.counterpartyType
  if (counterpartyType == null) return false

  return [CounterpartyType.PLAID, CounterpartyType.ALTIR_CONNECT].includes(counterpartyType)
}

// We exclude EXTERNAL_PERSONAL from this list because although they are bank accounts,
// their data is not "linked" to our platform
export function isCounterpartyTypeLinkedBankAccount (counterpartyType?: CounterpartyType): boolean {
  if (counterpartyType == null) return false

  return [
    CounterpartyType.PLAID,
    CounterpartyType.ALTIR_CONNECT
  ].includes(counterpartyType)
}

export function isCounterpartyTypeOwnedBankAccount (counterpartyType?: CounterpartyType): boolean {
  if (counterpartyType == null) return false

  return [
    CounterpartyType.PLAID,
    CounterpartyType.ALTIR_CONNECT,
    CounterpartyType.EXTERNAL_PERSONAL
  ].includes(counterpartyType)
}
