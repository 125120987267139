import React, { type ReactElement, useEffect, useRef } from 'react'
import lottie from 'lottie-web'
import { Box, Center } from '@chakra-ui/react'
import animationData from '../../../assets/animations/loader.json'

const BASE_LOGO_DIMENSIONS = '100px'
const BASE_PADDING = '0px'

interface LoaderProps {
  size?: string
  py?: string
  mx?: number
}

function Loader (
  {
    size = BASE_LOGO_DIMENSIONS,
    py = BASE_PADDING,
    mx = 0
  }: LoaderProps
): ReactElement {
  const containerRef = useRef(document.createElement('div'))

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData
    })
    return () => { anim.destroy() } // cleanup on unmount
  }, [animationData])

  return (
    <Center>
      <Box
        w={{ base: size, md: size }}
        h={{ base: size, md: size }}
        py={py}
        mx={mx}
      >
        <div ref={containerRef}></div>
      </Box>

    </Center>
  )
}

export default Loader
