import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import MonthlyInsightsSection from './components/MonthlyInsightsSection'
import FixedInsightsSection from './components/FixedInsightsSection'
import TotalAccountBalanceSection from './components/TotalAccountBalanceSection'
import { GET_INSIGHTS_PAGE_DATA } from '@/graphql/queries/GetInsightsData'
import {
  type GetInsightsPageDataVariables,
  type GetInsightsPageData
} from '@/graphql/__generated__/GetInsightsPageData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { ReLinkErrorBanner } from '@/library/errors/ReLinkErrorBanner'
import Page from '@/library/page/Page'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export default function InsightsPage (): ReactElement {
  const selectedFranchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)

  const {
    loading: isInsightsLoading,
    data: insightsData,
    error: insightsDataError
  } = useQuery<GetInsightsPageData, GetInsightsPageDataVariables>(
    GET_INSIGHTS_PAGE_DATA,
    {
      fetchPolicy: 'cache-and-network',
      variables: { franchiseGroupId: selectedFranchiseGroupId }
    }
  )

  const franchiseGroup = insightsData?.currentUser?.franchiseGroup
  const hasBrokenAccounts: boolean = franchiseGroup?.hasBrokenAccountLink === true

  return (
    <Page marginY={hasBrokenAccounts ? 0 : 16}>
      <Flex
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        width='100%'
      >
        <Flex flexDir='column' gap={4} w='100%'>
          {hasBrokenAccounts && <ReLinkErrorBanner franchiseGroupId={selectedFranchiseGroupId}/>}
          <AltirSkeleton isLoading={isInsightsLoading} error={insightsDataError}>
            <Flex flexDirection='column' gap={6} mb={6}>
              <TotalAccountBalanceSection
                franchiseGroupId={selectedFranchiseGroupId}
              />
              <FixedInsightsSection franchiseGroupId={selectedFranchiseGroupId}/>
              <MonthlyInsightsSection franchiseGroupId={selectedFranchiseGroupId}/>
            </Flex>
          </AltirSkeleton>
        </Flex>
      </Flex>
    </Page>
  )
}
