import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import PopoverMenu from '@/library/popoverMenu/components/PopoverMenu'
import PopoverMenuSingleOptionSelect, {
  type PopoverMenuItemContent
} from '@/library/popoverMenu/PopoverMenuSingleOptionSelect'
import { Role } from '@/graphql/__generated__/globalTypes'
import { getFormattedUserRole, getRoleDescription } from '@/utils/authUtils'
import { BorderRadius, FormInputHeight } from '@/theme/theme'

interface RoleSelectorProps {
  selectedRole?: Role
  availableRoles?: Role[]
  onChange: (role: Role) => void
}

export default function RoleSelector ({
  selectedRole,
  availableRoles = [Role.ADMIN, Role.MEMBER, Role.VIEWER, Role.NO_ACCESS],
  onChange
}: RoleSelectorProps): ReactElement {
  const formattedSelectedRole = selectedRole != null ? getFormattedUserRole(selectedRole) : undefined
  return (
    <Flex flexDir='column' w='100%' justifyContent='start' textAlign='start' gap={2}>
      <Text>
        Role
      </Text>
      <PopoverMenu
        popoverMenuTitle={formattedSelectedRole ?? 'Choose Role'}
        onSubmit={() => {}}
        borderRadius={String(BorderRadius.CARD)}
        border='0px'
        menuButtonHeight={FormInputHeight.DEFAULT}
      >
        <PopoverMenuSingleOptionSelect
          options={availableRoles}
          setSelectedOption={onChange}
          formattingFunction={getMenuItemContent}
        />
      </PopoverMenu>
    </Flex>
  )
}

function getMenuItemContent (role: Role): PopoverMenuItemContent {
  return { title: getFormattedUserRole(role), subtitle: getRoleDescription(role) }
}
