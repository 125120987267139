/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function BulletListIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M5 4.5H15' stroke={color} strokeWidth='1.5' strokeLinecap='round'/>
        <path d='M5 8H15' stroke={color} strokeWidth='1.5' strokeLinecap='round'/>
        <path d='M5 11.4995H15' stroke={color} strokeWidth='1.5' strokeLinecap='round'/>
        <circle cx='2' cy='4.5' r='1' fill={color}/>
        <circle cx='2' cy='8' r='1' fill={color}/>
        <circle cx='2' cy='11.5' r='1' fill={color}/>
      </svg>
    </IconContainer>
  )
}
