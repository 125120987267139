import React, { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import AmplifyFormGroupComponent from '../AmplifyFormGroupComponent'
import { AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import TreasuryIcon from '@/library/icons/TreasuryIcon'
import {
  AmplifyAccountApplicationStatus
} from '@/graphql/__generated__/globalTypes'
import { Color } from '@/theme/theme'
import { RouteParam, ROUTES } from '@/api/routes'
import { routeWithParams } from '@/utils/routerUtils'

export interface AccountUsageRowProps {
  franchiseGroupId: number
  applicationId?: string
  isDisabled: boolean
  existingFundingSources: string[]
  context: AmplifyApplicationContext
}

export default function AccountUsageRow (
  {
    applicationId,
    isDisabled,
    existingFundingSources,
    franchiseGroupId,
    context
  }: AccountUsageRowProps
): ReactElement {
  const navigate = useNavigate()
  function handleFormGroupClick (): void {
    if (isDisabled) return
    const link = routeWithParams(
      getRouteForContext(context),
      [
        {
          param: RouteParam.BUSINESS_ID,
          value: String(franchiseGroupId)
        },
        {
          param: RouteParam.AMPLIFY_APPLICATION_ID,
          value: String(applicationId)
        }
      ]
    )
    navigate(link)
  }

  const status = existingFundingSources.length > 0
    ? AmplifyAccountApplicationStatus.SUBMITTED
    : AmplifyAccountApplicationStatus.CREATED

  return (
    <AmplifyFormGroupComponent
      title='Amplify Account Overview'
      status={status}
      icon={<TreasuryIcon color={isDisabled ? Color.DARK_GREY : Color.DARK_BLUE}/>}
      handleClick={handleFormGroupClick}
      isDisabled={isDisabled}
    />
  )
}

function getRouteForContext (context: AmplifyApplicationContext): string {
  switch (context) {
    case AmplifyApplicationContext.DEFAULT:
      return ROUTES.AMPLIFY_APP_USAGE
    case AmplifyApplicationContext.ONBOARDING:
      return ROUTES.V2_SIGN_UP_AMPLIFY_APP_USAGE
  }
}
