import React, { useState, type ReactElement } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import EmailVerificationVerifyErrorContent from './components/EmailVerificationVerifyErrorContent'
import EmailVerificationVerifyLoadingContent from './components/EmailVerificationVerifyLoadingContent'
import { ROUTES } from '@/api/routes'
import { VERIFY_EMAIL_AND_ACCEPT_INVITE } from '@/graphql/mutations/onboarding/VerifyEmailAndAcceptInvite'
import {
  type VerifyEmailAndAcceptInvite,
  type VerifyEmailAndAcceptInviteVariables
} from '@/graphql/__generated__/VerifyEmailAndAcceptInvite'
import OnboardingPage from '@/library/page/OnboardingPage'
import {
  type GetOnboardingUserEmailVerificationStatus
} from '@/graphql/__generated__/GetOnboardingUserEmailVerificationStatus'
import {
  GET_ONBOARDING_USER_EMAIL_VERIFICATION_STATUS
} from '@/graphql/queries/onboarding/GetOnboardingUserEmailVerificationStatus'
import { ContainerWidth } from '@/theme/theme'
import { EmailVerificationStatus } from '@/graphql/__generated__/globalTypes'

export default function OnboardingEmailVerificationVerifyPage (): ReactElement {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')
  const email = searchParams.get('to')
  const [hasAttemptedVerification, setHasAttemptedVerification] = useState(false)

  const {
    data,
    loading: fetchUserLoading,
    error: fetchUserError
  } = useQuery<GetOnboardingUserEmailVerificationStatus>(
    GET_ONBOARDING_USER_EMAIL_VERIFICATION_STATUS,
    {
      onCompleted: (response) => {
        if (
          token != null &&
          email != null &&
          response.currentUser?.emailVerificationStatus !== EmailVerificationStatus.VERIFIED &&
          !hasAttemptedVerification
        ) {
          setHasAttemptedVerification(true)
          void verifyEmailAndAcceptInvite({ variables: { email, token } })
        }
      },
      onError: () => {},
      fetchPolicy: 'network-only'
    }
  )

  const [
    verifyEmailAndAcceptInvite,
    { loading: verifyEmailLoading, error: verifyEmailError }
  ] = useMutation<VerifyEmailAndAcceptInvite, VerifyEmailAndAcceptInviteVariables>(
    VERIFY_EMAIL_AND_ACCEPT_INVITE,
    {
      // If verification is successful,
      //    redirect to onboarding router which will resume onboarding from the correct place
      // If verification fails,
      //    redirect to org creation as we know that is the next logical step
      onCompleted: () => { navigate(ROUTES.V2_SIGN_UP) },
      onError: () => {}
    }
  )

  const isUrlParamsInvalid = token == null || email == null

  return (
    <OnboardingPage
      maxWidth={ContainerWidth.SMALL}
      isLoading={verifyEmailLoading || fetchUserLoading}
      error={fetchUserError}
    >
      {
        verifyEmailError != null || isUrlParamsInvalid
          ? <EmailVerificationVerifyErrorContent
              verifyEmailError={verifyEmailError}
              userId={Number(data?.currentUser?.id)}
            />
          : <EmailVerificationVerifyLoadingContent/>
      }
    </OnboardingPage>
  )
}
