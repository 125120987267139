
/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function SearchIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M12.3201 12.2484L9.38851 9.31677C10.2245 8.35537 10.6546 7.10693 10.588 5.83462C10.5215 4.56232 9.96358 3.36555 9.03187 2.49658C8.10016 1.62761 6.86746 1.15434 5.59361 1.17653C4.31976 1.19871 3.10428 1.71462 2.2034 2.61551C1.30251 3.51639 0.786604 4.73187 0.764418 6.00572C0.742232 7.27957 1.2155 8.51227 2.08447 9.44398C2.95344 10.3757 4.15021 10.9336 5.42252 11.0001C6.69482 11.0667 7.94326 10.6366 8.90466 9.80062L11.8363 12.7323C11.9012 12.7927 11.987 12.8256 12.0757 12.8241C12.1644 12.8225 12.249 12.7866 12.3118 12.7239C12.3745 12.6611 12.4104 12.5765 12.412 12.4878C12.4135 12.3991 12.3806 12.3133 12.3201 12.2484ZM1.46546 6.09985C1.46546 5.26476 1.71309 4.44842 2.17704 3.75407C2.64099 3.05972 3.30042 2.51854 4.07194 2.19897C4.84346 1.87939 5.69242 1.79578 6.51146 1.9587C7.33051 2.12161 8.08284 2.52375 8.67334 3.11424C9.26384 3.70474 9.66597 4.45708 9.82889 5.27612C9.99181 6.09516 9.90819 6.94412 9.58862 7.71564C9.26904 8.48716 8.72786 9.14659 8.03351 9.61055C7.33916 10.0745 6.52283 10.3221 5.68774 10.3221C4.56834 10.3208 3.49517 9.87549 2.70363 9.08395C1.9121 8.29241 1.46682 7.21925 1.46546 6.09985Z' fill={color} stroke={color} strokeWidth='0.832025'/>
      </svg>
    </IconContainer>
  )
}
