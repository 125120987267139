
import { gql } from '@apollo/client'

export const GET_ONBOARDING_BUSINESS_BY_ID = gql`
    query GetOnboardingBusinessById($franchiseGroupId: Int!) {
        franchiseGroup(franchiseGroupId: $franchiseGroupId) {
            id
            name
        }
    }
`
