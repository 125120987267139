import React, { useState, type ReactElement } from 'react'
import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Radio,
  Text
} from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import CloseIcon from '@/library/icons/CloseIcon'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { BorderRadius, Color } from '@/theme/theme'
import { FormInput } from '@/library/form/text/FormInput'

export interface PreSubmitModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (applicantName: string) => void
}

// eslint-disable-next-line max-len
const text = 'I hereby certify, to the best of my knowledge, that the information provided in this application is complete and correct.'

export default function PreSubmitModal (
  {
    isOpen,
    onClose,
    onSubmit
  }: PreSubmitModalProps
): ReactElement {
  const [isAttestationAccepted, setIsAttestationAccepted] = useState(false)
  const [applicantName, setApplicantName] = useState('')

  function handleApplicantNameChange (event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target
    setApplicantName(value)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      preserveScrollBarGap
    >
      <ModalOverlay/>
      <ModalContent
        borderRadius={BorderRadius.CARD}
      >
        <ModalBody
          display='flex'
          justifyContent='center'
          alignItems='center'
          borderRadius={BorderRadius.CARD}
          bg={Color.LIGHT_GREY}
        >
          <Flex
            flexDir='column'
            py={4}
          >
            <Flex
              w='100%'
              justifyContent='end'
              _hover={{ cursor: 'pointer' }}
              onClick={onClose}
            >
              <CloseIcon color={Color.DARK_GREY}/>
            </Flex>
            <Flex
              flexDir='column'
              px={6}
              justifyContent='center'
              alignItems='center'
              gap={6}
            >
              <Heading color={Color.DARK_BLUE} size='lg'>Submit Application</Heading>
              <FormInput
                fieldName='Applicant Full Name'
                label='Applicant Full Name'
                onChange={handleApplicantNameChange}
                placeholder='i.e. John Doe'
                value={applicantName}
              />
              <Flex gap={4} alignItems='start'>
                <Radio
                  isChecked={isAttestationAccepted}
                  onClick={() => { setIsAttestationAccepted(!isAttestationAccepted) }}
                  size='lg'
                  colorScheme='selectableInput'
                  pt={1}
                />
                <Text>{text}</Text>
              </Flex>
              <Flex w='100%' flexDir='column' gap={3}>
                <Button
                  text='Yes, Submit'
                  onClick={() => { onSubmit(applicantName) }}
                  size={ButtonSize.SMALL}
                  isDisabled={!isAttestationAccepted || applicantName === ''}
                  onClickEventType={Event.CONFIRM_SUBMIT_AMPLIFY_APPLICATION_CLICK}
                />
                <Button
                  text='Go Back'
                  onClick={onClose}
                  variant={ButtonVariant.WHITE}
                  size={ButtonSize.SMALL}
                  onClickEventType={Event.CLOSE_AMPLIFY_APPLICATION_SUBMISSION_MODAL_CLICK}
                />
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
