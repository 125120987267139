import React, { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { Event } from 'metrics/metrics'
import Button from './Button'
import TransferIcon from '../icons/TransferIcon'
import { ROUTES } from '@/api/routes'

export default function TransferLinkButton (): ReactElement {
  const navigate = useNavigate()
  return (
    <Button
      text='Transfer'
      beforeIcon={<TransferIcon/>}
      onClick={() => { navigate(ROUTES.TRANSFER) }}
      width='auto'
      onClickEventType={Event.BUTTON_CLICK}
    />
  )
}
