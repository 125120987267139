import React, { type ReactNode, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import Page from '@/library/page/Page'
import { Height } from '@/theme/theme'

interface CenteredInfoPageProps {
  children: ReactNode
}

export default function CenteredInfoPage ({ children }: CenteredInfoPageProps): ReactElement {
  return (
    <Page marginY={0}>
      <Flex
        flexDir='column'
        alignItems='center'
        textAlign='center'
        justifyContent='center'
        gap={6}
        h={`calc(100vh - ${Height.NAVBAR})`}
        marginTop={-12} // To re-center vertically based on Navbar
      >
        {children}
      </Flex>
    </Page>
  )
}
