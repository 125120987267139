import { gql } from '@apollo/client'

export const REMOVE_STORE_LOCATION = gql`
    mutation RemoveStoreLocation($storeLocationId: String!) {
        removeStoreLocation(storeLocationId: $storeLocationId){
            checkFieldIdentifier
            id
            name
            storeId
            posFieldIdentifier
        }
    }
`
