import React, { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { Center, Heading, Text } from '@chakra-ui/react'
import OnboardingPage from '@/library/page/OnboardingPage'
import { Graphic } from '@/library/utility/Graphic'
import { MULTIPLE_BUSINESS_GRAPHIC } from '@/theme/graphics'
import Button from '@/library/button/Button'
import { ROUTES } from '@/api/routes'
import { ContainerWidth } from '@/theme/theme'

export default function OnboardingBusinessIntroPage (): ReactElement {
  const navigate = useNavigate()

  return (
    <OnboardingPage maxWidth={ContainerWidth.MEDIUM}>
      <Graphic src={MULTIPLE_BUSINESS_GRAPHIC} h='130px'/>
      <Center gap={2} w='100%' flexDir='column' textAlign='center'>
        <Heading>Add one or more businesses</Heading>
        <Text>
          Add any business entities that are associated with your organization.
        </Text>
      </Center>
      <Button
        text='Continue'
        onClick={() => { navigate(ROUTES.V2_SIGN_UP_BUSINESS_CREATE) }}
      />
    </OnboardingPage>
  )
}
