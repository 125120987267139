import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { type OrganizationBillingComponentProps } from './OrganizationBillingComponent'
import { Color, BorderRadius } from '@/theme/theme'

export default function CardInfoComponent (subscriptionDetails?: OrganizationBillingComponentProps): ReactElement {
  const subscription = subscriptionDetails?.subscriptionDetails?.AltirSubscription
  const paymentDetails = subscription?.paymentDetails
  const lastFour = paymentDetails?.card?.last4
  const brand = paymentDetails?.card?.brand
  const expMonth = paymentDetails?.card?.expMonth
  const expYear = paymentDetails?.card?.expYear

  if (paymentDetails?.card == null) return <></>
  return (
    <Flex flexDir='column' w='full' gap={3}>
      <Text color={Color.DARK_GREY}>Card Information</Text>
      <Flex
        w='full'
        p={6}
        gap={1}
        borderRadius={BorderRadius.CARD}
        backgroundColor={Color.LIGHT_GREY}
      >
        <Text color={Color.DARK_BLUE}>{brand} **{lastFour}</Text>
        <Text>| Expires {expMonth}/{expYear}</Text>
      </Flex>
    </Flex>
  )
}
