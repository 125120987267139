import React, { useContext, type ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import UnsafeNavigateToErrorAndResetContext from './UnsafeNavigateToErrorAndResetContext'
import { ROUTES } from '@/api/routes'
import { AltirContext } from '@/hooks/store/useAltirStore'

export default function SafeNavigateToErrorPage (): ReactElement {
  const altirContext = useContext(AltirContext)
  if (altirContext != null) {
    return <UnsafeNavigateToErrorAndResetContext />
  }
  return <Navigate to={ROUTES.ERROR} />
}
