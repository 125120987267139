import React, { type ReactElement } from 'react'
import { Flex, Heading, ModalBody, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/api/routes'
import { Graphic } from '@/library/utility/Graphic'
import { HOUR_GLASS_GRAPHIC } from '@/library/theme/graphics'
import SupportEmailInlineLink from '@/library/text/SupportEmailInlineLink'
import Button from '@/library/button/Button'
import { Color, FontWeight } from '@/theme/theme'

export default function PendingContent (
): ReactElement {
  const navigate = useNavigate()
  return (
    <ModalBody>
      <Flex
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
        paddingTop={12}
        paddingBottom={6}
      >
        <Graphic
          w='auto'
          h='123px'
          mx='auto'
          src={HOUR_GLASS_GRAPHIC}
        />
        <Flex alignItems='center' flexDirection='column' mt={5} mb={8}>
          <Heading color={Color.DARK_BLUE} fontWeight={FontWeight.MEDIUM} size='lg' py={1}>
            Your application <br/> is under review.
          </Heading>
          <Text py={1} px={8}>
            We will email you when your result is ready. If you have questions, contact us at
            <SupportEmailInlineLink/>
          </Text>
        </Flex>
        <Button text='Done' onClick={() => { navigate(ROUTES.TREASURY) }}/>
      </Flex>
    </ModalBody>
  )
}
