import React, { type ReactElement } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import AltirContextProvider from './AltirContextOutlet'
import { useDefaultGroupContext } from './RequireAuthenticationLayout'
import { ROUTES } from '@/api/routes'
import { OnboardingStep } from '@/graphql/__generated__/globalTypes'

export default function AltirContextLayout (): ReactElement {
  const {
    userId,
    defaultFranchiseGroupId,
    defaultOrganizationId,
    defaultAvailableFranchiseGroupIds,
    onboardingStep
  } = useDefaultGroupContext()

  if (
    onboardingStep !== OnboardingStep.COMPLETE ||
    userId == null ||
    defaultOrganizationId == null ||
    defaultFranchiseGroupId == null ||
    defaultAvailableFranchiseGroupIds == null
  ) {
    return <Navigate to={ROUTES.V2_SIGN_UP} />
  }

  return (
    <AltirContextProvider
      userId={userId}
      defaultFranchiseGroupId={defaultFranchiseGroupId}
      defaultSelectedOrganizationState={{
        selectedOrganizationId: defaultOrganizationId,
        availableFranchiseGroupIds: defaultAvailableFranchiseGroupIds
      }}
    >
      <Outlet/>
    </AltirContextProvider>
  )
}
