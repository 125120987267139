/* eslint-disable max-len */
import { Icon } from '@chakra-ui/react'
import React, { type ReactNode, type ReactElement } from 'react'
import { type IconSize } from '../theme/theme'

export interface IconContainerProps {
  size: IconSize
  children: ReactNode
}

export default function IconContainer ({ size, children }: IconContainerProps): ReactElement {
  return (
    <Icon boxSize={size} display='flex' alignItems='center' justifyContent='center'>
      {children}
    </Icon>
  )
}
