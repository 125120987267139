import React, { useState, type ReactElement } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Center } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import BusinessApplicationFormComponent, { type BusinessApplicationFormData } from './BusinessApplicationFormComponent'
import { type AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import { UPDATE_AMPLIFY_ACCOUNT_BUSINESS_APPLICATION } from '@/graphql/mutations/AmplifyApplicationMutations'
import {
  AmplifyApplicationStep,
  createUpdateBusinessApplicationVariables,
  getAmplifyApplicationRouteForContext
} from '@/utils/amplifyApplicationUtils'
import {
  type UpdateAmplifyAccountBusinessApplicationVariables,
  type UpdateAmplifyAccountBusinessApplication
} from '@/graphql/__generated__/UpdateAmplifyAccountBusinessApplication'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import
AmplifyApplicationHeader
  from '@/components/pages/v2/sign_up/routes/amplify/routes/components/AmplifyApplicationHeader'
import { GET_AMPLIFY_BUSINESS_APPLICATION } from '@/graphql/queries/amplify_application/GetAmplifyBusinessApplication'
import {
  type GetAmplifyBusinessApplication,
  type GetAmplifyBusinessApplicationVariables
} from '@/graphql/__generated__/GetAmplifyBusinessApplication'
import { routeWithParams } from '@/utils/routerUtils'
import { RouteParam } from '@/api/routes'

export interface BusinessApplicationComponentProps {
  franchiseGroupId: number
  businessApplicationId: string
  backLink: string
  context: AmplifyApplicationContext
}

export default function BusinessApplicationComponent (
  {
    franchiseGroupId,
    businessApplicationId,
    backLink,
    context
  }: BusinessApplicationComponentProps
): ReactElement {
  const navigate = useNavigate()

  const {
    data,
    loading,
    error
  } = useQuery<GetAmplifyBusinessApplication, GetAmplifyBusinessApplicationVariables>(
    GET_AMPLIFY_BUSINESS_APPLICATION,
    { variables: { franchiseGroupId: Number(franchiseGroupId) } }
  )

  const [submissionError, setSubmissionError] = useState<Error | undefined>()
  const [
    updateBusinessApplication,
    { loading: updateBusinessApplicationLoading }
  ] = useMutation<UpdateAmplifyAccountBusinessApplication, UpdateAmplifyAccountBusinessApplicationVariables>(
    UPDATE_AMPLIFY_ACCOUNT_BUSINESS_APPLICATION,
    {
      onCompleted: () => {
        // Navigate to account usage page
        const applicationId = String(data?.franchiseGroup?.amplifyAccountApplication?.id)
        const baseRoute = getAmplifyApplicationRouteForContext(AmplifyApplicationStep.ACCOUNT_USAGE, context)
        navigate(routeWithParams(
          baseRoute,
          [
            { param: RouteParam.BUSINESS_ID, value: String(franchiseGroupId) },
            { param: RouteParam.AMPLIFY_APPLICATION_ID, value: applicationId }
          ]
        ))
      },
      onError: (error) => {
        setSubmissionError(error)
      }
    }
  )

  async function handleSubmission (formData: BusinessApplicationFormData): Promise<void> {
    const variables = createUpdateBusinessApplicationVariables(formData, businessApplicationId)
    void updateBusinessApplication({ variables })
  }

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center flexDir='column' gap={8} w='100%'>
        <AmplifyApplicationHeader
          backLink={backLink}
          businessName={data?.franchiseGroup?.name ?? null}
          header='Business Profile'
        />
        <BusinessApplicationFormComponent
          isSubmissionLoading={updateBusinessApplicationLoading}
          businessApplication={data?.amplifyBusinessApplication}
          handleSubmit={handleSubmission}
          handleCancel={() => { navigate(backLink) }}
          submissionError={submissionError}
        />
      </Center>
    </AltirSkeleton>
  )
}
