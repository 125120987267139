import React, { type ReactElement } from 'react'
import { Flex, Heading, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import Loader from '@/library/loading/Loader'
import { BorderRadius, Color } from '@/library/theme/theme'

interface AccountLinkLoaderModalProps {
  isOpen: boolean
}

export default function AccountLinkLoaderModal ({ isOpen }: AccountLinkLoaderModalProps): ReactElement {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      isCentered={true}
      preserveScrollBarGap
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent
        flexDirection='column'
        borderRadius={BorderRadius.CARD}
        bg={Color.LIGHT_GREY}
        alignItems='center'
        justifyContent='center'
        width='100%'
        p={12}
        gap={8}
      >
        <Loader/>
        <Flex flexDir='column' w='100%' alignItems='center' gap={2}>
          <Heading size='lg' color={Color.DARK_BLUE}>Please Wait...</Heading>
          <Text
            textAlign='center'
            color={Color.DARK_GREY}
          >
            Your accounts are being linked. This can take up to a few minutes.
          </Text>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
