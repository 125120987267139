import React, { type ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { ROUTES } from '@/api/routes'
import { clearAltirStoreFromOutsideOfStoreContext } from '@/hooks/store/useAltirStore'

/**
 * This page serves to clear the Altir cache (from outside of the store context)
 *  and redirect the user back to the index route.
 *
 * We employee this strategy for resetting the state to avoid a cleared store getting
 *  reset via cached Apollo data
 */
export default function ResetStatePage (): ReactElement {
  clearAltirStoreFromOutsideOfStoreContext()
  return <Navigate to={ROUTES.INDEX}/>
}
