import React, { type ReactElement, type ReactNode } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'

import { authConfig } from '../../config/authConfig'

export default function Provider ({
  children
}: {
  children: ReactNode
}): ReactElement {
  return (
    <Auth0Provider
      domain={authConfig.domain}
      clientId={authConfig.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: authConfig.audience,
        connection: authConfig.connection
      }}
      useRefreshTokens
      cacheLocation={authConfig.cacheLocation}
    >
      {children}
    </Auth0Provider>
  )
}
