import React, { useEffect, useState, type ReactElement } from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import { type Location, useLocation } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { getPageTitles, PageTitle as PageTitleName } from '@/utils/routerUtils'
import { Color } from '@/theme/theme'
import { GET_BUSINESS_NAME } from '@/graphql/queries/business/GetBusinessName'
import { type GetBusinessNameVariables, type GetBusinessName } from '@/graphql/__generated__/GetBusinessName'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { GET_FINANCIAL_ACCOUNT_NAME } from '@/graphql/queries/accounts/GetFinancialAccountName'
import {
  type GetFinancialAccountName,
  type GetFinancialAccountNameVariables
} from '@/graphql/__generated__/GetFinancialAccountName'
import { nonNull } from '@/utils/arrayUtils'

export default function PageTitle (): ReactElement {
  const location = useLocation()
  const [dynamicPageTitle, setDynamicPageTitle] = useState<string>()
  const [
    getBusinessName, { loading: businessNameLoading }
  ] = useLazyQuery<GetBusinessName, GetBusinessNameVariables>(
    GET_BUSINESS_NAME,
    {
      onCompleted: (data) => {
        setDynamicPageTitle(data.franchiseGroup?.name ?? undefined)
      },
      onError: () => {}
    }
  )
  const [
    getFinancialAccountName, { loading: financialAccountNameLoading }
  ] = useLazyQuery<GetFinancialAccountName, GetFinancialAccountNameVariables>(GET_FINANCIAL_ACCOUNT_NAME,
    {
      onCompleted: (data) => {
        setDynamicPageTitle(data.financialAccount?.name ?? undefined)
      },
      onError: () => {}
    }
  )

  useEffect(() => {
    const { pageTitles, resourceId } = getPageTitles(location)
    if (pageTitles[0] === PageTitleName.Home && resourceId != null) {
      void getBusinessName({ variables: { franchiseGroupId: Number(resourceId) } })
    } else if (pageTitles[0] === PageTitleName.Accounts && resourceId != null) {
      void getFinancialAccountName({ variables: { accountId: Number(resourceId) } })
    } else {
      setDynamicPageTitle(undefined)
    }
  }, [location, getBusinessName, getFinancialAccountName])

  const pageTitlesToRender = createDynamicPageTitles(location, dynamicPageTitle)

  return (
    <AltirSkeleton isLoading={businessNameLoading || financialAccountNameLoading}>
      <Flex alignItems='center' gap={2}>
        {
            pageTitlesToRender.map((title, index) => {
              const isFinalName = index === pageTitlesToRender.length - 1
              return (
                <Flex key={index} gap={2} alignItems='center'>
                  <Heading
                    size='sm'
                    color={isFinalName ? Color.DARK_BLUE : Color.DARK_GREY}
                  >
                    {title}
                  </Heading>
                  {
                    !isFinalName && <Heading size='sm' color={Color.MEDIUM_GREY}>|</Heading>
                  }
                </Flex>
              )
            })
        }
      </Flex>
    </AltirSkeleton>
  )
}

function createDynamicPageTitles (location: Location, dynamicTitle: string | null | undefined): string[] {
  const { pageTitles } = getPageTitles(location)
  return nonNull(pageTitles.map(title => {
    return title === PageTitleName.RESOURCE ? dynamicTitle : title
  }))
}
