import React, { useEffect, useState, type ReactElement } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { AmplifyApplicationPostSubmitModal } from './components/postSubmitModal/AmplifyApplicationPostSubmitModal'
import AmplifyApplicationComponent from './components/AmplifyApplicationComponent'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import {
  type GetAmplifyAccountApplication,
  type GetAmplifyAccountApplicationVariables
} from '@/graphql/__generated__/GetAmplifyAccountApplication'
import {
  GET_AMPLIFY_ACCOUNT_APPLICATION
} from '@/graphql/queries/amplify_application/GetAmplifyAccountApplication'
import { CREATE_AMPLIFY_ACCOUNT_APPLICATION } from '@/graphql/mutations/AmplifyApplicationMutations'
import {
  type CreateAmplifyAccountApplication,
  type CreateAmplifyAccountApplicationVariables
} from '@/graphql/__generated__/CreateAmplifyAccountApplication'
import { AmplifyAccountApplicationStatus } from '@/graphql/__generated__/globalTypes'
import { isAmplifyApplicationSubmitted } from '@/utils/amplifyApplicationUtils'

export enum AmplifyApplicationContext {
  ONBOARDING,
  DEFAULT
}

interface AmplifyApplicationContainerComponentProps {
  franchiseGroupId: number
  context: AmplifyApplicationContext
}

export default function AmplifyApplicationContainerComponent ({
  franchiseGroupId,
  context
}: AmplifyApplicationContainerComponentProps): ReactElement {
  // Explicitly prevent UI from attempting to create more than one application
  const [hasCreatedApplication, setHasCreatedApplication] = useState(false)

  const {
    data: applicationData,
    loading: applicationLoading,
    error: applicationFetchError,
    refetch: refetchAmplifyApplication
  } = useQuery<GetAmplifyAccountApplication, GetAmplifyAccountApplicationVariables>(
    GET_AMPLIFY_ACCOUNT_APPLICATION,
    {
      variables: { franchiseGroupId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  )
  const [
    createAmplifyApplicationMutation,
    { loading: createApplicationLoading }
  ] =
    useMutation<CreateAmplifyAccountApplication, CreateAmplifyAccountApplicationVariables>(
      CREATE_AMPLIFY_ACCOUNT_APPLICATION,
      {
        onCompleted: () => {
          setHasCreatedApplication(true)
        },
        onError: () => {} // TODO (PJ): Handle error
      }
    )

  useEffect(() => {
    // If an application hasn't yet been created for the franchise group,
    // create one before rendering the component
    const applicationDoesNotExist = applicationData
      ?.currentUser
      ?.franchiseGroup
      ?.amplifyAccountApplication
      ?.status === AmplifyAccountApplicationStatus.DOES_NOT_EXIST
    if (!hasCreatedApplication && applicationDoesNotExist) {
      void createAmplifyApplicationMutation({ variables: { franchiseGroupId } })
    }
    if (applicationDoesNotExist) {
      void refetchAmplifyApplication()
    }
  }, [
    refetchAmplifyApplication,
    hasCreatedApplication,
    applicationData,
    franchiseGroupId,
    createAmplifyApplicationMutation
  ])

  const isLoading = applicationLoading || createApplicationLoading
  const application = applicationData?.currentUser?.franchiseGroup?.amplifyAccountApplication

  // We hide loading skeleton once app is submitted since we continually refetch data
  const isSubmitted = isAmplifyApplicationSubmitted(application?.status)
  return (
    <AltirSkeleton
      isLoading={isLoading && !isSubmitted}
      error={applicationFetchError}
    >
      <AmplifyApplicationPostSubmitModal
        applicationStatus={application?.status ?? undefined}
        refetchApplication={refetchAmplifyApplication}
      />
      <AmplifyApplicationComponent
        franchiseGroupId={franchiseGroupId}
        franchiseGroup={applicationData?.currentUser?.franchiseGroup ?? undefined}
        amplifyApplication={application}
        refetchApplicationData={refetchAmplifyApplication}
        context={context}
      />
    </AltirSkeleton>
  )
}
