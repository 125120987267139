/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function MailIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M1.01953 1.46973H10.9807V1.86865C10.9781 1.87033 10.9755 1.87204 10.973 1.87378L6.28013 5.04438C6.11102 5.15864 5.88943 5.15864 5.72031 5.04438L1.02747 1.87378C1.02484 1.872 1.02219 1.87025 1.01953 1.86852V1.46973ZM1.01953 3.07526V8.53809H10.9807V3.07539L6.83996 5.87298C6.3326 6.21577 5.66783 6.21577 5.16048 5.87298L1.01953 3.07526ZM0.0195312 1.46973C0.0195312 0.917442 0.467247 0.469727 1.01953 0.469727H10.9807C11.533 0.469727 11.9807 0.917442 11.9807 1.46973V8.53809C11.9807 9.09037 11.533 9.53809 10.9807 9.53809H1.01953C0.467247 9.53809 0.0195312 9.09037 0.0195312 8.53809V1.46973Z'/>
      </svg>
    </IconContainer>
  )
}
