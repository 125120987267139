import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import DownloadLink from '@/library/button/DownloadLink'
import { Color } from '@/theme/theme'

interface DisclosureLineProps {
  name: string
  link: string
}

export default function DisclosureLine ({ name, link }: DisclosureLineProps): ReactElement {
  return (
    <Flex justifyContent='space-between' alignItems='center' w='100%'>
      <Text color={Color.DARK_BLUE}>{name}</Text>
      <DownloadLink href={link}/>
    </Flex>
  )
}
