import React, { useEffect, useState, type ReactElement } from 'react'
import { Flex, Text, useToast } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import RoleSelector from '../business_settings/team_settings/RoleSelector'
import { DefaultModalComponent } from '@/library/modal/DefaultModalComponent'
import { UserRoleContext, type NullableUser } from '@/library/layout/user_row/UserInfoRow'
import { type Role } from '@/graphql/__generated__/globalTypes'
import { Color } from '@/theme/theme'
import { getFormattedFullName } from '@/utils/stringUtils'
import { getSuccessToast } from '@/utils/toastUtils'
import {
  type UpdateUserOrganizationRoles,
  type UpdateUserOrganizationRolesVariables
} from '@/graphql/__generated__/UpdateUserOrganizationRoles'
import { UPDATE_USER_ORGANIZATION_ROLES } from '@/graphql/mutations/settings/UpdateUserOrganizationRoles'
import UserBusinessDescriptorRow from '@/library/layout/user_row/components/UserBusinessDescriptorRow'
import { UPDATE_USERS_ROLES } from '@/graphql/mutations/settings/UpdateUsersRoles'
import { type UpdateUsersRoles, type UpdateUsersRolesVariables } from '@/graphql/__generated__/UpdateUsersRoles'
import ErrorInline from '@/library/errors/ErrorInline'
import { type ErrorWithContent } from '@/types/types'
import { ErrorCopy } from '@/utils/errorUtils'

export interface EntityRole {
  entityName: string | null
  organizationId: string | null
  businessId: number | null
  role: Role | null
  context: UserRoleContext
}

interface EditUserRoleModalProps {
  isOpen: boolean
  onClose: () => void
  onSuccessfulUpdate: () => void

  user: NullableUser
  existingRole: EntityRole | null
}

export default function EditUserRoleModal ({
  user,
  existingRole,
  onSuccessfulUpdate,
  isOpen,
  onClose
}: EditUserRoleModalProps): ReactElement {
  const toast = useToast()
  const [selectedUserRole, setSelectedUserRole] = useState<Role | undefined>(existingRole?.role ?? undefined)
  const [editUserSubmissionError, setEditUserSubmissionError] = useState<ErrorWithContent>()

  const [
    updateUsersRoles,
    { loading: isUpdateUsersRolesLoading }
  ] = useMutation<UpdateUsersRoles, UpdateUsersRolesVariables>(
    UPDATE_USERS_ROLES, {
      onCompleted: () => {
        onSuccessfulUpdate()
        toast(getSuccessToast('User updated'))
        onClose()
      },
      onError: error => {
        setEditUserSubmissionError(getErrorContent(error))
      }
    }
  )
  const [
    updateUserOrganizationRoles,
    { loading: isUpdateUserOrganizationRolesLoading }
  ] = useMutation<UpdateUserOrganizationRoles, UpdateUserOrganizationRolesVariables>(UPDATE_USER_ORGANIZATION_ROLES, {
    onCompleted: () => {
      onSuccessfulUpdate()
      toast(getSuccessToast('User updated'))
      onClose()
    },
    onError: error => {
      setEditUserSubmissionError(getErrorContent(error))
    }
  })

  useEffect(() => {
    setSelectedUserRole(existingRole?.role ?? undefined)
  }, [existingRole])

  function handleClose (): void {
    setEditUserSubmissionError(undefined)
    setSelectedUserRole(undefined)
    onClose()
  }

  function handleUpdateUserOrganizationRole (): void {
    if (
      user.id == null ||
      user.organizationRole?.organizationId == null ||
      selectedUserRole == null
    ) {
      setEditUserSubmissionError(getErrorContent())
      return
    }

    void updateUserOrganizationRoles({
      variables: {
        userIds: [user.id],
        organizationIds: [user.organizationRole.organizationId],
        role: selectedUserRole
      }
    })
  }

  function handleUpdateUserBusinessRole (): void {
    if (
      user?.id == null ||
        existingRole?.businessId == null ||
        selectedUserRole == null
    ) {
      setEditUserSubmissionError(getErrorContent())
      return
    }
    void updateUsersRoles({
      variables: {
        userIds: [user.id],
        franchiseGroupIds: [existingRole?.businessId],
        role: selectedUserRole
      }
    })
  }

  function handleUserEditSubmission (): void {
    if (existingRole?.context == null) {
      setEditUserSubmissionError(getErrorContent())
      return
    }

    switch (existingRole?.context) {
      case UserRoleContext.BUSINESS:
        handleUpdateUserBusinessRole()
        return
      case UserRoleContext.ORGANIZATION:
        handleUpdateUserOrganizationRole()
    }
  }

  return (
    <DefaultModalComponent
      title="Edit User's Role"
      callToActionText='Save Changes'
      callToActionClick={handleUserEditSubmission}
      isOpen={isOpen}
      onClose={handleClose}
      isLoading={isUpdateUsersRolesLoading || isUpdateUserOrganizationRolesLoading}
    >
      <Flex w='100%' flexDir='column' gap={4} pb={2}>
        <Flex flexDir='column' gap={6}>
          <Flex flexDir='column'>
            <Text color={Color.DARK_BLUE}>{getFormattedFullName(user?.firstName, user?.lastName)}</Text>
            <Text>{user?.email}</Text>
          </Flex>
          <UserBusinessDescriptorRow entityName={existingRole?.entityName}/>
        </Flex>
        <RoleSelector
          onChange={(role) => { setSelectedUserRole(role) }}
          selectedRole={selectedUserRole}
        />
        <ErrorInline error={editUserSubmissionError}/>
      </Flex>
    </DefaultModalComponent>
  )
}

function getErrorContent (error?: Error): ErrorWithContent {
  return {
    customContent: { title: 'Unable to update user\'s role', subtitle: ErrorCopy.TRY_AGAIN_LATER },
    error: error ?? Error()
  }
}
