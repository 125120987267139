import React, { type ReactElement } from 'react'
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import AltirProUpgradeContent from './AltirProUpgradeContent'
import {
  BorderRadius
} from '../theme/theme'

export enum AltirProUpgradeModalVariant {
  DASHBOARD,
  AMPLIFY,
  LENDING
}

export interface AltirProUpgradeModalProps {
  organizationId: string
  isOpen: boolean
  onClose: () => void
  productVariant?: AltirProUpgradeModalVariant
}

export default function AltirProUpgradeModal ({
  organizationId,
  onClose,
  isOpen,
  productVariant = AltirProUpgradeModalVariant.DASHBOARD
}: AltirProUpgradeModalProps): ReactElement {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      size='2xl'
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent
        flexDirection='column'
        borderRadius={BorderRadius.CARD}
        alignItems='center'
        justifyContent='center'
        width='100%'
      >
        <AltirProUpgradeContent
          organizationId={organizationId}
          productVariant={productVariant}
        />
      </ModalContent>
    </Modal>
  )
}
