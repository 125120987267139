import React, { useState, type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { AccountSelectorModal } from './AccountSelectorModal'
import { SelectableAccountRow } from './account_row/SelectableAccountRow'
import { augmentAccountsWithEnabledStates } from './utils'
import { Color } from '@/theme/theme'
import { type TransferType } from '@/graphql/__generated__/globalTypes'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'

export interface AccountSelectorComponentProps {
  altirAccountOptions: FinancialAccountExtendedFragment[]
  nonAltirAccountOptions: FinancialAccountExtendedFragment[]
  selectedAccount?: FinancialAccountExtendedFragment
  selectedOppositeSideOfTransferAccount: FinancialAccountExtendedFragment | null
  onSelectedAccountChange?: (account: FinancialAccountExtendedFragment | null) => void
  refetch?: () => void
  context: AccountSelectorContext
  accountContext: AccountSelectorAccountContext
  transferType: TransferType | null

  headerText: string
  headerColor?: Color
}

export enum AccountSelectorContext {
  TRANSFER,
  TRANSFER_RULE
}

export enum AccountSelectorAccountContext {
  FROM,
  TO
}

export default function AccountSelectorComponent (
  {
    altirAccountOptions,
    nonAltirAccountOptions,
    onSelectedAccountChange,
    selectedAccount,
    selectedOppositeSideOfTransferAccount,
    headerText,
    headerColor = Color.DARK_GREY,
    refetch = () => {},
    context,
    accountContext,
    transferType
  }: AccountSelectorComponentProps
): ReactElement {
  const [isAccountSelectorModalOpen, setIsAccountSelectorModalOpen] = useState(false)
  function handleInputClick (): void {
    if (nonAltirAccountOptions != null) {
      setIsAccountSelectorModalOpen(true)
    }
  }

  function handleAccountSelection (account: FinancialAccountExtendedFragment): void {
    if (onSelectedAccountChange != null) {
      onSelectedAccountChange(account)
    }
    setIsAccountSelectorModalOpen(false)
  }

  function handleAccountClear (): void {
    if (onSelectedAccountChange != null) {
      onSelectedAccountChange(null)
    }
  }

  return (
    <Flex width='100%' flexDirection='column' gap={2}>
      <Text color={headerColor}>{headerText}</Text>
      <SelectableAccountRow
        onClick={handleInputClick}
        account={selectedAccount}
        onClearSelection={handleAccountClear}
      />
      <AccountSelectorModal
        isOpen={isAccountSelectorModalOpen}
        onClose={() => { setIsAccountSelectorModalOpen(false) }}
        onSelectCounterParty={handleAccountSelection}
        altirAccounts={
          augmentAccountsWithEnabledStates(
            altirAccountOptions,
            selectedOppositeSideOfTransferAccount,
            transferType
          )
        }
        otherAccounts={
          augmentAccountsWithEnabledStates(
            nonAltirAccountOptions ?? [],
            selectedOppositeSideOfTransferAccount,
            transferType,
            accountContext
          )
        }
        selectedAccount={selectedAccount}
        selectedOppositeSideOfTransferAccount={selectedOppositeSideOfTransferAccount}
        refetch={refetch}
        context={context}
        accountContext={accountContext}
      />
    </Flex>
  )
}
