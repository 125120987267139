import React, { useState, type ReactElement } from 'react'
import AddBankingDetailsForm, { type AddBankingDetailsFormData } from './AddBankingDetailsForm'
import DropDownComponent from '@/library/layout/drop_down/DropDownComponent'
import TreasuryIcon from '@/library/icons/TreasuryIcon'
import { Color } from '@/theme/theme'

interface RecipientBankingDetailsDropdownProps {
  globalFormData: AddBankingDetailsFormData | null
  onSubmit: (formData: AddBankingDetailsFormData) => void
}

export default function RecipientBankingDetailsDropdown ({
  globalFormData,
  onSubmit
}: RecipientBankingDetailsDropdownProps): ReactElement {
  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false)

  function handleFormSubmission (newFormData: AddBankingDetailsFormData): void {
    onSubmit(newFormData)
    setIsDropdownExpanded(false)
  }

  return (
    <DropDownComponent
      title='ACH/Wire Recipient Details'
      icon={<TreasuryIcon color={Color.DARK_BLUE}/>}
      isOpenFromParent={isDropdownExpanded}
      setIsOpenFromParent={(isOpen) => { setIsDropdownExpanded(isOpen) }}
      rightDescription={globalFormData != null ? 'Complete' : undefined}
      isComplete={globalFormData != null}
      dropDownContent={
        <AddBankingDetailsForm
          globalFormData={globalFormData}
          onSubmit={handleFormSubmission}
          onCancel={() => { setIsDropdownExpanded(false) }}
        />
        }
    />
  )
}
