import React, { type ReactElement } from 'react'
import BulletedList from '@/library/text/BulletedList'

const MSB_DESCRIPTION = 'A registered or unregistered MSB'
const BROKER_DEALER_DESCRIPTION = 'Registered with the SEC as a broker/dealer'
const FINANCIAL_SERVICES_COMPANY_DESCRIPTION = `
  A financial services company involved in the flow of funds for its customers, a currency dealer, deposit broker or other regulated financial service
`
const GAMBLING_BUSINESS_DESCRIPTION = 'An internet gambling, Casino or gaming business'
const ADULT_ENTERTAINMENT_DESCRIPTION = 'An adult entertainment business'
const CANNABIS_DESCRIPTION = 'Engaged in the cannabis business either directly or indirectly'
const CRYPTO_COMPANY_DESCRIPTION = `
  A business engaged in the crypto industry either directly or indirectly including virtual currency ATM owners and operators, or businesses that receive more than 50% of their revenue from virtual currencies
`
const EMBASSY_DESCRIPTION = 'An Embassy, Mission, Consulate or Foreign Government Account'

export default function BlackListedCompanyTypesListComponent (): ReactElement {
  const companyTypes = [
    MSB_DESCRIPTION,
    BROKER_DEALER_DESCRIPTION,
    FINANCIAL_SERVICES_COMPANY_DESCRIPTION,
    GAMBLING_BUSINESS_DESCRIPTION,
    ADULT_ENTERTAINMENT_DESCRIPTION,
    CANNABIS_DESCRIPTION,
    CRYPTO_COMPANY_DESCRIPTION,
    EMBASSY_DESCRIPTION
  ]
  return (
    <BulletedList items={companyTypes}/>
  )
}
