import React, { type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import CloudErrorIcon from '../icons/CloudErrorIcon'
import Button from '../button/Button'
import LeftArrowIcon from '../icons/LeftArrowIcon'
import { ROUTES } from '@/api/routes'
import { Color, IconSize } from '@/theme/theme'

export default function OnboardingErrorContent (): ReactElement {
  const navigate = useNavigate()
  return (
    <Center flexDir='column' gap={4}>
      <CloudErrorIcon color={Color.DARK_GREY} size={IconSize.X_LARGE}/>
      <Center flexDir='column' gap={1} textAlign='center'>
        <Heading size='md'>
          Something went wrong
        </Heading>
        <Text>
          We are having trouble loading this content. <br/>
          Please refresh the page and try again.
        </Text>
      </Center>
      <Button
        text='Go back'
        beforeIcon={<LeftArrowIcon/>}
        onClick={() => { navigate(ROUTES.V2_SIGN_UP) }}
        width='auto'
      />
    </Center>
  )
}
