import React, { type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import LeftArrowIcon from '@/library/icons/LeftArrowIcon'
import { IconSize } from '@/theme/theme'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { ROUTES } from '@/api/routes'
import FinancialAccountDropDownComponent from '@/library/accounts/FinancialAccountDropDownComponent'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'

interface AccountUpgradeAccountsOverviewProps {
  amplifyAccounts: FinancialAccountExtendedFragment[]
  onBack: () => void
  onContinue: () => void
}

export default function AccountUpgradeAccountsOverview ({
  amplifyAccounts,
  onBack,
  onContinue
}: AccountUpgradeAccountsOverviewProps): ReactElement {
  const navigate = useNavigate()
  return (
    <Center flexDir='column' gap={8} w='100%'>
      <Button
        text='Back'
        beforeIcon={<LeftArrowIcon size={IconSize.SMALL}/>}
        variant={ButtonVariant.GREY}
        onClick={onBack}
        width='auto'
        size={ButtonSize.SMALL}
      />
      <Center flexDir='column' textAlign='center' w='100%' gap={1}>
        <Heading>The following {amplifyAccounts.length > 1 ? 'accounts' : 'account'} will be upgraded.</Heading>
        <Text>
          We will transfer funds from{' '}
          {amplifyAccounts.length > 1
            ? 'these Altir accounts into your new Altir accounts'
            : 'this Altir account into your new Altir account'}.
          Your routing number and account number will change,
          but your transaction history and statements will remain accessible.
        </Text>
      </Center>
      <Center flexDir='column' gap={2} w='100%'>
        {
            amplifyAccounts.map((account, index) => {
              return (
                <FinancialAccountDropDownComponent
                  key={index}
                  account={account}
                  isTransactionRefreshEnabled={true}
                  refetch={() => {}}
                />
              )
            })
        }
      </Center>
      <Center flexDir='column' textAlign='center' w='100%' gap={4}>
        <Button
          text='Continue'
          afterIcon={<RightArrowIcon/>}
          onClick={onContinue}
        />
        <Button text='Cancel' variant={ButtonVariant.WHITE} onClick={() => { navigate(ROUTES.HOME) }}/>
      </Center>
    </Center>
  )
}
