import React, { useState, type ReactElement } from 'react'
import { Flex, Heading, Radio, Text } from '@chakra-ui/react'
import { type ApolloError, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { DisclosureTextComponent, DisclosureType } from './DisclosureTextComponent'
import { ROUTES } from '@/api/routes'
import ErrorBanner from '@/library/errors/ErrorBanner'
import DropDownComponent from '@/library/layout/drop_down/DropDownComponent'
import DisclosureIcon from '@/library/icons/DisclosureIcon'
import DownloadLink from '@/library/button/DownloadLink'
import {
  GRASSHOPPER_CUSTOMER_AGREEMENT_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL
} from '@/library/theme/urls'
import CheckBadgeIcon from '@/library/icons/CheckBadgeIcon'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { ACCEPT_AMPLIFY_TERMS_AND_CONDITIONS } from '@/graphql/mutations/AcceptTermsAndConditions'
import { Color } from '@/theme/theme'

export interface TermsAndConditionsComponentProps {
  franchiseGroupId: number
}

export default function TermsAndConditionsComponent (
  { franchiseGroupId }: TermsAndConditionsComponentProps
): ReactElement {
  const navigate = useNavigate()
  const [hasVerifiedAgreement, setHasVerifiedAgreement] = useState(true)
  const [acceptTermsAndConditions, { loading }] = useMutation(ACCEPT_AMPLIFY_TERMS_AND_CONDITIONS)
  const [submissionError, setSubmissionError] = useState<string | null>(null)

  function handleAcceptTermsAndConditions (): void {
    void acceptTermsAndConditions({ variables: { franchiseGroupId } })
      .then(() => {
        navigate(ROUTES.TRANSFER)
      })
      .catch((e: ApolloError) => { setSubmissionError(e.message) })
  }

  return (
    <Flex flexDirection='column' marginBottom={8} alignItems='start' width='100%' gap={8}>
      <ErrorBanner errorTitle='Unable to Accept Terms & Conditions' errorSubTitle={submissionError}/>
      <Flex flexDirection='column'>
        <Heading size='lg' color={Color.DARK_BLUE} marginBottom={2}>
          Please read and understand the following documents before you begin transferring funds.
        </Heading>
        <Text color={Color.DARK_GREY}>You will only need to do this once.</Text>
      </Flex>
      <Flex flexDirection='column' width='100%' gap={2}>
        <DropDownComponent
          title='Customer Agreement'
          subtitle='FirstBank'
          icon={<DisclosureIcon color={Color.DARK_BLUE}/>}
          dropDownContent={<DisclosureTextComponent disclosureType={DisclosureType.DISCLOSURES}/>}
          rightCallToActionComponent={<DownloadLink href={GRASSHOPPER_CUSTOMER_AGREEMENT_URL}/>}
        />
        <DropDownComponent
          title='Terms of Service'
          subtitle='Altir Industries, Inc.'
          icon={<DisclosureIcon color={Color.DARK_BLUE}/>}
          dropDownContent={<DisclosureTextComponent disclosureType={DisclosureType.TERMS_OF_SERVICE}/>}
          rightCallToActionComponent={<DownloadLink href={TERMS_OF_SERVICE_URL}/>}
        />
        <DropDownComponent
          title='Privacy Policy'
          subtitle='Altir Industries, Inc.'
          icon={<CheckBadgeIcon color={Color.DARK_BLUE}/>}
          dropDownContent={<DisclosureTextComponent disclosureType={DisclosureType.PRIVACY_POLICY}/>}
          rightCallToActionComponent={<DownloadLink href={PRIVACY_POLICY_URL}/>}
        />
      </Flex>
      <Flex gap={4}>
        <Radio
          isChecked={hasVerifiedAgreement}
          onClick={() => { setHasVerifiedAgreement(!hasVerifiedAgreement) }}
          size='lg'
          colorScheme='selectableInput'
        />
        <Text>
          I have read and agree to the above documents.
        </Text>
      </Flex>
      <Button
        text='I Agree'
        onClick={handleAcceptTermsAndConditions}
        isLoading={loading}
        variant={ButtonVariant.PRIMARY}
        size={ButtonSize.LARGE}
        isDisabled={!hasVerifiedAgreement}
      />
    </Flex>
  )
}
