import { gql } from '@apollo/client'
import { COUNTERPARTY_FRAGMENT } from '../../fragments/financial_accounts/CounterpartyFragment'

export const GET_COUNTERPARTY = gql`
    ${COUNTERPARTY_FRAGMENT}
    query GetCounterparty($counterpartyId: String!) {
        counterparty(id: $counterpartyId) {
            ...CounterpartyFragment
        }
    }
`
