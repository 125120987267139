import React, { useState, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import TransactionDownloadModal from './TransactionDownloadModal'
import { type TransactionsQueryVariables } from '../TransactionsComponent'
import Button from '@/library/button/Button'
import DownloadIcon from '@/library/icons/DownloadIcon'
import { useAltirStore } from '@/hooks/store/useAltirStore'

interface TransactionDownloadComponentProps {
  totalTransactionCount?: number
  queryVariables?: TransactionsQueryVariables
  isPageDataLoading?: boolean
  isCompact?: boolean
}

export default function TransactionDownloadComponent ({
  totalTransactionCount,
  queryVariables,
  isPageDataLoading,
  isCompact = false
}: TransactionDownloadComponentProps): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const organizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const isTransactionsCountZero = totalTransactionCount === 0
  return (
    <Flex>
      <TransactionDownloadModal
        isOpen={isModalOpen}
        onClose={() => { setIsModalOpen(false) }}
        queryVariables={queryVariables}
        totalTransactionCount={totalTransactionCount}
        isPageDataLoading={isPageDataLoading}
        organizationId={organizationId}
      />
      <Button
        text={isCompact ? 'Download' : `Download ${totalTransactionCount ?? ''} Transactions`}
        beforeIcon={<DownloadIcon/>}
        onClick={() => { setIsModalOpen(true) }}
        isDisabled={isTransactionsCountZero && isPageDataLoading === false}
      />
    </Flex>
  )
}
