import React, { type ReactElement } from 'react'
import { Flex, Heading, ModalBody, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/api/routes'
import Loader from '@/library/loading/Loader'
import Button from '@/library/button/Button'
import { Color, FontWeight } from '@/theme/theme'

export default function LoadingContent (): ReactElement {
  const navigate = useNavigate()
  return (
    <ModalBody>
      <Flex
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        p={6}
        gap={6}
      >
        <Loader/>
        <Flex flexDir='column' justifyContent='center' alignContent='center' textAlign='center'>
          <Heading
            textAlign='center'
            color={Color.DARK_BLUE}
            fontWeight={FontWeight.MEDIUM}
            size='lg'
          >
            Please Wait...
          </Heading>
          <Flex alignItems='center' color={Color.DARK_GREY} fontWeight={FontWeight.BOLD} flexDirection='column'>
            <Text>
              Give us some time to get things sorted.
            </Text>
            <Text>
              This should only take 30-60 seconds.
            </Text>
          </Flex>
        </Flex>
        <Button text='Done' onClick={() => { navigate(ROUTES.TREASURY) }}/>
      </Flex>
    </ModalBody>
  )
}
