import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import CircleCheckIcon from '../icons/CircleCheckIcon'
import { Color } from '../theme/theme'

export interface AltirProFeatureRowProps {
  text: string
  color?: string
}

export default function AltirProFeatureRow ({
  text,
  color = Color.BRIGHT_BLUE
}: AltirProFeatureRowProps): ReactElement {
  return (
    <Flex py={1} alignItems='center' gap={2}>
      <CircleCheckIcon color={color}/>
      <Text color={color}>{text}</Text>
    </Flex>
  )
}
