/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function RefreshIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M15 7.48201H18.4117V4.07031' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M19 15.6259C17.7055 18.26 15.0174 20.0703 11.9112 20.0703C7.54195 20.0703 4 16.4886 4 12.0703C4 7.65203 7.54195 4.07031 11.9112 4.07031C14.4095 4.07031 16.6374 5.24139 18.0872 7.07031' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      </svg>
    </IconContainer>
  )
}
