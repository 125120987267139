import React, { type ReactNode, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { type ContainerWidth, type Color } from '@/theme/theme'

interface PageProps {
  children: ReactNode
  marginX?: number
  marginY?: number
  maxWidth?: ContainerWidth
  backgroundColor?: Color
}

export default function Page ({
  children,
  backgroundColor,
  maxWidth,
  marginX = 14,
  marginY = 10
}: PageProps): ReactElement {
  return (
    <Flex
      w='100%'
      minH='100vh'
      alignItems='start'
      justifyContent='center'
      bg={backgroundColor}
    >
      <Flex
        w='100%'
        px={marginX}
        py={marginY}
        justifyContent='center'
        alignItems='center'
        maxWidth={maxWidth}
      >
        {children}
      </Flex>
    </Flex>
  )
}
