import React, { type ReactElement } from 'react'
import { Link, Text } from '@chakra-ui/react'
import { FontWeight, Color } from '../theme/theme'
import { sendSupportEmail } from '../nav/help_center/utils'

export default function SupportEmailInlineLink (): ReactElement {
  function onClick (): void {
    sendSupportEmail()
  }
  return (
    <Link as='span' onClick={onClick}>
      <Text as='span' color={Color.GREY_BLUE} fontWeight={FontWeight.MEDIUM} mx={1}>support@altir.app</Text>
    </Link>
  )
}
