import { useNavigate } from 'react-router-dom'
import { RouteParam, ROUTES } from '@/api/routes'
import { type OnboardingCallToAction } from '@/library/nav/onboarding/OnboardingStatusBar'
import { routeWithParams } from '@/utils/routerUtils'

interface AmplifyNavigation {
  skipCallToAction: OnboardingCallToAction
  backLink: string
}

export function useAmplifyAppNavigation (businessId: string): AmplifyNavigation {
  const navigate = useNavigate()

  const backLink = routeWithParams(
    ROUTES.AMPLIFY_APP_APPLY,
    [{ param: RouteParam.BUSINESS_ID, value: businessId }]
  )

  return {
    skipCallToAction: {
      callToActionText: 'Finish this later',
      callToActionOnClick: () => {
        navigate(ROUTES.HOME) // TODO (PJ): Redirect to Amplify App Hub once created
      }
    },
    backLink
  }
}
