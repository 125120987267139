import React, { type ReactElement } from 'react'
import { Flex, Link, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Event } from 'metrics/metrics'
import { ROUTES } from '@/api/routes'
import { Graphic } from '@/library/utility/Graphic'
import { BAR_CHART } from '@/library/theme/graphics'
import Button, { ButtonSize } from '@/library/button/Button'
import {
  type GetFixedMetrics_currentUser_franchiseGroup_fixedInsightMetrics_idleCash as IdleCash,
  type GetFixedMetrics_currentUser_franchiseGroup_fixedInsightMetrics_compoundGrowthRate as CompoundGrowthRate,
  type GetFixedMetrics_currentUser_franchiseGroup_fixedInsightMetrics_idleCashPotentialEarnings as PotentialEarnings
} from '@/graphql/__generated__/GetFixedMetrics'
import { BorderRadius, Color, IconSize } from '@/theme/theme'
import CarrotComponent from '@/library/utility/toggle/CarrotComponent'
import { IconFacing } from '@/types/types'

interface FixedInsightsSectionUpsellComponentProps {
  idleCash: IdleCash | null
  idleCashPotentialEarnings: PotentialEarnings | null
  compoundGrowthRate: CompoundGrowthRate | null
}

interface UpsellContent {
  ctaText: string
  route: string
  description: string
}

enum UpsellType {
  AMPLIFY,
  LENDING
}

export default function FixedInsightsSectionUpsellComponent ({
  idleCash, compoundGrowthRate, idleCashPotentialEarnings
}: FixedInsightsSectionUpsellComponentProps): ReactElement {
  const navigate = useNavigate()
  const upsellContent = getUpsellContent(idleCash, idleCashPotentialEarnings, compoundGrowthRate)

  // If upsell can't be computed, don't render component
  if (idleCash == null && compoundGrowthRate == null) {
    return <></>
  }

  return (
    <Flex
      flexDir='column'
      flex={1}
      bg={Color.LIGHT_BLUE}
      border={`1px solid ${String(Color.BRIGHT_BLUE)}`}
      borderRadius={BorderRadius.CARD}
      p={6}
      gap={4}
      justifyContent='center'
      alignItems='center'
    >
      <Graphic src={BAR_CHART} h='75px'/>
      <Text color={Color.DARK_BLUE} textAlign='center'>{upsellContent.description}</Text>
      <Flex>
        <Link onClick={() => { navigate(upsellContent.route) }}>
          <Button
            text={upsellContent.ctaText}
            size={ButtonSize.X_SMALL}
            afterIcon={
              <CarrotComponent facing={IconFacing.RIGHT} size={IconSize.EXTRA_SMALL} color={Color.WHITE}/>
            }
            onClickEventType={Event.INSIGHTS_UPSELL_CLICK}
          />
        </Link>
      </Flex>
    </Flex>
  )
}

function getUpsellContent (
  idleCash: IdleCash | null,
  idleCashPotentialEarnings: PotentialEarnings | null,
  compoundGrowthRate: CompoundGrowthRate | null
): UpsellContent {
  const upsellType = getUpsellType(idleCash?.amount ?? null, compoundGrowthRate?.rate ?? null)
  switch (upsellType) {
    case UpsellType.AMPLIFY:
      return {
        description: `Move up to ${idleCash?.amountFormatted ?? ''} into your Amplify Account to make an additional ${idleCashPotentialEarnings?.amountFormatted ?? ''} a year.`,
        route: ROUTES.TREASURY,
        ctaText: 'Go to Treasury'
      }
    case UpsellType.LENDING:
      return {
        // eslint-disable-next-line max-len
        description: 'Based on your cash balance and growth trends, you may experience an upcoming liquidity need. View offers from our preferred lending partners here.',
        route: ROUTES.LOANS,
        ctaText: 'Get a Loan'
      }
  }
}

/**
 *
 * Note that this logic will be flexible over time...
 * For now:
 * 1. Always advertise Amplify if idleCash > $100k
 * 2. Otherwise, advertise lending to customers with CAGR < -5%
 *
 */
function getUpsellType (idleCash: number | null, compoundGrowthRate: number | null): UpsellType {
  if ((idleCash ?? 0) > 100000) return UpsellType.AMPLIFY
  return (compoundGrowthRate ?? 0) < -0.05 ? UpsellType.LENDING : UpsellType.AMPLIFY
}
