import React, { useState, type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import InterestCalculatorSlider from '@/library/slider/InterestCalculatorSlider'
import { calculateProjectedInterest } from '@/utils/mathUtils'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { Color } from '@/theme/theme'
import { EMPTY_BALANCE_VALUE } from '@/utils/constants'

interface AmplifyPromoInterestCalculatorProps {
  interestRate?: number
  totalBalance?: number
}

export default function AmplifyPromoInterestCalculator ({
  interestRate,
  totalBalance = 0
}: AmplifyPromoInterestCalculatorProps): ReactElement {
  const minDepositValue = 0

  // Default slider to $100k if user hasn't linked any accounts yet
  const emptyBalanceDefault = totalBalance === 0
    ? 100_000
    : 0
  const maxDepositValue = Math.max(totalBalance, emptyBalanceDefault)

  // Default slider to 75% of FG's total deposits
  const [depositValue, setDepositValue] = useState(maxDepositValue * 0.75)
  const [depositTermYears, setDepositTermYears] = useState(1)

  const projectedInterest = interestRate != null
    ? getCurrencyFormatted(
      calculateProjectedInterest(depositValue, interestRate, depositTermYears),
      { minimumFractionDigits: 2 }
    )
    : EMPTY_BALANCE_VALUE
  return (
    <Flex flexDir='column' w='100%' gap={5}>
      <Flex flexDir='column' textAlign='center' gap={1}>
        <Text>Projected Interest:</Text>
        <Heading
          size='xl'
          color={Color.BRIGHT_BLUE}
        >
          {projectedInterest}
        </Heading>
      </Flex>
      <InterestCalculatorSlider
        depositValue={depositValue}
        depositTermYears={depositTermYears}
        minDepositValue={minDepositValue}
        maxDepositValue={maxDepositValue}
        setDepositValue={(value) => { setDepositValue(value) }}
        setDepositTermYears={(value) => { setDepositTermYears(value) }}
      />
    </Flex>
  )
}
