import React, { type ReactElement } from 'react'
import { Flex, Tooltip } from '@chakra-ui/react'
import Loader from './Loader'
import { TrackedJobType } from '@/graphql/__generated__/globalTypes'

interface JobTrackingLoaderProps {
  type: TrackedJobType
}

export default function TrackedJobLoader ({ type }: JobTrackingLoaderProps): ReactElement {
  return (
    <Tooltip label={getTooltipLabelByJobType(type)}>
      <Flex>
        <Loader size='20px'/>
      </Flex>
    </Tooltip>
  )
}

function getTooltipLabelByJobType (type: TrackedJobType): string | undefined {
  switch (type) {
    case TrackedJobType.TRANSACTION_REFRESH:
      return "This account's data is being refreshed. This may take up to 5 minutes."
    case TrackedJobType.TRANSACTION_EXPORT:
    case TrackedJobType.TRANSACTION_PULL:
    case TrackedJobType.TRANSACTION_RECALCULATE:
      return undefined
  }
}
