import React, { useState, type ReactElement } from 'react'
import { Flex, useToast } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { BorderRadius, FormInputHeight } from '@/theme/theme'
import PopoverMenu from '@/library/popoverMenu/components/PopoverMenu'
import PopoverMenuSingleOptionSelect from '@/library/popoverMenu/PopoverMenuSingleOptionSelect'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { getErrorToast, getSuccessToast } from '@/utils/toastUtils'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { GET_ORGANIZATION_OPTIONS } from '@/graphql/queries/GetOrganizationOptions'
import {
  type GetOrganizationOptions,
  type GetOrganizationOptions_currentUser_organizations as Organization
} from '@/graphql/__generated__/GetOrganizationOptions'

interface OrganizationSelectorComponentProps {
  // Styles
  shouldRotateMenuIcon?: boolean
  height?: string
}

export default function OrganizationSelectorComponent ({
  shouldRotateMenuIcon,
  height = FormInputHeight.DEFAULT
}: OrganizationSelectorComponentProps): ReactElement {
  const toast = useToast()
  const {
    selectedOrganizationId,
    setSelectedFranchiseGroupId,
    setSelectedOrganizationState
  } = useAltirStore(state => ({
    selectedOrganizationId: state.selectedOrganizationState.selectedOrganizationId,
    setSelectedFranchiseGroupId: state.setSelectedFranchiseGroupId,
    setSelectedOrganizationState: state.setSelectedOrganizationState
  }))

  const [selectedOrganization, setSelectedOrganization] = useState<Organization | undefined>(undefined)
  const {
    data,
    loading,
    error
  } = useQuery<GetOrganizationOptions>(
    GET_ORGANIZATION_OPTIONS,
    {
      onCompleted: (data) => {
        const selectedOrganization = (data
          ?.currentUser
          ?.organizations ?? [])
          .find(org => org.id === selectedOrganizationId)
        setSelectedOrganization(selectedOrganization)
      }
    }
  )

  function handleSelect (organization: Organization): void {
    // When switching organizations,
    // set the selectedFranchiseGroupId as an arbitrary sub-business within the organization
    const selectedFranchiseGroupId = (organization?.franchiseGroups ?? [])[0]?.id
    if (selectedFranchiseGroupId == null) {
      toast(getErrorToast('Unable to update organization. Please refresh the page or contact support.'))
      return
    }
    // Update selection state
    setSelectedOrganization(organization)
    setSelectedOrganizationState({
      selectedOrganizationId: organization.id,
      availableFranchiseGroupIds: organization.franchiseGroups?.map(franchiseGroups => Number(franchiseGroups.id)) ?? []
    })
    setSelectedFranchiseGroupId(Number(selectedFranchiseGroupId))

    // Display toast
    const successText = organization.name != null
      ? `Successfully switched to ${organization.name}`
      : 'Successfully switched context'
    toast(getSuccessToast(successText))
  }

  const options = data?.currentUser?.organizations ?? []

  // Hide selector if user only has access to a single organization
  if (!loading && options.length < 2) {
    return <></>
  }

  return (
    <Flex justifyContent='center' alignItems='center' flexDir='column'>
      <AltirSkeleton
        isLoading={loading}
        error={error}
        width='100%'
        isCompact={true}
        customErrorMessage='Something went wrong'
      >
        <PopoverMenu
          popoverMenuTitle={selectedOrganization?.name ?? 'Select Organization'}
          onSubmit={() => {}}
          borderRadius={BorderRadius.BUTTON}
          border='0px'
          menuButtonHeight={height}
          shouldRotateMenuIcon={shouldRotateMenuIcon}
          allowInteriorScroll={true}
        >
          <PopoverMenuSingleOptionSelect
            selectedOption={selectedOrganization}
            options={options}
            setSelectedOption={handleSelect}
            formattingFunction={(business) => { return { title: business.name ?? '' } }}
          />
        </PopoverMenu>
      </AltirSkeleton>
    </Flex>
  )
}
