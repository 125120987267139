import React, { type ReactElement } from 'react'
import { Box, Flex, MenuDivider, MenuItem, Text } from '@chakra-ui/react'
import { Color } from '@/theme/theme'

export interface PopoverMenuItemContent {
  title: string
  subtitle?: string
}

interface PopoverMenuSingleOptionSelectProps<T> {
  options: T[]
  selectedOption?: T
  setSelectedOption?: (option: T) => void
  setSelectedOptionOrUndefined?: (option: T | undefined) => void
  dividerFrequency?: number
  noOfLines?: number
  formattingFunction?: (value: T) => PopoverMenuItemContent
  clearSelectionTitle?: string
  callToActionElement?: ReactElement
  maxSubtitleWidth?: number
}

/**
 * A stylized replacement for FormSelect.tsx
 *
 * This component allows users to select one option and closes
 * after a selection is made.
 *
 * TODO (PJ): Pull the <PopoverMenu/> into this component to simplify usage
 */
export default function PopoverMenuSingleOptionSelect<T> (
  {
    options,
    selectedOption,
    setSelectedOption,
    setSelectedOptionOrUndefined,
    dividerFrequency,
    clearSelectionTitle,
    callToActionElement,
    noOfLines,
    formattingFunction = (value) => { return { title: String(value) } },
    maxSubtitleWidth
  }:
  PopoverMenuSingleOptionSelectProps<T>
): ReactElement {
  function shouldShowMenuDivider (index: number): boolean {
    if (dividerFrequency == null) {
      return false
    }
    return index !== 0 && index % dividerFrequency === 0
  }

  function setOption (option: T | undefined): void {
    if (setSelectedOptionOrUndefined != null) {
      setSelectedOptionOrUndefined(option)
    }
    if (setSelectedOption != null && option != null) {
      setSelectedOption(option)
    }
  }

  const hasTrailingElement = clearSelectionTitle != null || callToActionElement != null

  return (
    <>
      {options.map((option, index) => {
        const isSelectedOption = option === selectedOption
        const { title, subtitle } = formattingFunction(option)
        return (
          <Box key={index}>
            {shouldShowMenuDivider(index) && <MenuDivider />}
            <MenuItem
              closeOnSelect={true}
              color={isSelectedOption ? Color.BRIGHT_BLUE : Color.DARK_BLUE}
              onClick={() => { setOption(option) }}
            >
              <Flex flexDir='column' gap={1}>
                <Text color={isSelectedOption ? Color.BRIGHT_BLUE : Color.DARK_BLUE} noOfLines={noOfLines}>
                  {title}
                </Text>
                {subtitle != null && <Text maxW={maxSubtitleWidth} noOfLines={noOfLines}>{subtitle}</Text>}
              </Flex>
            </MenuItem>
          </Box>
        )
      })}
      {options.length > 0 && hasTrailingElement && <MenuDivider/>}
      {clearSelectionTitle != null &&
        <>
          <MenuItem closeOnSelect={true} onClick={() => { setOption(undefined) }}>
            <Flex gap={2} justifyContent='start' alignItems='center' >
              <Text color={Color.ERROR_RED}>{clearSelectionTitle}</Text>
            </Flex>
          </MenuItem>
          <MenuDivider />
        </>
      }
      {callToActionElement != null && callToActionElement}
    </>

  )
}
