/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function HomeIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M11.9586 1.45699C12.0484 1.45699 12.1212 1.52978 12.1212 1.61958V5.61096C12.1212 5.70076 12.0484 5.77355 11.9586 5.77355H7.96724C7.87744 5.77355 7.80465 5.70075 7.80465 5.61096V1.61958C7.80465 1.52978 7.87744 1.45699 7.96723 1.45699H11.9586ZM11.9586 8.40658C12.0484 8.40658 12.1212 8.47937 12.1212 8.56916V12.5605C12.1212 12.6503 12.0484 12.7231 11.9586 12.7231H7.96724C7.87744 12.7231 7.80465 12.6503 7.80465 12.5605V8.56916C7.80465 8.47937 7.87744 8.40658 7.96723 8.40658H11.9586ZM4.86438 1.45699C4.95417 1.45699 5.02696 1.52978 5.02696 1.61958V5.61096C5.02696 5.70075 4.95417 5.77355 4.86438 5.77355H0.872997C0.783202 5.77355 0.71041 5.70075 0.71041 5.61096V1.61958C0.71041 1.52978 0.783203 1.45699 0.872996 1.45699H4.86438ZM4.86438 8.40658C4.95417 8.40658 5.02696 8.47937 5.02696 8.56916V12.5605C5.02696 12.6503 4.95417 12.7231 4.86438 12.7231H0.872997C0.783202 12.7231 0.71041 12.6503 0.71041 12.5605V8.56916C0.71041 8.47937 0.783203 8.40658 0.872996 8.40658H4.86438Z' stroke={color} strokeWidth='1.33'/>
      </svg>
    </IconContainer>
  )
}
