import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { sendSupportEmail } from './utils'
import { DefaultModalComponent } from '../../modal/DefaultModalComponent'
import { Color, FontWeight } from '../../theme/theme'

export interface ContactUsModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export default function ContactUsModal ({ isOpen, setIsOpen }: ContactUsModalProps): ReactElement {
  return (
    <DefaultModalComponent
      title='Contact Us'
      isOpen={isOpen}
      onClose={() => { setIsOpen(false) }}
      callToActionText='Open Email App'
      callToActionClick={sendSupportEmail}
      isLoading={false}
    >
      <Flex flexDir='column' w='100%' gap={6} mb={4}>
        <Text>
          Reach out to us by email or phone.
        </Text>
        <Flex flexDir='column' justifyContent='center' alignItems='center' gap={1}>
          <Text fontWeight={FontWeight.BOLD} color={Color.DARK_BLUE}>support@altir.app</Text>
          <Text fontWeight={FontWeight.BOLD} color={Color.DARK_BLUE}>1-862-409-4490</Text>
        </Flex>
      </Flex>
    </DefaultModalComponent>
  )
}
