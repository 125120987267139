import React, { type ReactElement } from 'react'
import { Text } from '@chakra-ui/react'

export default function ErrorToast ({
  errorMessage
}: {
  errorMessage?: string | null
}): ReactElement {
  if (errorMessage == null) { return <></> }

  return <Text color='error'>{errorMessage}</Text>
}
