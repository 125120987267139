import React, { type Dispatch, type SetStateAction, useEffect, useState, type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { generateSubtitle } from './utils'
import DoubleSliderComponent from '@/library/slider/DoubleSliderComponent'
import PopoverMenu from '@/library/popoverMenu/components/PopoverMenu'
import { type NumberRangeInput } from '@/graphql/__generated__/globalTypes'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import FormDollarInput from '@/library/form/number/FormDollarInput'
import { Color } from '@/theme/theme'

const MINIMUM_SLIDER_VALUE = -100000
const MAXIMUM_SLIDER_VALUE = 100000
interface TransactionsAmountFilterComponentProps {
  queryValue?: NumberRangeInput
  onSubmit: (value?: NumberRangeInput) => void
}

export default function TransactionsAmountFilterComponent (
  { queryValue, onSubmit }: TransactionsAmountFilterComponentProps
): ReactElement {
  // Form handler needs string state
  const [minimumFormInput, setMinimumFormInput] = useState<string>('')
  const [maximumFormInput, setMaximumFormInput] = useState<string>('')

  // Range slider needs number state
  const [minimum, setMinimum] = useState<number | undefined>(undefined)
  const [maximum, setMaximum] = useState<number | undefined>(undefined)

  useEffect(() => {
    setMinimumFormInput(String(queryValue?.minimum ?? ''))
    setMaximumFormInput(String(queryValue?.maximum ?? ''))
    setMinimum(queryValue?.minimum ?? undefined)
    setMaximum(queryValue?.maximum ?? undefined)
  }, [queryValue?.maximum, queryValue?.minimum])

  useEffect(() => {
    preventNan(minimumFormInput, setMinimum)
    preventNan(maximumFormInput, setMaximum)
  }, [minimumFormInput, maximumFormInput])

  function handleSubmit (): void {
    if (minimum == null && maximum == null) {
      onSubmit(undefined)
      return
    }
    onSubmit({ minimum, maximum })
  }

  function handleFormInputChange (
    value: string,
    setStringFunction: Dispatch<SetStateAction<string>>,
    setNumberFunction: Dispatch<SetStateAction<number | undefined>>
  ): void {
    setStringFunction(value)
    preventNan(value, setNumberFunction)
  }

  function handleSliderChange ([minimum, maximum]: Array<number | undefined>): void {
    setMinimum(minimum)
    setMinimumFormInput(minimum != null ? String(minimum) : '')
    setMaximum(maximum)
    setMaximumFormInput(maximum != null ? String(maximum) : '')
  }

  const minimumFormatted = formatAsCurrencyOrUndefined(minimum)
  const maximumFormatted = formatAsCurrencyOrUndefined(maximum)

  return (
    <PopoverMenu
      popoverMenuTitle='Amount'
      onSubmit={handleSubmit}
      subtitle={generateSubtitle(minimumFormatted, maximumFormatted)}
      shouldMatchWidth={true}
      hasSelection={minimumFormatted != null || maximumFormatted != null}
    >
      <Flex flexDir='column' gap={4} p={3}>
        <FormDollarInput
          fieldName='minimumAmount'
          value={minimumFormInput}
          label='Minimum'
          onChange={value => { handleFormInputChange(value, setMinimumFormInput, setMinimum) }}
          placeholder=''
          fieldInteriorLabel={<Text color={Color.DARK_BLUE}>$</Text>}
          backgroundColor={Color.LIGHT_GREY}
          allowNegative={true}
        />

        <FormDollarInput
          fieldName='maximumAmount'
          value={maximumFormInput}
          label='Maximum'
          onChange={value => { handleFormInputChange(value, setMaximumFormInput, setMaximum) }}
          placeholder=''
          fieldInteriorLabel={<Text color={Color.DARK_BLUE}>$</Text>}
          backgroundColor={Color.LIGHT_GREY}
          allowNegative={true}
        />

        <DoubleSliderComponent
          lowValue={minimum}
          highValue={maximum}
          onChange={handleSliderChange}
          minValue={MINIMUM_SLIDER_VALUE}
          maxValue={MAXIMUM_SLIDER_VALUE}
          formatValue={getCurrencyFormatted}
          shouldEndsReturnUndefined={true}
        />
      </Flex>
    </PopoverMenu>
  )
}

function formatAsCurrencyOrUndefined (amount?: number): string | undefined {
  return amount != null ? getCurrencyFormatted(amount) : undefined
}

function preventNan (
  value: string,
  setFunction: Dispatch<SetStateAction<number | undefined>>
): void {
  if (value === '') {
    setFunction(undefined)
  } else if (!['-', '$-', '-$'].includes(value)) {
    setFunction(Number(value))
  }
}
