import { DateTime } from 'luxon'
import { dateIsBeforeTime, getCurrentDateTimeInUserTimeZone } from './dateUtils'
import { InstitutionConnectionProvider, TransferStatus, TransferType } from '@/graphql/__generated__/globalTypes'
import { ACH_AUTHORIZATION_URL, WIRE_AUTHORIZATION_URL } from '@/theme/urls'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'

export const ALTIR_ACH_PROCESSING_FEE = 0
export const ALTIR_WIRE_PROCESSING_FEE = 25
export const ALTIR_CHECK_PROCESSING_FEE = 1.50
// Indicates the time that First Bank initiates all transfers for a given day
export const ACH_TRANSFER_PROCESSING_TIME_CUTOFF = 15 // 3pm

export const WIRE_TRANSFER_PROCESSING_TIME_CUTOFF_HR = 17 // 5pm
export const WIRE_TRANSFER_PROCESSING_TIME_CUTOFF_MIN = 30

export const ACH_TOOLTIP_MESSAGE = `
ACH transfers initiated before 3:00pm EST will be accessible the following business day.
ACH transfers initiated after 3:00pm EST will be accessible in two business days.
`
export const WIRE_TOOLTIP_MESSAGE = `
Wire transfers initiated before 5:30pm EST will be accessible the same business day.
Wire transfers initiated after 5:30pm EST will be accessible the next business day.
`

export interface TransferComponentContent {
  transferTotal: number
  transferFee: number
  agreementUrl: string
  transferTypeFormatted: string
}

export function calculateSettleETA (transferType: TransferType): DateTime {
  const estTime = DateTime.now().setZone('America/New_York')
  const userDateTime = getCurrentDateTimeInUserTimeZone()
  let daysForTransferToSettle
  // If a transfer is initiated the daily processing window, it will add a day to delivery.
  // * Wire takes 0-1 business days
  // * ACH takes 1-2 business days
  if (transferType === TransferType.WIRE) {
    daysForTransferToSettle = dateIsBeforeTime(
      estTime, { hour: WIRE_TRANSFER_PROCESSING_TIME_CUTOFF_HR, minute: WIRE_TRANSFER_PROCESSING_TIME_CUTOFF_MIN })
      ? 0
      : 1
  } else {
    daysForTransferToSettle = estTime.hour < ACH_TRANSFER_PROCESSING_TIME_CUTOFF ? 1 : 2
  }

  // TODO account for weekends
  return userDateTime.plus({
    day: daysForTransferToSettle
  })
}

export function getTransferTooltipMessage (transferType: TransferType): string {
  return transferType === TransferType.WIRE
    ? WIRE_TOOLTIP_MESSAGE
    : ACH_TOOLTIP_MESSAGE
}

export function getTransferComponentContent (amount: number, transferType: TransferType): TransferComponentContent {
  if (transferType === TransferType.WIRE) {
    return {
      transferTotal: amount + ALTIR_WIRE_PROCESSING_FEE,
      transferFee: ALTIR_WIRE_PROCESSING_FEE,
      agreementUrl: WIRE_AUTHORIZATION_URL,
      transferTypeFormatted: 'Wire'
    }
  } else if (transferType === TransferType.CHECK) {
    return {
      transferTotal: amount + ALTIR_CHECK_PROCESSING_FEE,
      transferFee: ALTIR_CHECK_PROCESSING_FEE,
      agreementUrl: WIRE_AUTHORIZATION_URL, // TODO (PJ): Check agreement?
      transferTypeFormatted: 'Check'
    }
  }
  return {
    transferTotal: amount + ALTIR_ACH_PROCESSING_FEE,
    transferFee: ALTIR_ACH_PROCESSING_FEE,
    agreementUrl: ACH_AUTHORIZATION_URL,
    transferTypeFormatted: 'ACH'
  }
}

export function isPendingTransfer (status: TransferStatus | null): boolean {
  if (status == null) return false
  return [
    TransferStatus.PENDING,
    TransferStatus.CREATED,
    TransferStatus.PROCESSING,
    TransferStatus.SENT
  ].includes(status)
}

export function isPendingExternalTransfer (status: TransferStatus | null): boolean {
  if (status == null) return false
  return [
    TransferStatus.PENDING,
    TransferStatus.CREATED,
    TransferStatus.PROCESSING
  ].includes(status)
}

export function isAltirConnectAccount (account: FinancialAccountExtendedFragment): boolean {
  return account.connectionProvider === InstitutionConnectionProvider.ALTIR
}

export function needsWireVerification (
  transferType: TransferType,
  selectedCounterparty: FinancialAccountExtendedFragment | null): boolean {
  return (
    transferType === TransferType.WIRE &&
      selectedCounterparty?.wireAccountNumber == null && selectedCounterparty?.wireRoutingNumber == null
  )
}

export function getFormattedTransferType (transferType: TransferType): string {
  switch (transferType) {
    case TransferType.BOOK:
      return 'Book'
    case TransferType.CHECK:
      return 'Check'
    case TransferType.SAMEDAY_ACH:
    case TransferType.STANDARD_ACH:
      return 'ACH'
    case TransferType.WIRE:
      return 'Wire'
  }
}

export function isAmplifyAccount (account?: FinancialAccountExtendedFragment): boolean {
  return account?.amplifyAccount?.id != null
}

// TODO (PJ): Audit this when we introduce Treasure account
export function isCounterparty (account?: FinancialAccountExtendedFragment): boolean {
  return account?.counterpartyId != null && !isAmplifyAccount(account)
}
