import { useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { GET_AUTHENTICATED_USER } from '@/graphql/queries/GetAuthenticatedUser'
import {
  type GetAuthenticatedUser,
  type GetAuthenticatedUser_currentUser_authorization as Authorization
} from '@/graphql/__generated__/GetAuthenticatedUser'
import { isAuthenticationError } from '@/utils/errorUtils'
import { type OnboardingStep } from '@/graphql/__generated__/globalTypes'

interface useAuthenticationStates {
  isAuthenticated: boolean
  isAuthQueryError: boolean
  isAuthLoading: boolean
  userAuthorization: Authorization[]
  userId: number | undefined
  defaultOrganizationId: string | undefined
  defaultFranchiseGroupId: number | undefined
  defaultAvailableFranchiseGroupIds: number[] | undefined
  onboardingStep: OnboardingStep | undefined
}
/**
 *
 * @returns
 * isAuthenticated Only true if both Auth0 and the backend find a valid user
 * isAuthInError True if either Auth0 or the backend find an error
 * userAuthorization Array of Authorization objects from the backend
 * defaultOrganizationId The default organization ID from the backend
 * defaultFranchiseGroupId The default franchise group ID from the backend
 */
export default function useAuthentication (): useAuthenticationStates {
  const {
    isAuthenticated: isAuth0Authenticated,
    getAccessTokenSilently,
    isLoading: isAuth0Loading,
    error: isAuth0Error
  } = useAuth0()

  const {
    data,
    loading: getUserLoading,
    error: getUserError,
    refetch
  } = useQuery<GetAuthenticatedUser>(
    GET_AUTHENTICATED_USER, {
      fetchPolicy: 'network-only',
      variables: {}
    })

  useEffect(() => {
    async function requestAuth0 (): Promise<void> {
      await getAccessTokenSilently()
      await refetch()
    }
    if (!isAuth0Authenticated) {
      void requestAuth0()
    }
  }, [getAccessTokenSilently, refetch, isAuth0Authenticated])

  const isAuthLoading = isAuth0Loading || getUserLoading
  const isAuthQueryError = isAuth0Error != null || (getUserError != null && !isAuthenticationError(getUserError))
  const isAuthenticated = !isAuthLoading && !isAuthQueryError && !isAuthenticationError(getUserError)

  const defaultFranchiseGroupIdAsString = data?.currentUser?.selectedOrganization?.franchiseGroups?.[0]?.id ?? undefined
  const defaultFranchiseGroupId = defaultFranchiseGroupIdAsString != null
    ? Number(defaultFranchiseGroupIdAsString)
    : undefined
  const defaultAvailableFranchiseGroupIds =
    data?.currentUser?.selectedOrganization?.franchiseGroups?.map(group => Number(group.id)) ?? []

  const userId = data?.currentUser?.id != null ? Number(data?.currentUser.id) : undefined

  return {
    isAuthenticated,
    isAuthQueryError,
    isAuthLoading,
    userId,
    userAuthorization: data?.currentUser?.authorization ?? [],
    defaultOrganizationId: data?.currentUser?.selectedOrganization?.id,
    defaultFranchiseGroupId,
    defaultAvailableFranchiseGroupIds,
    onboardingStep: data?.currentUser?.onboardingStep
  }
}
