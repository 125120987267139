import React, { type ReactElement } from 'react'

import { useNavigate } from 'react-router-dom'
import { IconSize } from '@/theme/theme'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import PlusIcon from '@/library/icons/PlusIcon'
import { ROUTES } from '@/api/routes'

export default function CreatedAutomatedTransferButton (): ReactElement {
  const navigate = useNavigate()
  return (
    <Button
      text='Add an Automated Transfer Rule'
      variant={ButtonVariant.WHITE_OUTLINE}
      size={ButtonSize.MEDIUM}
      beforeIcon={<PlusIcon size={IconSize.SMALL}/>}
      onClick={() => { navigate(ROUTES.CREATE_AUTOMATED_TRANSFER_RULE) }}
    />
  )
}
