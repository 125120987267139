import React, { useState, type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import TransferRuleConfirmationModal from './TransferRuleConfirmationModal'
import RecurringRuleFormSection, { type RecurringRuleFormState } from './RecurringRuleFormSection'
import AccountSelectorComponent, {
  AccountSelectorAccountContext,
  AccountSelectorContext
} from '../../transfer/components/account_selector/AccountSelectorComponent'
import { Color } from '@/theme/theme'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { TransferRuleType } from '@/graphql/__generated__/globalTypes'
import FormDollarInput from '@/library/form/number/FormDollarInput'
import { getCurrentDate } from '@/utils/dateUtils'
import { isInvalidDate } from '@/utils/formUtils'
import { formatInputAsDate, stripDateFormatting } from '@/library/form/number/utils'
import { nonNull } from '@/utils/arrayUtils'
import { isAmplifyAccount } from '@/utils/transferUtils'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'

export interface CreateTimeRuleComponentProps {
  amplifyAccount?: FinancialAccountExtendedFragment
  counterparties: FinancialAccountExtendedFragment[]
  preSelectedCounterpartyId?: string
}

export default function CreateTimeRuleComponent (
  {
    amplifyAccount,
    counterparties,
    preSelectedCounterpartyId
  }: CreateTimeRuleComponentProps
): ReactElement {
  const [amount, setAmount] = useState<string>()
  const preSelectedCounterparty = counterparties.find(cp => cp.counterpartyId === preSelectedCounterpartyId) ?? null
  const [selectedSourceAccount, setSelectedSourceAccount] = useState<FinancialAccountExtendedFragment | null>(
    preSelectedCounterparty
  )
  const [selectedDestinationAccount, setSelectedDestinationAccount] = useState<FinancialAccountExtendedFragment | null>(
    preSelectedCounterparty != null && amplifyAccount != null ? amplifyAccount : null
  )
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const [recurringForm, setRecurringForm] = useState<RecurringRuleFormState>({
    dayOfWeek: 'Monday',
    dayOfMonth: 1,
    transferRuleType: TransferRuleType.DAILY,
    transferDate: stripDateFormatting(getCurrentDate())
  })

  function isValidInput (): boolean {
    if (
      !isAmplifyAccount(selectedSourceAccount ?? undefined) &&
      !isAmplifyAccount(selectedDestinationAccount ?? undefined)
    ) {
      return false
    }
    if (
      selectedSourceAccount?.counterpartyId == null &&
      selectedDestinationAccount?.counterpartyId == null
    ) {
      return false
    }
    if (amount == null || Number(amount) <= 0) {
      return false
    }
    // TODO validate future date
    if (recurringForm.transferDate == null || isInvalidDate(formatInputAsDate(recurringForm.transferDate))) {
      return false
    }
    return true
  }

  return (
    <Flex direction='column' gap={5} w='100%'>
      <AccountSelectorComponent
        altirAccountOptions={nonNull([amplifyAccount])}
        nonAltirAccountOptions={counterparties ?? []}
        headerText='From'
        onSelectedAccountChange={setSelectedSourceAccount}
        selectedAccount={selectedSourceAccount ?? undefined}
        selectedOppositeSideOfTransferAccount={selectedDestinationAccount}
        context={AccountSelectorContext.TRANSFER_RULE}
        accountContext={AccountSelectorAccountContext.FROM}
        transferType={null}
      />
      <AccountSelectorComponent
        altirAccountOptions={nonNull([amplifyAccount])}
        nonAltirAccountOptions={counterparties ?? []}
        headerText='To'
        onSelectedAccountChange={setSelectedDestinationAccount}
        selectedAccount={selectedDestinationAccount ?? undefined}
        selectedOppositeSideOfTransferAccount={selectedSourceAccount}
        context={AccountSelectorContext.TRANSFER_RULE}
        accountContext={AccountSelectorAccountContext.TO}
        transferType={null}
      />
      <FormDollarInput
        fieldName='amount'
        value={amount ?? ''}
        label='Amount'
        onChange={setAmount}
        placeholder='100'
        fieldInteriorLabel={<Text color={Color.DARK_BLUE}>$</Text>}
        backgroundColor={Color.WHITE}
      />
      <RecurringRuleFormSection
        formState={recurringForm}
        setState={setRecurringForm}
      />
      <Button
        text='Submit'
        size={ButtonSize.MEDIUM}
        variant={ButtonVariant.PRIMARY}
        isDisabled={!isValidInput()}
        onClick={() => { setIsConfirmationModalOpen(true) }}
        onClickEventType={Event.TRANSFER_RULE_PAGE_CREATE_RULE}
      />
      <TransferRuleConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        onModalClose={() => { setIsConfirmationModalOpen(false) }}
        onUnhandledSubmissionError={() => {}}
        ruleData={{
          sourceAccount: selectedSourceAccount ?? undefined,
          destinationAccount: selectedDestinationAccount ?? undefined,
          transferRuleType: recurringForm.transferRuleType,
          transferAmount: amount,
          targetDate:
            recurringForm.transferRuleType === TransferRuleType.MONTHLY ? recurringForm.dayOfMonth : undefined,
          targetDay: recurringForm.transferRuleType === TransferRuleType.WEEKLY ? recurringForm.dayOfWeek : undefined,
          ruleStartDate: formatInputAsDate(recurringForm.transferDate)
        }}
      />
    </Flex>
  )
}
