import React, { type ReactElement } from 'react'
import { Checkbox, Flex, Text } from '@chakra-ui/react'
import { Color } from '@/theme/theme'

interface CheckboxSelectProps {
  description: string
  isChecked: boolean
  setIsChecked: (isChecked: boolean) => void
}

export default function CheckboxSelect ({ description, isChecked, setIsChecked }: CheckboxSelectProps): ReactElement {
  return (
    <Flex gap={4}>
      <Checkbox
        isChecked={isChecked}
        onChange={() => { setIsChecked(!isChecked) }}
        size='lg'
        colorScheme='selectableInput'
      />
      <Text color={Color.DARK_BLUE}>{description}</Text>
    </Flex>
  )
}
