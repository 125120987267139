import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { Color, IconSize } from '../theme/theme'

interface AvatarComponentProps {
  text: string
  size?: IconSize
  backgroundColor?: string
  color?: string
}

export const AvatarComponent = ({
  text,
  size = IconSize.LARGE,
  backgroundColor = Color.GREY,
  color = Color.DARK_BLUE
}: AvatarComponentProps): ReactElement => {
  return (
    <Flex
      backgroundColor={backgroundColor}
      h={getComponentPadding(size)}
      w={getComponentPadding(size)}
      borderRadius='100%'
      justifyContent='center'
      alignItems='center'
    >
      <Text color={color} fontSize={getTextSize(size)}>{text}</Text>
    </Flex>
  )
}

function getComponentPadding (iconSize: IconSize): number {
  switch (iconSize) {
    case IconSize.SMALL:
      return 8
    case IconSize.MEDIUM:
      return 10
    case IconSize.LARGE:
      return 16
    case IconSize.X_LARGE:
      return 20
    default:
      return 20
  }
}

function getTextSize (iconSize: IconSize): string {
  switch (iconSize) {
    case IconSize.SMALL:
      return 'sm'
    case IconSize.MEDIUM:
      return 'md'
    case IconSize.LARGE:
      return 'xl'
    case IconSize.X_LARGE:
      return '2xl'
    default:
      return '2xl'
  }
}
