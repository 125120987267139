/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function LeftArrowIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g id='icon/16/arrowLeft/2px'>
          <path id='Vector' d='M6.36591 3.82389L2.44434 7.99056L6.3659 12.1572M13.5554 7.99056L2.77113 7.99056' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
        </g>
      </svg>
    </IconContainer>
  )
}
