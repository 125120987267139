import React, { type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import { BorderRadius, Color } from '@/theme/theme'
import StackedInfoAttribute from '@/library/utility/StackedInfoAttribute'
import {
  type TransferFragment as Transfer
} from '@/graphql/__generated__/TransferFragment'
import { getFormattedDateString } from '@/utils/dateUtils'
import { getCounterpartyShortName } from '@/utils/financialAccountUtils'
import { getCurrencyFormatted } from '@/utils/stringUtils'

interface CheckInfoCardProps {
  check: Transfer
}

export default function CheckInfoCard ({ check }: CheckInfoCardProps): ReactElement {
  return (
    <Center
      w='100%'
      flexDir='column'
      gap={4}
      p={6}
      bg={Color.WHITE}
      borderRadius={BorderRadius.CARD}
    >
      <StackedInfoAttribute
        header='Amount'
        subtext={getCurrencyFormatted(check.amount.amount)}
      />
      <StackedInfoAttribute
        header='To'
        // TODO (PJ): improve interface so we don't have to destructure and add nullable live balance
        subtext={getCounterpartyShortName(
          check.counterparty != null
            ? { ...check.counterparty, liveBalance: null, amplifyAccount: null }
            : undefined)
        }
      />
      <StackedInfoAttribute
        header='Date'
        subtext={getFormattedDateString(check.createdAt) ?? undefined}
      />
      {/* TODO (PJ): Populate once these are returned from the backend */}
      <StackedInfoAttribute header='Memo' subtext={check.checkMemo ?? undefined}/>
      <StackedInfoAttribute header='Message' subtext={check.checkMessage ?? undefined}/>
    </Center>
  )
}
