/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function TreasuryIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 26 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M19.8086 7.32422V15.0404' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M15.2695 7.32422V15.0404' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M10.7305 7.32422V15.0404' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M6.19141 7.32422V15.0404' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M22.5323 15.4941L3.46875 15.4941' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M24.347 19.125L1.65234 19.125' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M3.46875 7.3227L13.0005 1.87598L22.5323 7.3227H3.46875Z' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      </svg>
    </IconContainer>
  )
}
