import React, { type ReactElement } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import ProgressCircle from './ProgressCircle'
import { BorderRadius, Color } from '@/theme/theme'

interface ProgressItemProps {
  index: number
  currentIndex: number
  stepCount: number
}

export default function ProgressItem ({ index, currentIndex, stepCount }: ProgressItemProps): ReactElement {
  return (
    <Flex key={index} align='center' justifyContent='center' flex='1' gap={2}>
      <Box
        flex='1'
        height={index > 0 ? '3px' : '0px'}
        bg={index <= currentIndex ? Color.BRIGHT_BLUE : Color.GREY}
        borderRightRadius={BorderRadius.BADGE}
      />
      <ProgressCircle index={index} currentIndex={currentIndex}/>
      <Box
        flex='1'
        height={index < stepCount - 1 ? '3px' : '0px'}
        bg={index < currentIndex ? Color.BRIGHT_BLUE : Color.GREY}
        borderLeftRadius={BorderRadius.BADGE}
      />
    </Flex>
  )
}
