import React, { type ReactElement } from 'react'
import PopoverMenu from './components/PopoverMenu'
import PopoverMenuSelectionOptions from './components/PopoverMenuSelectionOptions'

interface PopoverMenuOption {
  id: string
  label: string
}

interface PopoverMenuMultiSelectorProps {
  label: string
  selectedOptionIds: string[]
  options: PopoverMenuOption[]
  onChange: (values: string[]) => void
  onSubmit?: () => void
  allowInteriorScroll?: boolean
  isLoading?: boolean
  shouldMatchWidth?: boolean
}

/**
 * Popover selector that allows the user to select multiple
 * items from a flat list of options, i.e:
 *
 * [x] Mercury
 * [ ] Bank of America
 * [x] Wells Fargo
 */
export default function PopoverMenuMultiSelector ({
  label,
  selectedOptionIds,
  options,
  allowInteriorScroll,
  isLoading,
  onChange,
  onSubmit,
  shouldMatchWidth
}: PopoverMenuMultiSelectorProps): ReactElement {
  const hasSelection = selectedOptionIds.length > 0
  const subtitle = hasSelection ? `(${selectedOptionIds.length})` : ''
  return (
    <PopoverMenu
      popoverMenuTitle={label}
      onSubmit={onSubmit != null ? onSubmit : () => {}}
      subtitle={subtitle}
      allowInteriorScroll={allowInteriorScroll}
      isLoading={isLoading}
      hasSelection={hasSelection}
      shouldMatchWidth={shouldMatchWidth}
    >
      <PopoverMenuSelectionOptions
        updateSelection={onChange}
        menuSelectionItems={
                options.map(option => { return { id: option.id, textArray: [option.label] } })
            }
        selectedItems={selectedOptionIds}
        isCheckBox={true}
      />
    </PopoverMenu>
  )
}
