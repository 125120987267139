import React, { useState, type ReactElement } from 'react'
import { Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip } from '@chakra-ui/react'
import TransferRuleActivationModal, { ActivationDirection } from './TransferRuleActivationModal'
import BalanceRuleDropdownComponent from './BalanceRuleDropdownComponent'
import TimeRuleDropdownComponent from './TimeRuleDropdownComponent'
import TransferRuleHeader from './TransferRuleHeader'
import TransferRuleDropdownErrorMessage from './TransferRuleDropdownErrorMessage'
import DropDownComponent from '@/library/layout/drop_down/DropDownComponent'
import { type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule }
  from '@/graphql/__generated__/GetTransferRules'
import { Color, IconSize } from '@/theme/theme'
import MenuIcon from '@/library/icons/MenuIcon'
import { isBalanceRule } from '@/utils/transferRuleUtils'
import { ERROR_BORDER } from '@/utils/errorUtils'
import { DropDownVariant } from '@/library/layout/drop_down/types'
import StackedInfoAttribute from '@/library/utility/StackedInfoAttribute'
import { DateTimeTemplate, getFormattedDateString } from '@/utils/dateUtils'
import { INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY } from '@/utils/authUtils'

export interface TransferRuleDropdownComponentProps {
  transferRule: TransferRule
  isOpenOnStart?: boolean
  onUpdate: () => void
  isError: boolean
  canEditTransferRules: boolean
}

export default function TransferRuleDropdownComponent (
  {
    transferRule,
    isOpenOnStart = false,
    onUpdate,
    canEditTransferRules,
    isError
  }: TransferRuleDropdownComponentProps
): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const dropdownContentWithMenu = (
    <Flex grow={1}>
      <Flex direction='column' gap={3} w='100%'>
        {
          isBalanceRule(transferRule.transferRuleType)
            ? <BalanceRuleDropdownComponent transferRule={transferRule}/>
            : <TimeRuleDropdownComponent transferRule={transferRule}/>
        }
        <TransferRuleDropdownErrorMessage transferRule={transferRule}/>
        {!transferRule.isActive &&
          <StackedInfoAttribute
            header='Deactivated On'
            subtext={`${getFormattedDateString(transferRule.updatedAt, DateTimeTemplate.FULL) ?? 'N/A'}`}
          />
        }
      </Flex>
      <Flex grow={1} justifyContent='right'>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label='Options'
            variant='ghost'
            icon={<MenuIcon color={Color.DARK_GREY} size={IconSize.SMALL} />}
          />
          <MenuList p={2}>
            <Tooltip label={!canEditTransferRules ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}>
              <MenuItem onClick={() => { setIsModalOpen(true) }} isDisabled={!canEditTransferRules}>
                <Text>
                  {transferRule.isActive
                    ? 'Deactivate'
                    : 'Re-Activate'}
                </Text>
              </MenuItem>
            </Tooltip>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>)

  return (
    <Flex>
      <TransferRuleActivationModal
        transferRule={transferRule}
        isModalOpen={isModalOpen}
        onModalClose={() => { setIsModalOpen(false) }}
        onUpdate={onUpdate}
        activationDirection={transferRule.isActive ? ActivationDirection.Deactivate : ActivationDirection.ReActivate}
      />
      <DropDownComponent
        customHeader={<TransferRuleHeader transferRule={transferRule}/>}
        isOpenOnStart={isOpenOnStart}
        variant={transferRule.isActive ? DropDownVariant.DEFAULT : DropDownVariant.GRAY}
        dropDownContent={dropdownContentWithMenu}
        borderStyle={isError ? ERROR_BORDER : undefined}
      />
    </Flex>
  )
}
