import React, { useState, type ReactElement } from 'react'
import { Flex, Tooltip } from '@chakra-ui/react'
import ChooseAnAccountContent from './ChooseAnAccountContent'
import AccountRowContent from './AccountRowContent'
import { BorderRadius, Color, Height } from '@/theme/theme'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'

export interface AccountRowProps {
  account?: FinancialAccountExtendedFragment
  isSelected?: boolean
  isDisabled?: boolean
  hideDisabledState?: boolean
  tooltipLabel?: string
  onClick: (account?: FinancialAccountExtendedFragment) => void
  onClearSelection?: () => void
}

export function SelectableAccountRow (
  {
    account: nullableAccount,
    isSelected = false,
    isDisabled = false,
    hideDisabledState = false,
    tooltipLabel,
    onClick,
    onClearSelection
  }: AccountRowProps
): ReactElement {
  const [isClearButtonHovered, setIsClearButtonHovered] = useState(false)
  const border = isSelected ? `1.5px solid ${String(Color.DARK_BLUE)}` : ''

  const handleClearSelection = onClearSelection != null
    ? () => {
        setIsClearButtonHovered(false)
        onClearSelection?.()
      }
    : undefined

  return (
    <Tooltip
      label={tooltipLabel}
      bg={Color.DARK_BLUE}
      hasArrow={true}
    >
      <Flex
        bg={isDisabled ? Color.GREY : Color.WHITE}
        border={border}
        borderRadius={BorderRadius.CARD}
        p={6}
        width='100%'
        _hover={{
          cursor: !isDisabled ? 'pointer' : hideDisabledState ? 'auto' : 'not-allowed',
          backgroundColor: !isClearButtonHovered ? Color.GREY : undefined
        }}
        alignItems='center'
        onClick={() => {
          !isDisabled && !isClearButtonHovered && onClick()
        }}
        height={Height.ROW}
      >
        {nullableAccount == null
          ? <ChooseAnAccountContent/>
          : <AccountRowContent
              account={nullableAccount}
              isDisabled={isDisabled}
              hideDisabledState={hideDisabledState}
              onClearSelection={handleClearSelection}
              onClearButtonHover={(isHovered) => { setIsClearButtonHovered(isHovered) }}
            />}
      </Flex>
    </Tooltip>
  )
}
