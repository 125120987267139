import React, { useState, type ReactElement } from 'react'
import { Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { AutomatedRuleTreasuryDropdownCardTextRow } from './AutomatedRuleTreasuryDropdownCardTextRow'
import { Color, IconSize } from '@/theme/theme'
import {
  // eslint-disable-next-line max-len
  type GetTreasuryPageData_currentUser_selectedOrganization_selectedFranchiseGroup_financialAccounts_transferRules as TransferRule
} from '@/graphql/__generated__/GetTreasuryPageData'
import { getFormattedBalanceRule, getFormattedTimeRule, isBalanceRule } from '@/utils/transferRuleUtils'
import RecurringRuleBalanceIcon from '@/library/icons/RecurringRuleBalanceIcon'
import RecurringRuleTimeIcon from '@/library/icons/RecurringRuleTimeIcon'
import MenuIcon from '@/library/icons/MenuIcon'
import { ROUTES } from '@/api/routes'
import TransferRuleActivationModal, { ActivationDirection }
  from '@/components/pages/transfer_rules/components/TransferRuleActivationModal'

export interface AutomatedRuleTreasuryDropdownCardProps {
  transferRule: TransferRule
  onDelete: () => void
}
export default function AutomatedRuleTreasuryDropdownCard (
  { transferRule, onDelete }: AutomatedRuleTreasuryDropdownCardProps
): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false)

  function balanceDescription (): ReactElement {
    const formattedRule = getFormattedBalanceRule(transferRule)

    return <>
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='Balance Threshold'
        value={formattedRule.balance}
        isLargeText={true}
      />
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='To'
        value={formattedRule.to}
      />
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='Description'
        value={formattedRule.sentenceDescription}
        shouldIncludeDivider={false}
        flexHeader={5}
        flexValue={5}
      />
    </>
  }

  function timeDescription (): ReactElement {
    const formattedRule = getFormattedTimeRule(transferRule)

    return <>
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='Amount'
        value={formattedRule.amount}
        isLargeText={true}
      />
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='To'
        value={formattedRule.to}
      />
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='From'
        value={formattedRule.from}
      />
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='Frequency'
        value={formattedRule.frequency}
      />
      <AutomatedRuleTreasuryDropdownCardTextRow
        header='Next Transfer'
        value={formattedRule.nextTransfer}
        shouldIncludeDivider={false}
      />
    </>
  }

  const menuButton = (
    <Flex>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Options'
          variant='ghost'
          icon={<MenuIcon color={Color.DARK_GREY} size={IconSize.SMALL} />}
        />
        <MenuList p ={2}>
          <MenuItem onClick={() => { navigate(ROUTES.AUTOMATED_TRANSFERS) }}>
            <Text>Manage Rules</Text>
          </MenuItem>
          <MenuItem onClick={() => { setIsModalOpen(true) }}>
            <Text>Deactivate</Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )

  const navigate = useNavigate()

  return (
    <Flex>
      <Flex flex={15} grow={1} gap={4}>
        <Flex direction='column' grow={1} gap={2}>
          <Flex justifyContent='space-between' alignItems='center'>
            <Text color={Color.DARK_BLUE}>Automated Transfer Rule</Text>
            <Flex justifyContent='end' gap={2} alignItems='center'>
              {
                isBalanceRule(transferRule.transferRuleType)
                  ? <RecurringRuleBalanceIcon color={Color.DARK_GREY}/>
                  : <RecurringRuleTimeIcon color={Color.DARK_GREY}/>
              }
              <Text whiteSpace='pre-line'>
                {isBalanceRule(transferRule.transferRuleType) ? 'Balance-Based' : 'Time-Based'}
              </Text>
              {menuButton}
            </Flex>
          </Flex>
          {isBalanceRule(transferRule.transferRuleType) ? balanceDescription() : timeDescription()}
        </Flex>
      </Flex>
      <TransferRuleActivationModal
        transferRule={transferRule}
        isModalOpen={isModalOpen}
        onModalClose={() => { setIsModalOpen(false) }}
        onUpdate={() => { onDelete() }}
        activationDirection={ActivationDirection.Deactivate}
      />
    </Flex>
  )
}
