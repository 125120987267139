import React, { type ReactElement } from 'react'
import { GridItem, Text } from '@chakra-ui/react'
import { type TableHeader } from '../Table'
import { Color } from '@/theme/theme'

interface TableCellProps<T> {
  columnMetadata: TableHeader<T>
  columnIndex: number
  row: T
}

export default function TableCell<T> ({
  row,
  columnIndex,
  columnMetadata: {
    key,
    alignment = 'start',
    isReactElement = false
  }
}: TableCellProps<T>): ReactElement {
  return (
    <GridItem
      key={columnIndex}
      colStart={columnIndex + 1}
      justifySelf='left'
      width='100%'
      py={2}
      textAlign={alignment}
    >
      {
        isReactElement
          ? row[key] as unknown as ReactElement
          : <Text noOfLines={1} maxWidth='95%' color={Color.DARK_BLUE}>{String(row[key])}</Text>
        }
    </GridItem>
  )
}
