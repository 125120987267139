import { gql } from '@apollo/client'

export const UPDATE_USERS_ROLES = gql`
    mutation UpdateUsersRoles(
        $userIds: [Int!]!, 
        $franchiseGroupIds: [Int!]!,
        $role: Role!, 
    ) {
        updateUserRoles(
            franchiseGroupIds: $franchiseGroupIds, 
            userIds: $userIds,
            role: $role, 
        )
    }
`
