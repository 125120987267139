import alloy from '@alloyidentity/web-sdk'
import { useState, useEffect } from 'react'
import { getAlloyEnvironment } from '../utils/envUtils'

export interface AlloyDeviceData {
  data: { blackboxToken: string | null }
  loading: boolean
  error: string | null
}

export function useFetchAlloyDeviceData (): AlloyDeviceData {
  const [loading, setLoading] = useState(true)
  const [blackboxToken, setBlackboxToken] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    async function init (): Promise<void> {
      try {
        const response = await alloy.init({
          ...getAlloyEnvironment()
        })
        setBlackboxToken(response.iovationBlackboxId)
      } catch (err) {
        setError(`Unable to load device data ${String(err)}`)
      } finally {
        setLoading(false)
      }
    }

    void init()
  }, [])

  return { data: { blackboxToken }, loading, error }
}
