import React, { useState, type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import GrasshopperMigrationModalHeaderBanner from './GrasshopperMigrationModalHeaderBanner'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { Color } from '@/theme/theme'
import Button, { ButtonVariant } from '@/library/button/Button'
import LinkText from '@/library/text/LinkText'
import { BANK_MIGRATION_FAQ_URL } from '@/theme/urls'
import AltirPartnerLinkGraphic from '@/library/utility/AltirPartnerLinkGraphic'
import { GRASSHOPPER_LOGO } from '@/theme/graphics'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { ROUTES } from '@/api/routes'
import AltirProFeatureBox from '@/library/pro/AltirProFeatureBox'
import AltirProFeatureRow from '@/library/pro/AltirProFeatureRow'
import { type BankMigrationStatus } from '@/graphql/__generated__/globalTypes'
import { hasRequestedMigration } from '@/utils/bankMigrationUtils'
import {
  type DismissBankMigration,
  type DismissBankMigrationVariables
} from '@/graphql/__generated__/DismissBankMigration'
import { DISMISS_BANK_MIGRATION } from '@/graphql/mutations/DismissBankMigration'
import { useAltirStore } from '@/hooks/store/useAltirStore'

interface GrasshopperMigrationCTAModalProps {
  migrationStatus: BankMigrationStatus | null
  hasDismissedBankMigration: boolean
  isLoading: boolean
}

export default function GrasshopperMigrationCTAModal ({
  migrationStatus,
  hasDismissedBankMigration,
  isLoading
}: GrasshopperMigrationCTAModalProps): ReactElement {
  const navigate = useNavigate()
  const organizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const [isOpen, setIsOpen] = useState(
    !isLoading && !hasDismissedBankMigration && !hasRequestedMigration(migrationStatus)
  )

  const [
    dismissBankMigration,
    { loading }
  ] = useMutation<DismissBankMigration, DismissBankMigrationVariables>(
    DISMISS_BANK_MIGRATION,
    {
      variables: { organizationId },
      onCompleted: () => { setIsOpen(false) }
    }
  )

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={() => { setIsOpen(false) }}
      headerBanner={<GrasshopperMigrationModalHeaderBanner/>}
      size='xl'
    >
      <Center w='100%' flexDir='column' gap={8}>
        <AltirPartnerLinkGraphic partnerLogoSrc={GRASSHOPPER_LOGO}/>
        <Center w='100%' flexDir='column' gap={1} textAlign='center'>
          <Heading>Upgrade Your Accounts</Heading>
          <Text>
            We are moving to a new banking partner, Grasshopper Bank.
            View our <LinkText text='FAQs' href={BANK_MIGRATION_FAQ_URL}/> to learn more.
          </Text>
        </Center>
        <AltirProFeatureBox color={Color.DARK_BLUE}>
          <AltirProFeatureRow text='Initiate the upgrade in under 2 minutes' color={Color.DARK_BLUE}/>
          <AltirProFeatureRow text='Continue using our product with minimal interruption' color={Color.DARK_BLUE}/>
          <AltirProFeatureRow text='Once upgraded, access Altir’s upcoming features' color={Color.DARK_BLUE}/>
        </AltirProFeatureBox>
        <Center w='100%' flexDir='column' gap={4}>
          <Button
            text='Get Started'
            afterIcon={<RightArrowIcon/>}
            onClick={() => { navigate(ROUTES.ACCOUNT_UPGRADE) }}
          />
          <Button text='Not Now' variant={ButtonVariant.WHITE_OUTLINE} onClick={() => { setIsOpen(false) }}/>
          <Button
            text="Don't Remind Me Again"
            variant={ButtonVariant.LINK_TRANSPARENT}
            onClick={dismissBankMigration}
            isLoading={loading}
          />
        </Center>
      </Center>
    </ModalComponent>
  )
}
