import React, { type ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { ROUTES } from '@/api/routes'

export default function UnsafeNavigateToErrorAndResetContext (): ReactElement {
  const resetAltirStoreToDefault = useAltirStore(state => state.resetFranchiseGroupAndOrgToDefault)
  resetAltirStoreToDefault()
  return (
    <Navigate to={ROUTES.ERROR} />
  )
}
