import React, { type ReactElement } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import AmplifyStatusBar, { type AmplifyApplicationContext } from './AmplifyStatusBar'
import { Graphic } from '@/library/utility/Graphic'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { ALTIR_LOGO_BLUE_HORIZONTAL } from '@/theme/graphics'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { IconSize } from '@/theme/theme'
import ProgressBar from '@/library/progress/ProgressBar'

export interface OnboardingCallToAction {
  callToActionText?: string
  callToActionOnClick: () => void
  isLoading?: boolean
}

interface OnboardingStatusBarProps {
  callToAction?: OnboardingCallToAction
  amplifyApplicationContext?: AmplifyApplicationContext
  shouldHideProgressBar: boolean
}

export default function OnboardingStatusBar ({
  callToAction,
  amplifyApplicationContext,
  shouldHideProgressBar
}: OnboardingStatusBarProps): ReactElement {
  const location = useLocation()
  const currentIndex = getCurrentStep(location.pathname)

  const isAmplifyOnboarding = amplifyApplicationContext != null

  return (
    <Flex w='100%' justifyContent='space-between' alignItems='center'>
      <Flex flex={1}>
        <Graphic src={ALTIR_LOGO_BLUE_HORIZONTAL} h='36px'/>
      </Flex>
      <Flex flex={6} justifyContent='center'>
        {
          !shouldHideProgressBar && (
            isAmplifyOnboarding
              ? <AmplifyStatusBar amplifyApplicationContext={amplifyApplicationContext}/>
              : <ProgressBar
                  steps={[
                    { title: 'User Profile' },
                    { title: 'Confirm Email' },
                    { title: 'Your Organization' },
                    { title: 'Your Business' }
                    // TODO (PJ): Re-enable once we have accounts live
                    // { title: 'Amplify Application' }
                  ]}
                  currentIndex={currentIndex}
                />
          )
        }
      </Flex>
      <Flex flex={1} alignItems='center'>
        {
            callToAction != null
              ? <Button
                  text={callToAction.callToActionText}
                  variant={ButtonVariant.WHITE}
                  afterIcon={<RightArrowIcon size={IconSize.SMALL}/>}
                  size={ButtonSize.SMALL}
                  width='auto'
                  onClick={callToAction.callToActionOnClick}
                  isLoading={callToAction.isLoading === true}
                />
              : <Box/>
        }
      </Flex>
    </Flex>
  )
}

function getCurrentStep (pathname: string): number {
  if (pathname.includes('user')) {
    return 0
  } else if (pathname.includes('email')) {
    return 1
  } else if (pathname.includes('organization')) {
    return 2
  } else if (pathname.includes('business')) {
    return 3
  } else if (pathname.includes('amplify')) {
    return 4
  }

  return 5
}
