import React, { type ReactElement } from 'react'
import { Flex, Heading, Link, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/api/routes'
import { Graphic } from '@/library/utility/Graphic'
import { GRAPH_GRAPHIC } from '@/library/theme/graphics'
import { BorderRadius, Color, IconSize } from '@/library/theme/theme'
import AccountsIcon from '@/library/icons/AccountsIcon'
import Button from '@/library/button/Button'

interface EmptyGraphSectionProps {
  renderGraphIcon?: boolean
  metricDescription: string
}

export default function EmptyGraphSection ({
  metricDescription,
  renderGraphIcon = false
}: EmptyGraphSectionProps): ReactElement {
  const navigate = useNavigate()
  return (
    <Flex w='100%' justifyContent='center' bg={Color.GREY} borderRadius={BorderRadius.CARD}>
      <Flex
        flexDir='column'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
        maxW='350px'
        gap={4}
        py={32}
      >
        {renderGraphIcon ? <Graphic src={GRAPH_GRAPHIC} h='130px'/> : null}
        <Flex flexDir='column' gap={2}>
          <Heading color={Color.DARK_BLUE} size='lg'>More data needed.</Heading>
          <Text>Not enough data to accurately calculate your {metricDescription}.</Text>
        </Flex>
        <Link onClick={() => { navigate(ROUTES.SETTINGS) }} w='100%'>
          <Button text='Link an Account' beforeIcon={<AccountsIcon size={IconSize.SMALL}/>}/>
        </Link>
      </Flex>
    </Flex>
  )
}
