import React, { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import OnboardingPage from '@/library/page/OnboardingPage'
import AccountUsageComponent
  from '@/components/pages/amplify_account_application/routes/apply/components/account/AccountUsageComponent'
import { useAmplifyAppNavigation } from '@/hooks/onboarding/useAmplifyAppNavigation'
import { ContainerWidth } from '@/theme/theme'

export default function AmplifyApplyUsagePage (): ReactElement {
  const { BUSINESS_ID, AMPLIFY_APPLICATION_ID } = useParams()
  const { skipCallToAction, backLink } = useAmplifyAppNavigation(String(BUSINESS_ID))

  return (
    <OnboardingPage
      maxWidth={ContainerWidth.EXTRA_LARGE}
      callToAction={skipCallToAction}
      amplifyApplicationContext={{ franchiseGroupId: Number(BUSINESS_ID) }}
    >
      <AccountUsageComponent
        franchiseGroupId={Number(BUSINESS_ID)}
        applicationId={String(AMPLIFY_APPLICATION_ID)}
        backLink={backLink}
        context={AmplifyApplicationContext.DEFAULT}
      />
    </OnboardingPage>
  )
}
