import { gql } from '@apollo/client'

export const GET_AMPLIFY_UPSELL_DATA = gql`
    query GetAmplifyUpsellData($organizationId: String!) {
        AltirSubscription(organizationId: $organizationId) {
            status
        }
        amplifyAccountPromotionalOffer {
            subscribedAnnualPercentageYieldFormatted
        }
    }
`
