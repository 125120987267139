import React, { type ReactElement } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Center } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import DisclosuresFormComponent from './DisclosuresFormComponent'
import { ACCEPT_AMPLIFY_TERMS_AND_CONDITIONS } from '@/graphql/mutations/AcceptTermsAndConditions'
import AmplifyApplicationHeader
  from '@/components/pages/v2/sign_up/routes/amplify/routes/components/AmplifyApplicationHeader'
import {
  type AcceptAmplifyTermsAndConditions,
  type AcceptAmplifyTermsAndConditionsVariables
} from '@/graphql/__generated__/AcceptAmplifyTermsAndConditions'
import { GET_ONBOARDING_BUSINESS_BY_ID } from '@/graphql/queries/onboarding/GetOnboardingBusinessById'
import {
  type GetOnboardingBusinessById,
  type GetOnboardingBusinessByIdVariables
} from '@/graphql/__generated__/GetOnboardingBusinessById'
import AltirSkeleton from '@/library/loading/AltirSkeleton'

export interface DisclosuresComponentProps {
  franchiseGroupId: number
  backLink: string
}

export default function DisclosuresComponent (
  {
    franchiseGroupId,
    backLink
  }: DisclosuresComponentProps
): ReactElement {
  const navigate = useNavigate()

  const {
    data,
    loading,
    error
  } = useQuery<GetOnboardingBusinessById, GetOnboardingBusinessByIdVariables>(
    GET_ONBOARDING_BUSINESS_BY_ID,
    { variables: { franchiseGroupId } }
  )

  const [
    acceptTermsAndConditions,
    { loading: acceptTermsAndConditionsLoading, error: acceptTermsAndConditionsError }
  ] = useMutation<AcceptAmplifyTermsAndConditions, AcceptAmplifyTermsAndConditionsVariables>(
    ACCEPT_AMPLIFY_TERMS_AND_CONDITIONS,
    {
      onCompleted: onAction,
      onError: () => {}
    }
  )

  async function handleAcceptTermsAndConditions (): Promise<void> {
    void acceptTermsAndConditions({ variables: { franchiseGroupId } })
  }

  function onAction (): void {
    navigate(backLink)
  }

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center flexDir='column' gap={8} w='100%'>
        <AmplifyApplicationHeader
          backLink={backLink}
          businessName={data?.franchiseGroup?.name ?? null}
          header='Disclosures & Agreements'
          description='Please read and understand the following documents before continuing.'
        />
        <DisclosuresFormComponent
          isLoading={acceptTermsAndConditionsLoading}
          error={acceptTermsAndConditionsError}
          onSubmit={handleAcceptTermsAndConditions}
        />
      </Center>
    </AltirSkeleton>
  )
}
