import React, { useState, useEffect, type ReactElement } from 'react'
import { Flex, Heading, Link } from '@chakra-ui/react'
import { useQuery, useMutation } from '@apollo/client'
import { Event } from 'metrics/metrics'
import AltirProFeatureRow from './AltirProFeatureRow'
import { AltirProUpgradeModalVariant } from './AltirProUpgradeModal'
import AltirProFeatureBox from './AltirProFeatureBox'
import { Graphic } from '../utility/Graphic'
import {
  BorderRadius,
  Color,
  FontWeight
} from '../theme/theme'
import { ALTIR_PRO_BLUE_GRAPHIC } from '../theme/graphics'
import AltirSkeleton from '../loading/AltirSkeleton'
import Button, { ButtonVariant } from '../button/Button'
import SparkleIcon from '../icons/SparkleIcon'
import { getStripeEnvironment } from '@/utils/envUtils'
import { GET_STRIPE_AND_AMPLIFY_PROMO } from '@/graphql/queries/GetStripeCheckoutLink'
import { CREATE_STRIPE_CHECKOUT_LINK } from '@/graphql/mutations/CreateStripeCheckoutLink'
import {
  type GetStripeCheckoutLinkVariables,
  type GetStripeCheckoutLink
} from '@/graphql/__generated__/GetStripeCheckoutLink'
import {
  type CreateStripeCheckoutLinkVariables,
  type CreateStripeCheckoutLink
} from '@/graphql/__generated__/CreateStripeCheckoutLink'

const { STRIPE_CHECKOUT_LINK, STRIPE_PRODUCT_ALTIR_PRO } = getStripeEnvironment()

export interface AltirProUpgradeContentProps {
  franchiseGroupId?: string
  organizationId: string
  productVariant: AltirProUpgradeModalVariant
}

export default function AltirProUpgradeContent ({
  productVariant,
  organizationId
}: AltirProUpgradeContentProps): ReactElement {
  const [isLinkCreated, setIsLinkCreated] = useState(false)
  // default to standard `Altir Pro` plan with 1 x qty
  const [existingCheckoutLink, setExistingCheckoutLink] = useState(STRIPE_CHECKOUT_LINK)
  const [newCheckoutLink, setNewCheckoutLink] = useState<string | undefined>(undefined)
  const [promoCode, setPromoCode] = useState<string | undefined>(undefined)
  const [
    createLink
  ] = useMutation<CreateStripeCheckoutLink, CreateStripeCheckoutLinkVariables>(CREATE_STRIPE_CHECKOUT_LINK)

  const {
    data,
    loading,
    error
  } = useQuery<
  GetStripeCheckoutLink,
  GetStripeCheckoutLinkVariables
  >(GET_STRIPE_AND_AMPLIFY_PROMO,
    {
      variables: {
        organizationId
      },
      onCompleted: (_data) => {
        if (_data?.StripeCheckoutLink?.link !== undefined) {
          setExistingCheckoutLink(_data.StripeCheckoutLink.link)
          setIsLinkCreated(true)
        }
        setPromoCode(_data.PromoCode?.code)
      }
    })

  useEffect(() => {
    if (
      !loading &&
      existingCheckoutLink === STRIPE_CHECKOUT_LINK &&
      !isLinkCreated) {
      const variables: CreateStripeCheckoutLinkVariables = {
        organizationId,
        stripeProductId: STRIPE_PRODUCT_ALTIR_PRO
      }
      void createLink({ variables }).then((response) => {
        const _newCheckoutLink = response?.data?.createStripeCheckoutLink?.link

        if (_newCheckoutLink !== undefined) {
          setIsLinkCreated(true)
          setNewCheckoutLink(_newCheckoutLink)
        }
      })
    }
  }, [loading])

  function getHeaderText (): string {
    switch (productVariant) {
      case AltirProUpgradeModalVariant.AMPLIFY:
        return 'Earn interest on your idle cash.'
      case AltirProUpgradeModalVariant.DASHBOARD:
        return 'Enhance your financial intelligence.'
      case AltirProUpgradeModalVariant.LENDING:
        return 'Get pre-qualified for a revolving loan.'
    }
  }

  function buildPromoQueryString (promoCode: string | undefined): string {
    if (promoCode !== undefined) return `&prefilled_promo_code=${String(promoCode)}`
    return ''
  }

  const potentialYield = data?.amplifyAccountPromotionalOffer?.subscribedAnnualPercentageYieldFormatted ?? ''

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Flex
        flexDirection='column'
        width='100%'
        py={10}
        px={20}
        borderRadius={BorderRadius.CARD}
        gap={10}
      >
        <Graphic
          w='auto'
          h='83px'
          src={ALTIR_PRO_BLUE_GRAPHIC}
        />
        <Heading
          color={Color.DARK_BLUE}
          size='lg'
          fontWeight={FontWeight.MEDIUM}
          textAlign='center'
        >
          {getHeaderText()}
        </Heading>
        <Flex flexDir='column'>
          <Heading color={Color.BRIGHT_BLUE} pb={2} size='sm' fontWeight={FontWeight.BOLD}>PRO FEATURES</Heading>
          <AltirProFeatureBox>
            <AltirProFeatureRow text='Pre-approved, revolving and lines of credit'/>
            <AltirProFeatureRow text={`${potentialYield} APY on cash in your Amplify Account`}/>
            <AltirProFeatureRow text='Dashboard with real-time financial insights'/>
          </AltirProFeatureBox>
        </Flex>
        <Link _hover={{ textDecoration: 'none' }}
          href={`${newCheckoutLink !== undefined ? newCheckoutLink : existingCheckoutLink}` +
          `?client_reference_id=${organizationId}` +
          `${buildPromoQueryString(promoCode)}`}
          width='100%'
        >
          <Button
            text='Upgrade'
            variant={ButtonVariant.PRIMARY}
            beforeIcon={<SparkleIcon/>}
            onClickEventType={Event.ALTIR_UPGRADE_MODAL_STRIPE_REDIRECT_CLICK}
          />
        </Link>
      </Flex>
    </AltirSkeleton>
  )
}
