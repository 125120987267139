import React, { useEffect, useState, type ReactElement } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { BorderRadius, FontWeight, IconSize, Color } from '../theme/theme'
import CircleSolidErrorIcon from '../icons/CircleSolidErrorIcon'
import CloseIcon from '../icons/CloseIcon'
import { type ErrorWithContent } from '@/types/types'
import { getErrorContent } from '@/utils/errorUtils'

interface ErrorInlineProps {
  error?: Error | ErrorWithContent
}

// TODO (PJ): Update signature to accept ErrorWithContent
export default function ErrorInline ({ error }: ErrorInlineProps): ReactElement {
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState<boolean>(true)
  useEffect(() => {
    // We only want this to happen after page load and on a prop change
    setIsErrorMessageDisplayed(true)
  }, [error])

  if (
    !isErrorMessageDisplayed || error == null) {
    return <></>
  }

  const { title, subtitle } = getErrorContent(error)

  return (
    <Box
      borderRadius={BorderRadius.CARD}
      flexDirection='column'
      width='100%'
      backgroundColor={Color.ERROR_RED}
    >
      <Flex flexDir='row' p={4} gap={4} grow={0} >
        <Flex my='auto' >
          <CircleSolidErrorIcon color={Color.WHITE} />
        </Flex>
        <Flex flexDir='column'grow={1} alignItems='start' textAlign='start'>
          <Text color={Color.WHITE} fontSize='md' fontWeight={FontWeight.BOLD}>{title}</Text>
          <Text color={Color.WHITE} fontSize='sm' fontWeight={FontWeight.BOLD}>{subtitle}</Text>
        </Flex>
        <Flex
          onClick={() => { setIsErrorMessageDisplayed(false) }}
          alignItems='start'
          _hover={{ cursor: 'pointer' }}
        >
          <CloseIcon color={Color.WHITE} size={IconSize.SMALL} />
        </Flex>
      </Flex>
    </Box>
  )
}
