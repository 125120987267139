import { useMutation } from '@apollo/client'
import { LOG_PLATFORM_EVENT } from '@/graphql/mutations/LogPlatformEvent'
import { type PlatformEventType } from '@/graphql/__generated__/globalTypes'
import { type LogPlatformEvent, type LogPlatformEventVariables } from '@/graphql/__generated__/LogPlatformEvent'

interface UsePlatformEventSignature { logEvent: (type: PlatformEventType) => void }

export function useLogPlatformEvent (): UsePlatformEventSignature {
  const [logPlatformEvent] = useMutation<LogPlatformEvent, LogPlatformEventVariables>(LOG_PLATFORM_EVENT)

  function logEvent (type: PlatformEventType): void {
    logPlatformEvent({ variables: { type } })
      .then(() => {})
      .catch(() => {})
  }

  return { logEvent }
}
