import React, { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import OnboardingPage from '@/library/page/OnboardingPage'
import { useOnboardingAmplifyAppNavigation } from '@/hooks/onboarding/useOnboardingAmplifyAppNavigateion'
import BusinessApplicationComponent
  from '@/components/pages/amplify_account_application/routes/apply/components/business/BusinessApplicationComponent'
import {
  AmplifyApplicationContext
} from '@/components/pages/amplify_account_application/routes/apply/AmplifyApplicationContainerComponent'
import { ContainerWidth } from '@/theme/theme'

export default function OnboardingAmplifyApplyBusinessPage (): ReactElement {
  const { BUSINESS_ID, BUSINESS_APPLICATION_ID } = useParams()
  const { skipCallToAction, backLink } = useOnboardingAmplifyAppNavigation(String(BUSINESS_ID))

  return (
    <OnboardingPage
      maxWidth={ContainerWidth.LARGE}
      callToAction={skipCallToAction}
      amplifyApplicationContext={{ franchiseGroupId: Number(BUSINESS_ID) }}
    >
      <BusinessApplicationComponent
        franchiseGroupId={Number(BUSINESS_ID)}
        businessApplicationId={String(BUSINESS_APPLICATION_ID)}
        backLink={backLink}
        context={AmplifyApplicationContext.ONBOARDING}
      />
    </OnboardingPage>
  )
}
