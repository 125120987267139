import { type ApolloError, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useAltirStore } from '../store/useAltirStore'
import {
  type GetOrganizationSettingsVariables,
  type GetOrganizationSettings
} from '@/graphql/__generated__/GetOrganizationSettings'
import { GET_ORGANIZATION_SETTINGS } from '@/graphql/queries/settings/GetOrganizationSettings'

interface OrganizationSettings {
  data?: GetOrganizationSettings
  loading: boolean
  error: ApolloError | undefined
  refetch: () => void
}

/**
 * This hook exposes the GetOrganizationSettings query whilst preventing it
 * from infiitely re-rendering. It's not clear why we need to do this.
 *
 * https://altirgroup.slack.com/archives/C056A6G2WCX/p1718911572888359
 */
export function useFetchOrganizationSettings (): OrganizationSettings {
  const organizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)

  const [prevOrganizationId, setPrevOrganizationId] = useState(organizationId)
  const [data, setData] = useState<GetOrganizationSettings | undefined>()

  const {
    loading,
    error,
    refetch: apolloRefetch
  } = useQuery<GetOrganizationSettings, GetOrganizationSettingsVariables>(
    GET_ORGANIZATION_SETTINGS,
    {
      variables: { organizationId },
      skip: organizationId === prevOrganizationId && data != null,
      onCompleted: (data) => { setData(data) }
    }
  )

  useEffect(() => {
    if (organizationId !== prevOrganizationId) {
      void apolloRefetch({ organizationId })
      setPrevOrganizationId(organizationId)
    }
  }, [organizationId, prevOrganizationId, apolloRefetch])

  function refetch (): void {
    void apolloRefetch({ organizationId })
  }

  return {
    data, loading, error, refetch
  }
}
