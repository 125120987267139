import { Center, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { DisclosureText } from './disclosureText'
import { FontWeight } from '@/theme/theme'

export default function OnboardingDisclosureFooter (): ReactElement {
  return (
    <Center flexDir='column' gap={3} w='100%'>
      <Text fontSize='sm' fontWeight={FontWeight.NORMAL}>
        {DisclosureText.APY_DISCLAIMER_FOOT_NOTE}
      </Text>
      <Text fontSize='sm' fontWeight={FontWeight.NORMAL}>
        {DisclosureText.ALTIR_IS_NOT_A_BANK}
      </Text>
      <Text fontSize='sm' fontWeight={FontWeight.NORMAL}>
        {DisclosureText.INTRAFI_DISCLAIMER}
      </Text>
    </Center>
  )
}
