import { Flex } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import ErrorBanner from './ErrorBanner'
import RecurringIcon from '../icons/RecurringIcon'
import ReLinkAccountButton from '../../clients/plaid/ReLinkAccountButton'
import Button, { ButtonVariant } from '../button/Button'
import { ROUTES } from '@/api/routes'
import { type InstitutionConnectionProvider } from '@/graphql/__generated__/globalTypes'

const ReLinkTitle = 'Bank Disconnected'
const ReLinkSubTitle =
`Bank accounts periodically require re-authentication. 
The information below may not be up-to-date. Reconnect to continue using Altir.`

const reLinkTitleWithInstitutionName = (institutionName: string): string => `Your ${institutionName} account has been disconnected`

interface ReLinkErrorBannerProps {
  franchiseGroupId: number
  plaidAccessToken?: string
  plaidItemId?: string
  refetchData?: () => void
  institutionName?: string
  connectionProvider?: InstitutionConnectionProvider
  customButton?: ReactElement
  customTitle?: string
  customSubTitle?: string
}
export function ReLinkErrorBanner (
  {
    franchiseGroupId,
    plaidAccessToken,
    plaidItemId,
    refetchData,
    institutionName,
    connectionProvider,
    customButton,
    customTitle,
    customSubTitle
  }: ReLinkErrorBannerProps): ReactElement {
  const navigate = useNavigate()
  const shouldShowReLink =
    plaidAccessToken != null && plaidItemId != null && refetchData != null && connectionProvider != null
  const defaultTitle = institutionName == null ? ReLinkTitle : reLinkTitleWithInstitutionName(institutionName)
  const title = customTitle ?? defaultTitle
  const subtitle = customSubTitle ?? ReLinkSubTitle

  const defaultButton = (
    <Button
      text='Fix Link'
      variant={ButtonVariant.DESTRUCTIVE}
    />
  )
  return (
    <Flex my='4' w='100%'>
      <ErrorBanner
        errorTitle={title}
        errorSubTitle={subtitle}
        inlineCallToActionComponent={
          <Flex ml={6} >
            {shouldShowReLink
              ? <ReLinkAccountButton
                  franchiseGroupId={franchiseGroupId}
                  handleBankAccountLinkUpdate={refetchData}
                  plaidAccessToken={plaidAccessToken}
                  plaidItemId={plaidItemId}
                  connectionProvider={connectionProvider}
                  customButton={customButton ?? defaultButton}
                />
              : <Button
                  variant={ButtonVariant.DESTRUCTIVE}
                  onClick={() => { navigate(ROUTES.SETTINGS, { state: { isLinkedAccountsSelected: true } }) }}
                  text='Reconnect'
                  beforeIcon={<RecurringIcon/>}
                  width='60'
                />
             }
          </Flex>}
      />
    </Flex>
  )
}
