import React, { useState, type ReactElement } from 'react'
import LogOutModal from '../../../modal/log_out/LogOutModal'
import PopoverIconMenu from '../../../popoverMenu/PopoverIconMenu'
import LogOutIcon from '../../../icons/LogOutIcon'
import { Color, IconSize } from '@/theme/theme'

export default function NavBarLogoutMenu (): ReactElement {
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false)
  return (
    <>
      <PopoverIconMenu
        menuOptions={[
          {
            text: 'Logout',
            onClick: () => { setIsLogOutModalOpen(true) },
            color: Color.ERROR_RED,
            beforeIcon: <LogOutIcon color={Color.ERROR_RED} size={IconSize.SMALL}/>
          }
        ]}
      />
      <LogOutModal isOpen={isLogOutModalOpen} onClose={() => { setIsLogOutModalOpen(false) }}/>
    </>
  )
}
