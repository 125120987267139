import { gql } from '@apollo/client'

export const TEAM_MEMBER_FRAGMENT = gql`
    fragment TeamMemberFragment on User {
        id
        firstName
        lastName
        email
        lastActivity
        roles {
            franchiseGroupId
            role
        }
        organizationRoles {
            organizationId
            role
        }
    }
`
