
import { gql } from '@apollo/client'

export const GET_ONBOARDING_ORGANIZATION = gql`
    query GetOnboardingOrganization {
        currentUser {
            id
            firstName
            lastName
            selectedOrganization {
                id
                name
                franchiseGroups {
                  id
                  name
                  amplifyAccountApplicationStatus
                }
            }
        }
    }
`
