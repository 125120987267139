/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function RecurringXIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M4.0658 13.9624C3.70897 12.1569 3.97864 10.2836 4.83027 8.65203C5.68189 7.02044 7.06454 5.72811 8.74986 4.98847C10.4352 4.24884 12.3224 4.10614 14.0997 4.58394C15.8771 5.06174 17.4384 6.13146 18.5257 7.61644' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M19.934 10.8265C20.2908 12.6321 20.0211 14.5053 19.1695 16.1369C18.3179 17.7685 16.9352 19.0608 15.2499 19.8005C13.5646 20.5401 11.6774 20.6828 9.90002 20.205C8.12264 19.7272 6.56139 18.6575 5.4741 17.1725' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M18.7419 7.71837V4.30664' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M5.25757 17.0697L5.25757 20.4814' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M18.7422 7.94419V4.53247' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M5.25781 17.2956L5.25781 20.7073' stroke={color} strokeLinecap='round' strokeLinejoin='round'/>
        <path d='M9.45361 9.84814L14.5463 14.9409' stroke={color} strokeLinecap='round'/>
        <path d='M9.45361 14.9414L14.5463 9.84867' stroke={color} strokeLinecap='round'/>
      </svg>
    </IconContainer>
  )
}
