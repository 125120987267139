import React, { useState, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import OrganizationInfoComponent from './OrganizationInfoComponent'
import { getUserRolesForOrganization } from './utils'
import OrganizationTeamSettings from './OrganizationTeamSettings'
import OrganizationBillingComponent from './billing/OrganizationBillingComponent'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import DropDownComponent from '@/library/layout/drop_down/DropDownComponent'
import UserIcon from '@/library/icons/UserIcon'
import { Color } from '@/theme/theme'
import SparkleIcon from '@/library/icons/SparkleIcon'
import { AuthAction, hasAccess } from '@/utils/authUtils'
import { AuthResource } from '@/graphql/__generated__/globalTypes'
import { useNavigationState } from '@/hooks/useNavigationState'
import { useFetchOrganizationSettings } from '@/hooks/data/useFetchOrganizationSettings'
import { GET_ALTIR_SUBSCRIPTION_DETAILS } from '@/graphql/queries/GetAltirSubscriptionDetails'
import {
  type GetAltirSubscriptionDetailsVariables,
  type GetAltirSubscriptionDetails
} from '@/graphql/__generated__/GetAltirSubscriptionDetails'
import MockDropDownComponent from '@/library/layout/drop_down/MockDropDownComponent'
import HelpRaftIcon from '@/library/icons/HelpRaftIcon'
import ContactUsModal from '@/library/nav/help_center/ContactUsModal'

interface OrganizationSettingsNavigationState {
  isTeamSettingsSelected: boolean | false
  isPlanDetailsSelected: boolean | false
  isBusinessSettingsPreselected: boolean | false
}

export default function OrganizationSettingsComponent (): ReactElement {
  const stateOnNavigate = useNavigationState<OrganizationSettingsNavigationState>()
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false)
  const { data, loading, error, refetch } = useFetchOrganizationSettings()

  const isTeamSettingsPreSelected = stateOnNavigate?.isTeamSettingsSelected ?? false
  const { data: subscriptionDetails } = useQuery<GetAltirSubscriptionDetails, GetAltirSubscriptionDetailsVariables>(
    GET_ALTIR_SUBSCRIPTION_DETAILS,
    { variables: { organizationId: selectedOrganizationId } }
  )

  const isPlanDetailsPreSelected = stateOnNavigate?.isPlanDetailsSelected ?? false

  const invitedUsers = data?.currentUser?.selectedOrganization?.invitedUsers ?? []
  const userRolesForOrganization = getUserRolesForOrganization(
    selectedOrganizationId,
    data?.currentUser?.selectedOrganization?.name ?? null,
    data?.currentUser?.selectedOrganization?.franchiseGroups ?? [],
    data?.currentUser?.selectedOrganization?.users ?? []
  )
  const canManageUsers = hasAccess(
    AuthResource.USER, AuthAction.WRITE, data?.currentUser?.authorization ?? []
  )
  const canManageInvites = hasAccess(
    AuthResource.USER_INVITE, AuthAction.WRITE, data?.currentUser?.authorization ?? []
  )

  const organizationBusinesses = data?.currentUser?.selectedOrganization?.franchiseGroups ?? []

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Flex flexDirection='column' width='100%' gap={6}>
        <OrganizationInfoComponent organization={data?.currentUser?.selectedOrganization ?? undefined} />
        <Flex flexDir='column' gap={2}>
          <DropDownComponent
            title='Team'
            icon={<UserIcon color={Color.DARK_BLUE}/>}
            isOpenOnStart={isTeamSettingsPreSelected}
            dropDownContent={
              <OrganizationTeamSettings
                users={userRolesForOrganization}
                invitedUsers={invitedUsers}
                refetchSettings={refetch}
                canManageUsers={canManageUsers}
                canManageInvites={canManageInvites}
                organizationBusinesses={organizationBusinesses}
              />
            }
          />
          <DropDownComponent
            title='Plan Details'
            icon={<SparkleIcon color={Color.DARK_BLUE}/>}
            isOpenOnStart={isPlanDetailsPreSelected}
            dropDownContent={<OrganizationBillingComponent subscriptionDetails={subscriptionDetails} />}
          />
          <Flex onClick={() => { setIsContactUsModalOpen(true) }}>
            <MockDropDownComponent
              title='Help & Support'
              icon={<HelpRaftIcon color={Color.DARK_BLUE}/>}
            />
            <ContactUsModal
              isOpen={isContactUsModalOpen}
              setIsOpen={setIsContactUsModalOpen}
            />
          </Flex>
        </Flex>
      </Flex>
    </AltirSkeleton>
  )
}
