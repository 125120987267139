import { gql } from '@apollo/client'

export const LOG_INSTITUTION_CONNECTION_EVENT = gql`
    mutation LogInstitutionLinkEvent(
        $connectionEventJson: String!, 
        $isConnectionEventError: Boolean!, 
        $connectionProvider: InstitutionConnectionProvider!
    ) {
        logInstitutionLinkEvent(
            connectionEventJson: $connectionEventJson, 
            isConnectionEventError: $isConnectionEventError, 
            connectionProvider: $connectionProvider
        )
  }
`
