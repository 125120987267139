import React, { type ReactElement } from 'react'
import { Flex, Grid, GridItem, Divider, Text, Center } from '@chakra-ui/react'
import { type OrganizationBillingComponentProps } from '../OrganizationBillingComponent'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { Color, BorderRadius, IconSize } from '@/theme/theme'
import { formatEpochTime } from '@/utils/dateUtils'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { getStripeEnvironment } from '@/utils/envUtils'
import CloseIcon from '@/library/icons/CloseIcon'

export default function SubscriptionHistoryComponent (
  subscriptionDetails?: OrganizationBillingComponentProps
): ReactElement {
  const { STRIPE_ACCOUNT_LINK } = getStripeEnvironment()
  const subscription = subscriptionDetails?.subscriptionDetails?.AltirSubscription
  const plan = subscription?.product.name
  const quantity = subscription?.quantity
  const periodEnd = subscription?.subscription?.periodEnd
  const planTotalPrice = subscription?.invoices[0]?.total ?? 0
  const planUnitPrice = (planTotalPrice ?? 0) / (quantity ?? 1)
  const planTotalPriceFormatted = getCurrencyFormatted(planTotalPrice / 100, { minimumFractionDigits: 2 })
  const planUnitPriceFormatted = getCurrencyFormatted(planUnitPrice / 100, { minimumFractionDigits: 2 })

  return (
    <Center flexDir='column' w='full' gap={3}>
      <Flex flexDir='row' w='full' gap={1}>
        <Text color={Color.DARK_GREY}>Current Plan: </Text>
        <Text color={Color.BRIGHT_BLUE}> {plan}</Text>
      </Flex>
      <Grid
        minH='300px'
        p={6}
        templateColumns='repeat(2, minmax(0, 1fr))'
        borderRadius={BorderRadius.CARD}
        backgroundColor={Color.LIGHT_GREY}
        w='full'
        gridRowGap={4}
      >
        <GridItem justifySelf='start'><Text color={Color.DARK_BLUE}>{plan} Plan</Text></GridItem>
        <GridItem justifySelf='end' textAlign='end'>
          <Text color={Color.DARK_BLUE}>{planUnitPriceFormatted}</Text>
          <Text color={Color.DARK_GREY}>Per Unit</Text>
        </GridItem>
        <GridItem justifySelf='start'>
          <Text color={Color.DARK_BLUE}>x {quantity} Unit(s)</Text>
        </GridItem>
        <GridItem justifySelf='end' textAlign='end'>
          <Text color={Color.DARK_BLUE}>{planTotalPriceFormatted}</Text>
        </GridItem>
        <GridItem colStart={1} colEnd={3}>
          <Divider color={Color.GREY}/>
        </GridItem>
        <GridItem justifySelf='start'><Text color={Color.DARK_BLUE}>Total</Text></GridItem>
        <GridItem justifySelf='end' textAlign='end'>
          <Text align='end' color={Color.DARK_BLUE}>{planTotalPriceFormatted}</Text>
          <Text color={Color.DARK_GREY}>Billed Monthly</Text>
        </GridItem>
        <GridItem colStart={1} colEnd={3}>
          <Divider color={Color.DARK_GREY}/>
        </GridItem>
        {
          periodEnd != null &&
          <GridItem colStart={2} justifySelf='end'>
            <Text color={Color.DARK_GREY}>Your plan renews on {formatEpochTime(periodEnd)}</Text>
          </GridItem>
        }
        <GridItem colStart={1} colEnd={3} justifySelf='end' pt={2}>
          <Button
            text='Cancel Plan'
            onClick={() => { window?.open(STRIPE_ACCOUNT_LINK, '_blank')?.focus() }}
            variant={ButtonVariant.DESTRUCTIVE_TRANSPARENT}
            width='auto'
            beforeIcon={<CloseIcon size={IconSize.SCHMEDIUM}/>}
            size={ButtonSize.SMALL}
          />
        </GridItem>
      </Grid>
    </Center>
  )
}
