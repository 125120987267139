import React, { type ReactElement } from 'react'
import { Text } from '@chakra-ui/react'
import { Color, FontWeight } from '@/theme/theme'

export enum DisclosureType {
  DISCLOSURES,
  TERMS_OF_SERVICE,
  PRIVACY_POLICY
}

export interface DisclosureTextComponentProps {
  disclosureType: DisclosureType
}

const PRIVACY_POLICY_CONTENT = `
    By clicking I AGREE, lorem ipsum dolor sit amet consectetur adipiscing elit orci per, curae leo nec suspendisse facilisis morbi mi habitasse, convallis ultricies auctor sem sociosqu rutrum tristique ullamcorper. Et justo non nisi ad phasellus gravida ac lacinia, porta aenean class vestibulum congue fermentum dictum rutrum cum, fringilla curabitur quam imperdiet semper taciti lacus.

    Ad venenatis condimentum quisque maecenas libero curabitur, sapien montes vel suscipit aliquet sagittis, pretium purus parturient ornare cras.

    Sem consequat quis pulvinar habitant rhoncus quam congue commodo, molestie tortor dui fringilla parturient venenatis laoreet, neque netus cubilia aenean facilisis pellentesque nec facilisi, magnis libero ad volutpat metus feugiat. Vivamus conubia donec cubilia malesuada magna senectus in est cum, dictumst euismod et suspendisse fames tempor consequat nulla aliquet, quisque dapibus viverra ridiculus ligula inceptos nisi imperdiet. Odio consequat platea per cum facilisi nullam habitant sociis ornare montes molestie, id rutrum turpis volutpat dapibus class ad elementum suspendisse proin, eros leo penatibus porttitor pharetra ullamcorper laoreet metus diam donec. Diam fermentum donec massa iaculis dapibus nisi class magnis vitae, laoreet penatibus integer porttitor dictumst sem sagittis luctus.

    Interdum etiam aliquam ante quis imperdiet euismod hac nam diam dui molestie, dictum tortor eu massa purus tellus aptent nullam nunc sociis. Hendrerit mollis himenaeos et phasellus ante curae fermentum interdum aliquet lacus venenatis habitant, nibh rhoncus quis diam turpis commodo euismod nisl sociosqu ultricies. Montes eros blandit magnis elementum bibendum morbi scelerisque dignissim, ridiculus congue nisl nam commodo suscipit nunc iaculis, mattis conubia aliquet sociis hac pellentesque urna.

    Senectus nisl integer dapibus facilisis maecenas habitasse vitae, duis volutpat rhoncus enim mi luctus leo, rutrum in montes vulputate penatibus per. Iaculis placerat sed donec condimentum fermentum platea vel tortor integer, mus varius massa cum nascetur feugiat curae euismod. Mollis nascetur curae arcu mus cursus ridiculus sodales suspendisse fames, commodo egestas nisl volutpat himenaeos odio nibh torquent et id, nec congue lacinia pharetra sociis vivamus dignissim inceptos.

    Metus elementum ullamcorper rutrum facilisis feugiat tellus neque lacinia ut risus quam, viverra sociosqu congue nam in tristique per pretium fusce vestibulum. Natoque molestie ligula laoreet quisque malesuada ornare parturient arcu cursus, aliquet vivamus nostra himenaeos odio class conubia risus senectus, tristique suscipit convallis erat sem pharetra commodo diam. Eleifend nostra per mollis odio purus et tempor rhoncus porttitor, consequat auctor at elementum ad eros viverra curabitur class non, augue potenti fames gravida a vel dapibus inceptos.

    Sollicitudin duis condimentum ligula semper facilisi luctus erat cras senectus curabitur rutrum, faucibus sem malesuada odio mauris est nibh mus ante. Etiam placerat purus ultrices sagittis phasellus tortor nec ligula aptent, himenaeos rhoncus fringilla dui eu mi curae sapien. Vitae porta senectus suscipit praesent nascetur magnis sed curae, semper molestie a sociis ante non ornare bibendum, est tellus facilisi mauris sagittis torquent lobortis. Eros porttitor gravida luctus magnis lacus hendrerit aenean vitae velit, magna ultrices vulputate mi arcu neque fermentum porta inceptos, faucibus fames duis vehicula consequat conubia natoque viverra.

    Suscipit mus vivamus conubia tincidunt rhoncus platea aliquet nam, lacus cubilia scelerisque ornare maecenas vel porta, dictum vestibulum malesuada a montes natoque magnis. Dictumst suspendisse habitasse per dictum turpis semper consequat gravida auctor etiam, leo fringilla fermentum faucibus donec felis hendrerit potenti euismod mus dapibus, justo nulla iaculis vitae malesuada massa purus odio cras. Imperdiet himenaeos orci proin aliquet primis, fermentum convallis posuere magnis, vestibulum bibendum mi viverra.

    Cursus sodales potenti lectus nisl sed ut, ante nisi platea per semper commodo, odio bibendum netus ad consequat. Volutpat pretium molestie eleifend mauris egestas donec cursus urna, semper primis erat non senectus inceptos pharetra. Auctor quisque viverra id eu torquent volutpat ultricies curabitur praesent, sollicitudin eros eleifend malesuada varius gravida vestibulum accumsan euismod, habitant platea litora libero habitasse fusce facilisis lobortis.
    Aptent etiam suscipit nisl duis diam magna facilisis curae vulputate, libero sollicitudin pulvinar dignissim at elementum vitae. 

    Taciti feugiat pretium dapibus pulvinar inceptos dignissim semper ligula laoreet, vivamus purus leo condimentum tempor curae eget. Himenaeos hendrerit euismod ac leo turpis integer ornare rhoncus imperdiet, pellentesque torquent purus suspendisse diam fusce vehicula volutpat mauris, accumsan sed fringilla pulvinar conubia magnis tempus aenean. Cum nulla est ante mi magna neque rhoncus urna, mollis parturient turpis pellentesque dictum condimentum ligula rutrum dignissim, enim sociosqu montes aliquet risus himenaeos eget.
  `

export function DisclosureTextComponent ({ disclosureType }: DisclosureTextComponentProps): ReactElement {
  function getContent (): string {
    if (disclosureType === DisclosureType.PRIVACY_POLICY) {
      return PRIVACY_POLICY_CONTENT
    }
    return 'TODO'
  }
  return (
    <Text fontWeight={FontWeight.BOLD} color={Color.DARK_GREY}>{getContent()}</Text>
  )
}
