import React, { type ReactElement } from 'react'
import { Divider, Flex, Text } from '@chakra-ui/react'
import { Color } from '@/theme/theme'

export interface AutomatedRuleTreasuryDropdownCardTextRowProps {
  header: string
  value: string
  shouldIncludeDivider?: boolean
  isLargeText?: boolean
  flexHeader?: number
  flexValue?: number
}

export function AutomatedRuleTreasuryDropdownCardTextRow (
  {
    header,
    value,
    shouldIncludeDivider = true,
    isLargeText = false,
    flexHeader = 8,
    flexValue = 2
  }: AutomatedRuleTreasuryDropdownCardTextRowProps
): ReactElement {
  return (
    <>
      <Flex alignItems='baseline'>
        <Text flex={flexHeader}> {header} </Text>
        <Text
          flex={flexValue}
          textColor={Color.DARK_BLUE}
          align='right'
          {...(isLargeText ? { fontSize: '2xl' } : {})}
        > {value} </Text>
      </Flex>
      {shouldIncludeDivider && <Divider my={2} borderColor={Color.MEDIUM_GREY}/>}
    </>
  )
}
