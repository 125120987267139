import React, { type ReactElement } from 'react'
import { SimpleGrid } from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import LoanOfferCardComponent from './LoanOfferCardComponent'
import { EmptyLoanOffersComponent } from './EmptyOffersComponent'
import {
  type GetLendingPageData_currentUser_franchiseGroup_preApprovedLendingOffers as Offer
} from '@/graphql/__generated__/GetLendingPageData'

export interface LoanOffersComponentProps {
  preApprovalOffers: Offer[]
  genericOffers: Offer[]
}

export default function LoanOffersComponent (
  { preApprovalOffers, genericOffers }: LoanOffersComponentProps
): ReactElement {
  return (
    <SimpleGrid columns={2} spacing={4}>
      <EmptyLoanOffersComponent/>
      {preApprovalOffers.map(offer => {
        return (
          <LoanOfferCardComponent key={nanoid()} offer={offer} isPreApprovalOffer/>
        )
      })}
      {genericOffers.map(offer => {
        return (
          <LoanOfferCardComponent key={nanoid()} offer={offer}/>
        )
      })}
    </SimpleGrid>
  )
}
