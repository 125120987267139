import { gql } from '@apollo/client'
import { TRACKED_JOB_FRAGMENT } from '../TrackedJobFragment'

export const FINANCIAL_ACCOUNT_SHORT_FRAGMENT = gql`
    ${TRACKED_JOB_FRAGMENT}
    fragment FinancialAccountShortFragment on FinancialAccount {
        id
        accountId
        name
        lastFour
        businessName
        liveBalance {
            availableBalance {
                amount
            }
        }
        amplifyAccount {
            id
            earningsToDate {
                amount
            }
        }
        trackedJob {
            ...TrackedJobFragment
        }
    }
`
