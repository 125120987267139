import React, { useState, type ReactElement } from 'react'
import { Center, Flex, Heading, Switch, Text, useToast } from '@chakra-ui/react'
import { useMutation, useQuery } from '@apollo/client'
import EditUserModal from './EditUserModal'
import { AvatarComponent } from '@/library/utility/AvatarComponent'
import { type GetCurrentUserVariables, type GetCurrentUser } from '@/graphql/__generated__/GetCurrentUser'
import { GET_CURRENT_USER } from '@/graphql/queries/GetCurrentUser'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { getFormattedFullName, safeGetFirstLetter } from '@/utils/stringUtils'
import { Color, IconSize } from '@/theme/theme'
import Button, { ButtonVariant } from '@/library/button/Button'
import EditIcon from '@/library/icons/EditIcon'
import { type NullableUser } from '@/library/layout/user_row/UserInfoRow'
import { getErrorToast, getSuccessToast } from '@/utils/toastUtils'
import DropDownComponent from '@/library/layout/drop_down/DropDownComponent'
import NotificationBellIcon from '@/library/icons/NotificationBellIcon'
import { UPDATE_USER_EMAIL_PREFERENCES } from '@/graphql/mutations/settings/UpdateUserEmailPreferences'
import {
  type UpdateUserEmailPreferencesVariables,
  type UpdateUserEmailPreferences
} from '@/graphql/__generated__/UpdateUserEmailPreferences'

export default function UserSettingsComponent (): ReactElement {
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false)
  const [editUserFormData, setEditUserFormData] = useState<NullableUser>()

  const toast = useToast()

  const { data, loading, error, refetch } = useQuery<GetCurrentUser, GetCurrentUserVariables>(
    GET_CURRENT_USER, {
      variables: {}, // Ensures the linter will check for missing variables
      onCompleted: (data) => {
        if (data.currentUser != null) setEditUserFormData({ ...data.currentUser, id: Number(data.currentUser.id) })
      }
    })

  const [
    updateUserEmailPreferences
  ] = useMutation<UpdateUserEmailPreferences, UpdateUserEmailPreferencesVariables>(
    UPDATE_USER_EMAIL_PREFERENCES,
    {
      onError: () => {
        toast(getErrorToast('Unable to update email notification settings'))
      }
    }
  )

  function onUserEmailNotificationToggle (event: React.ChangeEvent<HTMLInputElement>): void {
    const { target: { checked } } = event
    void updateUserEmailPreferences({ variables: { input: { areEmailNotificationsEnabled: checked } } })
      .then(() => {
        void refetch()
        const updatedState = checked ? 'enabled' : 'disabled'
        toast(getSuccessToast(`Email notifications ${updatedState}`))
      })
  }

  const user = data?.currentUser
  const name = getFormattedFullName(user?.firstName, user?.lastName)

  const dropDownContent = (
    <Flex justifyContent='space-between'>
      <Heading size='sm' color={Color.DARK_BLUE}>Email Notifications</Heading>
      <Switch
        id='areEmailNotificationsEnabled'
        isChecked={user?.areEmailNotificationsEnabled ?? false}
        onChange={onUserEmailNotificationToggle}
      />
    </Flex>
  )

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center flexDir='column' gap={6} w='100%'>
        <Center flexDir='column' textAlign='center' gap={4}>
          <AvatarComponent text={`${safeGetFirstLetter(user?.firstName)}${safeGetFirstLetter(user?.lastName)}`}/>
          <Flex flexDir='column'>
            <Text color={Color.DARK_BLUE}>{name}</Text>
            <Text>{user?.email}</Text>
          </Flex>
          <Flex>
            <Button
              text='Edit'
              beforeIcon={<EditIcon size={IconSize.SMALL}/>}
              variant={ButtonVariant.WHITE}
              onClick={() => { setIsEditUserModalOpen(true) }}
            />
          </Flex>
        </Center>
        <Flex w='100%'>
          <DropDownComponent
            title='Notifications'
            icon={<NotificationBellIcon color={Color.DARK_BLUE} size={IconSize.MEDIUM}/>}
            isOpenOnStart={true}
            dropDownContent={dropDownContent}
          />
        </Flex>
      </Center>
      <EditUserModal
        isOpen={isEditUserModalOpen}
        onClose={() => { setIsEditUserModalOpen(false) }}
        user={editUserFormData}
        refetch={refetch}
      />
    </AltirSkeleton>
  )
}
