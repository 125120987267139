import React from 'react'
import { type ReactElement } from 'react'
import { PlaidComponent } from './PlaidComponent'
import TellerConnect from '../teller/TellerConnect'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import AccountsIcon from '@/library/icons/AccountsIcon'
import { Color } from '@/library/theme/theme'
import { InstitutionConnectionProvider } from '@/graphql/__generated__/globalTypes'

interface ReLinkAccountButtonProps {
  handleBankAccountLinkUpdate: () => void
  plaidAccessToken: string
  connectionProvider: InstitutionConnectionProvider
  franchiseGroupId: number
  plaidItemId: string
  customButton?: ReactElement
}

export default function ReLinkAccountButton (
  {
    handleBankAccountLinkUpdate,
    connectionProvider,
    franchiseGroupId,
    plaidAccessToken,
    plaidItemId,
    customButton
  }: ReLinkAccountButtonProps): ReactElement {
  const defaultButton = (
    <Button
      text='Relink'
      beforeIcon={<AccountsIcon color={Color.ERROR_RED}/>}
      variant={ButtonVariant.DESTRUCTIVE_TRANSPARENT}
      size={ButtonSize.X_SMALL}
      width='140px'
    />)
  const button = customButton ?? defaultButton
  switch (connectionProvider) {
    case InstitutionConnectionProvider.PLAID: {
      return (
        <PlaidComponent
          franchiseGroupId={franchiseGroupId}
          plaidAccessToken={plaidAccessToken}
          onSuccess={handleBankAccountLinkUpdate}
          customButton={button}
        />
      )
    }
    case InstitutionConnectionProvider.TELLER:{
      return (
        <TellerConnect
          franchiseGroupId={franchiseGroupId}
          tellerEnrollmentId={plaidItemId}
          onSuccess={handleBankAccountLinkUpdate}
          onError={() => {}}
          customButton={button}
        />
      )
    }
    case InstitutionConnectionProvider.TREASURY_PRIME:
    case InstitutionConnectionProvider.ALTIR:{
      // TODO - implement
      return <> </>
    }
  }
}
