import React, { type ReactElement } from 'react'
import FormNumberInput from './FormNumberInput'
import { formatInputAsEIN } from './utils'
import { type BaseFormInputProps } from '../types'

type FormEINInputProps = {
  value: string
  onChange: (value: string) => void
} & BaseFormInputProps

export default function FormEINInput (props: FormEINInputProps): ReactElement {
  return (
    <FormNumberInput
      autoFormatRule={formatInputAsEIN}
      maxNumberOfDigits={9}
      {...props}
    />
  )
}
