import React, { type ReactElement } from 'react'
import {
  Text, Grid, GridItem
} from '@chakra-ui/react'
import TransactionRowDropInfoDropdown from './TransactionRowDropInfoDropdown'
import AnimatedDropDownRow, {
  AnimatedDropDownRowExpandedContent,
  AnimatedDropDownRowRowContent
} from '../layout/AnimatedDropDownRow'
import TransferStatusBadge from '../badge/TransferStatusBadge'
import {
  Color
} from '@/theme/theme'
import { getCategoryLabelFormatted, getCurrencyFormatted } from '@/utils/stringUtils'
import { getFormattedDateString } from '@/utils/dateUtils'
import { getFromAndToAccounts, type BankAccountIdMap } from '@/components/pages/transactions/components/utils'
import {
  type TransactionFragment as Transaction
} from '@/graphql/__generated__/TransactionFragment'
import { TransferStatus } from '@/graphql/__generated__/globalTypes'
import { getFormattedTransferType } from '@/utils/transferUtils'

interface TransactionRowProps {
  index: number
  isSelected: boolean
  transaction: Transaction
  setSelectedTransactionIndex: (index: number | null) => void
  bankAccountIdMap?: BankAccountIdMap

  isCheckManagementEnabled: boolean
}

export default function TransactionRow (
  {
    index,
    transaction,
    isSelected,
    setSelectedTransactionIndex,
    bankAccountIdMap,
    isCheckManagementEnabled
  }: TransactionRowProps
): ReactElement {
  const amountFormatted = transaction?.amount != null
    ? getCurrencyFormatted(transaction?.amount, { minimumFractionDigits: 2 })
    : undefined
  const categoryLabelFormatted = getCategoryLabelFormatted(transaction.categoryLabel)
  const dateFormatted = getFormattedDateString(transaction.date)
  const longDescription = transaction.originalDescription ?? '' // TODO Ensure this field is non-null
  const merchantFormatted = transaction.merchantFormatted
  const isPending = transaction.isPendingTransaction
  const baseFontColor = isPending ? Color.DARK_GREY : Color.DARK_BLUE

  const bankAccount = transaction.bankAccountId != null
    ? bankAccountIdMap?.get(transaction.bankAccountId)
    : { institutionName: undefined, lastFour: undefined }

  const institutionName = bankAccount?.institutionName

  const { fromAccount, toAccount } = getFromAndToAccounts(transaction, bankAccount)

  return (
    <AnimatedDropDownRow
      isSelected={isSelected}
      onClick={() => { setSelectedTransactionIndex(isSelected ? null : index) }}
      isRowClickable={true}
    >
      <AnimatedDropDownRowRowContent>
        <Grid
          w='100%'
          gridAutoFlow='column'
          templateColumns='7rem 4rem 9fr 6rem 2fr'
          alignContent='center'
          gap={6}
        >
          {/* Date */}
          <GridItem alignSelf='center'>
            <Text color={Color.DARK_GREY}>
              {dateFormatted}
            </Text>
          </GridItem>

          {/* Transfer Type */}
          <GridItem alignSelf='center'>
            {
              transaction.originatingTransfer?.type != null &&
                <Text
                  color={baseFontColor}
                  noOfLines={1}
                >
                  {getFormattedTransferType(transaction.originatingTransfer.type)}
                </Text>
            }
          </GridItem>

          {/* Description */}
          <GridItem alignSelf='center' overflow='hidden'>
            <Text
              color={baseFontColor}
              noOfLines={1}
            >
              {merchantFormatted ?? longDescription}
            </Text>
          </GridItem>

          {/* Transfer Status Badge */}
          <GridItem alignSelf='center' justifySelf='center'>
            <TransferStatusBadge
              status={isPending ? TransferStatus.PENDING : transaction.originatingTransfer?.status ?? undefined}
            />
          </GridItem>

          {/* Amount */}
          <GridItem alignSelf='center'>
            <Text color={baseFontColor} textAlign='right' >
              {amountFormatted}
            </Text>
          </GridItem>
        </Grid>
      </AnimatedDropDownRowRowContent>
      <AnimatedDropDownRowExpandedContent>
        <TransactionRowDropInfoDropdown
          isSelected={isSelected}
          categoryLabelFormatted={categoryLabelFormatted}
          longDescription={longDescription}
          fromAccount={fromAccount}
          toAccount={toAccount}
          dateFormatted={dateFormatted ?? undefined}
          institutionName={institutionName}
          isCheckManagementEnabled={isCheckManagementEnabled}
          originatingTransfer={transaction.originatingTransfer ?? undefined}
        />
      </AnimatedDropDownRowExpandedContent>
    </AnimatedDropDownRow>
  )
}
