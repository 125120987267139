import {
  type GetOrganizationSettings_currentUser_selectedOrganization_franchiseGroups as FranchiseGroup,
  type GetOrganizationSettings_currentUser_selectedOrganization_users as User
} from '@/graphql/__generated__/GetOrganizationSettings'
import { type NullableUser } from '@/library/layout/user_row/UserInfoRow'

/**
 *
 * @param organizationId the selected org id
 * @param franchiseGroups all franchise groups associated with the org
 * @param users all users associated with the org
 * @returns transforms params into list of users augmented with their
 *      respective permission level on each franchise group
 */
export function getUserRolesForOrganization (
  organizationId: string | null,
  organizationName: string | null,
  franchiseGroups: FranchiseGroup[],
  users: User[]
): NullableUser[] {
  return users.map(user => getUserRoleForOrganization(organizationId, organizationName, franchiseGroups, user))
}

function getUserRoleForOrganization (
  organizationId: string | null,
  organizationName: string | null,
  franchiseGroups: FranchiseGroup[],
  user: User
): NullableUser {
  const organizationRole = user.organizationRoles.find(role => role.organizationId === organizationId)?.role ?? null
  const franchiseGroupRoles = franchiseGroups
    .map(franchiseGroup => {
      const role = user.roles.find(role => role.franchiseGroupId === Number(franchiseGroup.id))?.role ?? null
      return { name: franchiseGroup.name, role, businessId: Number(franchiseGroup.id) }
    })
  return {
    ...user,
    id: Number(user.id),
    organizationRole: { organizationId, name: organizationName, role: organizationRole },
    subRoles: franchiseGroupRoles
  }
}
