interface StripeEnv {
  STRIPE_CHECKOUT_LINK: string
  STRIPE_ACCOUNT_LINK: string
  STRIPE_PRODUCT_ALTIR_PRO: string
}

enum VercelMode {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development'
}

enum ApolloMode {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development'
}

function isProduction (): boolean {
  return process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION'
}

export const getVercelEnvironment = (): VercelMode => {
  if (isProduction()) {
    return VercelMode.PRODUCTION
  }

  return VercelMode.DEVELOPMENT
}

export const getStripeEnvironment = (): StripeEnv => {
  // Default to Stripe Test mode if these aren't defined
  const checkoutLink = process.env.REACT_APP_STRIPE_CHECKOUT_LINK ?? 'https://pay.altir.app/b/test_9AQ1814bddM1dCU6oo'
  const accountLink = process.env.REACT_APP_STRIPE_ACCOUNT_LINK ?? 'https://pay.altir.app/p/login/test_6oE8zv4gs7GX65i144'
  const stripeProductAltirPro = process.env.REACT_APP_STRIPE_PRODUCT_ALTIR_PRO ?? 'prod_OAQKRgDhRN6ura'

  return {
    STRIPE_CHECKOUT_LINK: checkoutLink,
    STRIPE_ACCOUNT_LINK: accountLink,
    STRIPE_PRODUCT_ALTIR_PRO: stripeProductAltirPro
  }
}

export const getApolloEnvironment = (): { mode: ApolloMode, devTools: boolean } => {
  const devTools = process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT'
  const mode = devTools ? ApolloMode.DEVELOPMENT : ApolloMode.PRODUCTION
  return { mode, devTools }
}

export function getAlloyEnvironment (): { key: string, production: boolean } {
  return {
    key: process.env.REACT_APP_ALLOY_KEY ?? '',
    production: isProduction()
  }
}
