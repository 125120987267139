/**
 * Queries/mutations related to adding new member to existing franchise
 */

import { gql } from '@apollo/client'

export const CREATE_INVITE = gql`
    mutation CreateInvite($input: CreateUserInviteInput!, $deprecatedFranchiseGroupID: Int) {
        createInvite(input: $input, deprecatedFranchiseGroupID: $deprecatedFranchiseGroupID) {
            email
            inviteCreator
            franchiseGroupId
        }
    }
`
