import React, { type ReactElement } from 'react'
import { Box } from '@chakra-ui/react'
import { Color } from '@/theme/theme'

export enum VerticalDividerHeight {
  DEFAULT = '28px',
  FULL = '100%'
}

interface VerticalDividerProps {
  height?: VerticalDividerHeight
}

/**
 * Note: for the more common HorizontalDivider, use Chakra's built-in <Divider/> component
 *  which is styled via our ThemeProvider
 */
export default function VerticalDivider ({
  height = VerticalDividerHeight.DEFAULT
}: VerticalDividerProps): ReactElement {
  return (
    <Box width='2px' height={height} backgroundColor={Color.GREY} />
  )
}
