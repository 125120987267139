import React, { useEffect, useRef, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import * as d3 from 'd3'
import { Color } from '@/theme/theme'
import { createSvgCanvas, drawBox, formatLargeCurrency, skipTickLabels } from '@/utils/chartUtils'
import { DateTimeTemplate, getFormattedDateString } from '@/utils/dateUtils'
import { nonNull } from '@/utils/arrayUtils'
import { getCurrencyChangeFormatted } from '@/utils/stringUtils'
import {
  type GetMonthlyMetrics_currentUser_franchiseGroup_historicalMonthlyAccountBalanceChange as MonthlyAccountBalanceChange
} from '@/graphql/__generated__/GetMonthlyMetrics'
import { type DateSelectionWindow } from '@/types/types'
interface MonthlyCashChangeGraphProps {
  parentWidth: number
  data: MonthlyAccountBalanceChange[]
  selectedDateRange: DateSelectionWindow
}
type SVG = d3.Selection<SVGGElement, unknown, null, undefined>

interface GraphData {
  monthStartDates: string[]
  selectedMonthIndex: number
  cashChanges: number[]
}

const CHART_HEIGHT_SMALL = 300
const CHART_HEIGHT_LARGE = 600

export default function MonthlyCashChangeGraph ({
  parentWidth,
  data,
  selectedDateRange
}: MonthlyCashChangeGraphProps
): ReactElement {
  const chartRef = useRef(null)
  useEffect(() => {
    // Create SVG canvas
    if (parentWidth < 200 || data.length < 1) {
      return
    }
    // Data
    const monthStartDates: string[] = nonNull(data.map(monthChange => monthChange.startDate))
    const selectedMonthIndex = selectedDateRange.startDate?.toISODate() == null
      ? 0
      : monthStartDates.indexOf(selectedDateRange.startDate.toISODate() as string)

    const cashChanges: number[] = nonNull(
      data.map(monthChange => (monthChange.netCashChange?.amount ?? null)))

    const graphData = {
      monthStartDates,
      selectedMonthIndex,
      cashChanges
    }

    // Generate Graph Canvas
    const chartWidth = parentWidth * 0.8
    const chartHeight = parentWidth > 1000 ? CHART_HEIGHT_LARGE : CHART_HEIGHT_SMALL
    const svgCanvas = createSvgCanvas(chartRef, chartHeight, chartWidth, 50, 30)

    // Set up scales
    const xScale = d3.scaleBand().domain(monthStartDates).range([0, chartWidth]).paddingInner(0.4).paddingOuter(0.2)
    // Max is 20% larger than the largest cashChange
    const yScalePadding = chartHeight * 0.1
    const yAxisMax = (d3.max(cashChanges.map(value => Math.abs(value))) ?? 1000) * 1.2
    const yScale = d3.scaleLinear().domain([-1 * (yAxisMax), yAxisMax]).range([
      chartHeight - yScalePadding,
      yScalePadding]
    )

    // Draw Elements
    drawBarrierLines(svgCanvas, graphData, xScale, yScale)
    createYAxis(svgCanvas, xScale, yScale)
    createGradients(svgCanvas)
    drawCashChangeBars(svgCanvas, graphData, xScale, yScale)
    drawMidLine(svgCanvas, xScale, yScale)
    createXAxis(svgCanvas, graphData, xScale, yScale)
    drawHighlightBox(svgCanvas, graphData, xScale, yScale)
  }, [data, parentWidth, selectedDateRange])

  return (
    <Flex
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
    >
      <div ref={chartRef}></div>
    </Flex>
  )
}
function createGradients (svg: SVG): void {
  // Create a linear gradient
  const positiveGradient = svg.append('defs')
    .append('linearGradient')
    .attr('id', 'positive-bar-gradient')
    .attr('spreadMethod', 'pad')
    .attr('x1', '0%')
    .attr('y1', '0%')
    .attr('x2', '0%')
    .attr('y2', '100%')

  positiveGradient.append('stop')
    .attr('offset', '0%')
    .style('stop-color', Color.SUCCESS_GREEN)
    .style('stop-opacity', 1)

  positiveGradient.append('stop')
    .attr('offset', '100%')
    .style('stop-color', Color.SUCCESS_GREEN)
    .style('stop-opacity', 0)

  // Create a linear gradient
  const negativeGradient = svg.append('defs')
    .append('linearGradient')
    .attr('id', 'negative-bar-gradient')
    .attr('spreadMethod', 'pad')
    .attr('x1', '0%')
    .attr('y1', '0%')
    .attr('x2', '0%')
    .attr('y2', '100%')

  negativeGradient.append('stop')
    .attr('offset', '0%')
    .style('stop-color', Color.ERROR_RED)
    .style('stop-opacity', 0)

  negativeGradient.append('stop')
    .attr('offset', '100%')
    .style('stop-color', Color.ERROR_RED)
    .style('stop-opacity', 1)
}

function drawCashChangeBars (
  svg: SVG,
  graphData: GraphData,
  xScale: d3.ScaleBand<string>,
  yScale: d3.ScaleLinear<number, number, never>
): void {
  graphData.cashChanges.forEach((cashValue, index) => {
    const isPositive = cashValue >= 0
    const isZero = cashValue === 0
    const isSelected = index === graphData.selectedMonthIndex
    const barOuter = yScale(cashValue)
    const graphMidpoint = yScale(0)
    const barHeight = Math.abs(graphMidpoint - barOuter)

    // Bar Colors
    const valueColor = isPositive ? 'url(#positive-bar-gradient)' : 'url(#negative-bar-gradient)'
    const fillColor = isSelected ? valueColor : Color.GREY

    // Bar dimensions
    const y = isPositive ? barOuter : graphMidpoint
    const month = graphData.monthStartDates[index]
    if (month == null) {
      throw Error('Invalid month')
    }
    const x = xScale(month)
    if (x == null) {
      throw Error('Invalid date for XScale')
    }
    const bandWidth = xScale.bandwidth()
    if (isZero) {
      // Don't draw a box
    } else if (barHeight < 10) {
      // Draw a minimum box
      const minBarSize = 5
      const radiusSize = 4
      const radiusTL = isPositive ? radiusSize : 0
      const radiusTR = isPositive ? radiusSize : 0
      const radiusBL = isPositive ? 0 : radiusSize
      const radiusBR = isPositive ? 0 : radiusSize

      drawBox(svg,
        x,
        graphMidpoint - minBarSize,
        bandWidth,
        minBarSize,
        fillColor,
        radiusTL,
        radiusTR,
        radiusBL,
        radiusBR
      )
    } else if (barHeight > 10) {
      // Draw a normal box
      const radiusSize = 10
      const radiusTL = isPositive ? radiusSize : 0
      const radiusTR = isPositive ? radiusSize : 0
      const radiusBL = isPositive ? 0 : radiusSize
      const radiusBR = isPositive ? 0 : radiusSize
      drawBox(svg, x, y, bandWidth, barHeight, fillColor, radiusTL, radiusTR, radiusBL, radiusBR)
    }

    // Text
    const textSpacing = 10
    const textSize = isSelected ? 20 : 14
    const textVerticalPosition = isPositive ? barOuter - (textSpacing + textSize) : barOuter + textSpacing
    const textValueColor = isZero
      ? Color.DARK_GREY
      : isPositive ? Color.SUCCESS_GREEN : Color.ERROR_RED
    const textColor = isSelected ? Color.BRIGHT_BLUE : textValueColor
    svg.append('text')
      .attr('x', x + bandWidth / 2)
      .attr('y', textVerticalPosition) // Adjust the vertical position of the labels
      .attr('dy', textSize) // Adjust for better vertical centering
      .text(d => getCurrencyChangeFormatted(cashValue, formatLargeCurrency))
      .attr('text-anchor', 'middle')
      .attr('fill', textColor)
      .style('font-weight', 'bold')
      .style('font-size', textSize)
      .style('font-family', 'AltirCommons')
  })
}

function drawHighlightBox (
  svg: SVG,
  graphData: GraphData,
  xScale: d3.ScaleBand<string>,
  yScale: d3.ScaleLinear<number, number, never>
): void {
  const scaleHeight = yScale.range()[0] ?? 0
  const highlightBoxHeight = scaleHeight * 1.1
  const expandedScale = xScale.copy().paddingInner(0.2).paddingOuter(0.1)
  const boxWidth = expandedScale.bandwidth()
  const selectedMonth = graphData.monthStartDates[graphData.selectedMonthIndex]
  if (selectedMonth == null) {
    throw Error('No selected month')
  }
  const x = expandedScale(selectedMonth)
  if (x == null) {
    throw Error('X not a value')
  }
  const y = scaleHeight * 0.05
  svg.append('rect')
    .attr('x', x) // x-coordinate of the top-left corner
    .attr('y', y) // y-coordinate of the top-left corner
    .attr('width', boxWidth) // width of the rectangle
    .attr('height', highlightBoxHeight) // height of the rectangle
    .attr('rx', 10) // horizontal corner radius
    .attr('ry', 10) // vertical corner radius
    .attr('stroke', Color.BRIGHT_BLUE) // fill color
    .attr('fill', Color.BRIGHT_BLUE)
    .attr('fill-opacity', 0.1)
}

function drawMidLine (
  svg: SVG,
  xScale: d3.ScaleBand<string>,
  yScale: d3.ScaleLinear<number, number, never>
): void {
  const graphMidpoint = yScale(0)
  const startPoint = xScale.range()[0]
  const endPoint = xScale.range()[1]
  drawLine(svg, startPoint, endPoint, graphMidpoint, graphMidpoint, Color.DARK_GREY)
}

function drawBarrierLines (
  svg: SVG,
  graphData: GraphData,
  xScale: d3.ScaleBand<string>,
  yScale: d3.ScaleLinear<number, number, never>
): void {
  const createSpacerLine = (xPosition: number): void => {
    drawLine(svg, xPosition, xPosition, yScale.range()[0] ?? 0, yScale.range()[1] ?? 0, Color.GREY)
  }
  graphData.monthStartDates.forEach((date, index) => {
    const position = xScale(date)
    // Calculate the tick width (spacing between points)
    if (position == null) {
      throw Error('Graph Scale not configured')
    }
    const singlePaddingValue = (xScale.step() - xScale.bandwidth()) / 2
    createSpacerLine(position + xScale.step() - singlePaddingValue)
  })
}

function drawLine (
  svg: SVG,
  xStart: number,
  xStop: number,
  yStart: number,
  yStop: number,
  color: string
): void {
  svg.append('line')
    .attr('x1', xStart)
    .attr('x2', xStop)
    .attr('y1', yStart)
    .attr('y2', yStop)
    .style('stroke-width', 2)
    .style('stroke', color)
    .style('fill', 'none')
}

function createXAxis (
  svg: SVG,
  graphData: GraphData,
  xScale: d3.ScaleBand<string>,
  yScale: d3.ScaleLinear<number, number, never>
): d3.Axis<string> {
  const xAxis = d3.axisBottom(xScale).tickFormat(formatXAxisLabels).tickPadding(10)
  const yAxisLength = yScale.range()[0]
  svg
    .append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0, ${yAxisLength ?? 0})`)
    .call(xAxis)
    .attr('color', Color.GREY)
    .attr('stroke-width', 0)

  styleXAxisLabels(svg, '.x-axis', graphData.selectedMonthIndex)
  return xAxis
}

function createYAxis (
  svg: SVG,
  xScale: d3.ScaleBand<string>,
  yScale: d3.ScaleLinear<number, number, never>
): d3.Axis<d3.NumberValue> {
  const [max, min] = yScale.domain()
  // There is likely a better way to do this, here we want 7 ticks evenly spaced between the min and max
  // D3 often wanted to avoid a 0 value to make the scales nicer
  // Therefore, we force 7 evenly spaced values
  if (max == null || min == null) {
    throw Error('Invalid scale') // TODO: Verify with @phil that this is expected behavior
  }
  const yAxis = d3.axisLeft(yScale).tickValues([
    min,
    min * (2 / 3),
    min * (1 / 3),
    0,
    max * (1 / 3),
    max * (2 / 3),
    max])
  svg
    .append('g')
    .attr('class', 'y-axis')
    .call(
      yAxis.tickFormat((domainValue, index) => skipTickLabels(3, formatLargeCurrency, domainValue, index))
    )
    .attr('color', Color.GREY)
    .style('stroke-width', 2)

  styleAxisLabels(svg, '.y-axis')
  return yAxis
}

function styleAxisLabels (svg: SVG, className: string): void {
  svg.selectAll(`${className} text`)
    .style('text-anchor', 'center')
    .style('font-weight', 'bold')
    .style('font-size', '12px')
    .style('font-family', 'AltirCommons')
    .style('fill', Color.DARK_BLUE)
}
function formatXAxisLabels (domainValue: string): string {
  const month = getFormattedDateString(domainValue, DateTimeTemplate.MONTH_LONG) ?? ''
  const year = getFormattedDateString(domainValue, DateTimeTemplate.YEAR_LONG) ?? ''
  return `${month} ${year}`
}

function styleXAxisLabels (svg: SVG, className: string, selectedMonthIndex: number): void {
  svg.selectAll(`${className} text`)
    .style('text-anchor', 'center')
    .style('font-weight', 'bold')
    .style('font-size', '14px')
    .style('font-family', 'AltirCommons')
    .style('fill', Color.DARK_BLUE)
    .call(function (t) {
      t.each(function (d, i) {
        const self = d3.select(this)
        const s = self.text().split(' ')
        self.text('')
        self.append('tspan')
          .attr('x', 0)
          .attr('dy', '0.2em')
          .text(s[0] ?? '')
        self.append('tspan')
          .attr('x', 0)
          .attr('dy', '1.5em')
          .text(s[1] ?? '')
        // Nth child selector
        if (i === selectedMonthIndex) {
          self.style('fill', Color.BRIGHT_BLUE)
        }
      }

      )
    })
}
