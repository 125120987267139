import React, { type ReactElement } from 'react'
import RecipientDropDownContent from './RecipientDropDownContent'
import DropDownComponent from '@/library/layout/drop_down/DropDownComponent'
import CircleCheckIcon from '@/library/icons/CircleCheckIcon'
import { Color, IconSize } from '@/theme/theme'
import ClockIcon from '@/library/icons/ClockIcon'
import {
  type GetRecentRecipientsPage_currentUser_franchiseGroup_amplifyAccount as FinancialAccount,
  type GetRecentRecipientsPage_currentUser_franchiseGroup_counterparties_transfers as Transfer
} from '@/graphql/__generated__/GetRecentRecipientsPage'

export interface Recipient {
  id: string
  name: string
  isVerified: boolean
  transfers: Transfer[]
}

interface RecipientDropDownProps {
  recipient: Recipient
  amplifyAccount: FinancialAccount | null
}

export default function RecipientDropDown ({ recipient, amplifyAccount }: RecipientDropDownProps): ReactElement {
  const icon = recipient.isVerified
    ? <CircleCheckIcon color={Color.DARK_BLUE} size={IconSize.SCHMEDIUM}/>
    : <ClockIcon color={Color.DARK_GREY} size={IconSize.SCHMEDIUM}/>
  return (
    <DropDownComponent
      title={recipient.name}
      icon={icon}
      dropDownContent={<RecipientDropDownContent recipient={recipient} amplifyAccount={amplifyAccount}/>}
    />
  )
}
