import React, { useState, type ReactElement } from 'react'
import { Flex, Text, Heading } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import AddWireInfoForm, {
  type AddWireInfoFormData
} from '../../../../recipients/routes/creation/components/AddWireInfoForm'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { Color } from '@/theme/theme'
import { ADD_WIRE_COUNTERPARTY } from '@/graphql/mutations/AddWireCounterparty'
import { type AddWireInfoToCounterparty, type AddWireInfoToCounterpartyVariables }
  from '@/graphql/__generated__/AddWireInfoToCounterparty'
import { fieldRequiredMessage } from '@/utils/stringUtils'
import ErrorInline from '@/library/errors/ErrorInline'
import { type ErrorWithContent } from '@/types/types'
import { isInvalidRoutingNumber, isInvalidWireAccountNumber } from '@/utils/formUtils'
import { addressInputToAddressUnderscoreInput } from '@/utils/addressUtils'

interface AddWireCounterpartyModalProps {
  isOpen: boolean
  onSuccess: (newCounterpartyId: string) => void
  onClose: () => void
  counterpartyId: string
  achAccountNumber: string
  achRoutingNumber: string
}

export default function AddWireCounterpartyModal ({
  isOpen,
  onSuccess,
  onClose,
  counterpartyId,
  achAccountNumber,
  achRoutingNumber
}: AddWireCounterpartyModalProps): ReactElement {
  const [inlineErrorState, setInlineErrorState] = useState<AddWireInfoFormData>({})
  const [submitError, setSubmitError] = useState<ErrorWithContent>()

  const [
    addWireCounterparty,
    { loading }
  ] = useMutation<AddWireInfoToCounterparty, AddWireInfoToCounterpartyVariables>(
    ADD_WIRE_COUNTERPARTY,
    {
      onCompleted: (data) => { onAddCounterpartySuccess(data.addWireInfoToCounterparty) },
      onError: (error) => {
        setSubmitError({
          error
        })
      }
    })

  function updateInlineErrorState (name: string, value?: string): void {
    setInlineErrorState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  function onAddCounterpartySuccess (newCounterpartyId: string): void {
    onClose()
    onSuccess(newCounterpartyId)
  }

  function onSubmit (formData: AddWireInfoFormData): void {
    setInlineErrorState({})
    setSubmitError(undefined)

    const {
      isInvalid: isInvalidAccountNumber,
      reason: invalidAccountNumberReason
    } = isInvalidWireAccountNumber(formData.wireAccountNumber)
    if (isInvalidAccountNumber || formData.wireAccountNumber == null) {
      updateInlineErrorState('wireAccountNumber', invalidAccountNumberReason)
      return
    }
    const {
      isInvalid: isInvalidWireRoutingNumber,
      reason: invalidWireRoutingNumberReason
    } = isInvalidRoutingNumber(formData.wireRoutingNumber)
    if (isInvalidWireRoutingNumber || formData.wireRoutingNumber == null) {
      updateInlineErrorState('wireRoutingNumber', invalidWireRoutingNumberReason)
      return
    }
    if (formData.bankName == null) {
      updateInlineErrorState('bankName', fieldRequiredMessage('Institution'))
      return
    }

    const addressFormatted = addressInputToAddressUnderscoreInput(formData.address)
    const bankAddressFormatted = addressInputToAddressUnderscoreInput(formData.bankAddress)
    if (addressFormatted == null) {
      updateInlineErrorState('address', fieldRequiredMessage('Address'))
      return
    }
    if (bankAddressFormatted == null) {
      updateInlineErrorState('bankAddress', fieldRequiredMessage('Bank Address'))
      return
    }

    void addWireCounterparty({
      variables: {
        counterpartyId,
        wireInfo: {
          accountNumber: formData.wireAccountNumber,
          routingNumber: formData.wireRoutingNumber,
          address: addressFormatted,
          bankAddress: bankAddressFormatted,
          bankName: formData.bankName
        }
      }
    })
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
      trapFocus={false}
    >
      <Flex flexDir='column' gap={8} alignItems='center'>
        <Flex flexDir='column' gap={2} alignItems='center'>
          <Heading
            color={Color.DARK_BLUE}
            size='lg'
          >
            Add wire details to complete transfer
          </Heading>
          <Text>
            We need additional information to send wires to this account.
          </Text>
        </Flex>
        <AddWireInfoForm
          isSubmissionLoading={loading}
          onSubmit={(data) => { onSubmit(data) }}
          inlineErrorState={inlineErrorState}
          achAccountNumber={achAccountNumber}
          achRoutingNumber={achRoutingNumber}
        />
        <ErrorInline error={submitError}/>
      </Flex>
    </ModalComponent>
  )
}
