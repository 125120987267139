import React, { type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Graphic } from '@/library/utility/Graphic'
import { NOT_AUTHORIZED_OCTAGON_GRAPHIC } from '@/theme/graphics'
import SupportEmailInlineLink from '@/library/text/SupportEmailInlineLink'
import { Color, IconSize } from '@/theme/theme'
import Button from '@/library/button/Button'
import { ROUTES } from '@/api/routes'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import CenteredInfoPage from '@/library/page/CenteredInfoPage'

export default function NotAuthorizedPage (): ReactElement {
  const navigate = useNavigate()
  return (
    <CenteredInfoPage>
      <Flex flexDir='column' gap={6} alignItems='center'>
        <Graphic src={NOT_AUTHORIZED_OCTAGON_GRAPHIC} h='102px'/>
        <Flex flexDir='column' gap={2} alignItems='center'>
          <Heading color={Color.DARK_BLUE} size='lg'>You do not have permission to view this page.</Heading>
          <Text maxW={96}>
            Contact your administrator to change your role. If you have questions, contact us at
            <SupportEmailInlineLink/>
          </Text>
        </Flex>
        <Button
          text='Go to Treasury'
          afterIcon={<RightArrowIcon size={IconSize.SMALL}/>}
          onClick={() => { navigate(ROUTES.TREASURY) }}
        />
      </Flex>
    </CenteredInfoPage>
  )
}
