import React, { type ReactNode, type ReactElement } from 'react'
import { AmplifyAccountApplicationStatus } from '@/graphql/__generated__/globalTypes'
import { DropDownVariant } from '@/library/layout/drop_down/types'
import MockDropDownComponent from '@/library/layout/drop_down/MockDropDownComponent'

export interface AmplifyFormGroupComponentProps {
  title: string
  subtitle?: string
  status?: AmplifyAccountApplicationStatus | null
  icon: ReactNode
  isNewApplication?: boolean
  handleClick: () => void
  isDisabled?: boolean
}

export default function AmplifyFormGroupComponent (
  {
    title,
    subtitle,
    status,
    icon,
    isNewApplication = false,
    isDisabled = false,
    handleClick
  }: AmplifyFormGroupComponentProps
): ReactElement {
  function getStatusDescription (): string {
    if (isDisabled) return ''
    if (status === AmplifyAccountApplicationStatus.CREATED) {
      return 'In Progress'
    }
    if (status === AmplifyAccountApplicationStatus.SUBMITTED) {
      return 'Complete'
    }

    return 'Not Started'
  }
  const isComplete = status === AmplifyAccountApplicationStatus.SUBMITTED

  return (
    <MockDropDownComponent
      title={title}
      subtitle={subtitle}
      isComplete={isComplete}
      onClick={handleClick}
      rightDescription={getStatusDescription()}
      variant={isNewApplication ? DropDownVariant.DARK : DropDownVariant.DEFAULT}
      icon={icon}
      isDisabled={isDisabled}
    />
  )
}
