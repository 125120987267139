import axios from 'axios'
import { useEffect, useState } from 'react'
export interface customHookStates {
  setDownloadUrl: React.Dispatch<React.SetStateAction<string | undefined>>
  error?: Error
  loading: boolean
}
export enum BlobDownloadFileType {
  CSV = 'text/csv',
  PDF = 'application/pdf',
}

interface useTriggerDownloadArgs {
  fileType: BlobDownloadFileType
  filename: string
  onError?: (error: Error) => void
}

/**
 * This hook creates a file download trigger for a pre-defined file Type and file Name
 * @param fileType The type of file that will be downloed
 * @param filename The name of the file as downloaded on the client side
 * @param onError Optional arg that calls this function on encountering an error
 * @returns
 *   trigger: the function that sets the download URL
 *   error: error state
 *   loading: loading state
 */
export default function useInitiateBrowserDownload ({
  fileType,
  filename,
  onError
}: useTriggerDownloadArgs
): customHookStates {
  const [loading, setLoading] = useState<boolean>(false)
  const [downloadUrl, setDownloadUrl] = useState<string>()
  const [error, setError] = useState<Error | undefined>()

  useEffect(() => {
    if (downloadUrl != null) {
      setLoading(true)
      const fetchAndGenerateFileDownload = async (): Promise<void> => {
        const response = await axios.get(downloadUrl, { responseType: 'arraybuffer' })
        const url = window.URL.createObjectURL(new Blob([response.data], { type: fileType }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          filename
        )
        link.target = '_blank'

        document.body.appendChild(link)
        link.click()
      }
      fetchAndGenerateFileDownload()
        .catch(error => {
          setError(error)
          if (onError != null) {
            onError(error)
          }
        })
        .finally(() => { setLoading(false) })
    }
  },
  [downloadUrl]
  )
  return { setDownloadUrl, loading, error }
}
