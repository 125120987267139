import React, { useState, type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { Flex, Text, useMediaQuery } from '@chakra-ui/react'
import MetricCard, { MetricCardVariant } from '@/library/card/MetricCard'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { type FinancialDataPoint } from '@/types/types'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { GET_AGGREGATE_EOD_BALANCES_FOR_ORGANIZATION } from '@/graphql/queries/GetAggregateEODBalancesForOrganization'
import {
  type GetAggregateEODBalancesForOrganization,
  type GetAggregateEODBalancesForOrganizationVariables
} from '@/graphql/__generated__/GetAggregateEODBalancesForOrganization'
import { Color, IconSize } from '@/theme/theme'
import Button, { ButtonVariant } from '@/library/button/Button'
import EmptyStateCard from '@/library/card/EmptyStateCard'
import PlusIcon from '@/library/icons/PlusIcon'
import { DateTimeTemplate, dateTimeToDateTimeTemplate, dateTimeToISODate, newDate, parseDate } from '@/utils/dateUtils'
import FinancialLineGraph from '@/library/graphs/FinancialLineGraph'
import ResponsiveD3Graph from '@/library/graphs/ResponsiveD3Graph'
import BusinessSelectorPopover from '@/library/nav/BusinessSelectorPopover'
import { ROUTES } from '@/api/routes'
import { EODAccountBalancesToFinancialDataPoint } from '@/utils/financialAccountUtils'
import { LinkedAccountType } from '@/graphql/__generated__/globalTypes'
import { nonNull } from '@/utils/arrayUtils'

export default function AggregateCashSection (): ReactElement {
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const [graphData, setGraphData] = useState<FinancialDataPoint[]>([])
  const [defaultFinancialDataPoint, setDefaultFinancialDataPoint] = useState<FinancialDataPoint | undefined>()
  const [financialDataPoint, setFinancialDatePoint] = useState<FinancialDataPoint | undefined>()

  const {
    loading,
    error
  } = useQuery<GetAggregateEODBalancesForOrganization, GetAggregateEODBalancesForOrganizationVariables>(
    GET_AGGREGATE_EOD_BALANCES_FOR_ORGANIZATION,
    {
      variables: {
        organizationId: selectedOrganizationId,
        ascending: true,
        financialAccountFilter: { accountType: LinkedAccountType.BANK }
      },
      onCompleted: (data) => { onDataLoad(data, setGraphData, setDefaultFinancialDataPoint) }
    })

  function handleValueChange (financialDataPoint?: FinancialDataPoint): void {
    setFinancialDatePoint(financialDataPoint)
  }
  const hasSufficientData = loading || (graphData.length > 1)
  const [isLargeScreen] = useMediaQuery('(min-width: 1000px)')
  const graphHeight = isLargeScreen === true ? 48 : 28
  return (
    <Flex flexDir='column' gap={2}>
      <Text>My Cash</Text>
      {hasSufficientData
        ? <MetricCard
            metric={getCurrencyFormatted(financialDataPoint?.amount ?? defaultFinancialDataPoint?.amount)}
            secondaryMetric={
              dateTimeToDateTimeTemplate(
                parseDate(financialDataPoint?.date ?? defaultFinancialDataPoint?.date),
                DateTimeTemplate.M_D_YY)
              }
            isLoading={loading}
            loadingHeight={graphHeight}
            error={error}
            variant={MetricCardVariant.EDGE_TO_EDGE}
          >
          <ResponsiveD3Graph>
            <FinancialLineGraph
              data={graphData}
              parentHeight={graphHeight}
              onValueChange={handleValueChange}
              isCompact={true}
              allowReSampleData
            />
          </ResponsiveD3Graph>
        </MetricCard>
        : <EmptyStateCard
            title='Not enough data to accurately calculate your total cash.'
            subTitle='Link an account to get started.'
          >
          <BusinessSelectorPopover
            isAmplifyRequired={false}
            quickActionRoute={`${ROUTES.TREASURY}/business`}
            appendBusinessIdToRoute
            urlState={{ isLinkAccountModalOpen: true }}
          >
            <Button
              text='Link an Account'
              beforeIcon={<PlusIcon color={Color.DARK_BLUE} size={IconSize.SMALL}/>}
              onClick={() => {}}
              variant={ButtonVariant.WHITE_GREY_BACKGROUND}
            />
          </BusinessSelectorPopover>
        </EmptyStateCard>}
    </Flex>
  )
}

function onDataLoad (
  data: GetAggregateEODBalancesForOrganization,
  setGraphData: React.Dispatch<React.SetStateAction<FinancialDataPoint[]>>,
  setDefaultFinancialDataPoint: React.Dispatch<React.SetStateAction<FinancialDataPoint | undefined>>
): void {
  const aggregateAccountBalance =
    data?.currentUser?.selectedOrganization?.aggregateAccountBalance.availableBalance?.amount ?? undefined
  const liveBalanceDataPoint = aggregateAccountBalanceToFinancialDataPoint(aggregateAccountBalance)
  const allEODBalanceDataPoints: FinancialDataPoint[] = EODAccountBalancesToFinancialDataPoint(
    data?.currentUser?.selectedOrganization?.aggregateEODBalances ?? []
  )
  const allFinancialDataPoints = nonNull([...allEODBalanceDataPoints, liveBalanceDataPoint])
  const lastElement = allFinancialDataPoints.slice(-1)[0]
  setGraphData(allFinancialDataPoints)
  setDefaultFinancialDataPoint(lastElement)
}

function aggregateAccountBalanceToFinancialDataPoint (amount?: number): FinancialDataPoint | undefined {
  if (amount == null) {
    return undefined
  }
  return { amount, date: dateTimeToISODate(newDate()) }
}
