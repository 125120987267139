import { gql } from '@apollo/client'

export const GET_ALL_STORE_LOCATIONS = gql`
    query GetAllStoreLocations($franchiseGroupId: Int!) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {    
                storeLocations {
                    id
                    name
                    storeId
                    posFieldIdentifier
                    checkFieldIdentifier
                }
            }
        }
    }
`
