/* eslint-disable max-len */
import { Icon } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function CardsIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <Icon boxSize={size} display='flex' alignItems='center' justifyContent='center'>
      <svg width='100%' height='100%' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path stroke={color} d='M22.0612 11.6737V18.2849C22.0612 18.8186 21.8492 19.3304 21.4719 19.7078C21.0945 20.0852 20.5827 20.2972 20.049 20.2972H6.96929C6.43561 20.2972 5.92378 20.0852 5.54641 19.7078C5.16904 19.3304 4.95703 18.8186 4.95703 18.2849V16.7757M22.0612 11.6737V10.2359C22.0612 9.70221 21.8492 9.19038 21.4719 8.81301C21.0945 8.43564 20.5827 8.22363 20.049 8.22363H19.0429M22.0612 11.6737H19.0429' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        <path stroke={color} d='M19.0437 8.2236V14.7634C19.0437 15.2971 18.8317 15.809 18.4543 16.1863C18.0769 16.5637 17.5651 16.7757 17.0314 16.7757H3.95171C3.41803 16.7757 2.9062 16.5637 2.52883 16.1863C2.15146 15.809 1.93945 15.2971 1.93945 14.7634V6.71441C1.93945 6.18072 2.15146 5.6689 2.52883 5.29153C2.9062 4.91415 3.41803 4.70215 3.95171 4.70215H17.0314C17.5651 4.70215 18.0769 4.91415 18.4543 5.29153C18.8317 5.6689 19.0437 6.18072 19.0437 6.71441V8.2236ZM19.0437 8.2236H1.93945' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      </svg>
    </Icon>
  )
}
