import React, { type ReactElement } from 'react'
import { Box } from '@chakra-ui/react'
import { type Color } from '@/theme/theme'

interface IconOutlineProps {
  icon: ReactElement
  backgroundColor: Color
}

export default function IconOutline ({ icon, backgroundColor }: IconOutlineProps): ReactElement {
  return (
    <Box
      borderRadius='100%'
      p={3}
      bg={backgroundColor}
    >
      {icon}
    </Box>
  )
}
