import React, { type ReactElement } from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import TransferRuleEmptyStateComponent from './TransferRuleEmptySectionComponent'
import TransferRuleDropdownComponent from './TransferRuleDropdownComponent'
import RecurringRuleBalanceIcon from '@/library/icons/RecurringRuleBalanceIcon'
import { Color } from '@/theme/theme'
import {
  type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule
}
  from '@/graphql/__generated__/GetTransferRules'
import { GenericTransferRuleType, isUnaddressedTransferRuleError } from '@/utils/transferRuleUtils'
import RecurringRuleTimeIcon from '@/library/icons/RecurringRuleTimeIcon'

interface TransferRuleGroupProps {
  type: GenericTransferRuleType
  transferRules: TransferRule[]
  canAutoExpand: boolean
  canEditTransferRules: boolean
  refetch: () => void
}

export default function TransferRuleGroup ({
  type,
  transferRules,
  canAutoExpand,
  canEditTransferRules,
  refetch
}: TransferRuleGroupProps): ReactElement {
  const { icon, title } = getHeadingForRule(type)
  return (
    <Flex direction='column' gap={3}>
      <Flex alignItems='center' gap={2}>
        {icon}
        <Heading size='sm'>{title}</Heading>
      </Flex>
      {
        transferRules.length > 0
          ? transferRules.map(rule => {
            return (
              <TransferRuleDropdownComponent
                key={rule.id}
                transferRule={rule}
                isOpenOnStart={canAutoExpand}
                onUpdate={refetch}
                isError={isUnaddressedTransferRuleError(rule)}
                canEditTransferRules={canEditTransferRules}
              />
            )
          })
          : <TransferRuleEmptyStateComponent ruleType={type}/>
        }
    </Flex>
  )
}

function getHeadingForRule (ruleType: GenericTransferRuleType): { icon: ReactElement, title: string } {
  switch (ruleType) {
    case GenericTransferRuleType.BALANCE:
      return {
        icon: <RecurringRuleBalanceIcon color={Color.DARK_BLUE}/>,
        title: 'Balance-Based Rules'
      }
    case GenericTransferRuleType.TIME:
      return {
        icon: <RecurringRuleTimeIcon color={Color.DARK_BLUE}/>,
        title: 'Time-Based Rules'
      }
  }
}
