import { faBank } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { type ReactElement } from 'react'
import { Graphic } from '@/library/utility/Graphic'
import { Color } from '@/theme/theme'

interface InstitutionLogoProps {
  src?: string
  height?: string
}

export default function InstitutionLogo (
  { src, height = '30px' }: InstitutionLogoProps
): ReactElement {
  if (src == null) {
    return <FontAwesomeIcon height={height} icon={faBank} color={Color.DARK_GREY}/>
  }

  return (
    <Graphic
      w='auto'
      h={height}
      src={src}
    />
  )
}
