import { gql } from '@apollo/client'

export const UPDATE_USER_ONBOARDING_STATE = gql`
    mutation UpdateUserOnboardingState(
        $isEmailVerificationSkipped: Boolean, 
        $isOrganizationAssociationAcknowledgmentCompleted: Boolean
    ) {
        updateUserOnboardingState(
            isEmailVerificationSkipped: $isEmailVerificationSkipped, 
            isOrganizationAssociationAcknowledgmentCompleted: $isOrganizationAssociationAcknowledgmentCompleted
        )
    }
`
