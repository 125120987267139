import React, { type ReactElement } from 'react'
import { Center, Flex, Heading, Text } from '@chakra-ui/react'
import { BorderRadius, Color, ContainerWidth } from '@/theme/theme'
import AltirPartnerLinkGraphic from '@/library/utility/AltirPartnerLinkGraphic'
import { GRASSHOPPER_LOGO } from '@/theme/graphics'
import LinkText from '@/library/text/LinkText'
import { BANK_MIGRATION_FAQ_URL } from '@/theme/urls'
import Button from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { sendSupportEmail } from '@/library/nav/help_center/utils'

export default function GrasshopperMigrationDisabledTransferCard (): ReactElement {
  return (
    <Flex flexDir='column' gap={6}>
      <Heading size='md'>Make a Transfer</Heading>
      <Center w='100%' flexDir='column' p={6} gap={4} bg={Color.GREY} borderRadius={BorderRadius.CARD}>
        <AltirPartnerLinkGraphic partnerLogoSrc={GRASSHOPPER_LOGO}/>
        <Center flexDir='column' gap={1} textAlign='center' maxW={ContainerWidth.SMALL}>
          <Heading size='sm'>Account Upgrade in Progress</Heading>
          <Text>
            Transfers are not allowed while the upgrade is in progress.
            View our <LinkText text='FAQs' href={BANK_MIGRATION_FAQ_URL}/> to learn more, or contact us with questions.
          </Text>
        </Center>
        <Button text='Contact Us' afterIcon={<RightArrowIcon/>} onClick={sendSupportEmail} width='auto'/>
      </Center>
    </Flex>
  )
}
