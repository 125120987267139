import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import BaseAnimation, { AnimationSize } from './BaseAnimation'
import altirSuccess from '../../../assets/animations/altir_success.json'

export interface SuccessConfettiAnimationProps {
  size?: AnimationSize
}

export default function SuccessConfettiAnimation (
  { size = AnimationSize.Medium }: SuccessConfettiAnimationProps
): ReactElement {
  const { size: animationSize, overflow } = getSizeProps(size)
  return (
    <Flex w='100%' my={overflow} justifyContent='center'>
      <BaseAnimation animationData={altirSuccess} size={animationSize} loop={false}/>
    </Flex>
  )
}

function getSizeProps (size: AnimationSize): { size: string, overflow: number } {
  switch (size) {
    case AnimationSize.Medium:
      return {
        size: '300px',
        overflow: -24 // return a negative Y margin so that confetti falls over sibling components
      }
  }
}
