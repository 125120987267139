import { useMutation } from '@apollo/client'
import { Flex, Heading, Link, Stack, Text } from '@chakra-ui/react'
import React, { useState, type ReactElement } from 'react'
import { Event } from 'metrics/metrics'
import { BorderRadius, Color } from '@/theme/theme'
import ErrorInline from '@/library/errors/ErrorInline'
import TextBadgeComponent from '@/library/utility/TextBadgeComponent'
import { CardDivider, CardRow } from '@/library/layout/CardComponets'
import Button, { ButtonVariant } from '@/library/button/Button'
import { UPDATE_PREAPPROVAL_OFFER } from '@/graphql/mutations/UpdatePreApprovalOffer'
import {
  type UpdatePreApprovalOfferVariables,
  type UpdatePreApprovalOffer
} from '@/graphql/__generated__/UpdatePreApprovalOffer'
import { LoanStatus, LoanType } from '@/graphql/__generated__/globalTypes'
import {
  type GetLendingPageData_currentUser_franchiseGroup_preApprovedLendingOffers as Offer
} from '@/graphql/__generated__/GetLendingPageData'

export interface LoanOfferCardComponentProps {
  offer: Offer
  isPreApprovalOffer?: boolean
}

export default function LoanOfferCardComponent (
  { offer, isPreApprovalOffer = false }: LoanOfferCardComponentProps
): ReactElement {
  const [error, setError] = useState<Error>()
  const [
    updatePreApprovalOfferStatus
  ] = useMutation<UpdatePreApprovalOffer, UpdatePreApprovalOfferVariables>(UPDATE_PREAPPROVAL_OFFER)

  function handleOfferClick (): void {
    if (!isPreApprovalOffer) return

    const variables = {
      id: offer.id,
      status: LoanStatus.VIEWED
    }
    void updatePreApprovalOfferStatus({ variables }).catch(e => { setError(e) })
  }

  const offerHasExpiration = isPreApprovalOffer && offer.offerTTLDays != null && offer.offerTTLDays !== 0

  return (
    <Stack
      borderRadius={BorderRadius.CARD}
      bg={Color.WHITE}
      p={6}
      gap={2}
      flex={1}
      justifyContent='space-between'
    >
      <Flex justifyContent='space-between' width='100%' paddingBottom={24}>
        <Flex flexDirection='column'>
          <Heading color={Color.DARK_BLUE} size='sm' paddingBottom={1}>{offer.lender?.name}</Heading>
          <Heading color={Color.DARK_GREY} size='sm'>{getLoanDescription(offer.type)}</Heading>
        </Flex>
        {isPreApprovalOffer
          ? <TextBadgeComponent text='PRE-APPROVED' backgroundColor={Color.BRIGHT_BLUE}/>
          : <TextBadgeComponent text='APPLY NOW'/>}
      </Flex>
      <Flex flexDir='column'>
        <CardRow>
          <Text>Amount</Text>
          <Text color={Color.DARK_BLUE}>{getAmountDescription(offer)}</Text>
        </CardRow>
        <CardDivider/>
        <CardRow>
          <Text> {shouldDisplayMonthlyRate(offer) ? 'Monthly Interest Rate' : 'APR'}</Text>
          <Text color={Color.DARK_BLUE}>{getInterestRateDescription(offer)}</Text>
        </CardRow>
        <CardDivider/>
        <CardRow>
          <Text>Term</Text>
          <Text color={Color.DARK_BLUE}>{getLoanLength(offer)}</Text>
        </CardRow>
      </Flex>
      <Link href={offer.offerUrl ?? ''} isExternal>
        <Button
          text={isPreApprovalOffer ? 'Select Loan' : 'Start Application'}
          afterIcon={offerHasExpiration
            ? <Text fontSize='xs'>Expires in {offer.offerTTLDays} days</Text>
            : undefined}
          onClick={handleOfferClick}
          justifyContent={offerHasExpiration ? 'space-between' : 'center'}
          variant={isPreApprovalOffer ? ButtonVariant.PRIMARY : ButtonVariant.WHITE_OUTLINE}
          onClickEventType={Event.LOAN_OFFER_CARD_CLICK}
        />
      </Link>
      <ErrorInline error={error}></ErrorInline>
    </Stack>
  )
}

function getLoanLength (offer: Offer): string {
  const maxLoanMonths = offer.maxLoanMonths
  const minLoanMonths = offer.minLoanMonths
  const shouldDisplayInYears = maxLoanMonths >= 2 * 12

  const end = shouldDisplayInYears ? Math.round(maxLoanMonths / 12) : maxLoanMonths
  const start = shouldDisplayInYears && minLoanMonths != null ? Math.round(minLoanMonths / 12) : minLoanMonths
  const unit = shouldDisplayInYears ? 'Years' : 'Months'

  if (start == null) {
    return `Up to ${end} ${unit}`
  }
  return `${start} - ${end} ${unit}`
}

function getLoanDescription (type?: LoanType): string {
  switch (type) {
    case LoanType.TERM_LOAN:
      return 'Term Loan'
    case LoanType.LINE_OF_CREDIT:
      return 'Line of Credit'
    default:
      return 'Line of Credit'
  }
}

function shouldDisplayMonthlyRate (offer: Offer): boolean {
  return offer.type === LoanType.LINE_OF_CREDIT
}

function getInterestRateDescription (offer: Offer): string {
  const minInterest = (shouldDisplayMonthlyRate(offer))
    ? offer.minInterestRate?.monthlyRate
    : offer.minInterestRate?.aprRate

  const maxInterest = (shouldDisplayMonthlyRate(offer))
    ? offer.maxInterestRate.monthlyRate
    : offer.maxInterestRate.aprRate

  if (minInterest == null) {
    return formatPercent(maxInterest)
  }
  return `${formatPercent(minInterest)} - ${formatPercent(maxInterest)}`
}

function formatPercent (interestAsDecimal: number): string {
  return `${(interestAsDecimal * 100).toFixed(2)} %`
}

function getAmountDescription (offer: Offer): string | null {
  if (offer.maxOfferAmount.amountFormatted == null) {
    console.error('Cannot render offer range due to missing max offer amount')
    return ''
  }

  if (offer.minOfferAmount?.amountFormatted == null) {
    return `Up to ${offer.maxOfferAmount.amountFormatted}`
  }

  return `${offer.minOfferAmount.amountFormatted} - ${offer.maxOfferAmount.amountFormatted}`
}
