import React, { useEffect, useState, type ReactElement } from 'react'
import PopoverMenuSelectionOptions from './components/PopoverMenuSelectionOptions'
import PopoverMenu from './components/PopoverMenu'

export interface MenuSelectionItem {
  id: string
  textArray: string[]
}

export interface MenuSelectionGroup {
  menuSelectionGroupHeading: string
  menuSelectionItems: MenuSelectionItem[]
}

export type MenuSelections = string[]

interface PopoverMenuProps {
  popoverMenuTitle: string
  onSubmit: (selectedItems: string[]) => void
  menuSelectionGroups?: MenuSelectionGroup[]
  initialState: string[]
  isCheckBox: boolean
  menuSelectionGroupsLoading?: boolean
  allowInteriorScroll?: boolean
  shouldMatchWidth?: boolean
}

/**
 * Popover selector that allows the user to select multiple
 * items from a hierarchical list of options, i.e:
 *
 * [x] Mercury
 *   [x] Account 1
 *   [x] Account 2
 * [] Bank of America
 *   [x] Account 5
 *   [] Account 6
 */
export default function PopoverMenuHierarchicalMultiSelector (
  {
    popoverMenuTitle,
    onSubmit,
    menuSelectionGroups,
    initialState,
    isCheckBox,
    menuSelectionGroupsLoading,
    allowInteriorScroll,
    shouldMatchWidth
  }:
  PopoverMenuProps
): ReactElement {
  // The Menu Button and Menu List are not under the Same DOM parent (chakra), we use a ref to gather width
  const [selectedItems, setSelectedItems] = useState<MenuSelections>(initialState)
  const [subtitle, setSubtitle] = useState<string>('')

  function updateSelection (values: string[], menuOptions: MenuSelectionItem[]): void {
    // Each MenuOption Group can only return a set of values selected within that subgroup
    // Therefore we need to trigger removals based on the menuOptions context
    const menuOptionsNotSelected = menuOptions.filter(item => !values.includes(item.id))
    const selectedItemsSet = new Set([...values, ...selectedItems])
    menuOptionsNotSelected.forEach(item => selectedItemsSet.delete(item.id))
    setSelectedItems(Array.from(selectedItemsSet))
  }

  // Listen for updates to the initial state (e.g. on clearing) and refresh state
  useEffect(() => { setSelectedItems(initialState) }, [initialState])

  useEffect(() => {
    const numberOfSelectedItems = selectedItems.length
    const selectionString = numberOfSelectedItems > 0 ? `(${numberOfSelectedItems})` : ''
    setSubtitle(selectionString)
  }, [selectedItems])

  return (
    <PopoverMenu
      popoverMenuTitle = {popoverMenuTitle}
      onSubmit = {() => { onSubmit(selectedItems) }}
      subtitle = {subtitle}
      isLoading = {menuSelectionGroupsLoading}
      allowInteriorScroll={allowInteriorScroll}
      hasSelection={selectedItems.length > 0}
      shouldMatchWidth={shouldMatchWidth}
    >
      {menuSelectionGroups?.map(({ menuSelectionGroupHeading, menuSelectionItems }, index) => {
        return (
          <PopoverMenuSelectionOptions
            key={index}
            menuSelectionGroupHeading={menuSelectionGroupHeading}
            updateSelection={updateSelection}
            menuSelectionItems={menuSelectionItems}
            isCheckBox={isCheckBox}
            selectedItems={selectedItems}
          />
        )
      })
    }
    </PopoverMenu>
  )
}
