import { gql } from '@apollo/client'

export const GET_CAPITAL_OS_ACCOUNT_CARD_DATA = gql`
    query GetCapitalOSAccountCardData($franchiseGroupId: Int!) {
        currentUser {
            capitalOsConfiguration(franchiseGroupId: $franchiseGroupId) {
                account {
                    status
                    currentLimit {
                        amountFormatted
                        amount
                    }
                    currentBalance {
                        amountFormatted
                        amount
                    }
                }
            }
        }
    }
`
