import { gql } from '@apollo/client'

export const GET_HISTORICAL_ACCRUED_INTEREST = gql`
    query GetHistoricalAccruedInterest($accountId: Int!) {
        financialAccount(accountId: $accountId) {
            amplifyAccount {
                historicalAccruedInterest {
                    amount {
                        amount
                    }
                    endDate
                }
            }
        }
    }
`
