import React, { type ReactElement } from 'react'
import { Flex, Text, Heading } from '@chakra-ui/react'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { BorderRadius, Color } from '@/theme/theme'
import { getCurrencyFormatted } from '@/utils/stringUtils'

interface MonthlyMetricProps {
  description?: string
  numericalValue?: number
  isLoading?: boolean
}

export default function MonthlyMetric ({
  description,
  numericalValue,
  isLoading = false
}: MonthlyMetricProps): ReactElement {
  return (
    <AltirSkeleton isLoading={isLoading}>
      <Flex
        flex={1}
        flexDir='column'
        bg={Color.LIGHT_GREY}
        justifyContent='center'
        alignItems='center'
        borderRadius={BorderRadius.CARD}
        p={6}
        gap={2}
      >
        <Text textAlign='center' minH={7}>
          {description}
        </Text>
        <Heading color={Color.DARK_BLUE} minH={7} size='lg'>
          {getCurrencyFormatted(numericalValue)}
        </Heading>
      </Flex>

    </AltirSkeleton>
  )
}
