import React, { type ReactElement } from 'react'
import { Text } from '@chakra-ui/react'
import { Color, FontWeight } from '../../theme/theme'

export interface FormInlineErrorProps {
  errorMessage?: string | null
}

export default function FormInlineError ({ errorMessage }: FormInlineErrorProps): ReactElement | null {
  if (errorMessage == null) return null
  return (
    <Text
      color={Color.ERROR_RED}
      fontSize='xs'
      fontWeight={FontWeight.BOLD}
      marginTop={-2}
      paddingBottom={2}
    >
      {errorMessage}
    </Text>
  )
}
