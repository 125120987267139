import { gql } from '@apollo/client'

export const TRANSFER_RULE_FRAGMENT = gql`
    fragment TransferRuleFragment on TransferRuleFormatted {
        id
        updatedAt
        amplifyAccountId
        counterparty {
            name
            nickname
            achAccountNumber
        }
        counterpartyId
        direction
        isActive
        minTransferAmount
        mostRecentTransferEST
        nextScheduledTransferEST
        ruleStartDate
        targetBalance
        targetDate
        targetDay
        transferAmount
        transferRuleType
        lastEvaluationStatus
        lastEvaluationErrorType
    }
`
