/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize, Color } from '../theme/theme'

export default function TransferNavIcon ({ color = Color.WHITE, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M11.2541 1.69169C11.0711 1.48287 10.7778 1.40932 10.518 1.50707C10.2581 1.60482 10.086 1.8534 10.086 2.13105V3.67119H1.37931C1.01112 3.67119 0.712646 3.96967 0.712646 4.33786V6.54467C0.712646 6.91286 1.01112 7.21133 1.37931 7.21133H14.6202C14.8818 7.21133 15.1193 7.05825 15.2273 6.81993C15.3354 6.5816 15.294 6.30211 15.1216 6.10531L11.2541 1.69169ZM11.4193 4.33786V3.90342L13.1496 5.878H2.04598V5.00453H10.7527C11.1209 5.00453 11.4193 4.70605 11.4193 4.33786ZM4.74577 14.2901C4.92876 14.499 5.22201 14.5725 5.48188 14.4748C5.74176 14.377 5.91384 14.1284 5.91384 13.8508V12.3106L14.6205 12.3106C14.9887 12.3106 15.2872 12.0122 15.2872 11.644V9.43716C15.2872 9.06897 14.9887 8.7705 14.6205 8.7705L1.37968 8.7705C1.11801 8.7705 0.880532 8.92358 0.772491 9.1619C0.664449 9.40023 0.705821 9.67972 0.878274 9.87652L4.74577 14.2901ZM4.58051 11.644V12.0784L2.85026 10.1038L13.9539 10.1038V10.9773H5.24718C4.87899 10.9773 4.58051 11.2758 4.58051 11.644Z' fill={color}/>
      </svg>
    </IconContainer>
  )
}
