import React, { useState, type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { Flex, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import EmptyAccountsSectionComponent from './EmptyAccountsSectionComponent'
import AccountsSortAndFilterComponent from './AccountsSortAndFilterComponent'
import { getInstitutionNames, getStoreLocations, sortAndFilterAccounts } from './utils'
import AmplifyAccountCardComponent from '../amplify/card/AmplifyAccountCardComponent'
import CapitalOSAccountCardComponent from '../capital_os/CapitalOSAccountCardComponent'
import { ROUTES } from '@/api/routes'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import IconCircleButton from '@/library/button/IconCircleButton'
import TransferIcon from '@/library/icons/TransferIcon'
import AccountsIcon from '@/library/icons/AccountsIcon'
import {
  GET_TREASURY_PAGE_DATA
} from '@/graphql/queries/GetTreasuryPageData'
import {
  AuthResource,
  BankAccountExtensionFilter,
  LinkedAccountType
} from '@/graphql/__generated__/globalTypes'
import {
  type GetTreasuryPageDataVariables,
  type GetTreasuryPageData,
  type GetTreasuryPageData_currentUser_selectedOrganization_selectedFranchiseGroup_aggregateAccountBalance
  as AggregateBalance,
  type GetTreasuryPageData_currentUser_capitalOsConfiguration_account_currentBalance as CapitalOSBalance
} from '@/graphql/__generated__/GetTreasuryPageData'
import {
  Color
} from '@/theme/theme'
import HeaderStatistic from '@/library/text/HeaderStatistic'
import ClockIcon from '@/library/icons/ClockIcon'
import Page from '@/library/page/Page'
import InstitutionConnectionComponent from '@/components/clients/financial_institution/InstitutionConnectionComponent'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { AuthAction, hasAccess, INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY } from '@/utils/authUtils'
import { EMPTY_BALANCE_VALUE } from '@/utils/constants'
import { type AccountsSortByInput, AccountsSortByType } from '@/types/types'
import FinancialAccountDropDownComponent from '@/library/accounts/FinancialAccountDropDownComponent'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import GrasshopperMigrationBanner from '@/components/grasshopper_migration/GrasshopperMigrationBanner'

export interface TreasuryComponentProps {
  requestedFranchiseGroupId: number
  accountType: LinkedAccountType
  isLinkAccountModalOpen?: boolean
}

export default function TreasuryComponent (
  {
    requestedFranchiseGroupId,
    accountType,
    isLinkAccountModalOpen = false
  }: TreasuryComponentProps
): ReactElement {
  const navigate = useNavigate()
  const organizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const {
    data,
    loading,
    error,
    refetch
  } = useQuery<GetTreasuryPageData, GetTreasuryPageDataVariables>(
    GET_TREASURY_PAGE_DATA,
    {
      variables: {
        organizationId,
        franchiseGroupId: requestedFranchiseGroupId,
        financialAccountFilter: {
          accountType,
          bankAccountExtensionFilter: BankAccountExtensionFilter.EXLCUDE_AMPLIFY
        },
        aggregateBalanceAccountFilter: {
          accountType,
          bankAccountExtensionFilter: BankAccountExtensionFilter.ALL_ACCOUNTS
        }
      }
    })
  const [accountQueryString, setAccountQueryString] = useState<string | undefined>(undefined)
  const [institutionFilter, setInstitutionFilter] = useState<string[]>([])
  const [locationFilter, setLocationFilter] = useState<string[]>([])
  const [sortByInput, setSortByInput] = useState<AccountsSortByInput>(
    { type: AccountsSortByType.BALANCE, ascending: false }
  )

  const totalBalanceFormatted = getTotalBalanceFormatted(
    accountType,
    data?.currentUser?.selectedOrganization?.selectedFranchiseGroup?.aggregateAccountBalance ?? undefined,
    data?.currentUser?.capitalOsConfiguration?.account?.currentBalance ?? undefined
  )

  const isBank = accountType === LinkedAccountType.BANK
  const isCard = accountType === LinkedAccountType.CARD
  const headerText = isBank
    ? 'Total Cash'
    : 'Total Card Balance'
  const secondaryHeaderText = isBank ? 'External Accounts' : 'External Cards'

  const franchiseGroup = data?.currentUser?.selectedOrganization?.selectedFranchiseGroup
  const franchiseGroupId = franchiseGroup?.id
  const isCapitalOSEnabled = data?.currentUser?.rolloutConfiguration?.enableCapitalOS === true
  const shouldShowAmplifyComponent = isBank && franchiseGroupId != null
  const shouldShowCapitalOSComponent = isCapitalOSEnabled && isCard && franchiseGroupId != null
  const hasLinkedAccount = (franchiseGroup?.financialAccounts ?? []).length > 0
  const hasAmplifyAccount = franchiseGroup?.amplifyAccount?.accountId != null
  // TODO (PJ): Re-enable when bank accounts are live
  // Show FDIC Modal to users with no linked accounts and no active amplify applications
  // Note: don't show when viewing cards
  // const hasAmplifyAccountApplication =
  //   isAmplifyApplicationActive(data?.currentUser?.franchiseGroup?.amplifyAccountApplicationStatus)
  // const shouldShowFDICModal =
  //   isBank &&
  //   !hasAmplifyAccountApplication &&
  //   !hasLinkedAccount &&
  //   !hasAmplifyAccount &&
  //   !loading &&
  //   !isLinkAccountModalOpen

  // Authorization Checks
  const canTransfer = hasAccess(AuthResource.AMPLIFY_ACCOUNT, AuthAction.WRITE, data?.currentUser?.authorization ?? [])
  const canLinkAccounts = hasAccess(
    AuthResource.FINANCIAL_INSTITUTION_LINK_PLAID, AuthAction.WRITE, data?.currentUser?.authorization ?? []
  )

  // Rollout configuration
  const isTransactionRefreshEnabled = data?.currentUser?.rolloutConfiguration?.enableTransactionRefresh === true
  const isGrasshopperMigrationEnabled = data?.currentUser?.rolloutConfiguration?.enableGrasshopperMigration === true

  const filteredAccounts = sortAndFilterAccounts(
    franchiseGroup?.financialAccounts ?? [],
    sortByInput,
    accountQueryString,
    institutionFilter,
    locationFilter
  )

  return (
    <Page marginY={14}>
      <AltirSkeleton isLoading={loading} error={error}>
        <Flex flexDir='column' gap={6} w='100%'>
          {isGrasshopperMigrationEnabled && <GrasshopperMigrationBanner/>}
          <Flex
            width='100%'
            flexDir='column'
            mx={4}
          >
            {/* {shouldShowFDICModal && <FDICAmplifyInfoModal/>} */}
            <Flex flexDir='row' justify='space-between' alignItems='center'>
              <HeaderStatistic description={headerText} statistic={totalBalanceFormatted ?? EMPTY_BALANCE_VALUE}/>
              {
            hasLinkedAccount &&
              <Flex flexDir='row'>
                {
                  hasAmplifyAccount && isBank &&
                    <Flex>
                      <IconCircleButton
                        icon={<TransferIcon/>}
                        color={Color.WHITE}
                        backgroundColor={Color.BRIGHT_BLUE}
                        hoverBackgroundColor={Color.DARK_BLUE}
                        text='Transfer'
                        onClick={() => { navigate(ROUTES.TRANSFER) }}
                        disabledReasonText={!canTransfer ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
                      />
                      <IconCircleButton
                        icon={<ClockIcon/>}
                        color={Color.DARK_BLUE}
                        backgroundColor={Color.WHITE}
                        hoverBackgroundColor={Color.GREY}
                        text='Transfer History'
                        onClick={() => { navigate(ROUTES.TRANSFER_HISTORY) }}
                        disabledReasonText={!canTransfer ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
                      />
                    </Flex>
                }
                <InstitutionConnectionComponent
                  franchiseGroupId={requestedFranchiseGroupId}
                  handleBankAccountLink={refetch}
                  defaultIsOpen={isLinkAccountModalOpen}
                  button={
                    <IconCircleButton
                      icon={<AccountsIcon/>}
                      color={Color.DARK_BLUE}
                      backgroundColor={Color.WHITE}
                      hoverBackgroundColor={Color.GREY}
                      text='Link an Account'
                      disabledReasonText={!canLinkAccounts ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
                    />
                }
                />
              </Flex>
          }
            </Flex>
            <Flex flexDirection='row' width='100%' my={8}>
              {shouldShowAmplifyComponent &&
                <Flex flex={1} paddingRight={4}>
                  <AmplifyAccountCardComponent
                    organizationId={organizationId}
                    franchiseGroupId={Number(franchiseGroupId)}
                    totalBalance={
                      franchiseGroup?.aggregateAccountBalance?.availableBalance?.amount ?? undefined
                    }
                  />
                </Flex>
              }
              {shouldShowCapitalOSComponent &&
                <Flex flex={2} paddingRight={4}>
                  <CapitalOSAccountCardComponent/>
                </Flex>
              }
              <Flex flexDirection='column' width='100%' flex={2} gap={3}>
                <Text>
                  {secondaryHeaderText}
                </Text>
                {
          hasLinkedAccount
            ? <Flex gap={2} direction='column'>
              <AccountsSortAndFilterComponent
                queryValue={accountQueryString}
                setQueryValue={(value) => { setAccountQueryString(value) }}
                selectedInstitutions={institutionFilter}
                institutionOptions={getInstitutionNames(franchiseGroup?.financialAccounts ?? [])}
                setSelectedInstitutions={(institutions) => { setInstitutionFilter(institutions) }}
                selectedLocations={locationFilter}
                locationOptions={getStoreLocations(franchiseGroup?.financialAccounts ?? [])}
                setSelectedLocations={(locations) => { setLocationFilter(locations) }}
                sortByInput={sortByInput}
                setSortByInput={(value) => { setSortByInput(value) }}
              />
              {filteredAccounts.map((account, i) => {
                return (
                  <FinancialAccountDropDownComponent
                    key={i}
                    isTransactionRefreshEnabled={isTransactionRefreshEnabled}
                    account={account}
                    refetch={refetch}
                  />
                )
              })}
            </Flex>
            : <EmptyAccountsSectionComponent
                franchiseGroupId={requestedFranchiseGroupId}
                isLinkAccountModalOpen={isLinkAccountModalOpen}
                refetch={refetch}
              />
          }
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </AltirSkeleton>
    </Page>
  )
}

function getTotalBalanceFormatted (
  accountType?: LinkedAccountType,
  aggregateBalance?: AggregateBalance,
  capitalOSBalance?: CapitalOSBalance
): string | undefined {
  // TODO (PJ): This is a hack since we don't store the capitalOS balance as an AccountBalance
  // Remove when we begin creating balances
  if (accountType === LinkedAccountType.CARD) {
    const globalBalanceInt: number = aggregateBalance?.currentBalance?.amount ?? 0
    const capitalOSBalanceInt: number = capitalOSBalance?.amount ?? 0
    return getCurrencyFormatted(
      globalBalanceInt + capitalOSBalanceInt,
      { minimumFractionDigits: 2 }
    )
  }

  return getCurrencyFormatted(aggregateBalance?.availableBalance?.amount, {
    minimumFractionDigits: 2
  })
}
