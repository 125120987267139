import React, { useEffect, type ReactElement } from 'react'
import { Outlet } from 'react-router-dom'
import { useNoContextStore } from '@/hooks/store/useNoContextStore'

/**
 * Global layout that is applied to all pages, regardless of authentication or onboarding status
 *
 * currently just used to store invite codes in local store
 *    (need to be persisted so that they avoid getting lost when we auth)
 */
export default function GlobalLayout (): ReactElement {
  const { persistedInviteCode, setPersistedInviteCode } = useNoContextStore((state) => {
    return {
      persistedInviteCode: state.inviteCode,
      setPersistedInviteCode: state.setInviteCode
    }
  })
  const searchParams = new URLSearchParams(location.search)
  const inviteCodeFromLink = searchParams.get('inviteCode')

  useEffect(() => {
    if (inviteCodeFromLink != null && inviteCodeFromLink !== persistedInviteCode) {
      setPersistedInviteCode(inviteCodeFromLink)
    }
  }, [persistedInviteCode, setPersistedInviteCode, inviteCodeFromLink])

  return (
    <Outlet/>
  )
}
