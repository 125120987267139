import { gql } from '@apollo/client'

export const AMPLIFY_ACCOUNT_BUSINESS_APPLICATION_FORMATTED_FRAGMENT = gql`
    fragment AmplifyAccountBusinessApplicationFormattedFragment on AmplifyAccountBusinessApplicationFormatted {
        id
        businessDescription
        doingBusinessAsName
        incorporationState
        legalName
        legalStructure
        mailingAddress {
            city
            country
            postalCode
            state
            streetAddressLine1
            streetAddressLine2
        }
        naicsDescription
        naicsSectorCode
        phoneNumber
        physicalAddress {
            streetAddressLine2
            streetAddressLine1
            state
            postalCode
            country
            city
        }
        urls
        status
    }
`
