import { gql } from '@apollo/client'

export const CREATE_STORE_LOCATION = gql`
    mutation CreateStoreLocation($franchiseGroupId: Int!, $storeLocationInput: StoreLocationInput!) {
        createStoreLocation(franchiseGroupId: $franchiseGroupId, storeLocationInput: $storeLocationInput) {
            checkFieldIdentifier
            name
            posFieldIdentifier
            storeId
            id
        }
    }
`
