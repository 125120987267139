import React, { useEffect, useState, type ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import { Flex, Heading, useToast } from '@chakra-ui/react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { type ApolloError, useMutation } from '@apollo/client'
import Button, { ButtonVariant } from '@/library/button/Button'
import { FormInput } from '@/library/form/text/FormInput'
import { getErrorToast, getSuccessToast } from '@/utils/toastUtils'
import { GraphQLErrorCode } from '@/graphql/__generated__/globalTypes'
import { getErrorCode } from '@/utils/errorUtils'
import { type ErrorWithContent } from '@/types/types'
import ErrorInline from '@/library/errors/ErrorInline'
import { replaceEmptyStringWithUndefined } from '@/utils/stringUtils'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { Color } from '@/theme/theme'
import { UPDATE_STORE_LOCATION } from '@/graphql/mutations/UpdateStoreLocation'
import {
  type UpdateStoreLocation,
  type UpdateStoreLocationVariables
} from '@/graphql/__generated__/UpdateStoreLocation'
import { type GetAllStoreLocations_currentUser_franchiseGroup_storeLocations as StoreLocation }
  from '@/graphql/__generated__/GetAllStoreLocations'

// Define Zod Schema
const storeLocationUpdateFormSchema = z.object({
  storeLocationName: z.string()
    .min(1, { message: 'Please enter a Store Name' })
    .optional(),
  storeLocationStoreId: z.string()
    .min(1, { message: 'Please enter a Store ID' })
    .optional(),
  storeLocationCheckId: z.string()
    .transform(replaceEmptyStringWithUndefined)
    .optional(),
  storeLocationPosId: z.string()
    .transform(replaceEmptyStringWithUndefined)
    .optional()
})
// Create a type from the zod schema
type EditStoreLocationFormInput = z.infer<typeof storeLocationUpdateFormSchema>

interface EditStoreLocationProps {
  isOpen: boolean
  onClose: () => void
  franchiseGroupId: number
  storeLocation: StoreLocation
}

export default function EditStoreLocationModal ({
  isOpen,
  onClose,
  franchiseGroupId,
  storeLocation
}: EditStoreLocationProps): ReactElement {
  const toast = useToast()
  const [submissionError, setSubmissionError] = useState<ErrorWithContent | undefined>()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<EditStoreLocationFormInput>({
    resolver: zodResolver(storeLocationUpdateFormSchema)
  })

  const [updateStoreLocation] = useMutation<UpdateStoreLocation, UpdateStoreLocationVariables>(
    UPDATE_STORE_LOCATION, {
      onCompleted: onSuccess,
      onError
    }
  )

  // Pre-populate form with current store location data, re-render form on upstream changes
  useEffect(() => {
    reset({
      storeLocationName: storeLocation.name,
      storeLocationStoreId: storeLocation.storeId,
      storeLocationCheckId: storeLocation.checkFieldIdentifier ?? undefined,
      storeLocationPosId: storeLocation.posFieldIdentifier ?? undefined
    })
  }, [storeLocation])

  function onError (error: ApolloError): void {
    const errorCode = getErrorCode(error)
    switch (errorCode) {
      case GraphQLErrorCode.CHECK_ID_FIELD_ALREADY_IN_USE:
        setSubmissionError({
          customContent: {
            title: 'Invalid Check ID',
            subtitle: 'This Check ID  is already associated with another store location'
          },
          error: Error()
        })
        return
      case GraphQLErrorCode.POS_ID_FIELD_ALREADY_IN_USE:
        setSubmissionError({
          customContent: {
            title: 'Invalid POS ID',
            subtitle: 'This POS ID is already associated with another store location'
          },
          error: Error()
        })
        return
    }
    toast(getErrorToast('Failed to Update Store Location'))
  }

  function onSuccess (): void {
    toast(getSuccessToast('Store Location Updated'))
    handleClose()
  }

  function handleClose (): void {
    reset()
    setSubmissionError(undefined)
    onClose()
  }
  async function onSubmit (formData: EditStoreLocationFormInput): Promise<void> {
    await updateStoreLocation({
      variables: {
        franchiseGroupId,
        storeLocationId: storeLocation.id,
        storeLocationUpdate: {
          name: formData.storeLocationName,
          storeId: formData.storeLocationStoreId,
          checkFieldIdentifier: formData.storeLocationCheckId,
          posFieldIdentifier: formData.storeLocationPosId
        }
      }
    })
  }
  return (
    <ModalComponent isOpen={isOpen} onClose={handleClose} >
      <Flex flexDir='column' w='100%' alignItems='center' textAlign='center' gap={3}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <Heading color={Color.DARK_BLUE} size='lg'>Edit Store Location</Heading>
          <Flex w='100%' flexDir='column' gap={4}>
            {/* Name */}
            <FormInput<EditStoreLocationFormInput>
              label='Name'
              fieldName='storeLocationName'
              fieldError={errors?.storeLocationName?.message}
              placeholder='E.g. East Side Store'
              register={register}
              registerLabel='storeLocationName'
              allowAutoComplete={false}
            />
            {/* Location ID */}
            <FormInput<EditStoreLocationFormInput>
              label='Location ID'
              fieldName='storeLocationStoreId'
              fieldError={errors?.storeLocationStoreId?.message}
              placeholder='E.g. 01234'
              register={register}
              registerLabel='storeLocationStoreId'
              allowAutoComplete={false}
            />
            {/* Check ID */}
            <FormInput<EditStoreLocationFormInput>
              label='Check Number Identifier'
              fieldName='storeLocationCheckId'
              backgroundColor={Color.WHITE}
              fieldError={errors?.storeLocationCheckId?.message}
              placeholder='E.g. 01234'
              register={register}
              registerLabel='storeLocationCheckId'
              allowAutoComplete={false}
            />
            {/* POS ID */}
            <FormInput<EditStoreLocationFormInput>
              label='Point of Sale Identifier'
              fieldName='storeLocationPosId'
              fieldError={errors?.storeLocationPosId?.message}
              placeholder='E.g. Toast POS ID: 01234'
              register={register}
              registerLabel='storeLocationPosId'
              allowAutoComplete={false}
            />
            <ErrorInline error={submissionError}/>
            <Button
              text='Save Changes'
              variant={ButtonVariant.PRIMARY}
            />
          </Flex>
        </form>
        <Button
          text='Cancel'
          variant={ButtonVariant.WHITE}
          onClick={handleClose}
        />
      </Flex>
    </ModalComponent>
  )
}
