import React, { type ReactElement } from 'react'
import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import BusinessSettingsComponent from './components/business_settings/BusinessSettingsComponent'
import UserSettingsComponent from './components/user_settings/UserSettingsComponent'
import OrganizationSettingsComponent from './components/organization_settings/OrganizationSettingsComponent'

import Page from '@/library/page/Page'
import { useNavigationState } from '@/hooks/useNavigationState'
import { ContainerWidth } from '@/theme/theme'

interface SettingsPageNavigationState {
  isBusinessSettingPreSelected?: boolean
  isUserSettingsPreSelected?: boolean
  isLinkedAccountsSelected?: boolean
}

enum SettingsIndex {
  ORG_SETTINGS = 0,
  BUSINESS_SETTINGS = 1,
  USER_SETTINGS = 2
}

export default function SettingsPage (): ReactElement {
  const navigationState = useNavigationState<SettingsPageNavigationState>()

  return (
    <Page maxWidth={ContainerWidth.FULL_PAGE}>
      <Flex w='100%' justifyContent='center'>
        <Tabs width='100%' defaultIndex={getIndexForNavigationState(navigationState)}>
          <TabList>
            <Tab>Organization Settings</Tab>
            <Tab>Business Settings</Tab>
            <Tab>User Settings</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <OrganizationSettingsComponent/>
            </TabPanel>
            <TabPanel>
              <BusinessSettingsComponent/>
            </TabPanel>
            <TabPanel>
              <UserSettingsComponent/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Page>
  )
}

function getIndexForNavigationState (navigationState?: SettingsPageNavigationState | null): SettingsIndex {
  if (navigationState?.isBusinessSettingPreSelected === true || navigationState?.isLinkedAccountsSelected === true) {
    return SettingsIndex.BUSINESS_SETTINGS
  }
  if (navigationState?.isUserSettingsPreSelected === true) {
    return SettingsIndex.USER_SETTINGS
  }
  return SettingsIndex.ORG_SETTINGS
}
