import React, { type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import GrasshopperMigrationBannerContent from './GrasshopperMigrationBannerContent'
import {
  type GetBankMigrationStatus,
  type GetBankMigrationStatusVariables
} from '@/graphql/__generated__/GetBankMigrationStatus'
import { GET_BANK_MIGRATION_STATUS } from '@/graphql/queries/account_upgrade/GetBankMigrationStatus'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { BankMigrationStatus } from '@/graphql/__generated__/globalTypes'

export default function GrasshopperMigrationBanner (): ReactElement | null {
  const organizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)

  const { data, loading } = useQuery<GetBankMigrationStatus, GetBankMigrationStatusVariables>(
    GET_BANK_MIGRATION_STATUS,
    { variables: { organizationId } }
  )

  const migrationStatus = data?.currentUser?.selectedOrganization?.bankMigrationStatus ?? null

  if (!loading && migrationStatus === BankMigrationStatus.COMPLETE) {
    return null
  }

  return (
    <AltirSkeleton isLoading={loading}>
      <GrasshopperMigrationBannerContent status={migrationStatus}/>
    </AltirSkeleton>
  )
}
