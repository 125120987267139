
/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function SecureBadgeIcon ({ color, size = IconSize.LARGE }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M10.8182 0.425055C10.6348 0.164091 10.3379 0.00625779 10.019 0.000181929C9.70015 -0.00589393 9.39749 0.140516 9.2043 0.394306C8.34658 1.52109 7.19169 2.40358 5.85945 2.94613C4.52708 3.48873 3.06848 3.67041 1.63796 3.47115C1.35145 3.43124 1.06169 3.51736 0.84348 3.70728C0.625272 3.8972 0.5 4.1723 0.5 4.46158V8.31087L0.500003 8.31318C0.505614 10.7416 1.25864 13.1102 2.65825 15.111C4.05765 17.1115 6.03784 18.6508 8.33885 19.5325L8.34062 19.5332L9.17645 19.8516L9.17642 19.8517L9.18843 19.8561C9.71201 20.048 10.288 20.048 10.8116 19.8561L10.8116 19.8562L10.8235 19.8516L11.6594 19.5332L11.6611 19.5325C13.9622 18.6508 15.9424 17.1115 17.3418 15.111C18.7414 13.1102 19.4944 10.7416 19.5 8.31318L19.5 8.31087V4.46158C19.5 4.18499 19.3854 3.92075 19.1836 3.73169C18.9817 3.54262 18.7105 3.44561 18.4345 3.46373C14.8898 3.69642 12.3386 2.58871 10.8182 0.425055ZM6.61379 4.79842C7.88221 4.28186 9.02547 3.51634 9.97478 2.55251C11.8116 4.51624 14.4114 5.48634 17.5 5.49376L17.5 8.30856L17.5 8.30937C17.4952 10.3257 16.8701 12.2962 15.7029 13.9646C14.5355 15.6335 12.8789 16.924 10.9458 17.6648L10.9455 17.6649L10.1193 17.9797C10.0425 18.0068 9.95751 18.0068 9.88068 17.9797L9.05448 17.6649L9.05407 17.6647C7.12106 16.924 5.46449 15.6334 4.29707 13.9646C3.13 12.2962 2.50489 10.326 2.5 8.30969V5.54608C3.90513 5.58339 5.30737 5.33045 6.61379 4.79842ZM15.0338 8.65459C15.3953 8.23707 15.3499 7.60554 14.9324 7.24402C14.5149 6.8825 13.8833 6.92789 13.5218 7.34541L9.39182 12.1151L7.18341 10.0477C6.78022 9.67031 6.1474 9.69118 5.76996 10.0944C5.39253 10.4976 5.4134 11.1304 5.81659 11.5078L8.78391 14.2856C8.98244 14.4714 9.2481 14.5684 9.51966 14.5542C9.79123 14.54 10.0453 14.4157 10.2233 14.2101L15.0338 8.65459Z'/>
      </svg>
    </IconContainer>
  )
}
