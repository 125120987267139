import React, { type ReactElement } from 'react'
import { Center, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import CreateTransferRuleGenericComponent from './components/CreateTransferRuleGenericComponent'
import { useNavigationState } from '@/hooks/useNavigationState'
import BusinessSelectorComponent from '@/library/state/BusinessSelectorComponent'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { ROUTES } from '@/api/routes'
import LeftArrowIcon from '@/library/icons/LeftArrowIcon'
import { Color, ContainerWidth, IconSize } from '@/theme/theme'
import { GenericTransferRuleType } from '@/utils/transferRuleUtils'

const BALANCE_RULE_INSTRUCTIONS =
// eslint-disable-next-line max-len
'A balance-based rule allows you to automatically optimize your yield. You can either transfer out funds when an account exceeds a specified balance (minimum $500 transfer), or pull funds in when it falls below a specified balance.'

const RECURRING_RULE_INSTRUCTIONS = 'Set up either a recurring or one-time scheduled transfer.'

export const BALANCE_TAB = 0
export const TIME_TAB = 1

export default function TransferRuleCreationPage (): ReactElement {
  const navigate = useNavigate()
  const navigationState = useNavigationState<{ startingTab: number | undefined, counterparty: string | undefined }>()
  const startingTab = navigationState?.startingTab ?? BALANCE_TAB
  const preSelectedCounterpartyId = navigationState?.counterparty

  return (
    <Center w='100%'>
      <Center flexDir='column' gap='8' width={ContainerWidth.FULL_PAGE}>
        <Flex justifyContent='space-between' alignItems='center' w='100%'>
          <Button
            text='View All Rules'
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.GREY}
            onClick={() => { navigate(ROUTES.AUTOMATED_TRANSFERS) }}
            beforeIcon={<LeftArrowIcon color={Color.DARK_BLUE} size={IconSize.SMALL}/>}
            width='flex'
          />
          <BusinessSelectorComponent businessFilterParams={{ hasAmplify: true }} requireMultipleBusinesses={true}/>
        </Flex>
        <Tabs defaultIndex={startingTab} w='100%'>
          <TabList>
            <Tab fontWeight='medium'>Balance-Based Rule </Tab>
            <Tab fontWeight='medium'>Time-Based Rule </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <CreateTransferRuleGenericComponent
                header='Create a Balance-Based Rule'
                subtext={BALANCE_RULE_INSTRUCTIONS}
                ruleType={GenericTransferRuleType.BALANCE}
                preSelectedCounterpartyId={preSelectedCounterpartyId}
              />
            </TabPanel>
            <TabPanel>
              <CreateTransferRuleGenericComponent
                header='Create a Time-Based Rule'
                subtext={RECURRING_RULE_INSTRUCTIONS}
                ruleType={GenericTransferRuleType.TIME}
                preSelectedCounterpartyId={preSelectedCounterpartyId}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Center>
    </Center>
  )
}
