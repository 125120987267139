import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { Color } from '@/theme/theme'

interface DefaultMenuItemProps {
  color: string
  onClick: () => void
  children: ReactElement
  isFocused: boolean
  isCompact: boolean
}

export default function DefaultMenuItem ({
  color,
  onClick,
  children,
  isFocused,
  isCompact
}: DefaultMenuItemProps): ReactElement {
  return (
    <Flex
      alignItems='center'
      justifyContent='start'
      px={4}
      py={2}
      color={color}
      _hover={{ cursor: 'pointer', backgroundColor: Color.LIGHT_GREY }}
      onClick={onClick}
      backgroundColor={isFocused ? Color.LIGHT_GREY : Color.WHITE}
      borderRadius={10}
      w='100%'
    >
      {children}
    </Flex>
  )
}
