import { Flex, Heading, Text } from '@chakra-ui/react'
import React, { type ReactNode, useState, type ReactElement } from 'react'
import { type ApolloError, useMutation } from '@apollo/client'
import { DateTime } from 'luxon'
import { ModalComponent } from '../ModalComponent'
import Button, { ButtonVariant } from '../../button/Button'
import SupportEmailInlineLink from '../../text/SupportEmailInlineLink'
import FormPinInput from '../../form/number/FormPinInput'
import Loader from '../../loading/Loader'
import { Graphic } from '../../utility/Graphic'
import { Color, ModalHeight } from '@/theme/theme'
import { CIRCLE_CHECK_GRAPHIC, LOCK } from '@/theme/graphics'
import { VERIFY_USER_PASSCODE } from '@/graphql/mutations/VerifyUserPasscode'
import { type VerifyUserPasscodeVariables, type VerifyUserPasscode } from '@/graphql/__generated__/VerifyUserPasscode'
import { getErrorCode } from '@/utils/errorUtils'
import { GraphQLErrorCode } from '@/graphql/__generated__/globalTypes'
import { type ErrorWithContent } from '@/types/types'
import { isFutureDate } from '@/utils/dateUtils'
import { useAltirStore } from '@/hooks/store/useAltirStore'

interface PasscodeVerificationModalProps {
  isOpen: boolean
  onClose: () => void
  onVerificationStatusChange: (isVerified: boolean) => void
}

const PASSCODE_EXPLANATION_TEXT = `
  For your security, we need to verify your identity. In order to continue, please enter your 4-digit PIN.
`

const PASSCODE_VERIFICATION_TEXT = `
  We have verified your 4-digit PIN.  Continue to make your transfer.
`

const PASSCODE_MISMATCH_ERROR_TEXT = `
  The 4-digit PIN you entered is incorrect. You may attempt 3 times before you are temporarily prevented from making outgoing transfers.
`

export default function PasscodeVerificationModal ({
  isOpen,
  onClose,
  onVerificationStatusChange
}: PasscodeVerificationModalProps): ReactElement {
  const passcodeCooldownTimeISO = useAltirStore(state => state.passcodeCooldownTimeISO)
  const startPasscodeCooldownWindow = useAltirStore(state => state.startPasscodeCooldownWindow)
  // Mutation
  const [
    verifyUserPasscode,
    { loading }
  ] = useMutation<VerifyUserPasscode, VerifyUserPasscodeVariables>(VERIFY_USER_PASSCODE)

  // Submission results
  const [isSuccess, setIsSuccess] = useState(false)
  const [submissionError, setSubmissionError] = useState<ErrorWithContent | undefined>()

  // Pin
  const [pinValue, setPinValue] = useState('')

  function handleCompletion (value: string): void {
    setPinValue('')
    void verifyUserPasscode({ variables: { passcode: value } })
      .then(() => {
        setIsSuccess(true)
        onVerificationStatusChange(true)
        setSubmissionError(undefined)
      }).catch((error: ApolloError) => {
        handlePasscodeVerificationFailure(error)
      })
  }

  function handlePasscodeVerificationFailure (error: ApolloError): void {
    onVerificationStatusChange(false)
    const errorCode = getErrorCode(error)
    if (errorCode === GraphQLErrorCode.PASSCODE_RETRIES_EXCEEDED) {
      startPasscodeCooldownWindow()
    } else if (errorCode === GraphQLErrorCode.PASSCODE_MISMATCH) {
      setSubmissionError({
        customContent: {

          title: 'Incorrect PIN',
          subtitle: PASSCODE_MISMATCH_ERROR_TEXT
        },
        error: Error()
      })
    } else {
      setSubmissionError({
        error: Error()
      })
    }
  }

  function handleChange (value: string): void {
    setPinValue(value)
  }

  function handleClose (): void {
    onClose()
    setIsSuccess(false) // So that next time the modal opens, the user will have to re-enter their PIN
  }

  function getFormContent (): ReactNode {
    return (
      <Flex flexDir='column' alignItems='center' gap={12}>
        <Flex flexDir='column' gap={2} alignItems='center' textAlign='center'>
          <Heading color={Color.DARK_BLUE} size='lg'>
            {isSuccess ? 'PIN Verified' : 'Enter Your PIN'}
          </Heading>
          <Text>
            {
              isSuccess
                ? PASSCODE_VERIFICATION_TEXT
                : PASSCODE_EXPLANATION_TEXT
            }
          </Text>
        </Flex>
        <Flex justifyContent='center' alignItems='center' h='100%' w='100%'>
          {loading && <Loader size='50px'/>}
          {isSuccess && <Graphic src={CIRCLE_CHECK_GRAPHIC} h='64px'/>}
          {
            !loading && !isSuccess &&
              <FormPinInput
                value={pinValue}
                onComplete={handleCompletion}
                onChange={handleChange}
                error={submissionError}
              />
          }
        </Flex>
        <Flex flexDir='column' gap={4} alignItems='center' textAlign='center' width='100%'>
          {isSuccess
            ? <Button text='Continue' onClick={handleClose}/>
            : <Button text='Cancel' variant={ButtonVariant.WHITE} onClick={handleClose}/>
            }
          <Text>
            Forgot your PIN? Contact us at
            <SupportEmailInlineLink />
          </Text>
        </Flex>
      </Flex>
    )
  }

  function getCooldownPeriodContent (): ReactNode {
    return (
      <Flex flexDir='column' alignItems='center' textAlign='center' justifyContent='space-between'>
        <Graphic src={LOCK} h='116px'/>
        <Heading color={Color.ERROR_RED} size='lg'>
          Your Transfer PIN has been locked after three failed attempts.
        </Heading>
        <Text>
          You can try again ten minutes after your last attempt. If the problem persists, contact us at
          <SupportEmailInlineLink/>
        </Text>
        <Button text='Cancel' variant={ButtonVariant.WHITE} onClick={handleClose}/>
      </Flex>
    )
  }

  const isInCooldownPeriod = isFutureDate(DateTime.fromISO(passcodeCooldownTimeISO))

  return (
    <ModalComponent isOpen={isOpen} onClose={handleClose} height={ModalHeight.SMALL}>
      {isInCooldownPeriod ? getCooldownPeriodContent() : getFormContent()}
    </ModalComponent>
  )
}
