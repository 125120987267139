import React, { type ReactNode, type ReactElement, useState } from 'react'
import { IconSize } from '../theme/theme'
import CircleExclamationIcon from '../icons/CircleExclamationIcon'
import Banner, { BannerVariant } from '../banner/Banner'

export interface ErrorBannerProps {
  errorTitle?: string | null
  errorSubTitle?: string | null
  inlineCallToActionComponent?: ReactNode
  isCollapsible?: boolean
  onClose?: () => void
  isHidden?: boolean
}

export default function ErrorBanner ({
  errorTitle,
  errorSubTitle,
  inlineCallToActionComponent,
  isCollapsible = false,
  isHidden = false,
  onClose = () => {}
}: ErrorBannerProps): ReactElement | null {
  const [isOpen, setIsOpen] = useState(!isHidden)

  function onCollapse (): void {
    onClose()
    setIsOpen(false)
  }

  if (errorTitle == null || errorSubTitle == null || !isOpen) return null
  return (
    <Banner
      title={errorTitle}
      subtitle={errorSubTitle}
      beforeIcon={<CircleExclamationIcon size={IconSize.LARGE}/>}
      onCollapse={isCollapsible ? onCollapse : undefined}
      callToActionComponent={inlineCallToActionComponent}
      variant={BannerVariant.ERROR}
    />
  )
}
