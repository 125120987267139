import { gql } from '@apollo/client'
import { FINANCIAL_ACCOUNT_FRAGMENT } from '../../fragments/financial_accounts/FinancialAccountFragment'

export const REFRESH_TRANSACTIONS = gql`
    ${FINANCIAL_ACCOUNT_FRAGMENT}
    mutation RefreshTransactions($plaidItemId: String!) {
        refreshTransactions(
            plaidItemId: $plaidItemId, 
        ) {
            ...FinancialAccountFragment
        }
    }
`
