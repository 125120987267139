import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import NextStepsCard from './NextStepsCard'
import { ALTIR_CARD, BANK_PLUS_GRAPHIC, CHAIN_LINK, HOUR_GLASS_GRAPHIC } from '@/theme/graphics'
import { GET_ONBOARDING_NEXT_STEP } from '@/graphql/queries/GetOnboardingNextStep'
import {
  type GetOnboardingNextStep,
  type GetOnboardingNextStepVariables
} from '@/graphql/__generated__/GetOnboardingNextStep'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { ROUTES } from '@/api/routes'

interface NextStepsSectionProps {
  id?: string
}

enum OnboardingCTAStep {
  START_AMPLIFY_APP,
  CONTINUE_AMPLIFY_APP,
  LINK_ACCOUNT,
  CREATE_TRANSFER,
  CREATE_TRANSFER_RULE,
  START_CARD_APP,
}

interface CardContent {
  icon: string
  text: string
  buttonText: string
  buttonLink: string
}

// @todo/tucker update content once design is final
const cardContent: Record<OnboardingCTAStep, CardContent> = {
  [OnboardingCTAStep.START_AMPLIFY_APP]: {
    icon: BANK_PLUS_GRAPHIC,
    text: 'Apply for an Amplify Account to start earning interest!*',
    buttonText: 'Start Application',
    buttonLink: ROUTES.AMPLIFY_APP_APPLY
  },
  [OnboardingCTAStep.CONTINUE_AMPLIFY_APP]: {
    icon: BANK_PLUS_GRAPHIC,
    text: 'Finish your Amplify Account Application(s) to start earning interest!*',
    buttonText: 'Finish Application',
    buttonLink: ROUTES.AMPLIFY_APP_APPLY
  },
  [OnboardingCTAStep.LINK_ACCOUNT]: {
    icon: CHAIN_LINK,
    text: 'Get started by linking an account!',
    buttonText: 'Link an Account',
    buttonLink: ROUTES.TREASURY
  },
  [OnboardingCTAStep.CREATE_TRANSFER]: {
    icon: BANK_PLUS_GRAPHIC,
    text: 'Make your first transfer!',
    buttonText: 'Make a transfer.',
    buttonLink: ROUTES.TRANSFER
  },
  [OnboardingCTAStep.CREATE_TRANSFER_RULE]: {
    icon: HOUR_GLASS_GRAPHIC,
    text: 'Automate a transfer to save time and money.',
    buttonText: 'Create a Transfer Rule',
    buttonLink: ROUTES.CREATE_AUTOMATED_TRANSFER_RULE
  },
  [OnboardingCTAStep.START_CARD_APP]: {
    icon: ALTIR_CARD,
    text: 'Open an Altir Card today!',
    buttonText: 'Open a Card',
    buttonLink: ROUTES.CARDS
  }
}

export default function NextStepsSection ({ id }: NextStepsSectionProps): ReactElement {
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const {
    data,
    loading: isDataLoading
  } = useQuery<GetOnboardingNextStep, GetOnboardingNextStepVariables>(
    GET_ONBOARDING_NEXT_STEP,
    { variables: { organizationId: selectedOrganizationId ?? '' }, fetchPolicy: 'network-only' }
  )

  const cardKey: OnboardingCTAStep = OnboardingCTAStep[data?.onboardingCTAStep as keyof typeof OnboardingCTAStep]
  const cardData: CardContent = cardContent[cardKey]

  if (cardData == null) return (<></>)
  return (
    <Flex flexDir='column'>
      <Text>My Next Steps</Text>
      <NextStepsCard
        isLoading={isDataLoading}
        icon={cardData.icon}
        text={cardData.text}
        key={cardData.text}
        buttonText={cardData.buttonText}
        buttonLink={cardData.buttonLink}
      />
    </Flex>
  )
}
