import React, { useState, type ReactElement } from 'react'
import { Flex, useToast } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { type BusinessSelection, filterBusinesses } from './utils'
import { AvatarComponent } from '../utility/AvatarComponent'
import { BorderRadius, FormInputHeight, IconSize } from '@/theme/theme'
import PopoverMenu from '@/library/popoverMenu/components/PopoverMenu'
import PopoverMenuSingleOptionSelect from '@/library/popoverMenu/PopoverMenuSingleOptionSelect'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { getErrorToast, getSuccessToast } from '@/utils/toastUtils'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import {
  type GetBusinessOptionsForOrganization,
  type GetBusinessOptionsForOrganizationVariables
} from '@/graphql/__generated__/GetBusinessOptionsForOrganization'
import { GET_BUSINESS_OPTIONS_FOR_ORGANIZATION } from '@/graphql/queries/GetBusinessOptionsForOrganization'
import { safeGetFirstLetter } from '@/utils/stringUtils'

export interface BusinessFilterParams {
  hasAmplify?: boolean
  needsToApplyForAmplify?: boolean
}

interface BusinessSelectorComponentProps {
  businessFilterParams?: BusinessFilterParams
  requireMultipleBusinesses?: boolean

  // Styles
  border?: string
  shouldRotateMenuIcon?: boolean
  height?: string
}

export default function BusinessSelectorComponent ({
  shouldRotateMenuIcon,
  requireMultipleBusinesses = false,
  border = '0px',
  height = FormInputHeight.DEFAULT,
  businessFilterParams = {}
}: BusinessSelectorComponentProps): ReactElement {
  const toast = useToast()
  const {
    selectedOrganizationId,
    selectedFranchiseGroupId,
    setSelectedFranchiseGroupId
  } = useAltirStore(state => {
    return {
      selectedOrganizationId: state.selectedOrganizationState.selectedOrganizationId,
      selectedFranchiseGroupId: state.selectedFranchiseGroupId,
      setSelectedFranchiseGroupId: state.setSelectedFranchiseGroupId
    }
  })
  const [selectedBusiness, setSelectedBusiness] = useState<BusinessSelection | undefined>(undefined)
  const {
    data,
    loading,
    error
  } = useQuery<GetBusinessOptionsForOrganization, GetBusinessOptionsForOrganizationVariables>(
    GET_BUSINESS_OPTIONS_FOR_ORGANIZATION,
    {
      variables: { organizationId: selectedOrganizationId },
      onCompleted: (data) => {
        const selectedBusiness = (data
          ?.currentUser
          ?.selectedOrganization
          ?.franchiseGroups ?? [])
          .find(fg => Number(fg.id) === selectedFranchiseGroupId)
        setSelectedBusiness(selectedBusiness)
      },
      fetchPolicy: 'network-only'
    }
  )

  function handleSelect (business: BusinessSelection): void {
    if (business.id != null) {
      // Update selection state
      setSelectedFranchiseGroupId(Number(business.id))
      setSelectedBusiness(business)

      // Display toast
      const successText = business.name != null
        ? `Successfully switched to ${business.name}`
        : 'Successfully switched context'
      toast(getSuccessToast(successText))
    } else {
      toast(getErrorToast('Unable to switch businesses'))
    }
  }

  const options = filterBusinesses(data?.currentUser?.selectedOrganization?.franchiseGroups ?? [], businessFilterParams)

  if (!loading && requireMultipleBusinesses && options.length < 2) {
    return <></>
  }

  const beforeIcon = selectedBusiness != null
    ? <AvatarComponent text={safeGetFirstLetter(selectedBusiness.name)} size={IconSize.SMALL}/>
    : undefined
  return (
    <Flex justifyContent='center' alignItems='center' flexDir='column'>
      <AltirSkeleton
        isLoading={loading}
        error={error}
        width='100%'
        isCompact={true}
        customErrorMessage='Something went wrong'
      >
        <PopoverMenu
          popoverMenuTitle={selectedBusiness?.name ?? 'Select Business'}
          onSubmit={() => {}}
          borderRadius={BorderRadius.BUTTON}
          border={border}
          menuButtonHeight={height}
          shouldRotateMenuIcon={shouldRotateMenuIcon}
          shouldMatchWidth
          allowInteriorScroll={true}
          beforeIcon={beforeIcon}
        >
          <PopoverMenuSingleOptionSelect
            selectedOption={selectedBusiness}
            options={options}
            setSelectedOption={handleSelect}
            formattingFunction={(business) => { return { title: business.name ?? '' } }}
          />
        </PopoverMenu>
      </AltirSkeleton>
    </Flex>
  )
}
