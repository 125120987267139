import React, { useState, type ReactElement } from 'react'
import { Flex, Input, InputGroup, InputRightElement, Text, useToast } from '@chakra-ui/react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import EditIcon from '../icons/EditIcon'
import CircleCheckIcon from '../icons/CircleCheckIcon'
import { BorderRadius, Color, FontWeight, IconSize } from '@/theme/theme'
import {
  type RenameBankAccount,
  type RenameBankAccountVariables
} from '@/graphql/__generated__/RenameBankAccount'
import { RENAME_BANK_ACCOUNT } from '@/graphql/mutations/RenameBankAccount'
import { getErrorToast, getSuccessToast } from '@/utils/toastUtils'

interface AccountNameFormInput {
  accountName: string
}

interface FinancialAccountNameComponentProps {
  accountId?: number
  accountName?: string
  accountNameOfficial?: string
  onUpdate?: () => void
}
export default function FinancialAccountNameComponent (
  { accountId, accountName, accountNameOfficial, onUpdate }: FinancialAccountNameComponentProps
): ReactElement {
  // State
  const [isEditing, setIsEditing] = useState(false)
  const toast = useToast()
  const { register, handleSubmit, setValue } = useForm<AccountNameFormInput>()

  // Mutations
  const [setAccountName] = useMutation<RenameBankAccount, RenameBankAccountVariables>(
    RENAME_BANK_ACCOUNT, {
      onCompleted: () => {
        toast(getSuccessToast('Account Renamed'))
        if (onUpdate != null) {
          onUpdate()
        }
      },
      onError: () => toast((getErrorToast('Unable To Rename Account')))
    }
  )

  const onSubmit: SubmitHandler<AccountNameFormInput> = (formData) => {
    setIsEditing(false)
    if (accountId != null && formData.accountName != null) {
      void setAccountName({ variables: { bankAccountId: accountId, accountName: formData.accountName } })
    }
  }

  function editName (): void {
    if (accountName != null) {
      setValue('accountName', accountName)
    }
    setIsEditing(true)
  }

  const showAccountNameOfficial = accountName != null && accountName !== accountNameOfficial

  return (
    <Flex flexDirection='column' gap={2} width='100%'>
      {/* Account Name row */}
      <Flex flexDirection='row' gap={4} role='group' onClick={() => { editName() }} width='100%'>
        {isEditing
          ? <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            {/* Using a form input allows submission with an enter key */}
            <InputGroup
              width='100%'
              bg={Color.LIGHT_GREY}
              borderRadius={BorderRadius.CARD}
            >
              <Input
                autoFocus
                borderRadius={BorderRadius.CARD}
                id='accountName'
                color={Color.DARK_BLUE}
                fontWeight={FontWeight.MEDIUM}
                placeholder='Account Name'
                border='0px'
                _focus={{ boxShadow: 'none' }} // Removes the chakra default blue focus ring
                {...register('accountName', { required: true })}
              />
              <InputRightElement onClick={handleSubmit(onSubmit)} alignSelf='center' cursor='pointer'>
                <CircleCheckIcon size={IconSize.SCHMEDIUM} color={Color.DARK_BLUE} />
              </InputRightElement>
            </InputGroup>
          </form>
          // Display Name and edit icon on hover of any of the parent state
          : <>
            <Text color={Color.DARK_BLUE}>
              {accountName}
            </Text>
            <Flex visibility='hidden' _groupHover={{ visibility: 'visible' }} alignSelf='center' cursor='pointer'>
              <EditIcon size={IconSize.SMALL} color={Color.DARK_GREY}/>
            </Flex>
          </>
        }
      </Flex>

      {showAccountNameOfficial &&
      <Text fontSize='sm' color={Color.DARK_GREY}>
        {accountNameOfficial}
      </Text>}
    </Flex>
  )
}
