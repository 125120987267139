import React, { type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import Step from './Step'
import VerticalDivider from '@/library/divider/VerticalDivider'

interface StepProps {
  icon: ReactElement
  title: string
}

interface VerticalStepListProps {
  steps: StepProps[]
}

export default function VerticalStepList ({
  steps
}: VerticalStepListProps): ReactElement {
  return (
    <Center flexDir='column' w='100%' gap={2}>
      {
        steps.map((step, index) => {
          return (
            <Center key={index} flexDir='column' w='100%' gap={1}>
              <Step {...step} stepNumber={index + 1}/>
              {
                index < steps.length - 1 && <VerticalDivider/>
              }
            </Center>
          )
        })
      }
    </Center>
  )
}
