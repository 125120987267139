import { gql } from '@apollo/client'

export const GET_BANK_MIGRATION_STATUS = gql`
    query GetBankMigrationStatus($organizationId: String!) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                bankMigrationStatus
            }
        }
    }
`
