import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import CloseButton from '../button/CloseButton'
import { IconSize } from '@/theme/theme'

interface ModalCloseHeaderProps {
  onClose: () => void
  iconSize?: IconSize
  headerContent?: ReactElement
}

export default function ModalCloseHeader ({
  onClose,
  headerContent,
  iconSize = IconSize.MEDIUM
}: ModalCloseHeaderProps): ReactElement {
  if (headerContent != null) {
    return (
      <Flex
        w='100%'
        justifyContent='space-between'
        alignItems='center'
        gap={4}
      >
        {/* Placeholder component for even spacing */}
        <Flex flex={1}></Flex>
        {headerContent}
        <Flex flex={1} justifyContent='end'>
          <CloseButton size={iconSize} onClick={onClose}/>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex
      w='100%'
      justifyContent='end'
      alignItems='start'
    >
      <CloseButton size={iconSize} onClick={onClose}/>
    </Flex>
  )
}
