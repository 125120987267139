import { useCallback, useState } from 'react'
import { type ApolloError, useMutation, useLazyQuery } from '@apollo/client'
import { type TellerConnectEnrollment, type TellerConnectOnSuccess } from 'teller-connect-react'
import { type PlaidLinkOnSuccess, type PlaidLinkOnSuccessMetadata } from 'react-plaid-link'
import { LOG_INSTITUTION_CONNECTION_EVENT } from '@/graphql/mutations/LogInstitutionLinkEvent'
import {
  type LogInstitutionLinkEvent,
  type LogInstitutionLinkEventVariables
} from '@/graphql/__generated__/LogInstitutionLinkEvent'
import { getErrorCode } from '@/utils/errorUtils'
import { type ErrorWithContent, type ErrorContent } from '@/types/types'
import { COMPLETE_INSTITUTION_RELINK } from '@/graphql/mutations/CompleteInstitutionReLink'
import { LINK_INSTITUTION } from '@/graphql/mutations/LinkInstitution'
import { GET_ACCOUNT_LINK_CONFIGURATION } from '@/graphql/queries/GetAccountLinkConfiguration'
import { formatOnSuccessMetadata } from '@/components/clients/plaid/PlaidComponent'
import {
  type LinkInstitution,
  type LinkInstitutionVariables
} from '@/graphql/__generated__/LinkInstitution'
import {
  type CompleteInstitutionReLink,
  type CompleteInstitutionReLinkVariables
} from '@/graphql/__generated__/CompleteInstitutionReLink'
import {
  type GetAccountLinkConfiguration,
  type GetAccountLinkConfigurationVariables
} from '@/graphql/__generated__/GetAccountLinkConfiguration'
import { InstitutionConnectionProvider, GraphQLErrorCode } from '@/graphql/__generated__/globalTypes'

export interface UseInstitutionCreateLinkStates {
  initialize: () => void
  isUpdateMode: boolean
  isInitializationMutationLoading: boolean
  onSuccessfulPlaidAccountLogin: PlaidLinkOnSuccess
  onSuccessfulTellerAccountLogin: TellerConnectOnSuccess
  isLinkMutationLoading: boolean
  linkToken: string | null
  error?: Error
  logError: (errorObject: object) => void
  logEvent: (eventObject: object) => void
}
export interface UseInstitutionCreateLinkProps {
  selectedFranchiseGroupId: number
  plaidAccessToken?: string
  connectionProvider: InstitutionConnectionProvider
  tellerEnrollmentId?: string
  onSuccess?: () => void
  onError?: (error: ErrorWithContent) => void
}
export function useInstitutionCreateLink (
  {
    selectedFranchiseGroupId,
    plaidAccessToken,
    connectionProvider,
    tellerEnrollmentId,
    onSuccess,
    onError
  }: UseInstitutionCreateLinkProps
): UseInstitutionCreateLinkStates {
  const [error, setError] = useState<Error>()
  const [linkToken, setLinkToken] = useState<string | null>(null)

  // Log out Events
  const [logConnectionEvent] =
useMutation<LogInstitutionLinkEvent, LogInstitutionLinkEventVariables>(
  LOG_INSTITUTION_CONNECTION_EVENT
)

  // Re Link Mutation
  const [completeInstitutionReLink, { loading: isReLinkMutationLoading }] =
    useMutation<CompleteInstitutionReLink, CompleteInstitutionReLinkVariables>(
      COMPLETE_INSTITUTION_RELINK, {
        onError: handleApolloError,
        onCompleted: onSuccess
      }
    )

  // Link Mutation
  const [linkInstitution, { loading: isLinkMutationLoading }] =
   useMutation<LinkInstitution, LinkInstitutionVariables>(
     LINK_INSTITUTION, {
       onError: handleApolloError,
       onCompleted: onSuccess
     }
   )

  // Account Link Configuration: Pass a plaidAccessToken to initiate re-link flow
  const [getPlaidLinkToken, { loading: isGetPlaidLinkTokenMutationLoading }] =
    useLazyQuery<GetAccountLinkConfiguration, GetAccountLinkConfigurationVariables>(
      GET_ACCOUNT_LINK_CONFIGURATION, {
        fetchPolicy: 'network-only',
        variables: { externalToken: plaidAccessToken },
        onError: handleApolloError,
        onCompleted: (data) => {
          setLinkToken(data?.accountLinkConfiguration?.plaidLinkToken ?? null)
        }
      })

  // TELLER
  const tellerReLinkOnSuccess = useCallback<TellerConnectOnSuccess>(
    async (authorization: TellerConnectEnrollment) => {
      await completeInstitutionReLink({
        variables: {
          connectionProvider: InstitutionConnectionProvider.TELLER,
          tellerReLinkArgs: {
            tellerEnrollmentId: authorization.enrollment.id
          }
        }
      })
    }, [])

  const tellerLinkOnSuccess = useCallback<TellerConnectOnSuccess>(
    async (authorization: TellerConnectEnrollment) => {
      const franchiseGroupId = Number(selectedFranchiseGroupId)
      await linkInstitution({
        variables: {
          franchiseGroupId,
          connectionProvider: InstitutionConnectionProvider.TELLER,
          tellerLinkInput: {
            tellerAccessToken: authorization.accessToken,
            enrollmentId: authorization.enrollment.id,
            institutionName: authorization.enrollment.institution.name
          }
        }
      })
    }, [])

  // PLAID
  const plaidReLinkOnSuccess = useCallback<PlaidLinkOnSuccess>(
    async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      const linkSessionMetadata = formatOnSuccessMetadata(metadata)
      await completeInstitutionReLink({
        variables: {
          connectionProvider: InstitutionConnectionProvider.PLAID,
          plaidReLinkArgs: {
            plaidPublicToken: publicToken,
            linkSessionMetadata
          }
        }
      })
    }, [])

  const plaidLinkOnSuccess = useCallback<PlaidLinkOnSuccess>(
    async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      const linkSessionMetadata = formatOnSuccessMetadata(metadata)
      const franchiseGroupId = Number(selectedFranchiseGroupId)
      await linkInstitution({
        variables: {
          franchiseGroupId,
          connectionProvider: InstitutionConnectionProvider.PLAID,
          plaidLinkInput: {
            plaidPublicToken: publicToken,
            linkSessionMetadata
          }
        }
      })
    }, [])

  // Transform Apollo states into ErrorWithContent State
  function handleApolloError (error: ApolloError): void {
    logError(error)
    setError(error)
    if (onError != null && error != null) {
      onError({ ...getErrorContent(error), error })
    }
  }

  function logError (errorObject: object): void {
    void logConnectionEvent({
      variables: {
        connectionEventJson: JSON.stringify(errorObject),
        isConnectionEventError: true,
        connectionProvider
      }
    })
  }

  // TODO Consider how to make more consistent event logging
  function logEvent (eventObject: object): void {
    void logConnectionEvent({
      variables: {
        connectionEventJson: JSON.stringify(eventObject),
        isConnectionEventError: false,
        connectionProvider
      }
    })
  }

  switch (connectionProvider) {
    case InstitutionConnectionProvider.PLAID:{
      const isUpdateMode = plaidAccessToken != null
      return {
        initialize: () => { void getPlaidLinkToken() },
        isUpdateMode,
        isInitializationMutationLoading: isGetPlaidLinkTokenMutationLoading,
        onSuccessfulPlaidAccountLogin: isUpdateMode ? plaidReLinkOnSuccess : plaidLinkOnSuccess,
        onSuccessfulTellerAccountLogin: () => {},
        isLinkMutationLoading: isReLinkMutationLoading || isLinkMutationLoading,
        linkToken,
        error,
        logError,
        logEvent
      }
    }
    case InstitutionConnectionProvider.TELLER:{
      const isUpdateMode = tellerEnrollmentId != null
      return {
        initialize: () => {},
        isUpdateMode,
        isInitializationMutationLoading: false,
        onSuccessfulPlaidAccountLogin: () => {},
        onSuccessfulTellerAccountLogin: isUpdateMode ? tellerReLinkOnSuccess : tellerLinkOnSuccess,
        isLinkMutationLoading: isReLinkMutationLoading || isLinkMutationLoading,
        linkToken,
        error,
        logError,
        logEvent
      }
    }
    case InstitutionConnectionProvider.TREASURY_PRIME:
    case InstitutionConnectionProvider.ALTIR:{
      throw Error('TODO: implement')
    }
  }
}

// TODO PAL: Improve Error Messaging & Unify with Teller
function getErrorContent (error: ApolloError): ErrorContent {
  const errorCode = getErrorCode(error)
  switch (errorCode) {
    case GraphQLErrorCode.AUTHORIZATION_ERROR:
      return {
        title: "You don't have permission to link this account.",
        subtitle: 'Reach out to the account administrator or contact us for help.'
      }
    case GraphQLErrorCode.DUPLICATE_ACCOUNT:
      return {
        title: 'Institution Already Linked',
        subtitle: 'You have already linked this institution'
      }
    default:
      return {
        title: 'Something went wrong while linking your account',
        subtitle: 'Please try again later. If the issue persists, contact us.'
      }
  }
}
