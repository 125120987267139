import React, { useState, type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import ErrorInline from '@/library/errors/ErrorInline'
import Button from '@/library/button/Button'
import { getExpectedTransferVolumeDescription } from '@/utils/amplifyApplicationUtils'
import RadioSelect from '@/library/form/radio/RadioSelect'
import CheckBoxSelectGroup, { type SelectItem } from '@/library/form/checkbox/CheckBoxSelectGroup'
import { getSelectedEntries } from '@/library/form/checkbox/utils'
import { AmplifyAccountIntendedFunction } from '@/graphql/__generated__/globalTypes'

interface AccountUsageFormComponentProps {
  onSubmit: (
    sourcesOfFunds: string[],
    expectedContinuousSourcesOfFunds: string[],
    expectedTransferVolume: string,
    expectedAccountFunction: AmplifyAccountIntendedFunction,
    isExpectsToMakeInternationalTransfer: boolean
  ) => void
  isLoading: boolean
  error?: Error
}

export enum ExpectedTransferVolume {
  ZERO_TO_TEN,
  TEN_TO_TWENTY_FIVE,
  MORE_THAN_TWENTY_FIVE
}

const TRANSFER_OF_FUNDS_FROM_EXISTING_ACCOUNTS = 'Transfer of funds from existing business accounts'
const DIRECT_DEPOSIT_FROM_BUSINESS_REVENUE = 'Direct deposit from business revenue'

export default function AccountUsageFormComponent ({
  onSubmit, isLoading, error
}: AccountUsageFormComponentProps): ReactElement {
  const [expectedInitialSourcesOfFunds, setExpectedInitialSourcesOfFunds] = useState<Record<string, SelectItem>>({
    transfer: { description: TRANSFER_OF_FUNDS_FROM_EXISTING_ACCOUNTS, isSelected: false },
    deposit: { description: DIRECT_DEPOSIT_FROM_BUSINESS_REVENUE, isSelected: false }
  })
  const [
    expectedContinuousSourcesOfFunds,
    setExpectedContinuousSourcesOfFunds
  ] = useState<Record<string, SelectItem>>({
    revenue: { description: 'Revenue', isSelected: false },
    netIncome: { description: 'Net Income', isSelected: false },
    idleCash: { description: 'Idle Cash', isSelected: false }
  })
  const [expectedVolume, setExpectedVolume] = useState<ExpectedTransferVolume | null>(null)
  const [expectedAccountFunction, setExpectedAccountFunction] = useState<AmplifyAccountIntendedFunction | null>(null)
  const [
    isExpectsToMakeInternationalTransfer,
    setIsExpectsToMakeInternationalTransfer
  ] = useState<boolean | null>(null)

  const [internalFormError, setInternalFormError] = useState<Error | undefined>(undefined)

  function handleSubmit (): void {
    const initialSourcesOfFunds = getSelectedEntries(expectedInitialSourcesOfFunds)
    const continuousSourcesOfFunds = getSelectedEntries(expectedContinuousSourcesOfFunds)

    if (expectedAccountFunction == null) {
      setInternalFormError(Error('Please select your expected account function'))
      return
    }
    if (isExpectsToMakeInternationalTransfer == null) {
      setInternalFormError(Error('Please select whether you expect to transact internationally'))
      return
    }
    if (
      expectedVolume == null
    ) {
      setInternalFormError(Error('Please select your expected volume'))
      return
    }

    const expectedTransferVolumeDescription = getExpectedTransferVolumeDescription(expectedVolume)

    onSubmit(
      initialSourcesOfFunds,
      continuousSourcesOfFunds,
      expectedTransferVolumeDescription,
      expectedAccountFunction,
      isExpectsToMakeInternationalTransfer
    )
  }

  const isFormValid = expectedVolume != null &&
    getSelectedEntries(expectedInitialSourcesOfFunds).length > 0 &&
    getSelectedEntries(expectedContinuousSourcesOfFunds).length > 0 &&
    expectedAccountFunction != null &&
    isExpectsToMakeInternationalTransfer != null

  return (
    <Flex flexDir='column' gap={10}>
      <Flex flexDir='column' gap={6}>
        <Flex flexDir='column' gap={4}>
          <Text>How are you going to use this Account?</Text>
          <Flex flexDir='column' gap={2}>
            <RadioSelect
              description='Operating Account'
              selectedValue={expectedAccountFunction}
              valueOnSelection={AmplifyAccountIntendedFunction.OPERATING_ACCOUNT}
              handleSelection={value => { setExpectedAccountFunction(value) }}
            />
            <RadioSelect
              description='Business Savings Account'
              selectedValue={expectedAccountFunction}
              valueOnSelection={AmplifyAccountIntendedFunction.SAVINGS_ACCOUNT}
              handleSelection={value => { setExpectedAccountFunction(value) }}
            />
            <RadioSelect
              description='Payroll Account'
              selectedValue={expectedAccountFunction}
              valueOnSelection={AmplifyAccountIntendedFunction.PAYROLL_ACCOUNT}
              handleSelection={value => { setExpectedAccountFunction(value) }}
            />
            <RadioSelect
              description='Other'
              selectedValue={expectedAccountFunction}
              valueOnSelection={AmplifyAccountIntendedFunction.OTHER}
              handleSelection={value => { setExpectedAccountFunction(value) }}
            />
          </Flex>
        </Flex>
        <Flex flexDir='column' gap={4}>
          <Text>How are you going to initially fund this account? (Select all that apply)</Text>
          <CheckBoxSelectGroup
            options={expectedInitialSourcesOfFunds}
            setOptions={(value) => { setExpectedInitialSourcesOfFunds(value) }}
          />
        </Flex>
        <Flex flexDir='column' gap={4}>
          <Text>How do you expect to continuously fund this account? (Select all that apply)</Text>
          <CheckBoxSelectGroup
            options={expectedContinuousSourcesOfFunds}
            setOptions={(value) => { setExpectedContinuousSourcesOfFunds(value) }}
          />
        </Flex>
        <Flex flexDir='column' gap={4}>
          <Text>How many transfers in and out of this account do you anticipate making per month?</Text>
          <Flex flexDir='column' gap={2}>
            <RadioSelect
              description='0-10'
              selectedValue={expectedVolume}
              valueOnSelection={ExpectedTransferVolume.ZERO_TO_TEN}
              handleSelection={value => { setExpectedVolume(value) }}
            />
            <RadioSelect
              description='10-25'
              selectedValue={expectedVolume}
              valueOnSelection={ExpectedTransferVolume.TEN_TO_TWENTY_FIVE}
              handleSelection={value => { setExpectedVolume(value) }}
            />
            <RadioSelect
              description='More than 25'
              selectedValue={expectedVolume}
              valueOnSelection={ExpectedTransferVolume.MORE_THAN_TWENTY_FIVE}
              handleSelection={value => { setExpectedVolume(value) }}
            />
          </Flex>
        </Flex>
        <Flex flexDir='column' gap={4}>
          <Text>Do you intend to send or receive international transactions?</Text>
          <Flex flexDir='column' gap={2}>
            <RadioSelect
              description='Yes'
              selectedValue={isExpectsToMakeInternationalTransfer}
              valueOnSelection={true}
              handleSelection={value => { setIsExpectsToMakeInternationalTransfer(value) }}
            />
            <RadioSelect
              description='No'
              selectedValue={isExpectsToMakeInternationalTransfer}
              valueOnSelection={false}
              handleSelection={value => { setIsExpectsToMakeInternationalTransfer(value) }}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDir='column' gap={4}>
        <Button
          text='Save & Continue'
          onClick={handleSubmit}
          isLoading={isLoading}
          isDisabled={!isFormValid}
          onClickEventType={Event.ACCOUNT_USAGE_SUBMISSION_CLICK}
        />
        <ErrorInline error={error ?? internalFormError}/>
      </Flex>
    </Flex>
  )
}
