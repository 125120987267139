import React, { type ReactNode, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { BorderRadius, Color } from '@/theme/theme'
import { ERROR_BORDER } from '@/utils/errorUtils'

interface DefaultCardProps {
  children: ReactNode
  padding?: number
  isError?: boolean
}

export default function DefaultCard ({ children, padding = 6, isError = false }: DefaultCardProps): ReactElement {
  return (
    <Flex
      borderRadius={BorderRadius.CARD}
      bg={Color.WHITE}
      p={padding}
      w='100%'
      border={isError ? ERROR_BORDER : undefined}
    >
      {children}
    </Flex>
  )
}
