import React, { type ReactElement } from 'react'
import { Center, Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import TransferComponent from './TransferComponent'
import RecipientsCard from './settings/recipients/RecipientsCard'
import AmplifyInfoComponent from './amplify_info/AmplifyInfoComponent'
import CounterpartiesPendingVerificationCard from './settings/recipients/CounterpartiesPendingVerificationCard'
import CreatedAutomatedTransferButton from './settings/CreateAutomatedTransferButton'
import ViewAutomatedTransferRulesCard from './settings/ViewAutomatedTransferRulesCard'
import RecentTransfersComponent from './transfer_history/RecentTransfersComponent'
import PasscodeCreationModal from '@/library/modal/passcode/PasscodeCreationModal'
import {
  type GetCounterpartiesPendingVerification,
  type GetCounterpartiesPendingVerificationVariables
} from '@/graphql/__generated__/GetCounterpartiesPendingVerification'
import { GET_COUNTERPARTIES_PENDING_VERIFICATION } from '@/graphql/queries/GetRecipientsPageData'
import { type CheckEnablementStatus, CounterpartyType } from '@/graphql/__generated__/globalTypes'
import BusinessSelectorComponent from '@/library/state/BusinessSelectorComponent'
import { ContainerWidth } from '@/theme/theme'
import { type FinancialAccountExtendedFragment } from '@/graphql/__generated__/FinancialAccountExtendedFragment'
import GrasshopperMigrationDisabledTransferCard from
  '@/components/grasshopper_migration/GrasshopperMigrationDisabledTransferCard'

interface TransferContainerComponentProps {
  franchiseGroupId: number
  amplifyAccount?: FinancialAccountExtendedFragment
  counterparties?: FinancialAccountExtendedFragment[]
  doesUserHavePasscode?: boolean
  isRecurringVendorPaymentsEnabled: boolean
  isCheckIssuanceEnabled: boolean
  organizationCheckEnablementStatus?: CheckEnablementStatus
  isBankMigrationInProgress: boolean
  refetch: () => void
}

export default function TransferContainerComponent ({
  franchiseGroupId,
  amplifyAccount,
  counterparties,
  doesUserHavePasscode,
  isRecurringVendorPaymentsEnabled,
  isCheckIssuanceEnabled,
  organizationCheckEnablementStatus,
  isBankMigrationInProgress,
  refetch
}: TransferContainerComponentProps): ReactElement {
  const {
    data: unverifiedCounterpartyData,
    loading: unverifiedCounterpartyLoading,
    error: unverifiedCounterpartyError,
    refetch: refetchUnverifiedCounterpartyData
  } = useQuery<GetCounterpartiesPendingVerification, GetCounterpartiesPendingVerificationVariables>(
    GET_COUNTERPARTIES_PENDING_VERIFICATION,
    {
      variables: {
        franchiseGroupId,
        isOwnershipVerified: false,
        counterpartyTypes: [CounterpartyType.EXTERNAL_PERSONAL, CounterpartyType.PLAID]
      },
      fetchPolicy: 'network-only'
    }
  )

  function refetchAllData (): void {
    refetch()
    void refetchUnverifiedCounterpartyData()
  }

  return (
    <Center w='100%'>
      <Flex gap={6} w='100%' maxW={ContainerWidth.FULL_PAGE}>
        <Flex w='100%' flexDir='column' gap='10'>
          {doesUserHavePasscode === false && <PasscodeCreationModal/>}
          {isBankMigrationInProgress
            ? <GrasshopperMigrationDisabledTransferCard/>
            : <TransferComponent
                franchiseGroupId={franchiseGroupId}
                amplifyAccount={amplifyAccount}
                counterparties={counterparties}
                isRecurringVendorPaymentsEnabled={isRecurringVendorPaymentsEnabled}
                isCheckIssuanceEnabled={isCheckIssuanceEnabled}
                organizationCheckEnablementStatus={organizationCheckEnablementStatus}
                refetch={refetchAllData}
              />}
          <RecentTransfersComponent franchiseGroupId={franchiseGroupId}/>
        </Flex>
        <Flex flexDir='column' gap={6}>
          <BusinessSelectorComponent
            businessFilterParams={{ hasAmplify: true }}
            requireMultipleBusinesses={true}
          />
          <Flex flexDir='column' gap={3}>
            <Text>Transfer Settings</Text>
            <ViewAutomatedTransferRulesCard/>
            <AmplifyInfoComponent/>
            <RecipientsCard franchiseGroupId={franchiseGroupId}/>
            <CounterpartiesPendingVerificationCard
              data={unverifiedCounterpartyData}
              loading={unverifiedCounterpartyLoading}
              error={unverifiedCounterpartyError}
            />
            <CreatedAutomatedTransferButton/>
          </Flex>
        </Flex>
      </Flex>
    </Center>
  )
}
