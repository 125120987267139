import { gql } from '@apollo/client'
import { AMPLIFY_ACCOUNT_OWNERSHIP_INFO_FRAGMENT } from '../fragments/AmplifyAccountOwnershipInfoFragment'
import { FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT } from '../fragments/financial_accounts/FinancialAccountExtendedFragment'

export const GET_TRANSFER_PAGE_DATA = gql`
    ${FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT}
    query GetTransferPageData($franchiseGroupId: Int!, $organizationId: String!) {
        counterparties(franchiseGroupId: $franchiseGroupId) {
            ...FinancialAccountExtendedFragment
        }
        amplifyAccount(franchiseGroupId: $franchiseGroupId) {
            ...FinancialAccountExtendedFragment
        }
        currentUser {
            hasPasscode
            selectedOrganization(organizationId: $organizationId) {
                id
                checkEnablementStatus
                selectedFranchiseGroup(franchiseGroupId: $franchiseGroupId) {
                    bankMigrationState {
                        status
                    }
                }
            }
            rolloutConfiguration {
                enableRecurringVendorPayments
                enableCheckIssuance
            }
        }
    }
`

export const GET_AMPLIFY_ACCOUNT_INFO = gql`
    ${AMPLIFY_ACCOUNT_OWNERSHIP_INFO_FRAGMENT}
    query GetAmplifyAccountInfo($franchiseGroupId: Int!) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                amplifyAccount {
                    accountSubTypeFormatted
                    achAccountNumber
                    achRoutingNumber
                    institution {
                        name
                    }
                    amplifyAccount {
                        ...AmplifyAccountOwnershipInfoFragment
                    }
                }
            }
        }
    }
`
