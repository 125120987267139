import React, { useState, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import AddNewBusinessModal from './AddNewBusinessModal'
import { AvatarComponent } from '@/library/utility/AvatarComponent'
import { safeGetFirstLetter } from '@/utils/stringUtils'
import BusinessSelectorComponent from '@/library/state/BusinessSelectorComponent'

export interface FranchiseGroupSelection {
  id?: string | null
  name?: string | null
}

interface BusinessSelectorComponentProps {
  selectedFranchiseGroup?: FranchiseGroupSelection
}

export default function BusinessInfoComponent ({
  selectedFranchiseGroup
}: BusinessSelectorComponentProps): ReactElement {
  const [isAddNewBusinessModalOpen, setIsAddNewBusinessModalOpen] = useState(false)

  return (
    <Flex flexDir='column' gap={4} justifyContent='center' alignItems='center' w='100%'>
      <AvatarComponent text={safeGetFirstLetter(selectedFranchiseGroup?.name)}/>
      <BusinessSelectorComponent />
      <AddNewBusinessModal
        isOpen={isAddNewBusinessModalOpen}
        onClose={() => { setIsAddNewBusinessModalOpen(false) }}
      />
    </Flex>
  )
}
