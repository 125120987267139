import { gql } from '@apollo/client'

export const ADDRESS_FRAGMENT = gql`
    fragment AddressFragment on Address {
        streetAddressLine1
        streetAddressLine2
        city
        state
        postalCode
        country
    }
`
