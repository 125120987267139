import React, { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import OnboardingPage from '@/library/page/OnboardingPage'
// eslint-disable-next-line max-len
import OwnershipAttestationComponent from '@/components/pages/amplify_account_application/routes/apply/components/ownership_attestation/OwnershipAttestationComponent'
import { useAmplifyAppNavigation } from '@/hooks/onboarding/useAmplifyAppNavigation'
import { ContainerWidth } from '@/theme/theme'

export default function AmplifyApplyPersonConfirmationPage (): ReactElement {
  const { BUSINESS_ID, AMPLIFY_APPLICATION_ID } = useParams()
  const { skipCallToAction, backLink } = useAmplifyAppNavigation(String(BUSINESS_ID))

  return (
    <OnboardingPage
      maxWidth={ContainerWidth.LARGE}
      callToAction={skipCallToAction}
      amplifyApplicationContext={{ franchiseGroupId: Number(BUSINESS_ID) }}
    >
      <OwnershipAttestationComponent
        applicationId={String(AMPLIFY_APPLICATION_ID)}
        franchiseGroupId={Number(BUSINESS_ID)}
        backLink={backLink}
        context={AmplifyApplicationContext.DEFAULT}
      />
    </OnboardingPage>
  )
}
