import React, { type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import { type CheckRecipientDetailsFormData } from '../../check_details/CheckRecipientDetailsForm'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import EditIcon from '@/library/icons/EditIcon'
import { BorderRadius, Color } from '@/theme/theme'
import StackedInfoAttribute from '@/library/utility/StackedInfoAttribute'
import { getAddressLines } from '@/utils/addressUtils'

interface CheckRecipientDetailsCardProps {
  // TODO (PJ): enforce not null once we fetch data
  existingRecipientDetails: CheckRecipientDetailsFormData | null
  onEdit: () => void
}

export default function CheckRecipientDetailsCard ({
  existingRecipientDetails,
  onEdit
}: CheckRecipientDetailsCardProps): ReactElement {
  // TODO (PJ): Populate with real data
  return (
    <Center
      w='100%'
      justifyContent='space-between'
      alignItems='start'
      p={6}
      bg={Color.WHITE}
      borderRadius={BorderRadius.CARD}
    >
      <Center flexDir='column' w='100%' gap={6}>
        <StackedInfoAttribute header='Name' subtext={existingRecipientDetails?.name}/>
        {
            existingRecipientDetails?.address != null &&
            <StackedInfoAttribute
              header='Mailing Address'
              multiLineSubtext={getAddressLines(existingRecipientDetails.address)}
            />
        }
      </Center>
      <Button
        text='Edit'
        beforeIcon={<EditIcon/>}
        variant={ButtonVariant.WHITE_OUTLINE}
        size={ButtonSize.SMALL}
        width='auto'
        onClick={onEdit}
      />
    </Center>
  )
}
