import React, { type ReactElement } from 'react'
import FinancialLineGraph from '@/library/graphs/FinancialLineGraph'
import { type FinancialDataPoint } from '@/types/types'
import ResponsiveD3Graph from '@/library/graphs/ResponsiveD3Graph'

interface InterestGraphProps {
  data: FinancialDataPoint[]
  onValueChange: (amount?: number, date?: string) => void
}

export default function InterestGraph (
  {
    data,
    onValueChange
  }: InterestGraphProps
): ReactElement {
  function handleValueChange (value?: FinancialDataPoint): void {
    onValueChange(value?.amount, value?.date)
  }

  return (
    <ResponsiveD3Graph>
      <FinancialLineGraph
        data={data}
        onValueChange={handleValueChange}
        isCompact
      />
    </ResponsiveD3Graph>
  )
}
