import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import InfoBoxComponent from '../InfoBoxComponent'
import AccountInfoRowComponent, { AccountInfoRowType } from '../AccountInfoRowComponent'
import {
  type GetAmplifyOwnerData_currentUser_franchiseGroup_amplifyAccount_amplifyAccount_people as Person
} from '@/graphql/__generated__/GetAmplifyOwnerData'
import { AccountInfoUpdateField } from '@/graphql/__generated__/globalTypes'

export interface AmplifyAccountPersonComponentProps {
  person: Person
  ownerNumber?: number
}

export default function AmplifyAccountPersonComponent (
  { person, ownerNumber }: AmplifyAccountPersonComponentProps
): ReactElement {
  const label = ownerNumber != null ? `About Owner #${ownerNumber}` : 'About the Owner'
  return (
    <InfoBoxComponent label={label}>
      <Flex flexDir='column' gap={5}>
        <Flex justifyContent='space-between' textAlign='start'>
          <AccountInfoRowComponent
            label='First Name'
            valueLine1={person.firstName}
            accountInfoRowType={AccountInfoRowType.PERSON_WITH_KYC}
            objectId={person.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.PERSON_FIRST_NAME}
          />
          <AccountInfoRowComponent
            label='Middle Name'
            valueLine1={person.middleName ?? ''}
            accountInfoRowType={AccountInfoRowType.PERSON_WITH_KYC}
            objectId={person.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.PERSON_MIDDLE_NAME}
          />
          <AccountInfoRowComponent
            label='Last Name'
            valueLine1={person.lastName}
            accountInfoRowType={AccountInfoRowType.PERSON_WITH_KYC}
            objectId={person.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.PERSON_LAST_NAME}
          />
        </Flex>
        <Flex justifyContent='space-between' textAlign='start'>
          <AccountInfoRowComponent
            label='Ownership Percentage'
            valueLine1={person.ownershipPercentage}
            accountInfoRowType={AccountInfoRowType.PERSON_NO_KYC}
            objectId={person.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.PERSON_OWNERSHIP_PERCENTAGE}
          />
          <AccountInfoRowComponent
            label='Title'
            valueLine1={person.title}
            accountInfoRowType={AccountInfoRowType.PERSON_NO_KYC}
            objectId={person.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.PERSON_BUSINESS_TITLE}
          />
          <AccountInfoRowComponent
            label='Occupation'
            valueLine1={person.occupation}
            accountInfoRowType={AccountInfoRowType.PERSON_NO_KYC}
            objectId={person.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.PERSON_OCCUPATION}
          />
        </Flex>
        <Flex justifyContent='space-between' textAlign='start'>
          <AccountInfoRowComponent
            label='Email'
            valueLine1={person.email}
            accountInfoRowType={AccountInfoRowType.PERSON_NO_KYC}
            objectId={person.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.PERSON_EMAIL}
          />
          <AccountInfoRowComponent
            label='Phone Number'
            valueLine1={person.phoneNumber}
            accountInfoRowType={AccountInfoRowType.PERSON_NO_KYC}
            objectId={person.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.PERSON_PHONE_NUMBER}
          />
          <AccountInfoRowComponent
            label='Mailing Address'
            valueLine1={person?.mailingAddress?.streetAddressLine1 ?? undefined}
            valueLine2={person?.mailingAddress?.streetAddressLine2 ?? undefined}
            valueLine3={`${person?.mailingAddress?.city ?? ''}, ${person?.mailingAddress?.state ?? ''} ${person?.mailingAddress?.postalCode ?? ''}`}
            accountInfoRowType={AccountInfoRowType.PERSON_WITH_KYC}
            objectId={person.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.PERSON_MAILING_ADDRESS}
          />
        </Flex>
        <Flex justifyContent='space-between' textAlign='start'>
          <AccountInfoRowComponent
            label='Home Address'
            valueLine1={person?.physicalAddress?.streetAddressLine1 ?? undefined}
            valueLine2={person?.physicalAddress?.streetAddressLine2 ?? undefined}
            valueLine3={`${person?.physicalAddress?.city ?? ''}, ${person?.physicalAddress?.state ?? ''} ${person?.physicalAddress?.postalCode ?? ''}`}
            accountInfoRowType={AccountInfoRowType.PERSON_WITH_KYC}
            objectId={person.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.PERSON_PHYSICAL_ADDRESS}
          />
          <AccountInfoRowComponent
            label='Date of Birth'
            valueLine1={person.dateOfBirth}
            accountInfoRowType={AccountInfoRowType.PERSON_NO_KYC}
            objectId={person.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.PERSON_DATE_OF_BIRTH}
          />
          <AccountInfoRowComponent
            label='Country of Citizenship'
            valueLine1={person.citizenship}
            accountInfoRowType={AccountInfoRowType.PERSON_NO_KYC}
            objectId={person.treasuryPrimeId}
            fieldType={AccountInfoUpdateField.PERSON_CITIZENSHIP}
          />
        </Flex>
      </Flex>
    </InfoBoxComponent>
  )
}
