/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function TransferIconFilled ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg viewBox='0 0 25 24'>
        <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M16.0408 3.05874C15.6769 2.64337 15.0451 2.6017 14.6297 2.96568C14.2143 3.32966 14.1726 3.96145 14.5366 4.37683L18.163 8.51528H4.63281C4.08053 8.51528 3.63281 8.963 3.63281 9.51528C3.63281 10.0676 4.08053 10.5153 4.63281 10.5153H20.3689C20.7614 10.5153 21.1176 10.2857 21.2797 9.92817C21.4417 9.57069 21.3797 9.15145 21.121 8.85624L16.0408 3.05874ZM8.96094 20.9411C9.32492 21.3565 9.95671 21.3981 10.3721 21.0342C10.7875 20.6702 10.8291 20.0384 10.4651 19.623L6.83877 15.4846L20.369 15.4846C20.9212 15.4846 21.369 15.0369 21.369 14.4846C21.369 13.9323 20.9212 13.4846 20.369 13.4846L4.6329 13.4846C4.24039 13.4846 3.88418 13.7142 3.72212 14.0717C3.56006 14.4292 3.62211 14.8484 3.88079 15.1436L8.96094 20.9411Z'/>
        <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M15.5408 3.05874C15.1769 2.64337 14.5451 2.6017 14.1297 2.96568C13.7143 3.32966 13.6726 3.96145 14.0366 4.37683L17.663 8.51528H4.13281C3.58053 8.51528 3.13281 8.963 3.13281 9.51528C3.13281 10.0676 3.58053 10.5153 4.13281 10.5153H19.8689C20.2614 10.5153 20.6176 10.2857 20.7797 9.92817C20.9417 9.57069 20.8797 9.15145 20.621 8.85624L15.5408 3.05874ZM8.46094 20.9411C8.82492 21.3565 9.45671 21.3981 9.87209 21.0342C10.2875 20.6702 10.3291 20.0384 9.96515 19.623L6.33877 15.4846L19.869 15.4846C20.4212 15.4846 20.869 15.0369 20.869 14.4846C20.869 13.9323 20.4212 13.4846 19.869 13.4846L4.1329 13.4846C3.74039 13.4846 3.38418 13.7142 3.22212 14.0717C3.06006 14.4292 3.12211 14.8484 3.38079 15.1436L8.46094 20.9411Z'/>
        </svg>
      </svg>
    </IconContainer>
  )
}
