import React, { useCallback, useState, type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import HistoricalAccountBalanceGraph from './HistoricalAccountBalanceGraph'
import { BorderRadius, Color, FormInputHeight } from '@/theme/theme'
import SelectorComponent from '@/library/form/select/SelectorComponent'
import { DateWindowSelectionOptions } from '@/types/types'
import { EMPTY_BALANCE_VALUE } from '@/utils/constants'
import { AmplifyAccountStatus } from '@/graphql/__generated__/globalTypes'

interface HistoricalAccountBalanceComponentProps {
  accountId: number
  accountStatus: AmplifyAccountStatus | null
}

export default function HistoricalAccountBalanceComponent (
  {
    accountId,
    accountStatus
  }: HistoricalAccountBalanceComponentProps
): ReactElement {
  const [accountBalanceFormatted, setAccountBalanceFormatted] = useState(EMPTY_BALANCE_VALUE)
  const [selectedDate, setSelectedDate] = useState<string | undefined>()
  const [dateWindowSelection, setDateWindowSelection] = useState(DateWindowSelectionOptions.MONTH)

  function onSelectedAccountBalanceChange (balance: string, date?: string): void {
    setAccountBalanceFormatted(balance)
    setSelectedDate(date)
  }
  // This prevents the child component from re-rendering every time it updates the account balance
  const memoizedOnChange = useCallback(onSelectedAccountBalanceChange, [])

  const isAccountClosed = accountStatus === AmplifyAccountStatus.CLOSED

  return (
    <Flex flexDir='column' gap={3} overflow='hidden'>
      <Text>Account Balance</Text>
      <Flex bg={Color.WHITE} borderRadius={BorderRadius.CARD} flexDir='column' p={6} overflow='hidden' maxW='100%'>
        <Flex w='100%' justifyContent='space-between'>
          <Flex flexDir='column' gap={1}>
            <Heading size='sm' color={Color.DARK_GREY} minH={5}>{selectedDate}</Heading>
            <Heading
              size='lg'
              color={isAccountClosed ? Color.DARK_GREY : Color.DARK_BLUE}
            >
              {accountBalanceFormatted}
            </Heading>
          </Flex>
          <Flex>
            <SelectorComponent
              hasBorder
              height={FormInputHeight.SMALL}
              options={[
                DateWindowSelectionOptions.WEEK,
                DateWindowSelectionOptions.MONTH,
                DateWindowSelectionOptions.THREE_MONTH,
                DateWindowSelectionOptions.YEAR_TO_DATE,
                DateWindowSelectionOptions.YEAR,
                DateWindowSelectionOptions.ALL
              ]}
              value={dateWindowSelection}
              handleSelection={setDateWindowSelection}
            />
          </Flex>
        </Flex>
        <HistoricalAccountBalanceGraph
          accountId={accountId}
          selectedDateWindow={dateWindowSelection}
          onSelectedAccountBalanceChange={memoizedOnChange}
        />
      </Flex>
    </Flex>
  )
}
