import { gql } from '@apollo/client'

export const GET_AMPLIFY_APPLICATION_COMPLETION_PERCENTAGE = gql`
    query GetAmplifyApplicationCompletionPercentage($franchiseGroupId: Int!) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                amplifyAccountApplication {
                    completionPercentage
                }
            }
        }
    }
`
