import { Flex } from '@chakra-ui/react'
import React, { useState, type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import MonthlyCashBalanceContainer from './MonthlyCashBalanceContainer'
import MonthlyCashChangeGraphContainer from './monthlyCashChange/MonthlyCashChangeGraphContainer'
import MonthlyCashChangeInfoCard from './monthlyCashChange/MonthlyCashChangeInfoCard'
import DashboardSection from './layout/DashboardSection'
import { type DateSelectionWindow } from '@/types/types'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import SelectorComponent, { type SelectorComponentProps } from '@/library/form/select/SelectorComponent'
import CalendarIcon from '@/library/icons/CalendarIcon'
import StockMarketIcon from '@/library/icons/StockMarketIcon'
import { GET_MONTHLY_METRICS } from '@/graphql/queries/GetInsightsData'
import { getCurrencyChangeFormatted } from '@/utils/stringUtils'
import { nonNull } from '@/utils/arrayUtils'
import {
  DateTimeTemplate,
  getFormattedDateString,
  parseDate,
  TimeZone
} from '@/utils/dateUtils'
import {
  type GetMonthlyMetricsVariables,
  type GetMonthlyMetrics,
  type GetMonthlyMetrics_currentUser_franchiseGroup_historicalMonthlyAccountBalanceChange as BalanceChange
} from '@/graphql/__generated__/GetMonthlyMetrics'
import { FormInputHeight, Color } from '@/theme/theme'

interface MonthlyInsightsSectionProps {
  franchiseGroupId: number
}

export default function MonthlyInsightsSection ({ franchiseGroupId }: MonthlyInsightsSectionProps): ReactElement {
  const [months, setMonths] = useState<DateSelectionWindow[]>([])
  const [selectedDateRange, setSelectedDateRange] = useState<DateSelectionWindow>(
    { startDate: null, endDate: null }
  )
  const { data, loading, error } = useQuery<GetMonthlyMetrics, GetMonthlyMetricsVariables>(GET_MONTHLY_METRICS, {
    variables: { franchiseGroupId },
    onCompleted: (data) => {
      const balanceChanges = data.currentUser?.franchiseGroup?.historicalMonthlyAccountBalanceChange ?? []
      const mostRecentBalanceChange = balanceChanges[balanceChanges.length - 1]
      const months = nonNull(
        balanceChanges
          .map(m => {
            return { startDate: parseDate(m.startDate), endDate: parseDate(m.endDate) }
          })
          .reverse() // Reverse so that most recent months appear first in the selector
      )
      setSelectedDateRange({
        startDate: parseDate(mostRecentBalanceChange?.startDate),
        endDate: parseDate(mostRecentBalanceChange?.endDate)
      })
      setMonths(months)
    }
  })

  function handleMonthSelection (dateWindow: DateSelectionWindow): void {
    setSelectedDateRange(dateWindow)
  }

  const monthlyBalanceChangeData = data?.currentUser?.franchiseGroup?.historicalMonthlyAccountBalanceChange ?? []
  const bestMonthDescription = getBestMonthText(monthlyBalanceChangeData)
  const averageCashChangeDescription = getAverageCashChangeFormatted(monthlyBalanceChangeData)

  const selectorProps: SelectorComponentProps<DateSelectionWindow> = {
    backgroundColor: Color.WHITE,
    options: months,
    value: selectedDateRange,
    handleSelection: handleMonthSelection,
    height: FormInputHeight.SMALL,
    hasBorder: true,
    // Note we leverage UTC here because DerivedAccountBalance values have time=0
    // Longer term fix is for the backend to return these objects as just dates, not times
    formatOptions: (option) => getFormattedDateString(
      option?.startDate,
      DateTimeTemplate.MONTH_YEAR,
      TimeZone.DEFAULT_SYSTEM_TIME_ZONE
    ) ?? ''
  }
  const selectorComponent = (
    <Flex alignItems='center'>
      <SelectorComponent {...selectorProps}/>
    </Flex>
  )

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <DashboardSection
        title='Monthly Insights'
        secondaryTitleElement={selectorComponent}
      >
        <MonthlyCashBalanceContainer selectedTimeWindow={selectedDateRange} franchiseGroupId={franchiseGroupId}/>
        <Flex w='100%' gap={3}>
          <Flex flex={4}>
            <MonthlyCashChangeGraphContainer
              data={monthlyBalanceChangeData}
              loading={loading}
              error={error}
              selectedDateRange={selectedDateRange}
            />
          </Flex>
          <Flex flexDirection='column' flex={1} gap={3} justify='space-between'>
            <MonthlyCashChangeInfoCard
              title={bestMonthDescription ?? undefined}
              description='Your best month was'
              icon={<CalendarIcon color={Color.DARK_GREY}/>}
            />
            <MonthlyCashChangeInfoCard
              title={averageCashChangeDescription ?? undefined}
              description='Your average monthly cash change is'
              icon={<StockMarketIcon color={Color.DARK_GREY}/>}
            />
          </Flex>
        </Flex>
      </DashboardSection>
    </AltirSkeleton>
  )
}

function getBestMonthText (balanceChanges: BalanceChange[]): string | null {
  if (balanceChanges.length < 1) return null
  const bestMonth = balanceChanges.reduce((bestMonth, currentMonth) => {
    return (bestMonth?.netCashChange?.amount ?? 0) > (currentMonth.netCashChange?.amount ?? 0)
      ? bestMonth
      : currentMonth
  }, balanceChanges[0])
  return getFormattedDateString(bestMonth?.startDate, DateTimeTemplate.MONTH_YEAR, TimeZone.DEFAULT_SYSTEM_TIME_ZONE)
}

/**
 *
 * @param balanceChanges
 * @returns The average of a given list of monthly cash change values
 *
 * Note: this isn't the ~most~ informative metric as it leaves out changes between months
 * i.e. the change from 11/30 -> 12/1. Computing ((start of period) + (end of period)) / number of months
 * is probably what we want in the long run.
 *
 * This metric does, however, ~technically~ describe the average monthly change
 *
 */
function getAverageCashChangeFormatted (balanceChanges: BalanceChange[]): string | null {
  if (balanceChanges.length < 1) return null
  const averageChange = balanceChanges
    .map(b => b.netCashChange?.amount ?? 0)
    .reduce((acc, b) => b + acc, 0) / balanceChanges.length
  return getCurrencyChangeFormatted(averageChange)
}
