/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function RecurringIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g id='icon/24/recurring'>
          <path id='Vector' fillRule='evenodd' clipRule='evenodd' d='M18.8273 2.98535C19.3796 2.98535 19.8274 3.43307 19.8274 3.98535V7.39705C19.8274 7.94933 19.3796 8.39705 18.8273 8.39705H15.4157C14.8634 8.39705 14.4157 7.94933 14.4157 7.39705C14.4157 6.84476 14.8634 6.39705 15.4157 6.39705H16.3305C15.6153 5.8622 14.8004 5.4635 13.9258 5.22838C12.3682 4.80966 10.7143 4.93471 9.23741 5.58289C7.76048 6.23107 6.5488 7.3636 5.80248 8.79344C5.05616 10.2233 4.81984 11.8649 5.13254 13.4472C5.23962 13.989 4.8872 14.515 4.34539 14.6221C3.80359 14.7292 3.27757 14.3768 3.17049 13.835C2.76955 11.8062 3.07256 9.70131 4.02947 7.86799C4.98639 6.03468 6.53998 4.58258 8.43366 3.7515C10.3273 2.92042 12.4478 2.76007 14.445 3.29695C15.6903 3.63171 16.8411 4.22507 17.8273 5.0289V3.98535C17.8273 3.43307 18.2751 2.98535 18.8273 2.98535ZM6.34332 19.1162V20.1592C6.34332 20.7115 5.8956 21.1592 5.34332 21.1592C4.79103 21.1592 4.34332 20.7115 4.34332 20.1592L4.34332 16.7475C4.34332 16.1952 4.79103 15.7475 5.34332 15.7475H8.75501C9.3073 15.7475 9.75501 16.1952 9.75501 16.7475C9.75501 17.2998 9.3073 17.7475 8.75501 17.7475H7.83932C8.55483 18.2828 9.37022 18.6819 10.2454 18.9171C11.803 19.3359 13.4568 19.2108 14.9337 18.5626C16.4107 17.9144 17.6224 16.7819 18.3687 15.3521C19.115 13.9222 19.3513 12.2806 19.0386 10.6983C18.9315 10.1565 19.284 9.63048 19.8258 9.52341C20.3676 9.41633 20.8936 9.76875 21.0007 10.3106C21.4016 12.3393 21.0986 14.4442 20.1417 16.2775C19.1848 18.1108 17.6312 19.5629 15.7375 20.394C13.8438 21.2251 11.7233 21.3854 9.72619 20.8486C8.48069 20.5137 7.32964 19.9202 6.34332 19.1162Z' fill={color}/>
        </g>
      </svg>
    </IconContainer>
  )
}
