import { isEmpty, containsCharacters, fieldRequiredMessage, isValidRoutingNumber } from './stringUtils'
import { type AddressInput } from '../components/library/form/address/AddressForm'

interface InvalidFieldReason {
  isInvalid: boolean
  reason?: string
}

export function isInvalidDate (dob: string): boolean {
  if (isEmpty(dob)) return true
  if (!(/^\d{2}\/\d{2}\/\d{4}$/.test(dob))) return true
  return false
}

export function isInvalidPhoneNumber (phone: string): boolean {
  if (phone.length < 10 || phone.length > 15) return true
  if (containsCharacters(phone)) return true
  return false
}

export function isInvalidSSN (ssn: string | null | undefined): boolean {
  if (isEmpty(ssn)) return true
  if (ssn?.length !== 9) return true
  if (containsCharacters(ssn)) return true
  return false
}

export function isInvalidAccountNumber (accountNumber?: string): InvalidFieldReason {
  if (accountNumber == null || isEmpty(accountNumber)) {
    return { isInvalid: true, reason: fieldRequiredMessage('Account Number') }
  }

  if (accountNumber.length > 17) {
    return { isInvalid: true, reason: 'Account numbers must be 17 digits or fewer.' }
  }

  return { isInvalid: false }
}

export function isInvalidWireAccountNumber (accountNumber?: string): InvalidFieldReason {
  if (accountNumber == null || isEmpty(accountNumber)) {
    return { isInvalid: true, reason: fieldRequiredMessage('Wire Account Number') }
  }

  if (accountNumber.length > 34) {
    return { isInvalid: true, reason: 'Wire Account numbers must be 34 digits or fewer.' }
  }

  return { isInvalid: false }
}

export function isInvalidRoutingNumber (routingNumber?: string): InvalidFieldReason {
  if (routingNumber == null || isEmpty(routingNumber)) {
    return { isInvalid: true, reason: fieldRequiredMessage('Routing Number') }
  }

  if (!isValidRoutingNumber(routingNumber)) {
    return { isInvalid: true, reason: 'Please enter a valid routing number.' }
  }

  return { isInvalid: false }
}

export function isInvalidAddress (address: AddressInput): InvalidFieldReason {
  if (Object.keys(address).length === 0) return { isInvalid: true }
  if (isEmpty(address?.streetAddressLine1)) return { isInvalid: true }
  if (containsCharacters(address.postalCode ?? '')) return { isInvalid: true }
  if (address.city != null && address.city.length > 18) {
    return { isInvalid: true, reason: 'City must contain 18 characters or fewer' }
  }
  return { isInvalid: false }
}

export function isInvalidOwnershipPercent (ownership: number | null | undefined): boolean {
  if (ownership == null) return true
  if (ownership < 0 || ownership > 100) return true
  if (containsCharacters(String(ownership))) return true
  return false
}
