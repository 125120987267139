import React, { type ReactNode, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import DropDownCustomHeader from './DropDownCustomHeader'
import DropDownDefaultHeader from './DropDownDefaultHeader'
import { BorderRadius } from '@/theme/theme'

interface DropDownHeaderProps {
  // Header
  title?: string
  subtitle?: string
  rightDescription?: string
  icon?: ReactNode
  rightCallToActionComponent?: ReactNode

  // Custom Header
  customHeader?: ReactNode

  // State
  isSelected: boolean
  isDisabled: boolean
  isComplete: boolean
  hasNotification: boolean
  handleDropDownToggle: () => void

  // Styles
  headerColor: string
}

export default function DropDownHeader ({
  title,
  subtitle,
  rightDescription,
  icon,
  rightCallToActionComponent,
  customHeader,
  isSelected,
  isDisabled,
  isComplete,
  hasNotification,
  handleDropDownToggle,
  headerColor
}: DropDownHeaderProps): ReactElement {
  return (
    <Flex
      width='100%'
      direction='column'
      borderRadius={BorderRadius.CARD}
      role='group'
    >
      {
      customHeader != null
        ? <DropDownCustomHeader
            customHeader={customHeader}
            isSelected={isSelected}
            isDisabled={isDisabled}
            onClick={handleDropDownToggle}
          />
        : <DropDownDefaultHeader
            isSelected={isSelected}
            isDisabled={isDisabled}
            hasNotification={hasNotification}
            title={title}
            subtitle={subtitle}
            headerColor={headerColor}
            isComplete={isComplete}
            rightDescription={rightDescription}
            icon={icon}
            onClick={handleDropDownToggle}
            rightCallToActionComponent={rightCallToActionComponent}
          />
    }
    </Flex>
  )
}
