export const SCROLL_CONTAINER_ID = 'SCROLL_CONTAINER_ID'

export function scrollToTop ({ behavior }: { behavior?: 'smooth' }): void {
  const container = document.getElementById(SCROLL_CONTAINER_ID)

  container?.scrollTo({
    top: 0,
    behavior
  })
}
