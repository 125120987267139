import {
  ALTIR_LOGO_BLUE_HORIZONTAL,
  ALTIR_LOGO_WHITE_HORIZONTAL,
  DOYLE_LOGO,
  HILINE_LOGO,
  SIB_LOGO,
  SULLY_FI_LOGO
} from './graphics'
import { Color } from './theme'

export enum CustomThemeType {
  ALTIR = 'ALTIR',
  DOYLE = 'DOYLE',
  HILINE = 'HILINE',
  SIB_FIXED_COST_REDUCTION = 'SIB',
  SULLY_FI = 'SULLY_FI'
}

export interface CustomTheme {
  color: {
    primary: string
    primaryHover: string
    secondaryLight: string
    secondaryDark: string
  }
  logo: {
    url: string
    height: string
  }
  altirLogoUrl?: string
}

export function getCustomTheme (themeType: CustomThemeType): CustomTheme {
  switch (themeType) {
    case CustomThemeType.ALTIR:
      return {
        color: {
          primary: Color.BRIGHT_BLUE,
          primaryHover: Color.BRIGHT_BLUE_HOVER,
          secondaryLight: Color.LIGHT_GREY,
          secondaryDark: Color.DARK_BLUE
        },
        logo: {
          url: ALTIR_LOGO_BLUE_HORIZONTAL,
          height: '40px'
        }
      }
    case CustomThemeType.DOYLE:
      return {
        color: {
          primary: '#327471',
          primaryHover: '#1E4C4A',
          secondaryLight: Color.WHITE,
          secondaryDark: Color.GREY
        },
        logo: {
          url: DOYLE_LOGO,
          height: '60px'
        },
        altirLogoUrl: ALTIR_LOGO_BLUE_HORIZONTAL
      }
    case CustomThemeType.HILINE:
      return {
        color: {
          primary: '#F7DB52',
          primaryHover: '#FAE67A',
          secondaryLight: Color.WHITE,
          secondaryDark: '#F1F0EB'
        },
        logo: {
          url: HILINE_LOGO,
          height: '35px'
        },
        altirLogoUrl: ALTIR_LOGO_BLUE_HORIZONTAL
      }
    case CustomThemeType.SIB_FIXED_COST_REDUCTION:
      return {
        color: {
          primary: '#4CA59E',
          primaryHover: '#3D847E',
          secondaryLight: Color.WHITE,
          secondaryDark: Color.LIGHT_GREY
        },
        logo: {
          url: SIB_LOGO,
          height: '45px'
        },
        altirLogoUrl: ALTIR_LOGO_BLUE_HORIZONTAL
      }
    case CustomThemeType.SULLY_FI:
      return {
        color: {
          primary: '#6C46B6',
          primaryHover: '#503C9F',
          secondaryLight: '#F4F8FF',
          secondaryDark: Color.DARK_BLUE
        },
        logo: {
          url: SULLY_FI_LOGO,
          height: '35px'
        },
        altirLogoUrl: ALTIR_LOGO_WHITE_HORIZONTAL
      }
  }
}

export function getCustomThemeType (themeType: string | null): CustomThemeType {
  if (themeType == null) return CustomThemeType.ALTIR
  switch (themeType) {
    case CustomThemeType.ALTIR:
      return CustomThemeType.ALTIR
    case CustomThemeType.DOYLE:
      return CustomThemeType.DOYLE
    case CustomThemeType.HILINE:
      return CustomThemeType.HILINE
    case CustomThemeType.SIB_FIXED_COST_REDUCTION:
      return CustomThemeType.SIB_FIXED_COST_REDUCTION
    case CustomThemeType.SULLY_FI:
      return CustomThemeType.SULLY_FI
    default:
      return CustomThemeType.ALTIR
  }
}

export function isDistributionPartner (themeType: CustomThemeType): boolean {
  return themeType !== CustomThemeType.ALTIR
}
