import { useState, useEffect, useRef } from 'react'

interface IsUserIdleUntilResetProps {
  timeoutSeconds: number
}

export function useIsUserIdleUntilReset ({ timeoutSeconds }: IsUserIdleUntilResetProps):
{ isUserIdle: boolean, resetIdleUserState: () => void } {
  const [isUserIdle, setIsUserIdle] = useState(false)
  const timer = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const resetTimer = (): void => {
      if (timer.current != null) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        setIsUserIdle(true)
      }, timeoutSeconds * 1000)
    }
    resetTimer()
    window.addEventListener('mousemove', resetTimer)
    window.addEventListener('keydown', resetTimer)
    return () => {
      window.removeEventListener('mousemove', resetTimer)
      window.removeEventListener('keydown', resetTimer)
    }
  }, [timeoutSeconds])

  function resetIdleUserState (): void {
    setIsUserIdle(false)
    if (timer.current != null) {
      clearTimeout(timer.current)
    }
  }

  return { isUserIdle, resetIdleUserState }
}
