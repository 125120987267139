/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function RightArrowIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path stroke={color} d='M12.2471 15.432L16.9531 10.4318L12.2471 5.43164M3.61934 10.4318L16.561 10.4318' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round'/>
      </svg>
    </IconContainer>
  )
}
