import { gql } from '@apollo/client'

export const ADD_WIRE_COUNTERPARTY = gql`
mutation AddWireInfoToCounterparty(
  $counterpartyId: String!, 
  $wireInfo: WireParams!
) {
  addWireInfoToCounterparty(
    counterpartyId: $counterpartyId, 
    wireInfo: $wireInfo)
}
`
