/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function LocationIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='24' height='24' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path stroke={color} strokeWidth='2' d='M12.3511 21.2878L12.351 21.2879C12.2531 21.372 12.1283 21.4182 11.9992 21.418C11.8706 21.4179 11.7463 21.3718 11.6487 21.2881C11.6484 21.2878 11.648 21.2875 11.6477 21.2872L11.3208 21.0036C11.3203 21.0032 11.3197 21.0027 11.3192 21.0022C6.56545 16.8416 4.4248 13.2452 4.4248 10.1572C4.4248 8.14816 5.2229 6.22138 6.64353 4.80075C8.06415 3.38013 9.99093 2.58203 12 2.58203C14.0091 2.58203 15.9358 3.38013 17.3565 4.80075C18.7771 6.22138 19.5752 8.14816 19.5752 10.1572C19.5752 13.3178 17.3323 17.0099 12.3511 21.2878Z'/>
        <circle cx='12.1877' cy='10.6018' r='3.02219' stroke='#12285E' strokeWidth='2'/>
      </svg>
    </IconContainer>
  )
}
