import { Text, Heading, Flex } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { BorderRadius, Color } from '@/theme/theme'
import { Graphic } from '@/library/utility/Graphic'
import { TELESCOPE } from '@/theme/graphics'

// eslint-disable-next-line max-len
const BODY_TEXT = 'We are currently searching for the best pre-approved loan offers. In the mean-time, you can upload documents that prove your identity or eligibility to your document center.'

export function EmptyLoanOffersComponent (): ReactElement {
  return (
    <Flex
      flexDir='column'
      justifyContent='center'
      alignItems='center'
      textAlign='center'
      borderRadius={BorderRadius.CARD}
      bg={Color.GREY}
      p={16}
      gap={3}
      flex={1}
    >
      <Graphic src={TELESCOPE} h='64px'/>
      <Heading size='lg' lineHeight={1.2} color={Color.DARK_BLUE}>Searching for Pre-Approved Loans</Heading>
      <Text lineHeight={1.2}>
        {BODY_TEXT}
      </Text>
    </Flex>
  )
}
