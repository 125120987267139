import React from 'react'
import { Flex, useToast } from '@chakra-ui/react'
import { tellerConfig } from 'config/config'
import { useCallback, type ReactElement } from 'react'
import {
  useTellerConnect,
  type TellerConnectOnExit,
  type TellerConnectOptions
} from 'teller-connect-react'
import { Event } from 'metrics/metrics'
import AccountLinkLoaderModal from '../financial_institution/AccountLinkLoaderModal'
import { InstitutionConnectionProvider } from '@/graphql/__generated__/globalTypes'
import { useInstitutionCreateLink } from '@/hooks/useInstitutionCreateLink'
import { type ErrorWithContent } from '@/types/types'
import { getErrorToast, getSuccessToast } from '@/utils/toastUtils'

interface TellerConnectProps {
  franchiseGroupId: number
  tellerEnrollmentId?: string
  onSuccess: () => void
  onError: (error: ErrorWithContent) => void
  customButton: ReactElement
}

export default function TellerConnect ({
  franchiseGroupId,
  tellerEnrollmentId,
  onSuccess,
  onError,
  customButton
}: TellerConnectProps): ReactElement {
  const toast = useToast()
  const tellerAppId = tellerConfig.appId

  const {
    isUpdateMode,
    isInitializationMutationLoading,
    onSuccessfulTellerAccountLogin,
    isLinkMutationLoading,
    logEvent,
    logError
  } = useInstitutionCreateLink({
    selectedFranchiseGroupId: franchiseGroupId,
    connectionProvider: InstitutionConnectionProvider.TELLER,
    tellerEnrollmentId,
    onError: handleError,
    onSuccess: handleSuccess
  })

  function handleSuccess (): void {
    toast(getSuccessToast('Account Linked'))
    onSuccess()
  }

  function handleError (error: ErrorWithContent): void {
    if (onError != null) {
      onError(error)
    }
    toast(getErrorToast('Account Link Failed'))
  }

  // TODO Investigate why onEvent and onFailure do not trigger with TellerConnect
  const onExit = useCallback<TellerConnectOnExit>(() => {
    logEvent({ message: 'Teller Connect Dismissed by user without linking' })
  }, [])

  // EnrollmentId Should be able to be passed undefined, however, it causes the modal to error out
  const baseTellerConfig: TellerConnectOptions = {
    applicationId: tellerAppId,
    onSuccess: onSuccessfulTellerAccountLogin,
    onExit,
    onFailure: (failure) => { logError(failure) },
    onEvent: (name, data) => { logEvent({ name, data }) }
  }

  const config = tellerEnrollmentId == null
    ? baseTellerConfig
    : { enrollmentId: tellerEnrollmentId, ...baseTellerConfig }

  const {
    open: openTellerConnect,
    ready: tellerConnectReady
  } = useTellerConnect(config)

  const tellerConnectButton = React.cloneElement(customButton, {
    onClickEventType: isUpdateMode ? Event.TELLER_RELINK_OPEN_CLICK : Event.TELLER_LINK_OPEN_CLICK,
    isLoading: !tellerConnectReady && !isInitializationMutationLoading
  })
  return (
    <Flex onClick={() => { openTellerConnect() }} width='100%'>
      <AccountLinkLoaderModal isOpen={isLinkMutationLoading}/>
      {tellerConnectButton}
    </Flex>
  )
}
