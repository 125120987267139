import { gql } from '@apollo/client'

export const UPDATE_COUNTERPARTY = gql`
    mutation UpdateCounterparty(
        $counterpartyId: String!, 
        $checkInfo: CheckParams, 
        $achInfo: AchParams, 
        $wireInfo: WireParams
    ) {
        updateCounterparty(
            counterpartyId: $counterpartyId, 
            checkInfo: $checkInfo, 
            achInfo: $achInfo, 
            wireInfo: $wireInfo
        )
    }
`
