import React, { type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import VerticalStepList from '@/library/progress/step/VerticalStepList'
import TreasuryIcon from '@/library/icons/TreasuryIcon'
import CloseThinIcon from '@/library/icons/CloseThinIcon'
import TransferIcon from '@/library/icons/TransferIcon'
import DollarIcon from '@/library/icons/DollarIcon'
import LinkText from '@/library/text/LinkText'
import Button, { ButtonVariant } from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { ROUTES } from '@/api/routes'
import { GRASSHOPPER_LOGO } from '@/theme/graphics'
import AltirPartnerLinkGraphic from '@/library/utility/AltirPartnerLinkGraphic'
import { BANK_MIGRATION_FAQ_URL } from '@/theme/urls'

interface AccountUpgradeIntroProps {
  onContinue: () => void
}

export default function AccountUpgradeIntro ({ onContinue }: AccountUpgradeIntroProps): ReactElement {
  const navigate = useNavigate()
  const steps = [
    {
      title: 'We open your new Altir account',
      icon: <TreasuryIcon/>
    },
    {
      title: 'We pay out any interest you have accrued in your old Altir account ',
      icon: <DollarIcon/>
    },
    {
      title: 'We wire funds from your old Altir account to your new Altir account for free',
      icon: <TransferIcon/>
    },
    {
      title: 'We close your old account',
      icon: <CloseThinIcon/>
    }
  ]
  return (
    <Center flexDir='column' gap={8}>
      <AltirPartnerLinkGraphic partnerLogoSrc={GRASSHOPPER_LOGO}/>
      <Center flexDir='column' textAlign='center' gap={1}>
        <Heading>Upgrade Your Accounts</Heading>
        <Text>
          Altir is moving to a new banking partner.
          This upgrade allows us to continue offering you new features and updates.
          We will email you once all of your account has been successfully upgraded.
          View our <LinkText text='FAQs' href={BANK_MIGRATION_FAQ_URL}/> to learn more.
        </Text>
      </Center>
      <VerticalStepList steps={steps}/>
      <Center flexDir='column' gap={4} w='100%'>
        <Button text='Continue' afterIcon={<RightArrowIcon/>} onClick={onContinue}/>
        <Button text='Remind Me Later' variant={ButtonVariant.WHITE} onClick={() => { navigate(ROUTES.HOME) }}/>
      </Center>
    </Center>
  )
}
