import React, { type ReactElement, memo, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  type DateWindowSelectionOptions,
  type DateSelectionWindow,
  type FinancialDataPoint
} from '@/types/types'
import { GET_HISTORICAL_ACCOUNT_BALANCES } from '@/graphql/queries/amplify_account/GetHistoricalAccountBalances'
import {
  type GetHistoricalAccountBalances,
  type GetHistoricalAccountBalancesVariables
} from '@/graphql/__generated__/GetHistoricalAccountBalances'
import AltirSkeleton, { SkeletonVariant } from '@/library/loading/AltirSkeleton'
import { dateTimeToISODate, getDateWindow, getFormattedDateString, newDate } from '@/utils/dateUtils'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import FinancialLineGraph from '@/library/graphs/FinancialLineGraph'
import ResponsiveD3Graph from '@/library/graphs/ResponsiveD3Graph'
import { EODAccountBalancesToFinancialDataPoint } from '@/utils/financialAccountUtils'
import { nonNull } from '@/utils/arrayUtils'

interface HistoricalAccountBalanceGraphProps {
  accountId: number
  selectedDateWindow: DateWindowSelectionOptions
  onSelectedAccountBalanceChange?: (amount: string, date?: string) => void
}

const GRAPH_HEIGHT = 200

export default memo(function HistoricalAccountBalanceGraph ({
  accountId,
  selectedDateWindow,
  onSelectedAccountBalanceChange = () => {}
}: HistoricalAccountBalanceGraphProps): ReactElement {
  const [transformedDateWindow, setTransformedDateWindow] = useState<DateSelectionWindow | undefined>(undefined)
  const [balances, setBalances] = useState<FinancialDataPoint[]>([])

  // Transform 1 month -> { startDate: today - 1 month, endDate: today }
  // We have to useEffect here so that child component doesn't infinitely re-render
  useEffect(() => {
    setTransformedDateWindow(getDateWindow(selectedDateWindow))
  }, [selectedDateWindow])

  const {
    loading,
    error
  } = useQuery<GetHistoricalAccountBalances, GetHistoricalAccountBalancesVariables>(
    GET_HISTORICAL_ACCOUNT_BALANCES, {
      variables: {
        accountId,
        ascending: true,
        dateRange: {
          startDate: transformedDateWindow?.startDate?.toISO(),
          endDate: transformedDateWindow?.endDate?.toISO()
        }
      },
      onCompleted: (data) => {
        const fetchedLiveBalance = liveBalanceToFinancialDataPoint(
          data.financialAccount.liveBalance?.availableBalance?.amount ?? undefined
        )
        const fetchedEODBalances = EODAccountBalancesToFinancialDataPoint(data.financialAccount.EODBalances ?? [])
        // Set the live balance as the last data point in the historical graph
        const augmentedBalances = nonNull([...fetchedEODBalances, fetchedLiveBalance])
        setBalances(augmentedBalances)
        handleDefaultBalanceSelection(augmentedBalances)
      }
    }
  )

  function handleBalanceSelectionChange (balance?: FinancialDataPoint): void {
    if (balance != null) {
      onSelectedAccountBalanceChange(
        getCurrencyFormatted(balance.amount),
        getFormattedDateString(balance.date) ?? undefined)
    } else {
      handleDefaultBalanceSelection(balances)
    }
  }

  // Called when the user hasn't hovered over the graph. Sets the "selected"
  // value as the most recent balance
  function handleDefaultBalanceSelection (data: FinancialDataPoint[]): void {
    const lastBalance = data[data.length - 1]
    if (lastBalance != null) {
      onSelectedAccountBalanceChange(
        getCurrencyFormatted(lastBalance.amount),
        getFormattedDateString(lastBalance.date ?? null) ?? undefined
      )
    }
  }

  return (
    <AltirSkeleton isLoading={loading} error={error} variant={SkeletonVariant.LIGHT}>
      <ResponsiveD3Graph minHeight={GRAPH_HEIGHT + 100}>
        <FinancialLineGraph
          data={balances}
          onValueChange={handleBalanceSelectionChange}
          shouldHideSliderDisplayText
        />
      </ResponsiveD3Graph>
    </AltirSkeleton>
  )
})

function liveBalanceToFinancialDataPoint (amount?: number): FinancialDataPoint | undefined {
  if (amount == null) {
    return undefined
  }
  return { amount, date: dateTimeToISODate(newDate()) }
}
