/* eslint-disable max-len */
import { Icon } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function CircleCheckIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <Icon boxSize={size} display='flex' alignItems='center' justifyContent='center'>
      <svg width='100%' height='100%' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M1.61719 6.00386C1.61719 3.26754 3.83541 1.04932 6.57173 1.04932C9.30805 1.04932 11.5263 3.26754 11.5263 6.00386C11.5263 8.74018 9.30805 10.9584 6.57173 10.9584C3.83541 10.9584 1.61719 8.74018 1.61719 6.00386ZM6.57173 0.0493164C3.28313 0.0493164 0.617188 2.71526 0.617188 6.00386C0.617188 9.29247 3.28313 11.9584 6.57173 11.9584C9.86034 11.9584 12.5263 9.29247 12.5263 6.00386C12.5263 2.71526 9.86034 0.0493164 6.57173 0.0493164ZM9.17879 4.08505C9.32404 3.8502 9.25141 3.54206 9.01656 3.3968C8.7817 3.25155 8.47357 3.32419 8.32831 3.55904L6.01156 7.30488L4.78742 5.70056C4.61991 5.48102 4.30615 5.43885 4.08661 5.60636C3.86708 5.77387 3.8249 6.08763 3.99241 6.30717L5.6572 8.48898C5.75701 8.6198 5.9148 8.69313 6.07916 8.68508C6.24351 8.67703 6.39338 8.58863 6.47994 8.44869L9.17879 4.08505Z' />
      </svg>
    </Icon>
  )
}
