import React, { type ReactElement } from 'react'
import { Center, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { type ApolloError, useMutation } from '@apollo/client'
import { UPDATE_USER_ONBOARDING_STATE } from '@/graphql/mutations/onboarding/UpdateUserOnboardingState'
import { ROUTES } from '@/api/routes'
import {
  type UpdateUserOnboardingState,
  type UpdateUserOnboardingStateVariables
} from '@/graphql/__generated__/UpdateUserOnboardingState'
import Button, { ButtonVariant } from '@/library/button/Button'
import RecurringIcon from '@/library/icons/RecurringIcon'
import ErrorInline from '@/library/errors/ErrorInline'
import { isVerificationEmailExpiredError } from '@/utils/errorUtils'
import SupportEmailInlineLink from '@/library/text/SupportEmailInlineLink'
import { Graphic } from '@/library/utility/Graphic'
import { EMAIL_VERIFICATION_FAILED, STOPWATCH } from '@/theme/graphics'
import { SEND_USER_EMAIL_VERIFICATION_EMAIL } from '@/graphql/mutations/onboarding/SendUserEmailVerificationEmail'
import {
  type SendUserEmailVerificationEmail,
  type SendUserEmailVerificationEmailVariables
} from '@/graphql/__generated__/SendUserEmailVerificationEmail'

interface EmailVerificationVerifyErrorContentProps {
  userId: number
  verifyEmailError?: ApolloError
}

export default function EmailVerificationVerifyErrorContent ({
  userId,
  verifyEmailError
}: EmailVerificationVerifyErrorContentProps): ReactElement {
  const navigate = useNavigate()
  const [
    reSendVerificationEmail,
    { loading: isReSendVerificationEmailLoading, error: reSendVerificationEmailError }
  ] = useMutation<SendUserEmailVerificationEmail, SendUserEmailVerificationEmailVariables>(
    SEND_USER_EMAIL_VERIFICATION_EMAIL,
    {
      variables: { userId },
      onCompleted: () => { navigate(ROUTES.V2_SIGN_UP_EMAIL_VERIFICATION) },
      onError: () => {}
    }
  )
  const [
    skipEmailVerification,
    { loading: isSkipEmailVerificationLoading, error: skipEmailVerificationError }
  ] = useMutation<UpdateUserOnboardingState, UpdateUserOnboardingStateVariables>(
    UPDATE_USER_ONBOARDING_STATE,
    {
      variables: { isEmailVerificationSkipped: true },
      onCompleted: () => { navigate(ROUTES.V2_SIGN_UP) },
      onError: () => {}
    }
  )

  const { title, subtitle, graphicSrc, graphicHeight } = getPageErrorContent(verifyEmailError)

  return (
    <Center flexDir='column' gap={8} w='100%'>
      <Graphic src={graphicSrc} h={graphicHeight}/>
      <Center flexDir='column' textAlign='center' gap={1}>
        <Heading>{title}</Heading>
        <Text>
          {subtitle}
          If you need help, contact us at <SupportEmailInlineLink/>
        </Text>
      </Center>
      <Center flexDir='column' gap={4} w='100%'>
        <Button
          text='Resend Verification Email'
          beforeIcon={<RecurringIcon/>}
          onClick={reSendVerificationEmail}
          isLoading={isReSendVerificationEmailLoading}
        />
        <Button
          text='Skip This for Now'
          variant={ButtonVariant.WHITE}
          onClick={skipEmailVerification}
          isLoading={isSkipEmailVerificationLoading}
        />
        <ErrorInline error={skipEmailVerificationError ?? reSendVerificationEmailError}/>
      </Center>
    </Center>
  )
}

function getPageErrorContent (
  error?: ApolloError
): { title: string, graphicSrc: string, graphicHeight: string, subtitle: string } {
  if (isVerificationEmailExpiredError(error)) {
    return {
      title: 'Email Verification Link Expired',
      subtitle: `
        Verification links expire after 10 minutes for security reasons. Try re-sending the email.
      `,
      graphicSrc: STOPWATCH,
      graphicHeight: '100px'
    }
  }

  return {
    title: 'Email Verification Failed',
    subtitle: `
      We are having trouble verifying your email. Try re-sending the email. If the problem persists, you may skip this step and try again later.
    `,
    graphicSrc: EMAIL_VERIFICATION_FAILED,
    graphicHeight: '126px'
  }
}
