import React, { type ReactElement } from 'react'
import { Divider, Flex, Text } from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import { Event } from 'metrics/metrics'
import AccountManagementOptionsComponent from './AccountManagementOptionsComponent'
import { type GroupedFinancialAccounts } from '@/types/types'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { getAccountString } from '@/utils/financialAccountUtils'
import { AccountLinkStatus } from '@/graphql/__generated__/globalTypes'
import {
  BorderRadius,
  Color
} from '@/theme/theme'
import InstitutionLogo from '@/library/logo/InstitutionLogo'
import { ReLinkErrorBanner } from '@/library/errors/ReLinkErrorBanner'
import { ERROR_BORDER } from '@/utils/errorUtils'

export interface InstitutionGroupComponentProps {
  institutionGroup: GroupedFinancialAccounts
  franchiseGroupId: number
  refetchData: () => void
}

export default function InstitutionGroupComponent (
  {
    institutionGroup,
    franchiseGroupId,
    refetchData
  }: InstitutionGroupComponentProps
): ReactElement {
  const isAccountLoginRequired = institutionGroup.status === AccountLinkStatus.LOGIN_REQUIRED

  return (
    <Flex
      flexDir='column'
      bg={Color.LIGHT_GREY}
      w='100%'
      borderRadius={BorderRadius.CARD}
      py={6}
      px={6}
      border={isAccountLoginRequired ? ERROR_BORDER : ''}
    >
      <Flex justifyContent='space-between' pb={3}>
        <Flex alignItems='center' gap={3}>
          <InstitutionLogo src={institutionGroup.institution.logoAssetUrl ?? undefined}/>
          <Text color={Color.DARK_BLUE}>
            {institutionGroup.institution.name}
          </Text>
        </Flex>
        <Flex alignItems='center'>
          <AccountManagementOptionsComponent
            franchiseGroupId={franchiseGroupId}
            refetch={refetchData}
            institutionGroup={institutionGroup}
          />
        </Flex>
      </Flex>
      {isAccountLoginRequired &&
        <ReLinkErrorBanner
          franchiseGroupId={franchiseGroupId}
          refetchData={refetchData}
          plaidAccessToken={institutionGroup.plaidAccessToken}
          plaidItemId={institutionGroup.plaidItemId}
          institutionName={institutionGroup.institution.name ?? undefined}
          connectionProvider={institutionGroup.connectionProvider}
          customTitle='Connection to Institution Failed'
          customSubTitle={"We're unable to connect to your financial institution"}
          customButton={<Button
            text='Fix Link'
            variant={ButtonVariant.DESTRUCTIVE_TRANSPARENT}
            size={ButtonSize.X_SMALL}
            onClickEventType={Event.PLAID_RELINK_OPEN_CLICK}
                        />}
        />
      }
      <Flex flexDir='column' gap={2} pt={3}>
        <Text>Accounts</Text>
        <Flex
          bg={Color.WHITE}
          flexDir='column'
          p={4}
          borderRadius={BorderRadius.CARD}
          gap={2}
        >
          {
            institutionGroup.accounts.map((account, index) => {
              return (
                <Flex key={nanoid()} flexDir='column' gap={1}>
                  <Text color={Color.DARK_BLUE} fontSize='sm'>
                    {getAccountString(account)}
                  </Text>
                  {index !== institutionGroup.accounts.length - 1 && <Divider/>}
                </Flex>
              )
            })
          }
        </Flex>
      </Flex>
    </Flex>
  )
}
