import React, { useState, type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import InterestGraph from './InterestGraph'
import MetricCard, { MetricCardVariant } from '@/library/card/MetricCard'
import { type FinancialDataPoint } from '@/types/types'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { GET_HISTORICAL_ACCRUED_INTEREST } from '@/graphql/queries/amplify_account/GetHistoricalAccruedInterest'
import {
  type GetHistoricalAccruedInterest,
  type GetHistoricalAccruedInterestVariables
} from '@/graphql/__generated__/GetHistoricalAccruedInterest'
import { EMPTY_BALANCE_VALUE } from '@/utils/constants'
import { getFormattedDateString } from '@/utils/dateUtils'

interface InterestCardProps {
  accountId: number
}

export default function InterestCard ({ accountId }: InterestCardProps): ReactElement {
  const [selectedAmount, setSelectedAmount] = useState<string>(EMPTY_BALANCE_VALUE)
  const [selectedDate, setSelectedDate] = useState<string | undefined>(undefined)
  const [graphData, setGraphData] = useState<FinancialDataPoint[]>([])
  const {
    loading,
    error
  } = useQuery<GetHistoricalAccruedInterest, GetHistoricalAccruedInterestVariables>(
    GET_HISTORICAL_ACCRUED_INTEREST,
    {
      variables: { accountId },
      onCompleted: (data) => {
        // Set initial metric value
        const interestAccrualDates = data.financialAccount.amplifyAccount?.historicalAccruedInterest ?? []
        const totalBalance = interestAccrualDates[interestAccrualDates.length - 1]?.amount.amount ?? 0
        setSelectedAmount(
          `+${getCurrencyFormatted(totalBalance, { minimumFractionDigits: 2 })}`
        )

        // Set graph data
        const formattedData = interestAccrualDates
          .map(i => { return { amount: i.amount.amount ?? 0, date: i.endDate } })
        setGraphData(formattedData)
      }
    }
  )

  function handleValueChange (amount?: number, date?: string): void {
    setSelectedDate(getFormattedDateString(date) ?? undefined)
    if (amount != null) {
      const formattedAmount = getCurrencyFormatted(amount, { minimumFractionDigits: 2 })
      setSelectedAmount(`+${formattedAmount}`)
    } else {
      const lastPoint = graphData[graphData.length - 1]
      if (lastPoint != null) {
        setSelectedAmount(`+${getCurrencyFormatted(lastPoint.amount, { minimumFractionDigits: 2 })}`)
      }
    }
  }

  // only show graph when there are at least two points to draw line between
  const hasSufficientData = graphData.length > 1
  return (
    <MetricCard
      label='Total Interest Earned'
      metric={selectedAmount}
      secondaryMetric={selectedDate}
      isLoading={loading}
      error={error}
      variant={MetricCardVariant.EDGE_TO_EDGE}
    >
      {hasSufficientData && <InterestGraph data={graphData} onValueChange={handleValueChange}/>}
    </MetricCard>
  )
}
