import { type SelectItem } from './CheckBoxSelectGroup'

export function getSelectedEntries (entries: Record<string, SelectItem>): string[] {
  return Object.entries(entries)
    .filter(([_, source]) => source.isSelected)
    .map(([_, source]) => source.description)
}

export function getSelectedEntryIds (entries: Record<string, SelectItem>): string[] {
  return Object.entries(entries)
    .filter(([_, source]) => source.isSelected)
    .map(([key, _]) => key)
}
