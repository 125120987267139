import React, { type ReactElement } from 'react'
import { Button, MenuButton } from '@chakra-ui/react'
import PopoverMenuButtonContent, { type PopOverMenuButtonVariant } from './PopoverMenuButtonContent'
import {
  Color, BorderRadius
} from '@/theme/theme'

interface PopoverMenuButtonProps {
  isOpen: boolean
  title: string
  subtitle?: string
  beforeIcon?: ReactElement

  // Styles
  variant?: PopOverMenuButtonVariant
  borderRadius?: string
  border?: string
  height?: string
  shouldRotateIcon?: boolean
  paddingX?: number
  paddingY?: number
}

export default function PopoverMenuButton (
  {
    isOpen,
    title,
    subtitle,
    borderRadius = BorderRadius.BUTTON,
    border = '1px',
    height,
    beforeIcon,
    variant,
    shouldRotateIcon = true,
    paddingX = 0,
    paddingY = 0
  }:
  PopoverMenuButtonProps
): ReactElement {
  return (
    <MenuButton
      as={Button}
      w='100%'
      borderRadius={borderRadius}
      borderColor={Color.DARK_BLUE}
      bg={Color.WHITE}
      border={border}
      paddingX={paddingX}
      paddingY={paddingY}
    >
      <PopoverMenuButtonContent
        isOpen={isOpen}
        title={title}
        subtitle={subtitle}
        beforeIcon={beforeIcon}
        height={height}
        shouldRotateIcon={shouldRotateIcon}
        variant={variant}
      />
    </MenuButton>
  )
}
