import { gql } from '@apollo/client'

export const GET_INSIGHTS_PAGE_DATA = gql`
    query GetInsightsPageData($franchiseGroupId: Int!) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                id
                hasBrokenAccountLink
            }
        }
    }  
`

export const GET_FIXED_METRICS = gql`
    query GetFixedMetrics($franchiseGroupId: Int!) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                fixedInsightMetrics {
                    idleCash {
                        amount
                        amountFormatted
                    }
                    idleCashPercentage {
                        rate
                        rateFormatted
                    }
                    idleCashPotentialEarnings {
                        amount
                        amountFormatted
                    }
                    netCash {
                        amount
                        amountFormatted
                    }
                    compoundGrowthRate {
                        rate
                        rateFormatted
                    }
                    compoundGrowthRateMonthEarningProjection {
                        amount
                        amountFormatted
                    }
                }
            }
        }
    }  
`

export const GET_MONTHLY_METRICS = gql`
    query GetMonthlyMetrics($franchiseGroupId: Int!) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                historicalMonthlyAccountBalanceChange{
                    netCashChange{
                        amount
                        amountFormatted
                    }
                    startDate
                    endDate
                }
            }
        }
    }  
`

export const GET_HISTORICAL_CASH_BALANCE = gql`
    query GetHistoricalCashBalance(
        $franchiseGroupId: Int!, 
        $dateRange: DateRangeInput,
        $ascending: Boolean
        ) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                aggregateEODBalances(dateRange: $dateRange, ascending: $ascending){
                    balance{
                        amount
                    }
                    date
                }
            }
        }
    }  
`

export const GET_TOTAL_CASH_SECTION_DATA = gql`
    query GetTotalCashSectionData($franchiseGroupId: Int!) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                name
                numberOfFranchiseLocations
                signUpDate
                members {
                    id
                }
                aggregateAccountBalance{
                    availableBalance{
                        amount
                    }
                }
            }
        }
    }  
`
