import React, { type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import DefaultCard from '@/library/card/DefaultCard'
import { Color } from '@/theme/theme'
import { Graphic } from '@/library/utility/Graphic'
import { HANDHOLDING_PEOPLE } from '@/theme/graphics'

export default function NewExternalRecipientCard (): ReactElement {
  return (
    <DefaultCard>
      <Flex flexDir='column' gap={4}>
        <Graphic src={HANDHOLDING_PEOPLE} h='53px'/>
        <Flex flexDir='column' gap={2} alignItems='center' textAlign='center'>
          <Heading color={Color.DARK_BLUE} size='md'>You are interacting with a new recipient.</Heading>
          <Text fontSize='sm'>
            For your security, we need to verify this new external recipient.
            This should not interfere with your transfer.
          </Text>
        </Flex>
      </Flex>
    </DefaultCard>
  )
}
