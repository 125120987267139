import { gql } from '@apollo/client'

export const GET_AVAILABLE_STATEMENTS = gql`
    query GetAvailableStatements($accountId: Int) {
        availableStatements(accountId: $accountId) {
            accountId
            description
            startDate
            endDate
        }
    }
`
