import React, { type ReactElement } from 'react'
import { Center, Text } from '@chakra-ui/react'
import IconOutline from '@/library/utility/IconOutline'
import { Color } from '@/theme/theme'

export interface StepProps {
  icon: ReactElement
  title: string
  stepNumber: number
}

export default function Step ({
  icon,
  title,
  stepNumber
}: StepProps): ReactElement {
  const iconWithProps = React.cloneElement(icon, { color: Color.DARK_BLUE })
  return (
    <Center flexDir='column' w='100%' gap={2}>
      <IconOutline
        icon={iconWithProps}
        backgroundColor={Color.WHITE}
      />
      <Center flexDir='column' w='100%' gap={1}>
        <Text color={Color.DARK_BLUE} fontSize='sm'>STEP {String(stepNumber)}</Text>
        <Text>{title}</Text>
      </Center>
    </Center>
  )
}
