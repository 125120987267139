import React, { type ReactElement } from 'react'
import { Graphic } from '../utility/Graphic'
import {
  ALTIR_LOGO_BLUE,
  ALTIR_LOGO_BLUE_HORIZONTAL,
  ALTIR_PRO_LOGO_BLUE,
  ALTIR_PRO_LOGO_BLUE_HORIZONTAL
} from '../theme/graphics'

export interface AltirLogoProps {
  isPro?: boolean
  isExpanded: boolean
}

export default function AltirLogo ({ isExpanded, isPro = false }: AltirLogoProps): ReactElement {
  const altirProLogo = isExpanded ? ALTIR_PRO_LOGO_BLUE_HORIZONTAL : ALTIR_PRO_LOGO_BLUE
  const altirDefaultLogo = isExpanded ? ALTIR_LOGO_BLUE_HORIZONTAL : ALTIR_LOGO_BLUE
  const src = isPro ? altirProLogo : altirDefaultLogo
  return <Graphic src={src} h='28px'/>
}
