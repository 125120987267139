import { gql } from '@apollo/client'

export const AUTHORIZATION_FRAGMENT = gql`
    fragment AuthorizationFragment on AuthResourceMap {
        resource 
        actions {
            READ
            WRITE
        }
    }
`
