import { Flex, Heading, Text } from '@chakra-ui/react'
import React, { type ReactNode, useState, type ReactElement } from 'react'
import { useMutation } from '@apollo/client'
import { ModalComponent } from '../ModalComponent'
import Button, { ButtonVariant } from '../../button/Button'
import ErrorInline from '../../errors/ErrorInline'
import FormNumberInput from '../../form/number/FormNumberInput'
import { Graphic } from '../../utility/Graphic'
import { Color, ModalHeight } from '@/theme/theme'
import { CREATE_USER_PASSCODE } from '@/graphql/mutations/CreateUserPasscode'
import { type CreateUserPasscodeVariables, type CreateUserPasscode } from '@/graphql/__generated__/CreateUserPasscode'
import { CIRCLE_CHECK_GRAPHIC } from '@/theme/graphics'

interface FormData {
  pin?: string
  pinReEntry?: string
}

export default function PasscodeCreationModal (): ReactElement {
  // Modal state
  const [isOpen, setIsOpen] = useState(true)
  const [isPasscodeSuccessfullyCreated, setIsPasscodeSuccessfullyCreated] = useState(false)

  // Mutation
  const [
    createUserPasscode,
    { loading, error: submissionError }
  ] = useMutation<CreateUserPasscode, CreateUserPasscodeVariables>(CREATE_USER_PASSCODE)

  // Form data
  const [formData, setFormData] = useState<FormData>({ pin: '', pinReEntry: '' })
  const [inlineErrorState, setInlineErrorState] = useState<FormData>({ })
  const [inlineError, setInlineError] = useState<Error | undefined>()

  function updateForm (name: string, value: string): void {
    flushErrors(name)
    if (
      formData.pin?.length === 4 &&
      value.length === 4 &&
      formData.pin !== value
    ) {
      updateInlineErrorState(
        'pinReEntry',
        'Your PINs do not match. Please re-enter your PIN to confirm it.'
      )
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  function flushErrors (name: string): void {
    updateInlineErrorState(name, undefined)
    setInlineError(undefined)
  }

  function updateInlineErrorState (name: string, value?: string): void {
    setInlineErrorState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  function handleSubmit (): void {
    if (formData.pin == null) {
      setInlineError(Error('Invalid form'))
      return
    }
    void createUserPasscode({ variables: { passcode: formData.pin } })
      .then(() => { setIsPasscodeSuccessfullyCreated(true) })
  }

  function getSuccessContent (): ReactNode {
    return (
      <Flex flexDir='column' gap={6} justifyContent='space-between'>
        <Flex flexDir='column' gap={2} textAlign='center' alignItems='center'>
          <Heading color={Color.DARK_BLUE} size='lg'>PIN Successfully Created</Heading>
          <Text>
            You have successfully created your 4-digit PIN. Continue to make your transfer.
          </Text>
        </Flex>
        <Graphic src={CIRCLE_CHECK_GRAPHIC} h='100px'/>
        <Flex flexDir='column' alignItems='center' gap={4}>
          <Button text='Continue' onClick={() => { setIsOpen(false) }}/>
        </Flex>
      </Flex>
    )
  }

  function getFormContent (): ReactNode {
    return (
      <Flex flexDir='column' gap={6} justifyContent='space-between'>
        <Flex flexDir='column' gap={2} textAlign='center' alignItems='center'>
          <Heading color={Color.DARK_BLUE} size='lg'>Create a Transfer PIN</Heading>
          <Text>
            For your security, we need to verify your identity.
            Create a 4-digit PIN that will be used every time you make a transfer.
          </Text>
        </Flex>
        <Flex flexDir='column' gap={6} h='100%'>
          <FormNumberInput
            label='Enter 4-digit PIN'
            value={formData.pin ?? ''}
            maxNumberOfDigits={4}
            onChange={(value) => { updateForm('pin', value) }}
            fieldName='pin'
            placeholder='i.e. 1234'
            fieldError={inlineErrorState.pin}
            backgroundColor={Color.WHITE}
          />
          <FormNumberInput
            label='Re-enter 4-digit PIN'
            value={formData.pinReEntry ?? ''}
            maxNumberOfDigits={4}
            onChange={(value) => { updateForm('pinReEntry', value) }}
            fieldName='pinReEntry'
            placeholder='i.e. 1234'
            fieldError={inlineErrorState.pinReEntry}
            backgroundColor={Color.WHITE}
          />
        </Flex>
        <ErrorInline error={inlineError ?? submissionError}/>
        <Flex flexDir='column' alignItems='center' gap={4}>
          <Button text='Continue' onClick={handleSubmit} isDisabled={isDisabled} isLoading={loading}/>
          <Button text='Cancel' variant={ButtonVariant.WHITE} onClick={() => { setIsOpen(false) }}/>
        </Flex>
      </Flex>
    )
  }

  const isDisabled = (formData.pin?.length === 4 && formData.pin !== formData.pinReEntry) ||
    (formData.pin?.length ?? 0) < 4 || (formData.pinReEntry?.length ?? 0) < 4

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={() => { setIsOpen(false) }}
      height={ModalHeight.MEDIUM}
    >
      <Flex w='100%'>
        {isPasscodeSuccessfullyCreated ? getSuccessContent() : getFormContent()}
      </Flex>
    </ModalComponent>
  )
}
