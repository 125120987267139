import React, { type ReactElement } from 'react'
import { Center, Text } from '@chakra-ui/react'
import { Graphic } from '@/library/utility/Graphic'
import { BorderRadius, Color } from '@/theme/theme'

interface SuccessNextStepBoxProps {
  text: string
  imageSrc: string
  imageHeight: string
}

export default function SuccessNextStepBox ({ imageSrc, text, imageHeight }: SuccessNextStepBoxProps): ReactElement {
  return (
    <Center
      flexDir='column'
      gap={4}
      px={4}
      py={6}
      bg={Color.GREY}
      borderRadius={BorderRadius.CARD}
      flex={1}
      textAlign='center'
    >
      <Graphic src={imageSrc} h={imageHeight}/>
      <Text color={Color.DARK_BLUE}>{text}</Text>
    </Center>
  )
}
