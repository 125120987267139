import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { BorderRadius, Color } from '../theme/theme'

interface LegalDisclosureBoxProps {
  disclosureText: string
  icon?: ReactElement
}

export default function LegalDisclosureBox ({ disclosureText, icon }: LegalDisclosureBoxProps): ReactElement {
  return (
    <Flex
      w='100%'
      overflowY='auto'
      bg={Color.GREY}
      p={4}
      textAlign='start'
      justifyContent='start'
      alignItems='center'
      borderRadius={BorderRadius.CARD}
      gap={4}
    >
      {icon != null && icon}
      <Text fontSize='xs' color={Color.DARK_GREY}>
        {disclosureText}
      </Text>
    </Flex>
  )
}
