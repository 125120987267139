import React, { type ReactElement } from 'react'
import { Text, Flex, Heading, Modal, ModalContent, ModalOverlay, Divider } from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import { BorderRadius, Color } from '@/theme/theme'
import ModalCloseHeader from '@/library/modal/ModalCloseHeader'
import { CopyTextPill, CopyTextPillVariant } from '@/library/text/CopyTextPill'
import Button from '@/library/button/Button'
import {
  type GetAmplifyAccountInfo_currentUser_franchiseGroup_amplifyAccount as AmplifyAccount
} from '@/graphql/__generated__/GetAmplifyAccountInfo'
import { getFormattedFullName } from '@/utils/stringUtils'

interface AmplifyAccountInfoModalProps {
  isOpen: boolean
  amplifyAccount?: AmplifyAccount
  onClose: () => void
}

export default function AmplifyAccountInfoModal (
  {
    amplifyAccount,
    isOpen,
    onClose
  }: AmplifyAccountInfoModalProps
): ReactElement {
  const accountOwnersFormatted = (amplifyAccount?.amplifyAccount?.people ?? [])
    .map(person => getFormattedFullName(person.firstName, person.lastName))
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      preserveScrollBarGap
      size='sm'
    >
      <ModalOverlay />
      <ModalContent
        flexDirection='column'
        borderRadius={BorderRadius.CARD}
        bg={Color.LIGHT_GREY}
        alignItems='center'
        justifyContent='center'
        width='100%'
        px={4}
        pt={4}
        pb={8}
      >
        <ModalCloseHeader onClose={onClose}/>
        <Flex flexDir='column' w='100%' alignItems='center' gap={8} px={4}>
          <Heading size='lg' color={Color.DARK_BLUE} textAlign='center'>Amplify Account <br/>Information</Heading>
          <Flex flexDir='column' gap={2} w='100%'>
            <Flex justifyContent='space-between'>
              <Text>Institution</Text>
              <Text color={Color.DARK_BLUE}>{amplifyAccount?.institution?.name}</Text>
            </Flex>
            <Divider/>
            <Flex justifyContent='space-between'>
              <Text>Account Type</Text>
              <Text color={Color.DARK_BLUE}>{amplifyAccount?.accountSubTypeFormatted}</Text>
            </Flex>
            <Divider/>
            <Flex justifyContent='space-between'>
              <Text>{accountOwnersFormatted.length > 1 ? 'Account Holders' : 'Account Holder'}</Text>
              <Flex flexDir='column' alignItems='end' gap={1}>
                {
                    accountOwnersFormatted.map(person => {
                      return (
                        <Text color={Color.DARK_BLUE} key={nanoid()}>{person}</Text>
                      )
                    })
                }
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDir='column' gap={4} w='100%'>
            <CopyTextPill
              name='Account Number'
              number={amplifyAccount?.achAccountNumber ?? '123456789'}
              backgroundColor={Color.WHITE}
              variant={CopyTextPillVariant.EXPANDED}
            />
            <CopyTextPill
              name='Routing Number'
              number={amplifyAccount?.achRoutingNumber ?? '123456789'}
              backgroundColor={Color.WHITE}
              variant={CopyTextPillVariant.EXPANDED}
            />
          </Flex>
          <Button text='Done' onClick={onClose}/>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
