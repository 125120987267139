/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { Icon } from '@chakra-ui/react'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function AltirTransparentIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <Icon boxSize={size} display='flex' alignItems='center' justifyContent='center'>
      <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path stroke={color} d='M19.5 15.8571L12 3L4.5 15.8571H9.1875L12 21L14.8125 15.8571H19.5Z' strokeWidth='2' strokeLinejoin='round'/>
      </svg>
    </Icon>
  )
}
