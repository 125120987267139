import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { TRANSACTIONS_PAGE_QUERY_LIMIT } from './TransactionsComponent'
import {
  type GetTransactionsMetaData_currentUser_selectedOrganization_transactions_meta as TransactionsMeta
}
  from '@/graphql/__generated__/GetTransactionsMetaData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { getCurrencyFormatted } from '@/utils/stringUtils'
import { Color, FontWeight } from '@/theme/theme'
import PaginationSelector from '@/library/pagination/PaginationSelector'

interface TransactionsQueryMetaRowProps {
  transactionsMeta?: TransactionsMeta
  isLoading: boolean
  paginationOffset: number
  onUpdatePaginationOffset: (increase: boolean) => void
  error?: Error
}

export default function TransactionsQueryMetaRowComponent (
  {
    transactionsMeta,
    isLoading,
    paginationOffset,
    onUpdatePaginationOffset,
    error
  }: TransactionsQueryMetaRowProps
): ReactElement {
  const incoming = transactionsMeta?.totalIncomingValue
  const outgoing = transactionsMeta?.totalOutgoingValue
  const net = (incoming != null) && (outgoing != null)
    ? Number(incoming) + Number(outgoing)
    : undefined // Outgoing will be negative
  const totalTransactions = transactionsMeta?.totalTransactions

  // Apply formatting
  const incomingFormatted = incoming != null ? getCurrencyFormatted(incoming) : undefined
  const outgoingFormatted = outgoing != null ? getCurrencyFormatted(outgoing) : undefined
  const netFormatted = net != null ? getCurrencyFormatted(net) : undefined

  return (
    <Flex flexDir='row' justify='space-between'>
      {/* Net Values Component */}
      <AltirSkeleton isLoading={isLoading} width=''>
        <Flex flexDir='row' gap={2}>
          <Text color={Color.DARK_GREY} fontWeight={FontWeight.MEDIUM}>Incoming:</Text>
          <Text color={Color.DARK_BLUE} fontWeight={FontWeight.MEDIUM}>{incomingFormatted}</Text>
          <Text px={1}>|</Text>
          <Text color={Color.DARK_GREY} fontWeight={FontWeight.MEDIUM}>Outgoing:</Text>
          <Text color={Color.DARK_BLUE} fontWeight={FontWeight.MEDIUM}>{outgoingFormatted}</Text>
          <Text px={1}>|</Text>
          <Text color={Color.DARK_GREY} fontWeight={FontWeight.MEDIUM}>Net:</Text>
          <Text color={Color.DARK_BLUE} fontWeight={FontWeight.MEDIUM}>{netFormatted}</Text>
        </Flex>
      </AltirSkeleton >
      <AltirSkeleton isLoading={isLoading} width=''>
        <PaginationSelector
          totalResults={totalTransactions}
          limit={TRANSACTIONS_PAGE_QUERY_LIMIT}
          offset={paginationOffset}
          onUpdatePaginationOffset={onUpdatePaginationOffset}
        />
      </AltirSkeleton>
      {/* TODO Add Pagination buttons */}
    </Flex>
  )
}
