import React, { type ReactElement } from 'react'
import FormNumberInput from './FormNumberInput'
import { formatInputAsDate } from './utils'
import { type BaseFormInputProps } from '../types'

type FormDateInputProps = {
  value: string
  onChange: (value: string) => void
} & BaseFormInputProps

export default function FormDateInput (props: FormDateInputProps): ReactElement {
  return (
    <FormNumberInput
      autoFormatRule={formatInputAsDate}
      maxNumberOfDigits={8}
      {...props}
    />
  )
}
