import React, { type ReactElement } from 'react'
import { Button } from '@chakra-ui/react'
import { type DateTime } from 'luxon'
import PopoverMenuButtonContent from '@/library/popoverMenu/components/button/PopoverMenuButtonContent'
import { BorderRadius, Color } from '@/theme/theme'
import { DateTimeTemplate, getFormattedDateString } from '@/utils/dateUtils'

interface DatePickerButtonProps {
  isOpen: boolean
  onClick: () => void
  startDate?: DateTime
  endDate?: DateTime
}

export default function DatePickerButton ({
  isOpen,
  onClick,
  startDate,
  endDate
}: DatePickerButtonProps): ReactElement {
  const hasSelection = startDate != null && endDate != null
  const subtitle = hasSelection ? `${getFormattedDateString(startDate, DateTimeTemplate.MM_DD_YY_SLASH) ?? ''} - ${getFormattedDateString(endDate, DateTimeTemplate.MM_DD_YY_SLASH) ?? ''}` : undefined
  return (
    <Button
      onClick={onClick}
      border={hasSelection ? `1px solid ${Color.DARK_BLUE}` : undefined}
      bg={Color.WHITE}
      _hover={{ bg: Color.WHITE }}
      borderRadius={BorderRadius.BUTTON}
      width='100%'
      px={0}
    >
      <PopoverMenuButtonContent
        isOpen={isOpen}
        title='Date'
        subtitle={subtitle}
      />
    </Button>
  )
}
