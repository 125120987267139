
import { gql } from '@apollo/client'

export const GET_ONBOARDING_BUSINESS_AMPLIFY_APPLICATION_DATA = gql`
    query GetOnboardingBusinessAmplifyApplicationData {
        currentUser {
            selectedOrganization {
                id
                name
                franchiseGroups {
                    id
                    name
                    amplifyAccountApplication {
                        id
                        status
                    }
                }
            }
        }
    }
`
