import React, { useState, type ReactElement } from 'react'
import InstitutionConnectionModal from '@/library/modal/financial_institution/InstitutionConnectionModal'

interface InstitutionConnectionComponentProps {
  button: ReactElement
  franchiseGroupId: number
  defaultIsOpen?: boolean
  handleBankAccountLink: () => void
}

export default function InstitutionConnectionComponent ({
  button,
  franchiseGroupId,
  defaultIsOpen = false,
  handleBankAccountLink
}: InstitutionConnectionComponentProps): ReactElement {
  const [isOpen, setIsOpen] = useState(defaultIsOpen)

  function onBankAccountLink (): void {
    setIsOpen(false)
    handleBankAccountLink()
  }

  return (
    <>
      <InstitutionConnectionModal
        franchiseGroupId={franchiseGroupId}
        isOpen={isOpen}
        onClose={() => { setIsOpen(false) }}
        handleBankAccountLink={onBankAccountLink}
      />
      <div onClick={() => { setIsOpen(true) }}>
        {button}
      </div>
    </>
  )
}
