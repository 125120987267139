import React, { useState, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import AltirProUpgradeModal, { type AltirProUpgradeModalVariant } from './AltirProUpgradeModal'
import Button, { ButtonSize, ButtonVariant } from '../button/Button'
import SparkleIcon from '../icons/SparkleIcon'
import { IconSize } from '../theme/theme'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { isSubscribed } from '@/utils/subscriptionUtils'
import { type AltirSubscriptionLevel } from '@/graphql/__generated__/globalTypes'

export enum UpgradeButtonVariant {
  LIGHT_BLUE,
  BRIGHT_BLUE,
  WHITE
}

interface UpgradeButtonProps {
  h?: string
  width?: string
  marginRight?: string
  buttonVariant?: ButtonVariant
  productVariant?: AltirProUpgradeModalVariant
  ctaText?: string
  subscriptionLevel?: AltirSubscriptionLevel
}

export default function AltirProUpgradeComponent (
  {
    width = '240px',
    buttonVariant = ButtonVariant.PRIMARY_TRANSPARENT,
    productVariant,
    ctaText,
    subscriptionLevel
  }: UpgradeButtonProps
): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)

  if (isSubscribed(subscriptionLevel)) return <></>
  if (selectedOrganizationId == null) return <></>

  return (
    <Flex w={width}>
      <AltirProUpgradeModal
        // Refactor how we create the AltirPro component (ALT-1178)
        organizationId={selectedOrganizationId}
        isOpen={isModalOpen}
        onClose={() => { setIsModalOpen(false) }}
        productVariant={productVariant}
      />
      <Button
        text={ctaText}
        onClick={() => { setIsModalOpen(true) }}
        beforeIcon={<SparkleIcon size={IconSize.SMALL}/>}
        variant={buttonVariant}
        size={ButtonSize.SMALL}
        width={width}
        onClickEventType={Event.ALTIR_UPGRADE_MODAL_OPEN_CLICK}
      />
    </Flex>
  )
}
