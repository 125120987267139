import React, { type ReactElement, type ReactNode } from 'react'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import {
  Color
} from './theme'
import {
  accordionTheme,
  badgeTheme,
  checkBoxStyleConfig,
  dividerStyleConfig,
  headingStyleConfig,
  inputTheme,
  menuStyleConfig,
  numberInputTheme,
  radioStyleConfig,
  selectorTheme,
  switchTheme,
  tabsTheme,
  textStyleConfig,
  tooltipTheme
} from './componentThemes'

const theme = extendTheme({
  fonts: {
    body: 'AltirCommons',
    heading: 'AltirCommons'
  },
  components: {
    Accordion: accordionTheme,
    Badge: badgeTheme,
    Checkbox: checkBoxStyleConfig,
    Divider: dividerStyleConfig,
    Heading: headingStyleConfig,
    Menu: menuStyleConfig,
    Radio: radioStyleConfig,
    Tabs: tabsTheme,
    Text: textStyleConfig,
    Tooltip: tooltipTheme,
    Select: selectorTheme,
    Switch: switchTheme,
    Input: inputTheme,
    NumberInput: numberInputTheme
  },
  colors: {
    selectableInput: {
      300: Color.DARK_GREY,
      400: Color.DARK_BLUE,
      500: Color.BRIGHT_BLUE,
      600: Color.BRIGHT_BLUE_HOVER
    },
    successToast: {
      500: Color.SUCCESS_GREEN
    },
    actionToast: {
      500: Color.DARK_BLUE
    },
    errorToast: {
      500: Color.ERROR_RED
    }
  },
  fontSizes: {
    xs: '0.6rem',
    sm: '0.7rem',
    md: '0.85rem',
    lg: '1rem'
  },
  semanticTokens: {
    colors: {
      error: Color.ERROR_RED,
      success: 'green.500',
      primary: {
        default: 'red.500'
      },
      darkBlue: {
        default: Color.DARK_BLUE
      },
      lightBlue: {
        default: Color.BRIGHT_BLUE
      },
      greyBlue: {
        default: Color.GREY_BLUE
      },
      darkGrey: {
        default: Color.DARK_GREY
      },
      grey: {
        default: Color.GREY
      },
      lightGrey: {
        default: Color.LIGHT_GREY
      },
      mediumGrey: {
        default: Color.MEDIUM_GREY
      },
      roseGrey: {
        default: Color.ROSE_GREY
      },
      yellow: {
        default: Color.YELLOW
      }
    }
  },
  breakpoints: {
    base: '0em', // 0px
    sm: '30em', // ~480px // Mobile
    md: '62em', // ~992px // Tablet
    lg: '80em', // ~1280px // Laptop
    xl: '96em', // ~1536px // Desktop
    '2xl': '150em' // ~2400px // Large Desktop monitor
  }
})

export default function ThemeProvider ({
  children
}: {
  children: ReactNode
}): ReactElement {
  return (
    <ChakraProvider theme={theme}>
      {children}
    </ChakraProvider>
  )
}
