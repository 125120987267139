import React, { type ReactElement } from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import { useComponentSize } from 'react-use-size'
import { useQuery } from '@apollo/client'
import MonthlyMetric from './MonthlyMetric'
import EmptyGraphSection from './EmptyGraphSection'
import { type DateSelectionWindow } from '@/types/types'
import AltirSkeleton, { SkeletonVariant } from '@/library/loading/AltirSkeleton'
import { GET_HISTORICAL_CASH_BALANCE } from '@/graphql/queries/GetInsightsData'
import { DateTimeTemplate, getFormattedDateString, TimeZone } from '@/utils/dateUtils'
import {
  type GetHistoricalCashBalanceVariables,
  type GetHistoricalCashBalance
} from '@/graphql/__generated__/GetHistoricalCashBalance'
import { BorderRadius, Color } from '@/theme/theme'
import LineGraph from '@/library/graphs/FinancialLineGraph'
import { EODAccountBalancesToFinancialDataPoint } from '@/utils/financialAccountUtils'
import { EMPTY_BALANCE_VALUE } from '@/utils/constants'

interface MonthlyCashBalanceContainerProps {
  franchiseGroupId: number
  selectedTimeWindow: DateSelectionWindow
}

const CHART_HEIGHT = 300

export default function MonthlyCashBalanceContainer (
  {
    franchiseGroupId,
    selectedTimeWindow
  }: MonthlyCashBalanceContainerProps
): ReactElement {
  const {
    data,
    loading,
    error
  } = useQuery<GetHistoricalCashBalance, GetHistoricalCashBalanceVariables>(
    GET_HISTORICAL_CASH_BALANCE,
    {
      variables: {
        franchiseGroupId,
        dateRange: {
          startDate: selectedTimeWindow.startDate?.toISO(),
          endDate: selectedTimeWindow.endDate?.toISO()
        },
        ascending: true
      }
    }
  )
  const { ref, width } = useComponentSize()
  const balances = EODAccountBalancesToFinancialDataPoint(data?.currentUser?.franchiseGroup?.aggregateEODBalances ?? [])
  const startOfMonthBalance = balances[0]
  const endOfMonthBalance = balances[balances.length - 1]

  // Note we leverage UTC here because DerivedAccountBalance values have time=0
  // Longer term fix is for the backend to return these objects as just dates, not times
  const startOfMonthDate = getFormattedDateString(
    startOfMonthBalance?.date, DateTimeTemplate.LONG, TimeZone.DEFAULT_SYSTEM_TIME_ZONE
  )
  const endOfMonthDate = getFormattedDateString(
    endOfMonthBalance?.date, DateTimeTemplate.LONG, TimeZone.DEFAULT_SYSTEM_TIME_ZONE
  )
  const graphMonth = getFormattedDateString(
    selectedTimeWindow.startDate,
    DateTimeTemplate.MONTH_YEAR,
    TimeZone.DEFAULT_SYSTEM_TIME_ZONE
  )
  const graphTitle = `${graphMonth ?? EMPTY_BALANCE_VALUE} Cash Balance`

  const hasSufficientData = balances.length > 0 || loading

  return (
    <Flex
      ref={ref}
      flexDir='column'
      bg={hasSufficientData ? Color.WHITE : Color.GREY}
      w='100%'
      borderRadius={BorderRadius.CARD}
      p={6}
    >
      {hasSufficientData
        ? <Flex flexDir='column'>
          <Flex>
            <Heading size='sm'>{graphTitle}</Heading>
          </Flex>
          <AltirSkeleton isLoading={loading} error={error} variant={SkeletonVariant.LIGHT}>
            <Flex minHeight={`${String(CHART_HEIGHT + 100)}px`}>
              <LineGraph
                data={balances}
                parentWidth={width}
                parentHeight={CHART_HEIGHT}
              />
            </Flex>
          </AltirSkeleton>
          <Flex w='100%' gap={3}>
            <MonthlyMetric
              description={`Starting Balance (${startOfMonthDate ?? ''})`}
              numericalValue={startOfMonthBalance?.amount}
              isLoading={loading}
            />
            <MonthlyMetric
              description={`Ending Balance (${endOfMonthDate ?? ''})`}
              numericalValue={endOfMonthBalance?.amount}
              isLoading={loading}
            />
          </Flex>
        </Flex>
        : <EmptyGraphSection renderGraphIcon={true} metricDescription='month-to-month cash balance'/>}
    </Flex>
  )
}
