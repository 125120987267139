import React, { useState, type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import AccountInfoUpdateInfoModal
  from './accounts/AccountSettingsUpdateInfoModal'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import EditIcon from '@/library/icons/EditIcon'
import { Color, FontWeight, IconSize } from '@/theme/theme'
import { type AccountInfoUpdateField } from '@/graphql/__generated__/globalTypes'

export enum AccountInfoRowType {
  PERSON_WITH_KYC,
  PERSON_NO_KYC,
  BUSINESS_WITH_KYC,
  BUSINESS_WITH_NO_KYC
}

export interface AccountInfoRowComponentProps {
  label: string
  valueLine1?: string
  valueLine2?: string
  valueLine3?: string
  accountInfoRowType: AccountInfoRowType
  objectId: string
  fieldType: AccountInfoUpdateField
}

export default function AccountInfoRowComponent (
  {
    label,
    valueLine1,
    valueLine2,
    valueLine3,
    accountInfoRowType,
    objectId,
    fieldType
  }: AccountInfoRowComponentProps
): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <Flex flexDir='column' flex={1} textAlign='start'>
      <Flex flexDir='row' textAlign='start' alignItems='center'>
        <Text fontWeight={FontWeight.BOLD} color={Color.DARK_GREY} fontSize='xs'>{label}</Text>
        <AccountInfoUpdateInfoModal
          currentValue={`${valueLine1 ?? ''} ${valueLine2 ?? ''} ${valueLine3 ?? ''}`}
          isModalOpen={isModalOpen}
          onClose={() => { setIsModalOpen(false) }}
          productVariant={accountInfoRowType}
          objectId={objectId}
          fieldType={fieldType}
        />
        <Button
          size={ButtonSize.X_SMALL}
          width='0px'
          afterIcon={<EditIcon size={IconSize.EXTRA_SMALL}/>}
          variant={ButtonVariant.GHOST}
          onClick={() => { setIsModalOpen(true) }}
          onClickEventType={Event.OPEN_UPDATE_ACCOUNT_INFO_MODAL_CLICK}
        />
      </Flex>
      <Text fontWeight={FontWeight.MEDIUM} color={Color.DARK_BLUE}>{valueLine1}</Text>
      {valueLine2 != null && <Text fontWeight={FontWeight.MEDIUM} color={Color.DARK_BLUE}>{valueLine2}</Text>}
      {valueLine3 != null && <Text fontWeight={FontWeight.MEDIUM} color={Color.DARK_BLUE}>{valueLine3}</Text>}
    </Flex>
  )
}
