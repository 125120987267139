/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import IconContainer from './IconContainer'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function FilesIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='16' height='21' viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M6.27173 2.75977H2.44531V18.2398L13.5553 18.2398V10.0431H8.27173C7.16716 10.0431 6.27173 9.14767 6.27173 8.0431V2.75977ZM7.33075 0.761493C7.57453 0.775835 7.80545 0.879022 7.97909 1.05266L15.2624 8.33599C15.45 8.52353 15.5553 8.77788 15.5553 9.0431V18.2398C15.5553 19.3443 14.6599 20.2398 13.5553 20.2398H2.44531C1.34075 20.2398 0.445312 19.3443 0.445312 18.2398V2.75977C0.445312 1.6552 1.34074 0.759766 2.44531 0.759766H7.26387H7.27198C7.29164 0.759766 7.31123 0.760344 7.33075 0.761493ZM8.27173 8.0431V4.17398L12.1408 8.0431H8.27173Z'/>
      </svg>
    </IconContainer>
  )
}
