import React, { Fragment, type ReactElement } from 'react'
import { Grid } from '@chakra-ui/react'
import TableBodyRow from './TableBodyRow'
import TableDivider from './TableDivider'
import { type TableHeader } from '../Table'
import { BorderRadius, type Color } from '@/theme/theme'

interface TableBodyProps<T> {
  tableMetadata: Array<TableHeader<T>>
  data: T[]
  templateColumns: string
  gridHorizontalPadding: number

  // Styles
  backgroundColor: Color
}

export default function TableBody<T> ({
  tableMetadata,
  data,
  templateColumns,
  gridHorizontalPadding,
  backgroundColor
}: TableBodyProps<T>): ReactElement {
  return (
    <Grid
      templateColumns={templateColumns}
      w='100%'
      rowGap={2}
      alignItems='center'
      overflow='hidden'
      bg={backgroundColor}
      borderRadius={BorderRadius.CARD}
      px={gridHorizontalPadding}
      py={4}
    >
      {
        data.map((row, index) => {
          return (
            <Fragment key={index}>
              <TableBodyRow tableMetadata={tableMetadata} row={row}/>
              <TableDivider currentRowIndex={index} totalRows={data.length} totalColumns={tableMetadata.length}/>
            </Fragment>
          )
        })
    }
    </Grid>
  )
}
