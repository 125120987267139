import { type Location } from 'react-router-dom'
import { getRouteName } from './routerUtils'
import { AuthResource, Role } from '@/graphql/__generated__/globalTypes'
import { RouteName } from '@/api/routes'

export const INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY = `
  You do not have permission to perform this action. Contact your administrator to change your role.
`

const SUPER_ADMIN_DESCRIPTION = `
  The business's primary administrator. Full access to Altir
`
const ADMIN_DESCRIPTION = `
  Full access to Altir. Not able to manage user permissions.
`
const MEMBER_DESCRIPTION = `
  Limited Access to Altir. Members have access to everything except sensitive banking data. Not able to move money.
`
const VIEWER_DESCRIPTION = `
  Able to view insights and transactions.
`

const NO_ACCESS_DESCRIPTION = `
  Will not be able to access any of the entity's information.
`

interface FranchiseRole {
  franchiseGroupId?: number
  role: Role
}

export enum AuthAction {
  READ,
  WRITE
}

export interface AuthActionMap {
  READ: boolean
  WRITE: boolean
}

export interface PermissionMap {
  resource: AuthResource
  actions: AuthActionMap
}

/**
 * Returns true if the current user has access to the provided RESOURCE x ACTION combination
 */
export function hasAccess (resource: AuthResource, action: AuthAction, permissions: PermissionMap[]): boolean {
  const permission = permissions.find(permission => permission.resource === resource)

  // This should never be null. Since this only dictates whether a UI component is true,
  // we default to being overly permissive in the event of an error
  if (permission == null) return true

  switch (action) {
    case AuthAction.READ:
      return permission.actions.READ
    case AuthAction.WRITE:
      return permission.actions.WRITE
  }
}

export function hasAccessToPage (path: Location, permissions: PermissionMap[]): boolean {
  const routeName = getRouteName(path)
  if (routeName == null) return true

  switch (routeName) {
    case RouteName.AMPLIFY_APP:
      return hasAccess(AuthResource.AMPLIFY_ACCOUNT_APPLICATION, AuthAction.WRITE, permissions)
    case RouteName.TRANSFER:
      return hasAccess(AuthResource.AMPLIFY_ACCOUNT, AuthAction.WRITE, permissions)
    case RouteName.TRANSFER_HISTORY:
      return hasAccess(AuthResource.AMPLIFY_ACCOUNT, AuthAction.READ, permissions)
    case RouteName.AUTOMATED_TRANSFERS:
      return hasAccess(AuthResource.AMPLIFY_ACCOUNT, AuthAction.WRITE, permissions)
    case RouteName.CREATE_AUTOMATED_TRANSFER_RULE:
      return hasAccess(AuthResource.AMPLIFY_ACCOUNT, AuthAction.WRITE, permissions)
    default:
      return true
  }
}

export function clearAltirCache (): void {
  localStorage.clear()
}

export function getUserRoleForFranchiseContext (roles: FranchiseRole[], franchiseGroupId: number): Role | undefined {
  return roles.find(r => r.franchiseGroupId === franchiseGroupId)?.role
}

export function getFormattedUserRole (role: Role | null): string {
  if (role == null) {
    return 'No Access'
  }

  switch (role) {
    case Role.GOD_ADMIN:
    case Role.OWNER:
      return 'Super Admin'
    case Role.ADMIN:
      return 'Admin'
    case Role.MEMBER:
      return 'Member'
    case Role.VIEWER:
      return 'Viewer'
    case Role.NO_ACCESS:
      return 'No Access'
  }
}

export function getRoleDescription (role: Role): string {
  switch (role) {
    case Role.GOD_ADMIN:
    case Role.OWNER:
      return SUPER_ADMIN_DESCRIPTION
    case Role.ADMIN:
      return ADMIN_DESCRIPTION
    case Role.MEMBER:
      return MEMBER_DESCRIPTION
    case Role.VIEWER:
      return VIEWER_DESCRIPTION
    case Role.NO_ACCESS:
      return NO_ACCESS_DESCRIPTION
  }
}
