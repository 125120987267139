import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import StackedInfoAttribute from '../../../library/utility/StackedInfoAttribute'
import { BorderRadius, Color } from '@/theme/theme'
import { TransferRuleType } from '@/graphql/__generated__/globalTypes'
import { formatTransferRuleType } from '@/utils/transferRuleUtils'

export interface TimeRuleConfirmationCardProps {
  frequency: TransferRuleType
  amount: string
  from: string
  to: string
  dayOfWeek?: string
  dateOfMonth?: string
  startDate?: string
}

export default function TimeRuleConfirmationCard (
  { frequency, amount, from, to, dayOfWeek, dateOfMonth, startDate }:
  TimeRuleConfirmationCardProps
): ReactElement {
  function getSubFrequency (): string {
    if (frequency === TransferRuleType.WEEKLY) {
      return dayOfWeek != null ? `on ${dayOfWeek}` : ''
    } else if (frequency === TransferRuleType.MONTHLY) {
      return dateOfMonth != null ? `on the ${dateOfMonth}` : ''
    } else if (frequency === TransferRuleType.ONE_TIME) {
      return startDate != null ? `on ${startDate}` : ''
    }
    return ''
  }

  return (
    <Flex borderRadius={BorderRadius.CARD} backgroundColor={Color.WHITE} flexDir='column' gap={3} px={5} py={5}>
      <StackedInfoAttribute header='Frequency' subtext={`${formatTransferRuleType(frequency)} ${getSubFrequency()}`}/>
      <StackedInfoAttribute header='Amount' subtext={amount}/>
      <StackedInfoAttribute header='From' subtext={from}/>
      <StackedInfoAttribute header='To' subtext={to}/>
    </Flex>
  )
}
