import React, { type ReactElement } from 'react'
import {
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text
} from '@chakra-ui/react'
import { Color } from '@/theme/theme'

interface DoubleSliderComponentProps {
  lowValue?: number
  highValue?: number
  minValue: number
  maxValue: number
  onChange: (value: Array<number | undefined>) => void
  shouldEndsReturnUndefined?: boolean
  // Styles
  colorScheme?: string
  formatValue?: (value: number) => string
}

export default function DoubleSliderComponent ({
  lowValue,
  highValue,
  minValue,
  maxValue,
  onChange,
  colorScheme = Color.DARK_BLUE,
  formatValue = (value) => String(value),
  shouldEndsReturnUndefined = false
}: DoubleSliderComponentProps): ReactElement {
  const minText = lowValue == null || lowValue <= minValue
    ? 'Minimum'
    : formatValue(lowValue)
  const maxText = highValue == null || highValue >= maxValue
    ? 'Maximum'
    : formatValue(highValue)

  function handleChange ([low, high]: [number, number]): void {
    if (!shouldEndsReturnUndefined) {
      onChange([low, high])
      return
    }
    onChange([
      endsReturnUndefined(low, minValue),
      endsReturnUndefined(high, maxValue)
    ])
  }
  return (
    <Flex flexDir='column' w='100%' gap={2}>
      <RangeSlider
        onChange={handleChange}
        aria-label={['min', 'max']}
        min={minValue}
        max={maxValue}
        value={[lowValue ?? minValue, highValue ?? maxValue]}
      >
        <RangeSliderTrack bg={Color.GREY}>
          <RangeSliderFilledTrack bg={Color.DARK_BLUE}/>
        </RangeSliderTrack>
        <RangeSliderThumb index={0} bg={Color.DARK_BLUE}/>
        <RangeSliderThumb index={1} bg={Color.DARK_BLUE}/>
      </RangeSlider>
      <Flex justifyContent='space-between'>
        <Text>{minText}</Text>
        <Text>{maxText}</Text>
      </Flex>
    </Flex>

  )
}
/**
 * When used this will make the ends of the sliders return an undefined value (i.e. no max or min limit enforced)
 * @param value
 * @param end
 * @returns
 */
function endsReturnUndefined (value: number, end: number): number | undefined {
  return value === end ? undefined : value
}
