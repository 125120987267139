/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import IconContainer from './IconContainer'
import { IconSize } from '../theme/theme'

export default function MenuIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <IconContainer size={size}>
      <svg width='100%' height='100%' viewBox='0 0 4 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='2.08021' cy='14.9022' r='1.59779' transform='rotate(-90 2.08021 14.9022)' fill={color}/>
        <circle cx='2.08021' cy='8.70641' r='1.59779' transform='rotate(-90 2.08021 8.70641)' fill={color}/>
        <circle cx='2.08021' cy='2.51061' r='1.59779' transform='rotate(-90 2.08021 2.51061)' fill={color}/>
      </svg>
    </IconContainer>
  )
}
