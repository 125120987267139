import { type Location } from 'react-router-dom'
import { RouteName, type RouteParam, ROUTES } from '../api/routes'
import { LinkedAccountType } from '../graphql/__generated__/globalTypes'

export enum PageTitle {
  Accounts = 'Accounts',
  Checks = 'Checks',
  AmplifyAccountApplication = 'Amplify Account Application',
  AutomatedTransfers = 'Automated Transfers',
  Insights = 'Insights',
  SignUp = 'Sign Up',
  LogIn = 'Log In',
  Cards = 'Cards',
  Home = 'Home',
  TermsAndConditions = 'Terms & Conditions',
  Settings = 'Settings',
  Loans = 'Loans',
  Recipients = 'Recipients',
  Transactions = 'Transactions',
  Transfers = 'Transfers',
  Statements = 'Statements & Transactions',
  History = 'History',

  RESOURCE = 'RESOURCE'
}

interface PathObject {
  basePath?: string
  fullPath: string[]
}

interface PageTitleMeta {
  pageTitles: PageTitle[]
  resourceId?: string
}

export const getPageTitles = (path: Location): PageTitleMeta => {
  const pathObject = getPathObject(path)
  if (pathObject?.basePath == null) return { pageTitles: [] }

  switch (pathObject.basePath) {
    case RouteName.ACCOUNTS: {
      if (pathObject.fullPath.length <= 1) {
        return { pageTitles: [PageTitle.Accounts] }
      }
      if (pathObject.fullPath.some(path => path === RouteName.CHECKS)) {
        return {
          pageTitles: [PageTitle.Accounts, PageTitle.RESOURCE, PageTitle.Checks],
          resourceId: pathObject.fullPath[pathObject.fullPath.length - 2]
        }
      }
      return {
        pageTitles: [PageTitle.Accounts, PageTitle.RESOURCE],
        resourceId: pathObject.fullPath[pathObject.fullPath.length - 1]
      }
    }
    case RouteName.AMPLIFY_APP:
      return { pageTitles: [PageTitle.AmplifyAccountApplication] }
    case RouteName.INSIGHTS:
      return { pageTitles: [PageTitle.Insights] }
    case RouteName.SIGN_UP_SUCCESS:
      return { pageTitles: [PageTitle.SignUp] }
    case RouteName.LOG_IN:
      return { pageTitles: [PageTitle.LogIn] }
    case RouteName.CAPTIAL_OS_CARDS:
    case RouteName.CARDS:
      return { pageTitles: [PageTitle.Cards] }
    case RouteName.HOME:
      return { pageTitles: [PageTitle.Home] }
    case RouteName.TREASURY: {
      if (pathObject.fullPath.length <= 1) {
        return { pageTitles: [PageTitle.Home] }
      } else if (pathObject.fullPath.some(path => path === 'business')) {
        return {
          pageTitles: [PageTitle.Home, PageTitle.RESOURCE],
          resourceId: pathObject.fullPath[pathObject.fullPath.length - 1]
        }
      }
      return {
        pageTitles: [PageTitle.Accounts, PageTitle.RESOURCE, PageTitle.Statements],
        resourceId: pathObject.fullPath[pathObject.fullPath.length - 2]
      }
    }
    case RouteName.TERMS_AND_CONDITIONS:
      return { pageTitles: [PageTitle.TermsAndConditions] }
    case RouteName.SETTINGS:
      return { pageTitles: [PageTitle.Settings] }
    case RouteName.LOANS:
      return { pageTitles: [PageTitle.Loans] }
    case RouteName.RECIPIENTS:
      return { pageTitles: [PageTitle.Transfers, PageTitle.Recipients] }
    case RouteName.TRANSACTIONS:
      return { pageTitles: [PageTitle.Transactions] }
    case RouteName.TRANSFER:
      return { pageTitles: [PageTitle.Transfers] }
    case RouteName.TRANSFER_HISTORY:
      return { pageTitles: [PageTitle.Transfers, PageTitle.History] }
    case RouteName.AUTOMATED_TRANSFERS:
    case RouteName.CREATE_AUTOMATED_TRANSFER_RULE:
      return { pageTitles: [PageTitle.Transfers, PageTitle.AutomatedTransfers] }
  }
  return { pageTitles: [] }
}

export function isLogInPage (path: Location): boolean {
  return getBasePathName(path) === RouteName.LOG_IN
}

export function getAccountTypeFromPath (path: Location): LinkedAccountType {
  const basePathName = getBasePathName(path)
  if (basePathName === RouteName.CARDS) return LinkedAccountType.CARD

  return LinkedAccountType.BANK
}

export function getBasePathName (path: Location): string | undefined {
  return path.pathname.split('/')[1]
}

export function getPathObject (path: Location): PathObject | undefined {
  const paths = path.pathname.split('/')

  if (paths.length <= 1) return undefined

  const parsedPaths = paths.splice(1)

  return {
    basePath: parsedPaths[0],
    fullPath: parsedPaths
  }
}

export function getRouteName (path: Location): RouteName | undefined {
  const pathName = getBasePathName(path)
  return pathName != null && Object.values(RouteName).includes(pathName as RouteName)
    ? pathName as RouteName
    : undefined
}

export function getQueryParamValue (location: Location, queryField: string): string | null {
  return new URLSearchParams(location.search).get(queryField)
}

export function shouldShowDisclosureFooter (location: Location): boolean {
  return ![ROUTES.CAPITAL_OS_CARDS].includes(location.pathname)
}

export function getBusinessPageRoute (id: string): string {
  return `${ROUTES.TREASURY}/business/${String(id)}`
}

export function refreshPage (): void {
  window.location.reload()
}

export function routeWithParams (
  route: string,
  params: Array<{ param: RouteParam, value: string }>): string {
  for (const param of params) {
    route = route.split(`:${param.param}`).join(param.value)
  }
  return route
}
