import React, { type ReactElement } from 'react'
import { Flex, Link, Text } from '@chakra-ui/react'
import DownloadIcon from '../icons/DownloadIcon'
import { FontWeight, Color, IconSize } from '../theme/theme'

export interface DownloadLinkProps {
  href: string
  name?: string
}

export default function DownloadLink ({ href, name = 'Download' }: DownloadLinkProps): ReactElement {
  return (
    <Link
      href={href}
      isExternal
    >
      <Flex gap={2} alignItems='center'>
        <DownloadIcon color={Color.GREY_BLUE} size={IconSize.LARGE}/>
        <Text color={Color.GREY_BLUE} fontWeight={FontWeight.BOLD}>{name}</Text>
      </Flex>
    </Link>
  )
}
