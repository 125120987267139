import { Divider, Flex, Heading, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useState, type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { nanoid } from 'nanoid'
import { Event } from 'metrics/metrics'
import DocumentUploadComponent from './DocumentUploadComponent'
import DocumentUploadModal from './DocumentUploadModal'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import ProgressBarComponent, { ProgressBarVariant } from '@/library/utility/ProgressBarComponent'
import Button, { ButtonVariant } from '@/library/button/Button'
import FileUploadIcon from '@/library/icons/FileUploadIcon'
import { GET_DOCUMENTS_HUB_DATA } from '@/graphql/queries/GetLendingPageData'
import { getSuccessToast } from '@/utils/toastUtils'
import {
  type GetDocumentsHubData_currentUser_franchiseGroup_lendingDocuments as Document,
  type GetDocumentsHubData,
  type GetDocumentsHubDataVariables
} from '@/graphql/__generated__/GetDocumentsHubData'
import { FileUploadType } from '@/graphql/__generated__/globalTypes'
import { BorderRadius, Color, IconSize } from '@/theme/theme'

export interface DocumentsHubComponentProps {
  franchiseGroupId: number
  organizationId: string | null
}

// eslint-disable-next-line max-len
const DOCUMENT_DESCRIPTION_SUBTEXT = 'This is your space to upload documents that prove your identity and eligibility for loans. These documents will be shared with lenders.'

export default function DocumentsHubComponent (
  {
    franchiseGroupId,
    organizationId
  }: DocumentsHubComponentProps
): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { data, loading, error, refetch } = useQuery<GetDocumentsHubData, GetDocumentsHubDataVariables>(
    GET_DOCUMENTS_HUB_DATA,
    { variables: { franchiseGroupId, organizationId } }
  )
  const toast = useToast()

  function onDocumentUploadSuccess (): void {
    void refetch()
    toast(getSuccessToast('Document Uploaded'))
  }

  const organizationDocuments = data?.currentUser?.franchiseGroup?.lendingDocuments ?? []
  const documents = organizationDocuments

  const lendingDocumentTypes = (data?.lendingDocumentTypes ?? [])
  const requiredDocumentTypes = lendingDocumentTypes
    .filter(d => d !== FileUploadType.GENERIC_LENDING_DOCUMENT)

  const uploadedDocumentCount = getUploadedDocumentCount(documents, requiredDocumentTypes)
  const hasOtherDocument = documents.find(d => d.type === FileUploadType.GENERIC_LENDING_DOCUMENT) != null
  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Stack
        borderRadius={BorderRadius.CARD}
        bg={Color.WHITE}
        px={6}
        py={8}
        gap={4}
        flex={1}
        w='100%'
      >
        <Flex flexDir='column' gap={2}>
          <Heading size='md' color={Color.DARK_BLUE}>Loan Application Documents</Heading>
          <Text fontSize='sm'>
            {DOCUMENT_DESCRIPTION_SUBTEXT}
          </Text>
        </Flex>
        <Flex w='100%' flexDir='column' gap={2}>
          <ProgressBarComponent
            variant={ProgressBarVariant.BRIGHT_BLUE}
            percentage={uploadedDocumentCount / requiredDocumentTypes.length * 100}
          />
          <Text color={Color.DARK_BLUE} fontSize='xs'>
            {`${uploadedDocumentCount}/${requiredDocumentTypes.length} Documents Uploaded`}
          </Text>
        </Flex>
        <Flex flexDir='column' gap={3} w='100%'>
          {requiredDocumentTypes.map((type, i) => {
            return (
              <Flex key={nanoid()} flexDir='column' gap={3}>
                {i !== 0 && <Divider/>}
                <DocumentUploadComponent
                  key={nanoid()}
                  franchiseGroupId={franchiseGroupId}
                  organizationId={organizationId}
                  type={type}
                  existingDocuments={documents}
                  onUploadSuccess={onDocumentUploadSuccess}
                />
              </Flex>
            )
          })}
          {hasOtherDocument &&
            <>
              <Divider/>
              <DocumentUploadComponent
                franchiseGroupId={franchiseGroupId}
                organizationId={organizationId}
                type={FileUploadType.GENERIC_LENDING_DOCUMENT}
                existingDocuments={documents}
                onUploadSuccess={onDocumentUploadSuccess}
              />
            </>
          }
        </Flex>
        <Button
          text='Upload Additional Documents'
          variant={ButtonVariant.WHITE_OUTLINE}
          beforeIcon={<FileUploadIcon size={IconSize.SMALL}/>}
          onClick={() => { setIsModalOpen(true) }}
          onClickEventType={Event.FILE_UPLOAD_ADDITONAL_DOCUMENTS_CLICK}
        />
      </Stack>
      <DocumentUploadModal
        franchiseGroupId={franchiseGroupId}
        organizationId={organizationId}
        isOpen={isModalOpen}
        onClose={() => { setIsModalOpen(false) }}
        onSuccess={onDocumentUploadSuccess}
        lendingDocumentTypes={lendingDocumentTypes}
      />
    </AltirSkeleton>
  )
}

function getUploadedDocumentCount (documents: Document[], lendingDocumentTypes: FileUploadType[]): number {
  return lendingDocumentTypes
    .filter(type => documents.find(existingDoc => existingDoc.type === type) != null).length
}
