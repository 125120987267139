import React, { useState, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { nanoid } from 'nanoid'
import { Event } from 'metrics/metrics'
import AmplifyAccountPersonComponent from './AmplifyAccountPersonComponent'
import AmplifyAccountBusinessComponent from './AmplifyAccountBusinessComponent'
import AmplifyAccountApplicationCTA from './AmplifyAccountApplicationCTA'
import { DefaultModalComponent } from '@/library/modal/DefaultModalComponent'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { GET_AMPLIFY_OWNER_DATA } from '@/graphql/queries/GetAmplifyOwnerData'
import {
  type GetAmplifyOwnerDataVariables,
  type GetAmplifyOwnerData
} from '@/graphql/__generated__/GetAmplifyOwnerData'
import { BorderRadius, IconSize, Color } from '@/theme/theme'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import CloseIcon from '@/library/icons/CloseIcon'

interface AmplifyAccountSettingsComponentProps {
  isOnAmplifyWaitlist: boolean
}

export default function AmplifyAccountSettingsComponent ({
  isOnAmplifyWaitlist
}: AmplifyAccountSettingsComponentProps): ReactElement {
  const franchiseGroupId = useAltirStore((state) => state.selectedFranchiseGroupId)

  const { data, loading, error } = useQuery<GetAmplifyOwnerData, GetAmplifyOwnerDataVariables>(
    GET_AMPLIFY_OWNER_DATA, {
      variables: { franchiseGroupId },
      fetchPolicy: 'network-only'
    }
  )

  const [isCloseAmplifyAccountModalOpen, setIsCloseAmplifyAccountModalOpen] = useState(false)
  const [isEditAmplifyAccountModalOpen, setIsEditAmplifyAccountModalOpen] = useState(false)

  const amplifyAccount = data?.currentUser?.franchiseGroup?.amplifyAccount
  const business = amplifyAccount?.amplifyAccount?.business
  const people = amplifyAccount?.amplifyAccount?.people ?? []
  const isMultiOwnerAccount = people.length > 1
  if (!loading && amplifyAccount == null) {
    return (
      <AmplifyAccountApplicationCTA
        status={data?.currentUser?.franchiseGroup?.amplifyAccountApplication?.status}
        isOnAmplifyWaitlist={isOnAmplifyWaitlist}
      />
    )
  }

  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Flex flexDir='column' gap={4} w='100%'>
        <Flex
          flexDir='column'
          bg={Color.WHITE}
          w='100%'
          borderRadius={BorderRadius.CARD}
          gap={4}
        >
          {business != null && <AmplifyAccountBusinessComponent business={business}/>}
          {people?.map((person, index) => {
            return (
              <AmplifyAccountPersonComponent
                key={nanoid()}
                person={person}
                ownerNumber={isMultiOwnerAccount ? index + 1 : undefined}
              />
            )
          })}
        </Flex>
        <Flex gap={6} w='100%' justifyContent='end'>
          <Button
            variant={ButtonVariant.DESTRUCTIVE_TRANSPARENT}
            size={ButtonSize.SMALL}
            text='Close Amplify Account'
            beforeIcon={<CloseIcon size={IconSize.SMALL}/>}
            onClick={() => { setIsCloseAmplifyAccountModalOpen(true) }}
            onClickEventType={Event.CLOSE_AMPLIFY_ACCOUNT_CLICK}
            width='auto'
          />
        </Flex>
        <DefaultModalComponent
          title='Need to edit your Amplify Account information?'
          callToActionText='Contact Us'
          callToActionClick={() => { console.log('test') }}
          isOpen={isEditAmplifyAccountModalOpen}
          onClose={() => { setIsEditAmplifyAccountModalOpen(false) }}
          isLoading={false}
        >
          <Flex>
          </Flex>
        </DefaultModalComponent>
        <DefaultModalComponent
          title='Need to close your Amplify Account?'
          callToActionText='Contact Us'
          callToActionClick={() => { console.log('test') }}
          isOpen={isCloseAmplifyAccountModalOpen}
          onClose={() => { setIsCloseAmplifyAccountModalOpen(false) }}
          isLoading={false}
        >
          <Flex>
          </Flex>
        </DefaultModalComponent>
      </Flex>
    </AltirSkeleton>
  )
}
