import { gql } from '@apollo/client'

export const GET_STATEMENT_DOWNLOAD_LINK = gql`
    query GetStatementDownloadLink($accountId: Int!, $startDate: String!, $endDate: String!) {
        statementDownload(accountId: $accountId, startDate: $startDate, endDate: $endDate) {
            statementDownloadLink
        }
    }
`

export const GET_STATEMENT_DOWNLOAD_AVAILABLE = gql`
    query GetStatementDownloadIsAvailable($accountId: Int!, $startDate: String!, $endDate: String!) {
        statementDownload(accountId: $accountId, startDate: $startDate, endDate: $endDate) {
            isStatementAvailable
            statementMonth
        }
    }
`
