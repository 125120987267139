
import { gql } from '@apollo/client'

export const GET_ONBOARDING_STORE_LOCATION_DATA = gql`
    query GetOnboardingStoreLocationData($franchiseGroupId: Int!) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                id
                name
            }
        }
    }
`
