import {
  type GetCapitalOSPageData_currentUser_selectedOrganization_franchiseGroups as Business
} from '@/graphql/__generated__/GetCapitalOSPageData'
import { CapitalOSAccountStatus } from '@/graphql/__generated__/globalTypes'
import { getCurrencyFormatted } from '@/utils/stringUtils'
export function getTotalCapitalOSBalance (businesses: Business[]): string {
  const balances = businesses
    .map(business => business.capitalOSConfiguration?.account?.currentBalance?.amount ?? 0)
  const totalBalance = balances.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  return getCurrencyFormatted(totalBalance, { minimumFractionDigits: 2 })
}

export function getCTAText (status?: CapitalOSAccountStatus | null): string {
  if (status == null) return 'Apply for a Card'

  switch (status) {
    case CapitalOSAccountStatus.APPROVED:
      return 'View Cards'
    case CapitalOSAccountStatus.ELIGIBLE:
      return 'Apply for a Card'
    case CapitalOSAccountStatus.ENGAGED:
      return 'Finish Application'
    case CapitalOSAccountStatus.ONBOARDING:
    case CapitalOSAccountStatus.PENDING:
    case CapitalOSAccountStatus.PROCESSING:
      return 'View Application'
    case CapitalOSAccountStatus.CLOSED:
    case CapitalOSAccountStatus.DECLINED:
    case CapitalOSAccountStatus.INELIGIBLE:
      return ''
  }
}
