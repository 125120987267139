import { gql } from '@apollo/client'

export const GET_HISTORICAL_ACCOUNT_BALANCES = gql`
    query GetHistoricalAccountBalances($accountId: Int!, $dateRange: DateRangeInput, $ascending: Boolean) {
        financialAccount(accountId: $accountId) {
            EODBalances(dateRange: $dateRange, ascending: $ascending) {
                balance {
                    amount
                }
                date
            }
            liveBalance {
                availableBalance {
                    amount
                }
            }
        }
    
    }
`
