import React, { type ReactElement } from 'react'
import { Divider, GridItem } from '@chakra-ui/react'
import { Color } from '@/theme/theme'

interface TableDividerProps {
  currentRowIndex: number
  totalRows: number
  totalColumns: number

}

/**
 * Conditionally renders the <Divider/> component to ensure that it is only displayed between rows.
 *
 * Additionally, ensure divider spans width of entire table
 */
export default function TableDivider ({
  currentRowIndex,
  totalRows,
  totalColumns
}: TableDividerProps): ReactElement {
  if (currentRowIndex === totalRows - 1) {
    return <></>
  }
  return (
    <GridItem
      colStart={1}
      colEnd={totalColumns + 1}
    >
      <Divider borderColor={Color.MEDIUM_GREY}/>
    </GridItem>
  )
}
