import React, { useState } from 'react'
import {
  chakra,
  type ImageProps,
  forwardRef
} from '@chakra-ui/react'

const BASE_LOGO_DIMENSIONS = '30px'

export const Graphic = forwardRef<ImageProps, 'img'>((props, ref) => {
  const [loaded, setLoaded] = useState(false)

  const handleImageLoad = (): void => {
    setLoaded(true)
  }
  return <chakra.img src={props.src}
    ref={ref}
    w={{ base: 'auto', md: 'auto' }}
    h={{ base: BASE_LOGO_DIMENSIONS, md: BASE_LOGO_DIMENSIONS }}
    onLoad={handleImageLoad}
    style={{ opacity: loaded ? 1 : 0 }}
    {...props}
         />
})
