import { type BusinessFilterParams } from './BusinessSelectorComponent'

export interface BusinessSelection {
  id?: string
  name?: string | null
  amplifyAccount?: {
    accountId?: number | null
  } | null
}

export function filterBusinesses (
  businesses: BusinessSelection[],
  businessFilterParams: BusinessFilterParams
): BusinessSelection[] {
  if (businessFilterParams.hasAmplify === true) {
    return businesses
      .filter(business => {
        return business.amplifyAccount?.accountId != null
      })
  } else if (businessFilterParams.needsToApplyForAmplify === true) {
    return businesses
      .filter(business => {
        return !(business.amplifyAccount?.accountId != null)
      })
  }

  return businesses
}
