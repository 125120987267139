import { type RuleData } from './components/TransferRuleConfirmationModal'
import { TransferDirection } from '@/graphql/__generated__/globalTypes'
import { isAmplifyAccount } from '@/utils/transferUtils'

// TODO (PJ): Update to make these fields nullable and prompt call site
// to perform more robust error handling.
//
// Note: implementing it like this for now as I'd rather the backend throw
// an error which will alert us to the failure
export interface RuleCreationParams {
  amplifyAccountId: string
  counterpartyId: string
  transferDirection: TransferDirection
}

export function getRuleCreationParams (
  ruleData: RuleData
): RuleCreationParams {
  return {
    amplifyAccountId: isAmplifyAccount(ruleData.sourceAccount)
      ? ruleData.sourceAccount?.amplifyAccount?.id ?? ''
      : ruleData.destinationAccount?.amplifyAccount?.id ?? '',
    counterpartyId: !isAmplifyAccount(ruleData.sourceAccount)
      ? ruleData.sourceAccount?.counterpartyId ?? ''
      : ruleData.destinationAccount?.counterpartyId ?? '',
    transferDirection: isAmplifyAccount(ruleData.sourceAccount) ? TransferDirection.CREDIT : TransferDirection.DEBIT
  }
}
