import React, { type ReactElement } from 'react'
import { Center, Text } from '@chakra-ui/react'
import { GRASSHOPPER_MIGRATION_CUTOFF_DATE_FORMATTED } from '../utils'
import TreasuryIcon from '@/library/icons/TreasuryIcon'
import { Color } from '@/theme/theme'

export default function GrasshopperMigrationModalHeaderBanner (): ReactElement {
  return (
    <Center gap={4}>
      <TreasuryIcon color={Color.WHITE}/>
      <Text color={Color.WHITE} fontSize='sm'>
        Upgrade your account by {GRASSHOPPER_MIGRATION_CUTOFF_DATE_FORMATTED} to avoid account closure.
      </Text>
    </Center>
  )
}
