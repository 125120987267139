import React, { type ReactElement } from 'react'
import { Flex, Link, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/api/routes'
import Button, { ButtonSize } from '@/library/button/Button'
import AccountsIcon from '@/library/icons/AccountsIcon'
import { IconSize } from '@/theme/theme'
import MetricCard from '@/library/card/MetricCard'

interface FixedInsightCardProps {
  label: string
  metric: string | null
  description?: ReactElement | null
  moreInfoText?: string
}

export default function FixedInsightCard ({
  label,
  metric,
  description,
  moreInfoText
}: FixedInsightCardProps): ReactElement {
  const navigate = useNavigate()
  const isMetricAvailable = metric != null

  const emptyMetricContent = (
    <Flex flexDir='column' gap={2}>
      <Text>Not enough data to accurately calculate this metric</Text>
      <Flex>
        <Link onClick={() => { navigate(ROUTES.SETTINGS) }}>
          <Button
            text='Link an Account'
            size={ButtonSize.X_SMALL}
            beforeIcon={<AccountsIcon size={IconSize.SMALL}/>}
          />
        </Link>
      </Flex>
    </Flex>
  )

  return (
    <MetricCard
      label={label}
      metric={metric}
      tooltipInfo={moreInfoText}
    >
      {isMetricAvailable ? description : emptyMetricContent}
    </MetricCard>
  )
}
